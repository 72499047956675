import i18n from 'configs/i18n'

const initialEditUser = {
  isVisible: false,
  initialValue: {
    username: '',
    email: '',
    passwordGroup: 'reset'
  }
}
const initialEditAdmin = {
  isVisible: false,
  initialValue: {
    userName: '',
    email: '',
    department: '',
    authority: ''
  }
}
const initialParamStatusEmail = {
  key: '',
  userId: '',
  email: '',
  type: ''
}
const initialModal = {
  isVisible: false,
  type: '',
  isComponent: false,
  img: '',
  content: '',
  btnSubmit: i18n.t('modal.btnSubmit'),
  btnCancel: i18n.t('modal.btnCancel')
}
const initialUserDetail = {
  userId: '',
  userName: '',
  registerDate: '',
  numberSensorGroup: '',
  numberSensor: '',
  email: '',
  role: '',
  statusUser: false
}
export {
  initialEditUser,
  initialParamStatusEmail,
  initialModal,
  initialUserDetail,
  initialEditAdmin
}
