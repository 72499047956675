import { ILoginParams } from 'services/params/ILoginParams'
import LoginRequest from 'services/requests/LoginRequest'
import { configRequest } from '..'

class AuthApis {
  login = (loginParams: ILoginParams) => {
    const requests = LoginRequest(loginParams)
    return configRequest(requests)
  }
}

export const AuthApi = new AuthApis()
