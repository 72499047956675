import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { IDataUserListRes } from 'interfaces/IUserList'
import { all, put, call, takeLatest, delay } from 'redux-saga/effects'
import instance from 'services/api/v1'
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { EUserActions } from 'redux/actions/users/EUserAction'
import {
  ICheckTokenExistAction,
  ICreateUserAction,
  IDeleteUserAction,
  IForgotPasswordAction,
  IGetAdminAction,
  IGetUsersAction,
  IResetPasswordAction,
  IUpdateUserStatusAction,
  IVerifyAdminAccountAction,
  IChangePasswordAction
} from 'redux/actions/users/usersTypes'
import { UsersApi } from 'services/api/users/usersApi'
import { DELAY } from 'interfaces/enums/EDelay'

function* createUserSaga(action: ICreateUserAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<any> = yield call(UsersApi.createUser, action.createParam)
    if (response?.data?.error && response?.data?.error?.code !== 400) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* getUsersListSaga(action: IGetUsersAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<IDataUserListRes>> = yield call(
      UsersApi.getUsersList,
      action.request
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    yield put(SetLoadingAction(false))
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
  }
}
function* deleteUsersListSaga(action: IDeleteUserAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(UsersApi.deleteUser, action.request)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

function* updateUserStatusSaga(action: IUpdateUserStatusAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<any> = yield call(UsersApi.updateUserStatus, action.request)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}

function* getAdminListSaga(action: IGetAdminAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<any> = yield call(UsersApi.getAdminList, action.request)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* verifyAdminAccountSaga(action: IVerifyAdminAccountAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    instance.defaults.headers.common['x-token-auth'] = `${action.params.token}`
    const response: AxiosResponse<IData<any>> = yield call(UsersApi.verifyAdminAccount, {
      password: action.params.password
    })
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 401 && err?.code !== 400) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

function* forgotPasswordSaga(action: IForgotPasswordAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(UsersApi.forgotPassword, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 401 && err?.code !== 400) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* changePasswordSaga(action: IChangePasswordAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(UsersApi.changePass, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 401 && err?.code !== 400) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* resetPasswordSaga(action: IResetPasswordAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    instance.defaults.headers.common['x-token-auth'] = `${action.params.token}`
    const response: AxiosResponse<IData<any>> = yield call(UsersApi.resetPassword, {
      password: action.params.password
    })
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 401 && err?.code !== 400) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* checkTokenExistSaga(action: ICheckTokenExistAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    instance.defaults.headers.common['x-token-auth'] = `${action.params.token}`
    const response: AxiosResponse<IData<any>> = yield call(UsersApi.checkTokenExist, {
      email: action.params.email
    })
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 401) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(EUserActions.USERS_LIST, getUsersListSaga),
    takeLatest(EUserActions.CREATE_USER, createUserSaga),
    takeLatest(EUserActions.DELETE_USER, deleteUsersListSaga),
    takeLatest(EUserActions.UPDATE_USER_STATUS, updateUserStatusSaga),
    takeLatest(EUserActions.ADMIN_LIST, getAdminListSaga),
    takeLatest(EUserActions.VERIFY_ADMIN, verifyAdminAccountSaga),
    takeLatest(EUserActions.FORGOT_PASSWORD, forgotPasswordSaga),
    takeLatest(EUserActions.CHANGE_PASSWORD, changePasswordSaga),
    takeLatest(EUserActions.RESET_PASSWORD, resetPasswordSaga),
    takeLatest(EUserActions.CHECK_TOKEN_EXIST, checkTokenExistSaga)
  ])
}
