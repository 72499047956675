import 'styles/Information.scss'
import { useTranslation } from 'react-i18next'
import InputDateComponent from 'components/common/input/InputDateComponent'
import InputTimeComponent from 'components/common/input/InputTimeComponent'
import InputTextAreaComponent from 'components/common/input/InputTextAreaComponent'
import ButtonFooterComponent from 'components/common/button/ButtonFooterComponent'
import { useRef, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AppState } from 'redux/reducers'
import { originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import { ADMIN_ROUTE, routesInfoManagement, routesName } from 'views/routes/routes'
import ModalComponent from 'components/modal/ModalComponent'
import { useDispatch, useSelector } from 'react-redux'
import { SuccessAction, SetLoadingAction } from 'redux/actions/common/commonAction'
import { IconDeleteModal } from 'assets'
import {
  detailAlertAction,
  updateAlertAction
} from 'redux/actions/notifications/notificationAction'
import { Col, Row } from 'antd'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import moment from 'moment'
import { EAuditLogActionType, EAuditLogType } from 'interfaces/enums/EAuditLog'
import { detailAuditLogAction } from 'redux/actions/auditLog/auditLogAction'
interface IParamTypes {
  id: string
}

const InfoDetail = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  const refDateDetail = useRef()
  const [startDateDetail, setStartDateDetail] = useState(new Date())
  const refTimeDetail = useRef()
  const [startTimeDetail, setStartTimeDetail] = useState(new Date())
  const [title, setTitle] = useState<string>('')
  const styleTile = { minHeight: 150, maxHeight: 150 }
  const [checkStatus, setCheckStatus] = useState<any>()
  const [content, setContent] = useState<string>('')
  const styleText = { minHeight: 380, maxHeight: 380 }
  const history = useHistory()
  const { id } = useParams<IParamTypes>()
  const [auditLogCreated, setAuditLogCreated] = useState<any>()
  const [auditLogUpdated, setAuditLogUpdated] = useState<any>()
  const [createdAt, setCreatedAt] = useState<any>()
  const [updatedAt, setUpdatedAt] = useState<any>()
  // modal delete
  const [modalDelete, setModalDelete] = useState({
    isModalVisible: false,
    content: t('modal.contentDelNoti')
  })

  const onClickBtnEdit = () => {
    const path = ADMIN_ROUTE.concat(routesInfoManagement.INFO_EDIT + `/${id}`)
    history.push(path)
  }
  // const checkDisable = () => {
  //   if (checkStatus === 1) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  const onClickBtnDel = () => {
    setModalDelete({
      ...modalDelete,
      isModalVisible: true
    })
  }

  const handleOnSubmitDelete = async () => {
    deleteNotification()
    setModalDelete({
      ...modalDelete,
      isModalVisible: false
    })
    // modal success
    const stateSuccess = {
      isVisible: true,
      content: t('modal.contentSuccessNoti'),
      isRedirect: true,
      href: ADMIN_ROUTE.concat(routesName?.INFO_MANAGEMENT)
    }
    await dispatch(SuccessAction({ ...stateSuccess }))
  }

  const deleteNotification = async () => {
    const params = {
      systemAlertId: id,
      type: 'delete'
    }
    dispatch(
      updateAlertAction(
        { ...params },
        (data) => {
          if (data?.result?.deletedAt) {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  const onClickBtnReturn = () => {
    history.goBack()
  }

  useEffect(() => {
    const params = {
      systemAlertId: id
    }
    dispatch(
      detailAlertAction(
        { ...params },
        (data) => {
          if (data?.result?.systemAlertId) {
            setTitle(data?.result?.title)
            setContent(data?.result?.content)
            setCheckStatus(data?.result?.statusNotification)
            setStartDateDetail(new Date(data?.result?.timePushNotification))
            setStartTimeDetail(new Date(data?.result?.timePushNotification))
            setCreatedAt(data?.result?.createdAt)
            setUpdatedAt(data?.result?.updatedAt)
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
    getAuditLog()
    // eslint-disable-next-line
  }, [])

  const getAuditLog = () => {
    const params: any = {
      recordId: Number(id),
      type: EAuditLogType.ALERT
    }
    dispatch(
      detailAuditLogAction(
        { ...params },
        async (data) => {
          const dataAuditLogs = await data?.result?.record;
          for (const dataAuditLog of dataAuditLogs) {

            if (dataAuditLog?.action === EAuditLogActionType.CREATE_ALERT) {

              setAuditLogCreated(dataAuditLog);
            } else if (dataAuditLog?.action === EAuditLogActionType.EDIT_ALERT) {

              setAuditLogUpdated(dataAuditLog);
            }
          }

          dispatch(SetLoadingAction(false))
        },
        true
      )
    )
  }
  const formatDate = (date: string) => {
    return moment(date).format('YYYY/MM/DD')
  }

  const getUpdatedAt = (): string => {
    if (auditLogUpdated?.createdAt) {
      return moment(auditLogUpdated?.createdAt).format('YYYY/MM/DD');
    }
    if (auditLogCreated?.createdAt) {
      return moment(auditLogCreated?.createdAt).format('YYYY/MM/DD');
    }
    return '';
  }

  return (
    <div className='bgrffffff' style={{ padding: '16px' }}>
      <div className='info-detail'>
        <div className='label-bold' tabIndex={-1}>
          {t('infoManagement.deliveryDateTime')}
        </div>
        <div style={{ display: 'flex' }}>
          <InputDateComponent
            refer={refDateDetail}
            state={startDateDetail}
            readOnly={true}
            onChangeDate={(date: Date) => setStartDateDetail(date)}
          />

          <InputTimeComponent
            refer={refTimeDetail}
            state={startTimeDetail}
            readOnly={true}
            onChangeDate={(date: Date) => setStartTimeDetail(date)}
          />
        </div>
        <div style={{ display: 'flex', margin: '25px 0px 10px' }}>
          <div className='label-bold'>{t('infoManagement.deliveryStatus')}</div>
          <div>
            {checkStatus === 1
              ? t('infoManagement.stsDelivered')
              : t('infoManagement.stsUndelivered')}
          </div>
        </div>
        <div className='label-bold'>{t('infoManagement.title')}</div>
        <InputTextAreaComponent
          state={title}
          style={styleTile}
          onChangeText={(e: React.ChangeEvent<HTMLTextAreaElement>) => setTitle(e.target.value)}
          disabled={true}
          placeholder={''}
        />
        <div className='label-bold' style={{ marginTop: '25px' }}>
          {t('infoManagement.text')} <span style={{ color: '#d7000f' }}>*</span>
        </div>
        <InputTextAreaComponent
          state={content}
          style={styleText}
          onChangeText={(e: React.ChangeEvent<HTMLTextAreaElement>) => setContent(e.target.value)}
          disabled={true}
          placeholder={''}
        />
        <Row style={{ marginBottom: "62px" }}>
          <Col span={12}>
            <Row className='row-spc-8' style={{ ...styleFieldSystem }}>
              <Col span={6} className='row-label' style={{fontWeight: 'bold'}}>
                {t('sideBar.order.orderItem.registeredPerson')}
              </Col>
              <Col span={12} className='row-content'>
                <TooltipComponent maxSlice={40} title={auditLogCreated?.username} />
              </Col>
            </Row>
            <Row className='row-spc-8' style={{ ...styleFieldSystem }}>
              <Col span={6} className='row-label' style={{fontWeight: 'bold'}}>
                {t('sideBar.order.orderItem.changer')}
              </Col>
              <Col span={12} className='row-content'>
                <TooltipComponent maxSlice={40} title={auditLogUpdated?.username ? auditLogUpdated?.username : auditLogCreated?.username} />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row className='row-spc-8' />
            <Row className='row-spc-8' style={{ ...styleFieldSystem }}>
              <Col span={6} className='row-label' style={{fontWeight: 'bold'}}>
                {t('sideBar.order.orderItem.createdAt')}
              </Col>
              <Col span={12} className='row-content'>
                {auditLogCreated?.createdAt ? formatDate(auditLogCreated?.createdAt) : ''}
              </Col>
            </Row>
            <Row className='row-spc-8' style={{ ...styleFieldSystem }} >
              <Col span={6} className='row-label' style={{fontWeight: 'bold'}}>
                {t('sideBar.order.orderItem.updatedAt')}
              </Col>
              <Col span={12} className='row-content'>
                {getUpdatedAt()}
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='view-btn-ft'>
          {ROLE_CRUD.includes(role) ? (
            <ButtonFooterComponent
              text={t('infoManagement.btnEdit')}
              classSty='btn-edit'
              onClickButton={onClickBtnEdit}
            // disabled={checkDisable()}
            />
          ) : null}
          {ROLE_CRUD.includes(role) ? (
            <ButtonFooterComponent
              text={t('infoManagement.btnDel')}
              classSty='btn-del'
              onClickButton={onClickBtnDel}
            />
          ) : null}
          <ButtonFooterComponent
            text={t('infoManagement.btnReturn')}
            classSty='btn-return'
            onClickButton={onClickBtnReturn}
          />
        </div>
      </div>

      {modalDelete?.isModalVisible ? (
        <ModalComponent
          isVisible={modalDelete?.isModalVisible}
          img={IconDeleteModal}
          content={modalDelete?.content}
          onCancelModal={() =>
            setModalDelete({
              ...modalDelete,
              isModalVisible: false
            })
          }
          btnCancel={t('modal.btnCancel')}
          btnSubmit={t('modal.btnDelete')}
          onSubmitModal={handleOnSubmitDelete}
        />
      ) : null}
    </div>
  )
}
const styleFieldSystem = {
  color: '#69696A',
  marginTop: '24px',
}

export default InfoDetail
