import 'styles/Information.scss'
import { useTranslation } from 'react-i18next'
import InputDateComponent from 'components/common/input/InputDateComponent'
import InputTimeComponent from 'components/common/input/InputTimeComponent'
import InputTextAreaComponent from 'components/common/input/InputTextAreaComponent'
import ButtonFooterComponent from 'components/common/button/ButtonFooterComponent'
import { useRef, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { AppState } from 'redux/reducers'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'
import {
  detailAlertAction,
  updateAlertAction
} from 'redux/actions/notifications/notificationAction'
import { originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import AccessDeniedComponent from 'views/pages/accessDenied/AccessDeniedComponent'
import { EDate } from 'interfaces/enums/EDate'
const diffTime = (timeEnd: any, timeStart: any) => {
  const diffTimeValue = moment
    .utc(moment(timeEnd, EDate.DATEJP).diff(moment(timeStart, EDate.DATEJP)))
    .valueOf()
  return diffTimeValue
}

const InfoEdit = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id }: { id: string } = useParams()
  const refDateDetail = useRef()
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  const [startDateDetail, setStartDateDetail] = useState(new Date())
  const refTimeDetail = useRef()
  const [startTimeDetail, setStartTimeDetail] = useState(new Date())
  const [detailInfo, setDetailInfo] = useState<number>(0)
  const [checkEditNoti, setCheckEditNoti] = useState<boolean>(false)
  const [errTime, setErrorTime] = useState<string>('')
  const [errText, setErrText] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const styleTile = { minHeight: 150, maxHeight: 150 }
  const [content, setContent] = useState<string>('')
  const styleText = { minHeight: 380, maxHeight: 380 }
  const history = useHistory()
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  const showSuccess = (text: string) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text,
      isRedirect: true,
      href: ADMIN_ROUTE.concat(routesName.INFO_MANAGEMENT)
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }

  const handleOnChangeDate = (date: Date) => {
    setStartDateDetail(date)
    const currentTime = moment().format(EDate.DATEJP)
    const concatTime = moment(date)
      .format('YYYY/MM/DD')
      .concat(' ' + moment(startTimeDetail).format('HH:mm'))
    const diffTimeValue = diffTime(currentTime, concatTime)
    if (diffTimeValue > 0 || date === null) {
      setErrorTime(t('errors.time'))
    } else {
      setErrorTime('')
    }
  }
  const handleOnChangeTime = (date: Date) => {
    setStartTimeDetail(date)
    const currentTime = moment().format(EDate.DATEJP)
    const concatTime = moment(startDateDetail)
      .format('YYYY/MM/DD')
      .concat(' ' + moment(date).format('HH:mm'))
    const diffTimeValue = diffTime(currentTime, concatTime)
    if (diffTimeValue > 0 || date === null) {
      setErrorTime(t('errors.time'))
    } else {
      setErrorTime('')
    }
  }

  const handleOnChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value)
    if (e.target.value.trim() === '' || e.target.value.length <= 0) {
      setErrText(t('infoManagement.error.text'))
    } else {
      setErrText('')
    }
  }
  const handleOnChangeTitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(e.target.value)
  }
  // check disable submit
  const checkDisabled = () => {
    if (content.trim().length > 0 && !errTime && !errText) {
      return false
    }
    return true
  }

  const onClickBtnSave = () => {
    const currentDateAndTime = moment().format(EDate.DATEJP)
    const concatTime = moment(startDateDetail)
      .format('YYYY/MM/DD')
      .concat(' ' + moment(startTimeDetail).format('HH:mm'))
    const time = new Date(concatTime).toLocaleString('en-US', {
      timeZone: 'UTC',
      hourCycle: 'h23'
    })
    if (
      (startDateDetail && startTimeDetail && concatTime && concatTime >= currentDateAndTime) ||
      detailInfo === 1
    ) {
      const params = {
        systemAlertId: id,
        content: content,
        title: title,
        type: 'update',
        timePushNotification: time
      }
      dispatch(
        updateAlertAction(
          { ...params },
          (data) => {
            if (data?.error) {
              if (data?.error?.code === 400) {
                setErrText(t(`errors.message.${data?.error?.data?.content[0]}`))
                dispatch(SetLoadingAction(false))
              } else {
                dispatch(SetLoadingAction(false))
              }
            } else {
              showSuccess(t('modal.contentSuccessEdit'))
            }
          },
          true
        )
      )
      // modal success
    } else {
      setErrorTime(t('errors.time'))
    }
  }
  const onClickBtnReturn = () => {
    history.push(ADMIN_ROUTE.concat(routesName.INFO_MANAGEMENT))
  }

  useEffect(() => {
    const params = {
      systemAlertId: id
    }
    dispatch(
      detailAlertAction(
        { ...params },
        (data) => {
          if (data?.result?.systemAlertId) {
            setTitle(data?.result?.title)
            setContent(data?.result?.content)
            setStartDateDetail(new Date(data?.result?.timePushNotification))
            setStartTimeDetail(new Date(data?.result?.timePushNotification))
            dispatch(SetLoadingAction(false))
            setDetailInfo(data?.result?.statusNotification)
            setCheckEditNoti(true)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
    // eslint-disable-next-line
  }, [id])

  return (
    <>
      {checkEditNoti ? (
        <div>
          {/* {detailInfo === 0 ? ( */}
          <div>
            {ROLE_CRUD.includes(role) ? (
              <div className='bgrffffff' style={{ padding: '16px' }}>
                <div className='info-detail'>
                  <div className='label-bold' tabIndex={-1}>
                    {t('infoManagement.deliveryDateTime')}
                  </div>
                  <div style={{ display: 'flex' }}>
                    <InputDateComponent
                      minDate={new Date()}
                      refer={refDateDetail}
                      state={startDateDetail}
                      readOnly={detailInfo === 0 ? false : true}
                      onChangeDate={(date: Date) => handleOnChangeDate(date)}
                    />
                    <InputTimeComponent
                      refer={refTimeDetail}
                      state={startTimeDetail}
                      readOnly={detailInfo === 0 ? false : true}
                      onChangeDate={(date: Date) => handleOnChangeTime(date)}
                    />
                  </div>
                  <div>
                    <div className='custom-error'>{errTime ? errTime : null}</div>
                  </div>
                  <div style={{ marginTop: 25 }} className='label-bold'>
                    {t('infoManagement.title')}
                  </div>
                  <InputTextAreaComponent
                    maxLength={160}
                    state={title}
                    style={styleTile}
                    onChangeText={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleOnChangeTitle(e)
                    }
                    disabled={false}
                    placeholder={t('infoManagement.plTitle')}
                  />
                  <div className='text-note'>{t('infoManagement.noteMaximumTitle')}</div>
                  <div className='label-bold' style={{ marginTop: '25px' }}>
                    {t('infoManagement.text')} <span style={{ color: '#d7000f' }}>*</span>
                  </div>
                  <InputTextAreaComponent
                    maxLength={3000}
                    state={content}
                    style={styleText}
                    onChangeText={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleOnChangeText(e)
                    }
                    disabled={false}
                    placeholder={t('infoManagement.plContent')}
                  />
                  <div>
                    <div className='custom-error'>{errText ? errText : null}</div>
                  </div>
                  <div className='text-note'>{t('infoManagement.noteMaximumContent')}</div>
                  <div className='view-btn-ft'>
                    <ButtonFooterComponent
                      text={t('infoManagement.btnSubmitEdit')}
                      classSty='btn-edit'
                      onClickButton={onClickBtnSave}
                      disabled={checkDisabled()}
                    />
                    <ButtonFooterComponent
                      text={t('infoManagement.btnCancelFilter')}
                      classSty='btn-return btn-cancel'
                      onClickButton={onClickBtnReturn}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <AccessDeniedComponent />
            )}
          </div>
          {/* ) : (
            <Redirect to={ADMIN_ROUTE.concat(routesName?.INFO_MANAGEMENT)} exact />
          )} */}
        </div>
      ) : null}
    </>
  )
}

export default InfoEdit
