import { IAuthState, AuthActionTypes } from 'redux/actions/auth/authTypes'
import { EAuthTypes } from 'redux/actions/auth/EAuthAction'

const initialState: IAuthState = {
  authed: false,
  expiresIn: 0,
  accessToken: '',
  role: '',
  username: '',
  passwordChanged: false,
  tokenTimeout: false,
}

const authReducer = (state = initialState, action: AuthActionTypes): IAuthState => {
  switch (action.type) {
    case EAuthTypes.UPDATE_AUTH:
      return {
        ...state,
        authed: action.authed,
        expiresIn: action.expiresIn,
        accessToken: action.accessToken,
        role: action.role,
        username: action.username
      }
    case EAuthTypes.LOGOUT:
      return { ...initialState }
    case EAuthTypes.PASSWORD_CHANGED:
      return { ...state, passwordChanged : true }
    case EAuthTypes.TOKEN_TIMEOUT:
      return { ...state, tokenTimeout: true }
    default:
      return state
  }
}

export default authReducer
