/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IconDeleteBtn, IconDeleteModal } from 'assets'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'
import GroupButtonActionSearchComponent from 'components/common/button/GroupButtonActionSearchComponent'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import SelectComponent from 'components/common/select/SelectComponent'
import ModalComponent from 'components/modal/ModalComponent'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import 'styles/User.scss'
import { showTotalRecord } from 'utils/Functions'
import { defaultUserStatus, headersCsvUserList } from './defaultValue'
import {
  deleteUserAction,
  getUsersListAction,
  updateUserStatusAction
} from 'redux/actions/users/usersAction'
import TableComponent from 'components/common/table/TableComponent'
import { AppState } from 'redux/reducers'
import { originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import InputComponent from 'components/common/input/InputComponent'
import ExportCsvComponent from 'components/common/exportCsv/ExportCsvComponent'
import { IListUser } from 'services/params/user/IUserParam'
import Collapsed from '../serialNo/serialNoManagement/components/collapsed'
interface IDataType {
  key: string
  customerName: string
  customerKey: string
  userName: string
  email: string
  emailVerifiedAt: string
  roleName: string
  parentID: string | any
}

const ROLES = {
  MASTER: 'master',
  USER: 'user'
}

const USER_STATUS = {
  ALL: 'All',
  ACTIVE: 'Active',
  INACTIVE: 'NonActive'
}

const ROLE_NAME = {
  MASTER: '顧客管理者',
  ADMIN: '管理者ユーザ',
  USER: '一般ユーザ'
}

function UserManagement() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [dataTable, setDataTable] = useState<IDataType[]>([])
  const [idItem, setIdItem] = useState<string>('')
  const [roleUser, setRoleUser] = useState<string>('')
  const [userSystemStatus, setUserSystemStatus] = useState<string>('all')
  const dispatch = useDispatch()
  const [csvData, setCsvData] = useState<any>([])
  // modal
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  const [totalItem, setTotalItem] = useState<number>(0)

  const columns: ColumnsType<IDataType> = [
    {
      title: t('sensorManagementScreen.table.column.no'),
      dataIndex: '',
      render: (text: any, row: any, index: any) => {
        return <span>{(currentPage - 1) * pageSize + index + 1}</span>
      },
      align: 'left',
      width: '5%'
    },
    {
      title: t('userManagementScreen.table.column.customerName'),
      dataIndex: 'userName',
      render: (_: object, record: { customerName: string }) => {
        return <TooltipComponent title={record.customerName} />
      }
    },
    {
      title: t('userManagementScreen.table.column.customerCode'),
      dataIndex: 'userName',
      render: (_: object, record: { customerKey: string }) => {
        return <TooltipComponent title={record.customerKey} />
      },
      width: '15%'
    },
    {
      title: t('userManagementScreen.table.column.userName'),
      dataIndex: 'userName',
      render: (_: object, record: { userName: string }) => {
        return <TooltipComponent title={record?.userName} />
      }
    },
    {
      title: t('userManagementScreen.table.column.mailAddress'),
      dataIndex: 'email'
    },
    {
      title: t('userManagementScreen.table.column.userRole'),
      dataIndex: 'registerDate',
      render: (_: object, record: { roleName: string, parentID: any }) => {
        return <span>{getRoleName(record.roleName, record.parentID)}</span>
      }
    },
    {
      title: t('userManagementScreen.table.column.userStatus'),
      dataIndex: 'userStatus',
      render: (_: object, record: { emailVerifiedAt: string }) => {
        return <span>{getUserStatus(record.emailVerifiedAt)}</span>
      }
    },
    ROLE_CRUD.includes(role)
      ? {
          title: t('userManagementScreen.table.status.deleteBtn'),
          dataIndex: '',
          width: '70px',
          align: 'center',
          render: (_: object, record: any) => {
            return (
              <div>
                <img
                  onClick={() => handleOnClickDelete(record)}
                  src={IconDeleteBtn}
                  alt='delete'
                  className='w42x-h28x pointer'
                />
              </div>
            )
          }
        }
      : {
          width: '0px'
        }
  ]

  const getRoleName = (roleCode: string, parentID: string | any) => {
    if (roleCode.includes(ROLES.MASTER)) {
      if(parentID) {
        return ROLE_NAME.ADMIN
      } else {
        return ROLE_NAME.MASTER
      }
    } else {
      return ROLE_NAME.USER
    }
  }

  const getUserStatus = (emailVerifiedAt: any) => {
    return emailVerifiedAt ? '利用中' : '会員未登録'
  }

  // get list users data
  const convertDataUser = (data: any) => {
    if (data) {
      data = data.map((item: any, index: number) => {
        return {
          key: item?.userId,
          id: item?.userId,
          userName: item?.username,
          email: item?.email,
          emailVerifiedAt: item?.emailVerifiedAt,
          roleName: item?.role?.roleName,
          customerName: item?.customer?.customerName,
          customerKey: item?.customer?.customerKey,
          parentID: item?.parentUserId
        }
      })
    }
    setDataTable(data)
  }
  useEffect(() => {
    handleApiGetUserList()
  }, [])

  const getStatusToCallAPI = (isActive: string) => {
    switch (isActive) {
      case 'true':
        return USER_STATUS.ACTIVE
      case 'false':
        return USER_STATUS.INACTIVE
      default:
        return USER_STATUS.ALL
    }
  }

  const handleApiGetUserList = (
    isLoading: boolean = true,
    pageParam: number | any = 1,
    pageSizeParam: number | any = 10,
    mail: string = form.getFieldValue('email') ? form.getFieldValue('email') : '',
    name: string = form.getFieldValue('customerName') ? form.getFieldValue('customerName') : '',
    code: string = form.getFieldValue('customerCode') ? form.getFieldValue('customerCode') : '',
    isActive: string = userSystemStatus
  ) => {
    const params: IListUser = {
      userEmail: mail,
      customerName: name,
      customerCode: code,
      status: getStatusToCallAPI(isActive),
      pageNo: pageParam,
      pageSize: pageSizeParam
    }
    dispatch(
      getUsersListAction(
        params,
        (data) => {
          if (data && data?.result) {
            if(pageParam && pageSizeParam) { // If not get data to export csv. Page != null & Size != null
              convertDataUser(data?.result?.record)
              setTotalItem(data.result.totalRecord)
            } else {
              convertCsvData(data.result.record)
            }
          }
        },
        isLoading
      )
    )
  }

  useEffect(() => {
    setCsvData([])
    setTimeout(() => {
      if (dataTable.length > 0) {
        getCsvData()
      }
    }, 1500)
  }, [dataTable])

  const getCsvData = () => {
    handleApiGetUserList(false, null, null)
  }

  const convertCsvData = (data: []) => {
    const list = data.map((item: any, index: number) => {
      return {
        customerName: item?.customer?.customerName,
        customerCode: item?.customer?.customerKey,
        userName: item?.username,
        email: item?.email,
        userStatus: getUserStatus(item?.emailVerifiedAt),
        userRole: getRoleName(item?.role?.roleName, item?.parentUserId)
      }
    })
    setCsvData(list)
  }

  const handleOnChangePage = (pageChange: number) => {
    dispatch(SetLoadingAction(true))
    setCurrentPage(pageChange)
    setTimeout(() => {
      handleApiGetUserList(false, pageChange, pageSize)
    }, 750);
  }
  const handleOnChangePageSize = (size: any) => {
    handleApiGetUserList(true, 1, size)
    setPageSize(size)
    setCurrentPage(1)
  }
  // action delete user
  const handleOnClickDelete = (record: any) => {
    setIdItem(record?.id)
    setRoleUser(record?.roleName)
    setModal({
      ...modal,
      isVisible: true,
      type: 'delete-user',
      isComponent: false,
      img: IconDeleteModal,
      btnSubmit: t('modal.btnDelete'),
      content: t('modal.userManagement.deleteUser'),
      hideBtnFooter: false
    })
  }
  // submit action
  const deleteUser = async () => {
    const request = {
      id: idItem,
      role: roleUser
    }
    dispatch(
      deleteUserAction(
        {
          ...request
        },
        (data) => {
          if (data?.result?.deletedAt) {
            reloadTableData()
            showSuccess(t('modal.userManagement.success.deleteUser'))
          }
        },
        true
      )
    )
  }

  const reloadTableData = () => {
    const maxPage = Math.ceil((totalItem - 1) / pageSize)
    const calPage = currentPage < maxPage ? currentPage : maxPage
    const page = calPage === 0 ? 1 : calPage
    setCurrentPage(page)
    handleApiGetUserList(true, page, pageSize)
  }

  // update user active
  const activeUser = async () => {
    const request = {
      id: idItem,
      statusUser: true,
      role: roleUser
    }
    dispatch(
      updateUserStatusAction(
        { ...request },
        (data) => {
          if (data?.result) {
            showSuccess(t('modal.userManagement.success.activeUser'))
          }
        },
        true
      )
    )
  }
  // update user stop
  const stopUser = async () => {
    const request = {
      id: idItem,
      statusUser: false,
      role: roleUser
    }
    dispatch(
      updateUserStatusAction(
        { ...request },
        (data) => {
          if (data?.result) {
            showSuccess(t('modal.userManagement.success.stopUser'))
          }
        },
        true
      )
    )
  }
  const showSuccess = (text: string) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }
  // submit modal
  async function handleOnSubmitModal() {
    // check modal?.type dispatch action
    switch (modal?.type) {
      case 'stop-user':
        stopUser()
        break
      case 'active-user':
        activeUser()
        break
      case 'delete-user':
        deleteUser()
        break
      default:
        break
    }
  }

  // handle submit search
  const onClickButtonSubmitSearch = () => {
    handleApiGetUserList(true, 1, pageSize)
    setCurrentPage(1)
  }
  // handle clear filter
  const onClickButtonCancel = () => {
    setCurrentPage(1)
    setUserSystemStatus('all')
    form.resetFields()
    handleApiGetUserList(true, 1, pageSize, '', '', '', 'All')
  }
  // state select option type sensor
  const handleOnChangeSelectUserStatus = (valueSensorType: string) => {
    setUserSystemStatus(valueSensorType)
  }

  return (
    <div>
      <Collapsed>
        <div className=''>
        <div className='user-management'>
          <div className=''>
            <Form form={form}>
              <Row>
                <Col span={12}>
                  <InputComponent
                    name='customerName'
                    type='text'
                    label='顧客名'
                    style={fieldWidth}
                    maxLength={64}
                  />
                </Col>
                <Col span={12}>
                  <InputComponent
                    name='email'
                    type='text'
                    label='メールアドレス'
                    maxLength={64}
                    style={fieldWidth}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <InputComponent
                    name='customerCode'
                    type='text'
                    label='お客様コード'
                    maxLength={64}
                    style={fieldWidth}
                  />
                </Col>
                <Col span={12} className='dropdown'>
                  <SelectComponent
                    lable={t('userManagementScreen.lableSystemStatus')}
                    defaultOption={userSystemStatus}
                    dataOption={defaultUserStatus}
                    onChangeSelect={handleOnChangeSelectUserStatus}
                    styleSelect={fieldWidth}
                  />
                </Col>
              </Row>
            </Form>
            <div className='mg_0_16x'>
              <div className='text-align-right'>
                <GroupButtonActionSearchComponent
                  onClickBtnSubmit={onClickButtonSubmitSearch}
                  onClickBtncancel={onClickButtonCancel}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </Collapsed>
      <div className='bgrffffff'>
        <div className='user-management'>
          <div className='ss-table'>
            <div className='mg_0_16x'>
              <Row>
                <Col span={8}>
                  <div className='pd_16x_0x'>
                    <Row>
                      <ExportCsvComponent
                        headers={headersCsvUserList}
                        data={csvData}
                        fileName='ユーザ一覧'
                        isDisabled={csvData.length < 1}
                        title={'CSVエクスポート'}
                        btnClass={'csvBtn'}
                      />
                      {/* <div
                        className={`${
                          selectedRowKeys.length < 1 ? 'remove-btn-disable' : ''
                        } remove-btn`}>
                        <img src={IconDeleteBtn} alt='remove user' />
                        <span className='remove-title'>チェックして一括削除</span>
                      </div> */}
                    </Row>
                  </div>
                </Col>
                <Col span={16}>
                  <div className='d-flex justify-content-flex-end align-items-center mg-pagination'>
                    <div className='d-flex justify-content-flex-end font-w-bold pd_16x_0x total-record'>
                      {showTotalRecord(totalItem)}
                    </div>
                    <div className='sensor-pagination'>
                      <PaginationComponent
                        onChangePageSize={(size) => handleOnChangePageSize(size)}
                        total={totalItem}
                        onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                        pageSize={pageSize}
                        isShowSizeChanger={false}
                        currentpage={currentPage}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className='custom-table'>
              <TableComponent isSearch={true} columns={columns} dataSource={dataTable} />
            </div>
            <div className='mg_0_16x'>
              <div className='mg-pagination'>
                <PaginationComponent
                  onChangePageSize={(size) => handleOnChangePageSize(size)}
                  total={totalItem}
                  onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                  pageSize={pageSize}
                  isShowSizeChanger={true}
                  currentpage={currentPage}
                />
              </div>
            </div>
            <div className='btn-return text-align-center'>
              <ButtonReturnComponent />
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        isVisible={modal?.isVisible}
        isComponent={modal?.isComponent}
        img={modal?.img}
        content={modal?.content}
        component={modal?.component}
        btnSubmit={modal?.btnSubmit}
        btnCancel={modal?.btnCancel}
        onCancelModal={() => setModal({ ...modal, isVisible: false })}
        onSubmitModal={handleOnSubmitModal}
        hideBtnFooter={modal?.hideBtnFooter}
      />
    </div>
  )
}

const fieldWidth = {
  width: '97.5%',
}
export default UserManagement
