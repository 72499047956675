import { Input, Form } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { CSSProperties } from 'react'
import 'styles/Input.scss'

type Props = {
  placeholder?: string
  type?: string
  label?: string
  size?: SizeType
  style?: CSSProperties
  labelStyle?: CSSProperties
  name: any
  rules?: any
  maxLength?: number
  editable?: boolean
  disabled?: boolean
  value?: string
  isReq?: boolean
  prefix?: any
  onBlur?: () => void
  onFocus?: () => void
  onChange?: any
  onChangeValue?: any
  minValue?: any
  maxValue?: any
  numberPattern?: any
}

const InputComponent = ({
  placeholder,
  type,
  style,
  label,
  name,
  rules,
  maxLength,
  disabled,
  value,
  isReq,
  prefix,
  onBlur,
  onFocus,
  onChange,
  onChangeValue,
  minValue,
  maxValue,
  labelStyle,
  numberPattern
}: Props) => {
  return (
    <div className='input-component'>
      {label ? (
        <div className='label-mb font-w-bold' style={labelStyle}>
          {label}
          {isReq && <span style={{ color: '#E93A44', fontSize: 14 }}>&nbsp;*</span>}
        </div>
      ) : null}
      <Form.Item name={name} rules={rules}>
        {type !== 'password' ? (
          <Input
            maxLength={maxLength}
            placeholder={placeholder}
            style={{ ...style }}
            disabled={disabled}
            value={value}
            prefix={prefix}
            onFocus={onFocus}
            onBlur={onBlur}
            type={type}
            min={minValue}
            max={maxValue}
            onKeyUp={onChange}
            onChange={onChangeValue}
            pattern={numberPattern}
          />
        ) : (
          <Input.Password
            maxLength={maxLength}
            placeholder={placeholder}
            style={{ ...style }}
            disabled={disabled}
            value={value}
          />
        )}
      </Form.Item>
    </div>
  )
}

export default InputComponent
