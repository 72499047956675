import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import { checkTokenExistAction } from 'redux/actions/users/usersAction'
import { routesName } from 'views/routes/routes'
import VerifyAdminComponent from './VerifyAdminComponent'

const VerifyAdmin = () => {
  const { token }: { token: string } = useParams()
  const [validToken, setValidToken] = useState<string>('check')
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    const params = {
      token
    }
    dispatch(SetLoadingAction(true))
    dispatch(
      checkTokenExistAction(
        { ...params },
        (data) => {
          if (data?.code && data?.code === 401) {
            dispatch(SetLoadingAction(false))
            setValidToken('invalid')
          } else if (data?.result) {
            setValidToken('valid')
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
    // eslint-disable-next-line
  }, [])
  return (
    <>
      {validToken === 'check' ? null : validToken === 'invalid' ? (
        history.push(routesName.EXPIRED)
      ) : (
        <VerifyAdminComponent />
      )}
    </>
  )
}

export default VerifyAdmin
