import { ISensorType } from 'interfaces/sensor/ISensorType'

const convertLteLoraToId = (key: string) => {
  switch (key) {
    case 'lte':
      return 2
    case 'lora':
      return 1
    default:
      return ''
  }
}

const convertLetLoraToName = (id: string | number) => {
  switch (id) {
    case '1':
    case 1:
      return 'LoRa'
    case '2':
    case 2:
      return 'LTE-M'
    default:
      return ''
  }
}

const getSensorTypeByValue = (sensorType: ISensorType[], value: string) => {
  const type = sensorType.find((item) => item.value === value)
  if (type) {
    return type.name
  }
  return ''
}

const convertSensorType = (defaultTypeSensor: ISensorType[], value: string) => {
  switch (value) {
    case defaultTypeSensor[0]?.value:
      return '01'
    case defaultTypeSensor[1]?.value:
      return '02'
    case defaultTypeSensor[2]?.value:
      return '03'
    default:
      return ''
  }
}

const convertLteLora = (defaultLteOrLora: ISensorType[], value: string) => {
  switch (value) {
    case defaultLteOrLora[0]?.value:
      return 'L'
    case defaultLteOrLora[1]?.value:
      return 'R'
    default:
      return ''
  }
}

const convertIdToChacracter = (id: string) => {
  switch (id) {
    case '1':
    case 'lora':
      return 'R'
    case '2':
    case 'lte':
      return 'L'
    default:
      return ''
  }
}

const getValueByKey = (arr: any[], valueGet: string, key: string) => {
  const result = arr.find((item) => item[valueGet] === key)
  return result
}

const comunicationType: any[] = [
  { id: 1, name: 'LTE-M(KDDI)' },
  { id: 2, name: 'LoRa' },
  { id: 3, name: 'LTE-M(BG96)' },
  { id: 4, name: 'LTE(EG91)' }
]

const sensorType: any[] = [
  { id: 1, name: '水田' },
  { id: 2, name: '畑作' },
  { id: 3, name: '気象 ' },
  { id: 4, name: 'データ収集装置' }
]

const SERVICE_ERROR_ID = {
  FRE00027: 'FRE00027'
}

const SERVICE_ERROR_MSG = {
  FRE00027: '機器種別が一致しない。'
}

const CONFIRMED_STATUS = {
  TRUE: '確認済み',
  FALSE: '未確認'
}

export {
  convertLteLoraToId,
  convertLetLoraToName,
  getSensorTypeByValue,
  convertSensorType,
  convertLteLora,
  getValueByKey,
  convertIdToChacracter,
  comunicationType,
  sensorType,
  SERVICE_ERROR_ID,
  SERVICE_ERROR_MSG,
  CONFIRMED_STATUS
}
