import { IPropsPagination } from 'interfaces/sensor/IPropsPagination'
import { Pagination, Select, ConfigProvider } from 'antd'
import 'styles/Pagination.scss'
import { IconEndPage, IconEndPageDisabled, IconTopPage, IconTopPageDisable } from 'assets'
import { defaultOptionPageSize } from 'utils/Functions'
import { useTranslation } from 'react-i18next'
import ja_JP from 'antd/es/locale/ja_JP'

const { Option } = Select

function PaginationComponent({
  onChangePageSize,
  total,
  onChangePage,
  pageSize,
  isShowSizeChanger,
  currentpage
}: IPropsPagination) {
  const { t } = useTranslation()
  const handleChangeOption = (value: number) => {
    if (onChangePageSize) {
      onChangePageSize(value)
    }
  }
  const handleOnClickTopPage = () => {
    if (currentpage !== 1) {
      if (onChangePage) {
        onChangePage(1)
      }
    }
  }
  const handleOnClickEndPage = () => {
    const totalPage = Math.ceil(total / pageSize)
    if (currentpage !== totalPage) {
      if (onChangePage) {
        onChangePage(totalPage)
      }
    }
  }
  const onChange = (pageNumber: number) => {
    if (onChangePage) {
      onChangePage(pageNumber)
    }
  }
  return (
    <>
      {total ? (
        <>
          <div className='d-flex justify-content-flex-end custom-pagination'>
            {isShowSizeChanger ? (
              <div className='select-page-size d-flex ss-drop-drown'>
                <div className='select-option'>
                  <Select defaultValue={pageSize} onChange={handleChangeOption}>
                    {defaultOptionPageSize.map((item) => (
                      <Option key={item?.id} value={item?.value}>
                        {item?.value}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className='d-flex align-items-center displayed-results'>
                  {t('sensorManagementScreen.displayResult')}
                </div>
              </div>
            ) : null}
            <div className='pagination-result d-flex align-items-center'>
              <div>
                {currentpage === 1 ? (
                  <img className='icon cursor-not-allow' src={IconTopPageDisable} alt='top-page' />
                ) : (
                  <img
                    onClick={handleOnClickTopPage}
                    className='icon pointer'
                    src={IconTopPage}
                    alt='top-page'
                  />
                )}
              </div>
              <ConfigProvider locale={ja_JP}>
                <Pagination
                  size='small'
                  onChange={onChange}
                  total={total}
                  current={currentpage}
                  pageSize={pageSize}
                  showSizeChanger={false}
                />
              </ConfigProvider>
              <div>
                {currentpage === Math.ceil(total / pageSize) ? (
                  <img className='icon cursor-not-allow' src={IconEndPageDisabled} alt='end-page' />
                ) : (
                  <img
                    onClick={handleOnClickEndPage}
                    className='icon pointer'
                    src={IconEndPage}
                    alt='end-page'
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
export default PaginationComponent
