import {
  IChangePasswordParams,
  ICheckTokenExistParams,
  IForgotPasswordParams,
  IResetPasswordParams,
  IVerifyParams
} from 'services/params/ILoginParams'
import {
  ICreateUser,
  IDeleteUser,
  IListAdmin,
  IListUser,
  IUpdateUserStatus
} from 'services/params/user/IUserParam'
import { IUpdateUserById } from 'services/requests/IUpdateUserById'
import verifyRequest from 'services/requests/VerifyRequest'
import createUserRequest, {
  checkTokenExistRequest,
  deleteUserRequest,
  forgotPasswordRequest,
  getListAdminRequest,
  getListUserRequest,
  resetPasswordRequest,
  changePasswordRequest,
  updateUserStatus
} from 'services/requests/user/userRequest'
import { GET_USER_BY_ID, UPDATE_USER_BY_ID, configRequest } from '..'
import instance from '../v1'

class UsersApis {
  createUser = (createParam: ICreateUser) => {
    const requests = createUserRequest(createParam)
    return configRequest(requests)
  }
  getUsersList = (request: IListUser) => {
    const requests = getListUserRequest(request)
    return configRequest(requests)
  }
  deleteUser = (request: IDeleteUser) => {
    const requests = deleteUserRequest(request)
    return configRequest(requests)
  }
  updateUserStatus = (request: IUpdateUserStatus) => {
    const requests = updateUserStatus(request)
    return configRequest(requests)
  }
  getAdminList = (request: IListAdmin) => {
    const requests = getListAdminRequest(request)
    return configRequest(requests)
  }
  verifyAdminAccount = (request: IVerifyParams) => {
    const requests = verifyRequest(request)
    return configRequest(requests)
  }
  forgotPassword = (request: IForgotPasswordParams) => {
    const requests = forgotPasswordRequest(request)
    return configRequest(requests)
  }
  resetPassword = (request: IResetPasswordParams) => {
    const requests = resetPasswordRequest(request)
    return configRequest(requests)
  }
  changePass = (request: IChangePasswordParams) => {
    const requests = changePasswordRequest(request)
    return configRequest(requests)
  }
  checkTokenExist = (request: ICheckTokenExistParams) => {
    const requests = checkTokenExistRequest(request)
    return configRequest(requests)
  }

  getUserById = (id: number) => instance.get(GET_USER_BY_ID.concat('/').concat(id.toString()))
  updateUserById = (user: IUpdateUserById) =>
    instance.put(UPDATE_USER_BY_ID.concat('/').concat(user.id.toString()), user)
}

export const UsersApi = new UsersApis()
