import i18n from 'configs/i18n'
import { maxNumberScaleValue, unitFormula } from 'utils/maxLength'
const validatorAttribute = {
  scaleValue: [
    () => ({
      validator(_: object, value: number) {
        if (value?.toString()?.length > 0) {
          if (value < 0) {
            return Promise.reject(
              new Error(
                i18n.t('errors.message.FRE00002', {
                  0: i18n.t('caculation.table.sensorIndex')
                })
              )
            )
          } else if (value > maxNumberScaleValue) {
            return Promise.reject(new Error('0～5までの整数を入力してください。'))
          } else {
            return Promise.resolve()
          }
        }
        return Promise.reject(
          new Error(
            i18n.t('errors.message.FRE00001', {
              0: i18n.t('caculation.table.sensorIndex')
            })
          )
        )
      }
    })
  ],
  unit: [
    () => ({
      validator(_: object, value: string) {
        if (value.trim().length > 0) {
          if (value.trim().length > unitFormula) {
            return Promise.reject(new Error('入力長を超えました'))
          }
          return Promise.resolve()
        }
        return Promise.reject(
          new Error(
            i18n.t('errors.message.FRE00001', {
              0: i18n.t('caculation.table.sensorUnit')
            })
          )
        )
      }
    })
  ],
  settingTarget: [
    () => ({
      validator(_: object, value: string) {
        if (value.trim().length > 0) {
          if (value.trim().length > 64) {
            return Promise.reject(new Error('入力長を超えました'))
          }
          return Promise.resolve()
        }
        return Promise.reject(
          new Error(
            i18n.t('errors.message.FRE00001', {
              0: i18n.t('caculation.table.sensorSpecs')
            })
          )
        )
      }
    })
  ],
  conversionFormula: [
    () => ({
      validator(_: object, value: string) {
        if (value.trim().length <= 0) {
          return Promise.reject(new Error(i18n.t('errors.formulaBlank')))
        }
        return Promise.resolve()
      }
    })
  ]
}
export { validatorAttribute }
