import i18n from 'configs/i18n'
import { ConvertTime } from 'utils/Functions'
import { Link } from 'react-router-dom'
import { ADMIN_ROUTE, routesInfoManagement, routesSensorManagement } from 'views/routes/routes'
import { IconDashEdit } from 'assets'
import { defaultShowBlank } from '../sensor/defaultValue'
import { convertTimePushAtData } from './convertData'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'

// render colums communication
const columnsSensorCommunication = [
  {
    title: i18n.t('tableCommunicationErr.no'),
    dataIndex: 'no',
    width: '8%'
  },
  {
    title: i18n.t('tableCommunicationErr.deviceID'),
    dataIndex: 'deviceID',
    width: '17%',
    render: (_: object, record: { key: string; deviceID: string }) => {
      return (
        <div className='pointer custom-a cls_3EACFF text-underline'>
          <Link
            to={ADMIN_ROUTE.concat(routesSensorManagement.SENSOR_DETAIL + `/${record?.deviceID}`)}>
            {record?.deviceID}
          </Link>
        </div>
      )
    }
  },
  {
    title: i18n.t('tableCommunicationErr.sensorType'),
    dataIndex: 'sensorType',
    width: '17%'
  },
  {
    title: i18n.t('tableCommunicationErr.lteOrLora'),
    dataIndex: 'lteOrLora',
    width: '17%',
    render: (_: object, record: { lteOrLora: string }) => {
      return <span>{record?.lteOrLora}</span>
    }
  },
  {
    title: i18n.t('tableCommunicationErr.lastDate'),
    dataIndex: 'lastDate',
    width: '20%',
    render: (_: object, record: { lastDate: string }) => {
      return (
        <span>{record?.lastDate ? convertTimePushAtData(record?.lastDate) : defaultShowBlank}</span>
      )
    }
  },
  {
    title: i18n.t('tableCommunicationErr.abnormalOccurrencePast'),
    dataIndex: 'abnormalOccurrencePast',
    render: (_: object, record: { abnormalOccurrencePast: string }) => {
      return (
        <span>
          {record?.abnormalOccurrencePast ? record?.abnormalOccurrencePast : defaultShowBlank}
        </span>
      )
    }
  }
]
// render colums sensor battery
const columnsSensorBattery = [
  {
    title: i18n.t('tableBattery.no'),
    dataIndex: 'no',
    width: '8%'
  },
  {
    title: i18n.t('tableBattery.deviceID'),
    dataIndex: 'deviceID',
    width: '17%',
    render: (_: object, record: { key: string; deviceID: string }) => {
      return (
        <div className='pointer custom-a cls_3EACFF text-underline'>
          <Link
            to={ADMIN_ROUTE.concat(routesSensorManagement.SENSOR_DETAIL + `/${record?.deviceID}`)}>
            {record?.deviceID}
          </Link>
        </div>
      )
    }
  },
  {
    title: i18n.t('tableBattery.sensorType'),
    dataIndex: 'sensorType',
    width: '17%'
  },
  {
    title: i18n.t('tableBattery.lteOrLora'),
    dataIndex: 'lteOrLora',
    width: '17%',
    render: (_: object, record: { lteOrLora: string }) => {
      return <span>{record?.lteOrLora}</span>
    }
  },
  {
    title: i18n.t('tableBattery.lastDate'),
    dataIndex: 'lastDate',
    width: '20%',
    render: (_: object, record: { lastDate: string }) => {
      return (
        <span>{record?.lastDate ? convertTimePushAtData(record?.lastDate) : defaultShowBlank}</span>
      )
    }
  },
  {
    title: i18n.t('tableBattery.batteryDroppedDate'),
    dataIndex: 'batteryDroppedDate',
    render: (_: object, record: { batteryDroppedDate: string }) => {
      return (
        <span>
          {record?.batteryDroppedDate ? ConvertTime(record?.batteryDroppedDate) : defaultShowBlank}
        </span>
      )
    }
  }
]
// render colums sensor of outages
const columnsSensorOfOutages = [
  {
    title: i18n.t('tableOutages.no'),
    dataIndex: 'no',
    width: '8%'
  },
  {
    title: i18n.t('tableOutages.deviceID'),
    dataIndex: 'deviceID',
    width: '17%',
    render: (_: object, record: { key: string; deviceID: string }) => {
      return (
        <div className='pointer custom-a cls_3EACFF text-underline'>
          <Link
            to={ADMIN_ROUTE.concat(routesSensorManagement.SENSOR_DETAIL + `/${record?.deviceID}`)}>
            {record?.deviceID}
          </Link>
        </div>
      )
    }
  },
  {
    title: i18n.t('tableOutages.sensorType'),
    dataIndex: 'sensorType',
    width: '17%'
  },
  {
    title: i18n.t('tableOutages.lteOrLora'),
    dataIndex: 'lteOrLora',
    width: '17%',
    render: (_: object, record: { lteOrLora: string }) => {
      return <span>{record?.lteOrLora}</span>
    }
  },
  {
    title: i18n.t('tableOutages.dateTimeOfOutages'),
    dataIndex: 'dateTimeOfOutages',
    render: (_: object, record: { dateTimeOfOutages: string }) => {
      return ConvertTime(record?.dateTimeOfOutages)
    }
  }
]
// render colums notification
const defaultStatusNotification = {
  delivered: 'Delivered',
  UnDelivered: 'UnDelivered'
}
const columnsNotification = [
  {
    title: i18n.t('tableNotification.no'),
    dataIndex: 'no',
    width: '8%'
  },
  {
    title: i18n.t('tableNotification.dateTime'),
    dataIndex: 'dateTime',
    width: '17%',
    render: (_: object, record: { dateTime: string }) => {
      return ConvertTime(record?.dateTime)
    }
  },
  {
    title: i18n.t('tableNotification.deliveryStatus'),
    dataIndex: 'deliveryStatus',
    width: '17%',
    render: (_: object, record: { deliveryStatus: string }) => {
      return (
        <span>
          {defaultStatusNotification?.delivered === record?.deliveryStatus
            ? i18n.t('tableNotification.delivered')
            : i18n.t('tableNotification.UnDelivered')}
        </span>
      )
    }
  },
  {
    title: i18n.t('tableNotification.subject'),
    dataIndex: 'subject',
    width: '17%',
    render: (_: object, record: { subject: string }) => {
      return (
        <>{record?.subject ? <TooltipComponent maxSlice={20} title={record?.subject} /> : ''}</>
      )
    }
  },
  {
    title: i18n.t('tableNotification.content'),
    dataIndex: 'content',
    width: '20%',
    render: (_: object, record: { content: string }) => {
      return (
        <>{record?.content ? <TooltipComponent maxSlice={30} title={record?.content} /> : ''}</>
      )
    }
  },
  {
    title: i18n.t('tableNotification.detail'),
    dataIndex: 'detail',
    width: '50px',
    align: 'center',
    render: (_: object, record: { key: string }) => {
      return (
        <div>
          <Link to={ADMIN_ROUTE.concat(routesInfoManagement.INFO_DETAIL + `/${record?.key}`)}>
            <img src={IconDashEdit} className='w42x-h28x' alt='edit' />
          </Link>
        </div>
      )
    }
  }
]
export {
  columnsSensorBattery,
  columnsSensorCommunication,
  columnsSensorOfOutages,
  columnsNotification
}
