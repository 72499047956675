export enum ECustomerActions {
  GET_CUSTOMER_MASTER_DATA = 'GET_CUSTOMER_MASTER_DATA',
  ADD_CUSTOMER_MASTER_DATA = 'ADD_CUSTOMER_MASTER_DATA',
  CREAT_CUSTOMER = 'CREATE_CUSTOMER',
  GENERATE_CUSTOMER_KEY = 'GENERATE_CUSTOMER_KEY',
  LIST_CUSTOMER = 'LIST_CUSTOMER',
  DETAIL_CUSTOMER = 'DETAIL_CUSTOMER',
  DETAIL_CUSTOMER_REG = 'DETAIL_CUSTOMER_REG',
  DETAIL_CUSTOMER_AUDIT_LOG = 'DETAIL_CUSTOMER_AUDIT_LOG',
  EDIT_CUSTOMER = 'EDIT_CUSTOMER',
  DELETE_CUSTOMER = 'DELETE_CUSTOMER',
  DELETE_MASTER_SALES = 'DELETE_SALES',
  DELETE_MASTER_DEALER = 'DELETE_DEALER',
  RESEND_MAIL = 'RESEND_MAIL',
  DOWNLOAD_CSV = 'DOWNLOAD_CSV'
}
