import {
  ICopyFormula,
  ICreateFormula,
  IDeleteFormula,
  IDisplay,
  IGetDetailFormula,
  IUpdateFormula
} from 'services/params/display/IDisplay'
import {
  copyFormulaRequest,
  createFormulaRequest,
  deleteFormulaRequest,
  getDataDisplayRequest,
  getDtailFormulaRequest,
  updateFormulaRequest
} from 'services/requests/display/displayRequest'
import { configRequest } from '..'

class DisplayApis {
  getDataDisplay = (param: IDisplay) => {
    const requests = getDataDisplayRequest(param)
    return configRequest(requests)
  }
  deleteFormula = (param: IDeleteFormula) => {
    const requests = deleteFormulaRequest(param)
    return configRequest(requests)
  }
  copyFormula = (param: ICopyFormula) => {
    const requests = copyFormulaRequest(param)
    return configRequest(requests)
  }
  createFormula = (param: ICreateFormula) => {
    const requests = createFormulaRequest(param)
    return configRequest(requests)
  }
  getDetailFormula = (param: IGetDetailFormula) => {
    const requests = getDtailFormulaRequest(param)
    return configRequest(requests)
  }
  updateFormula = (param: IUpdateFormula) => {
    const requests = updateFormulaRequest(param)
    return configRequest(requests)
  }
}

export const DisplayApi = new DisplayApis()
