import i18n from 'configs/i18n'
import { regexPassword, regexUppercaseAndLowercase, regexSpecialCharacters } from 'utils/Functions'
const validChangePassword = {
  oldPassword: [
    {
      required: true,
      message: i18n.t('errors.changePasswordEmpty'),
    },
    {
      min: 6,
      message: i18n.t('errors.message.FRE00006', {
        0: 6
      })
    },
    {
      max: 32,
      message: '入力長を超えました '
    },
    {
      pattern: new RegExp(regexPassword),
      message: i18n.t('errors.message.FRE00002', {
        0: i18n.t('changePassword.currentPassword')
      })
    }
  ],
  newPassword: [
    {
      required: true,
      message: i18n.t('errors.resetPasswordEmpty')
    },
    ({ getFieldValue }: any) => ({
      validator(_: object, value: string) {
        if (value) {
          if ((value.length < 8)
            || !value.match(regexPassword)
            || !value.match(regexUppercaseAndLowercase)
            || !value.match(regexSpecialCharacters)) {
            return Promise.reject(new Error(i18n.t('errors.message.PRE00034')))
          }
          if (value.length > 32) {
            return Promise.reject(new Error('入力長を超えました'))
          }
        }
        return Promise.resolve()
      }
    }),
    // Check trung pass current
    // ({ getFieldValue }: any) => ({
    //   validator(_: object, value: string) {
    //     if (value.length >= 6) {
    //       if (!value || getFieldValue('oldPassword') === value) {
    //         return Promise.reject(new Error(i18n.t('errors.message.PRE00033')))
    //       }
    //     }
    //     return Promise.resolve()
    //   }
    // })
  ],
  newPasswordConfirm: [
    ({ getFieldValue }: any) => ({
      validator(_: object, value: string) {
        if (!value) {
          return Promise.reject(new Error(i18n.t('errors.confirmPasswordEmpty')))
        }
        if (value.length > 32) {
          return Promise.reject(new Error('入力長を超えました'))
        }
        if (getFieldValue('newPassword') !== value) {
          return Promise.reject(new Error(i18n.t('errors.message.FRE00005')))
        }
        return Promise.resolve()
      }
    })
  ]
}
export { validChangePassword }
