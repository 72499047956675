import {
  ICreateOrderParams,
  IDeleteOrder,
  IListOrder,
  IOrderDetailParams,
  IUpdateOrderParams
} from 'services/params/order/IorderParam'
import { orderRequest } from 'services/requests/order/orderRequest'
import { configRequest } from '..'
import { Methods } from '../EMethod'

class OrderApis {
  createOrder = (param: ICreateOrderParams) => {
    const request = orderRequest(param, Methods.CREATE_ORDER)
    return configRequest(request)
  }
  getListOrder = (params: IListOrder) => {
    const requests = orderRequest(params, Methods.LIST_ORDER)
    return configRequest(requests)
  }
  orderDetail = (params: IOrderDetailParams) => {
    const requests = orderRequest(params, Methods.ORDER_DETAIL)
    return configRequest(requests)
  }
  deleteOrder = (params: IDeleteOrder) => {
    const requests = orderRequest(params, Methods.DELETE_ORDER)
    return configRequest(requests)
  }
  updateOrder = (param: IUpdateOrderParams) => {
    const request = orderRequest(param, Methods.UPDATE_ORDER)
    return configRequest(request)
  }
}

export const orderApi = new OrderApis()
