import { Form } from 'antd'
import { IconDeleteBtn, IconDeleteModal } from 'assets'
import VButton from 'components/button/VButton'
import TableComponent from 'components/common/table/TableComponent'
import { IKatashikiRecordType } from 'interfaces/katashiki/IKatashiki'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import 'styles/katashiki.scss'
import i18n from 'configs/i18n'
import { useDispatch } from 'react-redux'
import { listKatashikiRemove } from 'redux/actions/katashiki/katashikiAction'
import ModalComponent from 'components/modal/ModalComponent'
import ModalCheckSuccessComponent from 'components/modal/ModalCheckSuccessComponent'
import { KEY_ITEM_CREATED } from './createKatashiki'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'

const INVALID_ITEM_ID = -1
const KatashikiDetail = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const [listDataAdd, setListDataAdd] = useState<IKatashikiRecordType[]>([])
  const [msgPopup, setMsgPopup] = useState(false)
  const [removePopup, setRemovePopup] = useState({
    isVisible: false,
    itemID: INVALID_ITEM_ID
  })

  const itemCreated = window.localStorage.getItem(KEY_ITEM_CREATED)

  useEffect(() => {
    if (itemCreated) {
      const arr = JSON.parse(itemCreated)
      setListDataAdd(arr)
    }
  }, [itemCreated])

  const handleOnClickDelete = (record: any) => {
    setRemovePopup({
      ...removePopup,
      isVisible: true,
      itemID: record.id
    })
  }

  const handlePopupRemoveSubmit = () => {
    if (removePopup.itemID !== INVALID_ITEM_ID)
      dispatch(
        listKatashikiRemove(removePopup.itemID, (resp) => {
          if (!resp?.result?.Error) {
            updateListOnItemRemoved(removePopup.itemID)
            setMsgPopup(true)
            hiddenRemovePopup()
          }
        })
      )
  }

  const updateListOnItemRemoved = (itemID: number) => {
    const cloneData = [...listDataAdd]
    const newArr = cloneData?.filter((item: any) => item?.id !== itemID)
    window.localStorage.removeItem(KEY_ITEM_CREATED)
    window.localStorage.setItem(KEY_ITEM_CREATED, JSON.stringify(newArr))
    setListDataAdd(newArr)
  }

  const handlePopupRemoveCancel = () => {
    hiddenRemovePopup()
  }

  const hiddenRemovePopup = () => {
    setRemovePopup({
      ...removePopup,
      isVisible: false,
      itemID: INVALID_ITEM_ID
    })
  }

  const onFinish = () => {
    history.push(ADMIN_ROUTE.concat(routesName.KATASHIKI + '/listKatashiki'))
  }

  const handleMsgPopupCancel = () => {
    setMsgPopup(false)
  }

  const columns = [
    {
      title: '型式',
      dataIndex: '',
      key: '1',
      width: '160px',
      align: 'left',
      render: (_: object, record: any) => {
        return <TooltipComponent maxSlice={12} title={record?.model} />
      }
    },
    {
      title: '製品名',
      dataIndex: '',
      key: '2',
      width: '160px',
      align: 'left',
      render: (_: object, record: any) => {
        return <TooltipComponent maxSlice={12} title={record?.productName} />
      }
    },
    {
      title: '通信種別',
      dataIndex: 'communicationType',
      key: '3',
      width: '160px',
      align: 'left'
    },
    {
      title: '機器種別',
      dataIndex: 'deviceType',
      key: '4',
      width: '160px',
      align: 'left'
    },
    {
      title: '管理記号',
      dataIndex: 'managementSymbol',
      key: '5',
      width: '160px',
      align: 'left'
    },
    {
      title: '備考',
      dataIndex: '',
      key: '6',
      width: '160px',
      align: 'left',
      render: (_: object, record: any) => {
        return <TooltipComponent maxSlice={12} title={record?.notes} />
      }
    },
    {
      title: i18n.t('customerScreen.delete'),
      dataIndex: '',
      width: '75px',
      align: 'center',
      render: (_: object, record: any) => {
        return (
          <Form.Item>
            <img
              onClick={() => handleOnClickDelete(record)}
              src={IconDeleteBtn}
              alt='delete'
              className='w42x-h28x pointer'
            />
          </Form.Item>
        )
      }
    }
  ]

  return (
    <>
      <div className='bgrffffff'>
        <div className='kasaki-shape-management'>
          <p className='katashiki-label'>型式登録結果</p>
          <Form form={form} onFinish={onFinish}>
            <div className='katashiki-detail-custom-table'>
              <Form.List name='listKatashikiAdd'>
                {() => {
                  return (
                    <>
                      <TableComponent columns={columns} dataSource={listDataAdd} />
                    </>
                  )
                }}
              </Form.List>
            </div>
            <div className='btn-confirm-katashiki-detail'>
              <Form.Item shouldUpdate>
                {() => (
                  <VButton
                    title={t('sideBar.katashiki.btnSubmidKatashikiDetail')}
                    type='text'
                    style={styleBtnSubmit}
                    htmlType='button'
                    onClick={() => form.submit()}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      <ModalComponent
        isVisible={removePopup.isVisible}
        img={IconDeleteModal}
        content={t('modal.message.removeOrderItem')}
        btnCancel={t('modal.btnCancel')}
        btnSubmit={t('modal.btnDelete')}
        onCancelModal={handlePopupRemoveCancel}
        onSubmitModal={handlePopupRemoveSubmit}
      />
      <ModalCheckSuccessComponent
        isVisible={msgPopup}
        content={t('modal.message.removeSuccess')}
        handleClickCancel={handleMsgPopupCancel}
      />
    </>
  )
}

export default KatashikiDetail
const styleBtnSubmit = {
  height: 38,
  color: '#ffffff'
}
