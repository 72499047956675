import { IDetailAuditLog } from 'services/params/auditLog/IAuditLogParam';
 import CustomerRequest from 'services/requests/customer/customerRequest'
 import { configRequest } from '..'
 import { Methods } from '../EMethod'
 
 class AuditLogApis {
   detailAuditLog = (params: IDetailAuditLog) => {
     const requests = CustomerRequest(params, Methods.GET_AUDIT_LOG_LATEST)
     return configRequest(requests)
   }
 }
 
 export const AuditLogApi = new AuditLogApis()
 