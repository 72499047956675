import { IconExportCsv } from 'assets'
import moment from 'moment'
import { useEffect, useRef } from 'react'
import { CSVLink } from './csvCore/index'
import { useTranslation } from 'react-i18next'
import 'styles/ExportCsv.scss'

interface IPropsCsv {
  onClickButtonCsv?: () => void
  headers?: any
  data?: any
  fileName?: string
  isDisabled: boolean
  isDownload?: boolean
  title?: string
  btnClass?: string
  disableTitle?: string
}

function ExportCsvComponent({
  onClickButtonCsv,
  headers,
  data,
  fileName,
  isDisabled,
  isDownload,
  title,
  btnClass
}: IPropsCsv) {
  const ref: any = useRef()
  const { t } = useTranslation()
  const date = moment().format('YYYYMMDDHHmmss')
  const fileNameDate = fileName + '_' + date
  const handleOnClick = () => {
    if (onClickButtonCsv) {
      onClickButtonCsv()
    }
  }
  useEffect(() => {
    if (isDownload && ref && data?.length > 0) {
      ref.current.link.click()
    }
    // eslint-disable-next-line
  }, [isDownload])
  return (
    <>
      {isDisabled ? (
        <div className={`${btnClass} d-flex cutom-div-csv div-csv-disable`}>
          <img className='img-w-h_18x mr-8px' src={IconExportCsv} alt='export-csv' />
          <span>{title ? title : t('sensorManagementScreen.csv')}</span>
        </div>
      ) : (
        <div>
          {data.length > 0 ? (
            <CSVLink
              ref={ref}
              filename={`${fileNameDate}.csv`}
              className='hidden'
              data={data ?? []}
              headers={headers ?? []}>
              <div className={`${btnClass} d-flex cutom-div-csv pointer`} onClick={handleOnClick}>
                <img className='img-w-h_18x mr-8px' src={IconExportCsv} alt='export-csv' />
                <span>{title ? title : t('sensorManagementScreen.csv')}</span>
              </div>
            </CSVLink>
          ) : (
            <div className={`${btnClass} d-flex cutom-div-csv div-csv-disable`}>
              <img className='img-w-h_18x mr-8px' src={IconExportCsv} alt='export-csv' />
              <span>{title ? title : t('sensorManagementScreen.csv')}</span>
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default ExportCsvComponent
