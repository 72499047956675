import moment from 'moment'
import { diffTimeFunc } from '../dashboard/convertData'
import i18n from 'configs/i18n'
import { communicationStatusSetting, defaultTypeSensorLTE, defaultValueAll } from './defaultValue'
import { EDate } from 'interfaces/enums/EDate'
const arrTypeSensorKey = ['paddy_field', 'upland_farming', 'weather']
const convertListSensor = (data: any) => {
  data = data?.record?.map((item: any, index: number) => {
    return {
      key: item?.sensorAiId,
      no: index + 1,
      customerName: item?.customer?.customerName,
      groupName: item?.group !== null ? item?.group.groupName : '',
      sensorName: item?.sensorName,
      deviceID: item?.deviceId,
      sensorTypeId: item?.sensorType?.sensorTypeId,
      sensorType: convertTypeSensor(item?.sensorType?.sensorTypeTranslate),
      lteOrLora: item?.sensorTypeParent?.sensorTypeKey,
      imeiNumber: item?.imei,
      securityKey: item?.securityKey,
      communicationStatus:
        item?.statusTransmission === 1
          ? communicationStatusSetting.abnormal
          : communicationStatusSetting.normal,
      battery:
        item?.statusBattery === 1
          ? communicationStatusSetting.abnormal
          : communicationStatusSetting.normal,
      registerDate: item?.createdAt,
      lastDate: item?.pushAtData
    }
  })
  return data
}
const convertListEmail = (data: any) => {
  data = data?.map((item: any) => {
    return {
      id: item?.userId,
      name: item?.email,
      value: item?.email
    }
  })
  return data
}
//  list sensor communication err
const convertDataListSensorCommunicationErr = (data: any) => {
  const timeCurrent = moment().format(EDate.DATEJP)
  data = data?.record?.map((item: any, index: number) => {
    return {
      key: item?.sensorAiId,
      no: index + 1,
      deviceID: item?.deviceId,
      customerName: item?.customer?.customerName,
      groupName: item?.group !== null ? item?.group.groupName : '',
      sensorName: item?.sensorName,
      sensorType: convertTypeSensor(item?.sensorType?.sensorTypeTranslate),
      lteOrLora: item?.sensorTypeParent?.sensorTypeKey,
      imeiNumber: item?.imei,
      securityKey: item?.securityKey,
      lastDate: item?.pushAtData,
      abnormalOccurrencePast: item?.pushAtData ? diffTimeFunc(timeCurrent, item?.pushAtData) : ''
    }
  })
  return data
}
// list sensor low battery
const convertDataListSensorLowBattery = (data: any) => {
  data = data?.record?.map((item: any, index: number) => {
    return {
      key: item?.sensorAiId,
      no: index + 1,
      deviceID: item?.deviceId,
      customerName: item?.customer?.customerName,
      groupName: item?.group !== null ? item?.group.groupName : '',
      sensorName: item?.sensorName,
      sensorType: convertTypeSensor(item?.sensorType?.sensorTypeTranslate),
      lteOrLora: item?.sensorTypeParent?.sensorTypeKey,
      securityKey: item?.securityKey,
      imeiNumber: item?.imei,
      lastDate: item?.pushAtData,
      batteryDroppedDate: item?.timeSensorBattery
    }
  })
  return data
}
// list sensor stop
const convertDataListSensorStop = (data: any) => {
  data = data?.record?.map((item: any, index: number) => {
    return {
      key: item?.sensorAiId,
      no: index + 1,
      deviceID: item?.deviceId,
      customerName: item?.customer?.customerName,
      groupName: item?.group !== null ? item?.group.groupName : '',
      sensorName: item?.sensorName,
      sensorType: convertTypeSensor(item?.sensorType?.sensorTypeTranslate),
      lteOrLora: item?.sensorTypeParent?.sensorTypeKey,
      imeiNumber: item?.imei,
      securityKey: item?.securityKey,
      dateTimeOfOutages: item?.timeSensorInactive
    }
  })
  return data
}
// convert list data deviceId
const convertListDeviceId = (data: any) => {
  data = data?.map((item: any, index: number) => {
    return {
      id: item?.device_id,
      name: item?.device_id
    }
  })
  return data
}
const convertListGroup = (data: any) => {
  data = data?.map((item: any, index: number) => {
    return {
      id: item?.groupId,
      name: item?.groupName,
      value: item?.groupName
    }
  })
  return data
}
const convertTypeParentSensor = (lteOrLora: string) => {
  if (lteOrLora === defaultTypeSensorLTE) {
    return i18n.t('LTE')
  }
  return i18n.t('LoRa')
}
const filterList = (list: any, value: any) => {
  const listSensorType = list.filter((item: any) => item?.parentSensorTypeId === value)
  return listSensorType
}
const convertListTypeSensor = (data: any) => {
  const listSensorType = filterList(data, '1')
  const result: any = []
  listSensorType?.forEach((item: any) => {
    if (arrTypeSensorKey?.includes(item?.sensorTypeKey)) {
      item?.sensorTypeTranslate?.forEach((trans: any) => {
        if (trans?.lang === 'jp') {
          result.push({
            id: item?.sensorTypeId,
            name: trans?.sensorTypeName,
            value: item?.sensorTypeKey
          })
        }
      })
    }
  })
  return result
}
//  show key search
const showKeySearch = (keySearch: any) => {
  const show =
    (keySearch?.email ? keySearch?.email : '') +
    (keySearch?.email && keySearch?.groupName ? ', ' : '') +
    keySearch?.groupName +
    (keySearch?.email || keySearch?.groupName ? i18n.t('sensorList') : '')
  return show
}
const convertTypeSensor = (sensorTypeTranslate: any) => {
  let text = ''
  sensorTypeTranslate?.forEach((item: any) => {
    if (item?.lang === 'jp') {
      text = item?.sensorTypeName
    }
  })
  return text
}
// param get list group by email
const paramGetListGroupByEmail = (email: string) => {
  const param = {
    userEmail: email === defaultValueAll ? '' : email,
    sortBy: {
      target: 'createdAt',
      order: 'ASC'
    }
  }
  return param
}
// convert list type sensor by lte or lora
const listTypeSensorByParentType = (listTypeSensor: any, lteOrLora: string) => {
  listTypeSensor = lteOrLora === defaultTypeSensorLTE ? listTypeSensor?.slice(0, 2) : listTypeSensor
  return listTypeSensor
}
export {
  convertListSensor,
  convertListEmail,
  convertDataListSensorCommunicationErr,
  convertDataListSensorLowBattery,
  convertDataListSensorStop,
  convertListDeviceId,
  convertListGroup,
  convertTypeParentSensor,
  convertListTypeSensor,
  showKeySearch,
  convertTypeSensor,
  paramGetListGroupByEmail,
  listTypeSensorByParentType
}
