const initialValueSensorDetail = {
  deviceId: '',
  oldDeviceId: '',
  sensorType: '',
  lteOrLora: '',
  collectionId: '',
  sensorId: '',
  securityKey: '',
  registerDate: '',
  gps: '',
  lastData: '',
  sensorIp: '',
  timeTracking: '',
  battery: '',
  communicationStt: '',
  operaStt: '',
  serialNumber: '',
  imeiNumber: '',
  rebootRequest: ''
}
export { initialValueSensorDetail }
