import i18n from 'configs/i18n'
import { validSignUp } from './rules'

const formSignUp = [
  {
    label: i18n.t('adminManagement.confirmPassword.passwordLabel'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.password'),
    name: 'password',
    rules: validSignUp.password,
    type: 'password',
    maxLength: 32,
    isRequired: true
  },
  {
    label: i18n.t('adminManagement.confirmPassword.confirmPasswordLabel'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.passwordConfirm'),
    name: 'confirm',
    rules: validSignUp.confirmPassword,
    type: 'confirmPassword',
    maxLength: 32,
    isRequired: true
  }
]
export { formSignUp }
