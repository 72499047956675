import i18n from 'configs/i18n'
const validImei = {
  imei: [
    {
      pattern: new RegExp(/^[0-9]{15}$/),
      message: i18n.t(`errors.message.FRE00031`)
    }
  ]
}
export { validImei }
