import { Col, Row } from 'antd'
import { IconLine } from 'assets'
import VButton from 'components/button/VButton'
import InputDateComponent from 'components/common/input/InputDateComponent'
import InputTimeComponent from 'components/common/input/InputTimeComponent'
import { originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducers'
import { diffTimeFun } from 'utils/Functions'
import { defaultOperaStatus } from '../../defaultValue'
import ItemSensorDetail from '../ItemSensorDetail'

interface ISensorTypeLTE {
  sensorDetailData: any
  onClickBtnJson?: () => void
  onClickBtnEdit?: () => void
  onClickBtnStopSensor?: () => void
  onClickBtnSend?: () => void
  onClickBtnActiveSensor?: () => void
  onClickBtnResendData?: () => void
}
function ShowSensorTypeLTE({
  sensorDetailData,
  onClickBtnJson,
  onClickBtnEdit,
  onClickBtnStopSensor,
  onClickBtnSend,
  onClickBtnActiveSensor,
  onClickBtnResendData
}: ISensorTypeLTE) {
  const { t } = useTranslation()
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))

  // start date
  const refDateDetail = useRef()
  const [startDateDetail, setStartDateDetail] = useState(new Date())
  // start time
  const refTimeDetail = useRef()
  const [startTimeDetail, setStartTimeDetail] = useState(new Date())
  // end date
  const refDateDetailEnd = useRef()
  const [endDateDetail, setEndDateDetail] = useState(new Date())
  // end time
  const refTimeDetailEnd = useRef()
  const [endTimeDetail, setEndTimeDetail] = useState(new Date())
  // validator time
  const [errTime, setErrTime] = useState<string>('')
  const handleOnClickBtnResendData = () => {
    const diffTime = diffTimeFun(startDateDetail, endDateDetail, startTimeDetail, endTimeDetail)
    if (diffTime <= 0) {
      setErrTime(t('errors.time'))
    } else {
      setErrTime('')
      if (onClickBtnResendData) {
        onClickBtnResendData()
      }
    }
  }
  return (
    <div>
      <div className='show-sensor-detail'>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.deviceId')}
            value={sensorDetailData?.deviceId ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.oldDeviceId')}
            value={sensorDetailData?.oldDeviceId ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.sensorType')}
            value={sensorDetailData?.sensorType ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.lteOrLora')}
            value={sensorDetailData?.lteOrLora}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.serialNumber')}
            value={sensorDetailData?.serialNumber ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.imeiNumber')}
            value={sensorDetailData?.imeiNumber ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.gps')}
            value={sensorDetailData?.gps ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.lastData')}
            value={sensorDetailData?.lastData ?? ''}
            isButton={true}
            btnName={t('sensorDetail.btn.btnJson')}
            width={'81px'}
            onClickButton={onClickBtnJson}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.battery')}
            value={sensorDetailData?.battery ?? ''}
            isConvertDisplay={true}
          />
        </div>
        {ROLE_CRUD.includes(role) ? (
          <div className='item-show'>
            <div className='item-sensor-detail'>
              <Row className='align-items-center'>
                <Col span={4} className='d-flex align-items-center'>
                  <span className='font-w-bold'>{t('sensorDetail.label.resendRequest')}</span>
                </Col>
                <Col span={20}>
                  <div className='d-flex'>
                    <InputDateComponent
                      maxDate={new Date()}
                      refer={refDateDetail}
                      state={startDateDetail}
                      readOnly={false}
                      onChangeDate={(date: Date) => setStartDateDetail(date)}
                    />
                    <InputTimeComponent
                      refer={refTimeDetail}
                      state={startTimeDetail}
                      readOnly={false}
                      onChangeDate={(date: Date) => setStartTimeDetail(date)}
                    />
                    <div className='d-flex align-items-center'>
                      <img className='custom-img-line' src={IconLine} alt='line' />
                    </div>
                    <InputDateComponent
                      maxDate={new Date()}
                      refer={refDateDetailEnd}
                      state={endDateDetail}
                      readOnly={false}
                      onChangeDate={(date: Date) => setEndDateDetail(date)}
                    />
                    <InputTimeComponent
                      refer={refTimeDetailEnd}
                      state={endTimeDetail}
                      readOnly={false}
                      onChangeDate={(date: Date) => setEndTimeDetail(date)}
                    />
                    <div className='d-flex align-items-center'>
                      {ROLE_CRUD.includes(role) ? (
                        <VButton
                          onClick={handleOnClickBtnResendData}
                          title={t('sensorDetail.btn.btnSend')}
                          type='text'
                          style={styles}
                          disabled={true}
                        />
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={4} />
                <Col span={20}>
                  <div className='custom-error'>{errTime ? <span>{errTime}</span> : null}</div>
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
        {ROLE_CRUD.includes(role) ? (
          <div className='item-show'>
            <ItemSensorDetail
              label={t('sensorDetail.label.rebootRequest')}
              value={sensorDetailData?.rebootRequest ?? ''}
              isButton={true}
              btnName={t('sensorDetail.btn.btnSend')}
              width={'48px'}
              onClickButton={onClickBtnSend}
              isDisabled={true}
            />
          </div>
        ) : null}
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.communicationErr')}
            value={sensorDetailData?.timeTracking ?? ''}
            text={sensorDetailData?.timeTracking ? t('modal.minutes') : ''}
            isButton={ROLE_CRUD.includes(role) ? true : false}
            btnName={t('sensorDetail.btn.btnEdit')}
            width={'48px'}
            onClickButton={onClickBtnEdit}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.communicationStt')}
            value={sensorDetailData?.communicationStt ?? ''}
            isConvertDisplay={true}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.operaStt')}
            value={
              sensorDetailData?.operaStt &&
              sensorDetailData?.operaStt === defaultOperaStatus?.active
                ? t('sensorDetail.active')
                : t('sensorDetail.stop')
            }
            isButton={ROLE_CRUD.includes(role) ? true : false}
            btnName={
              sensorDetailData?.operaStt === defaultOperaStatus?.active
                ? t('sensorDetail.btn.btnStop')
                : t('sensorDetail.btn.btnResurrection')
            }
            width={'76px'}
            onClickButton={
              sensorDetailData?.operaStt === defaultOperaStatus?.active
                ? onClickBtnStopSensor
                : onClickBtnActiveSensor
            }
          />
        </div>
      </div>
    </div>
  )
}
const styles = {
  width: '48px',
  marginLeft: 16,
  color: '#ffffff',
  height: 28,
  padding: 0,
  border: 'solid 1px #3eacff',
  backgroundColor: '#3eacff',
  boxShadow: 'unset'
}
export default ShowSensorTypeLTE
