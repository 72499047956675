import { Form, Input } from 'antd'
interface IFormChangePass {
  label: string
  name: string
  rules: any
  type?: string
  maxLength: number
  placeholder?: string
  isRequired?: boolean
  autoComplete?: string
}
function formChangePass({
  label,
  name,
  rules,
  type,
  maxLength,
  placeholder,
  isRequired,
  autoComplete
}: IFormChangePass) {
  return (
    <div className='custom-form-item'>
      <div className='font-w-bold mb-lb-form flex-left'>
        {label} <span className='cls_d7000f fs-14x'>{isRequired ? ' *' : null}</span>
      </div>
      <Form.Item dependencies={['addDealers', 'addSales', 'password']} name={name} rules={rules}>
        {type === 'password' || type === 'confirmPassword' ? (
          <Input.Password
            maxLength={maxLength}
            placeholder={placeholder}
            autoComplete={autoComplete}
          />
        ) : (
          <Input maxLength={maxLength} placeholder={placeholder} autoComplete={autoComplete} />
        )}
      </Form.Item>
    </div>
  )
}
export default formChangePass
