import i18n from 'configs/i18n'

const arrOptionPassword = [
  {
    id: 'noChange',
    name: i18n.t('modal.userManagement.edit.option.noChange')
  },
  {
    id: 'reset',
    name: i18n.t('modal.userManagement.edit.option.reset'),
    note: i18n.t('modal.userManagement.edit.note.reset')
  },
  {
    id: 'enter',
    name: i18n.t('modal.userManagement.edit.option.enter'),
    note: i18n.t('modal.userManagement.edit.note.enter')
  }
]
const defaultUserStatus = [
  {
    id: 'active',
    name: i18n.t('userManagementScreen.status.active'),
    value: 'true'
  },
  {
    id: 'stop',
    name: i18n.t('userManagementScreen.status.stop'),
    value: 'false'
  }
]

const headersCsvUserList = [
  {
    label: i18n.t('userManagementScreen.table.column.customerName'),
    key: 'customerName'
  },
  {
    label: i18n.t('userManagementScreen.table.column.customerCode'),
    key: 'customerCode'
  },
  {
    label: i18n.t('userManagementScreen.table.column.userName'),
    key: 'userName'
  },
  {
    label: i18n.t('userManagementScreen.table.column.mailAddress'),
    key: 'email'
  },
  {
    label: i18n.t('userManagementScreen.table.column.userRole'),
    key: 'userRole'
  },
  {
    label: i18n.t('userManagementScreen.table.column.userStatus'),
    key: 'userStatus'
  }
]

export { arrOptionPassword, defaultUserStatus, headersCsvUserList }
