import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'antd'
import VButton from 'components/button/VButton'
import 'styles/Customer.scss'
import { useDispatch } from 'react-redux'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import ModalComponent from 'components/modal/ModalComponent'
import moment from 'moment'
import { ADMIN_ROUTE, routeOrder, routesCustomerManagement } from 'views/routes/routes'
import { useHistory } from 'react-router-dom'
import { IconDeleteModal } from 'assets'
import { useParams } from 'react-router'
import DetailTotalCustomer from './DetailTotalCustomer'
import { orderColumns } from './defaultColumns'
import {
  deleteCustomerAction,
  detailCustomerRegAction
} from 'redux/actions/customer/customerAction'
import {
  detailAuditLogAction
} from 'redux/actions/auditLog/auditLogAction'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import { defaultShowBlank } from '../sensor/defaultValue'
import { showTotalRecord } from 'utils/Functions'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import { concat } from 'lodash'
import { EAuditLogActionType, EAuditLogType } from 'interfaces/enums/EAuditLog'
import NotFoundComponent from '../notFound/NotFoundComponent'

export interface IMasterDataType {
  id: number
  nameJp: string
  name: string
}

export interface IPropsTable {
  columns: any
  data: any
}

const DetailCustomer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  const idCumstomer = useParams<any>()
  const [customer, setCustomer] = useState<any>()
  const [auditLogCreated, setAuditLogCreated] = useState<any>()
  const [auditLogUpdated, setAuditLogUpdated] = useState<any>()
  const [dashboard, setDashboad] = useState<any>()
  const [dataOrder, setDataOrder] = useState<any>()
  const [current, setCurrent] = useState<any>(1)
  const [pageSize, setPageSize] = useState<any>(10)
  const columns = orderColumns((record: any) => onClickOrderDetail(record), current, pageSize)
  const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [isNotFound, setIsNotFound] = useState<boolean>(false)

  useEffect(() => {
    getDataDetail()
    getAuditLog()
    // eslint-disable-next-line
  }, [])

  // show success modal
  // modal success
  const showSuccess = (text: string, href?: any) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text,
      href: href,
      isRedirect: true
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }

  // change page
  const handleOnChangePageSize = (size: number) => {
    const totalPageChange = Math.ceil(dataOrder?.length / size)
    if (current > totalPageChange) {
      setCurrent(totalPageChange)
    }
    setPageSize(size)
  }
  const handleOnChangePage = (pageChange: number) => {
    setCurrent(pageChange)
  }

  const convertDataOrder = (data: any) => {
    moment.locale('ja')
    if(data)
      data = data.map((item: any, index: number) => {
        return {
          orderId: item?.orderId,
          key: item?.orderId,
          orderNo: item?.orderNo ?? defaultShowBlank,
          createdAt: item?.createdAt
            ? moment(item?.createdAt).format('YYYY/MM/DD')
            : defaultShowBlank,
          dateDelivery: item?.dateDelivery
            ? moment(item?.dateDelivery).format('YYYY/MM/DD')
            : defaultShowBlank,
          address: item?.address ?? defaultShowBlank,
          totalOrder: `${item?.totalOrder}${'台'}`
        }
      })
    return data
  }

  // get data detail
  const getDataDetail = async () => {
    const params: any = {
      timeZone: getTimeZone,
      customerId: Number(idCumstomer?.id)
    }
    dispatch(
      detailCustomerRegAction(
        { ...params },
        async (data) => {
          if(data?.result) {
            const dataDetail = await data?.result?.customer
            const dataCustomerOrders = await data?.result?.listDataOrders
            setCustomer(dataDetail)
            setDashboad({
              totalUserRegister: data?.result?.totalUser,
              numberUserInc: data?.result?.totalUserInMonth,
              totalSensorRegister: data?.result?.totalSensor,
              numberSensorInc: data?.result?.sensorInMonth,
              totalCommunication: data?.result?.sensorTransmission,
              totalOperation: data?.result?.sensorActive,
              totalBattery: data?.result?.sensorBattery,
              totalStop: data?.result?.sensorInActive
            })
            setDataOrder(convertDataOrder(dataCustomerOrders))
            dispatch(SetLoadingAction(false))
          } else {
            setIsNotFound(true)
          }
        },
        true
      )
    )
  }

  // get audit log
  const getAuditLog = async () => {
    const params: any = {
      recordId: Number(idCumstomer?.id),
      type: EAuditLogType.CUSTOMER
    }
    dispatch(
      detailAuditLogAction(
        {...params},
        async (data) => {
          const dataAuditLogs = await data?.result?.record;
            for (const dataAuditLog of dataAuditLogs) {
              
              if (dataAuditLog?.action === EAuditLogActionType.CREATE_CUSTOMER) {

                setAuditLogCreated(dataAuditLog);
              } else if (dataAuditLog?.action === EAuditLogActionType.EDIT_CUSTOMER) {

                setAuditLogUpdated(dataAuditLog);
              }
            }
            
          dispatch(SetLoadingAction(false))
        },
        true
      )
    )
  }

  // get customer order detail

  const onClickOrderDetail = (record: any) => {
    history.push(
      ADMIN_ROUTE.concat(routeOrder.ORDER_DETAIL.replace(':id', record?.orderId.toString())),
      {
        item: record?.orderId
      }
    )
  }

  const goToOrderList = () => {
    history.push(ADMIN_ROUTE.concat(routeOrder.LIST_ORDER))
  }

  const handleOnClickDelete = () => {
    setModal({
      ...modal,
      isVisible: true,
      type: 'delete-user',
      isComponent: false,
      img: IconDeleteModal,
      btnSubmit: t('modal.btnDelete'),
      content: t('modal.customer.customerDeleteContent'),
      hideBtnFooter: false
    })
  }

  async function handleOnSubmitModal() {
    // check modal?.type dispatch action
    switch (modal?.type) {
      case 'delete-user':
        deleteUser()
        break
      default:
        break
    }
  }

  const deleteUser = async () => {
    const params: any = { id: Number(idCumstomer?.id) }
    dispatch(
      deleteCustomerAction(
        params,
        (data) => {
          if (data?.result?.id) {
            dispatch(SetLoadingAction(false))
            setModal({ ...modal, isVisible: false })
            showSuccess(
              '削除しました。',
              ADMIN_ROUTE.concat(routesCustomerManagement.CUSTOMER_LIST)
            )
          }
        },
        true
      )
    )
  }

  const handleOnClickEdit = () => {
    const url = ADMIN_ROUTE.concat(
      routesCustomerManagement.CUSTOMER_EDIT.replace(':id', idCumstomer?.id)
    )
    history.push({ pathname: url })
  }

  const renderInfoTxt = (
    label1: string,
    data1: string,
    label2: string,
    data2: string,
    useTooltip: boolean = false,
    firstLabelStyle?: React.CSSProperties,
    firstDataStyle?: React.CSSProperties,
    secondLabelStyle?: React.CSSProperties,
    secondDataStyle?: React.CSSProperties
  ) => {
    const styleFirstLabel: React.CSSProperties = {
      width: '30%',
      fontWeight: 'bold'
    }
    const styleFirstData: React.CSSProperties = {
      width: '70%'
    }
    const styleSecondLabel: React.CSSProperties = {
      width: '30%',
      fontWeight: 'bold'
    }
    const styleSecondData: React.CSSProperties = {
      width: '70%'
    }

    return (
      <div style={{ display: 'flex', paddingBottom: 24 }}>
        <div className='ct1-info'>
          <div style={{...styleFirstLabel, ...firstLabelStyle}}>{label1}</div>
          <div style={{...styleFirstData, ...firstDataStyle}}>{data1}</div>
        </div>
        <div style={{ width: '5%' }} />
        <div className='ct1-info'>
          <div style={{...styleSecondLabel, ...secondLabelStyle}}>{label2}</div>
          <>
            {useTooltip ? (
              <div style={{...styleSecondData, ...secondDataStyle}}>
                <TooltipComponent maxSlice={20} title={data2} />
              </div>
            ) : (
              <div style={{...styleSecondData, ...secondDataStyle}}>{data2}</div>
            )}
          </>
        </div>
      </div>
    )
  }

  const getUpdatedAt = (): string => {
    if (auditLogUpdated?.createdAt) {
      return moment(auditLogUpdated?.createdAt).format('YYYY/MM/DD');
    }
    if (auditLogCreated?.createdAt) {
      return moment(auditLogCreated?.createdAt).format('YYYY/MM/DD');
    }
    return '';
  }

  return (
    isNotFound ? <NotFoundComponent /> :
    <>
      <div>
        <div className='bgrffffff' style={{ marginBottom: 16 }}>
          <div className='detail-cus-ct1 pd_16x_16x'>
            {renderInfoTxt(
              t('customerScreen.customerName'),
              customer?.customerName,
              t('customerScreen.representativeEmail'),
              customer?.email,
              true
            )}
            {renderInfoTxt(
              t('customerScreen.createCustomer.zipCodeLabel'),
              customer?.zipCode,
              t('customerScreen.corporateKey'),
              customer?.customerKey,
            )}
            {renderInfoTxt(
              '',
              customer?.address1
                ? concat(customer?.address1 + '　' + customer?.address2)
                : customer?.address2,
              '',
              ''
            )}
            {renderInfoTxt(
              t('customerScreen.dealersName'),
              customer?.dealer?.nameJp,
              t('customerScreen.salesName'),
              customer?.sale?.nameJp,
              true
            )}
            {renderInfoTxt(
              t('customerScreen.startedAt'),
              moment(customer?.startedAt).format('YYYY/MM/DD'),
              t('customerScreen.endedAt'),
              customer?.endedAt !== null ? moment(customer?.endedAt).format('YYYY/MM/DD') : ''
            )}
            {renderInfoTxt(
              t('customerScreen.registeredPerson'),
              auditLogCreated?.username,
              t('customerScreen.registrationDate'),
              auditLogCreated?.createdAt ? moment(auditLogCreated?.createdAt).format('YYYY/MM/DD') : '',
              false,
              styleFieldSystem,
              styleFieldSystem,
              styleFieldSystem,
              styleFieldSystem,
            )}
            {renderInfoTxt(
              t('customerScreen.changer'),
              auditLogUpdated?.username ? auditLogUpdated?.username : auditLogCreated?.username,
              t('customerScreen.updateDate'),
              getUpdatedAt(),
              false,
              styleFieldSystem,
              styleFieldSystem,
              styleFieldSystem,
              styleFieldSystem,
            )}

            <div className='group-btn-del'>
              <VButton
                htmlType='button'
                disabled={false}
                title={t('customerScreen.btnEditCustomer')}
                style={styleBtnSubmit}
                className='btnEdit'
                onClick={handleOnClickEdit}
              />
              <VButton
                htmlType='button'
                onClick={handleOnClickDelete}
                title={t('customerScreen.delete').concat(' ')}
                style={styleBtnReturn}
              />
            </div>
          </div>
        </div>
        <div className='dashboard-detail'>
          <DetailTotalCustomer dataDashboard={dashboard} />
        </div>
        <div className='bgrffffff' style={{ marginTop: 16 }}>
          <div className='detail-cus-ct1 pd_16x_16x'>
            <div style={{ fontWeight: 'bold' }}>{t('customerScreen.shippingInfo')}</div>
          </div>
          <div className='custom-table'>
            <div className='sensor-pagination inline' style={{ padding: 16 }}>
              <div className='font-w-bold'>{showTotalRecord(dataOrder?.length)}</div>
              <PaginationComponent
                onChangePageSize={(size) => handleOnChangePageSize(size)}
                total={dataOrder?.length}
                onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                pageSize={pageSize}
                isShowSizeChanger={false}
                currentpage={current}
              />
            </div>
            <Table columns={columns} dataSource={dataOrder} pagination={false} />
            <div className='sensor-pagination' style={{ padding: 16 }}>
              <PaginationComponent
                onChangePageSize={(size) => handleOnChangePageSize(size)}
                total={dataOrder?.length}
                onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                pageSize={pageSize}
                isShowSizeChanger={false}
                currentpage={current}
              />
            </div>
            <div className='text-align-center pd_16x_16x'>
              <VButton title={t('seeMore')} onClick={goToOrderList} />
            </div>
          </div>
        </div>
      </div>
      {modal?.isVisible ? (
        <ModalComponent
          img={modal?.img}
          isVisible={modal?.isVisible}
          content={modal?.content}
          component={modal?.component}
          btnSubmit={modal?.btnSubmit}
          btnCancel={modal?.btnCancel}
          onCancelModal={() => setModal({ ...modal, isVisible: false })}
          onSubmitModal={handleOnSubmitModal}
          hideBtnFooter={modal?.hideBtnFooter}
        />
      ) : null}
    </>
  )
}

const styleBtnSubmit = {
  width: 74,
  height: 38,
  color: '#ffffff'
}
const styleBtnReturn = {
  backgroundColor: '#fff',
  color: '#d7000f',
  border: 'solid 1px #d7000f',
  height: 38,
  marginLeft: 24
}
const styleFieldSystem = {
  color: '#69696A'
}

export default DetailCustomer
