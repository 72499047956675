import { Input } from 'antd'
interface IInputReadOnly {
  value: any
  className?: string
  styleInput?: any
}
function InputReadOnlyComponent({ value, className, styleInput }: IInputReadOnly) {
  return (
    <div>
      <Input
        value={value}
        readOnly={true}
        className={`${className} input-read-only`}
        style={{ ...style, ...styleInput }}
      />
    </div>
  )
}
const style = {
  height: 35,
  cursor: 'not-allowed'
}
export default InputReadOnlyComponent
