const statesIn = [
  {
    id: 4023,
    name: 'Andaman and Nicobar Islands',
    country_id: 101,
    country_code: 'IN',
    state_code: 'AN',
    latitude: '11.74008670',
    longitude: '92.65864010'
  },
  {
    id: 4017,
    name: 'Andhra Pradesh',
    country_id: 101,
    country_code: 'IN',
    state_code: 'AP',
    latitude: '15.91289980',
    longitude: '79.73998750'
  },
  {
    id: 4024,
    name: 'Arunachal Pradesh',
    country_id: 101,
    country_code: 'IN',
    state_code: 'AR',
    latitude: '28.21799940',
    longitude: '94.72775280'
  },
  {
    id: 4027,
    name: 'Assam',
    country_id: 101,
    country_code: 'IN',
    state_code: 'AS',
    latitude: '26.20060430',
    longitude: '92.93757390'
  },
  {
    id: 4037,
    name: 'Bihar',
    country_id: 101,
    country_code: 'IN',
    state_code: 'BR',
    latitude: '25.09607420',
    longitude: '85.31311940'
  },
  {
    id: 4031,
    name: 'Chandigarh',
    country_id: 101,
    country_code: 'IN',
    state_code: 'CH',
    latitude: '30.73331480',
    longitude: '76.77941790'
  },
  {
    id: 4040,
    name: 'Chhattisgarh',
    country_id: 101,
    country_code: 'IN',
    state_code: 'CT',
    latitude: '21.27865670',
    longitude: '81.86614420'
  },
  {
    id: 4032,
    name: 'Dadra and Nagar Haveli',
    country_id: 101,
    country_code: 'IN',
    state_code: 'DN',
    latitude: '20.32287780',
    longitude: '72.96672540'
  },
  {
    id: 4033,
    name: 'Daman and Diu',
    country_id: 101,
    country_code: 'IN',
    state_code: 'DD',
    latitude: '20.39737360',
    longitude: '72.83279910'
  },
  {
    id: 4021,
    name: 'Delhi',
    country_id: 101,
    country_code: 'IN',
    state_code: 'DL',
    latitude: '28.70405920',
    longitude: '77.10249020'
  },
  {
    id: 4009,
    name: 'Goa',
    country_id: 101,
    country_code: 'IN',
    state_code: 'GA',
    latitude: '15.29932650',
    longitude: '74.12399600'
  },
  {
    id: 4030,
    name: 'Gujarat',
    country_id: 101,
    country_code: 'IN',
    state_code: 'GJ',
    latitude: '22.25865200',
    longitude: '71.19238050'
  },
  {
    id: 4007,
    name: 'Haryana',
    country_id: 101,
    country_code: 'IN',
    state_code: 'HR',
    latitude: '29.05877570',
    longitude: '76.08560100'
  },
  {
    id: 4020,
    name: 'Himachal Pradesh',
    country_id: 101,
    country_code: 'IN',
    state_code: 'HP',
    latitude: '31.10482940',
    longitude: '77.17339010'
  },
  {
    id: 4029,
    name: 'Jammu and Kashmir',
    country_id: 101,
    country_code: 'IN',
    state_code: 'JK',
    latitude: '33.27783900',
    longitude: '75.34121790'
  },
  {
    id: 4025,
    name: 'Jharkhand',
    country_id: 101,
    country_code: 'IN',
    state_code: 'JH',
    latitude: '23.61018080',
    longitude: '85.27993540'
  },
  {
    id: 4026,
    name: 'Karnataka',
    country_id: 101,
    country_code: 'IN',
    state_code: 'KA',
    latitude: '15.31727750',
    longitude: '75.71388840'
  },
  {
    id: 4028,
    name: 'Kerala',
    country_id: 101,
    country_code: 'IN',
    state_code: 'KL',
    latitude: '10.85051590',
    longitude: '76.27108330'
  },
  {
    id: 4852,
    name: 'Ladakh',
    country_id: 101,
    country_code: 'IN',
    state_code: 'LA',
    latitude: '34.22684750',
    longitude: '77.56194190'
  },
  {
    id: 4019,
    name: 'Lakshadweep',
    country_id: 101,
    country_code: 'IN',
    state_code: 'LD',
    latitude: '10.32802650',
    longitude: '72.78463360'
  },
  {
    id: 4039,
    name: 'Madhya Pradesh',
    country_id: 101,
    country_code: 'IN',
    state_code: 'MP',
    latitude: '22.97342290',
    longitude: '78.65689420'
  },
  {
    id: 4008,
    name: 'Maharashtra',
    country_id: 101,
    country_code: 'IN',
    state_code: 'MH',
    latitude: '19.75147980',
    longitude: '75.71388840'
  },
  {
    id: 4010,
    name: 'Manipur',
    country_id: 101,
    country_code: 'IN',
    state_code: 'MN',
    latitude: '24.66371730',
    longitude: '93.90626880'
  },
  {
    id: 4006,
    name: 'Meghalaya',
    country_id: 101,
    country_code: 'IN',
    state_code: 'ML',
    latitude: '25.46703080',
    longitude: '91.36621600'
  },
  {
    id: 4036,
    name: 'Mizoram',
    country_id: 101,
    country_code: 'IN',
    state_code: 'MZ',
    latitude: '23.16454300',
    longitude: '92.93757390'
  },
  {
    id: 4018,
    name: 'Nagaland',
    country_id: 101,
    country_code: 'IN',
    state_code: 'NL',
    latitude: '26.15843540',
    longitude: '94.56244260'
  },
  {
    id: 4013,
    name: 'Odisha',
    country_id: 101,
    country_code: 'IN',
    state_code: 'OR',
    latitude: '20.95166580',
    longitude: '85.09852360'
  },
  {
    id: 4011,
    name: 'Puducherry',
    country_id: 101,
    country_code: 'IN',
    state_code: 'PY',
    latitude: '11.94159150',
    longitude: '79.80831330'
  },
  {
    id: 4015,
    name: 'Punjab',
    country_id: 101,
    country_code: 'IN',
    state_code: 'PB',
    latitude: null,
    longitude: null
  },
  {
    id: 4014,
    name: 'Rajasthan',
    country_id: 101,
    country_code: 'IN',
    state_code: 'RJ',
    latitude: '27.02380360',
    longitude: '74.21793260'
  },
  {
    id: 4034,
    name: 'Sikkim',
    country_id: 101,
    country_code: 'IN',
    state_code: 'SK',
    latitude: '27.53297180',
    longitude: '88.51221780'
  },
  {
    id: 4035,
    name: 'Tamil Nadu',
    country_id: 101,
    country_code: 'IN',
    state_code: 'TN',
    latitude: '11.12712250',
    longitude: '78.65689420'
  },
  {
    id: 4012,
    name: 'Telangana',
    country_id: 101,
    country_code: 'IN',
    state_code: 'TG',
    latitude: '18.11243720',
    longitude: '79.01929970'
  },
  {
    id: 4038,
    name: 'Tripura',
    country_id: 101,
    country_code: 'IN',
    state_code: 'TR',
    latitude: '23.94084820',
    longitude: '91.98815270'
  },
  {
    id: 4022,
    name: 'Uttar Pradesh',
    country_id: 101,
    country_code: 'IN',
    state_code: 'UP',
    latitude: '26.84670880',
    longitude: '80.94615920'
  },
  {
    id: 4016,
    name: 'Uttarakhand',
    country_id: 101,
    country_code: 'IN',
    state_code: 'UT',
    latitude: '30.06675300',
    longitude: '79.01929970'
  },
  {
    id: 4853,
    name: 'West Bengal',
    country_id: 101,
    country_code: 'IN',
    state_code: 'WB',
    latitude: '22.98675690',
    longitude: '87.85497550'
  }
]
export default statesIn
