import {
  IAddCustomerData,
  ICreateCustomer,
  ICustomerMasterData,
  IListCustomer,
  IDetailRegCustomer,
  IEditCustomer,
  IDeleteCustomer,
  IDeleteMasterSales,
  IDeleteMasterDealer,
  IResendCustomerVerifyEmail,
} from 'services/params/customer/ICustomerParam'
import {
  IAddCustomerDealerOrSales,
  ICreateCustomerAction,
  IGenerateCustomerKeyAction,
  IGetCustomerMasterDataAction,
  IListCustomerAction,
  IDetailCustomerRegAction,
  IUpdateCustomerAction,
  IDeleteCustomerAction,
  IDeleteMasterSalesAction,
  IDeleteMasterDealerAction,
  IResendCustomerVerifyEmailAction,
  IDownloadCustomerCsvAction,
} from './customerTypes'
import { ECustomerActions } from './ECustomerAction'

export const getCustomerMasterDataAction = (
  params: ICustomerMasterData,
  callBack: (value: any) => void,
  isLoading: boolean
): IGetCustomerMasterDataAction => ({
  type: ECustomerActions.GET_CUSTOMER_MASTER_DATA,
  params,
  callBack,
  isLoading
})
export const addCustomerDataAction = (
  params: IAddCustomerData,
  callBack: (value: any) => void,
  isLoading: boolean
): IAddCustomerDealerOrSales => ({
  type: ECustomerActions.ADD_CUSTOMER_MASTER_DATA,
  params,
  callBack,
  isLoading
})
export const createCustomerAction = (
  params: ICreateCustomer,
  callBack: (value: any) => void,
  isLoading: boolean
): ICreateCustomerAction => ({
  type: ECustomerActions.CREAT_CUSTOMER,
  params,
  callBack,
  isLoading
})
export const generateCustomerKeyAction = (
  params: any,
  callBack: (value: any) => void,
  isLoading: boolean
): IGenerateCustomerKeyAction => ({
  type: ECustomerActions.GENERATE_CUSTOMER_KEY,
  params,
  callBack,
  isLoading
})
export const listCustomerAction = (
  params: IListCustomer,
  isLoading: boolean,
  callBack: (value: any) => void
): IListCustomerAction => ({
  type: ECustomerActions.LIST_CUSTOMER,
  params,
  callBack,
  isLoading
})
export const detailCustomerRegAction = (
  params: IDetailRegCustomer,
  callBack: (value: any) => void,
  isLoading: boolean
): IDetailCustomerRegAction => ({
  type: ECustomerActions.DETAIL_CUSTOMER_REG,
  params,
  callBack,
  isLoading
})
export const updateCustomerAction = (
  params: IEditCustomer,
  callBack: (value: any) => void,
  isLoading: boolean
): IUpdateCustomerAction => ({
  type: ECustomerActions.EDIT_CUSTOMER,
  params,
  callBack,
  isLoading
})
export const deleteCustomerAction = (
  params: IDeleteCustomer,
  callBack: (value: any) => void,
  isLoading: boolean
): IDeleteCustomerAction => ({
  type: ECustomerActions.DELETE_CUSTOMER,
  params,
  callBack,
  isLoading
})
export const deleteMasterSalesAction = (
  params: IDeleteMasterSales,
  callBack: (value: any) => void,
  isLoading: boolean
): IDeleteMasterSalesAction => ({
  type: ECustomerActions.DELETE_MASTER_SALES,
  params,
  callBack,
  isLoading
});
export const deleteMasterDealerAction = (
  params: IDeleteMasterDealer,
  callBack: (value: any) => void,
  isLoading: boolean
): IDeleteMasterDealerAction => ({
  type: ECustomerActions.DELETE_MASTER_DEALER,
  params,
  callBack,
  isLoading
});
export const resendCustomerVerifyEmailAction = (
  params: IResendCustomerVerifyEmail,
  callBack: (value: any) => void,
  isLoading: boolean
): IResendCustomerVerifyEmailAction => ({
  type: ECustomerActions.RESEND_MAIL,
  params,
  callBack,
  isLoading
})
export const downloadCustomerCsvAction = (
  params: IListCustomer,
  callBack: (value: any) => void,
  isLoading: boolean
): IDownloadCustomerCsvAction => ({
  type: ECustomerActions.DOWNLOAD_CSV,
  params,
  callBack,
  isLoading
})
