import i18n from 'configs/i18n'
import { INotification } from 'interfaces/INotification'
import { store } from 'redux/store'
import { SensorApi } from 'services/api/sensor/sensorApi'
import {
  ICheckFormulaByDeviceId,
  IListEventBySensor,
  IListSensor,
  ISearchListSensor,
  IListUserBySensor,
  IMasterDataTypeSensor,
  ISearchDeviceIdSensor,
  ISearchSensor,
  ISensorDetail,
  IUpdateSensor
} from 'services/params/sensor/ISensorParam'
import { SetLoadingAction, SetNotificationAction } from '../common/commonAction'
import { ESensorActions } from './ESensorAction'
import {
  ICheckFormulaByDeviceIdAction,
  IListEmailSensorAction,
  IListEventBySensorAction,
  IListSensorAction,
  IListTypeSensorAction,
  IListUserBySensorAction,
  ISearchSensorAction,
  ISearchListSensorAction,
  ISensorDetailAction,
  IUpdateSensorAction
} from './sensorTypes'

export const getSensorListAction = (
  param: IListSensor,
  callBack: (data: any) => void,
  isLoading: boolean
): IListSensorAction => ({
  type: ESensorActions.SENSOR_LIST,
  param,
  callBack,
  isLoading
})
export const getListEmailSensorAction = (
  callBack: (data: any) => void
): IListEmailSensorAction => ({
  type: ESensorActions.EMAIL_LIST,
  callBack
})

export const sensorDetailAction = (
  param: ISensorDetail,
  callBack: (data: any) => void
): ISensorDetailAction => ({
  type: ESensorActions.SENSOR_DETAIL,
  param,
  callBack
})

export const getListUserBySensorAction = (
  param: IListUserBySensor,
  callBack: (data: any) => void
): IListUserBySensorAction => ({
  type: ESensorActions.GET_LIST_USER_BY_SENSOR,
  param,
  callBack
})
export const getListEventBySensorAction = (
  param: IListEventBySensor,
  callBack: (data: any) => void
): IListEventBySensorAction => ({
  type: ESensorActions.GET_LIST_EVENT_BY_SENSOR,
  param,
  callBack
})
export const updateSensorAction = (
  isLoading: boolean,
  param: IUpdateSensor,
  callBack: (data: any) => void
): IUpdateSensorAction => ({
  isLoading,
  type: ESensorActions.ADMIN_EDIT_SENSOR,
  param,
  callBack
})
export const searchDeviceIdSensorAction = async (param: ISearchDeviceIdSensor) => {
  const res = await SensorApi.searchDeviceIdSensor(param)
  return res
}
export const searchSensorAction = (
  isLoading: boolean,
  param: ISearchSensor,
  callBack: (data: any) => void
): ISearchSensorAction => ({
  isLoading,
  type: ESensorActions.SEARCH_SENSOR,
  param,
  callBack
})
export const searchListSensorAction = (
  isLoading: boolean,
  param: ISearchListSensor,
  callBack: (data: any) => void
): ISearchListSensorAction => ({
  isLoading,
  type: ESensorActions.SEARCH_LIST_SENSOR,
  param,
  callBack
})
export const getListTypeSensorAction = (
  param: IMasterDataTypeSensor,
  callBack: (data: any) => void
): IListTypeSensorAction => ({
  param,
  type: ESensorActions.GET_LIST_TYPE_SENSOR,
  callBack
})

export const checkFormulaByDeviceIdAction = (
  isLoading: boolean,
  params: ICheckFormulaByDeviceId,
  callBack: (data: any) => void
): ICheckFormulaByDeviceIdAction => ({
  isLoading,
  params,
  callBack,
  type: ESensorActions.CHECK_BY_DEVICE_ID
})

export const genSecurity = async (serial: string) => {
  let err: any = null
  try {
    const res = await SensorApi.genSecurity(serial)
    const result = res?.data?.result
    return result
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      store.dispatch(SetNotificationAction(noti))
      store.dispatch(SetLoadingAction(false))
    }
  }
}
