import { IconCollapsedClose, IconCollapsedOpen } from 'assets';
import { FunctionComponent, useState } from 'react'
import 'styles/collapsed.scss'

type CollapsedProps = {
  isTopSpace?: boolean;
  isBottomSpace?: boolean;
  label?: string;
}

const Collapsed: FunctionComponent<CollapsedProps> = (props) => {
  const { children, isTopSpace, isBottomSpace, label } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClickToggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOpen(!isOpen);
    document.querySelector('.collapsed__component-content')?.classList.toggle('collapsed__component-d-none');
  }
  return (
    <>
      {isTopSpace ? <br /> : <></>}
      <div className="collapsed__component">
        <button className="collapsed__component-button" onClick={(e) => {
          handleClickToggle(e);
        }}>
          <div className="collapsed__component-button-d">
            <div className="collapsed__component-button-text">{ label }</div>
            <div className="collapsed__component-button-icon">
              <img src={isOpen ? IconCollapsedClose : IconCollapsedOpen} alt="IconCollapsed" />
            </div>
          </div>
        </button>
        <div className="collapsed__component-content collapsed__component-d-none">{ children }</div>
      </div>
      {isBottomSpace ? <br /> : <></>}
    </>
  )
}

Collapsed.defaultProps = {
  isTopSpace: true,
  isBottomSpace: true,
  label: '検索条件'
}

export default Collapsed