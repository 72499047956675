import { IQrcode } from 'interfaces/qrcode/IQrcode'
import { EQrcodeActions } from './EQrcodeAction'
import { ICreateQrcode } from './qrcodeTypes'

export interface IQrcodeState {
  qrList: IQrcode[]
  qrListSearch: IQrcode[]
  detailQr: IQrcode | undefined
}

export const createQrcode = (
  param: ICreateQrcode,
  callback: (err: string | undefined) => void
) => ({
  type: EQrcodeActions.CREATE_QRCODE,
  param,
  callback
})

export const getListQrcode = () => ({
  type: EQrcodeActions.GET_LIST_QR
})

export const setQrList = (qrcode: IQrcode[]) => ({
  type: EQrcodeActions.SET_LIST_QR,
  data: qrcode
})

export const setQrListSearch = (qrcode: IQrcode[]) => ({
  type: EQrcodeActions.SET_LIST_SEARCH,
  data: qrcode
})

export const editQrAction = (params: IQrcode, callback: (err: string | undefined) => void) => ({
  type: EQrcodeActions.EDIT_QR,
  param: params,
  callback
})

export const deleteQrAction = (id: string) => ({
  type: EQrcodeActions.DELETE_QR,
  id
})

export const detailQrAction = (id: string) => ({
  type: EQrcodeActions.GET_DETAIL_QR,
  id
})

export const setDetailQrAction = (data: IQrcode) => ({
  type: EQrcodeActions.SET_DETAIL_QR,
  data
})
