import { IconDashEdit, IconDeleteRed } from 'assets'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import i18n from 'configs/i18n'
import { defaultShowBlank } from 'views/pages/sensor/defaultValue'

export const defaultColumns = (
  handleOnClickDetail: any,
  handleOnClickDelete: any,
  current: number,
  pageSize: number
) => {
  const columns = [
    {
      title: i18n.t('serialNoScreen.bulkDetail.No'),
      dataIndex: 'No',
      align: 'center',
      width: '5%',
      render: (text: any, row: any, index: any) => {
        return <span>{(current - 1) * pageSize + (index + 1)}</span>
      }
    },
    {
      title: i18n.t('serialNoScreen.bulkDetail.serialNumber'),
      dataIndex: 'serialNoNumber',
      render: (_: object, results: { serialNoNumber: string }) => {
        return <TooltipComponent maxSlice={20} title={results?.serialNoNumber} />
      },
      width: '10%'
    },
    {
      title: i18n.t('serialNoScreen.bulkDetail.deviceId'),
      dataIndex: 'deviceId',
      render: (_: object, results: { deviceId: string }) => {
        return (
          <>
            {results?.deviceId ? (
              <TooltipComponent maxSlice={20} title={results?.deviceId} />
            ) : (
              defaultShowBlank
            )}
          </>
        )
      },
      width: '18%'
    },
    {
      title: i18n.t('serialNoScreen.bulkDetail.securityKeyorImeiNumber'),
      dataIndex: 'securityKey',
      render: (_: object, record: { securityKey: any; IMEINumber: any; sensorTypeId: any }) => {
        return (
          <>
            {record?.sensorTypeId && record?.sensorTypeId === 1 ? (
              <TooltipComponent title={record?.securityKey} maxSlice={20} />
            ) : (
              <TooltipComponent title={record?.IMEINumber} maxSlice={20} />
            )}
          </>
        )
      },
      width: '19%'
    },
    {
      title: i18n.t('serialNoScreen.bulkDetail.communicationType'),
      dataIndex: 'sensorType',
      width: '14%'
    },
    {
      title: i18n.t('serialNoScreen.bulkDetail.equipmentType'),
      dataIndex: 'sensorTypeTranslate',
      width: '14%'
    },
    {
      title: i18n.t('serialNoScreen.bulkDetail.katashiki'),
      dataIndex: 'katashiki',
      width: '12%'
    },
    {
      title: i18n.t('serialNoScreen.bulkDetail.detail'),
      dataIndex: '',
      align: 'center',
      // width: '4%',
      render: (_: object, record: any) => {
        return (
          <div className='text-center'>
            <img
              src={IconDashEdit}
              onClick={() => handleOnClickDetail(record)}
              className='w42x-h28x pointer'
              alt='detail'
            />
          </div>
        )
      }
    },
    {
      title: i18n.t('serialNoScreen.bulkDetail.delete'),
      dataIndex: 'delete',
      align: 'center',
      // width: '4%',
      render: (_: object, record: any) => {
        return (
          <div className='text-center'>
            <img
              src={IconDeleteRed}
              onClick={() => handleOnClickDelete(record)}
              className='w42x-h28x pointer'
              alt='delete'
            />
          </div>
        )
      }
    }
  ]

  return columns
}
