import { combineReducers } from 'redux'
import authReducer from './auth/authReducer'
import commonReducer from './common/commonReducer'
import usersReducer from './users/usersReducer'
import { PERSIST_ROOT } from 'redux/store'
import { EAuthTypes } from 'redux/actions/auth/EAuthAction'
import autattributeReducer from './attributes/attributeReducer'
import formulaReducer from './formula/formulaReducer'
import qrReducer from './qrcode/qrReducer'
import createReducer from './create/createReducer'
import customerReducer from './customer/customerReducer'
import serialNoReducer from './serialNo/serialNoReducer'
import orderReducer from './order/orderReducer'
import auditLogReducer from './auditLog/auditLog'
const allReducers = combineReducers({
  common: commonReducer,
  auth: authReducer,
  users: usersReducer,
  attributes: autattributeReducer,
  formula: formulaReducer,
  qrcode: qrReducer,
  create: createReducer,
  customer: customerReducer,
  serialNo: serialNoReducer,
  order: orderReducer,
  auditLog: auditLogReducer
})

const rootReducer = (state: any, action: any) => {
  if (action.type === EAuthTypes.LOGOUT) {
    localStorage.removeItem(PERSIST_ROOT)
    return allReducers(undefined, action)
  }
  return allReducers(state, action)
}

export default rootReducer

export type AppState = ReturnType<typeof rootReducer>
