import citiesIn from './citiesIn'
import citiesJp from './citiesJp'
import country from './country'
import statesIn from './statesIn'
import statesJp from './statesJp'

const getCountryName = (countryId: string) => {
  let name = ''
  if (countryId) {
    country.forEach((item: any) => {
      if (item?.id === countryId) {
        name = item?.name
        return
      }
    })
    return name
  }
  return ''
}
const getNameState = (countryId: string, stateId: string) => {
  let name = ''
  let arr = []
  if (stateId) {
    if (countryId === 'JP') {
      arr = statesJp
    } else {
      arr = statesIn
    }
    arr.forEach((item: any) => {
      if (item?.id.toString() === stateId.toString()) {
        name = item?.name
        return
      }
    })
    return name
  }
  return ''
}
const getNameCity = (countryId: string, cityId: string) => {
  let name = ''
  let arr = []
  if (cityId) {
    if (countryId === 'JP') {
      arr = citiesJp
    } else {
      arr = citiesIn
    }
    arr.forEach((item: any) => {
      if (item?.id.toString() === cityId.toString()) {
        name = item?.name
        return
      }
    })
    return name
  }
  return ''
}
export { getCountryName, getNameState, getNameCity }
