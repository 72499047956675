import i18n from 'configs/i18n'
import { convertTimePushAtData } from '../dashboard/convertData'
import { communicationStatusSetting, defaultShowBlank } from './defaultValue'

const headerCsvSensor = [
  {
    label: i18n.t('sensorManagementScreen.table.column.customerName'),
    key: 'customerName'
  },
  {
    label: i18n.t('sensorManagementScreen.table.column.groupName'),
    key: 'groupName'
  },
  {
    label: i18n.t('sensorManagementScreen.table.column.deviceID'),
    key: 'deviceID'
  },
  {
    label: i18n.t('sensorManagementScreen.table.column.sensorName'),
    key: 'sensorName'
  },
  {
    label: i18n.t('sensorManagementScreen.table.column.lteOrLora'),
    key: 'lteOrLora'
  },
  {
    label: i18n.t('sensorManagementScreen.table.column.sensorType'),
    key: 'sensorType'
  },
  {
    label: i18n.t('sensorManagementScreen.table.column.communicationStatus'),
    key: 'communicationStatus'
  },
  {
    label: i18n.t('sensorManagementScreen.table.column.battery'),
    key: 'battery'
  },
  {
    label: i18n.t('sensorManagementScreen.table.column.lastDate'),
    key: 'lastDate'
  }
]
const headerCsvUser = [
  {
    label: 'No',
    key: 'no'
  },
  {
    label: '顧客名',
    key: 'customerName'
  },
  {
    label: 'サービス開始日',
    key: 'serviceStartDate'
  },
  {
    label: 'サービス終了日',
    key: 'serviceEndDate'
  },
  {
    label: 'ユーザ登録ステータス',
    key: 'customerStatus'
  },
  {
    label: '営業担当部署',
    key: 'departmentForSales'
  },
  {
    label: '代理店',
    key: 'agency'
  },
  {
    label: '法人キー',
    key: 'cooporateKey'
  }
]
const headerCsvCustomers = [
  {
    label: 'スペース名（顧客名）',
    key: 'customerName'
  },
  {
    label: 'お客様コード',
    key: 'customerKey'
  },
  {
    label: '顧客代表メールアドレス',
    key: 'email'
  },
  {
    label: '住所　（郵便番号）',
    key: 'zipCode'
  },
  {
    label: '住所（詳細住所）',
    key: 'address'
  },
  {
    label: 'サービス開始日',
    key: 'startedAt'
  },
  {
    label: 'サービス終了日',
    key: 'endedAt'
  },
  {
    label: 'ユーザ登録ステータス',
    key: 'statusUser'
  },
  {
    label: '営業担当部署',
    key: 'sale'
  },
  {
    label: '代理店',
    key: 'dealer'
  },
  {
    label: '利用中ユーザ数',
    key: 'totalUser'
  },
  {
    label: '出荷台数',
    key: 'totalOrder'
  },
  {
    label: '運用中センサ数',
    key: 'totalSensor'
  }
]
const convertDataCsvSensor = (data: any) => {
  if (data?.length) {
    data = data?.map((item: any, index: number) => {
      return {
        customerName: item?.customerName ? item?.customerName : defaultShowBlank,
        groupName: item?.groupName ? item?.groupName : defaultShowBlank,
        deviceID: item?.deviceID ? item?.deviceID : defaultShowBlank,
        sensorType: item?.sensorType ? item?.sensorType : defaultShowBlank,
        lteOrLora: item?.lteOrLora ? item?.lteOrLora : defaultShowBlank ,
        imeiNumber: item?.imeiNumber ? item?.imeiNumber : defaultShowBlank,
        securityKey: item?.securityKey ? item?.securityKey : defaultShowBlank,
        sensorName: item?.sensorName ? item?.sensorName : defaultShowBlank,
        communicationStatus:
          item?.communicationStatus === communicationStatusSetting?.abnormal
            ? i18n.t('sensorManagementScreen.table.status.abnormal')
            : i18n.t('sensorManagementScreen.table.status.normal'),
        battery:
          item?.battery === communicationStatusSetting?.abnormal
            ? i18n.t('sensorManagementScreen.table.status.abnormal')
            : i18n.t('sensorManagementScreen.table.status.normal'),
        lastDate: item?.lastDate ? convertTimePushAtData(item?.lastDate) : defaultShowBlank
      }
    })
    return data
  }
  return []
}

export { headerCsvSensor, headerCsvUser, convertDataCsvSensor, headerCsvCustomers }
