import { IRequest } from 'interfaces/IRequest'

export const orderRequest = (params?: any, method?: any): IRequest<any> => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: method,
    params: params
  }
}
