import DatePicker from 'react-datepicker'
import { IconCalendar } from 'assets'
import 'react-datepicker/dist/react-datepicker.css'
import 'styles/InputDate.scss'
import { IPropsInputDate } from 'interfaces/input/IPropsInputDate'
import { ja } from 'date-fns/locale'
import { forwardRef } from 'react'

const InputDateComponent = ({
  refer,
  state,
  readOnly,
  onChangeDate,
  maxDate,
  minDate,
  disabled,
  selectStart,
  selectEnd,
  startDate,
  endDate,
  tidleSign,
  customPopper,
  isClearable
}: IPropsInputDate) => {
  const formatDate = 'yyyy/MM/dd'
  const CustomInputDate = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className='custom-input-date pointer' onClick={onClick} ref={ref}>
      <div className='date-text'>{value}</div>
      <img className='icon-calendar' alt='calendar' src={IconCalendar} />
    </div>
  ))
  return (
    <div className='ip-date'>
      <DatePicker
        closeOnScroll={true}
        ref={refer}
        selected={state}
        dateFormat={formatDate}
        onChange={onChangeDate}
        autoComplete='off'
        className='ip-datePicker'
        disabledKeyboardNavigation
        maxDate={maxDate}
        minDate={minDate}
        readOnly={readOnly}
        disabled={disabled}
        locale={ja}
        customInput={<CustomInputDate />}
        selectsStart={selectStart}
        startDate={startDate}
        selectsEnd={selectEnd}
        endDate={endDate}
        popperPlacement={customPopper}
        isClearable={isClearable}
      />
      <>{tidleSign ? <div className='tidle-sign'>~</div> : null}</>
    </div>
  )
}

export default InputDateComponent
