import { Modal, Spin } from 'antd'
import 'styles/ModalLoading.scss'
interface IModalProp {
  isVisible: boolean
}
function LoadingComponent({ isVisible }: IModalProp) {
  return (
    <Modal
      visible={isVisible}
      centered={true}
      footer={null}
      maskClosable={false}
      closeIcon={<></>}
      className='modal-loading'
      zIndex={999999999999}>
      <div style={{ padding: '20px 0px' }}>
        <Spin size={'large'} />
      </div>
    </Modal>
  )
}
export default LoadingComponent
