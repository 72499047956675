import { Table } from 'antd'
import VButton from 'components/button/VButton'
import { useHistory } from 'react-router-dom'
interface IPropsTable {
  title?: string
  columns: any
  data: any
  path: string
  btnName: string
}
const locale = {
  emptyText: '  '
}
function DashboardTableComponent({ title, columns, data, path, btnName }: IPropsTable) {
  const history = useHistory()
  const handleOnClick = () => {
    history.push(path)
  }
  return (
    <div className='mg-dash-table'>
      <div className='bgrffffff'>
        <div className='dashboard-table-title fs-16x pd_16x_16x font-w-bold'>{title}</div>
        <div className='dash-table custom-table'>
          <Table locale={locale} columns={columns} dataSource={data} pagination={false} />
          <div className='text-align-center pd_16x_16x'>
            <VButton title={btnName} style={styles} onClick={handleOnClick} />
          </div>
        </div>
      </div>
    </div>
  )
}
const styles = {
  width: 116,
  height: 38
}
export default DashboardTableComponent
