import { Col, Row } from 'antd'
import { IconWarning } from 'assets'
import VButton from 'components/button/VButton'
import { useTranslation } from 'react-i18next'
import 'styles/ItemSensorDetail.scss'
import { communicationStatusSetting } from '../defaultValue'

interface IItemSensor {
  label: string
  value: any
  isButton?: boolean
  onClickButton?: () => void
  btnStyle?: object
  btnName?: string
  width?: string
  isConvertDisplay?: boolean | false
  text?: string
  isDisabled?: boolean
}
function ItemSensorDetail({
  label,
  value,
  isButton,
  onClickButton,
  btnName,
  width,
  isConvertDisplay,
  text,
  isDisabled
}: IItemSensor) {
  const { t } = useTranslation()
  const styles = {
    width: width,
    color: '#ffffff',
    height: 28,
    padding: 0,
    border: 'solid 1px #3eacff',
    backgroundColor: '#3eacff',
    boxShadow: 'unset'
  }
  return (
    <div className='item-sensor-detail'>
      <Row className='align-items-center'>
        <Col span={4}>
          <span className='font-w-bold'>{label}</span>
        </Col>
        <Col span={20}>
          {isConvertDisplay ? (
            value === communicationStatusSetting?.abnormal ? (
              <div className='d-flex align-items-center'>
                <span className='cls_E93A44'>
                  {t('sensorManagementScreen.table.status.abnormal')}
                </span>
                <img className='img_20x' src={IconWarning} alt='warning' />
              </div>
            ) : (
              <div className='d-flex align-items-center'>
                <span className='mr_10x'>{t('sensorManagementScreen.table.status.normal')}</span>
              </div>
            )
          ) : (
            <span className={value ? 'mr-16x' : ''}>
              {value}
              {text ?? ''}
            </span>
          )}

          {isButton ? (
            <VButton
              onClick={onClickButton}
              title={btnName ?? ''}
              type='text'
              style={styles}
              disabled={isDisabled}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  )
}

export default ItemSensorDetail
