const styBtnResetPassword = {
  height: 28,
  width: 114
}
const styBtnBtnStop = {
  color: '#ffffff',
  width: 42,
  height: 28,
  background: '#FDB901',
  padding: 0,
  boxShadow: 'unset'
}
const styBtnBtnActive = {
  color: '#ffffff',
  width: 42,
  height: 28,
  background: '#2BCBBA',
  padding: 0,
  boxShadow: 'unset'
}
const styleBtnSubmitEditUser = {
  width: 74,
  height: 38,
  marginRight: 10,
  color: '#ffffff',
  marginTop: 15
}
const styleBtnCancelEditUser = {
  width: 116,
  height: 38,
  marginLeft: 10,
  background: '#ffffff',
  border: '1px solid #D7000F',
  color: '#D7000F',
  marginTop: 15
}
export {
  styBtnResetPassword,
  styBtnBtnStop,
  styBtnBtnActive,
  styleBtnSubmitEditUser,
  styleBtnCancelEditUser
}
