import { all, fork } from 'redux-saga/effects'
import commonSaga from './common/commonSaga'
import authSaga from './auth/authSaga'
import usersSaga from './users/usersSaga'
import sensorsSaga from './sensor/sensorsSaga'
import userDetailSaga from './userDetail/userDetailSaga'
import dashboardSaga from './dashboard/dashboardSaga'
import groupSaga from './group/groupSaga'
import notificationSaga from './notifications/notificationsSaga'
import displaySaga from './display/displaySaga'
import attributesSaga from './attributes/attributesSaga'
import qrcodeSaga from './qrcode/qrcodeSaga'
import customerSaga from './customer/customerSaga'
import katashikiSaga from './katashiki/katashikiSaga'
import serialNoSaga from './serialNo/serialNoSaga'
import orderSaga from './order/orderSaga'
import auditLogSaga from './auditLog/auditLogSaga'

export default function* rootSaga() {
  yield all([
    fork(commonSaga),
    fork(authSaga),
    fork(usersSaga),
    fork(sensorsSaga),
    fork(userDetailSaga),
    fork(dashboardSaga),
    fork(groupSaga),
    fork(notificationSaga),
    fork(displaySaga),
    fork(attributesSaga),
    fork(qrcodeSaga),
    fork(customerSaga),
    fork(katashikiSaga),
    fork(serialNoSaga),
    fork(orderSaga),
    fork(auditLogSaga)
  ])
}
