import { Col, Form, Row } from 'antd'
import 'styles/Customer.scss'
import VButton from 'components/button/VButton'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'
import InputComponent from 'components/common/input/InputComponent'
import InputDateComponent from 'components/common/input/InputDateComponent'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import SelectComponent from 'components/common/select/SelectComponent'
import TableComponent from 'components/common/table/TableComponent'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ADMIN_ROUTE, routeOrder } from 'views/routes/routes'
import { defaultColumns } from './defaultColumns'
import { useDispatch } from 'react-redux'
import { listCustomerAction } from 'redux/actions/customer/customerAction'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import { deleteOrderAction, getListOrderAction } from 'redux/actions/order/orderAction'
import moment from 'moment'
import ModalComponent from 'components/modal/ModalComponent'
import { IconDeleteModal } from 'assets'
import { showTotalRecord } from 'utils/Functions'
import Collapsed from '../serialNo/serialNoManagement/components/collapsed'

interface IDataType {
  id: number
  customerName: string
  registerShipDate: string
  order: string
  confirmDate: string
  deliveryLocation: string
}

const getData = (current: number, pageSize: number, data: IDataType[]) => {
  return data.slice((current - 1) * pageSize, current * pageSize)
}

function OrderManagement() {
  const { t } = useTranslation()
  const history = useHistory()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [dataTable, setDataTable] = useState<any>([])
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [orderId, setOrderId] = useState<number>(0)
  const [selectCustomer, setSelectCustomer] = useState<string>('')
  const [listCustomer, setListCustomer] = useState<any[]>([])
  const refDateStart = useRef()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const refDateEnd = useRef()
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  const showSuccess = (text: string) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }
  const columns = defaultColumns(
    (record: any) => handleOnClickDetail(record),
    (record: any) => handleOnClickDelete(record),
    current,
    pageSize
  )
  useEffect(() => {
    const params = {
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    handleGetListOrder(params, true, false)
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    handleGetListCustomer()
    // eslint-disable-next-line
  }, [])
  // handle create new order
  const handleCreateOrder = () => {
    history.push(ADMIN_ROUTE.concat(routeOrder.CREATE_ORDER))
  }
  // get list customer
  const handleGetListCustomer = () => {
    const param: any = {
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    /* eslint-disable */
    dispatch(
      listCustomerAction(param, true, async (data) => {
        if (data?.result) {
          const dataList: any = []
          const record = await data?.result?.record
          record?.map((item: any) => {
            const cus = {
              id: item?.id,
              name: item?.customerName,
              value: item?.id
            }
            dataList.push(cus)
          })
          setListCustomer(dataList)
          dispatch(SetLoadingAction(false))
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
    /* eslint-disable */
  }
  const convertData = (data: any) => {
    moment.locale('ja')
    data = data.map((item: any, index: number) => {
      return {
        id: item?.id,
        key: item?.id,
        customerName: item?.customer?.customerName,
        createdAt: item?.createdAt ? moment(item?.createdAt).format('YYYY/MM/DD') : null,
        dateDelivery: item?.dateDelivery ? moment(item?.dateDelivery).format('YYYY/MM/DD') : null,
        orderCode: item?.orderCode,
        address: item?.address
      }
    })
    return data
  }
  // get list order
  const handleGetListOrder = (params: any, pageFirst: boolean, pageSearch: boolean) => {
    dispatch(
      getListOrderAction(
        params,
        async (data) => {
          if (data && data?.result?.record) {
            dispatch(SetLoadingAction(false))
            const dataTableConverted = convertData(data?.result?.record)
            if (pageFirst) {
              setCurrent(1)
            }
            if (pageSearch) {
              setIsSearch(true)
            }
            setDataTable(dataTableConverted)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  async function handleOnSubmitModal() {
    // check modal?.type dispatch action
    switch (modal?.type) {
      case 'delete-order':
        deleteOrder()
        break
      default:
        break
    }
  }

  const handleOnClickDetail = (record: any) => {
    history.push(ADMIN_ROUTE.concat(routeOrder.ORDER_DETAIL).replace(':id', record?.id), {
      item: record?.id
    })
  }
  // delete order
  const handleOnClickDelete = (record: any) => {
    const formatDateDelivery = moment(record?.dateDelivery).toDate().getTime()
    const formatDate = new Date().getTime()
    setOrderId(record?.id)
    setModal({
      ...modal,
      isVisible: true,
      type: 'delete-order',
      isComponent: false,
      img: IconDeleteModal,
      btnSubmit: t('modal.btnDelete'),
      content:
        formatDateDelivery < formatDate
          ? 'すでに納入済みとなっていますが削除しますか？'
          : '削除してもよろしいでしょうか。',
      hideBtnFooter: false
    })
  }

  const deleteOrder = () => {
    const params = {
      orderId: orderId
    }
    dispatch(
      deleteOrderAction(
        { ...params },
        (data) => {
          if (data && data?.result?.deletedAt) {
            dispatch(SetLoadingAction(false))
            setModal({ ...modal, isVisible: false })
            const dataSource = [...dataTable]
            const listOrder = dataSource?.filter((item) => parseInt(item.key, 10) !== orderId)
            setDataTable(listOrder)
            const totalPageChange = Math.ceil(listOrder?.length / pageSize)
            if (totalPageChange === current - 1) {
              setCurrent(totalPageChange)
            }
            showSuccess('削除しました。')
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }
  // change page
  const handleOnChangePageSize = (size: number) => {
    const totalPageChange = Math.ceil(dataTable?.length / size)
    if (current > totalPageChange) {
      setCurrent(totalPageChange)
    }
    setPageSize(size)
  }
  const handleOnChangePage = (pageChange: number) => {
    setCurrent(pageChange)
  }
  // handle change date
  const handleOnChangeStartDate = (date: Date) => {
    setStartDate(date)
  }
  const handleOnChangeEndDate = (date: Date) => {
    setEndDate(date)
  }
  // select customer
  const handleOnSelectCustomer = (customer: any) => {
    setSelectCustomer(customer)
  }
  // submit search
  const onFinish = async () => {
    setIsSearch(true)
    const formatStartDate = moment(startDate).format('YYYY/MM/DD')
    const formatEndDate = moment(endDate).format('YYYY/MM/DD')
    const params: any = {
      customerId: selectCustomer ? parseInt(selectCustomer, 10) : '',
      orderNo: form.getFieldValue('orderCode') ? form.getFieldValue('orderCode').trim() : '',
      dateDeliveryFrom: startDate ? formatStartDate : '',
      dateDeliveryTo: endDate ? formatEndDate : '',
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    handleGetListOrder(params, true, true)
  }
  // clear filter
  const onClearSearch = () => {
    setIsSearch(false)
    setSelectCustomer('')
    setStartDate(null)
    setEndDate(null)
    form.resetFields()
    const param = {
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    handleGetListOrder(param, true, false)
  }

  return (
    <>
      <div className='bgrffffff'>
        <div className='customer-management pd_16x_0x'>
          <div className='ss-search-email mg_0_16x'>
            <VButton
              onClick={handleCreateOrder}
              title='新規作成'
              htmlType='button'
              style={styleBtnSearch}
            />
          </div>
        </div>
      </div>
      <Collapsed isBottomSpace={false}>
        <div className=''>
        <div className='customer-management'>
          <div className='ss-search-email'>
            <div className='create-customer'>
              <div className='custom-order'>
                <Form form={form} onFinish={onFinish}>
                  <Col className='pr-7x'>
                    <Form.Item style={{ marginBottom: 12 }}>
                      <SelectComponent
                        styleSelect={{ marginBottom: -8 }}
                        styleLabel={{ fontSize: 12 }}
                        lable='顧客選択'
                        disableAll={true}
                        dataOption={listCustomer}
                        defaultOption={selectCustomer}
                        onChangeSelect={handleOnSelectCustomer}
                      />
                    </Form.Item>
                  </Col>
                  <Row gutter={24}>
                    <Col span={12}>
                      <InputComponent name='orderCode' type='text' label='オーダ' maxLength={64} />
                    </Col>
                    <Col span={6}>
                      <Form.Item>
                        <div className='start-date'>{t('customerScreen.selectDate')}</div>
                        <InputDateComponent
                          refer={refDateStart}
                          state={startDate}
                          readOnly={false}
                          selectStart={true}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={endDate}
                          customPopper='bottom'
                          onChangeDate={(date: Date) => handleOnChangeStartDate(date)}
                          tidleSign={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <div style={{ marginBottom: 8 }}>
                        <span>&nbsp;</span>
                      </div>
                      <Form.Item>
                        <InputDateComponent
                          refer={refDateEnd}
                          state={endDate}
                          readOnly={false}
                          startDate={startDate}
                          endDate={endDate}
                          selectEnd={true}
                          minDate={startDate}
                          customPopper='bottom'
                          onChangeDate={(date: Date) => handleOnChangeEndDate(date)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className='group-btn'>
                    <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                      {() => <VButton title='検索 ' htmlType='submit' style={styleBtnSearch} />}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <VButton
                        title='キャンセル'
                        htmlType='button'
                        onClick={onClearSearch}
                        style={styleBtnClear}
                      />
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Collapsed>
      <div className='bgrffffff' style={{ marginTop: 16 }}>
        <div className='order-management pd_16x_0x'>
          <div className='ss-search-email mg_0_16x'>
            <div className='sensor-pagination mb-16x'>
              <div className='d-flex justify-content-flex-end align-items-center mg-pagination'>
                <div className='d-flex justify-content-flex-end pd_16x_0x total-record'>
                  <div className='font-w-bold'>{showTotalRecord(dataTable?.length)}</div>
                  <div>
                    <PaginationComponent
                      onChangePageSize={(size) => handleOnChangePageSize(size)}
                      total={dataTable?.length}
                      onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                      pageSize={pageSize}
                      isShowSizeChanger={false}
                      currentpage={current}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='custom-table'>
              <TableComponent
                isSearch={isSearch}
                columns={columns}
                dataSource={getData(current, pageSize, dataTable)}
              />
            </div>
            <div className='sensor-pagination'>
              <PaginationComponent
                onChangePageSize={(size) => handleOnChangePageSize(size)}
                total={dataTable?.length}
                onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                pageSize={pageSize}
                isShowSizeChanger={true}
                currentpage={current}
              />
            </div>
            <div className='btn-return text-align-center'>
              <ButtonReturnComponent />
            </div>
          </div>
        </div>
      </div>
      {modal?.isVisible ? (
        <ModalComponent
          img={modal?.img}
          isVisible={modal?.isVisible}
          content={modal?.content}
          component={modal?.component}
          btnSubmit={modal?.btnSubmit}
          btnCancel={modal?.btnCancel}
          onCancelModal={() => setModal({ ...modal, isVisible: false })}
          onSubmitModal={handleOnSubmitModal}
          hideBtnFooter={modal?.hideBtnFooter}
        />
      ) : null}
    </>
  )
}
const styleBtnSearch = {
  height: 38,
  width: 76,
  backgroundColor: '#d7000f',
  color: '#fff',
  fontSize: 12,
  borderColor: ' #d7000f',
  marginRight: 16
}
const styleBtnClear = {
  height: 38,
  width: 116,
  backgroundColor: '#fff',
  color: ' #d7000f',
  border: 'solid 1px #e3242b'
}

export default OrderManagement
