import { Select, ConfigProvider } from 'antd'
import { IPropsSelect } from 'interfaces/sensor/IPropsSelect'
import { useTranslation } from 'react-i18next'
import 'styles/Select.scss'
const { Option } = Select
function SelectComponent({
  lable,
  dataOption,
  defaultOption,
  onChangeSelect,
  nextPage,
  styleLabel,
  disableAll,
  disableSelect = false,
  styleSelect,
  dropdownRender,
  isLabelRequired,
  visibleChange,
  showSearch,
  allowClear,
  filterOption,
  optionFilterProp,
  onSearch
}: IPropsSelect) {
  const { t } = useTranslation()
  const handleChangeOption = (value: string) => {
    if (onChangeSelect) {
      onChangeSelect(value)
    }
  }
  const handleOnScroll = (e: any) => {
    const element = e.target
    if (element.clientHeight + element.scrollTop + 1 >= element.scrollHeight) {
      if (nextPage) {
        nextPage()
      }
    }
  }
  return (
    <>
      <div className='select-component'>
        <div className='lable-mb font-w-bold' style={{ ...styleLabel }}>
          {lable}
          {isLabelRequired ? <span style={{ color: '#E93A44', fontSize: 14 }}>&nbsp;*</span> : null}
        </div>
        <div>
          <ConfigProvider renderEmpty={() => t('searchEmptyTable')}>
            <Select
              style={{ ...styleSelect }}
              className={disableSelect ? 'disableSelect' : ''}
              onPopupScroll={handleOnScroll}
              value={defaultOption}
              virtual={false}
              onChange={handleChangeOption}
              disabled={disableSelect}
              dropdownRender={dropdownRender}
              onDropdownVisibleChange={visibleChange}
              showSearch={showSearch}
              allowClear={allowClear}
              onSearch={onSearch}
              filterOption={filterOption}
              optionFilterProp={optionFilterProp}>
              {!disableAll ? (
                <Option key={'all'} value='all'>
                  {t('all')}
                </Option>
              ) : null}
              {dataOption.map((item) => (
                <Option key={item?.id + item?.value} value={item?.value ?? item?.id ?? item?.name}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </ConfigProvider>
        </div>
      </div>
    </>
  )
}
export default SelectComponent
