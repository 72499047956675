import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconChangeMap, IconSatellite, IconZoomIn, IconZoomOut, IconPosition } from 'assets/index'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import 'styles/Map.scss'

export interface IMapProps {
  getPosition: (e: any) => void
  position: any
}

function Map({ getPosition, position }: IMapProps) {
  const API_KEY: any = process.env.REACT_APP_GOOGLE_MAP_API_KEY
  const { t } = useTranslation()
  const [options, setOptions] = useState<any>()

  const containerStyle = {
    width: '100%',
    height: '250px',
    marginBottom: '30px'
  }

  const optionsDf = {
    center: { lat: 36.204823, lng: 138.25293 },
    zoom: 10,
    mapTypeId: 'roadmap',
    mapTypeControl: false,
    zoomControl: false,
    fullscreenControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    streetViewControl: false,
    keyboardShortcuts: false,
    panControl: false,
    rotateControl: false,
    maxZoom: 19,
    minZoom: 4
  }

  useEffect(() => {
    setOptions(optionsDf)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (position) {
      const opChange = { ...options, center: position }
      setOptions(opChange)
    }
    // eslint-disable-next-line
  }, [position])

  const switchType = () => {
    const typeIdTmp = options?.mapTypeId === 'satellite' ? 'roadmap' : 'satellite'
    const opChange = { ...options, mapTypeId: typeIdTmp }
    setOptions(opChange)
  }

  const handleZoom = (type: string) => {
    let zoom = options?.zoom
    zoom = type === 'in' ? zoom + 1 : zoom - 1
    if (zoom > 19 || zoom < 4) return
    const opChange = { ...options, zoom: zoom }
    setOptions(opChange)
  }

  const onDragEnd = (e: any) => {
    getPosition(e.latLng.toJSON())
  }

  return (
    <>
      <div className='zoomContainer' onClick={() => switchType()}>
        <img
          id='ic_switch'
          src={options?.mapTypeId === 'satellite' ? IconChangeMap : IconSatellite}
          alt=''
          style={{ width: '100%', height: 60 }}
        />

        <div className='switchTxt'>
          <p id='txt_switch' className='txt'>
            {options?.mapTypeId === 'satellite'
              ? t('customerScreen.map.map')
              : t('customerScreen.map.satellite')}
          </p>
        </div>
      </div>
      <div className='zoomBtn'>
        <img
          onClick={() => handleZoom('in')}
          src={IconZoomIn}
          style={{
            width: 24,
            height: 24
          }}
          alt='IconZoomIn'
        />
        <div className='line' />
        <img
          onClick={() => handleZoom('out')}
          src={IconZoomOut}
          style={{
            width: 24,
            height: 24
          }}
          alt='IconZoomOut'
        />
      </div>
      <LoadScript googleMapsApiKey={API_KEY}>
        <GoogleMap mapContainerStyle={containerStyle} options={options}>
          <Marker
            position={options?.center}
            icon={IconPosition}
            onDragEnd={onDragEnd}
            draggable={true}
          />
        </GoogleMap>
      </LoadScript>
    </>
  )
}

export default React.memo(Map)
