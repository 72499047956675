import { Form } from 'antd'
import VButton from 'components/button/VButton'
import FormChangePass from 'components/common/form/FormChangePass'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classes from 'styles/Login.module.scss'
import { formChangePassword } from './formChangePassword'
import { useDispatch } from 'react-redux'
import { changePasswordAction } from 'redux/actions/users/usersAction'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import { routesName } from 'views/routes/routes'

type LayoutType = Parameters<typeof Form>[0]['layout']

const ChangePasswordComponent = () => {
  const { t } = useTranslation()
  const formLayout: LayoutType = 'vertical'
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })

  const [isWrongPass, setIsWrongPass] = useState<boolean>(false)

  useEffect(() => {
    let getInputForm = document.getElementsByClassName('ant-input-password') as HTMLCollectionOf<HTMLElement>
    let isOldPassInput = getInputForm[0]
    if (isWrongPass) {
      document.addEventListener('keydown', function (event) {
        if (event.key === 'Backspace') {
          setIsWrongPass(false)
          isOldPassInput.style.borderColor = '#ff4d4f'
        }
      })
    }
  })

  const layout = {
    layout: formLayout,
    labelCol: { span: 10 },
    wrapperCol: { span: 20 }
  }
  const onFinish = (values: any) => {
    const params = {
      oldPassword: values?.oldPassword,
      newPassword: values?.newPassword,
      newPasswordConfirm: values?.newPasswordConfirm
    }
    let getInputForm = document.getElementsByClassName('ant-input-password') as HTMLCollectionOf<HTMLElement>
    let isOldPassInput = getInputForm[0]
    isOldPassInput.style.borderColor = '#d9d9d9'

    dispatch(SetLoadingAction(true))
    dispatch(
      changePasswordAction(
        { ...params },
        async (data) => {
          if (data?.code) {
            dispatch(SetLoadingAction(false))
            if (data?.code === 400 || data?.id === 'FRE00040') {
              await setIsWrongPass(true)
              dispatch(SetLoadingAction(false))
              isOldPassInput.style.borderColor = '#ff4d4f'
            }
          } else {
            if (data?.result) {
              dispatch(SetLoadingAction(false))
              showSuccess(t('modal.resetPasswordSuccess'))
            }
          }
          dispatch(SetLoadingAction(false))
        },
        true
      )
    )
  }

  const showSuccess = (text: string) => {
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text,
      isRedirect: true,
      href: routesName.LOGIN,
      isLogout: true
    }

    dispatch(SuccessAction({ ...stateSuccess }))
  }

  const onValuesChange = (values: any) => {
    let getInputForm = document.getElementsByClassName('ant-input-password') as HTMLCollectionOf<HTMLElement>
    let isOldPassInput = getInputForm[0]
    if (values?.oldPassword) {
      isOldPassInput.style.borderColor = '#ff4d4f'
      form.setFieldsValue({ oldPassword: values?.oldPassword?.split(' ').join('') })
      if (values?.oldPassword.length === 0) {
        setIsWrongPass(true)
        isOldPassInput.style.borderColor = '#ff4d4f'
      }
      else {
        setIsWrongPass(false)
      }
    }
    if (values?.newPassword) {
      form.setFieldsValue({ newPassword: values?.newPassword?.split(' ').join('') })
      checkIsWrongPass()
    }
    if (values?.newPasswordConfirm) {
      form.setFieldsValue({ newPasswordConfirm: values?.newPasswordConfirm?.split(' ').join('') })
      checkIsWrongPass()
    }
  }

  const checkIsWrongPass = () => {
    let getInputForm = document.getElementsByClassName('ant-input-password') as HTMLCollectionOf<HTMLElement>
    let isOldPassInput = getInputForm[0]
    if (isWrongPass) {
      isOldPassInput.style.borderColor = '#ff4d4f'
    }
    else {
      isOldPassInput.style.borderColor = '#d9d9d9'
    }
  }
  const _renderChangePassword = () => {
    return (
      <div className={classes.change_pass_container}>
        <Form
          form={form}
          {...layout}
          autoComplete='off'
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <div style={{ position: 'absolute', width: '820px', color: '#d7000f' }}>
            <div style={styles.isWrongPass}>
              {isWrongPass ? '現在のパスワードをご確認ください。' : null}
            </div>
          </div>
          {formChangePassword?.map((item) => (
            <div key={item?.name}>
              <FormChangePass
                label={item?.label}
                name={item?.name}
                type={item?.type}
                rules={item?.rules}
                maxLength={item?.maxLength}
                isRequired={item?.isRequired}
              />
            </div>
          ))}
          <div className='text-align-center d-flex justify-content-center'>
            <div className={classes.submit_btn}>
              <Form.Item shouldUpdate>
                {() => (
                  <VButton
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                    style={styles.styleBtnSubmit}
                    type='text'
                    title={t('changePassword.btnChange')}
                  />
                )}
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    )
  }

  return <div className={classes.login_page}>{_renderChangePassword()}</div>
}

const styles = {
  styleBtnSubmit: {
    width: 88,
    height: 38,
    marginRight: 10,
    color: '#ffffff',
    marginTop: 15
  },
  isWrongPass: {
    marginLeft: '30%',
    with: 900,
    marginTop: 40,
    fonSize: 12,
    color: '#d7000f !important'
  }
}

export default ChangePasswordComponent
