import { Modal, Form, Space, Radio, Descriptions } from 'antd'
import VButton from 'components/button/VButton'
import FormItem from 'components/common/form/FormItem'
import { EType } from 'interfaces/enums/EEditUser'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import { updateUserAction } from 'redux/actions/userDetail/usersDetailAction'
import { arrOptionPassword } from '../defaultValue'
import { formEdit } from '../formUser'
import { styleBtnCancelEditUser, styleBtnSubmitEditUser } from './style'

interface IEditUser {
  title: string
  onCancelModal: () => void
  onSubmitModal: (update: any) => void
  initialValue: any
}
function ModalEditUserComponent({ title, onCancelModal, onSubmitModal, initialValue }: IEditUser) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [valueRadio, setvalueRadio] = useState('noChange')
  const dispatch = useDispatch()
  const onFinish = (values: any) => {
    // check option
    let param = null
    if (values?.passwordGroup === 'enter') {
      param = {
        id: initialValue?.userId,
        username: values?.username,
        email: values?.email,
        password: values?.password,
        role: initialValue?.role,
        type: EType.enter
      }
    } else if (values?.passwordGroup === 'reset') {
      param = {
        id: initialValue?.userId,
        username: values?.username,
        email: values?.email,
        role: initialValue?.role,
        type: EType.reset
      }
    } else {
      param = {
        id: initialValue?.userId,
        username: values?.username,
        email: values?.email,
        role: initialValue?.role
      }
    }
    dispatch(
      updateUserAction({ ...param }, true, (data) => {
        if (data?.error) {
          if (data?.error?.code === 400) {
            form.setFields([
              {
                name: data?.error?.data?.email ? 'email' : '',
                errors: [
                  data?.error?.data?.email[0]
                    ? t(`errors.message.${data?.error?.data?.email[0]}`)
                    : ''
                ]
              }
            ])
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        } else {
          if (data?.result) {
            const userUpdate = {
              userName: data?.result?.username,
              email: data?.result?.email,
              key: data?.result?.userId,
              statusUser: initialValue?.statusUser
            }
            if (onSubmitModal) {
              onSubmitModal(userUpdate)
            }
          }
        }
      })
    )
  }
  const checkDisabled = () => {
    if (form.getFieldValue('passwordGroup') !== 'enter') {
      if (form.getFieldValue('username') && form.getFieldValue('email')) {
        return !!form
          .getFieldsError()
          .slice(0, 2)
          .filter(({ errors }) => errors.length).length
      }
      return !!form
        .getFieldsError()
        .slice(0, 2)
        .filter(({ errors }) => errors.length).length
    } else {
      if (!form.getFieldValue('password') || !form.getFieldValue('confirm')) {
        return true
      }
      return !!form.getFieldsError().filter(({ errors }) => errors.length).length
    }
  }
  const onValuesChange = (values: any) => {
    if (values?.email) {
      form.setFieldsValue({ email: values?.email?.split(' ').join('') })
    }
    if (values?.password) {
      form.setFieldsValue({ password: values?.password?.split(' ').join('') })
    }
    if (values?.confirm) {
      form.setFieldsValue({ confirm: values?.confirm?.split(' ').join('') })
    }
    if (values?.passwordGroup) {
      setvalueRadio(values?.passwordGroup)
      if (values?.passwordGroup !== 'enter') {
        form.setFieldsValue({ password: '' })
        form.setFieldsValue({ confirm: '' })
      }
    }
  }
  return (
    <Modal
      visible={true}
      centered={true}
      footer={null}
      maskClosable={false}
      onCancel={onCancelModal}>
      <div className='create-user'>
        <div className='text-align-center font-w-bold fs-16x'>{title}</div>
        <Form
          form={form}
          autoComplete='off'
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          initialValues={{
            ...initialValue
          }}>
          {formEdit?.slice(0, 2).map((item) => (
            <div key={item?.name}>
              <FormItem
                label={item?.label}
                placeholder={item?.placeholder}
                name={item?.name}
                type={item?.type}
                rules={item?.rules}
                maxLength={item?.maxLength}
                isRequired={item?.isRequired}
              />
            </div>
          ))}
          <div className='radio mb-16x'>
            <div className='font-w-bold mb-lb-form'>
              {t('modal.userManagement.create.lable.password')}
            </div>
            <Form.Item name='passwordGroup'>
              <Radio.Group>
                <Space direction='vertical'>
                  {arrOptionPassword.map((item) => (
                    <div key={item?.id}>
                      <div>
                        <Radio value={item?.id}>{item?.name}</Radio>
                      </div>
                      <Descriptions className='custom-description' title={item?.note} />
                    </div>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
          {valueRadio === arrOptionPassword[2].id ? (
            <>
              {formEdit?.slice(2, 4).map((item) => (
                <div key={item?.name}>
                  <FormItem
                    label={item?.label}
                    placeholder={item?.placeholder}
                    name={item?.name}
                    type={item?.type}
                    rules={item?.rules}
                    maxLength={item?.maxLength}
                    isRequired={item?.isRequired}
                  />
                </div>
              ))}
            </>
          ) : null}
          <div className='text-align-center d-flex justify-content-center'>
            <Form.Item shouldUpdate>
              {() => (
                <VButton
                  disabled={checkDisabled()}
                  style={styleBtnSubmitEditUser}
                  type='text'
                  title={t('modal.btnConfirm')}
                />
              )}
            </Form.Item>
            <VButton
              title={t('modal.btnCancel')}
              style={styleBtnCancelEditUser}
              type='text'
              htmlType='button'
              onClick={() => onCancelModal()}
            />
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default ModalEditUserComponent
