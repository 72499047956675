import jp from './jp'
import en from './en'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  jp: {
    translation: jp
  },
  en: {
    translation: en
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'jp',
  interpolation: {
    escapeValue: false
  }
})

export default i18n
