/* eslint-disable */
import { Form } from 'antd'
import { IconAddOutLined, IconDeleteBtn } from 'assets'
import IconButton from 'components/button/IconButton'
import VButton from 'components/button/VButton'
import SelectComponent from 'components/common/select/SelectComponent'
import TableComponent from 'components/common/table/TableComponent'
import { IKatashikiRecordType } from 'interfaces/katashiki/IKatashiki'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import 'styles/katashiki.scss'
import { v4 as uuidv4 } from 'uuid'
import i18n from 'configs/i18n'
import InputComponent from 'components/common/input/InputComponent'
import { useDispatch } from 'react-redux'
import {
  listKatashikiGetSensorType,
  createKatashiki
} from 'redux/actions/katashiki/katashikiAction'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'
import ModalComponent from 'components/modal/ModalComponent'
import ModalCheckSuccessComponent from 'components/modal/ModalCheckSuccessComponent'

export const KEY_ITEM_CREATED = 'KEY_ITEM_CREATED'
const LORA_ID = 1
const CreateKatashiki = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const [listDataAdd, setListDataAdd] = useState<IKatashikiRecordType[]>([])
  const [selectCommunication, setSelectCommunication] = useState<any>()
  const [listCommunicationType, setListCommunicationType] = useState<any>()
  const [selectDeviceType, setSelectDeviceType] = useState<any>()
  const [listDeviceType, setListDeviceType] = useState<any>()
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  const [msgPopup, setMsgPopup] = useState(false)
  const [isError, setIsError] = useState({
    isVisible: false,
    msg: ''
  })

  const [modelInputErr, setModelInputErr] = useState(false)
  const [productNameInputErr, setProductNameInputErr] = useState(false)

  const handleOnClickDelete = (record: any) => {
    setIsError({
      ...isError,
      isVisible: false,
      msg: ''
    })
    const getListKatashiki = form.getFieldsValue()?.listKatashikiAdd
    let arrRemoved = [...getListKatashiki]
    arrRemoved = arrRemoved?.filter((item: any) => item?.key !== record?.key)
    setListDataAdd(arrRemoved)
    form.setFieldsValue(arrRemoved)
  }
  useEffect(() => {
    const handleCallApiGetSensorType = () => {
      dispatch(
        listKatashikiGetSensorType((resp) => {
          const listSensorType: any = [],
            listDeviceType: any = []
          resp?.result?.sensorType.map((item: any) => {
            const ssType = {
              id: item?.sensorTypeId,
              name: item?.sensorTypeKey,
              value: item?.sensorTypeId
            }
            listSensorType.push(ssType)
          })
          resp?.result?.sensorTranslate.map((item: any) => {
            const deviceType = {
              id: item?.sensorTypeTranslateId,
              name: item?.sensorTypeName,
              value: item?.sensorTypeTranslateId
            }
            listDeviceType.push(deviceType)
          })
          setListCommunicationType(listSensorType)
          setListDeviceType(listDeviceType)

          const newRecord: IKatashikiRecordType = {
            key: 'id_first',
            model: '',
            productName: '',
            managementSymbol: '登録後自動生成',
            notes: ''
          }
          setListDataAdd([newRecord])
        })
      )
    }
    handleCallApiGetSensorType()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    const initialForm = {
      listKatashikiAdd: listDataAdd
    }
    form.setFieldsValue(initialForm)
    // eslint-disable-next-line
  }, [form, listDataAdd])
  const handleOnChangeCommunicationType = (commsType: any, record: any, index: any) => {
    const fields = form.getFieldsValue()
    const { listKatashikiAdd } = fields
    Object.assign(listKatashikiAdd[index], { deviceType: '' })
    form.setFieldsValue({ listKatashikiAdd })
    setSelectCommunication(commsType)

    const getListKatashiki = form.getFieldsValue()?.listKatashikiAdd
    let arrUpdate: any = []
    arrUpdate = arrUpdate.concat(getListKatashiki)
    const itemFind: any = arrUpdate.find((item: any) => item?.key === record?.key)
    itemFind.communicationType = commsType
    setListDataAdd(arrUpdate)
    form.setFieldsValue(arrUpdate)
    const listDeviceTypeTmp: any = []
    if (commsType !== '1') {
      listDeviceType.map((item: any) => {
        if (item.value !== '14') {
          listDeviceTypeTmp.push(item)
        }
      })
      setListDeviceType(listDeviceTypeTmp)
    } else if (!listDeviceType.find((item: any) => item.value === '14')) {
      const itemOther = {
        id: '14',
        name: 'データ収集装置',
        value: '14'
      }
      let listDeviceTypeTmp: any = []
      listDeviceTypeTmp = listDeviceTypeTmp.concat(listDeviceType)
      listDeviceTypeTmp.push(itemOther)
      setListDeviceType(listDeviceTypeTmp)
    }
  }
  const handleOnChangeDeviceType = (deviceId: any, record: any) => {
    setSelectDeviceType(deviceId)
    const getListKatashiki = form.getFieldsValue()?.listKatashikiAdd
    let arrUpdate: any = []
    arrUpdate = arrUpdate.concat(getListKatashiki)
    const itemFind: any = arrUpdate.find((item: any) => item?.key === record?.key)
    itemFind.deviceType = deviceId
    setListDataAdd(arrUpdate)
    form.setFieldsValue(arrUpdate)
  }
  const handleOnClickAdd = () => {
    const key = uuidv4()
    const getFormAttribute = form.getFieldsValue()?.listKatashikiAdd
    const newRecord: IKatashikiRecordType = {
      key: key.toString(),
      model: '',
      productName: '',
      managementSymbol: '登録後自動生成',
      notes: ''
    }
    setListDataAdd([...getFormAttribute, newRecord])
  }

  const handleOnModelInputChanges = (e: any) => {
    setIsError({
      ...isError,
      isVisible: false,
      msg: ''
    })
    setModelInputErr(false)
    if (e.target.defaultValue === '') {
      setModelInputErr(true)
    }
  }
  let timeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const handleOnProductNameInputChanges = (e: any) => {
    setProductNameInputErr(false)
    if (e.target.defaultValue === '') {
      setProductNameInputErr(true)
    }
  }

  const handleOnModelInpuBlur = (index: any) => {
    const fields = form.getFieldsValue()
    const { listKatashikiAdd } = fields
    Object.assign(listKatashikiAdd[index], { model: listKatashikiAdd[index]?.model.trim() })
    form.setFieldsValue({ listKatashikiAdd })
  }

  const handleOnProductNameInpuBlur = (index: any) => {
    const fields = form.getFieldsValue()
    const { listKatashikiAdd } = fields
    Object.assign(listKatashikiAdd[index], {
      productName: listKatashikiAdd[index]?.productName.trim()
    })
    form.setFieldsValue({ listKatashikiAdd })
  }

  const getListDevice = () => {
    const MOTHER_ID = '14'
    if (selectCommunication && selectCommunication == LORA_ID) {
      return listDeviceType
    } else {
      const arr = [...listDeviceType]
      const list = arr?.filter((el: any) => el.id !== MOTHER_ID)
      return list
    }
  }

  const columns = [
    {
      title: '型式',
      dataIndex: '',
      key: '1',
      render: (value: any, row: any, index: any) => {
        return (
          <InputComponent
            name={[index, 'model']}
            type='text'
            maxLength={64}
            rules={[
              {
                required: true,
                message: '型式を入力して下さい。'
              }
            ]}
            style={styleInput}
            onChange={handleOnModelInputChanges}
            onBlur={() => handleOnModelInpuBlur(index)}
          />
        )
      },
      width: '160px',
      align: 'left'
    },
    {
      title: '製品名',
      dataIndex: '',
      key: '2',
      render: (value: any, row: any, index: any) => {
        return (
          <InputComponent
            name={[index, 'productName']}
            type='text'
            rules={[
              {
                required: true,
                message: '製品名を入力して下さい。'
              }
            ]}
            maxLength={64}
            style={styleInput}
            onChange={handleOnProductNameInputChanges}
            onBlur={() => handleOnProductNameInpuBlur(index)}
          />
        )
      },
      width: '160px',
      align: 'left'
    },
    {
      title: '通信種別',
      dataIndex: '',
      key: '3',
      render: (value: any, row: any, index: any) => {
        return (
          <Form.Item name={[index, 'communicationType']}>
            <SelectComponent
              disableAll={true}
              dataOption={listCommunicationType}
              defaultOption={row.communicationType}
              onChangeSelect={(value) => handleOnChangeCommunicationType(value, row, index)}
            />
          </Form.Item>
        )
      },
      width: '160px',
      align: 'left'
    },
    {
      title: '機器種別',
      dataIndex: '',
      key: '4',
      render: (value: any, row: any, index: any) => {
        return (
          <Form.Item name={[index, 'deviceType']}>
            <SelectComponent
              disableAll={true}
              dataOption={getListDevice()}
              defaultOption={row.deviceType}
              onChangeSelect={(value) => handleOnChangeDeviceType(value, row)}
            />
          </Form.Item>
        )
      },
      width: '160px',
      align: 'left'
    },
    {
      title: '管理記号',
      dataIndex: '',
      key: '5',
      render: (value: any, row: any, index: any) => {
        return (
          <InputComponent
            name={[index, 'managementSymbol']}
            type='text'
            disabled={true}
            value='登録後自動生成'
            placeholder='登録後自動生成'
            style={styleInput}
          />
        )
      },
      width: '160px',
      align: 'left'
    },
    {
      title: '備考',
      dataIndex: '',
      key: '6',
      render: (value: any, row: any, index: any) => {
        return (
          <InputComponent name={[index, 'notes']} type='text' maxLength={64} style={styleInput} />
        )
      },
      width: '160px',
      align: 'left'
    },
    {
      title: i18n.t('customerScreen.delete'),
      dataIndex: '',
      width: 60,
      align: 'center',
      render: (_: object, record: any) => {
        return (
          <Form.Item>
            <img
              onClick={() => handleOnClickDelete(record)}
              src={IconDeleteBtn}
              alt='delete'
              className='w42x-h28x pointer'
            />
          </Form.Item>
        )
      }
    }
  ]

  const onFinish = async () => {
    const result: any = form.getFieldsValue()?.listKatashikiAdd
    const dataConvert: any = []
    result.map((item: any) => {
      const dataChild = {
        model: item.model,
        productName: item.productName,
        sensorTypeId: Number(item.communicationType),
        sensorTypeTranslateId: Number(item.deviceType),
        managementSymbol: '',
        remarks: item.notes
      }
      dataConvert.push(dataChild)
    })
    const param = {
      data: dataConvert
    }
    dispatch(
      createKatashiki(param, async (data) => {
        if (data && data?.result?.Error?.code === 400) {
          const msg: any = JSON.parse(data.result.Error.message)
          setIsError({
            ...isError,
            isVisible: true,
            msg: msg.message
          })
        } else {
          const itemsCreated: any[] = []
          data?.result?.some((el: any) => {
            itemsCreated.push({
              id: el.id,
              key: el.id,
              model: el.model,
              productName: el.productName,
              communicationType: getCommunicationType(el.sensorTypeId),
              deviceType: getDeviceType(el.sensorTypeTranslateId),
              managementSymbol: el.managementSymbol,
              notes: el.remarks
            })
          })
          setMsgPopup(true)
          localStorage.setItem(KEY_ITEM_CREATED, JSON.stringify(itemsCreated))
        }
      })
    )
  }

  const getCommunicationType = (id: any) => {
    let type = ''
    listCommunicationType?.some((el: any) => {
      if (el.id == id) {
        type = el.name
      }
    })
    return type
  }

  const getDeviceType = (id: any) => {
    let type = ''
    listDeviceType?.some((el: any) => {
      if (el.id == id) {
        type = el.name
      }
    })
    return type
  }

  const handleMsgPopupCancel = () => {
    setMsgPopup(false)
    const path = ADMIN_ROUTE.concat(routesName.KATASHIKI + '/detail')
    history.push(path)
  }

  const gotoLastPage = () => {
    history.goBack()
  }

  const checkDisableBtn = () => {
    const listKatashikiAdd = form.getFieldValue('listKatashikiAdd') || []
    if (listKatashikiAdd.length === 0) {
      return true
    } else if (listKatashikiAdd.length > 0) {
      let result = false
      listKatashikiAdd.map((item: any) => {
        if (
          item?.model === '' ||
          item?.productName === '' ||
          item?.communicationType === undefined ||
          item?.deviceType === undefined ||
          item?.deviceType === ''
        ) {
          result = true
        }
      })
      return result
    }
  }

  return (
    <>
      <div className='bgrffffff'>
        <div className='kasaki-shape-management'>
          <p className='katashiki-label'>型式情報入力</p>
          <Form form={form} onFinish={onFinish}>
            <div className='custom-table'>
              <Form.List name='listKatashikiAdd'>
                {() => {
                  return (
                    <>
                      <TableComponent columns={columns} dataSource={listDataAdd} />
                    </>
                  )
                }}
              </Form.List>
            </div>
            {isError.isVisible ? (
              <div className='input-error' style={{ width: '400px' }}>
                {isError.msg}
              </div>
            ) : null}
            <div className='btn-add pointer'>
              <VButton
                title='追加'
                btnIcon={<IconButton icon={IconAddOutLined} />}
                onClick={handleOnClickAdd}
                style={styleBtnAdd}
                type='text'
                htmlType='button'
              />
            </div>
            <div className='group-btn-action'>
              <Form.Item shouldUpdate>
                {() => (
                  <VButton
                    title={'登録'}
                    type='text'
                    style={styleBtnSubmit}
                    htmlType='button'
                    onClick={() => form.submit()}
                    disabled={checkDisableBtn()}
                  />
                )}
              </Form.Item>
              <Form.Item>
                <VButton
                  title='キャンセル'
                  style={styleBtnReturn}
                  onClick={() => {
                    gotoLastPage()
                  }}
                  htmlType='button'
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      {modal?.isVisible ? (
        <ModalComponent
          isVisible={modal?.isVisible}
          isComponent={modal?.isComponent}
          img={modal?.img}
          content={modal?.content}
          component={modal?.component}
          btnSubmit={modal?.btnSubmit}
          btnCancel={modal?.btnCancel}
          onCancelModal={() => setModal({ ...modal, isVisible: false })}
          onSubmitModal={() => setModal({ ...modal, isVisible: false })}
          hideBtnFooter={modal?.hideBtnFooter}
        />
      ) : null}
      <ModalCheckSuccessComponent
        isVisible={msgPopup}
        content={t('sideBar.katashiki.createSuccess')}
        handleClickCancel={handleMsgPopupCancel}
      />
    </>
  )
}

export default CreateKatashiki
const styleBtnSubmit = {
  width: 74,
  height: 38,
  color: '#ffffff'
}
const styleBtnAdd = {
  width: 74,
  height: 28,
  color: '#ffffff'
}
const styleBtnReturn = {
  width: 118,
  height: 38,
  color: '#d7000f',
  background: '#fff',
  borderRadius: 4,
  border: '1px solid #d7000f',
  marginLeft: 10
}
const styleInput = {
  height: 35
}
