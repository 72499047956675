import i18n from 'configs/i18n'
import { regexEmail, regexZipCode } from 'utils/Functions'
const customerCreateRules = {
  customerName: [
    // () => ({
    //   validator(_: object, value: string) {
    //     if (value.trim().length > 0) {
    //       return Promise.resolve()
    //     }
    //     return Promise.reject(
    //       new Error(
    //         i18n.t('errors.message.FRE00001', {
    //           0: i18n.t('customerScreen.customerName')
    //         })
    //       )
    //     )
    //   }
    // }),
    {
      required: true,
      message: i18n.t('errors.message.FRE00001', {
        0: i18n.t('customerScreen.customerName')
      })
    }
  ],
  representativeEmail: [
    {
      required: true,
      message: i18n.t('errors.message.FRE00001', {
        0: i18n.t('customerScreen.representativeEmail')
      })
    },
    {
      pattern: new RegExp(regexEmail),
      message: i18n.t(`errors.message.FRE00002`, {
        0: i18n.t('customerScreen.representativeEmail')
      })
    }
  ],
  zipCode: [
    {
      pattern: new RegExp(regexZipCode),
      message: i18n.t(`customerScreen.createCustomer.errorMessage.errZipCodeFormat`, {
        0: i18n.t('customerScreen.createCustomer.zipCodeLabel')
      })
    }
  ],
  address2: [
    {
      required: true,
      message: i18n.t('errors.message.FRE00001', {
        0: '番地・建物名'
      })
    }
  ],
  addDealers: [
    {
      required: true,
      message: i18n.t('customerScreen.createCustomer.errorMessage.dealerRequired')
    },
    {
      validator(_: object, value: any) {
        if (value.length > 0) {
          if (value.indexOf(' ') === 0 && value.trim().length === 0) {
            return (
              value = value.trim(),
              Promise.reject(new Error(i18n.t('customerScreen.createCustomer.errorMessage.dealerRequired')))
            )
          }
          else {
            return Promise.resolve()
          }
        }
      }
    }
  ],
  addSales: [
    {
      required: true,
      message: i18n.t('customerScreen.createCustomer.errorMessage.saleRequired')
    },
    {
      validator(_: object, value: any) {
        if (value.length > 0) {
          if (value.indexOf(' ') === 0 && value.trim().length === 0) {
            return (
              value = value.trim(),
              Promise.reject(new Error(i18n.t('customerScreen.createCustomer.errorMessage.saleRequired')))
            )
          }
          else {
            return Promise.resolve()
          }
        }
      }
    }
  ],
  startDate: [
    () => ({
      validator(_: object, value: any) {
        if (value?.length > 0) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(i18n.t('errors.message.FRE00008')))
      }
    })
  ],
  sales: [
    ({ getFieldValue }: any) => ({
      validator(_: object, value: any) {
        if (value?.length > 0) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(i18n.t('errors.message.FRE00008')))
      }
    })
  ]
}
export { customerCreateRules }
