import i18n from 'configs/i18n'
import {
  routeOrder,
  routesCustomerManagement,
  routeSerial,
  routesName,
  routesSensorManagement,
  routesUserManagement,
  routerChangePass
} from './routes'
const arrBredGroup = [
  {
    id: 1,
    name: i18n.t('sideBar.dashboard.txtDashboard'),
    path: routesUserManagement.USER_LIST
  },
  {
    id: 2,
    name: i18n.t('sideBar.groupDetail')
  }
]
const arrBredSensorDetail = [
  {
    id: 1,
    name: i18n.t('sideBar.sensorManagement.txtSensorManagement'),
    path: routesSensorManagement.SENSOR_LIST
  },
  {
    id: 2,
    name: i18n.t('sideBar.sensorDetail')
  }
]
const arrBredInfoDetail = [
  {
    id: 1,
    name: i18n.t('sideBar.infoManagement.txtInfoManagement'),
    path: routesName?.INFO_MANAGEMENT
  },
  {
    id: 2,
    name: i18n.t('sideBar.infoManagement.infoDetail')
  }
]
const arrBredInfoEdit = [
  {
    id: 1,
    name: i18n.t('sideBar.infoManagement.txtInfoManagement'),
    path: routesName?.INFO_MANAGEMENT
  },
  {
    id: 2,
    name: i18n.t('sideBar.infoManagement.infoEdit')
  }
]
const arrBredUserDetail = [
  {
    id: 1,
    name: i18n.t('sideBar.dashboard.txtDashboard'),
    path: routesUserManagement.USER_LIST
  },
  {
    id: 2,
    name: i18n.t('sideBar.userDetail')
  }
]
const arrCaculatorIndividual = [
  {
    id: 1,
    name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
    path: routesName?.DISPLAY_MANAGEMENT
  },
  {
    id: 2,
    name: i18n.t('caculation.individualSetting')
  }
]
const arrCaculatorMultiple = [
  {
    id: 1,
    name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
    path: routesName?.DISPLAY_MANAGEMENT
  },
  {
    id: 2,
    name: i18n.t('caculation.multiSetting')
  }
]
const arrCaculatorDetail = [
  {
    id: 1,
    name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
    path: routesName?.DISPLAY_MANAGEMENT
  },
  {
    id: 2,
    name: i18n.t('displayManagement.detail.formula')
  }
]
const arrCaculatorEdit = [
  {
    id: 1,
    name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
    path: routesName?.DISPLAY_MANAGEMENT
  },
  {
    id: 2,
    name: i18n.t('displayManagement.edit.formula')
  }
]
const arrFormulaCreate = [
  {
    id: 1,
    name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
    path: routesName?.DISPLAY_MANAGEMENT
  },
  {
    id: 2,
    name: i18n.t('caculation.createFormula')
  }
]
// const arrDetailDr = [
//   {
//     id: 1,
//     name: 'QRコード管理',
//     path: routesName?.QR_CODE + '/list'
//   },
//   {
//     id: 2,
//     name: 'QRコード詳細'
//   }
// ]
// const arrEdtiQr = [
//   {
//     id: 1,
//     name: 'QRコード管理',
//     path: routesName?.QR_CODE + '/list'
//   },
//   {
//     id: 2,
//     name: 'QRコード編集'
//   }
// ]
const arrDetailCustomer = [
  {
    id: 1,
    name: i18n.t('sideBar.customerManagement'),
    path: routesCustomerManagement.CUSTOMER_LIST
  },
  {
    id: 2,
    name: i18n.t('sideBar.customerDetail')
  }
]
const arrEditCustomer = [
  {
    id: 1,
    name: i18n.t('sideBar.customerManagement'),
    path: routesCustomerManagement.CUSTOMER_LIST
  },
  {
    id: 2,
    name: i18n.t('sideBar.customerEdit')
  }
]
const arrOrderDetail = [
  {
    id: 1,
    name: i18n.t('sideBar.order.orderTitle'),
    path: routeOrder.LIST_ORDER
  },
  {
    id: 2,
    name: i18n.t('sideBar.order.orderDetailBreadcrumb')
  }
]
const arrOrderEdit = [
  {
    id: 1,
    name: i18n.t('sideBar.order.orderTitle'),
    path: routeOrder.LIST_ORDER
  },
  {
    id: 2,
    name: i18n.t('sideBar.order.editOrderlBreadcrumb')
  }
]
const arrIndividualCreate = [
  {
    id: 1,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
    path: routeSerial.LIST_SERIAL
  },
  {
    id: 2,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialCreate'),
    path: routeSerial.SELECT_TYPE_CREATE_SERIAL
  },
  {
    id: 3,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.individual')
  }
]
const arrIndividualDetail = [
  {
    id: 1,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
    path: routeSerial.LIST_SERIAL
  },
  {
    id: 2,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialDetail')
  }
]
const arrIndividualEdit = [
  {
    id: 1,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
    path: routeSerial.LIST_SERIAL
  },
  {
    id: 2,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialDetail'),
    path: routeSerial.INDIVIDUAL_DETAIL
  },
  {
    id: 3,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.individualEdit')
  }
]
const arrBulkCreate = [
  {
    id: 1,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
    path: routeSerial.LIST_SERIAL
  },
  {
    id: 2,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialCreate'),
    path: routeSerial.SELECT_TYPE_CREATE_SERIAL
  },
  {
    id: 3,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.bulk')
  }
]
const arrBulkDetail = [
  {
    id: 1,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
    path: routeSerial.LIST_SERIAL
  },
  {
    id: 2,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialCreate')
  }
]
const arrBulkEdit = [
  {
    id: 1,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
    path: routeSerial.LIST_SERIAL
  },
  {
    id: 2,
    name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialCreate'),
    path: routeSerial.SELECT_TYPE_CREATE_SERIAL
  },
  {
    id: 3,
    name: '複数台編集'
  }
]
const arrChangPass = [
  {
    id: 1,
    name: i18n.t('sideBar.changePass'),
  },
]
// check title breadscrumb
const regexSensorManagementGroup = /(admin\/user-management\/group)/
const regexSensorManagementSensorDetail = /(admin\/sensor-management\/sensor)/
const regexInfoDetail = /(admin\/infomation-management\/detail)/
const regexInfoEdit = /(admin\/infomation-management\/edit)/
const regexUserDetail = /(admin\/user-management\/user)/
const regexCaculatorIndividual =
  /(admin\/display-management\/calculation\/calculator\/[\w]\/individual)/
const regexCaculatorMultiple = /(admin\/display-management\/calculation\/calculator\/[\w]\/multi)/
const regexCaculatorDetailMulti = /(admin\/display-management\/calculation\/multi-settings\/detail)/
const regexCaculatorDetailInvididual =
  /(admin\/display-management\/calculation\/individual-settings\/detail)/
const regexCaculatorEditMulti = /(admin\/display-management\/calculation\/edit-formula\/[\w])/
const regexCaculatorEditInvididual =
  /(admin\/display-management\/calculation\/edit-individual\/[\w])/
// const regexQrList = /(admin\/qrcode\/list\/[\w])/
// const regexQrEdit = /(admin\/qrcode\/create\/[\w])/
const regexCreateFormula =
  /(admin\/display-management\/calculation\/calculator\/[\w]\/formula\/create)/
const regexCustomDetail = /(admin\/customer-management\/detail)/
const regexCustomEdit = /(admin\/customer-management\/edit)/
const regexOrderDetail = /(admin\/order\/detail)/
const regexOrderEdit = /(admin\/order\/edit)/
const regexSerialIndividualCreate = /(admin\/serial-no\/individual-create)/
const regexSerialIndividualDetail = /(admin\/serial-no\/individual-detail)/
const regexSerialIndividualEdit = /(admin\/serial-no\/individual-edit)/
const regexSerialBulkCreate = /(admin\/serial-no\/bulk-create)/
const regexSerialBulkDetail = /(admin\/serial-no\/bulk-detail)/
const regexSerialBulkEdit = /(admin\/serial-no\/bulk-edit)/
const regexChangPass = /(change-password)/
// list regex

const checkRegexTitleDetail = (path: string) => {
  let arrTitle: any = []
  const listRegex = [
    {
      name: 'group-detail',
      regex: regexSensorManagementGroup.test(path),
      arr: arrBredGroup
    },
    {
      name: 'user-detail',
      regex: regexUserDetail.test(path),
      arr: arrBredUserDetail
    },
    {
      name: 'sensor-detail',
      regex: regexSensorManagementSensorDetail.test(path),
      arr: arrBredSensorDetail
    },
    {
      name: 'info-detail',
      regex: regexInfoDetail.test(path),
      arr: arrBredInfoDetail
    },
    {
      name: 'info-edit',
      regex: regexInfoEdit.test(path),
      arr: arrBredInfoEdit
    },
    {
      name: 'caculator-individual',
      regex: regexCaculatorIndividual.test(path),
      arr: arrCaculatorIndividual
    },
    {
      name: 'caculator-multiple',
      regex: regexCaculatorMultiple.test(path),
      arr: arrCaculatorMultiple
    },
    {
      name: 'caculator-detail',
      regex: regexCaculatorDetailMulti.test(path) || regexCaculatorDetailInvididual.test(path),
      arr: arrCaculatorDetail
    },
    {
      name: 'caculator-edit',
      regex: regexCaculatorEditMulti.test(path) || regexCaculatorEditInvididual.test(path),
      arr: arrCaculatorEdit
    },
    {
      name: 'formular-create',
      regex: regexCreateFormula.test(path),
      arr: arrFormulaCreate
    },
    /* {
      name: 'qrcode-detail',
      regex: regexQrList.test(path),
      arr: arrDetailDr
    },
    {
      name: 'qrcode-edit',
      regex: regexQrEdit.test(path),
      arr: arrEdtiQr
    }, */
    {
      name: 'customer-detail',
      regex: regexCustomDetail.test(path),
      arr: arrDetailCustomer
    },
    {
      name: 'customer-edit',
      regex: regexCustomEdit.test(path),
      arr: arrEditCustomer
    },
    {
      name: 'order-detail',
      regex: regexOrderDetail.test(path),
      arr: arrOrderDetail
    },
    {
      name: 'order-edit',
      regex: regexOrderEdit.test(path),
      arr: arrOrderEdit
    },
    {
      name: 'individual-create',
      regex: regexSerialIndividualCreate.test(path),
      arr: arrIndividualCreate
    },
    {
      name: 'individual-detail',
      regex: regexSerialIndividualDetail.test(path),
      arr: arrIndividualDetail
    },
    {
      name: 'individual-edit',
      regex: regexSerialIndividualEdit.test(path),
      arr: arrIndividualEdit
    },
    {
      name: 'bulk-create',
      regex: regexSerialBulkCreate.test(path),
      arr: arrBulkCreate
    },
    {
      name: 'bulk-detail',
      regex: regexSerialBulkDetail.test(path),
      arr: arrBulkDetail
    },
    {
      name: 'bulk-edit',
      regex: regexSerialBulkEdit.test(path),
      arr: arrBulkEdit
    },
    {
      name: 'change-password',
      regex: regexChangPass.test(path),
      arr: arrChangPass
    }
  ]
  listRegex.forEach((item) => {
    if (item?.regex) {
      arrTitle = item?.arr
      return
    }
  })
  return arrTitle
}
export {
  regexSensorManagementGroup,
  regexSensorManagementSensorDetail,
  arrBredGroup,
  arrBredSensorDetail,
  checkRegexTitleDetail
}
