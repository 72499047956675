export enum EKey {
  keyPrefixTv = 'Tv',
  keyPrefixTm = 'tm'
}

export enum EExpKeys {
  DEL_KEY = 'DEL',
  AC_KEY = 'AC',
  RAD_KEY = 'Rad',
  DEG_KEY = 'Deg'
}
export const EXP_KEYS: string[] = [
  EExpKeys.DEL_KEY,
  EExpKeys.AC_KEY,
  EExpKeys.RAD_KEY,
  EExpKeys.DEG_KEY
]
