import LoadingComponent from 'components/common/loading/LoadingComponent'
import ModalEnvironment from 'components/modal/ModalEnvironment'
import ModalSuccessComponent from 'components/modal/ModalSuccessComponent'
import i18n from 'configs/i18n'
import AdminLayoutContainer from 'containers/AdminLayoutContainer'
import AuthLayoutContainer from 'containers/AuthLayoutContainer'
import NotificationContainer from 'containers/NotificationContainer'
import RedirectRoutes from 'containers/RedirectRoutes'
import { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { AppState } from 'redux/reducers'
import { authSelector } from 'selectors/authSelectors/authSelector'
import instance from 'services/api/v1'
import AccessDeniedComponent from 'views/pages/accessDenied/AccessDeniedComponent'
import VerifyAdmin from 'views/pages/adminManagement/createAdminAccount/VerifyAdmin'
import ExpiredComponent from 'views/pages/expiredComponent/ExpiredComponent'
import NotFoundComponent from 'views/pages/notFound/NotFoundComponent'
import ResetPassword from 'views/pages/resetPassword/ResetPassword'
import PrivateRoute from 'views/routes/PrivateRoute'
import PublicRoute from 'views/routes/PublicRoute'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'

type Props = {
  authed: boolean
  accessToken: string
}

const App = ({ authed, accessToken }: Props) => {
  const success = useSelector((state: AppState) => state.common.success)
  const loading = useSelector((state: AppState) => state.common.isLoading)
  instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  // const [modal, setModal] = useState({
  //   isVisible: true,
  //   isComponent: false,
  //   content: i18n.t('modal.modalEnvironment.content'),
  //   btnClose: i18n.t('modal.modalEnvironment.btnClose')
  // })

  return (
    <>
      <NotificationContainer />
      <Switch>
        <PublicRoute authed={authed} path={routesName.LOGIN} component={AuthLayoutContainer} />
        <PublicRoute
          authed={authed}
          path={routesName.VERIFY_ADMIN_ACCOUNT}
          component={VerifyAdmin}
        />
        <PublicRoute authed={authed} path={routesName.RESET_PASSWORD} component={ResetPassword} />
        <PrivateRoute authed={authed} path={ADMIN_ROUTE} component={AdminLayoutContainer} />
        <Route exact path='/' component={RedirectRoutes} />
        <PublicRoute authed={authed} path={routesName.EXPIRED} component={ExpiredComponent} />
        <PublicRoute authed={authed} path='*' component={NotFoundComponent} />
        <PrivateRoute
          authed={authed}
          path={routesName.ACCESSDENIED}
          component={AccessDeniedComponent}
        />
      </Switch>
      {success?.isVisible ? (
        <ModalSuccessComponent
          isVisible={success?.isVisible}
          content={success?.content}
          isRedirect={success?.isRedirect ?? false}
          href={success?.href ?? ''}
          isImg={success?.isImg}
          src={success?.src}
          isLogout={success?.isLogout}
        />
      ) : null}
      {/* <ModalEnvironment
        isVisible={modal?.isVisible}
        content={modal?.content}
        onCloseModal={() => setModal({ ...modal, isVisible: false })}
        btnClose={modal?.btnClose}
      /> */}
      {loading ? <LoadingComponent isVisible={loading} /> : null}
    </>
  )
}

export default withRouter(connect(authSelector)(App))
