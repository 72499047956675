import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { AppState } from 'redux/reducers'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'

function RedirectRoutes() {
  const accessToken = useSelector((state: AppState) => state?.auth?.accessToken)
  return accessToken ? (
    <Redirect push to={ADMIN_ROUTE.concat(routesName.DASHBOARD)} />
  ) : (
    <Redirect push to={routesName?.LOGIN} />
  )
}
export default RedirectRoutes
