import {
  IGetFetchListDataOfUserDetail,
  IGetListEmailAlert,
  IUpdateEmailAlert,
  IUpdateUser,
  IUserDetail
} from 'services/params/userDetail/userDetail'
import { EUserDetailActions } from './EUserDetailAction'
import {
  IGetListDataOfUserDetailAction,
  IGetListEmailAlertAction,
  IUpdateEmailAlertAction,
  IUpdateUserAction,
  IUserDetailAction
} from './usersDetailTypes'

export const userDetailAction = (
  param: IUserDetail,
  callBack: (data: any) => void
): IUserDetailAction => ({
  type: EUserDetailActions.USER_DETAIL,
  param,
  callBack
})

export const getListEmailAlertAction = (
  param: IGetListEmailAlert,
  callBack: (data: any) => void
): IGetListEmailAlertAction => ({
  type: EUserDetailActions.GET_LIST_EMAIL_ALERT,
  param,
  callBack
})

export const getListDataOfUserDetailAction = (
  param: IGetFetchListDataOfUserDetail,
  callBack: (data: any) => void
): IGetListDataOfUserDetailAction => ({
  type: EUserDetailActions.FETCH_LIST_DATA_OF_USER_DETAIL,
  param,
  callBack
})
export const updateEmailAlertAction = (
  param: IUpdateEmailAlert,
  isLoading: boolean,
  callBack: (data: any) => void
): IUpdateEmailAlertAction => ({
  type: EUserDetailActions.UPDATE_EMAIL_ALERT,
  param,
  isLoading,
  callBack
})
export const updateUserAction = (
  param: IUpdateUser,
  isLoading: boolean,
  callBack: (data: any) => void
): IUpdateUserAction => ({
  type: EUserDetailActions.UPDATE_USER,
  param,
  isLoading,
  callBack
})
