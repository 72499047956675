import { AxiosResponse } from 'axios'
import { IData } from 'interfaces/IData'
import i18n from 'configs/i18n'
import { ILogin } from 'interfaces/ILogin'
import { INotification } from 'interfaces/INotification'
import { all, call, put, takeLatest, delay } from 'redux-saga/effects'
import { UpdateAuthAction } from 'redux/actions/auth/authAction'
import { ILoginAction } from 'redux/actions/auth/authTypes'
import { EAuthTypes } from 'redux/actions/auth/EAuthAction'
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { AuthApi } from 'services/api/auth/authApi'
import instance from 'services/api/v1'
import { checkStatus } from 'utils/services'
import { DELAY } from 'interfaces/enums/EDelay'
import { AES } from 'crypto-js'
import { ERoles } from 'interfaces/enums/ERoles'

function* loginSaga(action: ILoginAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    yield put(SetLoadingAction(true))
    const response: AxiosResponse<IData<ILogin>> = yield call(AuthApi.login, action.loginParams)
    if (response?.data?.error && response?.data?.error?.code !== 400) {
      err = response?.data?.error
      action.callBack(err?.code)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
    const data = checkStatus<ILogin>(response)
    if (data) {
      const accessToken = data.accessToken
      const expiresin = data.expiresIn
      instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      const role = data?.user?.role?.roleName
      const cipherRole = AES.encrypt(role, ERoles.SECRET_KEY).toString()
      const username = data?.user?.username
      action.callBack(data)
      yield put(UpdateAuthAction(true, expiresin, accessToken, cipherRole, username))
    }
  } catch (_error) {
    err = _error
    return Promise.reject(_error)
  } finally {
    if (err && err?.code !== 401 && err?.code !== 400) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}

export default function* authSaga() {
  yield all([takeLatest(EAuthTypes.LOGIN, loginSaga)])
}
