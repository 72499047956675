import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import i18n from 'configs/i18n'
import { API_URL } from 'constants/general'
import { ERROR_TYPE } from 'interfaces/enums/ErrorTypes'
import { mapKeys, snakeCase } from 'lodash'
import { LogoutAction, PasswordChangedAction, TokenTimeoutAction } from 'redux/actions/auth/authAction'
import { SetNotificationAction } from 'redux/actions/common/commonAction'
import { PERSIST_ROOT, store } from 'redux/store'
import { checkError, getToken } from 'utils/Functions'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'

const DEFAULT_API_CONFIG: AxiosRequestConfig = {
  baseURL: API_URL,
  timeout: 30000
}
const instance = axios.create({
  ...DEFAULT_API_CONFIG
})

const accessToken = getToken()

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  config.data = mapKeys(config.data, (_, key) => snakeCase(key))
  return config
})

instance.interceptors.response.use(
  (response: any) => {
    if(response?.data?.error?.code === ERROR_TYPE.UNAUTHENTICATED){
      if(response?.data?.error.message === 'Password changed'){
        return passwordChanged();
      }
      if(response?.data?.error.message === 'Token timeout'){
        return tokenTimeout();
      }
    }
    if (response?.data?.error?.code === ERROR_TYPE.UNAUTHENTICATED && accessToken) {
      logout()
      return 0
    }
    if (response?.data?.error?.code === ERROR_TYPE.ACCESSDENIED) {
      window.location.replace(ADMIN_ROUTE.concat(routesName.ACCESSDENIED))
    }
    if (response?.data?.error?.code === ERROR_TYPE.NOT_FOUND) {
      window.location.replace(ADMIN_ROUTE.concat(routesName.NOT_FOUND))
    }
    return response
  },
  (error: AxiosError) => {
    if (
      error.response?.data?.errorType === ERROR_TYPE.ACCESS_TOKEN_EXPIRED ||
      error.response?.data?.errorType === ERROR_TYPE.UNAUTHORIZED
    )
      return logout()
    if (error.response) {
      throw error.response
    }
    if (error.message && error.message === 'Network Error') {
      throw Object.assign(
        checkError(ERROR_TYPE.ERR_INTERNET_DISCONNECTED, i18n.t('errors.internet'))
      )
    }
    if (error.request) {
      throw Object.assign(checkError(ERROR_TYPE.BAD_REQUEST, i18n.t('errors.badRequest')))
    }
    throw error?.message || i18n.t('errors.wrong')
  }
)

export const logout = () => {
  // handle logout
  store.dispatch(SetNotificationAction(null))
  store.dispatch(LogoutAction())
  localStorage.removeItem(PERSIST_ROOT)
  window.location.replace('/login')
}


export const passwordChanged = () => {
  store.dispatch(PasswordChangedAction())
}

export const tokenTimeout = () => {
  store.dispatch(TokenTimeoutAction())
}

export default instance
