// list sensor
const convertDataListSensorOption = (data: any) => {
  data = data?.map((item: any, index: number) => {
    return {
      id: item?.sensorAiId,
      name: item?.sensorName,
      value: item?.sensorAiId,
      parentId: item?.deviceId
    }
  })
  return data
}
export { convertDataListSensorOption }
