import i18n from 'configs/i18n'
import { validCreateUser } from './InputFieldValidate'

const formRegister = [
  {
    label: i18n.t('adminManagement.nameLabel'),
    name: 'adminName',
    rules: validCreateUser.userName,
    type: 'text',
    maxLength: 64,
    isRequired: true
  },
  {
    label: i18n.t('adminManagement.emailLabel'),
    name: 'emailAcc',
    rules: validCreateUser.email,
    type: 'text',
    maxLength: 255,
    isRequired: true
  },
  /* {
    label: i18n.t('modal.userManagement.create.lable.password'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.password'),
    name: 'password',
    rules: validCreateUser.password,
    type: 'password',
    maxLength: 32,
    isRequired: true
  },
  {
    label: i18n.t('modal.userManagement.create.lable.passwordConfirm'),
    placeholder: i18n.t(
      'modal.userManagement.create.placeholder.passwordConfirm'
    ),
    name: 'confirm',
    rules: validCreateUser.confirmPassword,
    type: 'confirmPassword',
    maxLength: 32,
    isRequired: true
  }, */
  {
    label: i18n.t('adminManagement.descriptionLabel'),
    name: 'department',
    rules: validCreateUser.department,
    type: 'text',
    maxLength: 100,
    isRequired: true
  }
]
const formEdit = [
  {
    label: i18n.t('adminManagement.nameLabel'),
    name: 'userName',
    rules: validCreateUser.userName,
    type: 'text',
    maxLength: 64,
    isRequired: true
  },
  {
    label: i18n.t('adminManagement.emailLabel'),
    name: 'email',
    rules: validCreateUser.email,
    type: 'text',
    maxLength: 255,
    isRequired: true
  },
  {
    label: i18n.t('adminManagement.descriptionLabel'),
    name: 'department',
    rules: validCreateUser.department,
    type: 'text',
    maxLength: 100,
    isRequired: true
  }
]

const formForgotPassword = [
  {
    label: i18n.t('fogetPasswordScreen.titleInput'),
    name: 'email',
    rules: validCreateUser.email,
    type: 'text',
    maxLength: 255,
    isRequired: true
  }
]
export { formRegister, formEdit, formForgotPassword }
