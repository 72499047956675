import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ItemComponent from './ItemComponent'
import 'styles/GroupDetail.scss'
import { useState, useEffect } from 'react'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'
import { columnsGroupDetail } from './defaultColumn'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'
import TableComponent from 'components/common/table/TableComponent'
import { useDispatch } from 'react-redux'
import { groupDetailAction } from 'redux/actions/group/groupDetailAction'
import { convertDataGroupDetail, convertListSensorByGroup } from './convertData'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import { IDataListTableGroupDetail, IGroupDetail } from 'interfaces/group/IGroup'
import { initialGroupDetail } from './initialState'
interface IParamTypes {
  id: string
}
const getData = (current: number, pageSize: number, data: IDataListTableGroupDetail[]) => {
  if (data) {
    return data.slice((current - 1) * pageSize, current * pageSize)
  }
}
function GroupDetailComponent() {
  const dispatch = useDispatch()
  // param id group
  const { id } = useParams<IParamTypes>()
  // state group detail
  const [groupDetail, setGroupDetail] = useState<IGroupDetail>(initialGroupDetail)
  // state pagination
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  // list data
  const [dataTable, setDataTable] = useState<IDataListTableGroupDetail[]>([])
  const { t } = useTranslation()

  const handleOnChangePage = (pageChange: number) => {
    setCurrent(pageChange)
  }
  const handleOnChangePageSize = (size: number) => {
    const totalPageChange = Math.ceil(dataTable?.length / size)
    if (current > totalPageChange) {
      setCurrent(totalPageChange)
    }
    setPageSize(size)
  }
  useEffect(() => {
    dispatch(SetLoadingAction(true))
    const param = {
      groupId: id
    }
    dispatch(
      groupDetailAction({ ...param }, (data) => {
        if (data?.result) {
          const convertDataSensor = convertListSensorByGroup(data?.result?.listSensorByGroup)
          if (data?.result?.groupDetail) {
            const dataGroupDetail = convertDataGroupDetail(data?.result?.groupDetail)
            setGroupDetail(dataGroupDetail)
          }
          setDataTable(convertDataSensor)
          dispatch(SetLoadingAction(false))
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
  }, [id, dispatch])
  return (
    <div className={`bgrffffff ${dataTable?.length ? 'group-detail-management' : ''}`}>
      <div className='group-detail pd_16x_0x'>
        <div className='mg_0_16x'>
          <div className='group-detail-item'>
            <Row>
              <Col span={12}>
                <ItemComponent label={t('groupDetail.userName')} value={groupDetail?.userName} />
                <ItemComponent label={t('groupDetail.groupName')} value={groupDetail?.groupName} />
                <ItemComponent label={t('groupDetail.area')} value={groupDetail?.area} />
                <ItemComponent
                  label={t('groupDetail.numberSensor')}
                  value={groupDetail?.numberSensor}
                />
              </Col>
              <Col span={12}>
                <ItemComponent
                  label={t('groupDetail.emailAddress')}
                  value={groupDetail?.email}
                  isHref={true}
                  href={ADMIN_ROUTE.concat(
                    routesName.USER_MANAGEMENT + `/user/${groupDetail?.userId}`
                  )}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className='group-table'>
          <div className='mg_0_16x'>
            <div className='d-flex justify-content-flex-end mg-pagination'>
              <div className='group-pagination'>
                <PaginationComponent
                  onChangePageSize={(size) => handleOnChangePageSize(size)}
                  total={dataTable?.length}
                  onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                  pageSize={pageSize}
                  isShowSizeChanger={false}
                  currentpage={current}
                />
              </div>
            </div>
          </div>
          <div className='custom-table'>
            <TableComponent
              columns={columnsGroupDetail}
              dataSource={getData(current, pageSize, dataTable)}
            />
            <div className='mg_0_16x'>
              <div className='mg-pagination'>
                <PaginationComponent
                  onChangePageSize={(size) => handleOnChangePageSize(size)}
                  total={dataTable?.length}
                  onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                  pageSize={pageSize}
                  isShowSizeChanger={true}
                  currentpage={current}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='btn-return text-align-center'>
          <ButtonReturnComponent />
        </div>
      </div>
    </div>
  )
}
export default GroupDetailComponent
