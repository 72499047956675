import { Layout, Menu } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import { IconMiharas, IconPlus, IconPlusWhite } from 'assets'
import VBreadcrumb from 'components/breadcrumb/VBreadcrumb'
import VFooter from 'components/footer/VFooter'
import VHeaderContainer from 'containers/VHeaderContainer'
import { originalRole } from 'interfaces/enums/ERoles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { getMasterData } from 'redux/actions/common/commonAction'
import { setAdminCreateAction, setUserCreateAction } from 'redux/actions/create/createActions'
import { AppState } from 'redux/reducers'
import classes from 'styles/AdminLayout.module.scss'
import { checkRegexTitleDetail } from 'views/routes/Breadscrumb'
import i18n from 'configs/i18n'
import { LogoutAction } from 'redux/actions/auth/authAction'
import {
  ADMIN_ROUTE,
  childrenRoutes,
  routes,
  routesName,
  checkRootKey,
  rootSubmenuKeys,
  routesUserManagement
} from 'views/routes/routes'
import ModalUnAuthentication from 'components/modal/ModalUnAuthentication'
import { useIdleTimer } from 'react-idle-timer'

const { Content, Sider } = Layout

const getContentRoute = (
  <Switch>
    {routes.map((route) => {
      if (route.subMenu && route.subMenu?.length > 0) {
        return route.subMenu.map((subRoute) => {
          return (
            <Route
              exact
              path={ADMIN_ROUTE.concat(subRoute?.path)}
              component={subRoute.component}
              key={subRoute.key}
            />
          )
        })
      } else {
        return (
          <Route
            exact
            path={ADMIN_ROUTE.concat(route.path ?? '')}
            component={route.component}
            key={route.id}
          />
        )
      }
    })}
    {childrenRoutes.map((route) => (
      <Route path={ADMIN_ROUTE.concat(route.path)} component={route.component} key={route.id} />
    ))}
  </Switch>
)

const AdminLayout = () => {
  const history = useHistory()
  const location = useLocation()
  const [defaultSelectedRoute, setDefaultSelectedRoute] = useState('')
  const [openKeys, setOpenKeys] = useState(['dashboard'])
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  const dispatch = useDispatch()
  const checkUserCreate = useSelector((state: AppState) => state?.create?.userCreate)
  const checkAdminCreate = useSelector((state: AppState) => state?.create?.adminCreate)
  const checkPasswordChanged = useSelector((state: AppState) => state?.auth.passwordChanged)
  const checkTokenTimeout = useSelector((state: AppState) => state?.auth.tokenTimeout)
  // Hook
  const logoutTimer = useIdleTimer({
    timeout: 30 * 60 * 1000, // 30min = 1800000 minilseconds
    crossTab: true,
    syncTimers: 200,
    name: 'logout-timer',
    onIdle: () => {
        console.log("Session timout");
        dispatch(LogoutAction());
    }
  })

  useEffect(() => {
    dispatch(getMasterData())
  }, [dispatch])

  const [modalPasswordChanged, setModalPwdChanged] = useState({
    isVisible: true,
    isComponent: false,
    content: i18n.t('modal.passwordChanged.content'),
    btnClose: i18n.t('modal.passwordChanged.btnLogin')
  })

  const [modalTokenTimeout, setModalTokenTimeout] = useState({
    isVisible: true,
    isComponent: false,
    content: i18n.t('modal.tokenTimeout.content'),
    btnClose: i18n.t('modal.tokenTimeout.btnLogin')
  })


  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }
  useEffect(() => {
    const idRegex = checkRootKey(location?.pathname)
    setOpenKeys([idRegex])
  }, [location.pathname])
  useEffect(() => {
    const arrCheck = checkRegexTitleDetail(location.pathname)
    if (arrCheck?.length) {
      setDefaultSelectedRoute('')
    } else {
      let routeSelected = ''
      routes.forEach((route) => {
        route?.subMenu?.forEach((itemSub: any) => {
          if (ADMIN_ROUTE.concat(itemSub.path) === location.pathname) {
            if (checkUserCreate) {
              routeSelected = 'userSub1'
              return false
            } else if (checkAdminCreate) {
              routeSelected = 'adminSub1'
              return false
            } else {
              routeSelected = itemSub?.key
              return false
            }
          }
        })
      })
      setDefaultSelectedRoute(routeSelected)
    }
    // eslint-disable-next-line
  }, [location.pathname, checkUserCreate, checkAdminCreate])
  const handleClickLogo = () => {
    setOpenKeys([])
    setDefaultSelectedRoute('')
    history.push(ADMIN_ROUTE.concat(routesName.DASHBOARD))
  }

  const handleSwitchRoute = (route: any) => {
    if (
      route?.isCreate &&
      ADMIN_ROUTE.concat(route?.path) === ADMIN_ROUTE.concat(routesUserManagement.USER_LIST)
    ) {
      dispatch(setUserCreateAction({ userCreate: true }))
    } else if (
      route?.isCreate &&
      ADMIN_ROUTE.concat(route?.path) === ADMIN_ROUTE.concat(routesName.ADMIN_MANAGEMENT)
    ) {
      dispatch(setAdminCreateAction({ adminCreate: true }))
    } else {
      dispatch(setUserCreateAction({ userCreate: false }))
      dispatch(setAdminCreateAction({ adminCreate: false }))
    }
    setDefaultSelectedRoute(route?.key)
    history.push(ADMIN_ROUTE.concat(route.path))
  }

  const handleLogout = () => {
    dispatch(LogoutAction());
    window.location.replace('/login');
  }
  const renderTitle = (route: any) => {
    return (
      <div className='flex j-between ai-center'>
        <div className='font-w-bold fs-12x'>
          <img
            src={openKeys[0] === route.key ? route.activeIcon : route.icon}
            className={classes.icRoute}
            alt='active'
          />
          {route.title}
        </div>
        <img
          src={openKeys[0] === route.key ? IconPlusWhite : IconPlus}
          alt='active'
          style={styles.iconPlus}
        />
      </div>
    )
  }
  const _renderItemRoute = (roleCheck: string) => {
    return (
      <div>
        <Menu
          mode='inline'
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={[defaultSelectedRoute]}>
          <Menu.Item className='custom-logo-miharas' key='dashboard'>
            <div className={classes.logoContainer}>
              <img
                onClick={handleClickLogo}
                className={classes.logo}
                src={IconMiharas}
                alt='logo'
              />
            </div>
          </Menu.Item>
          {routes.map((item) =>
            item?.isShow ? (
              <SubMenu key={item?.key} title={renderTitle(item)}>
                {item?.subMenu?.map((itemSub: any) =>
                  itemSub?.isShow && itemSub?.role?.includes(roleCheck) ? (
                    <Menu.Item
                      style={styles.itemRoute}
                      key={itemSub?.key}
                      onClick={() => handleSwitchRoute(itemSub)}>
                      {itemSub?.dividerLine && itemSub?.dividerLine === true ? (
                        <div>
                          {itemSub?.title}
                          {/* <Menu.Divider style={styleDividerLine} /> */}
                        </div>
                      ) : (
                        <div>{itemSub?.title}</div>
                      )}
                    </Menu.Item>
                  ) : null
                )}
              </SubMenu>
            ) : null
          )}
        </Menu>
      </div>
    )
  }


  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={234} style={styles.sideBar}>
        <div className='custom-submenu'>{_renderItemRoute(role)}</div>
      </Sider>
      <Layout className='rs-tablet-mobile'>
        <VHeaderContainer />
        <VBreadcrumb />
        <Content style={styles.content}>{getContentRoute}</Content>
        <VFooter />
      </Layout>
      <ModalUnAuthentication
        isVisible={checkTokenTimeout}
        content={modalTokenTimeout?.content}
        onCloseModal={() => handleLogout()}
        btnClose={modalTokenTimeout?.btnClose}
      />
      <ModalUnAuthentication
        isVisible={checkPasswordChanged}
        content={modalPasswordChanged?.content}
        onCloseModal={() => handleLogout()}
        btnClose={modalPasswordChanged?.btnClose}
      />
    </Layout>
  )
}

const styles = {
  itemRoute: { height: 40, marginTop: 0, fontSize: 12 },
  content: {
    margin: '0px 16px 30px 16px'
  },
  sideBar: {
    backgroundColor: '#fff',
    boxShadow: '2px 0px 15px rgba(167, 167, 167, 0.2)'
  },
  iconPlus: { width: 16, height: 16 }
}
// const styleDividerLine = {
//   borderColor: '#DFDFDF'
// }

export default AdminLayout
