import { useEffect, useState } from 'react'
import { Form, Col, Modal } from 'antd'
import VButton from 'components/button/VButton'
import FormItem from 'components/common/form/FormItem'
import { useTranslation } from 'react-i18next'
import { formEdit } from '../../views/pages/adminManagement/AccountForm'
import SelectComponent from 'components/common/select/SelectComponent'
import { IOption } from 'interfaces/sensor/IPropsSelect'
import { defaultPermission } from '../../views/pages/adminManagement/defaultValue'
import { useDispatch } from 'react-redux'
import { updateUserAction } from 'redux/actions/userDetail/usersDetailAction'
import { SetLoadingAction } from 'redux/actions/common/commonAction'

interface IEditUser {
  title: string
  onCancelModal: () => void
  onSubmitModal: (update: any) => void
  initialValue: any
}
function ModalEditAccountComponent({
  title,
  onCancelModal,
  onSubmitModal,
  initialValue
}: IEditUser) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  // set item list for dropdown
  const [listPermission, setListPermission] = useState<IOption[]>([])
  const [permission, setPermission] = useState<string>(initialValue?.authority)

  const isMaster = initialValue?.authority === "master";

  const handleShowPermission = (authority: string) => {
    setPermission(authority)
  }
  const onFinish = (values: any) => {
    const param = {
      id: initialValue?.adminId,
      username: values?.userName,
      email: values?.email,
      role: permission,
      department: values?.department,
      statusUser: initialValue?.statusUser
    }
    dispatch(
      updateUserAction({ ...param }, true, (data) => {
        if (data?.error) {
          if (data?.error?.code === 400) {
            form.setFields([
              {
                name: data?.error?.data?.email ? 'email' : '',
                errors: [
                  data?.error?.data?.email[0]
                    ? t(`errors.message.${data?.error?.data?.email[0]}`)
                    : ''
                ]
              }
            ])
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        } else {
          if (data?.result) {
            const userUpdate = {
              userName: data?.result?.username,
              email: data?.result?.email,
              key: initialValue?.adminId,
              department: data?.result?.department,
              authority: data?.result?.role?.roleName
            }
            if (onSubmitModal) {
              onSubmitModal(userUpdate)
            }
          }
        }
      })
    )
  }
  useEffect(() => {
    setListPermission(defaultPermission)
  }, [])
  // check disable button confirm when input field are empty
  const checkDisabled = () => {
    if (
      form.getFieldValue('userName') &&
      form.getFieldValue('email') &&
      form.getFieldValue('department') &&
      permission === ''
    ) {
      return !!form
        .getFieldsError()
        .slice(0, 2)
        .filter(({ errors }) => errors.length).length
    } else {
      if (
        !form.getFieldValue('userName') ||
        !form.getFieldValue('email') ||
        !form.getFieldValue('department') ||
        !permission
      ) {
        return true
      }
      return !!form.getFieldsError().filter(({ errors }) => errors.length).length
    }
  }
  const onValuesChange = (values: any) => {
    if (values?.email) {
      form.setFieldsValue({ email: values?.email?.split(' ').join('') })
    }
  }

  return (
    <Modal
      visible={true}
      centered={true}
      footer={null}
      maskClosable={false}
      onCancel={onCancelModal}>
      <div className='new-regis-form'>
        <div className='form-title text-align-center font-w-bold fs-16x'>{title}</div>
        <Form
          form={form}
          autoComplete='off'
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          initialValues={{
            ...initialValue
          }}>
          {formEdit?.slice(0, 2).map((item) => (
            <div key={item?.name}>
              <FormItem
                label={item?.label}
                name={item?.name}
                type={item?.type}
                rules={item?.rules}
                maxLength={item?.maxLength}
                isRequired={item?.isRequired}
                disabled={isMaster && item.name === "email"}
              />
            </div>
          ))}
          <Col span={24} className='mt-16x'>
            <div className='label-bold'>
              権限 <span>*</span>
            </div>
            <SelectComponent
              disableAll={true}
              defaultOption={isMaster ? '初期管理者' : permission}
              dataOption={listPermission}
              onChangeSelect={handleShowPermission}
              disableSelect={isMaster}
            />
          </Col>
          {formEdit?.slice(-1).map((item) => (
            <div key={item?.name}>
              <FormItem
                label={item?.label}
                name={item?.name}
                type={item?.type}
                rules={item?.rules}
                maxLength={item?.maxLength}
                isRequired={item?.isRequired}
              />
            </div>
          ))}
          <div className='text-align-center d-flex justify-content-center'>
            <Form.Item shouldUpdate>
              {() => (
                <VButton
                  disabled={checkDisabled()}
                  style={styleBtnSubmit}
                  type='text'
                  title={t('modal.btnConfirm')}
                />
              )}
            </Form.Item>
            <VButton
              title={t('modal.btnCancel')}
              style={styleBtnCancel}
              type='text'
              htmlType='button'
              onClick={() => onCancelModal()}
            />
          </div>
        </Form>
      </div>
    </Modal>
  )
}
const styleBtnSubmit = {
  width: 74,
  height: 38,
  marginRight: 10,
  color: '#ffffff',
  marginTop: 15
}
const styleBtnCancel = {
  width: 116,
  height: 38,
  marginLeft: 10,
  background: '#ffffff',
  border: '1px solid #D7000F',
  color: '#D7000F',
  marginTop: 15
}
export default ModalEditAccountComponent
