import { ColumnsType } from 'antd/lib/table'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import { IDataListTableSensorOfOutages } from 'interfaces/sensor/list/ISensorList'
import { Link } from 'react-router-dom'
import { ConvertTime } from 'utils/Functions'
import {
  // defaultTypeSensorLTE,
  defaultShowBlank
  // defaultTypeSensorLora
} from 'views/pages/sensor/defaultValue'
import { ADMIN_ROUTE, routesSensorManagement } from 'views/routes/routes'
import i18n from 'configs/i18n'

// render colums
const columnsSensorOfOutages: ColumnsType<IDataListTableSensorOfOutages> = [
  {
    title: i18n.t('tableOutages.no'),
    dataIndex: 'no',
    width: '6%'
  },
  {
    title: i18n.t('tableOutages.customerName'),
    dataIndex: 'customerName',
    render: (_: object, record: { customerName: string }) => {
      return (
        <TooltipComponent
          maxSlice={18}
          title={record?.customerName ? record?.customerName : defaultShowBlank}
        />
      )
    }
  },
  {
    title: i18n.t('tableOutages.groupName'),
    dataIndex: 'groupName',
    render: (_: object, record: { groupName: string }) => {
      return (
        <TooltipComponent
          maxSlice={18}
          title={record?.groupName ? record?.groupName : defaultShowBlank}
        />
      )
    }
  },
  {
    title: i18n.t('tableOutages.deviceID'),
    dataIndex: 'deviceID',
    width: '17%',
    render: (_: object, record: { key: string; deviceID: string }) => {
      return (
        <div className='pointer custom-a cls_3EACFF text-underline'>
          <Link
            to={ADMIN_ROUTE.concat(routesSensorManagement.SENSOR_DETAIL + `/${record?.deviceID}`)}>
            {record?.deviceID}
          </Link>
        </div>
      )
    }
  },
  {
    title: i18n.t('tableOutages.sensorName'),
    dataIndex: 'sensorName',
    render: (_: object, record: { sensorName: string }) => {
      return (
        <TooltipComponent
          maxSlice={18}
          title={record?.sensorName ? record?.sensorName : defaultShowBlank}
        />
      )
    }
  },
  {
    title: i18n.t('tableOutages.sensorType'),
    dataIndex: 'sensorType',
    render: (_: object, record: { sensorType: string }) => {
      return <span>{record?.sensorType ? record?.sensorType : defaultShowBlank}</span>
    }
  },
  {
    title: i18n.t('tableOutages.lteOrLora'),
    dataIndex: 'lteOrLora',
    render: (_: object, record: { lteOrLora: string; imeiNumber: string }) => {
      return <span>{record?.lteOrLora}</span>
    }
  },
  {
    title: i18n.t('tableOutages.dateTimeOfOutages'),
    dataIndex: 'dateTimeOfOutages',
    render: (_: object, record: { dateTimeOfOutages: string }) => {
      return record?.dateTimeOfOutages ? ConvertTime(record?.dateTimeOfOutages) : defaultShowBlank
    }
  }
]
export { columnsSensorOfOutages }
