import { ILoginParams } from 'services/params/ILoginParams'
import { ILoginAction, ILogoutAction,ITokenTimeoutAction, IPasswordChangedAction, IUpdateAuthAction } from './authTypes'
import { EAuthTypes } from './EAuthAction'

export const LoginAction = (
  loginParams: ILoginParams,
  callBack: (value: any) => void
): ILoginAction => ({
  type: EAuthTypes.LOGIN,
  loginParams,
  callBack,
  isLoading: false
})

export const UpdateAuthAction = (
  authed: boolean,
  expiresIn: number,
  accessToken: string,
  role: string,
  username: string
): IUpdateAuthAction => ({
  type: EAuthTypes.UPDATE_AUTH,
  authed,
  expiresIn,
  accessToken,
  role,
  username
})

export const LogoutAction = (): ILogoutAction => ({
  type: EAuthTypes.LOGOUT
})


export const TokenTimeoutAction = (): ITokenTimeoutAction => ({
  type: EAuthTypes.TOKEN_TIMEOUT,
})

export const PasswordChangedAction = (): IPasswordChangedAction => ({
  type: EAuthTypes.PASSWORD_CHANGED,
})
