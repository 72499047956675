import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { DELAY } from 'interfaces/enums/EDelay'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, call, delay, put, takeLatest } from 'redux-saga/effects'
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { EQrcodeActions } from 'redux/actions/qrcode/EQrcodeAction'
import {
  deleteQrAction,
  setDetailQrAction,
  setQrList,
  setQrListSearch
} from 'redux/actions/qrcode/qrcodeAction'
import {
  ICreateQrcodeType,
  IDetailQrcodeType,
  IEditQrcodeType
} from 'redux/actions/qrcode/qrcodeTypes'
import { qrcodeApi } from 'services/api/qrcode/qrcodeApi'

function* createQrCode(action: ICreateQrcodeType) {
  yield put(SetLoadingAction(true))
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(qrcodeApi.createQr, action.param)
    const result = response?.data?.result
    if (result) {
      yield put(SetLoadingAction(false))
      action.callback(undefined)
    } else {
      yield put(SetLoadingAction(false))
      action.callback('製造番号が既に登録されています。')
    }
  } catch (error) {
    err = error
    return Promise.reject(err)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

function* editQrCode(action: IEditQrcodeType) {
  let err: any = null
  yield put(SetLoadingAction(true))
  try {
    const response: AxiosResponse<IData<any>> = yield call(qrcodeApi.editQr, action.param)
    const result = response?.data?.result
    if (result) {
      action.callback(undefined)
      if (action.param.type === 'delete') {
        yield put(deleteQrAction(action.param.id))
      }
      yield put(SetLoadingAction(false))
    } else {
      yield put(SetLoadingAction(false))
      action.callback('製造番号が既に登録されています。')
    }
  } catch (error) {
    err = error
    return Promise.reject(err)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

function* getListQrCode() {
  let err: any = null
  yield put(SetLoadingAction(true))
  try {
    const response: AxiosResponse<IData<any>> = yield call(qrcodeApi.getListQr)
    const result = response?.data?.result
    if (result) {
      yield put(setQrList(result?.record || []))
      yield put(setQrListSearch(result?.record || []))
      yield put(SetLoadingAction(false))
    } else {
      err = response?.data?.error
    }
  } catch (error) {
    err = error
    return Promise.reject(err)
  } finally {
    yield put(SetLoadingAction(false))
  }
}

function* detailQrCode(action: IDetailQrcodeType) {
  let err: any = null
  yield put(SetLoadingAction(true))
  try {
    const response: AxiosResponse<IData<any>> = yield call(qrcodeApi.detailQr, action.id)
    const result = response?.data?.result
    if (result) {
      yield put(setDetailQrAction(result))
      yield delay(DELAY.DELAYSUCCESS)
      yield put(SetLoadingAction(false))
    } else {
      err = response?.data?.error
    }
  } catch (error) {
    err = error
    return Promise.reject(err)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

export default function* qrcodeSaga() {
  yield all([
    takeLatest(EQrcodeActions.CREATE_QRCODE, createQrCode),
    takeLatest(EQrcodeActions.GET_LIST_QR, getListQrCode),
    takeLatest(EQrcodeActions.EDIT_QR, editQrCode),
    takeLatest(EQrcodeActions.GET_DETAIL_QR, detailQrCode)
  ])
}
