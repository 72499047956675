import { Form, Modal } from 'antd'
import VButton from 'components/button/VButton'
import FormItem from 'components/common/form/FormItem'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import { addCustomerDataAction } from 'redux/actions/customer/customerAction'
import 'styles/ModalAddCustomerInfo.scss'
import { customerCreateRules } from 'views/pages/customer/rules'
interface IProps {
  onCancelModal: () => void
  onSubmitModal: (value: any) => void
}

function ModalAddDealer({ onCancelModal, onSubmitModal }: IProps) {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // submit create user form
  const onFinish = (value: any) => {
    const params = {
      typeData: 1,
      nameJp: value?.addDealers?.trim() ?? ''
    }
    dispatch(
      addCustomerDataAction(
        { ...params },
        (data) => {
          if (data && data?.code === 400) {
            form.setFields([
              {
                name: 'addDealers',
                errors: [t(`errors.message.FRE00022`)]
              }
            ])
            dispatch(SetLoadingAction(false))
          } else {
            onSubmitModal(data?.result)
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  return (
    <Modal
      visible={true}
      centered={true}
      footer={null}
      maskClosable={false}
      onCancel={onCancelModal}
      zIndex={99999999}>
      <div className='create-container'>
        <Form form={form} onFinish={onFinish}>
          <div className='add-info'>
            <FormItem
              label={t('customerScreen.addDealers')}
              name='addDealers'
              type='text'
              maxLength={255}
              rules={customerCreateRules.addDealers}
            />
          </div>
          <div className='btn-group'>
            <Form.Item shouldUpdate>
              {() => (
                <VButton
                  disabled={
                    !form.getFieldValue('addDealers') ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                  style={styleBtnSubmit}
                  type='text'
                  title={t('customerScreen.btnConfirmAddSalesOrDealers')}
                />
              )}
            </Form.Item>
            <Form.Item>
              <VButton
                title={t('customerScreen.btnCancel')}
                style={styleBtnCancel}
                type='text'
                htmlType='button'
                onClick={() => onCancelModal()}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  )
}
const styleBtnSubmit = {
  width: 74,
  height: 38,
  color: '#ffffff'
}
const styleBtnCancel = {
  backgroundColor: '#fff',
  color: '#d7000f',
  border: 'solid 1px #d7000f',
  height: 38,
  marginLeft: 24
}

export default ModalAddDealer
