import { accessDenied } from 'assets'
import { useTranslation } from 'react-i18next'
function AccessDeniedComponent() {
  const { t } = useTranslation()
  return (
    <>
      <div>
        <img style={styleImgDenied} src={accessDenied} alt='access-denied' />
      </div>
      <div
        className='text-align-center'
        style={{ fontSize: 24, fontWeight: 'bold', marginTop: 24 }}>
        {t('accessDenied.title')}
      </div>
      <div
        className='text-align-center'
        style={{ fontSize: 20, fontWeight: 'normal', marginTop: 12 }}>
        {t('accessDenied.description')}
      </div>
    </>
  )
}

const styleImgDenied = {
  width: '100%',
  height: '200px',
  marginTop: 100
}
export default AccessDeniedComponent
