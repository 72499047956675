import i18n from 'configs/i18n'
import { IconDeleteBtn, IconDetailBtn } from 'assets'
import moment from 'moment'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import Sortable from './sortable'

const BLANK = '---'

export const CONFIRMED = {
  false: '未',
  true: '済'
}

export const column = (
  currentPage: number,
  pageSize: number,
  handleOnClickEdit: any,
  handleOnClickDelete: any,
  callbackOnSort: (order: "ASC" | "DESC")=>void,
) => {
  const columns = [
    {
      title: i18n.t('ListSerialNoScreen.TableLabel.firstCol'),
      dataIndex: 'id',
      align: 'left',
      render: (text: any, row: any, index: any) => {
        return <span>{(currentPage - 1) * pageSize + (index + 1)}</span>
      }
    },
    {
      title: (<Sortable title={i18n.t('ListSerialNoScreen.TableLabel.secondCol')} onSort={(method) => {
        if (callbackOnSort) callbackOnSort(method);
      }} /> ),
      dataIndex: '',
      align: 'left',
      render: (item: any) => {
        return <TooltipComponent maxSlice={12} title={item?.serialNoNumber} />
      }
    },
    {
      title: i18n.t('ListSerialNoScreen.TableLabel.thirdCol'),
      dataIndex: '',
      align: 'left',
      width: '10%',
      render: (item: any) => {
        return (
          <TooltipComponent
            maxSlice={10}
            title={
              item?.ordDetails?.order?.customer?.customerName
                ? item?.ordDetails?.order?.customer?.customerName
                : BLANK
            }
          />
        )
      }
    },
    {
      title: i18n.t('ListSerialNoScreen.TableLabel.fourthCol'),
      dataIndex: '',
      align: 'left',
      render: (item: any) => {
        return <TooltipComponent maxSlice={6} title={item?.deviceId ? item?.deviceId : BLANK} />
      }
    },
    {
      title: i18n.t('ListSerialNoScreen.TableLabel.fifthCol'),
      key: 'id',
      dataIndex: '',
      align: 'left',
      render: (item: any) => item.sensorType.sensorTypeKey
    },
    {
      title: i18n.t('ListSerialNoScreen.TableLabel.sixthCol'),
      dataIndex: '',
      width: '11%',
      align: 'left',
      render: (item: any) => item.sensorTypeTranslate.sensorTypeName
    },
    {
      title: i18n.t('ListSerialNoScreen.TableLabel.seventhCol'),
      dataIndex: '',
      align: 'left',
      width: '11%',
      render: (item: any) => {
        const text =
          item.sensorTypeTranslateId === 14
            ? item.securityKey || item.IMEINumber || BLANK
            : item.IMEINumber || item.securityKey || BLANK
        return <TooltipComponent maxSlice={10} title={text} />
      }
    },
    {
      title: i18n.t('ListSerialNoScreen.TableLabel.eighthCol'),
      dataIndex: 'productSite',
      align: 'left'
    },
    {
      title: i18n.t('ListSerialNoScreen.TableLabel.ninethCol'),
      dataIndex: '',
      align: 'left',
      render: (item: any) => (item.confirmed ? CONFIRMED.true : CONFIRMED.false)
    },
    {
      title: '納入確定日',
      dataIndex: '',
      align: 'left',
      render: (item: any) => item?.ordDetails?.order?.dateDelivery ? moment(item?.ordDetails?.order?.dateDelivery).format('YYYY/MM/DD') : ''
    },
    {
      title: i18n.t('ListSerialNoScreen.TableLabel.tenthCol'),
      dataIndex: '',
      align: 'left',
      render: (item: any) => moment(item.updatedAt).format('YYYY/MM/DD')
    },
    {
      title: i18n.t('ListSerialNoScreen.TableLabel.eleventhCol'),
      dataIndex: '',
      align: 'center',
      width: '3%',
      render: (_: object, record: any) => {
        return (
          <div>
            <img
              src={IconDetailBtn}
              alt='edit'
              onClick={() => handleOnClickEdit(record)}
              className='w42x-h28x pointer'
            />
          </div>
        )
      }
    },
    {
      title: i18n.t('ListSerialNoScreen.TableLabel.twelvethCol'),
      dataIndex: '',
      align: 'center',
      width: '3%',
      render: (_: object, record: any) => {
        return (
          <div>
            <img
              onClick={() => handleOnClickDelete(record)}
              src={IconDeleteBtn}
              alt='delete'
              className='w42x-h28x pointer'
            />
          </div>
        )
      }
    }
  ]
  return columns
}
