import { Col, Form, Row } from 'antd'
import VButton from 'components/button/VButton'
import InputComponent from 'components/common/input/InputComponent'
import InputMonthComponent from 'components/common/input/InputMonthComponent'
import InputYearComponent from 'components/common/input/InputYearComponent'
import SelectComponent from 'components/common/select/SelectComponent'
import moment from 'moment'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import { useDispatch } from 'react-redux'
import {
  getListKatashikiAction,
  getListSensorAction,
  createBulkSerialNoAction
} from 'redux/actions/serialNo/serialNoAction'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import 'styles/serialNo.scss'
import { ADMIN_ROUTE, routeSerial } from 'views/routes/routes'
import { defaultFactoryList } from '../../defaultValue'
import { IPullDownType, ISensorPullDownType, ISensorTranslate } from 'interfaces/ISerialNo'

function BulkCreate() {
  const history = useHistory()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [year, setYear] = useState<Date | null>()
  const refMonth = useRef()
  const [month, setMonth] = useState<Date | null>()
  const refYear = useRef()

  const [selectCommsType, setSelectCommsType] = useState<any>()
  const [communicationTypeList, setCommunicationTypeList] = useState<any>([])
  const [selectType, setSelectType] = useState<any>()
  const [typeList, setTypeList] = useState<any>([])
  const [selectEquipmentType, setSelectEquipmentType] = useState<any>()
  const [equipmentTypeList, setEquipmentTypeList] = useState<any>([])
  const [selectFactory, setSelectFactory] = useState<any>()
  const [factoryList, setFactoryList] = useState<any>([])

  // change option
  const handleOnSelectType = (value: any) => {
    setSelectType(value)
    let filterSensor: any = []
    filterSensor = typeList?.filter((item: any) => item?.value === value)
    const sensorTypeFilter = filterSensor[0]?.sensorTypeId
    const sensorTranslateTypeFilter = filterSensor[0]?.sensorTypeTranslateId
    setSelectCommsType(sensorTypeFilter)
    setSelectEquipmentType(sensorTranslateTypeFilter)
  }
  const handleOnSelectFactory = (currentFactory: any) => {
    setSelectFactory(currentFactory)
  }
  // change year
  const handleOnChangeYear = (year: Date) => {
    setYear(year)
  }
  const handleOnChangeMonth = (month: Date) => {
    setMonth(month)
  }
  useEffect(() => {
    handleGetListKatashiki()
    setFactoryList(defaultFactoryList)
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    handleGetListSensor()
    // eslint-disable-next-line
  }, [])
  // data for dropdown katashiki
  const handleGetListKatashiki = () => {
    const params = {}
    dispatch(
      getListKatashikiAction(
        { ...params },
        (data) => {
          if (data && data?.result) {
            let listResult: any = []
            data.result = data.result.map(
              ({
                id,
                model,
                deletedAt,
                sensorTypeId,
                sensorTypeTranslateId
              }: // eslint-disable-next-line
              IPullDownType) => {
                if (deletedAt === null && model !== '') {
                  listResult.push({
                    id: id,
                    name: model,
                    value: id,
                    sensorTypeId: sensorTypeId,
                    sensorTypeTranslateId: sensorTypeTranslateId
                  })
                }
              }
            )
            listResult.sort((a: any, b: any) => a.id - b.id)
            setTypeList(listResult)
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }
  // data for dropdown sensor
  const handleGetListSensor = () => {
    const params = {
      type: 'LoRa'
    }
    dispatch(
      getListSensorAction(
        { ...params },
        (data) => {
          if (data && data?.result) {
            dispatch(SetLoadingAction(false))
            const resultCommsType: any = []
            const resultTypes: any = []
            data.result.sensorTypes = data.result.sensorTypes.map(
              ({
                sensorTypeId,
                sensorTypeKey
              }: // eslint-disable-next-line
              ISensorPullDownType) => {
                resultCommsType.push({
                  id: sensorTypeId,
                  name: sensorTypeKey,
                  value: parseInt(sensorTypeId, 10)
                })
              }
            )
            data.result.sensorTranslate = data.result.sensorTranslate.map(
              ({
                sensorTypeName,
                sensorTypeTranslateId
              }: // eslint-disable-next-line
              ISensorTranslate) => {
                resultTypes.push({
                  id: sensorTypeTranslateId,
                  name: sensorTypeName,
                  value: parseInt(sensorTypeTranslateId, 10)
                })
              }
            )
            setCommunicationTypeList(resultCommsType)
            setEquipmentTypeList(resultTypes)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  const checkDisable = () => {
    if (
      selectType &&
      selectCommsType &&
      selectEquipmentType &&
      year &&
      month &&
      form.isFieldTouched('registrationsNumber') &&
      !form.getFieldsError().filter(({ errors }) => errors.length).length
    ) {
      return false
    }
    return true
  }
  const onFinish = () => {
    const formatYear = moment(year).format('YYYY')
    const formatMonth = moment(month).format('MM')
    const params = {
      sensorTypeId: selectCommsType,
      modelRegistrationId: selectType,
      sensorTypeTranslateId: parseInt(selectEquipmentType),
      serialNoType: 1,
      manufacturingMonth: parseInt(formatMonth),
      manufacturingYear: parseInt(formatYear),
      serialNoQuantity: parseInt(form.getFieldValue('registrationsNumber').replace('台', '')),
      productSite: selectFactory,
      note: form.getFieldValue('notes')
    }
    dispatch(
      createBulkSerialNoAction(
        { ...params },
        async (data) => {
          if (data && data?.result) {
            const listData = data.result
            const serialNoParent = listData.serialNoParent
            if (serialNoParent) {
              history.push(
                ADMIN_ROUTE.concat(
                  routeSerial.BULK_DETAIL.replace(':id', serialNoParent.toString())
                ),
                {
                  param: params,
                  result: listData
                }
              )
            }
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    ) //ADMIN_ROUTE.concat(routeSerial.BULK_DETAIL.replace(':id', serialNoParent.toString()))
  }

  const handleOnClickBtnCancel = () => {
    const path = ADMIN_ROUTE.concat(routeSerial.LIST_SERIAL)
    history.push(path)
  }
  const onValuesChange = (value: any) => {
    if (value?.registrationsNumber) {
      form.setFieldsValue({
        registrationsNumber: value?.registrationsNumber?.replace(/[^0-9]/g, '')
      })
      if (value?.registrationsNumber > 10000) {
        form.setFieldsValue({ registrationsNumber: '10000' })
      } else if (value?.registrationsNumber < 1) {
        form.setFieldsValue({ registrationsNumber: '1' })
      } else {
        form.setFieldsValue({
          registrationsNumber: value?.registrationsNumber?.replace(/[^0-9]/g, '')
        })
      }
    }
  }
  const onFocusInputQuantity = () => {
    const quantity = form.getFieldValue('registrationsNumber')
    if (quantity) {
      form.setFieldsValue({
        registrationsNumber: quantity.replace('台', '')
      })
    }
  }
  const onBlurInputQuantity = () => {
    const quantity = form.getFieldValue('registrationsNumber')
    if (quantity) {
      form.setFieldsValue({
        registrationsNumber: quantity.concat('台')
      })
    }
  }
  return (
    <div className='bgrffffff'>
      <div className='serial-no-management pd_16x_0x'>
        <div className='mg_0_16x'>
          <div className='create-serial'>
            <Form
              form={form}
              onFinish={onFinish}
              autoComplete='off'
              onValuesChange={onValuesChange}>
              <Row gutter={24} style={{ marginBottom: 14 }}>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.bulkCreate.katashiki')}
                    disableAll={true}
                    isLabelRequired={true}
                    dataOption={typeList}
                    defaultOption={selectType}
                    onChangeSelect={(value: any) => handleOnSelectType(value)}
                  />
                </Col>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.bulkCreate.communicationType')}
                    isLabelRequired={true}
                    disableAll={true}
                    dataOption={communicationTypeList}
                    defaultOption={selectCommsType}
                    disableSelect={true}
                  />
                </Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: 14 }}>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.serialCreate.equipmentType')}
                    isLabelRequired={true}
                    disableAll={true}
                    dataOption={equipmentTypeList}
                    defaultOption={selectEquipmentType}
                    disableSelect={true}
                  />
                </Col>
                <Col span={12}>
                  <div className='year-picker-label'>
                    {t('serialNoScreen.bulkCreate.yearAndMonthPicker')} <span>*</span>
                  </div>
                  <Row gutter={15}>
                    <Col span={12}>
                      <InputYearComponent
                        refer={refYear}
                        state={year}
                        readOnly={false}
                        selectStart={true}
                        onChangeDate={(year: Date) => handleOnChangeYear(year)}
                        tidleSign={true}
                        customPopper='bottom'
                        describeText={t('serialNoScreen.bulkCreate.year')}
                      />
                    </Col>
                    <Col span={12}>
                      <InputMonthComponent
                        refer={refMonth}
                        state={month}
                        readOnly={false}
                        selectStart={true}
                        onChangeDate={(month: Date) => handleOnChangeMonth(month)}
                        tidleSign={true}
                        customPopper='bottom'
                        describeText={t('serialNoScreen.bulkCreate.month')}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12} style={{ marginBottom: 0 }}>
                  <InputComponent
                    isReq={true}
                    label={t('serialNoScreen.bulkCreate.registrationsNumber')}
                    name='registrationsNumber'
                    type='text'
                    maxLength={5}
                    labelStyle={{ marginBottom: 6 }}
                    onFocus={onFocusInputQuantity}
                    onBlur={onBlurInputQuantity}
                    placeholder={t('serialNoScreen.bulkCreate.inputQuantityPlaceHolder')}
                  />
                </Col>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.bulkCreate.manufacturingFactory')}
                    disableAll={true}
                    dataOption={factoryList}
                    defaultOption={selectFactory}
                    onChangeSelect={handleOnSelectFactory}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <InputComponent
                    label={t('serialNoScreen.bulkCreate.remarks')}
                    name='notes'
                    type='text'
                    maxLength={64}
                  />
                </Col>
              </Row>
              <div className='group-btn-create'>
                <Form.Item shouldUpdate>
                  {() => (
                    <VButton
                      title={t('customerScreen.btnConfirm')}
                      style={styleBtnSubmit}
                      type='text'
                      disabled={checkDisable()}
                    />
                  )}
                </Form.Item>
                <VButton
                  htmlType='button'
                  onClick={handleOnClickBtnCancel}
                  title={t('customerScreen.btnCancel')}
                  style={styleBtnReturn}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
const styleBtnSubmit = {
  width: 74,
  height: 38,
  color: '#ffffff'
}
const styleBtnReturn = {
  backgroundColor: '#fff',
  color: '#d7000f',
  border: 'solid 1px #d7000f',
  height: 38,
  marginLeft: 24
}

export default BulkCreate
