import { ColumnsType } from 'antd/es/table'
import InputDateComponent from 'components/common/input/InputDateComponent'
import InputTimeComponent from 'components/common/input/InputTimeComponent'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { defaultShowBlank } from '../sensor/defaultValue'
import moment from 'moment'
import 'styles/Information.scss'
import { ADMIN_ROUTE, routesInfoManagement, routesName } from 'views/routes/routes'
import {
  IconDashEdit,
  IconEditBlue,
  IconDeleteRed,
  IconDeleteModal
  // IconEditDisable,
  // IconDeleteDisable
} from 'assets'
import { AppState } from 'redux/reducers'
import { originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import SelectComponent from 'components/common/select/SelectComponent'
import { ConvertTime, showTotalRecord } from 'utils/Functions'
import ModalComponent from 'components/modal/ModalComponent'
import { useDispatch, useSelector } from 'react-redux'
import { SuccessAction, SetLoadingAction } from 'redux/actions/common/commonAction'
import VButton from 'components/button/VButton'
import {
  getListAlertAction,
  updateAlertAction
} from 'redux/actions/notifications/notificationAction'
import { IDataListAlertRes } from 'interfaces/INotificationList'
import TableComponent from 'components/common/table/TableComponent'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'
import GroupButtonActionSearchComponent from 'components/common/button/GroupButtonActionSearchComponent'
import Collapsed from '../serialNo/serialNoManagement/components/collapsed'
interface IDataType {
  key: string
  no: number
  timePushNotification: string
  title: string
  content: string
  statusNotification: number
}

const getData = (current: number, pageSize: number, data: IDataType[]) => {
  return data.slice((current - 1) * pageSize, current * pageSize)
}

const InfoManagement = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  const [idItem, setIdItem] = useState<string>('')
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  const refDateStart = useRef()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const refTimeStart = useRef()
  const [startTime, setStartTime] = useState<Date | null>(null)
  const refDateEnd = useRef()
  const [endDate, setEndDate] = useState<Date | null>(null)
  const refTimeEnd = useRef()
  const [endTime, setEndTime] = useState<Date | null>(null)
  // select delivery status
  const [statusType, setStatusType] = useState<string>('all')
  const [displayOrder, setDisplayOrder] = useState<string>('')
  const [errDateAndTime, setErrDateAndTime] = useState<string>('')
  // state pagination
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  // modal delete
  const [modalDelete, setModalDelete] = useState({
    isModalVisible: false,
    content: t('modal.contentDelNoti')
  })
  const showSuccess = (text: string) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }
  // list data
  const [dataTable, setDataTable] = useState<IDataType[]>([])
  const [masterDataTable, setMasterDataTable] = useState<IDataType[]>([])
  // render colums
  const columns: ColumnsType<IDataType> = [
    {
      title: t('sensorManagementScreen.table.column.no'),
      dataIndex: '',
      render: (text, row, index) => {
        return <span>{(current - 1) * pageSize + index + 1}</span>
      },
      width: '8%'
    },
    {
      title: t('infoManagement.colDeliveryDate'),
      dataIndex: 'timePushNotification',
      render: (_: object, record: { timePushNotification: string }) => {
        return (
          <span>
            {record?.timePushNotification
              ? ConvertTime(record?.timePushNotification)
              : defaultShowBlank}
          </span>
        )
      },
      width: '15%'
    },
    {
      title: t('infoManagement.colDeliveryStatus'),
      dataIndex: 'statusNotification',
      render: (_: object, record: { statusNotification: any }) => {
        return <span>{record?.statusNotification === 0 ? '未配信' : '配信済み'}</span>
      },
      width: '12%'
    },
    {
      title: t('infoManagement.colSubject'),
      dataIndex: 'title',
      render: (_: object, record: { title: string }) => {
        return <TooltipComponent maxSlice={20} title={record?.title} />
      },
      width: '25%'
    },
    {
      title: t('infoManagement.colContent'),
      dataIndex: 'content',
      render: (_: object, record: { content: string }) => {
        return <TooltipComponent maxSlice={20} title={record?.content} />
      },
      width: '25%'
    },
    {
      title: <div className='text-center'>{t('infoManagement.colDetail')}</div>,
      dataIndex: '',
      width: '58px',
      render: (_: object, record: { key: string }) => {
        return (
          <div className='text-center'>
            <img
              src={IconDashEdit}
              onClick={() => handleOnClickDetail(record?.key)}
              className='w42x-h28x pointer'
              alt='detail'
            />
          </div>
        )
      }
    },
    ROLE_CRUD.includes(role)
      ? {
        title: <div className='text-center'>{t('infoManagement.colEdit')}</div>,
        dataIndex: '',
        width: '58px',
        render: (_: object, record: { key: string; statusNotification: any }) => {
          return (
            <div className='text-center'>
              {/* {record?.statusNotification === 0 ? ( */}
              <img
                src={IconEditBlue}
                onClick={() => handleOnClickEdit(record?.key)}
                className='w42x-h28x pointer'
                alt='edit'
              />
              {/* ) : (
                  <img src={IconEditDisable} className='w42x-h28x' alt='edit-disable' />
                )} */}
            </div>
          )
        }
      }
      : { width: '0px' },
    ROLE_CRUD.includes(role)
      ? {
        title: <div className='text-center'>{t('infoManagement.colDel')}</div>,
        dataIndex: '',
        width: '58px',
        render: (_: object, record: { key: string; statusNotification: any }) => {
          return (
            <div className='text-center'>
              {/* {record?.statusNotification === 0 ? ( */}
              <img
                src={IconDeleteRed}
                onClick={() => handleOnClickDel(record?.key)}
                className='w42x-h28x pointer'
                alt='delete'
              />
              {/* ) : (
                  <img src={IconDeleteDisable} className='w42x-h28x pointer' alt='delete' />
                )} */}
            </div>
          )
        }
      }
      : { width: '0px' }
  ]
  // data option status
  const defaultStatus = [
    {
      id: '1',
      name: t('infoManagement.stsUndelivered'),
      value: '0'
    },
    {
      id: '2',
      name: t('infoManagement.stsDelivered'),
      value: '1'
    }
  ]
  // data option status
  const defaultDisplayOr = [
    {
      id: '1',
      name: t('infoManagement.opDisplayOrderDesc'),
      value: 'desc'
    },
    {
      id: '2',
      name: t('infoManagement.opDisplayOrderAsc'),
      value: 'asc'
    }
  ]
  useEffect(() => {
    dispatch(
      getListAlertAction(
        {},
        (data) => {
          data.result.record = data.result.record.map(
            (
              {
                systemAlertId,
                content,
                title,
                timePushNotification,
                statusNotification
              }: IDataListAlertRes,
              index: number
            ) => {
              return {
                key: systemAlertId,
                no: index + 1,
                title: title,
                content: content,
                timePushNotification: timePushNotification,
                statusNotification: statusNotification
              }
            }
          )
          setDataTable(data.result.record)
          setMasterDataTable(data.result.record)
          dispatch(SetLoadingAction(false))
        },
        true
      )
    )
    // eslint-disable-next-line
  }, [])

  const onChangeSelecteStatus = (value: string) => {
    setStatusType(value)
  }

  const onChangeSelecteDisplayOr = (value: string) => {
    setDisplayOrder(value)
  }

  const onClickBtnCreate = () => {
    if (
      location.pathname === ADMIN_ROUTE.concat(routesName.INFO_MANAGEMENT) &&
      ROLE_CRUD.includes(role)
    ) {
      history.push(ADMIN_ROUTE.concat(routesInfoManagement.INFO_CREATE))
    }
  }

  const onClickBtnUpdateFilter = () => {
    if (statusType !== '') {
      let searchTable = masterDataTable.filter((item) => {
        return (
          item?.statusNotification?.toString() === statusType || statusType === 'all' || !statusType
        )
      })
      if (displayOrder === 'asc') {
        searchTable.sort(
          (a, b) =>
            moment(a.timePushNotification).valueOf() - moment(b.timePushNotification).valueOf()
        )
      } else if (displayOrder === 'desc') {
        searchTable.sort(
          (a, b) =>
            moment(b.timePushNotification).valueOf() - moment(a.timePushNotification).valueOf()
        )
      }
      // case: 4 fields are null
      if (startDate !== null || startTime !== null || endDate !== null || endTime !== null) {
        // case: start and end time are select but start and end day are null
        if (startDate === null && endDate === null && startTime !== null && endTime !== null) {
          setErrDateAndTime(t('infoManagement.error.dateAndTimeError'))
          setDataTable(masterDataTable)
        } else {
          let startDateAndTime: any = null
          let endDateAndTime: any = null
          // case: start and end time are select but start and end day are null
          // set time field => start of the day and end of the day by default
          if (startDate !== null && endDate !== null && startTime === null && endTime === null) {
            startDateAndTime = moment(startDate).startOf('day').toString()
            endDateAndTime = moment(endDate).endOf('day').toString()
          } else if (
            startDate !== null &&
            endDate !== null &&
            startTime !== null &&
            endTime === null
          ) {
            startDateAndTime = moment(startDate)
              .format('YYYY/MM/DD')
              .concat(' ' + moment(startTime).format('HH:mm'))
            endDateAndTime = moment(endDate).endOf('day').toString()
          } else if (
            startDate !== null &&
            endDate !== null &&
            startTime === null &&
            endTime !== null
          ) {
            startDateAndTime = moment(startDate).startOf('day').toString()
            endDateAndTime = moment(endDate)
              .format('YYYY/MM/DD')
              .concat(' ' + moment(endTime).format('HH:mm'))
          } else if (
            startDate !== null &&
            endDate === null &&
            startTime === null &&
            endTime === null
          ) {
            startDateAndTime = moment(startDate).startOf('day').toString()
            endDateAndTime = null
          } else if (
            startDate === null &&
            endDate !== null &&
            startTime === null &&
            endTime === null
          ) {
            startDateAndTime = null
            endDateAndTime = moment(endDate).endOf('day').toString()
          } else if (startDate !== null &&
            endDate !== null &&
            startTime === null &&
            endTime === null) {
            startDateAndTime = moment(startDate).startOf('day').toString()
            endDateAndTime = moment(endDate).endOf('day').toString()
          } else if ((startDate === null &&
            endDate !== null &&
            startTime !== null &&
            endTime === null) ||
            (startDate !== null &&
              endDate === null &&
              startTime === null &&
              endTime !== null)) {
            startDateAndTime = 1
            endDateAndTime = 0
          } else {
            // case: start day and time are select and end day and time are null (and reverse)
            startDateAndTime = moment(startDate)
              .format('YYYY/MM/DD')
              .concat(' ' + moment(startTime).format('HH:mm'))
            endDateAndTime = moment(endDate)
              .format('YYYY/MM/DD')
              .concat(' ' + moment(endTime).format('HH:mm'))
          }
          searchTable = searchTable.filter(
            (item) =>
              // 4 fields are select
              (moment(item.timePushNotification).valueOf() >= moment(startDateAndTime).valueOf() &&
                moment(item.timePushNotification).valueOf() <= moment(endDateAndTime).valueOf()) ||
              // start day and time are select and end day and time are null
              (moment(startDateAndTime).isValid() &&
                !moment(endDateAndTime).isValid() &&
                moment(item.timePushNotification).valueOf() >=
                moment(startDateAndTime).valueOf()) ||
              // start day and time are null and end day and time are select
              (!moment(startDateAndTime).isValid() &&
                moment(endDateAndTime).isValid() &&
                moment(item.timePushNotification).valueOf() <= moment(endDateAndTime).valueOf()) ||
              //  both null
              (!moment(startDateAndTime).isValid() && !moment(endDateAndTime).isValid())
          )

          if (
            moment(startDateAndTime).isValid() &&
            moment(endDateAndTime).isValid() &&
            moment(startDateAndTime) > moment(endDateAndTime)
          ) {
            setErrDateAndTime(t('infoManagement.error.dateAndTimeError'))
          } else {
            setDataTable(searchTable)
            setErrDateAndTime('')
            setCurrent(1)
            setIsSearch(true)
          }
        }
      } else {
        setDataTable(searchTable)
        setCurrent(1)
        setIsSearch(true)
      }
    } else {
      setDataTable(masterDataTable)
      setCurrent(1)
    }
  }

  const onClickBtnCancelFilter = () => {
    defaultFilterValue()
    setIsSearch(false)
  }
  const defaultFilterValue = () => {
    setDataTable(masterDataTable)
    setErrDateAndTime('')
    setStatusType('all')
    setDisplayOrder('')
    setStartDate(null)
    setStartTime(null)
    setEndTime(null)
    setEndDate(null)
  }

  const handleOnClickDetail = (key: string) => {
    const path = ADMIN_ROUTE.concat(routesInfoManagement.INFO_DETAIL + `/${key}`)
    history.push(path)
  }

  const handleOnClickEdit = (key: string) => {
    const path = ADMIN_ROUTE.concat(routesInfoManagement.INFO_EDIT + `/${key}`)
    history.push(path)
  }

  const handleOnChangePageSize = (size: number) => {
    const totalPageChange = Math.ceil(dataTable?.length / size)
    if (current > totalPageChange) {
      setCurrent(totalPageChange)
    }
    setPageSize(size)
  }

  const handleOnChangePage = (pageChange: number) => {
    setCurrent(pageChange)
  }

  const handleOnClickDel = (id: string) => {
    setIdItem(id)
    setModalDelete({
      ...modalDelete,
      isModalVisible: true
    })
  }

  const handleOnSubmitDelete = () => {
    deleteNotification()
  }
  const deleteNotification = async () => {
    const params = {
      systemAlertId: idItem,
      type: 'delete'
    }
    dispatch(
      updateAlertAction(
        { ...params },
        (data) => {
          if (data?.result?.deletedAt) {
            const dataSource = [...dataTable]
            const listNoti = dataSource?.filter((item) => item?.key !== idItem)
            setDataTable(listNoti)
            const totalPageChange = Math.ceil(listNoti?.length / pageSize)
            if (totalPageChange === current - 1) {
              setCurrent(totalPageChange)
            }
            setModalDelete({
              ...modalDelete,
              isModalVisible: false
            })
            showSuccess(t('modal.contentSuccessNoti'))
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  return (
    <div className='info-management'>
      {ROLE_CRUD.includes(role) ? (
        <div className='bgrffffff' style={{ padding: '16px', marginBottom: '25px' }}>
          <VButton
            title={t('infoManagement.btnCreate')}
            style={styleBtnCreate}
            onClick={onClickBtnCreate}
          />
        </div>
      ) : null}
      <Collapsed isTopSpace={false}>
        <div className=''>
          <div>
            <div className='label-bold' style={{ fontSize: 18, marginBottom: 15 }}>
              {t('infoManagement.deliveryPeriod')}
            </div>
            <div style={{ display: 'flex', paddingLeft: 16 }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='label-bold'>{t('infoManagement.dateTimeStart')}</div>
                <div style={{ display: 'flex' }}>
                  <InputDateComponent
                    refer={refDateStart}
                    state={startDate}
                    readOnly={false}
                    onChangeDate={(date: Date) => setStartDate(date)}
                  />
                  <InputTimeComponent
                    refer={refTimeStart}
                    state={startTime}
                    readOnly={false}
                    onChangeDate={(date: Date) => setStartTime(date)}
                  />
                </div>
              </div>
              <div className='view-select'>
                <SelectComponent
                  lable={t('infoManagement.deliveryStatusList')}
                  defaultOption={statusType}
                  dataOption={defaultStatus}
                  onChangeSelect={onChangeSelecteStatus}
                  styleLabel={{ marginBottom: 8 }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 25, paddingLeft: 16 }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='label-bold'>{t('infoManagement.dateTimeEnd')}</div>
                <div style={{ display: 'flex' }}>
                  <InputDateComponent
                    refer={refDateEnd}
                    state={endDate}
                    readOnly={false}
                    onChangeDate={(date: Date) => setEndDate(date)}
                  />
                  <InputTimeComponent
                    refer={refTimeEnd}
                    state={endTime}
                    readOnly={false}
                    onChangeDate={(date: Date) => setEndTime(date)}
                  />
                </div>
                <div style={{ color: '#d7000f', marginTop: 5 }}>
                  {errDateAndTime ? errDateAndTime : ''}
                </div>
              </div>
              <div className='view-select'>
                <SelectComponent
                  disableAll={true}
                  lable={t('infoManagement.displayOrder')}
                  defaultOption={displayOrder}
                  dataOption={defaultDisplayOr}
                  onChangeSelect={onChangeSelecteDisplayOr}
                  styleLabel={{ marginBottom: 8 }}
                />
              </div>
            </div>

            <div className='view-btn-filter'>
              <GroupButtonActionSearchComponent
                onClickBtnSubmit={onClickBtnUpdateFilter}
                onClickBtncancel={onClickBtnCancelFilter}
              />
            </div>
          </div>
        </div>
      </Collapsed>
      <div className='bgrffffff' style={{ padding: '16px' }}>
        <div className='ss-table' style={{ margin: '0 -16px' }}>
          <div className='mg_0_16x'>
            <div className='d-flex justify-content-flex-end align-items-center mg-pagination'>
              <div className='d-flex justify-content-flex-end font-w-bold pd_16x_0x total-record'>
                {showTotalRecord(dataTable?.length)}
              </div>
              <div className='sensor-pagination'>
                <PaginationComponent
                  onChangePageSize={(size) => handleOnChangePageSize(size)}
                  total={dataTable?.length}
                  onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                  pageSize={pageSize}
                  isShowSizeChanger={false}
                  currentpage={current}
                />
              </div>
            </div>
          </div>
          <div className='custom-table'>
            <TableComponent
              isSearch={isSearch}
              columns={columns}
              dataSource={getData(current, pageSize, dataTable)}
            />
          </div>
          <div className='mg_0_16x'>
            <div className='mg-pagination'>
              <PaginationComponent
                onChangePageSize={(size) => handleOnChangePageSize(size)}
                total={dataTable?.length}
                onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                pageSize={pageSize}
                isShowSizeChanger={true}
                currentpage={current}
              />
            </div>
          </div>
        </div>
        <div className='view-btn-filter' style={{ justifyContent: 'center' }}>
          <ButtonReturnComponent />
        </div>
      </div>

      {modalDelete?.isModalVisible ? (
        <ModalComponent
          isVisible={modalDelete?.isModalVisible}
          img={IconDeleteModal}
          content={modalDelete?.content}
          onCancelModal={() =>
            setModalDelete({
              ...modalDelete,
              isModalVisible: false
            })
          }
          btnCancel={t('modal.btnCancel')}
          btnSubmit={t('modal.btnDelete')}
          onSubmitModal={handleOnSubmitDelete}
        />
      ) : null}
    </div>
  )
}
const styleBtnCreate = {
  width: 102,
  height: 38
}
export default InfoManagement
