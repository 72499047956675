import { IPropsInputSearch } from 'interfaces/sensor/IPropsInputSearch'
import { useState, useMemo, useEffect } from 'react'
import 'styles/InputSearch.scss'
import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import { IOption } from 'interfaces/sensor/IPropsSelect'
import { useTranslation } from 'react-i18next'
const { Option } = Select

function InputSearchComponent({
  placeholder,
  onSubmitSearch,
  fetchOptions,
  valueSelect,
  maxLength,
  onClear
}: IPropsInputSearch) {
  const { t } = useTranslation()
  const [valueSearch, setValueSearch] = useState<string>('')
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<IOption[]>([])
  const debounceTimeout = 1000
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string) => {
      setValueSearch(value.trim())
      setOptions([])
      if (value.trim()) {
        setFetching(true)
        fetchOptions(value.trim()).then((newOptions: any) => {
          setOptions(newOptions)
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])
  const handleChange = (value: string) => {
    if (onSubmitSearch) {
      onSubmitSearch(value)
    }
  }
  const limitMaxLength = () => {
    const select: any = document.getElementsByClassName('ant-select-selection-search-input')
    for (const el of select) {
      el.setAttribute(`maxlength`, maxLength)
    }
  }
  useEffect(() => {
    if (valueSelect === undefined) {
      setValueSearch('')
      setOptions([])
    }
  }, [valueSelect])
  useEffect(() => {
    limitMaxLength()
    // eslint-disable-next-line
  }, [])
  return (
    <div className='custom-search-select'>
      <Select
        className='max-length-input'
        style={{ width: '100%' }}
        showSearch
        value={valueSelect}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        allowClear={true}
        onClear={onClear}
        notFoundContent={
          fetching ? (
            <Spin size='small' />
          ) : valueSearch?.length === 0 ? null : (
            t('errors.message.FRE00015')
          )
        }>
        {options?.map((item) => (
          <Option key={item.id} value={item?.value ?? item.name}>
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default InputSearchComponent
