import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'styles/CalculatorSheet.scss'
import { Col, Row } from 'antd'
import SelectComponent from 'components/common/select/SelectComponent'
import { GetAttributesAction } from 'redux/actions/attributes/attributeAction'
import { useDispatch } from 'react-redux'
import { IconDelTm } from 'assets'
import { keyFormulaDefault } from '../multiSettings/convertData'
import { defaultShowBlank } from '../sensor/defaultValue'
import { dataKeyPrefixTv } from '../multiSettings/defaultValue'
import { sortListAttByAttributeIndex, formatExpFormulaOther } from 'utils/Functions'
import caculator from './Caculator'
import { convertAtt, convertViewFormula, getNameAtt } from './convertData'
import { EBlank } from 'interfaces/enums/EBlank'
import { EMaxLength } from 'interfaces/enums/EMaxLength'
import { EExpKeys, EXP_KEYS } from 'interfaces/enums/EKey'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
// import mexp from 'math-expression-evaluator'
type Props = {
  getResultCal: (exp: string, expCheck: string, expData: string, mode: string) => void
  dataSelected: any
  formula: any
}
// list tm push
let listTmPush: Array<string> = []
const CalculatorComponent = ({ getResultCal, dataSelected, formula }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [settingTarget, setSettingTarget] = useState<string>('')
  const handleOnChangeSelectSettingTarget = (valueSettingTarget: string) => {
    setSettingTarget(valueSettingTarget)
  }
  const [resultView, setResultView] = useState<any>('')
  // master data
  const [listAtt, setListAtt] = useState<any>([])
  const [listAttSelected, setListAttSelected] = useState<any>([])
  const [isRadTmp, setIsRadTmp] = useState<boolean>(dataSelected?.mode)
  useEffect(() => {
    if (formula?.listAttribute?.length > 0) {
      setListAtt(convertAtt(formula?.listAttribute))
      setListAttSelected(convertAtt(formula?.listAttribute))
    } else {
      const getAttributes = () => {
        const params = {
          parentType: dataSelected?.lteOrLora,
          typeSensor: dataSelected?.settingTarget
        }
        dispatch(GetAttributesAction(params))
      }
      getAttributes()
    }
    // eslint-disable-next-line
  }, [dispatch, formula])

  useEffect(() => {
    const expFromAtt = dataSelected?.expFromAtt
    if (expFromAtt === keyFormulaDefault || expFromAtt === '-' || expFromAtt === defaultShowBlank) {
      setResultView('')
    } else {
      setResultView(expFromAtt.indexOf('演算式') > -1 ? '' : dataSelected?.exp?.replaceAll(':', ''))
    }
    listTmPush = []
    let listAttTmp = convertAtt(formula?.listAttribute)
    if (dataSelected?.expFromAtt !== EBlank.FORMULA) {
      if (
        dataSelected?.expFromAtt.indexOf('tm') > -1 ||
        dataSelected?.expFromAtt.indexOf('Tv') > -1
      ) {
        const getAttNameTmOrTv = dataSelected?.expFromAtt?.replace(/[^tm{0-9} | ^Tv{0-9} ]+/g, ',')
        const arrDataSelected = getAttNameTmOrTv?.split(',')
        for (var i = 0; i < listAttTmp.length; i++) {
          const indexSpace = listAttTmp[i].name.indexOf('　')
          const tmp = listAttTmp[i].name.substring(0, indexSpace)
          if (arrDataSelected?.includes(tmp)) {
            const itemSelect = listAttTmp?.find(
              (item: any) => item?.dataKeyPrefix + item?.attributeIndex === tmp
            )
            listTmPush.push(itemSelect?.attributeKey)
          }
        }
        listAttTmp = listAttTmp?.filter((item) => !listTmPush?.includes(item?.attributeKey))
        setListAtt(listAttTmp)
      }
    } else {
      const itemSelect = listAttTmp?.find(
        (item: any) => item?.dataKeyPrefix + item?.attributeIndex === dataSelected?.attributeKey
      )
      if (itemSelect) {
        listTmPush.push(itemSelect?.attributeKey)
        listAttTmp = listAttTmp?.filter((item) => !listTmPush?.includes(item?.attributeKey))
        setListAtt(listAttTmp)
      }
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    //if (resultView !== '') {
    let expCheckTmp = resultView
    let expData = resultView
    let arrListTmPush = [...listTmPush]
    if (arrListTmPush.length > 0) {
      arrListTmPush.sort(function (a, b) {
        return a.length - b.length
      })
      for (var i = arrListTmPush.length; i >= 0; i--) {
        if (resultView.indexOf(arrListTmPush[i]) > -1) {
          expCheckTmp = expCheckTmp.replaceAll(arrListTmPush[i], 'y')
          const itemData = listAttSelected.find(
            // eslint-disable-next-line
            (x: any) => x.attributeKey === arrListTmPush[i]
          ).name
          const indexSpace = itemData.indexOf('　')
          const tmp = itemData.substring(0, indexSpace)
          expData = expData?.replaceAll(arrListTmPush[i], ':' + tmp)
          // const itemData = listAttSelected.find(
          //   // eslint-disable-next-line
          //   (x: any) => x.attributeKey === listTmPush[i]
          // ).value
          // expData = expData?.replaceAll(listTmPush[i], ':' + itemData)
        }
      }
    }
    // covert btn -> bieu thuc theo lib
    // số pi = pi
    // căn bậc 2 = sqrt
    // luỹ thừa = ^
    // % = /100
    // x = *
    // ÷ = /
    if (resultView.indexOf('x') !== -1) {
      expCheckTmp = expCheckTmp.replaceAll('x', '*')
      expData = expData.replaceAll('x', '*')
    }
    if (resultView.indexOf('÷') !== -1) {
      expCheckTmp = expCheckTmp.replaceAll('÷', '/')
      expData = expData.replaceAll('÷', '/')
    }
    if (resultView.indexOf('%') !== -1) {
      expCheckTmp = expCheckTmp.replaceAll('%', '/100')
    }
    if (resultView.indexOf('π') !== -1) {
      expCheckTmp = expCheckTmp.replaceAll('π', 'PI')
      expData = expData.replaceAll('π', 'pi')
    }
    if (resultView.indexOf('√(') !== -1) {
      expCheckTmp = expCheckTmp.replaceAll('√(', 'sqrt(')
      expData = expData.replaceAll('√(', 'root(')
    }
    getResultCal(resultView, expCheckTmp, expData, isRadTmp ? EExpKeys.RAD_KEY : EExpKeys.DEG_KEY)
    //}
    // eslint-disable-next-line
  }, [resultView, isRadTmp])
  const caculationView = (key: string) => {
    let keyEnd = resultView[resultView.length - 1]
    let keyLast = resultView[resultView.length - 2]
    if (resultView?.concat(key)?.length >= EMaxLength.maxCaculator && !EXP_KEYS.includes(key)) {
      return 0
    }
    switch (key) {
      case 'DEL':
        if (typeof resultView === 'string' && resultView !== 'Error') {
          if (keyEnd === '(' && (keyLast === 'n' || keyLast === 's' || keyLast === 'g')) {
            setResultView(resultView.substring(0, resultView.length - 4))
          } else if (keyEnd === '(' && keyLast === '√') {
            setResultView(resultView.substring(0, resultView.length - 2))
          } else if (keyLast !== '√' && keyLast !== 'n' && keyLast !== 's' && keyLast !== 'g') {
            if (listTmPush.length > 0) {
              let listTmp = []
              let resultViewTmp = convertViewFormula(listAttSelected, resultView)
              let thirstLastKey = resultViewTmp.substring(resultViewTmp.length - 3)
              let fourLastKey = resultViewTmp.substring(resultViewTmp.length - 4)
              for (var i = 0; i < listTmPush.length; i++) {
                // eslint-disable-next-line
                const itemData = listAttSelected.find((x: any) => x.attributeKey === listTmPush[i])
                const indexSpace = itemData?.name.indexOf('　')
                const tmp = itemData?.name.substring(0, indexSpace)
                listTmp.push(tmp)
              }
              if (listTmp.indexOf(thirstLastKey) !== -1) {
                const lastIndex = resultViewTmp.lastIndexOf(thirstLastKey)
                setResultView(
                  formatExpFormulaOther(resultViewTmp.substring(0, lastIndex), listAttSelected)
                )
              } else if (listTmp.indexOf(fourLastKey) !== -1) {
                const lastIndex = resultViewTmp.lastIndexOf(fourLastKey)
                setResultView(
                  formatExpFormulaOther(resultViewTmp.substring(0, lastIndex), listAttSelected)
                )
              } else {
                setResultView(resultView.substring(0, resultView.length - 1))
              }
            } else setResultView(resultView.substring(0, resultView.length - 1))
          } else setResultView('')
        }
        break
      case 'AC':
        setResultView('')
        break
      case '0':
        if (typeof resultView === 'string' && resultView !== 'Error')
          setResultView(resultView.concat('0'))
        else setResultView('0')
        break
      case '1':
        if (typeof resultView === 'string' && resultView !== 'Error')
          setResultView(resultView.concat('1'))
        else setResultView('1')
        break
      case '2':
        if (typeof resultView === 'string' && resultView !== 'Error')
          setResultView(resultView.concat('2'))
        else setResultView('2')
        break
      case '3':
        if (typeof resultView === 'string' && resultView !== 'Error')
          setResultView(resultView.concat('3'))
        else setResultView('3')
        break
      case '4':
        if (typeof resultView === 'string' && resultView !== 'Error')
          setResultView(resultView.concat('4'))
        else setResultView('4')
        break
      case '5':
        if (typeof resultView === 'string' && resultView !== 'Error')
          setResultView(resultView.concat('5'))
        else setResultView('5')
        break
      case '6':
        if (typeof resultView === 'string' && resultView !== 'Error')
          setResultView(resultView.concat('6'))
        else setResultView('6')
        break
      case '7':
        if (typeof resultView === 'string' && resultView !== 'Error')
          setResultView(resultView.concat('7'))
        else setResultView('7')
        break
      case '8':
        if (typeof resultView === 'string' && resultView !== 'Error')
          setResultView(resultView.concat('8'))
        else setResultView('8')
        break
      case '9':
        if (typeof resultView === 'string' && resultView !== 'Error')
          setResultView(resultView.concat('9'))
        else setResultView('9')
        break
      case '.':
        if (
          typeof resultView === 'string' &&
          resultView !== 'Error' &&
          (resultView.indexOf('.') === -1 ||
            resultView.indexOf('÷') > -1 ||
            resultView.indexOf('x') > -1 ||
            resultView.indexOf('+') > -1 ||
            resultView.indexOf('-') > -1 ||
            resultView.indexOf('%') > -1 ||
            resultView.indexOf('π') > -1 ||
            resultView.indexOf('(') > -1 ||
            resultView.indexOf('') > -1) &&
          resultView !== '^' &&
          keyEnd !== '.' &&
          keyEnd !== '÷' &&
          keyEnd !== 'x' &&
          keyEnd !== '+' &&
          keyEnd !== '-' &&
          keyEnd !== '%' &&
          keyEnd !== 'π' &&
          keyEnd !== '(' &&
          keyEnd !== '^'
        ) {
          setResultView(resultView.concat('.'))
        }
        break

      case '÷':
        if (
          resultView !== 'Error' &&
          resultView !== '' &&
          keyEnd !== '÷' &&
          keyEnd !== '.' &&
          keyEnd !== '('
        ) {
          let indexKeyEnd
          switch (keyEnd) {
            case 'x':
              if (
                keyLast !== '÷' &&
                keyLast !== 'x' &&
                keyLast !== '+' &&
                keyLast !== '.' &&
                keyLast !== '('
              )
                indexKeyEnd = resultView.lastIndexOf('x')
              setResultView(resultView.substring(0, indexKeyEnd).concat('÷'))
              break
            case '+':
              if (
                keyLast !== '÷' &&
                keyLast !== 'x' &&
                keyLast !== '+' &&
                keyLast !== '.' &&
                keyLast !== '('
              )
                indexKeyEnd = resultView.lastIndexOf('+')
              setResultView(resultView.substring(0, indexKeyEnd).concat('÷'))
              break
            case '-':
              if (
                keyLast !== '÷' &&
                keyLast !== 'x' &&
                keyLast !== '+' &&
                keyLast !== '.' &&
                keyLast !== '('
              )
                indexKeyEnd = resultView.lastIndexOf('-')
              setResultView(resultView.substring(0, indexKeyEnd).concat('÷'))
              break
            case '^':
              indexKeyEnd = resultView.lastIndexOf('^')
              setResultView(resultView.substring(0, indexKeyEnd).concat('÷'))
              break
            default:
              const tmp = typeof resultView === 'string' ? resultView : resultView.toString()
              setResultView(tmp.concat('÷'))
              break
          }
        }
        break
      case 'x':
        if (
          resultView !== 'Error' &&
          resultView !== '' &&
          keyEnd !== 'x' &&
          keyEnd !== '.' &&
          keyEnd !== '('
        ) {
          let indexKeyEnd
          switch (keyEnd) {
            case '÷':
              if (
                keyLast !== '÷' &&
                keyLast !== 'x' &&
                keyLast !== '+' &&
                keyLast !== '.' &&
                keyLast !== '('
              )
                indexKeyEnd = resultView.lastIndexOf('÷')
              setResultView(resultView.substring(0, indexKeyEnd).concat('x'))
              break
            case '+':
              if (
                keyLast !== '÷' &&
                keyLast !== 'x' &&
                keyLast !== '+' &&
                keyLast !== '.' &&
                keyLast !== '('
              )
                indexKeyEnd = resultView.lastIndexOf('+')
              setResultView(resultView.substring(0, indexKeyEnd).concat('x'))
              break
            case '-':
              if (
                keyLast !== '÷' &&
                keyLast !== 'x' &&
                keyLast !== '+' &&
                keyLast !== '.' &&
                keyLast !== '('
              )
                indexKeyEnd = resultView.lastIndexOf('-')
              setResultView(resultView.substring(0, indexKeyEnd).concat('x'))
              break
            case '^':
              indexKeyEnd = resultView.lastIndexOf('^')
              setResultView(resultView.substring(0, indexKeyEnd).concat('x'))
              break
            default:
              const tmp = typeof resultView === 'string' ? resultView : resultView.toString()
              setResultView(tmp.concat('x'))
              break
          }
        }
        break
      case '-':
        if (resultView !== 'Error' && keyEnd !== '-' && keyEnd !== '.') {
          let indexKeyEnd
          switch (keyEnd) {
            case '+':
              indexKeyEnd = resultView.lastIndexOf('+')
              setResultView(resultView.substring(0, indexKeyEnd).concat('-'))
              break
            case '^':
              indexKeyEnd = resultView.lastIndexOf('^')
              setResultView(resultView.substring(0, indexKeyEnd).concat('-'))
              break
            default:
              const tmp = typeof resultView === 'string' ? resultView : resultView.toString()
              setResultView(tmp.concat('-'))
              break
          }
        }
        break
      case '+':
        if (
          resultView !== 'Error' &&
          resultView !== '' &&
          keyEnd !== '+' &&
          keyEnd !== '.' &&
          keyEnd !== '('
        ) {
          let indexKeyEnd
          switch (keyEnd) {
            case '÷':
              if (
                keyLast !== '÷' &&
                keyLast !== 'x' &&
                keyLast !== '+' &&
                keyLast !== '.' &&
                keyLast !== '('
              )
                indexKeyEnd = resultView.lastIndexOf('÷')
              setResultView(resultView.substring(0, indexKeyEnd).concat('+'))
              break
            case 'x':
              if (
                keyLast !== '÷' &&
                keyLast !== 'x' &&
                keyLast !== '+' &&
                keyLast !== '.' &&
                keyLast !== '('
              )
                indexKeyEnd = resultView.lastIndexOf('x')
              setResultView(resultView.substring(0, indexKeyEnd).concat('+'))
              break
            case '-':
              if (
                keyLast !== '÷' &&
                keyLast !== 'x' &&
                keyLast !== '+' &&
                keyLast !== '.' &&
                keyLast !== '('
              )
                indexKeyEnd = resultView.lastIndexOf('-')
              setResultView(resultView.substring(0, indexKeyEnd).concat('+'))
              break
            case '^':
              indexKeyEnd = resultView.lastIndexOf('^')
              setResultView(resultView.substring(0, indexKeyEnd).concat('+'))
              break
            default:
              const tmp = typeof resultView === 'string' ? resultView : resultView.toString()
              setResultView(tmp.concat('+'))
              break
          }
        }
        break
      case '%':
        if (resultView !== 'Error' && resultView !== '' && keyEnd !== '.' && keyEnd !== '(') {
          let indexKeyEnd
          switch (keyEnd) {
            case '÷':
              indexKeyEnd = resultView.lastIndexOf('÷')
              setResultView(resultView.substring(0, indexKeyEnd).concat('%'))
              break
            case 'x':
              indexKeyEnd = resultView.lastIndexOf('x')
              setResultView(resultView.substring(0, indexKeyEnd).concat('%'))
              break
            case '-':
              if (keyLast !== '÷' && keyLast !== 'x') {
                indexKeyEnd = resultView.lastIndexOf('-')
                setResultView(resultView.substring(0, indexKeyEnd).concat('%'))
              }
              break
            case '+':
              indexKeyEnd = resultView.lastIndexOf('+')
              setResultView(resultView.substring(0, indexKeyEnd).concat('%'))
              break
            case '^':
              indexKeyEnd = resultView.lastIndexOf('^')
              setResultView(resultView.substring(0, indexKeyEnd).concat('%'))
              break
            default:
              const tmp = typeof resultView === 'string' ? resultView : resultView.toString()
              setResultView(tmp.concat('%'))
              break
          }
        }
        break

      case '(':
        if (resultView !== 'Error' && keyEnd !== '.' && keyEnd !== ')') {
          if (typeof resultView === 'string') setResultView(resultView.concat('('))
          else setResultView('(')
        }
        break
      case ')':
        if (
          typeof resultView === 'string' &&
          resultView !== 'Error' &&
          resultView !== '' &&
          keyEnd !== '.' &&
          keyEnd !== '(' &&
          resultView.indexOf('(') !== -1
        ) {
          let countStart = countKey('(')
          let countEnd = countKey(')')
          if (countEnd < countStart) setResultView(resultView.concat(')'))
        }
        break

      case 'sin':
        if (resultView !== 'Error' && keyEnd !== '.')
          typeof resultView === 'string'
            ? setResultView(resultView.concat('sin('))
            : setResultView('sin(')
        break
      case 'cos':
        if (resultView !== 'Error' && keyEnd !== '.')
          typeof resultView === 'string'
            ? setResultView(resultView.concat('cos('))
            : setResultView('cos(')
        break
      case 'tan':
        if (resultView !== 'Error' && keyEnd !== '.')
          typeof resultView === 'string'
            ? setResultView(resultView.concat('tan('))
            : setResultView('tan(')
        break

      case 'log':
        if (resultView !== 'Error' && keyEnd !== '.')
          typeof resultView === 'string'
            ? setResultView(resultView.concat('log('))
            : setResultView('log(')
        break

      case 'x^y':
        if (
          resultView !== 'Error' &&
          resultView !== '' &&
          keyEnd !== '^' &&
          keyEnd !== '.' &&
          keyEnd !== '('
        ) {
          let indexKeyEnd
          switch (keyEnd) {
            case '÷':
              indexKeyEnd = resultView.lastIndexOf('÷')
              setResultView(resultView.substring(0, indexKeyEnd).concat('^'))
              break
            case 'x':
              indexKeyEnd = resultView.lastIndexOf('x')
              setResultView(resultView.substring(0, indexKeyEnd).concat('^'))
              break
            case '-':
              if (keyLast !== '÷' && keyLast !== 'x' && keyLast !== undefined) {
                indexKeyEnd = resultView.lastIndexOf('-')
                setResultView(resultView.substring(0, indexKeyEnd).concat('^'))
              }
              break
            case '+':
              indexKeyEnd = resultView.lastIndexOf('+')
              setResultView(resultView.substring(0, indexKeyEnd).concat('^'))
              break
            default:
              const tmp = typeof resultView === 'string' ? resultView : resultView.toString()
              setResultView(tmp.concat('^'))
              break
          }
        }
        break

      case 'π':
        if (resultView !== 'Error' && keyEnd !== '.')
          typeof resultView === 'string'
            ? setResultView(resultView.concat('π'))
            : setResultView('π')
        break

      case '√':
        if (resultView !== 'Error' && keyEnd !== '.')
          typeof resultView === 'string'
            ? setResultView(resultView.concat('√('))
            : setResultView('√(')
        break

      case 'Rad':
        setIsRadTmp(true)
        break
      case 'Deg':
        setIsRadTmp(false)
        break

      // case '=':
      //   if (resultView !== '' && typeof resultView === 'string') {
      //     let expression = resultView;
      //     // covert btn -> bieu thuc theo lib
      //     if (resultView.indexOf('x') !== -1) expression = expression.replaceAll('x', '*')
      //     if (resultView.indexOf('÷') !== -1) expression = expression.replaceAll('÷', '/')
      //     if (resultView.indexOf('%') !== -1) expression = expression.replaceAll('%', '/100')
      //     if (resultView.indexOf('π') !== -1) expression = expression.replaceAll('π', 'pi')
      //     if (resultView.indexOf('√(') !== -1) expression = expression.replaceAll('√(', 'root(')

      //     try {
      //       const result = mexp.eval(expression);
      //       setResultView(result);
      //     }
      //     catch(e : any){
      //       console.log(e.message)
      //       setResultView('Error');
      //     }
      //   }
      //   break;
    }
  }

  const countKey = (key: string) => {
    var count = resultView.split(key).length - 1
    return count
  }

  const addVariable = () => {
    if (settingTarget) {
      if (!listTmPush.includes(settingTarget)) listTmPush?.push(settingTarget)
      let newListAtt = [...listAtt]
      newListAtt = newListAtt?.filter((item) => item?.value !== settingTarget)
      setListAtt(newListAtt)
    }
  }
  const addTmIntoExp = (item: string) => {
    const itemSelect = listAttSelected?.find((itemList: any) => itemList?.attributeKey === item)
    const attItem = itemSelect?.dataKeyPrefix + itemSelect?.attributeIndex
    if (resultView?.concat(attItem.trim())?.length >= EMaxLength.maxCaculator) {
      return
    }
    const expTmp = resultView.concat(item)
    setResultView(expTmp)
  }
  const delTm = (item: string) => {
    listTmPush = listTmPush?.filter((itemList) => itemList !== item)
    const attRemoveSelect = listAttSelected?.find((x: any) => x?.attributeKey === item)
    let arrListAtt = [...listAtt]
    arrListAtt?.push(attRemoveSelect)
    // get list att tm and sort
    let listAttTm = arrListAtt?.filter((item: any) => item?.dataKeyPrefix !== dataKeyPrefixTv)
    listAttTm = sortListAttByAttributeIndex(listAttTm)
    // get list att tv and sort
    let listAttTv = arrListAtt?.filter((item: any) => item?.dataKeyPrefix === dataKeyPrefixTv)
    listAttTv = sortListAttByAttributeIndex(listAttTv)
    // list att sort
    const arrConcatAtt = listAttTm?.concat(listAttTv)
    setListAtt(arrConcatAtt)
    if (resultView?.indexOf('_soil_temperature') > -1 && item === '_temperature') {
      let view = resultView?.replaceAll('_soil_temperature', '_soil_Temperature')
      view = view?.replaceAll(item, '')
      view = view?.replaceAll('_soil_Temperature', '_soil_temperature')
      setResultView(view)
    } else {
      setResultView(resultView?.replaceAll(item, ''))
    }
  }

  const renderTmSeleted = () => {
    return (
      <div className='list-tm-seleted'>
        {listTmPush?.map((item, index) => (
          <div key={index} className='tm-selected'>
            <div onClick={() => addTmIntoExp(item)} className='operator-btn w-btn-selected'>
              {listAttSelected
                ?.find((x: any) => x.attributeKey === item)
                ?.name.substring(
                  0,
                  listAttSelected?.find((x: any) => x.attributeKey === item)?.name.indexOf('　')
                )}
            </div>
            <div className='tm-name-selected'>
              <TooltipComponent maxSlice={7} title={getNameAtt(listAttSelected, item)} />
            </div>
            <div>
              <img
                onClick={() => delTm(item)}
                src={IconDelTm}
                alt='del_tm'
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className='calculator-sheet'>
      <div className='display-table mt-16x'>
        <div className='calculator'>
          <div className='pd_16x_0x'>
            <div className='mg_0_16x'>
              <Row>
                <Col span={16}>
                  <div className='display-label'>
                    {t('displayManagement.calculator.displayLabel')}
                  </div>
                  <div className='display'>
                    <div className='display-result'>
                      <div>
                        <div className='word-break-all'>
                          {resultView ? convertViewFormula(listAttSelected, resultView) : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='how-to mb-16x'>{t('displayManagement.calculator.textGuide')}</div>
                  <Row>
                    {caculator?.map((item: any) => (
                      <Col span={item?.span} key={item?.key}>
                        {item?.children ? (
                          <div className={item?.className}>
                            {item?.children?.map((itemChild: any) => (
                              <div className={itemChild?.classDiv} key={itemChild?.key}>
                                {itemChild?.isButton ? (
                                  <button
                                    onClick={() => {
                                      caculationView(itemChild?.value)
                                    }}
                                    className={
                                      itemChild?.name === 'Rad' && !isRadTmp
                                        ? 'btn-disable'
                                        : itemChild?.name === 'Deg' && isRadTmp
                                        ? 'btn-disable'
                                        : ''
                                    }>
                                    {itemChild?.name}
                                  </button>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        ) : item?.value ? (
                          <button
                            onClick={() => {
                              caculationView(item?.value)
                            }}
                            className={item?.className}>
                            {item?.name}
                          </button>
                        ) : null}
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col span={8} className='type-select'>
                  <Row>
                    <Col span={18} className='select-sensor'>
                      <SelectComponent
                        disableAll={true}
                        lable={t('caculation.variableCalculator')}
                        styleLabel={{ fontWeight: 'normal' }}
                        defaultOption={settingTarget}
                        dataOption={listAtt}
                        onChangeSelect={handleOnChangeSelectSettingTarget}
                      />
                    </Col>
                    <Col span={4} className='btn-add'>
                      <button
                        disabled={listTmPush.length > 2}
                        className='add-sensor'
                        onClick={addVariable}>
                        {t('displayManagement.calculator.btnAddSensor')}
                      </button>
                    </Col>
                  </Row>
                  <div className='option-cond'>{t('displayManagement.calculator.textGuide2')}</div>
                  {listTmPush.length > 0 ? renderTmSeleted() : null}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalculatorComponent
