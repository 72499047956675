export enum ESerialNoAction {
  LIST_KATASHIKI = 'LIST_KATASHIKI',
  CREATE_INDIVIDUAL = 'CREATE_INDIVIDUAL',
  CREATE_BULK = 'CREATE_BULK',
  LIST_SENSOR = 'LIST_SENSOR',
  LIST_SERIAL_NO = 'LIST_SERIAL_NO',
  SEARCH_SERIAL_NO = 'SEARCH_SERIAL_NO',
  SERIAL_NO_DETAIL = 'SERIAL_NO_DETAIL',
  BULK_SERIAL_NO_DETAIL = 'BULK_SERIAL_NO_DETAIL',
  DELETE_SERIAL_NO = 'DELETE_SERIAL_NO',
  CONFIRM_STATUS = 'CONFIRM_STATUS',
  UPDATE_SERIAL_NO = 'UPDATE_SERIAL_NO',
  UPDATE_BULK_SERIAL_NO = 'UPDATE_BULK_SERIAL_NO',
  DOWNLOAD_JSON = 'DOWNLOAD_JSON',
  SERIAL_NO_FOR_ORDER = 'SERIAL_NO_FOR_ORDER',
  IMPORT_CSV = 'IMPORT_CSV'
}
