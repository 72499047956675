import { dataKeyPrefixTv } from '../multiSettings/defaultValue'
import { convertTypeSensor } from '../sensor/convertData'

const convertDataDisplay = (data: any) => {
  data = data.map((item: any) => {
    return {
      key: item?.id?.toString(),
      settingType: item?.type,
      settingTarget: convertTypeSensor(item?.sensorType[0]?.sensorTypeTranslate),
      lteOrLora: item?.sensorTypeParentTranslate?.sensorTypeKey,
      sensorName: item?.sensorName,
      email: item?.email,
      userName: item?.user?.username,
      deviceId: item?.deviceId,
      setting: item?.description,
      dateTimeRegis: item?.createdAt,
      dateTimeUpdate: item?.updatedAt,
      statusFormula: item?.statusFormula
    }
  })
  return data
}
const convertListOptionSensor = (data: any) => {
  data = data?.map((item: any) => {
    return {
      id: item?.sensorAiId,
      name: item?.sensorName,
      value: item?.sensorName
    }
  })
  return data
}
const convertTypeParentSensorToNumber = (type: string) => {
  if (type === 'lora') {
    return 1
  }
  return 2
}
const getNameAttribute = (data: any) => {
  let text = ''
  if (data) {
    data?.forEach((item: any) => {
      if (item?.lang === 'jp') {
        text = item?.attributeName
        return
      }
    })
  }
  return text
}
const listAttributeTv = (data: any) => {
  data = data?.filter((item: any) => item?.dataKeyPrefix === dataKeyPrefixTv && !item?.deletedAt)
  return data
}
export {
  convertDataDisplay,
  convertTypeParentSensorToNumber,
  convertListOptionSensor,
  getNameAttribute,
  listAttributeTv
}
