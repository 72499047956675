import { AuditLogActionTypes } from './../../actions/auditLog/auditLogTypes';
import { IAuditLogState } from "redux/actions/auditLog/auditLogTypes";

const initialState: IAuditLogState = {
   auditLogList: {
      content: [],
      currentPage: 1,
      totalPages: 1,
      payloadSize: 20,
      hasNext: false,
      skippedRecords: 0,
      totalRecords: 0
    },
    auditLogDetail: {
      auditLogId: 0,
      userId: 0,
      userType: '',
      username: '',
      tableName: '',
      recordId: '',
      action: 0,
      createdAt: '',
      updatedAt: '',
    }
 }

 const auditLogReducer = (
   state: IAuditLogState = initialState,
   action: AuditLogActionTypes
 ): IAuditLogState => {
   switch (action.type) {
     default:
       return state
   }
 }
 export default auditLogReducer
 