import {
  IListSensorParams,
  IListSerialNo,
  IListSerialNoParams,
  ICreateSerialNoParams,
  IDeleteSerialNoParams,
  ISerialNoDetailParams,
  IChangeConfirmStatusParams,
  IBulkSerialNoDetailParams,
  IUpdateBulkSerialNoParams,
  IDownloadJsonParams,
  IGetListSerialForOrderParams,
  IImportCsvParams
} from 'services/params/serialNo/IserialNoParam'
import { importCsvFile, serialNoRequest } from 'services/requests/serialNo/serialNoRequest'
import { configRequest } from '..'
import { Methods } from '../EMethod'

class SerialNoApis {
  getListSerialNo = (param: IListSerialNo) => {
    const request = serialNoRequest(param, Methods.GET_LIST_SERIAL_NO)
    return configRequest(request)
  }
  getListKatashiki = (params: any) => {
    const requests = serialNoRequest(params, Methods.LIST_KATASHIKI)
    return configRequest(requests)
  }
  getListSensor = (params: IListSensorParams) => {
    const requests = serialNoRequest(params, Methods.LIST_SENSOR)
    return configRequest(requests)
  }
  createIndividual = (params: ICreateSerialNoParams) => {
    const requests = serialNoRequest(params, Methods.SERIAL_NO_CREATE)
    return configRequest(requests)
  }
  createBulk = (params: any) => {
    const requests = serialNoRequest(params, Methods.SERIAL_NO_CREATE)
    return configRequest(requests)
  }
  serialDetail = (params: ISerialNoDetailParams) => {
    const requests = serialNoRequest(params, Methods.SERIAL_NO_DETAIL)
    return configRequest(requests)
  }
  bulkSerialDetail = (params: IBulkSerialNoDetailParams) => {
    const requests = serialNoRequest(params, Methods.BULK_SERIAL_NO_DETAIL)
    return configRequest(requests)
  }
  deleteSerialNo = (params: IDeleteSerialNoParams) => {
    const requests = serialNoRequest(params, Methods.DELETE_SERIAL_NO)
    return configRequest(requests)
  }
  changeConfirmStatus = (params: IChangeConfirmStatusParams) => {
    const requests = serialNoRequest(params, Methods.CONFIRM_STATUS)
    return configRequest(requests)
  }
  getSerialNoDropdown = (params: IListSerialNoParams) => {
    const requests = serialNoRequest(params, Methods.GET_SERIAL_NO)
    return configRequest(requests)
  }
  updateSerialNo = (params: ICreateSerialNoParams) => {
    const requests = serialNoRequest(params, Methods.UPDATE_SERIAL_NO)
    return configRequest(requests)
  }
  updateBulkSerialNo = (params: IUpdateBulkSerialNoParams) => {
    const requests = serialNoRequest(params, Methods.UPDATE_BULK_SERIAL_NO)
    return configRequest(requests)
  }
  downloadJson = (params: IDownloadJsonParams) => {
    const requests = serialNoRequest(params, Methods.DOWNLOAD_JSON)
    return configRequest(requests)
  }
  getListForOrder = (params: IGetListSerialForOrderParams) => {
    const requests = serialNoRequest(params, Methods.SERIAL_NO_FOR_ORDER)
    return configRequest(requests)
  }
  importCsvFile = (data: IImportCsvParams) => {
    const requests = importCsvFile(data)
    return requests
  }
}

export const SerialNoApi = new SerialNoApis()
