const defaultPermission = [
  {
    id: '1',
    name: '閲覧',
    value: 'admin_view'
  },
  {
    id: '2',
    name: '全権限',
    value: 'admin_crud'
  }
]

export { defaultPermission }
