import { EOrderAction } from 'redux/actions/order/EOrderAction'
import { IOrderState, OrderActionTypes } from 'redux/actions/order/orderTypes'

const initState: IOrderState = {
  orderList: {
    content: [],
    currentPage: 1,
    totalPages: 1,
    payloadSize: 20,
    hasNext: false,
    skippedRecords: 0,
    totalRecords: 0
  },
  orderDetail: {
    customerName: '',
    orderId: '',
    notes: '',
    confirmDate: '',
    orderPlace: '',
    serialNumber: '',
    deviceId: '',
    modelRegistration: 1,
    communicationType: 1,
    sensorTranslateType: 1,
    productSite: '',
    confirmStatus: false
  }
}
const orderReducer = (state: IOrderState = initState, action: OrderActionTypes): IOrderState => {
  switch (action.type) {
    case EOrderAction.LIST_ORDER:
      return { ...state }
    default:
      return state
  }
}
export default orderReducer
