import { colors } from 'utils/colors'

const VFooter = () => {
  return (
    <div
      style={{
        backgroundColor: '#FFF',
        height: 48,
        boxShadow: '0px -2px 15px rgba(167, 167, 167, 0.2)',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 16,
        paddingBottom: 16,
        color: colors.GRAY
      }}>
      2021 © copy right : Nishimu Electronics Industries Co.,Ltd. All rights reserved
    </div>
  )
}

export default VFooter
