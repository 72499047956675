import 'styles/Information.scss'
import { useTranslation } from 'react-i18next'
import InputDateComponent from 'components/common/input/InputDateComponent'
import InputTimeComponent from 'components/common/input/InputTimeComponent'
import InputTextAreaComponent from 'components/common/input/InputTextAreaComponent'
import ButtonFooterComponent from 'components/common/button/ButtonFooterComponent'
import { useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import { useDispatch, useSelector } from 'react-redux'
import { originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import { AppState } from 'redux/reducers'
import moment from 'moment'
import { createAlertAction } from 'redux/actions/notifications/notificationAction'
import { ADMIN_ROUTE, routesName, routesInfoManagement } from 'views/routes/routes'
import AccessDeniedComponent from 'views/pages/accessDenied/AccessDeniedComponent'
import { EDate } from 'interfaces/enums/EDate'
const diffTime = (timeEnd: any, timeStart: any) => {
  const diffTimeValue = moment
    .utc(moment(timeEnd, EDate.DATEJP).diff(moment(timeStart, EDate.DATEJP)))
    .valueOf()
  return diffTimeValue
}
const InfoCreate = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const refDateDetail = useRef()
  const location = useLocation()
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  const [startDateDetail, setStartDateDetail] = useState(new Date())
  const refTimeDetail = useRef()
  const [startTimeDetail, setStartTimeDetail] = useState(new Date())
  const [title, setTitle] = useState<string>('')
  const styleTile = { minHeight: 90, maxHeight: 90 }
  const [content, setContent] = useState<string>('')
  const styleText = { minHeight: 120, maxHeight: 120 }
  const history = useHistory()
  const [errTime, setErrorTime] = useState<string>('')
  const [errText, setErrText] = useState<string>('')
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  const showSuccess = (text: string) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text,
      isRedirect: true,
      href: ADMIN_ROUTE.concat(routesName.INFO_MANAGEMENT)
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }

  const handleOnChangeDate = (date: Date) => {
    setStartDateDetail(date)
    const currentTime = moment().format(EDate.DATEJP)
    const concatTime = moment(startDateDetail)
      .format('YYYY/MM/DD')
      .concat(' ' + moment(date).format('HH:mm'))
    const diffTimeValue = diffTime(currentTime, concatTime)
    if (diffTimeValue > 0 || date === null) {
      setErrorTime(t('errors.time'))
    } else {
      setErrorTime('')
    }
  }
  const handleOnChangeTime = (date: Date) => {
    setStartTimeDetail(date)
    const currentTime = moment().format(EDate.DATEJP)
    const concatTime = moment(startDateDetail)
      .format('YYYY/MM/DD')
      .concat(' ' + moment(date).format('HH:mm'))
    const diffTimeValue = diffTime(currentTime, concatTime)
    if (diffTimeValue > 0 || date === null) {
      setErrorTime(t('errors.time'))
    } else {
      setErrorTime('')
    }
  }
  const handleOnChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value)
    if (e.target.value.trim() === '') {
      setErrText(t('infoManagement.error.text'))
    } else {
      setErrText('')
    }
  }
  const handleOnChangeTitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(e.target.value)
  }
  const checkDisabled = () => {
    if (content.trim().length > 0 && !errTime && !errText) {
      return false
    }
    return true
  }
  const onClickBtnReturn = () => {
    history.goBack()
  }
  const onClickBtnSave = () => {
    const currentDateAndTime = moment().format(EDate.DATEJP)
    const concatTime = moment(startDateDetail)
      .format('YYYY/MM/DD')
      .concat(' ' + moment(startTimeDetail).format('HH:mm'))
    const time = new Date(concatTime).toLocaleString('en-US', {
      timeZone: 'UTC',
      hourCycle: 'h23'
    })
    if (startDateDetail && startTimeDetail && concatTime && concatTime >= currentDateAndTime) {
      const params = {
        title: title,
        content: content,
        timePushNotification: time
      }
      dispatch(
        createAlertAction(
          { ...params },
          (data) => {
            if (data?.error) {
              if (data?.error?.code === 400) {
                setErrText(t(`errors.message.${data?.error?.data?.content[0]}`))
                dispatch(SetLoadingAction(false))
              } else {
                dispatch(SetLoadingAction(false))
              }
            } else {
              showSuccess(t('modal.contentSuccessCreate'))
            }
          },
          true
        )
      )
    } else {
      setErrorTime(t('errors.time'))
    }
  }
  return (
    <div>
      {ROLE_CRUD.includes(role) &&
      location.pathname === ADMIN_ROUTE.concat(routesInfoManagement.INFO_CREATE) ? (
        <div className='bgrffffff' style={{ padding: '16px' }}>
          <div className='info-detail'>
            <div className='label-bold' tabIndex={-1}>
              {t('infoManagement.deliveryDateTime')}
            </div>
            <div style={{ display: 'flex' }}>
              <InputDateComponent
                minDate={new Date()}
                refer={refDateDetail}
                state={startDateDetail}
                readOnly={false}
                onChangeDate={(date: Date) => handleOnChangeDate(date)}
              />
              <InputTimeComponent
                refer={refTimeDetail}
                state={startTimeDetail}
                readOnly={false}
                onChangeDate={(date: Date) => handleOnChangeTime(date)}
              />
            </div>
            <div className='custom-error'>{errTime ? errTime : ''}</div>
            <div style={{ marginTop: 25 }} className='label-bold'>
              {t('infoManagement.title')}
            </div>
            <InputTextAreaComponent
              maxLength={160}
              state={title}
              style={styleTile}
              onChangeText={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleOnChangeTitle(e)}
              disabled={false}
              placeholder={t('infoManagement.plTitle')}
            />
            <div className='text-note'>{t('infoManagement.noteMaximumTitle')}</div>
            <div className='label-bold' style={{ marginTop: '25px' }}>
              {t('infoManagement.text')} <span style={{ color: '#d7000f' }}>*</span>
            </div>
            <InputTextAreaComponent
              maxLength={3000}
              state={content}
              style={styleText}
              onChangeText={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleOnChangeText(e)}
              disabled={false}
              placeholder={t('infoManagement.plContent')}
            />
            <div className='d-flex justify-content-space-between'>
              <div>
                <div className='custom-error'>{errText ? errText : null}</div>
              </div>
              <div className='text-note'> {t('infoManagement.noteMaximumContent')}</div>
            </div>
            <div className='view-btn-ft'>
              <ButtonFooterComponent
                text={t('infoManagement.btnSaveCreate')}
                classSty='btn-edit'
                onClickButton={onClickBtnSave}
                disabled={checkDisabled()}
              />
              <ButtonFooterComponent
                text={t('infoManagement.btnReturn')}
                classSty='btn-return margin-left-10'
                onClickButton={onClickBtnReturn}
              />
            </div>
          </div>
        </div>
      ) : (
        <AccessDeniedComponent />
      )}
    </div>
  )
}

export default InfoCreate
