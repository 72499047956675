import i18n from 'configs/i18n'

const country = [
  {
    id: 'AN',
    name: 'India'
  },
  {
    id: 'JP',
    name: i18n.t('japan')
  }
]
export default country
