type Props = {
  icon: any
}
const IconButton = ({ icon }: Props) => {
  return (
    <>
      <img src={icon} alt='ic' style={styleIconBtn} />
    </>
  )
}
const styleIconBtn = {
  height: 16,
  width: 16
}
export default IconButton
