import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { DELAY } from 'interfaces/enums/EDelay'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, put, call, takeLatest, delay } from 'redux-saga/effects'
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { EUserDetailActions } from 'redux/actions/userDetail/EUserDetailAction'
import {
  IGetListDataOfUserDetailAction,
  IUpdateEmailAlertAction,
  IUpdateUserAction,
  IUserDetailAction
} from 'redux/actions/userDetail/usersDetailTypes'
import { UserDetailApi } from 'services/api/userDetail/userDetailApi'

function* getUserDetailSaga(action: IUserDetailAction) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(UserDetailApi.userDetail, action.param)
    if (response?.data?.result) {
      action.callBack(response?.data)
    } else {
      if (response?.data?.error && response?.data?.error?.code === 400) {
        action.callBack(response?.data?.error)
      } else {
        err = response?.data?.error
      }
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* getListDataOfUserDetail(action: IGetListDataOfUserDetailAction) {
  let err: any = null
  try {
    const { listEmailAlert, listSensor } = yield all({
      listEmailAlert: call(UserDetailApi.getListEmailAlert, action.param.emailAlert),
      listSensor: call(UserDetailApi.getListSensor, action.param.sensor)
    })
    action.callBack([listEmailAlert?.data, listSensor?.data])
  } catch (error) {
    err = error
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
  }
}

function* updateEmailAlert(action: IUpdateEmailAlertAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      UserDetailApi.updateEmailAlert,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* updateUser(action: IUpdateUserAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(UserDetailApi.updateUser, action.param)
    if (response?.data?.error && response?.data?.error?.code !== 400) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
export default function* userDetailSaga() {
  yield all([
    takeLatest(EUserDetailActions.USER_DETAIL, getUserDetailSaga),
    takeLatest(EUserDetailActions.FETCH_LIST_DATA_OF_USER_DETAIL, getListDataOfUserDetail),
    takeLatest(EUserDetailActions.UPDATE_EMAIL_ALERT, updateEmailAlert),
    takeLatest(EUserDetailActions.UPDATE_USER, updateUser)
  ])
}
