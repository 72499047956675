import { EQrcodeActions } from 'redux/actions/qrcode/EQrcodeAction'
import { IQrcodeState } from 'redux/actions/qrcode/qrcodeAction'
import { QrcodeActionTypes } from 'redux/actions/qrcode/qrcodeTypes'

const initialState: IQrcodeState = {
  qrList: [],
  qrListSearch: [],
  detailQr: undefined
}

const qrReducer = (state = initialState, action: QrcodeActionTypes) => {
  switch (action.type) {
    case EQrcodeActions.SET_LIST_QR:
      const newQrList = [...action.data]
      newQrList.forEach((item) => (item['key'] = item.id))
      return {
        ...state,
        qrList: newQrList
      }
    case EQrcodeActions.SET_LIST_SEARCH:
      const newQrListSearch = [...action.data]
      newQrListSearch.forEach((item) => (item['key'] = item.id))
      return {
        ...state,
        qrListSearch: newQrListSearch
      }
    case EQrcodeActions.DELETE_QR:
      const newQr = [...state.qrList]
      const newList = newQr.filter((item) => item.id !== action.id)
      return {
        ...state,
        qrList: newList
      }
    case EQrcodeActions.SET_DETAIL_QR:
      return {
        ...state,
        detailQr: action.data
      }
    default:
      return state
  }
}

export default qrReducer
