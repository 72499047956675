import { useTranslation } from 'react-i18next'
import { ConvertTime } from 'utils/Functions'
import { ColumnsType } from 'antd/es/table'
import TableComponent from 'components/common/table/TableComponent'
import { defaultShowBlank } from '../../defaultValue'

interface IPropsTableLTE {
  data: any
}
interface IDataLTE {
  key: number
  category: string
  logDateTime: string
  requestPeriodStart: string
  requestPeriodEnd: string
}
function TableTypeLTE({ data }: IPropsTableLTE) {
  const { t } = useTranslation()
  const columns: ColumnsType<IDataLTE> = [
    {
      title: t('sensorDetail.tableLte.no'),
      dataIndex: 'no',
      width: '5%'
    },
    {
      title: t('sensorDetail.tableLte.category'),
      dataIndex: 'category',
      width: '14%'
    },
    {
      title: t('sensorDetail.tableLte.logDateTime'),
      dataIndex: 'logDateTime',
      width: '20%',
      render: (_: object, record: { logDateTime: string }) => {
        return <span>{record?.logDateTime ? ConvertTime(record?.logDateTime) : '-'}</span>
      }
    },
    {
      title: t('sensorDetail.tableLte.requestPeriod'),
      dataIndex: 'requestPeriodStart',
      render: (_: object, record: { requestPeriodStart: string; requestPeriodEnd: string }) => {
        return (
          <span>
            {record?.requestPeriodStart && record?.requestPeriodEnd
              ? ConvertTime(record?.requestPeriodStart) +
                ' ~ ' +
                ConvertTime(record?.requestPeriodEnd)
              : defaultShowBlank}
          </span>
        )
      }
    }
  ]
  return <TableComponent columns={columns} dataSource={data} />
}
export default TableTypeLTE
