import {
  IListAlert,
  IDetailAlert,
  IUpdateAlert,
  ICreateAlert
} from 'services/params/notification/INotificationParams'
import {
  getListAlertRequest,
  createAlertRequest,
  detailAlertRequest,
  updateAlertRequest
} from 'services/requests/notification/notificationRequest'
import { configRequest } from '..'
class NotificationsApis {
  getListAlert = (request: IListAlert) => {
    const requests = getListAlertRequest(request)
    return configRequest(requests)
  }
  createAlert = (request: ICreateAlert) => {
    const requests = createAlertRequest(request)
    return configRequest(requests)
  }
  updateAlert = (request: IUpdateAlert) => {
    const requests = updateAlertRequest(request)
    return configRequest(requests)
  }
  detailAlert = (request: IDetailAlert) => {
    const requests = detailAlertRequest(request)
    return configRequest(requests)
  }
}
export const NotificationApi = new NotificationsApis()
