export const headerCsvOrder = [
  {
    label: '登録日',
    key: 'createdAt'
  },
  {
    label: '更新日',
    key: 'updatedAt'
  },
  {
    label: '顧客名',
    key: 'customerName'
  },
  {
    label: 'オーダ',
    key: 'orderCode'
  },
  {
    label: '納入確定日',
    key: 'dateDelivery'
  },
  {
    label: '納入場所',
    key: 'address'
  },
  {
    label: '製造番号',
    key: 'serialNoNumber'
  },
  {
    label: '型式',
    key: 'model'
  },
  {
    label: '製造年月',
    key: 'serialCreatedAt'
  },
  {
    label: 'デバイスID',
    key: 'deviceId'
  },
  {
    label: '通信種別',
    key: 'sensorType'
  },
  {
    label: '機器種別',
    key: 'sensorTypeTranslate'
  },
  {
    label: 'セキュリティキー',
    key: 'securityKey'
  },
  {
    label: 'ＩＭＥＩ番号',
    key: 'IMEINumber'
  },
  {
    label: '旧製造番号',
    key: 'serialNoNumberOld'
  },
  {
    label: '無線CH',
    key: 'CHAddress'
  },
  {
    label: '製造工場',
    key: 'productSite'
  },
  {
    label: '疎通確認ステータス',
    key: 'confirmed'
  },
  {
    label: '備考',
    key: 'note'
  }
]
