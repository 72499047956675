import { Tooltip } from 'antd'
import 'styles/Tooltip.scss'

interface ITooltip {
  title: string
  maxSlice?: number
  spanStyle?: React.CSSProperties
}
function TooltipComponent({ title, maxSlice, spanStyle }: ITooltip) {
  const slice = maxSlice ? maxSlice : 10
  const _renderHover = (title: string) => {
    return (
      <div className='customTooltip'>
        <div className='tooltip-title'>{title}</div>
      </div>
    )
  }
  return (
    <div>
      <Tooltip title={_renderHover(title)} placement='top'>
        <span style={spanStyle}>{title?.length > slice ? title.slice(0, slice) + '...' : title}</span>
      </Tooltip>
    </div>
  )
}
export default TooltipComponent
