import { getCountryName, getNameCity, getNameState } from 'data/address'
import { convertTypeSensor } from '../convertData'
import { communicationStatusSetting } from '../defaultValue'

const convertListSensorByGroup = (data: any) => {
  data = data?.record?.map((item: any, index: number) => {
    return {
      key: item?.sensorAiId,
      no: index + 1,
      deviceID: item?.deviceId,
      sensorName: item?.sensorName,
      sensorType: convertTypeSensor(item?.sensorType?.sensorTypeTranslate),
      lteOrLora: item?.sensorTypeParent?.sensorTypeKey,
      imeiNumber: item?.imei,
      securityKey: item?.securityKey,
      communicationStatus:
        item?.statusTransmission === 1
          ? communicationStatusSetting.abnormal
          : communicationStatusSetting.normal,
      battery:
        item?.statusBattery === 1
          ? communicationStatusSetting.abnormal
          : communicationStatusSetting.normal,
      registerDate: item?.createdAt,
      lastDate: item?.pushAtData
    }
  })
  return data
}
const showAddress = (data: any) => {
  let text = ''
  if (data?.countryId === 'JP') {
    text =
      getCountryName(data?.countryId) +
      getNameState(data?.countryId, data?.statesId) +
      getNameCity(data?.countryId, data?.cityId)
  } else {
    text =
      getNameCity(data?.countryId, data?.cityId) +
      ', ' +
      getNameState(data?.countryId, data?.statesId) +
      ', ' +
      getCountryName(data?.countryId)
  }
  return text
}
const convertDataGroupDetail = (data: any) => {
  const dataGroupDetail = {
    userId: data?.user?.userId,
    userName: data?.user?.username,
    groupName: data?.groupName,
    area: showAddress(data),
    numberSensor: data?.totalSensor?.toString(),
    email: data?.user?.email
  }
  return dataGroupDetail
}
export { convertListSensorByGroup, convertDataGroupDetail }
