import {
  IGetListGroupByEmail,
  IGetListSensorByGroup,
  IGroupDetail
} from 'services/params/group/IGroupParams'
import { EGroupDetailActions } from './EGroupDetailAction'
import {
  IGetListGroupByEmailAction,
  IGetListSensorByGroupAction,
  IGroupDetailAction
} from './groupDetailTypes'

export const groupDetailAction = (
  param: IGroupDetail,
  callBack: (data: any) => void
): IGroupDetailAction => ({
  type: EGroupDetailActions.GROUP_DETAIL,
  param,
  callBack
})
export const getListSensorByGroupAction = (
  param: IGetListSensorByGroup,
  callBack: (data: any) => void
): IGetListSensorByGroupAction => ({
  type: EGroupDetailActions.GET_LIST_SENSOR_BY_GROUP,
  param,
  callBack
})
export const getListGroupByEmailAction = (
  isLoading: boolean,
  param: IGetListGroupByEmail,
  callBack: (data: any) => void
): IGetListGroupByEmailAction => ({
  isLoading,
  type: EGroupDetailActions.GET_LIST_GROUP_BY_EMAIL,
  param,
  callBack
})
