export enum EAuditLogActionType {
    /** Customer action */
  CREATE_CUSTOMER,
  EDIT_CUSTOMER,
  DELETE_CUSTOMER,

  /** Serial action */
  CREATE_SERIAL_NO,
  EDIT_SERIAL_NO,
  DELETE_SERIAL_NO,

  /** Order action */
  CREATE_ORDER,
  EDIT_ORDER,
  DELETE_ORDER,

  /** Alert action */
  CREATE_ALERT,
  EDIT_ALERT,
  DELETE_ALERT
}

export enum EAuditLogType {
  CUSTOMER,
  SERIAL_NO,
  ORDER,
  ALERT
}
 
 