import { Col, Row, Switch } from 'antd'
import {
  IconChangeStatus,
  IconCopyMini,
  IconDeleteModal,
  IconExportCsv,
  IconWarningQr
} from 'assets'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'
import VButton from 'components/button/VButton'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  getSerialNoDetailAction,
  deleteSerialNoAction,
  changeConfirmStatusAction,
  downloadJsonAction
} from 'redux/actions/serialNo/serialNoAction'
import {
  SetLoadingAction,
  SetNotificationAction,
  SuccessAction
} from 'redux/actions/common/commonAction'
import { useEffect, useState } from 'react'
import moment from 'moment'
import ModalComponent from 'components/modal/ModalComponent'
import { ADMIN_ROUTE, routeSerial, routeOrder } from 'views/routes/routes'
import { INotification } from 'interfaces/INotification'
import i18n from 'configs/i18n'
import { ESuccessType } from 'interfaces/enums/ESuccessType'
import ModalEnvironment from 'components/modal/ModalEnvironment'
import NotFoundComponent from 'views/pages/notFound/NotFoundComponent'
import { EAuditLogActionType, EAuditLogType } from 'interfaces/enums/EAuditLog'
import { detailAuditLogAction } from 'redux/actions/auditLog/auditLogAction'

function CreateIndividualSerialDetail() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation();
  const serialNoId = useParams<any>()
  const [serialDetail, setSerialDetail] = useState<any>()
  const [auditLogCreated, setAuditLogCreated] = useState<any>()
  const [auditLogUpdated, setAuditLogUpdated] = useState<any>()
  const [qrcodeGenerateLora, setQrcodeGenerateLora] = useState<any>('')
  const [qrcodeGenerateLTE, setQrcodeGenerateLTE] = useState<any>('')
  const [confirmStatus, setConfirmStatus] = useState<boolean>(false)
  const [jsonData, setJsonData] = useState<any>('')
  const [checkData, setCheckData] = useState<any>('check')
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  const [modalPrevent, setModalPrevent] = useState({
    isVisible: false,
    btnClose: '',
    content: ''
  })
  const showSuccess = (text: string, href?: any) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text,
      href: href,
      isRedirect: true
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }
  useEffect(() => {
    handleGetSerialNoDetail()
    getAuditLog()
    // eslint-disable-next-line 
  }, [])

  const getAuditLog = () => {
    const params: any = {
      recordId: Number(serialNoId?.id),
      type: EAuditLogType.SERIAL_NO
    }
    dispatch(
      detailAuditLogAction(
        { ...params },
        async (data) => {
          const dataAuditLogs = await data?.result?.record;
          for (const dataAuditLog of dataAuditLogs) {

            if (dataAuditLog?.action === EAuditLogActionType.CREATE_SERIAL_NO) {

              setAuditLogCreated(dataAuditLog);
            } else if (dataAuditLog?.action === EAuditLogActionType.EDIT_SERIAL_NO) {

              setAuditLogUpdated(dataAuditLog);
            }
          }

          dispatch(SetLoadingAction(false))
        },
        true
      )
    )
  }


  const handleOnChangeStatus = (checked: any) => {
    setConfirmStatus(checked)
    setModal({
      ...modal,
      isVisible: true,
      type: 'change-status',
      isComponent: false,
      img: IconChangeStatus,
      btnSubmit: t('serialNoScreen.individualDetail.btnSubmitChangeStatus'),
      content:
        serialDetail?.sensorTypeId === 1 && serialDetail?.sensorTypeTranslateId !== 14
          ? t('serialNoScreen.individualDetail.changeStatusContent1')
          : t('serialNoScreen.individualDetail.changeStatusContent2'),
      hideBtnFooter: false
    })
  }
  const changeStatus = async () => {
    const params: any = {
      id: Number(serialNoId?.id),
      confirm: confirmStatus
    }
    dispatch(
      changeConfirmStatusAction(
        { ...params },
        (data) => {
          if (data && data?.result?.id) {
            dispatch(SetLoadingAction(false))
            setModal({ ...modal, isVisible: false })
            showSuccess(t('serialNoScreen.individualDetail.successChangeStatus'))
          }
        },
        true
      )
    )
  }
  const cancelChangeStatus = async () => {
    setConfirmStatus(!confirmStatus)
    setModal({ ...modal, isVisible: false })
  }
  const handleOnClickEditQrCode = () => {
    const serialId = serialDetail?.id
    history.push(
      ADMIN_ROUTE.concat(routeSerial.INDIVIDUAL_EDIT.replace(':id', serialId.toString()))
    )
  }
  const handleOnClickDeleteQrCode = () => {
    setModal({
      ...modal,
      isVisible: true,
      type: 'delete-qr',
      isComponent: false,
      img: IconDeleteModal,
      btnSubmit: t('modal.btnDelete'),
      content: t('serialNoScreen.individualDetail.deleteQrCodeContent'),
      hideBtnFooter: false
    })
  }

  // const handleOnClickBackButton = () => {
  //   const isPath = ADMIN_ROUTE.concat(routeOrder.CREATE_ORDER)
  //   const dataState = JSON.stringify(location.state)
  //   const pushData = JSON.parse(dataState)
  //   const dataPush = pushData.dataPush
  //   history.push({
  //     pathname: isPath,
  //     state: {
  //       customerId: dataPush.customerId ? dataPush.customerId : '',
  //       orderNo: dataPush.orderNo ? dataPush.orderNo : '',
  //       dateDelivery: dataPush.dateDelivery ? dataPush.dateDelivery : '',
  //       address: dataPush.address ? dataPush.address : '',
  //     }
  //   })
  // }
  const deleteQr = async () => {
    const params: any = {
      id: Number(serialNoId?.id)
    }
    dispatch(
      deleteSerialNoAction(
        { ...params },
        (data) => {
          if (data && data?.result?.deletedAt) {
            dispatch(SetLoadingAction(false))
            setModal({ ...modal, isVisible: false })
            showSuccess(
              t('serialNoScreen.individualDetail.successDeleteQr'),
              ADMIN_ROUTE.concat(routeSerial.LIST_SERIAL)
            )
          } else {
            setModalPrevent({
              ...modal,
              isVisible: true,
              content: t('serialNoScreen.individualDetail.preventDeleteQrContent'),
              btnClose: t('serialNoScreen.individualDetail.btnCloseModalPrevent')
            })
            setModal({ ...modal, isVisible: false })
            handleGetSerialNoDetail()
          }
        },
        true
      )
    )
  }
  const cancelDeleteQr = async () => {
    setModal({ ...modal, isVisible: false })
  }
  async function handleOnSubmitModal() {
    switch (modal?.type) {
      case 'delete-qr':
        deleteQr()
        break
      case 'change-status':
        changeStatus()
        break
      default:
        break
    }
  }
  async function handleOnCancelModal() {
    switch (modal?.type) {
      case 'delete-qr':
        cancelDeleteQr()
        break
      case 'change-status':
        cancelChangeStatus()
        break
      default:
        break
    }
  }

  const handleGetSerialNoDetail = () => {
    const params: any = {
      id: Number(serialNoId?.id)
    }
    dispatch(
      getSerialNoDetailAction(
        { ...params },
        async (data) => {
          if (data && data?.result) {
            dispatch(SetLoadingAction(false))
            setSerialDetail(data?.result)
            const serial = await data?.result?.serialNoNumber
            const imei = await data?.result?.IMEINumber
            const securityKey = await data?.result?.securityKey
            const sensorType = await data?.result?.sensorTypeId
            const qrCodeLora = `${serial}`
            const qrCodeLTE = `${serial}${imei}`
            setConfirmStatus(data?.result?.confirmed)
            setQrcodeGenerateLora(qrCodeLora)
            setQrcodeGenerateLTE(qrCodeLTE)
            if (securityKey || securityKey === null || sensorType === 1) {
              const paramLora: any = {
                securityKey: securityKey ?? null
              }
              handleGetJsonData(paramLora)
            } else {
              const paramLtem: any = {
                imei: imei ?? null
              }
              handleGetJsonData(paramLtem)
            }
            setCheckData('dataExist')
          } else {
            setCheckData('noData')
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  const downloadQrcode = () => {
    const canvas = document.getElementById('qrcode') as HTMLCanvasElement
    if (canvas) {
      const checkSensorType = serialDetail?.sensorTypeId
      const imgName = `${checkSensorType === 1 ? qrcodeGenerateLora : qrcodeGenerateLTE}.png`
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      const downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = imgName
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }
  const handleCopySecurityKey = () => {
    navigator.clipboard.writeText(serialDetail?.securityKey)
    const copySuccess: INotification = {
      title: '',
      message: i18n.t('qrcodeScreen.qrDetail.copied'),
      notiType: ESuccessType.SUCCESS
    }
    dispatch(SetNotificationAction(copySuccess))
  }
  const _renderSerialDetail = (
    name: string,
    value: any,
    customSpan?: number,
    copy?: boolean,
    customGutter?: number,
    color?: string
  ) => {
    return (
      <Col span={customSpan ?? 12}>
        <Row gutter={customGutter ?? 24}>
          <Col span={7} style={{ fontWeight: 'bold', marginBottom: 24, color: `${color ? color : ''}` }}>
            {name}
          </Col>
          {copy ? (
            <>
              <Col span={12} style={{ wordBreak: 'break-all' }}>
                {value}
              </Col>
              <Col span={2} style={{ paddingRight: 16 }}>
                {copy ? (
                  <img
                    className='pointer'
                    onClick={handleCopySecurityKey}
                    src={IconCopyMini}
                    alt='copy'
                  />
                ) : null}
              </Col>
            </>
          ) : (
            <Col span={17} style={{ wordBreak: 'break-all' }}>
              {value}
            </Col>
          )}
        </Row>
      </Col>
    )
  }
  const _renderQrCodeInfo = () => {
    const sensorType = serialDetail?.sensorTypeId
    const sensorTranslateType = serialDetail?.sensorTypeTranslateId
    const imei = serialDetail?.IMEINumber
    if (sensorType && sensorTranslateType && sensorType === 1 && sensorTranslateType === 14) {
      return (
        <>
          <Col span={12}>
            <Row>
              <Col span={24}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.serialNumberLabel'),
                  serialDetail?.serialNoNumber,
                  24,
                  false,
                )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.deviceIdLabel'),
                  serialDetail?.deviceId,
                  24,
                  false
                )}
                {serialDetail?.securityKey
                  ? _renderSerialDetail(
                    t('serialNoScreen.individualDetail.securityKeyLabel'),
                    serialDetail?.securityKey,
                    24,
                    true,
                    24
                  )
                  : _renderSerialDetail(
                    t('serialNoScreen.individualDetail.securityKeyLabel'),
                    'N/A',
                    24,
                    false
                  )}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={7} style={{ fontWeight: 'bold' }}>
                {t('serialNoScreen.individualDetail.QRcodeLabel')}
              </Col>
              <Col span={9}>
                <QRCode
                  id='qrcode'
                  value={serialDetail?.sensorTypeId === 1 ? qrcodeGenerateLora : qrcodeGenerateLTE}
                  size={170}
                  level={'H'}
                  includeMargin={false}
                />
              </Col>
              <Col span={8} className='download-code-container pointer'>
                <div className='download-code pointer' onClick={downloadQrcode}>
                  <img src={IconExportCsv} alt='export-csv' />
                  {t('serialNoScreen.individualDetail.downloadQrLabel')}
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={18}>
                <div className='code-text'>
                  <span>{serialDetail?.serialNoNumber}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </>
      )
    } else if (
      sensorType &&
      sensorTranslateType &&
      sensorType === 1 &&
      sensorTranslateType !== 14
    ) {
      return (
        <>
          <Col span={12}>
            <Row>
              <Col span={24}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.serialNumberLabel'),
                  serialDetail?.serialNoNumber,
                  24,
                  false
                )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.deviceIdLabel'),
                  'N/A',
                  24,
                  false
                )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.securityKeyLabel'),
                  'N/A',
                  24,
                  false
                )}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={24}>
              <Col span={7} style={{ fontWeight: 'bold' }}>
                {t('serialNoScreen.individualDetail.QRcodeLabel')}
              </Col>
              <Col span={9}>
                <div className='blank-qr'>
                  <span className='blank-text'>
                    {t('serialNoScreen.individualDetail.blankQRText')}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </>
      )
    } else if (
      !imei &&
      sensorType &&
      sensorTranslateType &&
      sensorType !== 1 &&
      sensorTranslateType !== 14
    ) {
      return (
        <>
          <Col span={12}>
            <Row>
              <Col span={24}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.serialNumberLabel'),
                  serialDetail?.serialNoNumber,
                  24,
                  false
                )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.deviceIdLabel'),
                  serialDetail?.deviceId,
                  24,
                  false
                )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.securityKeyLabel'),
                  'N/A',
                  24,
                  false
                )}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={24}>
              <Col span={7} style={{ fontWeight: 'bold' }}>
                {t('serialNoScreen.individualDetail.QRcodeLabel')}
              </Col>
              <Col span={7}>
                <div className='qr-warning'>
                  <img src={IconWarningQr} alt='warning' />
                  <span className='warning-text'>
                    {t('serialNoScreen.individualDetail.qrWarningText')}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </>
      )
    } else {
      return (
        <>
          <Col span={12}>
            <Row>
              <Col span={24}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.serialNumberLabel'),
                  serialDetail?.serialNoNumber,
                  24,
                  false
                )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.deviceIdLabel'),
                  serialDetail?.deviceId,
                  24,
                  false
                )}
                {serialDetail?.securityKey
                  ? _renderSerialDetail(
                    t('serialNoScreen.individualDetail.securityKeyLabel'),
                    serialDetail?.securityKey,
                    24,
                    true,
                    24
                  )
                  : _renderSerialDetail(
                    t('serialNoScreen.individualDetail.securityKeyLabel'),
                    'N/A',
                    24,
                    false
                  )}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={24}>
              <Col span={7} style={{ fontWeight: 'bold' }}>
                {t('serialNoScreen.individualDetail.QRcodeLabel')}
              </Col>
              <Col span={9}>
                <QRCode
                  id='qrcode'
                  value={serialDetail?.sensorTypeId === 1 ? qrcodeGenerateLora : qrcodeGenerateLTE}
                  size={170}
                  level={'H'}
                  includeMargin={false}
                />
              </Col>
              <Col span={8} className='download-code-container'>
                <div className='download-code pointer' onClick={downloadQrcode}>
                  <img src={IconExportCsv} alt='export-csv' />
                  {t('serialNoScreen.individualDetail.downloadQrLabel')}
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6} offset={8}>
                <div className='code-text'>
                  <span>{serialDetail?.serialNoNumber}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </>
      )
    }
  }
  // btn download json
  const _renderBtnJson = () => {
    const isDeviceId = serialDetail?.deviceId
    if (isDeviceId === null || isDeviceId === '') {
      return null
    } else {
      return (
        <Col span={4}>
          <span className='btn-download-json pointer' onClick={handleDownloadJson}>
            {t('serialNoScreen.individualDetail.btnDownloadJsonLabel')}
          </span>
        </Col>
      )
    }
  }
  const downloadFileJson = async (serialDetail: any, jsonData: any) => {
    const date = moment().format('YYYYMMDDHHmmss')
    const myData = jsonData
    const fileName = serialDetail?.deviceId + '_' + date
    const json =
      myData === 'このセンサは一回もサーバにデータ送信していません。'
        ? myData
        : JSON.stringify(myData, null, 4)
    const blob = new Blob([json], { type: 'application/json' })
    const href = await URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = href
    link.download = fileName + '.json'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const handleGetJsonData = async (params: any) => {
    dispatch(
      downloadJsonAction(
        { ...params },
        async (data) => {
          if (data && data?.result) {
            dispatch(SetLoadingAction(false))
            const downloadData = await data?.result
            setJsonData(downloadData)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        false
      )
    )
  }
  const handleDownloadJson = async () => {
    downloadFileJson(serialDetail, jsonData)
  }

  const getUpdatedAt = (): string => {
    if (auditLogUpdated?.createdAt) {
      return moment(auditLogUpdated?.createdAt).format('YYYY/MM/DD');
    }
    if (auditLogCreated?.createdAt) {
      return moment(auditLogCreated?.createdAt).format('YYYY/MM/DD');
    }
    return '';
  }

  return (
    <>
      {checkData === 'check' ? null : checkData === 'dataExist' ? (
        <div className='bgrffffff'>
          <div className='serial-no-management pd_16x_0x'>
            <div className='mg_0_16x'>
              <p className='label-w-bold'>
                {t('serialNoScreen.individualDetail.serialDetailLabel')}
              </p>
              <Row gutter={24}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.customerNameLabel'),
                  serialDetail?.customerName !== ''
                    ? serialDetail?.customerName
                    : t('serialNoScreen.individualDetail.noCustomerNameText'),
                  12,
                  false,
                  12
                )}
                {_renderSerialDetail(
                  '納入確定日',
                  serialDetail?.ordDetails?.order?.dateDelivery ? moment(serialDetail?.ordDetails?.order?.dateDelivery).format('YYYY/MM/DD') : '未確定（製造機器出荷後表示される）'
                )}
              </Row>
              <Row gutter={24}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.sensorTypeLabel'),
                  serialDetail?.sensorType?.sensorTypeKey
                )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.modalRegisLabel'),
                  serialDetail?.modelRegistration?.model
                )}
              </Row>
              <Row gutter={24}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.sensorTypeTranslateLabel'),
                  serialDetail?.sensorTypeTranslate?.sensorTypeName
                )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.manufacturingDateLabel'),
                  moment(serialDetail?.manufacturingDate).format('YYYY/MM')
                )}
              </Row>
              <Row gutter={24}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.oldSerialNumberLabel'),
                  serialDetail?.serialNoNumberOld
                )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.productSiteLabel'),
                  serialDetail?.productSite
                )}
              </Row>
              <Row gutter={24}>
                {!serialDetail?.IMEINumber && serialDetail?.sensorTypeId !== 1
                  ? _renderSerialDetail(
                    t('serialNoScreen.individualDetail.imeiLabel'),
                    <span className='no-imei'>
                      {t('serialNoScreen.individualDetail.noImeiText')}
                    </span>
                  )
                  : !serialDetail?.IMEINumber && serialDetail?.sensorTypeId === 1
                    ? _renderSerialDetail(t('serialNoScreen.individualDetail.imeiLabel'), '')
                    : _renderSerialDetail(
                      t('serialNoScreen.individualDetail.imeiLabel'),
                      serialDetail?.IMEINumber
                    )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.channelLabel'),
                  serialDetail?.CHAddress
                )}
              </Row>
              <Row gutter={24}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.noteLabel'),
                  serialDetail?.note
                )}
              </Row>
              <Row gutter={24} style={{ whiteSpace: 'pre-line' }}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.cnfLabel'),
                  serialDetail?.cnfSetting
                )}
              </Row>
              <Row className='group-switch'>
                <Col span={12}>
                  <Row>
                    <Col
                      span={7}
                      style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                      {serialDetail?.sensorTypeId === 1 &&
                        serialDetail?.sensorTypeTranslateId !== 14
                        ? t('serialNoScreen.individualDetail.statusLabel1')
                        : t('serialNoScreen.individualDetail.statusLabel2')}
                    </Col>
                    <Col span={17}>
                      <Row style={{ alignItems: 'center', paddingLeft: 3 }}>
                        <Col span={4}>{t('serialNoScreen.individualDetail.confirmedText')}</Col>
                        <Col span={4}>
                          <Switch
                            onClick={handleOnChangeStatus}
                            className='custom-switch'
                            checked={confirmStatus}
                          />
                        </Col>
                        <Col span={4}>{t('serialNoScreen.individualDetail.unconfirmedText')}</Col>
                        {_renderBtnJson()}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={24}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.registeredPerson'),
                  auditLogCreated?.username,
                  12,
                  false,
                  24,
                  '#69696A'
                )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.createdAtLabel'),
                  auditLogCreated?.createdAt ?
                    moment(auditLogCreated?.createdAt).format('YYYY/MM/DD') : '',
                  12,
                  false,
                  24,
                  '#69696A'
                )}
              </Row>
              <Row gutter={24}>
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.changer'),
                  auditLogUpdated?.username ? auditLogUpdated?.username : auditLogCreated?.username,
                  12,
                  false,
                  24,
                  '#69696A'
                )}
                {_renderSerialDetail(
                  t('serialNoScreen.individualDetail.updatedAtLabel'),
                  getUpdatedAt(),
                  12,
                  false,
                  24,
                  '#69696A'
                )}
              </Row>
              <p className='label-w-bold'>{t('serialNoScreen.individualDetail.moreInfo')}</p>
              <Row gutter={24}>{_renderQrCodeInfo()}</Row>
              <div className='group-btn-action'>
                <VButton
                  title={t('serialNoScreen.individualDetail.btnEditQr')}
                  onClick={handleOnClickEditQrCode}
                  style={styleBtnEdit}
                  type='text'
                />
                <VButton
                  title={t('serialNoScreen.individualDetail.btnDeleteQr')}
                  onClick={handleOnClickDeleteQrCode}
                  style={styleBtnDelete}
                  type='text'
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NotFoundComponent />
      )}
      <>
        {modal?.isVisible ? (
          <ModalComponent
            img={modal?.img}
            isVisible={modal?.isVisible}
            content={modal?.content}
            component={modal?.component}
            btnSubmit={modal?.btnSubmit}
            btnCancel={modal?.btnCancel}
            onCancelModal={handleOnCancelModal}
            onSubmitModal={handleOnSubmitModal}
            hideBtnFooter={modal?.hideBtnFooter}
          />
        ) : null}
        {modalPrevent?.isVisible ? (
          <ModalEnvironment
            isVisible={modalPrevent?.isVisible}
            btnClose={modalPrevent.btnClose}
            content={modalPrevent.content}
            onCloseModal={() => setModalPrevent({ ...modalPrevent, isVisible: false })}
          />
        ) : null}
      </>
    </>
  )
}
const styleBtnEdit = {
  height: 38,
  width: 74,
  backgroundColor: '#d7000f',
  color: '#fff',
  borderColor: ' #d7000f',
  marginRight: 16
}
const styleBtnDelete = {
  height: 38,
  width: 74,
  backgroundColor: '#fff',
  color: ' #d7000f',
  border: 'solid 1px #e3242b'
}

export default CreateIndividualSerialDetail
