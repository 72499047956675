import { useTranslation } from 'react-i18next'
import { Row, Col, Form } from 'antd'
import { useState, useEffect } from 'react'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import 'styles/Sensor.scss'
import { defaultTypeListSensor, defaultValueAll } from '../defaultValue'
import InputComponent from 'components/common/input/InputComponent'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'
import SelectComponent from '../../../../components/common/select/SelectComponent'
import GroupButtonActionSearchComponent from 'components/common/button/GroupButtonActionSearchComponent'
import { IDataListTableSensorOfOutages } from 'interfaces/sensor/list/ISensorList'
import { columnsSensorOfOutages } from './defaultColumn'
import { convertDataListSensorStop } from '../convertData'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import { getSensorListAction, searchListSensorAction } from 'redux/actions/sensor/sensorAction'
import { useDispatch } from 'react-redux'
import TableComponent from 'components/common/table/TableComponent'
import { showTotalRecord } from 'utils/Functions'
import { getListSensorAction } from 'redux/actions/serialNo/serialNoAction'
import { ISensorPullDownType, ISensorTranslate } from 'interfaces/ISerialNo'
import Collapsed from 'views/pages/serialNo/serialNoManagement/components/collapsed'

const getData = (current: number, pageSize: number, data: IDataListTableSensorOfOutages[]) => {
  if (data) {
    return data.slice((current - 1) * pageSize, current * pageSize)
  }
}

function SensorOfOutagesComponent() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  // check search
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const dispatch = useDispatch()
  // state pagination
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  // list data
  const [dataTable, setDataTable] = useState<IDataListTableSensorOfOutages[]>([])
  const [deviceId, setDeviceID] = useState<string>()
  const [groupName, setGroupName] = useState<string>()
  const [customerName, setCustomerName] = useState<string>()
  const [masterData, setMasterData] = useState<IDataListTableSensorOfOutages[]>([])
  // state select option type sensor
  const [sensorType, setSensorType] = useState<string>(defaultValueAll)
  const handleOnChangeSelectSensorType = (valueSensorType: string) => {
    setSensorType(valueSensorType)
  }
  // state select option LteOrLora sensor
  const [lteOrLora, setLteOrLora] = useState<any>(defaultValueAll)
  const [listLteOrLora, setListLteOrLora] = useState<any>([])
  const [listSensorTranslateType, setListSensorTranslateType] = useState<any>([])
  const handleOnChangeSelectLteOrLora = (valueLteOrLora: string) => {
    setLteOrLora(valueLteOrLora)
    setSensorType(defaultValueAll)
  }

  const handleOnChangePage = (pageChange: number) => {
    setCurrent(pageChange)
  }
  const handleOnChangePageSize = (size: number) => {
    const totalPageChange = Math.ceil(dataTable?.length / size)
    if (current > totalPageChange) {
      setCurrent(totalPageChange)
    }
    setPageSize(size)
  }
  const handleGetListSensor = () => {
    const params = {
      type: lteOrLora === defaultValueAll || lteOrLora === 'LoRa' ? 'LoRa' : ''
    }
    dispatch(
      getListSensorAction(
        { ...params },
        async (data) => {
          if (data && data?.result) {
            // dispatch(SetLoadingAction(false))
            const resultCommsType: any = []
            const resultTypes: any = []
            data.result.sensorTypes = await data.result.sensorTypes.map(
              (
                { sensorTypeId, sensorTypeKey }: ISensorPullDownType // eslint-disable-next-line
              ) => {
                resultCommsType.push({
                  id: sensorTypeId,
                  name: sensorTypeKey,
                  value: sensorTypeKey
                })
              }
            )
            if (lteOrLora === 'LoRa' || lteOrLora === defaultValueAll) {
              data.result.sensorTranslate = await data.result.sensorTranslate.slice(0, 3).map(
                (
                  { sensorTypeTranslateId, sensorTypeName, sensorTypeKey }: ISensorTranslate // eslint-disable-next-line
                ) => {
                  resultTypes.push({
                    id: sensorTypeTranslateId,
                    name: sensorTypeName,
                    value: sensorTypeKey
                  })
                }
              )
            } else {
              data.result.sensorTranslate = await data.result.sensorTranslate.slice(0, 2).map(
                (
                  { sensorTypeTranslateId, sensorTypeName, sensorTypeKey }: ISensorTranslate // eslint-disable-next-line
                ) => {
                  resultTypes.push({
                    id: sensorTypeTranslateId,
                    name: sensorTypeName,
                    value: sensorTypeKey
                  })
                }
              )
            }
            setListLteOrLora(resultCommsType)
            setListSensorTranslateType(resultTypes)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        false
      )
    )
  }
  useEffect(() => {
    handleGetListSensor()
    // eslint-disable-next-line
  }, [lteOrLora])

  // search
  const handleClickBtnSearch = () => {
    if (
      deviceId === undefined &&
      sensorType === '' &&
      lteOrLora === '' &&
      customerName === undefined &&
      groupName === undefined
    ) {
      return 0
    } else {
      const param = {
        deviceId: form.getFieldValue('deviceId'),
        customerName: form.getFieldValue('customerName'),
        groupName: form.getFieldValue('groupName'),
        sensorTypeKey: sensorType === defaultValueAll ? '' : sensorType,
        parentSensorTypeKey: lteOrLora === defaultValueAll ? '' : lteOrLora,
        type: defaultTypeListSensor.stop,
        sortBy: {
          target: 'timeSensorInactive',
          order: 'DESC'
        }
      }
      dispatch(
        searchListSensorAction(true, { ...param }, (data) => {
          if (data?.result) {
            const dataSensorConvert = convertDataListSensorStop(data?.result)
            setDataTable(dataSensorConvert)
            setDeviceID(undefined)
            setGroupName(undefined)
            setCustomerName(undefined)
            setCurrent(1)
            setIsSearch(true)
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        })
      )
    }
  }
  const defaultDropdown = () => {
    setSensorType(defaultValueAll)
    setLteOrLora(defaultValueAll)
    setCurrent(1)
    setDataTable(masterData)
  }
  const handleClickBtnCancel = () => {
    form.resetFields()
    setIsSearch(false)
    setDeviceID(undefined)
    setCustomerName(undefined)
    setGroupName(undefined)
    defaultDropdown()
  }
  useEffect(() => {
    dispatch(SetLoadingAction(true))
    const param = {
      sortBy: {
        target: 'timeSensorInactive',
        order: 'DESC'
      },
      type: defaultTypeListSensor.stop
    }
    dispatch(SetLoadingAction(true))
    dispatch(
      getSensorListAction(
        { ...param },
        (data) => {
          if (data?.result) {
            const convertListDataSensor = convertDataListSensorStop(data?.result)
            setDataTable(convertListDataSensor)
            setMasterData(convertListDataSensor)
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
            dispatch(SetLoadingAction(false))
          }
        },
        false
      )
    )
  }, [dispatch])

  const onValuesChange = (value: any) => {
    if (value?.deviceId) {
      form.setFieldsValue({ deviceId: value?.deviceId?.split(' ').join('') })
    }
  }
  return (
    <>
      <Collapsed>
        <div className=''>
          <div className='outages-management'>
            <div className='ss-drop-drown'>
              <Form
                form={form}
                onFinish={handleClickBtnSearch}
                autoComplete='off'
                onValuesChange={onValuesChange}>
                <Row>
                  <Col span={12} className='pr-7x'>
                    <InputComponent
                      name='deviceId'
                      type='text'
                      label={t('sensorManagementScreen.placeholder')}
                      maxLength={64}
                    />
                  </Col>
                  <Col span={12} className='pl-7x'>
                    <InputComponent
                      name='customerName'
                      type='text'
                      label={t('sensorManagementScreen.customerName')}
                      maxLength={64}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} className='pr-7x'>
                    <SelectComponent
                      lable={t('sensorManagementScreen.lteOrLora')}
                      defaultOption={lteOrLora}
                      dataOption={listLteOrLora}
                      onChangeSelect={handleOnChangeSelectLteOrLora}
                    />
                  </Col>
                  <Col span={12} className='pl-7x'>
                    <InputComponent
                      name='groupName'
                      type='text'
                      label={t('sensorManagementScreen.groupName')}
                      maxLength={64}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} className='pr-7x'>
                    <SelectComponent
                      lable={t('sensorManagementScreen.sensorType')}
                      defaultOption={sensorType}
                      dataOption={listSensorTranslateType}
                      onChangeSelect={handleOnChangeSelectSensorType}
                    />
                  </Col>
                  <Col span={12} className='pl-7x' />
                </Row>
                <div className='action text-align-right'>
                  <GroupButtonActionSearchComponent
                    onClickBtnSubmit={handleClickBtnSearch}
                    onClickBtncancel={handleClickBtnCancel}
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Collapsed>
      <div className='bgrffffff'>
        <div className='outages-management pd_16x_0x'>
          <div className='outages-management-table'>
            <div className='mg_0_16x'>
              <div className='d-flex justify-content-flex-end align-items-center mg-pagination'>
                <div className='d-flex justify-content-flex-end font-w-bold pd_16x_0x total-record'>
                  {showTotalRecord(dataTable?.length)}
                </div>
                <div className='outages-management-pagination'>
                  <PaginationComponent
                    onChangePageSize={(size) => handleOnChangePageSize(size)}
                    total={dataTable?.length}
                    onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                    pageSize={pageSize}
                    isShowSizeChanger={false}
                    currentpage={current}
                  />
                </div>
              </div>
            </div>
            <div className='custom-table'>
              <TableComponent
                columns={columnsSensorOfOutages}
                dataSource={getData(current, pageSize, dataTable)}
                isSearch={isSearch}
              />
            </div>
            <div className='mg_0_16x'>
              <div className='mg-pagination'>
                <PaginationComponent
                  onChangePageSize={(size) => handleOnChangePageSize(size)}
                  total={dataTable?.length}
                  onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                  pageSize={pageSize}
                  isShowSizeChanger={true}
                  currentpage={current}
                />
              </div>
            </div>
            <div className='btn-return text-align-center'>
              <ButtonReturnComponent />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SensorOfOutagesComponent
