import VButton from 'components/button/VButton'
import { useTranslation } from 'react-i18next'
interface IPropsGroupButton {
  onClickBtnSubmit?: () => void
  onClickBtncancel?: () => void
  isDisabledBtnSubmit?: boolean
}
function GroupButtonActionSearchComponent({
  onClickBtnSubmit,
  onClickBtncancel,
  isDisabledBtnSubmit
}: IPropsGroupButton) {
  const { t } = useTranslation()
  return (
    <div className='margin-group-button'>
      <VButton
        onClick={onClickBtnSubmit}
        title={t('sensorManagementScreen.btnSubmit')}
        type='text'
        style={styleBtnSubmit}
        disabled={isDisabledBtnSubmit}
      />
      <VButton
        onClick={onClickBtncancel}
        title={t('sensorManagementScreen.btnCancel')}
        type='text'
        style={styleBtnCancel}
      />
    </div>
  )
}
const styleBtnSubmit = {
  height: 38,
  width: 76,
  backgroundColor: '#d7000f',
  color: '#fff',
  borderColor: ' #d7000f',
  marginRight: 16
}
const styleBtnCancel = {
  height: 38,
  width: 116,
  backgroundColor: '#fff',
  color: ' #d7000f',
  border: 'solid 1px #e3242b'
}
export default GroupButtonActionSearchComponent
