import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { DELAY } from 'interfaces/enums/EDelay'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, call, delay, put, takeLatest } from 'redux-saga/effects'
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { ESerialNoAction } from 'redux/actions/serialNo/ESerialNoAction'
import {
  ICreateIndividualSerialNoAction,
  ICreateBulkSerialNoAction,
  IGetListKatashikiAction,
  IGetListSensorAction,
  IGetListSerialNoAction,
  ISearchSerialNoAction,
  IGetSerialNoDetailAction,
  IDeleteSerialNoAction,
  IChangeSerialNoConfirmStatusAction,
  IUpdateSerialNoAction,
  IGetBulkSerialNoDetailAction,
  IUpdateBulkSerialNoAction,
  IDownLoadJsonAction,
  IGetListSerialNoForOrderAction,
  ImportCsvFileAction
} from 'redux/actions/serialNo/serialNoTypes'
import { SerialNoApi } from 'services/api/serialNo/serialNoAPI'

function* getListKatashikiSaga(action: IGetListKatashikiAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SerialNoApi.getListKatashiki,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
  }
}
function* getListSensorSaga(action: IGetListSensorAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(SerialNoApi.getListSensor, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* createIndividualSerialNoSaga(action: ICreateIndividualSerialNoAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SerialNoApi.createIndividual,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 400) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}

function* createBulkSerialNoSaga(action: ICreateBulkSerialNoAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(SerialNoApi.createBulk, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

function* getSerialNoDetailSaga(action: IGetSerialNoDetailAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(SerialNoApi.serialDetail, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* getBulkSerialNoDetailSaga(action: IGetBulkSerialNoDetailAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SerialNoApi.bulkSerialDetail,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* deleteSerialNoSaga(action: IDeleteSerialNoAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SerialNoApi.deleteSerialNo,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}
function* changeConfirmStatusSaga(action: IChangeSerialNoConfirmStatusAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SerialNoApi.changeConfirmStatus,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

function* getListSerialNoSaga(action: IGetListSerialNoAction) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SerialNoApi.getSerialNoDropdown,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
  }
}

function* searchListSerialNo(action: ISearchSerialNoAction) {  
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SerialNoApi.getListSerialNo,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}
function* updateSerialNoSaga(action: IUpdateSerialNoAction) {
  let err: any = null
  yield put(SetLoadingAction(true))
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SerialNoApi.updateSerialNo,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 400) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}
function* updateBulkSerialNoSaga(action: IUpdateBulkSerialNoAction) {
  let err: any = null
  yield put(SetLoadingAction(true))
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SerialNoApi.updateBulkSerialNo,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}
function* downloadJsonSaga(action: IDownLoadJsonAction) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(SerialNoApi.downloadJson, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}
function* getSerialForORderSaga(action: IGetListSerialNoForOrderAction) {
  yield put(SetLoadingAction(true))
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SerialNoApi.getListForOrder,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}

function* importCsvFile(action: ImportCsvFileAction) {
  yield put(SetLoadingAction(true))
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(SerialNoApi.importCsvFile, action.data)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}

export default function* serialNoSaga() {
  yield all([
    takeLatest(ESerialNoAction.CREATE_INDIVIDUAL, createIndividualSerialNoSaga),
    takeLatest(ESerialNoAction.CREATE_BULK, createBulkSerialNoSaga),
    takeLatest(ESerialNoAction.LIST_KATASHIKI, getListKatashikiSaga),
    takeLatest(ESerialNoAction.LIST_SENSOR, getListSensorSaga),
    takeLatest(ESerialNoAction.LIST_SERIAL_NO, getListSerialNoSaga),
    takeLatest(ESerialNoAction.SEARCH_SERIAL_NO, searchListSerialNo),
    takeLatest(ESerialNoAction.SERIAL_NO_DETAIL, getSerialNoDetailSaga),
    takeLatest(ESerialNoAction.BULK_SERIAL_NO_DETAIL, getBulkSerialNoDetailSaga),
    takeLatest(ESerialNoAction.DELETE_SERIAL_NO, deleteSerialNoSaga),
    takeLatest(ESerialNoAction.CONFIRM_STATUS, changeConfirmStatusSaga),
    takeLatest(ESerialNoAction.UPDATE_SERIAL_NO, updateSerialNoSaga),
    takeLatest(ESerialNoAction.UPDATE_BULK_SERIAL_NO, updateBulkSerialNoSaga),
    takeLatest(ESerialNoAction.DOWNLOAD_JSON, downloadJsonSaga),
    takeLatest(ESerialNoAction.SERIAL_NO_FOR_ORDER, getSerialForORderSaga),
    takeLatest(ESerialNoAction.IMPORT_CSV, importCsvFile)
  ])
}
