import { AES, enc } from 'crypto-js'

export enum ERoles {
  ADMIN = 'Admin',
  DEVELOPER = 'Developer',
  USER = 'user',
  MASTER = 'master',
  ADMIN_CRUD = 'admin_crud',
  ADMIN_VIEW = 'admin_view',
  SECRET_KEY = '6D6968617261732D61646D696E4032303231'
}
export const ROLE_CRUD: string[] = [ERoles.ADMIN_CRUD, ERoles.MASTER]
export const ROLE_ALL: string[] = [ERoles.ADMIN_CRUD, ERoles.MASTER, ERoles.ADMIN_VIEW]
export const originalRole = (role: any) => {
  const bytes = AES.decrypt(role, ERoles.SECRET_KEY)
  const originalText = bytes.toString(enc.Utf8)
  return originalText
}
