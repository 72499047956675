import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { DELAY } from 'interfaces/enums/EDelay'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, put, call, takeLatest, delay } from 'redux-saga/effects'
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { ESensorActions } from 'redux/actions/sensor/ESensorAction'
import {
  ICheckFormulaByDeviceIdAction,
  IListEventBySensorAction,
  IListSensorAction,
  IListTypeSensorAction,
  IListUserBySensorAction,
  ISearchDeviceIdSensorAction,
  ISearchSensorAction,
  ISearchListSensorAction,
  ISensorDetailAction,
  IUpdateSensorAction
} from 'redux/actions/sensor/sensorTypes'
import { SensorApi } from 'services/api/sensor/sensorApi'

function* getSensorsListSaga(action: IListSensorAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(SensorApi.getListSensor, action.param)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      action.callBack(err)
    }
  }
}

function* sensorDetailSaga(action: ISensorDetailAction) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(SensorApi.sensorDetail, action.param)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      action.callBack(err)
    }
  }
}

function* getListUserBySensor(action: IListUserBySensorAction) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SensorApi.getListUserBySensor,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      action.callBack(err)
    }
  }
}
function* getListEventBySensor(action: IListEventBySensorAction) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SensorApi.getListEventBySensor,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      action.callBack(err)
    }
  }
}
function* updateSensorSaga(action: IUpdateSensorAction) {
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SensorApi.updateTimeTrackingBySensor,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* searchDeviceIdSensor(action: ISearchDeviceIdSensorAction) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SensorApi.searchDeviceIdSensor,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* searchSensor(action: ISearchSensorAction) {
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(SensorApi.searchSensor, action.param)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* searchListSensor(action: ISearchListSensorAction) {
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(SensorApi.searchListSensor, action.param)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

function* getListTypeSensorSaga(action: IListTypeSensorAction) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SensorApi.getListTypeSensorSensor,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      action.callBack(err)
    }
  }
}

function* checkFormulaByDeviceIdSaga(action: ICheckFormulaByDeviceIdAction) {
  if (action?.isLoading) {
    yield put(SetLoadingAction(true))
  }
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      SensorApi.checkFormulaByDeviceId,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if ((err && err?.code !== 400) || (err && err?.code !== 401)) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      action.callBack(err)
    }
  }
}
export default function* sensorsSaga() {
  yield all([
    takeLatest(ESensorActions.SENSOR_LIST, getSensorsListSaga),
    takeLatest(ESensorActions.SENSOR_DETAIL, sensorDetailSaga),
    takeLatest(ESensorActions.GET_LIST_USER_BY_SENSOR, getListUserBySensor),
    takeLatest(ESensorActions.ADMIN_EDIT_SENSOR, updateSensorSaga),
    takeLatest(ESensorActions.SEARCH_DEVICE_ID_SENSOR, searchDeviceIdSensor),
    takeLatest(ESensorActions.SEARCH_SENSOR, searchSensor),
    takeLatest(ESensorActions.SEARCH_LIST_SENSOR, searchListSensor),
    takeLatest(ESensorActions.GET_LIST_TYPE_SENSOR, getListTypeSensorSaga),
    takeLatest(ESensorActions.GET_LIST_EVENT_BY_SENSOR, getListEventBySensor),
    takeLatest(ESensorActions.CHECK_BY_DEVICE_ID, checkFormulaByDeviceIdSaga)
  ])
}
