import Modal from 'antd/lib/modal/Modal'
import VButton from 'components/button/VButton'

interface IModal {
  isVisible: boolean
  title?: string | ''
  content?: string | ''
  btnClose: string
  onCloseModal?: () => void
  contentStyle?: any
}
function ModalUnAuthentication({
  isVisible,
  title,
  content,
  btnClose,
  onCloseModal,
  contentStyle
}: IModal) {
  return (
    <Modal
      visible={isVisible}
      centered={true}
      footer={null}
      maskClosable={false}
      onCancel={onCloseModal}
      closable={false}
      zIndex={9999}>
      <div className='modal-content text-align-center' style={{ paddingBottom: 16 }}>
        <div className='font-w-bold' style={{ marginBottom: 15, fontWeight: 'bold' }}>
          {title}
        </div>
        <span style={{whiteSpace:'pre-wrap',...contentStyle}}>{content}</span>
      </div>
      <div className='text-align-center' style={{ marginTop: 12, alignSelf: 'center' }}>
        <VButton title={btnClose} style={styleBtnClose} onClick={onCloseModal} type='text' />
      </div>
    </Modal>
  )
}
const styleBtnClose = {
  width: 116,
  height: 35,
  borderRadius: 4,
  marginLeft: 10,
  color: '#ffffff'
}
export default ModalUnAuthentication
