import {
  ICheckFormulaByDeviceId,
  IListEventBySensor,
  IListSensor,
  IListUserBySensor,
  IMasterDataTypeSensor,
  ISearchDeviceIdSensor,
  ISearchSensor,
  ISearchListSensor,
  ISensorDetail,
  IUpdateSensor
} from 'services/params/sensor/ISensorParam'
import {
  gemSecurityKey,
  getListEmailSensorRequest,
  getListEventBySensor,
  getListSensorRequest,
  getListTypeSensorRequest,
  getListUserBySensor,
  searchDevieceIdRequest,
  searchSensorRequest,
  searchListSensorRequest,
  sensorDetailRequest,
  updateSensorRequest,
  checkFormulaByDeviceId
} from 'services/requests/sensor/sensorRequest'
import { configRequest } from '..'

class SensorApis {
  getListSensor = (param: IListSensor) => {
    const requests = getListSensorRequest(param)
    return configRequest(requests)
  }
  getListEmailSensor = () => {
    const requests = getListEmailSensorRequest()
    return configRequest(requests)
  }
  sensorDetail = (param: ISensorDetail) => {
    const requests = sensorDetailRequest(param)
    return configRequest(requests)
  }
  getListUserBySensor = (param: IListUserBySensor) => {
    const requests = getListUserBySensor(param)
    return configRequest(requests)
  }
  getListEventBySensor = (param: IListEventBySensor) => {
    const requests = getListEventBySensor(param)
    return configRequest(requests)
  }
  updateTimeTrackingBySensor = (param: IUpdateSensor) => {
    const requests = updateSensorRequest(param)
    return configRequest(requests)
  }
  searchDeviceIdSensor = (param: ISearchDeviceIdSensor) => {
    const requests = searchDevieceIdRequest(param)
    return configRequest(requests)
  }
  searchSensor = (param: ISearchSensor) => {
    const requests = searchSensorRequest(param)
    return configRequest(requests)
  }
  searchListSensor = (param: ISearchListSensor) => {
    const requests = searchListSensorRequest(param)
    return configRequest(requests)
  }
  getListTypeSensorSensor = (param?: IMasterDataTypeSensor) => {
    const requests = getListTypeSensorRequest(param)
    return configRequest(requests)
  }
  checkFormulaByDeviceId = (param: ICheckFormulaByDeviceId) => {
    const requests = checkFormulaByDeviceId(param)
    return configRequest(requests)
  }
  genSecurity = (serial: string) => {
    const requests = gemSecurityKey(serial)
    return configRequest(requests)
  }
}

export const SensorApi = new SensorApis()
