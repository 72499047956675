import { NotFound } from 'assets'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducers'

function NotFoundComponent() {
  const accessToken = useSelector((state: AppState) => state?.auth?.accessToken)
  return (
    <>
      <div>
        <img style={accessToken ? styleImgLogin : styleImgNoLogin} src={NotFound} alt='not-found' />
      </div>
    </>
  )
}
const styleImgNoLogin = {
  width: '100%',
  height: '200px',
  marginTop: 100
}
const styleImgLogin = {
  width: '100%',
  height: '200px',
  marginTop: 100
}

export default NotFoundComponent
