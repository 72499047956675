import { originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducers'
import { defaultOperaStatus } from '../../defaultValue'
import ItemSensorDetail from '../ItemSensorDetail'

interface ISensorTypeLora {
  sensorDetailData: any
  onClickBtnJson?: () => void
  onClickBtnEdit?: () => void
  onClickBtnStopSensor?: () => void
  onClickBtnActiveSensor?: () => void
}
function ShowSenserTypeLora({
  sensorDetailData,
  onClickBtnJson,
  onClickBtnEdit,
  onClickBtnStopSensor,
  onClickBtnActiveSensor
}: ISensorTypeLora) {
  const { t } = useTranslation()
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  return (
    <div>
      <div className='show-sensor-detail'>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.deviceId')}
            value={sensorDetailData?.deviceId ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.oldDeviceId')}
            value={sensorDetailData?.oldDeviceId ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.sensorType')}
            value={sensorDetailData?.sensorType ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.lteOrLora')}
            value={sensorDetailData?.lteOrLora}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.sensorLteOrLora')}
            value={sensorDetailData?.serialNumber ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.sensorId')}
            value={sensorDetailData?.sensorId ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.securityKey')}
            value={sensorDetailData?.securityKey ?? ''}
          />
        </div>

        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.gps')}
            value={sensorDetailData?.gps ?? ''}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.lastData')}
            value={sensorDetailData?.lastData ?? ''}
            isButton={true}
            btnName={t('sensorDetail.btn.btnJson')}
            width={'81px'}
            onClickButton={onClickBtnJson}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.battery')}
            value={sensorDetailData?.battery ?? ''}
            isConvertDisplay={true}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.communicationErr')}
            value={sensorDetailData?.timeTracking ?? ''}
            text={sensorDetailData?.timeTracking ? t('modal.minutes') : ''}
            isButton={ROLE_CRUD.includes(role) ? true : false}
            btnName={t('sensorDetail.btn.btnEdit')}
            width={'48px'}
            onClickButton={onClickBtnEdit}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.communicationStt')}
            value={sensorDetailData?.communicationStt ?? ''}
            isConvertDisplay={true}
          />
        </div>
        <div className='item-show'>
          <ItemSensorDetail
            label={t('sensorDetail.label.operaStt')}
            value={
              sensorDetailData?.operaStt &&
              sensorDetailData?.operaStt === defaultOperaStatus?.active
                ? t('sensorDetail.active')
                : t('sensorDetail.stop')
            }
            isButton={ROLE_CRUD.includes(role) ? true : false}
            btnName={
              sensorDetailData?.operaStt === defaultOperaStatus?.active
                ? t('sensorDetail.btn.btnStop')
                : t('sensorDetail.btn.btnResurrection')
            }
            width={'76px'}
            onClickButton={
              sensorDetailData?.operaStt === defaultOperaStatus?.active
                ? onClickBtnStopSensor
                : onClickBtnActiveSensor
            }
          />
        </div>
      </div>
    </div>
  )
}
export default ShowSenserTypeLora
