const initialFormulaCopy = {
  id: '',
  type: '',
  timeApply: '',
  sensorType: '',
  parentType: '',
  email: '',
  sensorName: '',
  deviceId: '',
  description: '',
  listAttribute: [],
  listAttributeAdd: []
}
export { initialFormulaCopy }
