import {
  IKatashiKiParamsGetList,
  IKatashikiParamUpdateItem,
  IKatashikiParamCreateItem
} from 'services/params/katashiki/IKatashikiParams'
import { EKatashikiActions } from './EKatashikiAction'
import { IKatashikiSearchType } from './katashikiTypes'

export const listKatashikiSearch = (
  isLoading: boolean,
  param: IKatashiKiParamsGetList,
  callBack: (data: any) => void
): IKatashikiSearchType => ({
  type: EKatashikiActions.SEARCH,
  isLoading,
  callBack,
  param
})

export const listKatashikiGetKeyword = (callBack: (data: any) => void): any => ({
  type: EKatashikiActions.GET_KEY_WORDS,
  callBack
})

export const listKatashikiGetSensorType = (callBack: (data: any) => void): any => ({
  type: EKatashikiActions.GET_SENSOR_TYPE,
  callBack
})

export const listKatashikiRemove = (param: number, callBack: (data: any) => void): any => ({
  type: EKatashikiActions.REMOVE_ITEM,
  param,
  callBack
})

export const listKatashikiUpdate = (
  param: IKatashikiParamUpdateItem,
  callBack: (data: any) => void
): any => ({
  type: EKatashikiActions.UPDATE_ITEM,
  param,
  callBack
})

export const createKatashiki = (
  param: IKatashikiParamCreateItem,
  callBack: (data: any) => void
): any => ({
  type: EKatashikiActions.CREATE_ITEM,
  param,
  callBack
})
