const headerCsvUser = [
  {
    label: 'No',
    key: 'id'
  },
  {
    label: '型式',
    key: 'model'
  },
  {
    label: '製品名',
    key: 'productName'
  },
  {
    label: '通信種別',
    key: 'communicationType'
  },
  {
    label: '機器種別',
    key: 'deviceType'
  },
  {
    label: '管理記号',
    key: 'managementSymbol'
  },
  {
    label: '型式登録日',
    key: 'modelRegistraionDate'
  },
  {
    label: '備考',
    key: 'remarks'
  }
]

const headersCSVListSerialNo = [
  {
    label: '製造番号',
    key: 'serialNoNumber'
  },
  {
    label: '型式',
    key: 'model'
  },
  {
    label: '製造年月',
    key: 'createdAt'
  },
  {
    label: 'デバイスID',
    key: 'deviceId'
  },
  {
    label: '通信種別',
    key: 'lineType'
  },
  {
    label: '機器種別',
    key: 'sensorType'
  },
  {
    label: 'セキュリティキー',
    key: 'securityKey'
  },
  {
    label: 'ＩＭＥＩ番号',
    key: 'IMEINumber'
  },
  {
    label: '旧製造番号',
    key: 'serialOldNumber'
  },
  {
    label: '無線CH',
    key: 'channel'
  },
  {
    label: '製造工場',
    key: 'productSite'
  },
  {
    label: '疎通確認ステータス',
    key: 'confirmed'
  },
  {
    label: '顧客名',
    key: 'customerName'
  },
  {
    label: '登録日',
    key: 'regDate'
  },
  {
    label: '更新日',
    key: 'updatedDate'
  },
  {
    label: '備考',
    key: 'note'
  }
]

const headersCSVListKatashiki = [
  {
    label: '型式',
    key: 'model'
  },
  {
    label: '製品名',
    key: 'productName'
  },
  {
    label: '通信種別',
    key: 'sensorTypeKey'
  },
  {
    label: '機器種別',
    key: 'sensorTypeName'
  },
  {
    label: '管理記号',
    key: 'managementSymbol'
  },
  {
    label: '型式登録日',
    key: 'createdAt'
  },
  {
    label: '備考',
    key: 'remarks'
  }
]

export { headerCsvUser, headersCSVListSerialNo, headersCSVListKatashiki }
