const styleBtnSeletedSs = {
  minWidth: 74,
  height: 38,
  marginRight: 5,
  color: '#ffffff',
  marginTop: 15
}
const styleBtnSubmit = {
  width: 74,
  height: 38,
  marginRight: 10,
  color: '#ffffff',
  marginTop: 15
}
const styleBtnReturn = {
  width: 74,
  height: 38,
  color: '#d7000f',
  marginTop: 15,
  background: '#fff',
  borderRadius: 4,
  border: '1px solid #d7000f',
  marginLeft: 10
}
const styleBtnAdd = {
  width: 48,
  height: 28,
  color: '#fff',
  background: '#3eacff',
  borderRadius: 4,
  padding: 0
}
const styleBtnConfiguration = {
  width: 48,
  height: 28,
  color: '#fff',
  background: '#3eacff',
  borderRadius: 4,
  padding: 0,
  boxShadow: 'none'
}
// ============
const styleBtnSubmitEdit = {
  width: 74,
  height: 38,
  marginRight: 10,
  color: '#ffffff',
  marginTop: 15
}
const styleBtnReturnEdit = {
  width: 74,
  height: 38,
  color: '#d7000f',
  marginTop: 15,
  background: '#fff',
  borderRadius: 4,
  border: '1px solid #d7000f',
  marginLeft: 10
}
const styleBtnAddEdit = {
  width: 48,
  height: 28,
  color: '#fff',
  background: '#3eacff',
  borderRadius: 4,
  padding: 0
}
const styleBtnConfigurationEdit = {
  width: 48,
  height: 28,
  color: '#fff',
  background: '#3eacff',
  borderRadius: 4,
  padding: 0,
  boxShadow: 'none'
}

export {
  styleBtnSeletedSs,
  styleBtnSubmit,
  styleBtnReturn,
  styleBtnAdd,
  styleBtnConfiguration,
  styleBtnSubmitEdit,
  styleBtnReturnEdit,
  styleBtnAddEdit,
  styleBtnConfigurationEdit
}
