import i18n from 'configs/i18n'
import { getNameAttribute } from '../displayManagement/convertData'
import { dataKeyPrefixTv } from '../multiSettings/defaultValue'
import { keyFormulaDefault } from 'views/pages/multiSettings/convertData'
import { EBlank } from 'interfaces/enums/EBlank'

const convertAtt = (data: any) => {
  data = data?.filter((item: any) => item?.attributeKey !== '')
  const result = [...data]
  result.map((item) => {
    // eslint-disable-next-line
    item.id = item.id
    item.name =
      getNameAttribute(item?.attributeTranslate) === '-' && item?.dataKeyPrefix !== dataKeyPrefixTv
        ? item?.dataKeyPrefix +
          item?.attributeIndex.toString() +
          '　' +
          i18n.t('caculation.reserve')
        : item?.dataKeyPrefix +
          item?.attributeIndex.toString() +
          '　' +
          getNameAttribute(item?.attributeTranslate)
    item.value = item.attributeKey
    item.langJP =
      getNameAttribute(item?.attributeTranslate) === '-' && item?.dataKeyPrefix !== dataKeyPrefixTv
        ? i18n.t('caculation.reserve')
        : getNameAttribute(item?.attributeTranslate)
    return item
  })
  return result
}
const getIndexAtt = (list: any, key: string) => {
  const itemSelect = list?.find((itemList: any) => itemList?.attributeKey === key)
  const attItem = itemSelect?.dataKeyPrefix + itemSelect?.attributeIndex
  return attItem ? attItem : ''
}
const convertViewFormula = (listAttSelected: any, formula: string) => {
  if (formula === keyFormulaDefault) return EBlank.FORMULA
  let result = formula
    .replace(/_soil_temperature/g, getIndexAtt(listAttSelected, '_soil_temperature'))
    .replace(/_water_temperature/g, getIndexAtt(listAttSelected, '_water_temperature'))
    .replace(/_wind_speed_max/g, getIndexAtt(listAttSelected, '_wind_speed_max'))
    .replace(/_water_level/g, getIndexAtt(listAttSelected, '_water_level'))
    .replace(/_wind_direction/g, getIndexAtt(listAttSelected, '_wind_direction'))
    .replace(/_amount_rain/g, getIndexAtt(listAttSelected, '_amount_rain'))
    .replace(/_wind_speed/g, getIndexAtt(listAttSelected, '_wind_speed'))
    .replace(/_water/g, getIndexAtt(listAttSelected, '_water'))
    .replace(/_temperature/g, getIndexAtt(listAttSelected, '_temperature'))
    .replace(/_battery/g, getIndexAtt(listAttSelected, '_battery'))
    .replace(/_humidity/g, getIndexAtt(listAttSelected, '_humidity'))
    .replace(/_brightness/g, getIndexAtt(listAttSelected, '_brightness'))
    .replace(/_latitude/g, getIndexAtt(listAttSelected, '_latitude'))
    .replace(/_em_wave/g, getIndexAtt(listAttSelected, '_em_wave'))
    .replace(/_EC_value/g, getIndexAtt(listAttSelected, '_EC_value'))
    .replace(/_longitude/g, getIndexAtt(listAttSelected, '_longitude'))

  if (result.indexOf('*') !== -1) {
    result = result.replaceAll('*', 'x')
  }
  if (result.indexOf('/') !== -1) {
    result = result.replaceAll('/', '÷')
  }
  if (result.indexOf('pi') !== -1) {
    result = result.replaceAll('pi', 'π')
  }
  if (result.indexOf('root(') !== -1) {
    result = result.replaceAll('root(', '√(')
  }

  if (result.indexOf(':') !== -1) {
    result = result.replaceAll(':', '')
  }

  return result
}
const getNameAtt = (listAtt: any, key: string) => {
  const itemSelect = listAtt?.find((itemList: any) => itemList?.attributeKey === key)
  const name = getNameAttribute(itemSelect?.attributeTranslate)
  return name
}
export { convertAtt, convertViewFormula, getNameAtt }
