export const ValidationCSVMessage = {
    NOT_ENOUGH_16_COLUMNS: `CSVフォーマットが正しくありません。（項目数が不足しています）`,
    WRONG_COLUMN_NAME_OR_MORE_THAN_16_COLUMNS: `CSVフォーマットが正しくありません。（項目名が正しくありません）`,
    DUPLICATE_ROWS: (serial_no: any) => {
        return `製造番号${serial_no}が重複しています。CSVデータを直してください。`;
    },
    NOT_ALLOW_EDIT: (number: any) => {
        return `${number}セキュリテイキー、顧客名、登録日、更新日、デバイスID、製造番号、通信種別、機器種別、型式の編集ができません。`;
    },
    SERIAL_NOT_REGISTER: (serial_no: any) => {
        return `${serial_no} が登録されていません。CSVファイルから取り除き、\n再度インポートしてください。`;
    },
    IMEI_DUPLICATE: (amount_of_records: any) => {
        return `同じIMEI番号${amount_of_records}が複数レコードに登録されています。CSVデータを直してください。`;
    },
    MISSING_DATA: (line: any) => {
        return `${line}行目の必須項目にデータが入っておりません。`;
    },
    MORE_THAN_1000_RECORDS: `CSVフォーマットが正しくありません。（レコード数が1000件を超えています）`,
    INVALID_FILE_EXTENSION: `ファイル形式が正しくありません。`,
    INVALID_INPUT_DATA: `製造年月、製造工場、疎通確認ステータスに正しくないデータがあります。\n ファイルを確認・調整の上、再度インポートしてください。`,
    IMPORT_SUCCESS: `IMPORT_SUCCESS`
};
