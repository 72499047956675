import { Methods } from 'services/api/EMethod'
import { IGetAttributeParams } from 'services/params/atrtibutes/IGetAttributeParams'

export const getAttributes = (request: IGetAttributeParams) => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_ATTRIBUTES,
    params: request
  }
}
