import { IconMenu } from 'assets'
import ModalComponent from 'components/modal/ModalComponent'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classes from 'styles/VHeader.module.scss'
import { IColors } from 'utils/colors'
import { useHistory } from 'react-router-dom'
import { ADMIN_ROUTE, routerChangePass } from 'views/routes/routes'

type Props = {
  handleLogout: () => void
  theme: IColors
  handleChangeTheme: (theme: IColors) => void
}

const VHeader = ({ handleLogout, theme, handleChangeTheme }: Props) => {
  const history = useHistory()
  const [isHover, setIsHover] = useState(false);
  const [isHoverMenu, setIsHoverMenu] = useState(false);
  const onLogout = () => {
    setModal({
      ...modal,
      isVisible: true
    })
    setIsShowMenu(false)
  }
  const onChangePass = () => {
    history.push(ADMIN_ROUTE.concat(routerChangePass.CHANGE_PASS))
    setIsShowMenu(false)
  }
  const { t } = useTranslation()
  const [isShowmenu, setIsShowMenu] = useState<boolean>(false)
  // modal
  const [modal, setModal] = useState({
    isVisible: false,
    isComponent: false,
    img: '',
    content: t('logout.content'),
    btnSubmit: t('logout.yes'),
    btnCancel: t('logout.no')
  })
  const handleOnSubmitModal = () => {
    handleLogout()
  }
  const handleShowMenu = () => {
    return isHoverMenu ? !isShowmenu ? setIsShowMenu(true) : setIsShowMenu(false) : (!isHoverMenu && isHover) ? setIsShowMenu(false) : setIsShowMenu(false)
  }
  useEffect(() => {
    document.addEventListener("click", handleShowMenu)
  })
  return (
    <>
      <div className={classes.container}>
        <div className={`${classes.searchContainer} fs-16x`}>{t('miharasManagement')}</div>
        <div className='flex j-center ai-center' style={{ height: 60 }}>
          <span className={classes.username}>{'ようこそ　Adminさん'}</span>
          <img className='pointer'
            onMouseEnter={() => setIsHoverMenu(true)}
            onMouseLeave={() => setIsHoverMenu(false)}
            src={IconMenu} alt='Menu'
            style={isShowmenu ? isOpenMenu : isCloseMenu}
          />
          {isShowmenu ? (
            <div className={classes.dropdown}>
              <ul className={classes.menu}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
              >
                <li className={classes.menuItem} style={{ borderBottom: 'solid 1px #cdcccc83' }}>
                  <button onClick={onChangePass}>{t('menu.changePass')}</button>
                </li>
                <li className={classes.menuItem}>
                  <button onClick={onLogout}>{t('menu.logOut')}</button>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
      {modal?.isVisible ? (
        <ModalComponent
          isVisible={modal?.isVisible}
          btnSubmit={modal?.btnSubmit}
          btnCancel={modal?.btnCancel}
          content={modal?.content}
          onCancelModal={() => setModal({ ...modal, isVisible: false })}
          onSubmitModal={handleOnSubmitModal}
        />
      ) : null}
    </>
  )
}

const isOpenMenu = {
  height: 28,
  padding: 2,
  backgroundColor: '#e1dede'

}
const isCloseMenu = {
  height: 28,
  padding: 2,
  backgroundColor: '#ffffff'
}
export default VHeader
