export const ERROR_TYPE = {
  ERROR: 'ERROR',
  UNAUTHORIZED: 'UNAUTHORIZED',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  ACCESS_TOKEN_EXPIRED: 'ACCESS_TOKEN_EXPIRED',
  BAD_REQUEST: 'BAD_REQUEST',
  SOMETHING_WRONG: 'SOMETHING_WRONG',
  UNAUTHENTICATED: 401,
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  ERR_INTERNET_DISCONNECTED: 'ERR_INTERNET_DISCONNECTED',
  UNCAUGHT: 700,
  PHONE_EXISTS: 'PHONE_EXISTS',
  EMAIL_EXISTS: 'EMAIL_EXISTS',
  ACCESSDENIED: 403,
  NOT_FOUND: 404
}

export enum ErrorCodes {
  FRE00005 = 'FRE00005',
  MSE0002 = 'MSE0002'
}
