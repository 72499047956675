/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Button, Checkbox, Input, TableColumnsType, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import 'styles/Customer.scss'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'
import '../../../styles/katashiki.scss'
import { useHistory } from 'react-router-dom'
import VButton from 'components/button/VButton'
import { IconDeleteRed, IconEditBlue, IconWarning } from 'assets'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import React, { useEffect, useState } from 'react'
import ExportCsvComponent from 'components/common/exportCsv/ExportCsvComponent'
import { headersCSVListKatashiki } from './scv'
import { IKatashiKiParamsGetList } from 'services/params/katashiki/IKatashikiParams'
import { IKatashikiModel } from 'interfaces/katashiki/IKatashiki'
import moment from 'moment'
import ModalComponent from 'components/modal/ModalComponent'
import ModalCheckSuccessComponent from 'components/modal/ModalCheckSuccessComponent'
import { IconDeleteModal } from '../../../assets'
import {
  listKatashikiSearch,
  listKatashikiGetKeyword,
  listKatashikiGetSensorType,
  listKatashikiRemove,
  listKatashikiUpdate
} from 'redux/actions/katashiki/katashikiAction'
import { useDispatch } from 'react-redux'
import { INotification } from 'interfaces/INotification'
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { getServiceMessage } from 'utils/Functions'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import { PRODUCT_NAME_MAX_LENGTH } from 'utils/maxLength'
import { showTotalRecord } from 'utils/Functions'
import ModalEnvironment from 'components/modal/ModalEnvironment'
import TableComponent from 'components/common/table/TableComponent'
import Collapsed from '../serialNo/serialNoManagement/components/collapsed'

const { Option } = Select
const INVALID_ITEM_ID = -1
const ListKatashiki = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [csvData, setCsvData] = useState<any>([])
  const [dataTable, setDataTable] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecord, setTotalRecord] = useState<number>(0)
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [keywordData, setKeywordData] = useState<any>()
  const [editingItem, setEditingItem] = useState<any>()
  const [keywordSelected, setKeywordSelected] = useState<string>('')
  const [communicationTypeData, setCommunicationTypeData] = useState<any>()
  const [communicationSelected, setCommunicationSelected] = useState<any>([])
  const [deviceTypeData, setDeviceTypeData] = useState<any>()
  const [deviceSelected, setDeviceSelected] = useState<any>([])
  const [removePopup, setRemovePopup] = useState({
    isVisible: false,
    itemID: INVALID_ITEM_ID
  })
  const [msgPopup, setMsgPopup] = useState({
    isVisible: false,
    msg: ''
  })
  const [editParam, setEditParam] = useState({
    id: -1,
    model: '',
    productName: '',
    sensorTypeId: -1,
    sensorTypeTranslateId: -1,
    managementSymbol: '',
    remarks: ''
  })
  const [errorMsg, setErrorMsg] = useState({
    productNameError: false,
    remarkError: false
  })
  const [isShowMessDelErr, seIsShowMessDelErr] = useState(false)

  useEffect(() => {
    handleCallAPIGetList(
      keywordSelected,
      communicationSelected,
      deviceSelected,
      currentPage,
      pageSize
    )

    const handleCallApiGetKeyword = () => {
      dispatch(
        listKatashikiGetKeyword((resp) => {
          if (resp?.result) {
            const arr: any[] = []
            resp?.result.some((el: any) => {
              if (!el?.deletedAt) {
                arr.push(el)
              }
            })
            setKeywordData(arr)
          }
        })
      )
    }
    const handleCallApiGetSensorType = () => {
      dispatch(
        listKatashikiGetSensorType((resp) => {
          let sensorType = resp?.result?.sensorType
          // sensorType = sensorType?.sort(function (a: any, b: any) {
          //   return Number(a.sensorTypeId) - Number(b.sensorTypeId)
          // })
          getCommunicationTypes(sensorType)
          getDeviceType(resp?.result?.sensorTranslate)
        })
      )
    }
    handleCallApiGetKeyword()
    handleCallApiGetSensorType()
  }, [])

  useEffect(() => {
    handleGetAllDataToExportCSV()
  }, [dataTable])

  const handleGetAllDataToExportCSV = () => {
    setCsvData([])
    const param: IKatashiKiParamsGetList = {
      model: keywordSelected,
      sensorTypes: communicationSelected,
      sensorTypeTranslates: deviceSelected,
      pageNo: null,
      pageSize: null
    }
    setTimeout(() => {
      dispatch(
        listKatashikiSearch(false, param, (resp) => {
          if (resp?.result?.record.length) {
            const records = resp?.result?.record
            const list: any[] = []
            records?.some((el: any) => {
              const item = {
                model: el.model,
                productName: el.productName,
                sensorTypeKey: el.sensorType.sensorTypeKey,
                sensorTypeName: el.sensorTypeTranslate.sensorTypeName,
                managementSymbol: el.managementSymbol,
                createdAt: moment(el.createdAt).format('YYYY/MM/DD'),
                remarks: el.remarks
              }
              list.push(item)
            })
            setCsvData(list)
          }
        })
      )
    }, 2000)
  }

  const handleEditItem = (item: any) => {
    const notEditAnyItem = !editingItem
    const editingAnyItem = editingItem && editingItem === item

    if (notEditAnyItem) {
      handleStartEditItem(item)
    } else if (editingAnyItem) {
      handleEndEditItem()
    }
  }

  const handleStartEditItem = (item: any) => {
    setEditingItem(item)
    setEditParam({
      ...editParam,
      id: item.id,
      model: item.model,
      productName: item.productName,
      sensorTypeId: item.sensorTypeId,
      sensorTypeTranslateId: item.sensorTypeTranslateId,
      managementSymbol: item.managementSymbol,
      remarks: item.remarks
    })
  }

  const handleEndEditItem = () => {
    const productNameLengthInvalid = editParam.productName.replace(/ /g, '').length === 0
    if (!handleErrorInput(productNameLengthInvalid)) {
      const params = {
        id: editParam.id,
        model: editParam.model,
        productName: editParam.productName.replace(/ /g, ''),
        sensorTypeId: editParam.sensorTypeId,
        sensorTypeTranslateId: editParam.sensorTypeTranslateId,
        managementSymbol: editParam.managementSymbol,
        remarks: editParam.remarks.replace(/ /g, '')
      }

      dispatch(
        listKatashikiUpdate(params, (resp) => {
          if (resp?.result && !resp?.result?.Error) {
            setEditingItem(undefined)
            handleOnSearch()
            setMsgPopup({
              ...msgPopup,
              isVisible: true,
              msg: t('customerScreen.editSuccess')
            })
          } else {
            const errorCode = JSON.parse(resp?.result?.Error.message)
            const noti: INotification = {
              title: t('notification.error'),
              message: getServiceMessage(errorCode?.id[0]),
              code: resp?.result?.Error?.code
            }
            dispatch(SetNotificationAction(noti))
          }
        })
      )
    }
  }

  const handleErrorInput = (productNameInputError: boolean) => {
    let isError = false

    setErrorMsg({
      ...errorMsg,
      productNameError: productNameInputError
    })

    if (productNameInputError) {
      isError = true
    }

    return isError
  }

  const getCommunicationTypes = (sensorType: any[]) => {
    const communicationType: any[] = []
    sensorType.some((el: any) => {
      communicationType.push({
        value: parseInt(el?.sensorTypeId),
        label: el?.sensorTypeKey
      })
    })
    setCommunicationTypeData(communicationType)
  }

  const getDeviceType = (deviceTypes: any[]) => {
    const deviceTypeArr: any[] = []
    deviceTypes.some((el: any) => {
      deviceTypeArr.push({
        value: parseInt(el?.sensorTypeTranslateId),
        label: el?.sensorTypeName
      })
    })
    setDeviceTypeData(deviceTypeArr)
  }

  const handleCallAPIGetList = (
    model: any = null,
    sensorTypeId: any = null,
    sensorTypeTranslateId: any = null,
    pageNo: number | null = 1,
    pageSize: number | null = 10
  ) => {
    const param: IKatashiKiParamsGetList = {
      model: model,
      sensorTypes: sensorTypeId,
      sensorTypeTranslates: sensorTypeTranslateId,
      pageNo: pageNo,
      pageSize: pageSize
    }
    dispatch(
      listKatashikiSearch(true, param, (resp) => {
        if (resp?.result?.record.length) {
          const records = resp?.result?.record
          const katashikiArr: any[] = []
          records?.some((item: any) => {
            if (item?.deletedAt === null) {
              katashikiArr.push({
                ...item,
                key: item.id
              })
            }
          })
          setDataTable(katashikiArr)
          setTotalRecord(resp?.result?.totalRecord)
        } else {
          setDataTable([])
          setTotalRecord(0)
          dispatch(SetLoadingAction(false))
        }
      })
    )
  }

  const handleOnChangeProductName = (e: any) => {
    setErrorMsg({
      ...errorMsg,
      productNameError: false
    })
    setEditParam({
      ...editParam,
      productName: e.target.value
    })
  }

  const handleOnBlurProductName = () => {
    setEditParam({
      ...editParam,
      productName: editParam.productName.replace(/ /g, '')
    })
    if (editParam.productName.replace(/ /g, '').length === 0) {
      setErrorMsg({
        ...errorMsg,
        productNameError: true
      })
    }
  }

  const handleOnChangeRemarks = (e: any) => {
    setErrorMsg({
      ...errorMsg,
      remarkError: false
    })
    let valueRemarks
    if (e.target.value.length > 64) {
      valueRemarks = e.target.value.substring(0, 64)
    } else {
      valueRemarks = e.target.value
    }
    setEditParam({
      ...editParam,
      remarks: valueRemarks
    })
  }

  const handleOnBlurRemarks = () => {
    setEditParam({
      ...editParam,
      remarks: editParam.remarks.replace(/ /g, '').substring(0, 64)
    })
  }

  const handleOnRemoveClicked = (record: IKatashikiModel) => {
    setRemovePopup({
      ...removePopup,
      isVisible: true,
      itemID: record.id
    })
  }

  const handleGoCreateKatashiki = () => {
    history.push(ADMIN_ROUTE + routesName.KATASHIKI + '/create')
  }

  const handleOnSearch = () => {
    const sensorTypeId = communicationSelected
    const sensorTypeTranslate = deviceSelected
    const model = keywordSelected
    handleCallAPIGetList(model, sensorTypeId, sensorTypeTranslate, 1, pageSize)
    setCurrentPage(1)
    setIsSearch(true)
  }

  const handleOnKeywordChanged = (keyword: any) => {
    setKeywordSelected(keyword)
  }

  const handleCommunicationTypeOptionChanged = (item: any) => {
    if (isExist(item, communicationSelected)) {
      setCommunicationSelected(removeArrayItem(item, communicationSelected))
    } else {
      setCommunicationSelected((oldArr: []) => [...oldArr, item])
    }
  }

  const handleDeviceTypeOptionChanged = (item: any) => {
    if (isExist(item, deviceSelected)) {
      setDeviceSelected(removeArrayItem(item, deviceSelected))
    } else {
      setDeviceSelected((oldArr: []) => [...oldArr, item])
    }
  }

  const isExist = (item: any, array: []) => {
    const index = array.findIndex((el: any) => item === el)
    return index > -1
  }

  const removeArrayItem = (item: any, array: []) => {
    const arr = array.filter((el: any) => el !== item)
    return arr
  }

  const handleCancelRemovePopup = () => {
    hiddenRemovePopup()
  }

  const handleSubmitRemovePopup = () => {
    if (removePopup.itemID !== INVALID_ITEM_ID)
      dispatch(
        listKatashikiRemove(removePopup.itemID, (resp) => {
          if (!resp?.result?.Error) {
            updateListOnItemRemoved(removePopup.itemID)
            setMsgPopup({
              ...msgPopup,
              isVisible: true,
              msg: t('modal.message.removeSuccess')
            })
            hiddenRemovePopup()
          } else {
            seIsShowMessDelErr(true)
            hiddenRemovePopup()
          }
        })
      )
  }

  const updateListOnItemRemoved = (itemID: number) => {
    let cloneData = [...dataTable]
    cloneData = cloneData?.filter((item: any) => item?.key !== itemID)
    setDataTable(cloneData)
  }

  const hiddenRemovePopup = () => {
    setRemovePopup({
      ...removePopup,
      isVisible: false,
      itemID: INVALID_ITEM_ID
    })
  }

  const handleMsgPopupCancel = () => {
    setMsgPopup({
      ...msgPopup,
      isVisible: false,
      msg: ''
    })
  }

  const handleOnChangePageSize = (size: number) => {
    setPageSize(size)
    setCurrentPage(1)
    handleCallAPIGetList(keywordSelected, communicationSelected, deviceSelected, 1, size)
  }

  const handleOnChangeCurrentPage = (page: number) => {
    setCurrentPage(page)
    isSearch 
    ? handleCallAPIGetList(keywordSelected, communicationSelected, deviceSelected, page, pageSize) 
    : handleCallAPIGetList('', '', '', page, pageSize)
    console.log('page',page,'pagesize',pageSize);
  }

  const columns: TableColumnsType<IKatashikiModel> = [
    {
      title: 'No',
      dataIndex: '',
      align: 'center',
      width: '5%',
      render: (text: any, row: any, index: any) => {
        return <span>{(currentPage - 1) * pageSize + (index + 1)}</span>
      }
    },
    {
      title: t('sideBar.katashiki.katashikiTitle.Model'),
      render: (_: object, record: any) => {
        return <TooltipComponent maxSlice={12} title={record?.model} />
      },
      width: '11%'
    },
    {
      title: t('sideBar.katashiki.katashikiTitle.productName'),
      render: (_: object, record: any) => {
        if (record === editingItem) {
          return (
            <React.Fragment>
              <Input
                className={`${errorMsg.productNameError ? 'input-error' : ''}`}
                maxLength={PRODUCT_NAME_MAX_LENGTH}
                value={editParam.productName}
                onChange={handleOnChangeProductName}
                onBlur={() => handleOnBlurProductName()}
              />
              {errorMsg.productNameError ? (
                <span className='error-message'>
                  {t('sideBar.katashiki.katashikiTitle.productName') + t('errors.message.FRE00001')}
                </span>
              ) : null}
            </React.Fragment>
          )
        } else {
          return <TooltipComponent maxSlice={12} title={record?.productName} />
        }
      },
      width: '11%'
    },
    {
      title: t('sideBar.katashiki.katashikiTitle.CommunicationType'),
      render: (_: object, record: any) => {
        return <div>{record?.sensorType?.sensorTypeKey}</div>
      },
      width: '10%'
    },
    {
      title: t('sideBar.katashiki.katashikiTitle.DeviceType'),
      render: (_: object, record: any) => {
        return <div>{record?.sensorTypeTranslate?.sensorTypeName}</div>
      },
      width: '10%'
    },
    {
      title: t('sideBar.katashiki.katashikiTitle.ManagementSymbol'),
      render: (_: object, record: any) => {
        return <div>{record.managementSymbol}</div>
      },
      width: '10%'
    },
    {
      title: t('sideBar.katashiki.katashikiTitle.date'),
      render: (_: object, record: any) => {
        return <div>{moment(record.createdAt).format('YYYY/MM/DD')}</div>
      },
      width: '10%'
    },
    {
      title: t('sideBar.katashiki.katashikiTitle.remarks'),
      render: (_: object, record: any) => {
        if (record === editingItem) {
          return (
            <React.Fragment>
              <Input
                className={`${errorMsg.remarkError ? 'input-error' : ''}`}
                value={editParam.remarks}
                onChange={handleOnChangeRemarks}
                onBlur={() => handleOnBlurRemarks()}
                maxLength={64}
              />
              {errorMsg.remarkError ? (
                <span className='error-message'>
                  {t('sideBar.katashiki.katashikiTitle.remarks') + t('errors.message.FRE00001')}
                </span>
              ) : null}
            </React.Fragment>
          )
        } else {
          return <TooltipComponent maxSlice={10} title={record?.remarks} />
        }
      },
      width: '10%'
    },
    {
      title: t('sideBar.katashiki.katashikiTitle.edit'),
      render: (_: object, record: any) => {
        return (
          <div
            className={`edit-btn ${!editingItem || editingItem === record ? 'pointer' : 'pointer-disable'
              }`}
            onClick={() => handleEditItem(record)}>
            <span>
              {editingItem === record ? (
                t('sideBar.katashiki.katashikiTitle.register')
              ) : (
                <img src={IconEditBlue} className='w42x-h28x' alt='edit' />
              )}
            </span>
          </div>
        )
      },
      width: '5%',
      align: 'center'
    },
    {
      title: t('sideBar.katashiki.katashikiTitle.delete'),
      dataIndex: '',
      render: (_: object, record: any) => {
        return (
          <div onClick={() => handleOnRemoveClicked(record)} className='text-center'>
            <img src={IconDeleteRed} className='w42x-h28x pointer' alt='delete' />
          </div>
        )
      },
      width: '5%',
      align: 'center'
    }
  ]

  const handleOnClearField = () => {
    setCommunicationSelected([])
    setDeviceSelected([])
    setKeywordSelected('')
    setCurrentPage(1)
    setPageSize(10)
    setIsSearch(false)
    handleCallAPIGetList('', [], [], 1, 10)
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <div className='kasaki-list'>
      <div className='bgrffffff'>
        <div className='kasaki-list-shape-btn pd_16x_0x'>
          <div className='ss-drop-drown mg_0_16x'>
            <VButton
              onClick={handleGoCreateKatashiki}
              className='kasaki-list-button-back'
              title={t('userManagementScreen.btnCreate')}
            />
          </div>
        </div>
      </div>
      <br />
      <Collapsed isTopSpace={false}>
        <div className=''>
          <div className='kasaki-shape-management'>
            <div className='ss-drop-drown'>
              <div className='filter-label'>{t('sideBar.katashiki.katashikiTitle.Model')}</div>
              <React.Fragment>
                <Select
                  value={keywordSelected}
                  className='keyword-options'
                  onChange={handleOnKeywordChanged}>
                  {keywordData?.map((item: IKatashikiModel) => (
                    <Option value={item.model} key={item.id}>
                      {item.model}
                    </Option>
                  ))}
                </Select>
              </React.Fragment>
            </div>
            <div className='ss-drop-drown mg_0_16x'>
              <div className='filter-label'>
                {t('sideBar.katashiki.katashikiTitle.CommunicationType')}
              </div>
              {communicationTypeData?.map((el: any) => {
                return (
                  <React.Fragment key={el.value}>
                    <Checkbox
                      checked={isExist(el.value, communicationSelected)}
                      style={checkbox}
                      onChange={() => handleCommunicationTypeOptionChanged(el.value)}>
                      {el.label}
                    </Checkbox>
                  </React.Fragment>
                )
              })}
            </div>
            <div className='ss-drop-drown mg_0_16x'>
              <div className='filter-label'>{t('sideBar.katashiki.katashikiTitle.DeviceType')}</div>
              {deviceTypeData?.map((el: any) => {
                return (
                  <React.Fragment key={el.value}>
                    <Checkbox
                      checked={isExist(el.value, deviceSelected)}
                      style={checkbox}
                      onChange={() => handleDeviceTypeOptionChanged(el.value)}>
                      {el.label}
                    </Checkbox>
                  </React.Fragment>
                )
              })}
            </div>
            <div className='group-btn-action'>
              <VButton
                onClick={() => handleOnSearch()}
                title={t('sensorManagementScreen.btnSubmit')}
                style={styleBtnSubmit}
                type='text'
              />
              <VButton
                title={t('userManagementScreen.btnCancel')}
                onClick={() => handleOnClearField()}
                style={styleBtnClear}
              />
            </div>
          </div>
        </div>
      </Collapsed>
      <div className='bgrffffff'>
        <div className='kasaki-list-table pd_16x_0x'>
          <div className='sensor-pagination mb-16x'>
            <div className='d-flex justify-content-space-between align-items-center mg-pagination'>
              <div className='ss-drop-drown mg_0_16x'>
                <ExportCsvComponent
                  headers={headersCSVListKatashiki}
                  data={csvData}
                  fileName='型式一覧'
                  isDisabled={false}
                />
              </div>
              <div className='d-flex justify-content-flex-end pd_16x_0x total-record'>
                <div className='font-w-bold'>{showTotalRecord(totalRecord)}</div>
                <div className='sensor-pagination'>
                  <PaginationComponent
                    onChangePageSize={(size) => handleOnChangePageSize(size)}
                    total={totalRecord}
                    onChangePage={(pageChange) => handleOnChangeCurrentPage(pageChange)}
                    pageSize={pageSize}
                    isShowSizeChanger={false}
                    currentpage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='custom-table'>
            <TableComponent isSearch={isSearch} columns={columns} dataSource={dataTable} />
          </div>
          <div className='sensor-pagination mg_0x_16x'>
            <PaginationComponent
              onChangePageSize={(size) => handleOnChangePageSize(size)}
              total={totalRecord}
              onChangePage={(pageChange) => handleOnChangeCurrentPage(pageChange)}
              pageSize={pageSize}
              isShowSizeChanger={true}
              currentpage={currentPage}
            />
          </div>
          <div className='btn-return text-align-center'>
            <Button onClick={() => handleGoBack()} className='katashiki-btnReset'>
              戻る
            </Button>
          </div>
        </div>
      </div>
      <ModalComponent
        isVisible={removePopup.isVisible}
        img={IconDeleteModal}
        content={t('modal.message.removeOrderItem')}
        btnCancel={t('modal.btnCancel')}
        btnSubmit={t('modal.btnDelete')}
        onCancelModal={handleCancelRemovePopup}
        onSubmitModal={handleSubmitRemovePopup}
      />
      <ModalCheckSuccessComponent
        isVisible={msgPopup.isVisible}
        content={msgPopup.msg}
        image={msgPopup.msg === t('searchEmptyTable') ? IconWarning : null}
        handleClickCancel={handleMsgPopupCancel}
      />
      <ModalEnvironment
        isVisible={isShowMessDelErr}
        content={'この型式に紐づかれる製造機器が登録されているため削除できません。'}
        btnClose={t('modal.modalEnvironment.btnClose')}
        onCloseModal={() => seIsShowMessDelErr(false)}
      />
    </div>
  )
}

export default ListKatashiki
const styleBtnSubmit = {
  width: 74,
  height: 38,
  color: '#ffffff'
}
const styleBtnClear = {
  height: 38,
  width: 116,
  marginLeft: 15,
  backgroundColor: '#fff',
  color: ' #d7000f',
  border: 'solid 1px #e3242b'
}
const checkbox = {
  marginRight: 20,
  fontSize: 12
}
