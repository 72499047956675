/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import VButton from 'components/button/VButton'
import VButtonOrder from 'components/button/VButtonOrder'
import TableComponent from 'components/common/table/TableComponent'
import 'styles/order.scss'
import { orderDetailColumns } from './defaultColumns'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import ModalComponent from 'components/modal/ModalComponent'
import { IconDeleteModal } from '../../../assets'
import ModalCheckSuccessComponent from 'components/modal/ModalCheckSuccessComponent'
import { ADMIN_ROUTE, routeOrder, routeSerial } from 'views/routes/routes'
import { useDispatch } from 'react-redux'
import { deleteOrderAction, getOrderDetailAction } from 'redux/actions/order/orderAction'
import { IDeleteOrder, IOrderDetailParams } from 'services/params/order/IorderParam'
import { listKatashikiGetKeyword } from 'redux/actions/katashiki/katashikiAction'
import { getShipMentStatus } from 'utils/Functions'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import ExportCsvComponent from 'components/common/exportCsv/ExportCsvComponent'
import { headerCsvOrder } from './csv'
import { CONFIRMED_STATUS } from 'utils/helpers'
import { EAuditLogActionType, EAuditLogType } from 'interfaces/enums/EAuditLog'
import { detailAuditLogAction } from 'redux/actions/auditLog/auditLogAction'
import NotFoundComponent from '../notFound/NotFoundComponent'

function OrderDetail() {
  const { t } = useTranslation()
  const location: any = useLocation()
  const orderItemUrl = location.pathname.split('/')
  const orderItem = location?.state?.item ? location?.state?.item : orderItemUrl[orderItemUrl.length - 1]
  
  const history = useHistory()
  const dispatch = useDispatch()


  const [popupRemove, setPopupRemove] = useState(false)
  const [popupMsg, setPopupMsg] = useState(false)

  const [customerData, setCustomerData] = useState<any>()
  const [tableData, setTableData] = useState<any>()

  const [auditLogCreated, setAuditLogCreated] = useState<any>()
  const [auditLogUpdated, setAuditLogUpdated] = useState<any>()

  const [modelList, setModelList] = useState<any>()

  const [csvData, setCsvData] = useState<any>()
  const [isNotFound, setIsNotFound] = useState<boolean>(false)

  useEffect(() => {
    const apiGetModelList = () => {
      dispatch(
        listKatashikiGetKeyword((resp) => {
          if (resp?.result) {
            setModelList(resp?.result)
          }
        })
      )
    }

    apiGetModelList()
    getAuditLog()
  }, [])
  const apiGetOrderDetail = (sortBy?: { order: 'ASC' | 'DESC', target: string }) => {
    if (modelList) {
      const param: IOrderDetailParams = {
        orderId: orderItem,
        ...(sortBy ? { sortBy } : {})
      }
      dispatch(
        getOrderDetailAction(
          { ...param },
          async (data) => {
            if (data?.result) {
              dispatch(SetLoadingAction(false))
              getTableDataAndCsvData(data?.result)
              const dataOrderConvert = convertDataOrder(data?.result?.ordDetails)
              setTableData(dataOrderConvert)
              setCustomerData(data?.result)
            } else {
              setIsNotFound(true)
              dispatch(SetLoadingAction(false))
            }
          },
          true
        )
      )
    }
  }

  useEffect(() => {
    apiGetOrderDetail()
  }, [modelList])

  const getAuditLog = () => {
    const params: any = {
      recordId: Number(orderItem),
      type: EAuditLogType.ORDER
    }
    dispatch(
      detailAuditLogAction(
        { ...params },
        async (data) => {
          const dataAuditLogs = await data?.result?.record;
          for (const dataAuditLog of dataAuditLogs) {

            if (dataAuditLog?.action === EAuditLogActionType.CREATE_ORDER) {

              setAuditLogCreated(dataAuditLog);
            } else if (dataAuditLog?.action === EAuditLogActionType.EDIT_ORDER) {

              setAuditLogUpdated(dataAuditLog);
            }
          }

          dispatch(SetLoadingAction(false))
        },
        true
      )
    )
  }

  const convertDataOrder = (data: any) => {
    data = data.map((item: any, index: number) => {
      return {
        serialNoNumber: item.serial.serialNoNumber,
        model: getModelName(item.serial.modelRegistrationId),
        manufacturingDate: formatDate(item.serial.manufacturingDate),
        deviceId: item.serial.deviceId || 'N/A',
        transmissionLineType: item?.serial?.sensorType?.sensorTypeKey,
        sensorType: item?.serial?.sensorTypeTranslate?.sensorTypeName,
        securityKey: item?.serial?.securityKey ? item?.serial?.securityKey : '',
        IMEINumber: item.serial.IMEINumber,
        serialNoNumberOld: item.serial.serialNoNumberOld,
        CHAddress: item.serial.CHAddress,
        productSite: item.serial.productSite,
        shipmentStatus: getShipMentStatus(item.serial.confirmed),
        key: item.serial.id,
        id: item.serial.id
      }
    })
    return data
  }

  const getTableDataAndCsvData = (data: any) => {
    const csv: any[] = []
    data?.ordDetails?.map((el: any, index: number) => {
      const obj: any = {}
      if (index === 0) {
        obj.createdAt = data?.createdAt ? formatDate(data.createdAt) : ''
        obj.updatedAt = data?.updatedAt ? formatDate(data?.updatedAt) : ''
        obj.customerName = data?.customer?.customerName ? data.customer.customerName : ''
        obj.orderCode = data?.orderCode ? data?.orderCode : ''
        obj.dateDelivery = data?.dateDelivery ? formatDate(data?.dateDelivery) : ''
        obj.address = data?.address ? data?.address : ''
      }

      obj.serialNoNumber = el?.serial?.serialNoNumber ? el?.serial?.serialNoNumber : ''
      obj.model = el?.serial?.modelRegistration?.model ? el?.serial?.modelRegistration?.model : ''
      obj.serialCreatedAt = el?.serial?.manufacturingDate
        ? moment(el?.serial?.manufacturingDate).format('YYYY/MM')
        : ''
      obj.deviceId = el?.serial?.deviceId
      obj.sensorType = el?.serial?.sensorType?.sensorTypeKey
        ? el?.serial?.sensorType?.sensorTypeKey
        : ''
      obj.sensorTypeTranslate = el?.serial?.sensorTypeTranslate?.sensorTypeName
        ? el?.serial?.sensorTypeTranslate?.sensorTypeName
        : ''
      obj.securityKey = el?.serial?.securityKey ? el?.serial?.securityKey : ''
      obj.IMEINumber = el?.serial?.IMEINumber ? el?.serial?.IMEINumber : ''
      obj.serialNoNumberOld = el?.serial?.serialNoNumberOld ? el?.serial?.serialNoNumberOld : ''
      obj.CHAddress = el?.serial?.CHAddress ? el?.serial?.CHAddress : ''
      obj.productSite = el?.serial?.productSite ? el?.serial?.productSite : ''
      obj.confirmed = el?.serial?.confirmed ? CONFIRMED_STATUS.TRUE : CONFIRMED_STATUS.FALSE
      obj.note = el?.serial?.note ? el?.serial?.note : ''

      csv.push(obj)
    })
    setCsvData(csv)
  }

  const getModelName = (modelID: number) => {
    let model = ''
    modelList?.some((el: any) => {
      if (el.id === modelID) {
        model = el.model
        return
      }
    })
    return model
  }

  const formatDate = (date: string) => {
    return moment(date).format('YYYY/MM/DD')
  }

  const columns = orderDetailColumns(
    (record: any) => handleOnClickDetail(record),
    (method) => {
      apiGetOrderDetail({ order: method, target: 'serial.serialNoNumber' })
    }
  )
  const handleOnClickDetail = (record: any) => {
    const path = ADMIN_ROUTE.concat(routeSerial.INDIVIDUAL_DETAIL).replace(':id', record?.id)
    history.push(path, record.id.toString())
  }

  const handleEditButtonOnClicked = () => {
    history.push(ADMIN_ROUTE.concat(routeOrder.ORDER_EDIT).replace(':id', orderItem), {
      item: orderItem
    })
  }

  const handleRemoveButtonOnClicked = () => {
    setPopupRemove(true)
  }

  const handlePopupRemoveCancel = () => {
    setPopupRemove(false)
  }

  const handlePopupRemoveSubmit = () => {
    const params: IDeleteOrder = {
      orderId: orderItem
    }

    dispatch(
      deleteOrderAction(
        params,
        (resp) => {
          if (resp?.result) {
            setPopupRemove(false)
            setPopupMsg(true)
          }
        },
        true
      )
    )
  }

  const handlePopupMsgCancel = () => {
    setPopupMsg(false)
    history.goBack()
  }

  const getUpdatedAt = (): string => {
    if (auditLogUpdated?.createdAt) {
      return moment(auditLogUpdated?.createdAt).format('YYYY/MM/DD');
    }
    if (auditLogCreated?.createdAt) {
      return moment(auditLogCreated?.createdAt).format('YYYY/MM/DD');
    }
    return '';
  }

  return (
    isNotFound ? <NotFoundComponent /> :
    <div className='bgrffffff'>
      <div className='order-create pd_16x_0x'>
        <div className='ss-search-email mg_0_16x'>
          <div className='label-bold'>{t('sideBar.order.orderDetailBreadcrumb')}</div>
          <Row>
            <Col span={12}>
              <Row className='row-spc-8'>
                <Col span={6} className='row-label'>
                  {t('sideBar.order.orderItem.customerName')}
                </Col>
                <Col span={12} className='row-content'>
                  <TooltipComponent maxSlice={40} title={customerData?.customer?.customerName} />
                </Col>
              </Row>
              <Row className='row-spc-8'>
                <Col span={6} className='row-label'>
                  {t('sideBar.order.orderItem.orderName')}
                </Col>
                <Col span={12} className='row-content'>
                  <TooltipComponent maxSlice={40} title={customerData?.orderCode} />
                </Col>
              </Row>
              <Row className='row-spc-8'>
                <Col span={6} className='row-label'>
                  {t('sideBar.order.orderItem.orderNote')}
                </Col>
                <Col span={12} className='row-content'>
                  <TooltipComponent maxSlice={40} title={customerData?.note} />
                </Col>
              </Row>
              <Row className='row-spc-8' style={{ ...styleFieldSystem }}>
                <Col span={6} className='row-label'>
                  {t('sideBar.order.orderItem.registeredPerson')}
                </Col>
                <Col span={12} className='row-content'>
                  <TooltipComponent maxSlice={40} title={auditLogCreated?.username} />
                </Col>
              </Row>
              <Row className='row-spc-8' style={{ ...styleFieldSystem }}>
                <Col span={6} className='row-label'>
                  {t('sideBar.order.orderItem.changer')}
                </Col>
                <Col span={12} className='row-content'>
                  <TooltipComponent maxSlice={40} title={auditLogUpdated?.username ? auditLogUpdated?.username : auditLogCreated?.username} />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row className='row-spc-8'>
                <Col span={6} className='row-label'>
                  {t('sideBar.order.orderItem.orderDate')}
                </Col>
                <Col span={12} className='row-content'>
                  {customerData?.dateDelivery ? formatDate(customerData?.dateDelivery) : ''}
                </Col>
              </Row>
              <Row className='row-spc-8'>
                <Col span={6} className='row-label'>
                  {t('sideBar.order.orderItem.orderLocation')}
                </Col>
                <Col span={12} className='row-content'>
                  <TooltipComponent maxSlice={40} title={customerData?.address} />
                </Col>
              </Row>
              <Row className='row-spc-8'>
                &nbsp;
              </Row>
              <Row className='row-spc-8' style={{ ...styleFieldSystem }}>
                <Col span={6} className='row-label'>
                  {t('sideBar.order.orderItem.createdAt')}
                </Col>
                <Col span={12} className='row-content'>
                  {auditLogCreated?.createdAt ? formatDate(auditLogCreated?.createdAt) : '' }
                </Col>
              </Row>
              <Row className='row-spc-8' style={{ ...styleFieldSystem }}>
                <Col span={6} className='row-label'>
                  {t('sideBar.order.orderItem.updatedAt')}
                </Col>
                <Col span={12} className='row-content'>
                  {getUpdatedAt()}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className='row-button-download-csv'>
          {csvData ? (
            <ExportCsvComponent
              headers={headerCsvOrder}
              data={csvData}
              fileName='出荷一覧'
              isDisabled={false}
            />
          ) : null}
        </div>
        <div className='custom-table-order-detail'>
          <TableComponent columns={columns} dataSource={tableData} tableScroll={{ y: 240 }} />
        </div>
        <div className='group-btn-add-remove pd_16x_0x'>
          <VButton
            onClick={() => handleEditButtonOnClicked()}
            title={t('sideBar.katashiki.katashikiTitle.edit')}
            style={styleBtnEdit}
            type='text'
          />
          <VButtonOrder
            onClick={() => handleRemoveButtonOnClicked()}
            title={t('adminManagement.colDel')}
            style={styleBtnDelete}
            type='text'
          />
        </div>
      </div>
      <ModalComponent
        isVisible={popupRemove}
        img={IconDeleteModal}
        content={t('modal.message.removeOrderItem')}
        btnCancel={t('modal.btnCancel')}
        btnSubmit={t('modal.btnDelete')}
        onCancelModal={handlePopupRemoveCancel}
        onSubmitModal={handlePopupRemoveSubmit}
      />
      <ModalCheckSuccessComponent
        isVisible={popupMsg}
        content={t('modal.message.removeSuccess')}
        handleClickCancel={handlePopupMsgCancel}
      />
    </div>
  )
}
const styleBtnEdit = {
  height: 38,
  width: 76,
  backgroundColor: '#d7000f',
  color: '#fff',
  fontSize: 12,
  borderColor: ' #d7000f'
}
const styleBtnDelete = {
  height: 38,
  width: 76,
  backgroundColor: '#fff',
  color: ' #d7000f',
  border: 'solid 1px #e3242b'
}
const styleFieldSystem = {
  color: '#69696A'
}
export default OrderDetail
