import Modal from 'antd/lib/modal/Modal'
import VButton from 'components/button/VButton'
interface IModal {
  isVisible: boolean
  type?: string | ''
  isComponent?: boolean | false
  component?: any
  img?: string | ''
  content?: string | ''
  btnCancel: string
  btnSubmit: string
  onCancelModal: () => void
  onSubmitModal: () => void
  hideBtnFooter?: boolean
  style?: any
  zIndex?: number
}
function ModalComponent({
  isVisible,
  isComponent,
  component,
  img,
  content,
  btnCancel,
  btnSubmit,
  onCancelModal,
  onSubmitModal,
  hideBtnFooter,
  style,
  zIndex,
}: IModal) {
  return (
    <Modal
      visible={isVisible}
      centered={true}
      footer={null}
      maskClosable={false}
      zIndex={zIndex}
      onCancel={onCancelModal}>
      <div className={`custom-padding-modal ${hideBtnFooter ? 'padding-footer' : ''}`}>
        {!isComponent ? (
          img ? (
            <div className='modal-ic text-align-center mb-25x'>
              <img src={img} alt='modal' style={{ ...styleImg }} />
            </div>
          ) : null
        ) : null}
        {isComponent ? (
          component
        ) : (
          <div className='modal-content text-align-center mb-25x font-w-bold' style={style}>
            {content}
          </div>
        )}
        {!hideBtnFooter ? (
          <div className='text-align-center'>
            <VButton title={btnSubmit} style={styleBtnSubmit} onClick={onSubmitModal} type='text' />
            <VButton title={btnCancel} style={styleBtnCancel} onClick={onCancelModal} type='text' />
          </div>
        ) : null}
      </div>
    </Modal>
  )
}
const styleImg = {
  width: 90,
  height: 75
}
const styleBtnSubmit = {
  width: 74,
  height: 38,
  marginRight: 10,
  color: '#ffffff'
}
const styleBtnCancel = {
  width: 116,
  height: 38,
  marginLeft: 10,
  background: '#ffffff',
  border: '1px solid #D7000F',
  color: '#D7000F'
}
export default ModalComponent
