import {
  IGetListGroupByEmail,
  IGetListSensorByGroup,
  IGroupDetail
} from 'services/params/group/IGroupParams'
import {
  getListGroupByEmailRequest,
  getListSensorByGroupRequest,
  groupDetailRequest
} from 'services/requests/group/groupRequest'
import { configRequest } from '..'

class GroupApis {
  groupDetail = (param: IGroupDetail) => {
    const requests = groupDetailRequest(param)
    return configRequest(requests)
  }
  getListSenorByGroup = (param: IGetListSensorByGroup) => {
    const requests = getListSensorByGroupRequest(param)
    return configRequest(requests)
  }
  getListGroupByEmail = (param: IGetListGroupByEmail) => {
    const requests = getListGroupByEmailRequest(param)
    return configRequest(requests)
  }
}

export const GroupApi = new GroupApis()
