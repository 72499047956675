import {
  IconAdminManagement,
  IconAdminManagementActive,
  IconDashboard,
  IconDashboardActive,
  IconDisplayManagement,
  IconDisplayManagementActive,
  IconInfoManagement,
  IconInfoManagementActive,
  // IconQrcode,
  // IconQrcodeBlack,
  IconSensorManagement,
  IconSensorManagementActive,
  IconCustomerManagement,
  IconCustomerManagementActive,
  IconKatakishiblack,
  IconKatakishiwhite
} from 'assets'
import i18n from 'configs/i18n'
import DashboardContainer from 'containers/DashboardContainer'
import SensorBatteryIsLowContainer from 'containers/SensorBatteryIsLowContainer'
import SensorCommunicationErrContainer from 'containers/SensorCommunicationErrContainer'
import SensorDetailContainer from 'containers/SensorDetailContainer'
import SensorManagementContainer from 'containers/SensorManagementContainer'
import SensorOfOutagesContainer from 'containers/SensorOfOutagesContainer'
import AdminManagement from 'views/pages/adminManagement/AdminManagement'
import DisplayManagement from 'views/pages/displayManagement/DisplayManagement'
import InfoManagement from 'views/pages/infoManagement/InfoManagement'
import GroupDetailComponent from 'views/pages/sensor/group/GroupDetailComponent'
// import MultiSettings from 'views/pages/multiSettings/MultiSettings'
// import IndividualSettings from 'views/pages/individualSettings/IndividualSettings'
import CalculatorSheet from 'views/pages/calculatorSheet/CalculatorSheet'
import InfoDetail from 'views/pages/infoManagement/detail/InfoDetail'
import InfoEdit from 'views/pages/infoManagement/edit/InfoEdit'
import InfoCreate from 'views/pages/infoManagement/create/InfoCreate'
import UserDetailContainer from 'containers/UserDetailContainer'
import UserManagement from 'views/pages/userManagement/UserManagement'
// import Qrcode from 'views/pages/qrcode/Qrcode'
import NotFoundComponent from 'views/pages/notFound/NotFoundComponent'
import AccessDeniedComponent from 'views/pages/accessDenied/AccessDeniedComponent'
// import MultiSettingsDetail from 'views/pages/multiSettings/MultiSettingsDetail'
// import IndividualSettingsDetail from 'views/pages/individualSettings/IndividualSettingsDetail'
// import EditIndividualSettings from 'views/pages/individualSettings/EditIndividualSettings'
// import EditMultiSettings from 'views/pages/multiSettings/EditMultiSettings'
// import ListQrcode from 'views/pages/qrcode/ListQrcode'
// import DetailQrcode from 'views/pages/qrcode/DetailRqcode'
// import UpdateQrcode from 'views/pages/qrcode/UpdateQrcode'
import CreateFormula from 'views/pages/formulaSetting/createFormula'
import EditFormula from 'views/pages/formulaSetting/editFormula'
import DetailFormula from 'views/pages/formulaSetting/detailFormula'
import { ERoles, ROLE_ALL, ROLE_CRUD } from 'interfaces/enums/ERoles'
import CreateCustomer from 'views/pages/customer/createCustomer'
import CustomerManagement from 'views/pages/customer/CustomerManagement'
import CreateKatashiki from 'views/pages/katashiki/createKatashiki'
import DetailCustomer from 'views/pages/customer/DetailCustomer'
import EditCustomer from 'views/pages/customer/EditCustomer'
import SerialNoManagement from 'views/pages/serialNo/serialNoManagement/serialNoManagement'
import CreateOrder from 'views/pages/order/createOrder'
import OrderManagement from 'views/pages/order/OrderManagement'
import OrderDetail from 'views/pages/order/orderDetail'
import EditOrder from 'views/pages/order/editOrder'
import SelectTypeCreateSerialNo from 'views/pages/serialNo/selectTypeCreateSerialNo'
import EditSerialNo from 'views/pages/serialNo/createSerial/individualCreate/editSerial'
import CreateIndividualSerialDetail from 'views/pages/serialNo/createSerial/individualCreate/serialDetail'
import IndividualCreate from 'views/pages/serialNo/createSerial/individualCreate/serialCreate'
import BulkCreate from 'views/pages/serialNo/createSerial/bulkCreate/serialCreate'
import CreateBulkSerialDetail from 'views/pages/serialNo/createSerial/bulkCreate/serialDetail'
import ListKatashiki from 'views/pages/katashiki/listKatashiki'
import EditBulkSerialNo from 'views/pages/serialNo/createSerial/bulkCreate/editSerial'
import KatashikiDetail from 'views/pages/katashiki/katashikiDetail'
import ChangePasswordComponent from 'views/pages/changePassword/ChangePasswordComponent'

export const ADMIN_ROUTE = '/admin'
const rootKey = {
  dashboard: 'dashboard',
  user: 'user',
  sensor: 'sensor',
  display: 'display',
  information: 'information',
  admin: 'administrator-management',
  qrcord: 'qrcode',
  customer: 'customer',
  katashiki: 'katashiki',
  serialNo: 'serialNo'
}
export const rootSubmenuKeys = [
  rootKey.dashboard,
  rootKey.user,
  rootKey.sensor,
  rootKey.display,
  rootKey.information,
  rootKey.admin,
  rootKey.qrcord,
  rootKey.customer,
  rootKey.katashiki,
  rootKey.serialNo
]

export const routesName = {
  DASHBOARD: '/dashboard',
  USER_MANAGEMENT: '/user-management',
  SENSOR_MANAGEMENT: '/sensor-management',
  DISPLAY_MANAGEMENT: '/display-management/calculation',
  INFO_MANAGEMENT: '/infomation-management',
  ADMIN_MANAGEMENT: '/administrator-management',
  OTHER_MANAGEMENT: '/other-management',
  LOGIN: '/login',
  BATTERY_MANAGEMENT: '/sensor-management/battery/list',
  COMMUNICATION_MANAGEMENT: '/sensor-management/communication-err/list',
  OUTAGES_MANAGEMENT: '/sensor-management/outages/list',
  NOTIFICATION_MANAGEMENT: '/notification',
  VERIFY_ADMIN_ACCOUNT: '/verify/:token',
  RESET_PASSWORD: '/reset-password/:token',
  // QR_CODE: '/qrcode',
  EXPIRED: '/expired',
  ACCESSDENIED: '/access-denied',
  NOT_FOUND: '/not-found',
  CUSTOMER_MANAGEMENT: '/customer-management',
  KATASHIKI: '/katashiki',
  SERIAL_NO: '/serial-no',
  ORDER: '/order',
  CHANGE_PASSWORD: '/change-password'
}
export const routesUserManagement = {
  USER_CREATE: routesName.USER_MANAGEMENT + '/create',
  USER_LIST: routesName.USER_MANAGEMENT + '/list'
}
const routesSensorManagement = {
  SENSOR_LIST: routesName.SENSOR_MANAGEMENT + '/list',
  SENSOR_DETAIL: routesName.SENSOR_MANAGEMENT + '/sensor'
}
const routesInfoManagement = {
  INFO_DETAIL: routesName.INFO_MANAGEMENT + '/detail',
  INFO_EDIT: routesName.INFO_MANAGEMENT + '/edit',
  INFO_CREATE: routesName.INFO_MANAGEMENT + '/create'
}
const routesDisplayManagement = {
  MULTI_SETTINGS: routesName.DISPLAY_MANAGEMENT + '/multi-settings',
  // MULTI_SETTINGS_DETAIL:
  //   routesName.DISPLAY_MANAGEMENT + '/multi-settings/detail',
  // INDIVIDUAL_SETTINGS: routesName.DISPLAY_MANAGEMENT + '/individual-settings',
  /* INDIVIDUAL_SETTINGS_DETAIL:
    routesName.DISPLAY_MANAGEMENT + '/individual-settings/detail', */
  DETAIL_FORMULA: routesName.DISPLAY_MANAGEMENT + '/formula/detail',
  CALCULATOR_SHEET: routesName.DISPLAY_MANAGEMENT + '/calculator',
  EDIT_FORMULA: routesName.DISPLAY_MANAGEMENT + '/edit-formula',
  EDIT_INDIVIDUAL: routesName.DISPLAY_MANAGEMENT + '/edit-individual',
  // formula new
  CREATE_FORMULA: routesName.DISPLAY_MANAGEMENT + '/formula/create'
}
const routeOrder = {
  CREATE_ORDER: routesName.ORDER + '/create',
  LIST_ORDER: routesName.ORDER + '/list',
  ORDER_DETAIL: routesName.ORDER + '/detail/:id',
  ORDER_EDIT: routesName.ORDER + '/edit/:id'
}
const routeSerial = {
  SELECT_TYPE_CREATE_SERIAL: routesName.SERIAL_NO + '/select',
  LIST_SERIAL: routesName.SERIAL_NO + '/list',
  INDIVIDUAL_CREATE: routesName.SERIAL_NO + '/individual-create',
  INDIVIDUAL_DETAIL: routesName.SERIAL_NO + '/individual-detail/:id',
  INDIVIDUAL_EDIT: routesName.SERIAL_NO + '/individual-edit/:id',
  BULK_CREATE: routesName.SERIAL_NO + '/bulk-create',
  BULK_DETAIL: routesName.SERIAL_NO + '/bulk-detail/:id',
  BULK_EDIT: routesName.SERIAL_NO + '/bulk-edit/:id'
}
const routerChangePass = {
  CHANGE_PASS: routesName.CHANGE_PASSWORD
}

export const routesCustomerManagement = {
  CUSTOMER_CREATE: routesName.CUSTOMER_MANAGEMENT + '/create',
  CUSTOMER_LIST: routesName.CUSTOMER_MANAGEMENT + '/list',
  CUSTOMER_DETAIL: routesName.CUSTOMER_MANAGEMENT + '/detail/:id',
  CUSTOMER_EDIT: routesName.CUSTOMER_MANAGEMENT + '/edit/:id'
}

const routes = [
  {
    id: 0,
    title: i18n.t('sideBar.dashBoardMenu'),
    icon: null,
    activeIcon: null,
    path: routesName.DASHBOARD,
    component: DashboardContainer,
    isShow: false
  },
  {
    id: 1,
    key: rootKey?.customer,
    title: i18n.t('sideBar.customerManagement'),
    icon: IconCustomerManagement,
    activeIcon: IconCustomerManagementActive,
    isShow: true,
    subMenu: [
      {
        isShow: true,
        key: 'customerSub2',
        title: i18n.t('sideBar.customerRegister'),
        path: routesCustomerManagement.CUSTOMER_CREATE,
        component: CreateCustomer,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'customer',
            name: i18n.t('sideBar.customerManagement'),
            path: routesCustomerManagement.CUSTOMER_LIST
          },
          {
            id: 'customerSub2',
            name: i18n.t('sideBar.customerRegister')
          }
        ]
      },
      {
        isShow: true,
        key: 'customerSub1',
        title: i18n.t('sideBar.customerList'),
        path: routesCustomerManagement.CUSTOMER_LIST,
        isCreate: true,
        component: CustomerManagement,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'customer',
            name: i18n.t('sideBar.customerManagement')
            // path: routesCustomerManagement.CUSTOMER_LIST
          },
          {
            id: 'customerSub1',
            name: i18n.t('sideBar.customerList')
          }
        ]
      },
      {
        isShow: false,
        key: '',
        title: i18n.t('sideBar.customerList'),
        path: routesCustomerManagement.CUSTOMER_DETAIL,
        isCreate: true,
        component: DetailCustomer,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'customer',
            name: i18n.t('sideBar.customerManagement'),
            path: routesCustomerManagement.CUSTOMER_LIST
          },
          {
            id: 'customerSub3',
            name: i18n.t('sideBar.customerDetail')
          }
        ]
      },
      {
        isShow: false,
        key: '',
        title: i18n.t('sideBar.customerList'),
        path: routesCustomerManagement.CUSTOMER_EDIT,
        isCreate: true,
        component: EditCustomer,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'customer',
            name: i18n.t('sideBar.customerManagement'),
            path: routesCustomerManagement.CUSTOMER_LIST
          },
          {
            id: 'customerSub3',
            name: i18n.t('sideBar.customerEdit')
          }
        ]
      }
    ]
  },
  {
    id: 2,
    key: rootKey?.user,
    title: i18n.t('sideBar.dashboard.txtDashboard'),
    icon: IconDashboard,
    activeIcon: IconDashboardActive,
    isShow: true,
    subMenu: [
      // {
      //   isShow: true,
      //   key: 'userSub1',
      //   title: i18n.t('sideBar.createUser'),
      //   path: routesUserManagement.USER_LIST,
      //   isCreate: true,
      //   component: UserManagement,
      //   role: ROLE_CRUD,
      //   arrBreadScrumb: [
      //     {
      //       id: 'user',
      //       name: i18n.t('sideBar.dashboard.txtDashboard'),
      //       path: routesUserManagement.USER_LIST
      //     },
      //     {
      //       id: 'userSub1',
      //       name: i18n.t('sideBar.createUser')
      //     }
      //   ]
      // },
      {
        isShow: true,
        key: 'userSub2',
        title: i18n.t('sideBar.userList'),
        path: routesUserManagement.USER_LIST,
        component: UserManagement,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'user',
            name: i18n.t('sideBar.dashboard.txtDashboard')
          },
          {
            id: 'userSub2',
            name: i18n.t('sideBar.userList')
          }
        ]
      }
    ]
  },
  {
    id: 3,
    key: rootKey?.sensor,
    title: i18n.t('sideBar.sensorManagement.txtSensorManagement'),
    icon: IconSensorManagement,
    activeIcon: IconSensorManagementActive,
    isShow: true,
    subMenu: [
      {
        isShow: true,
        key: 'sensorSub1',
        title: i18n.t('sideBar.sensorList'),
        path: routesSensorManagement.SENSOR_LIST,
        component: SensorManagementContainer,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'sensor',
            name: i18n.t('sideBar.sensorManagement.txtSensorManagement')
          },
          {
            id: 'sensorSub1',
            name: i18n.t('sideBar.sensorList')
          }
        ]
      },
      {
        isShow: true,
        key: 'sensorSub2',
        title: i18n.t('sideBar.communicationErrorList'),
        path: routesName.COMMUNICATION_MANAGEMENT,
        component: SensorCommunicationErrContainer,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'sensor',
            name: i18n.t('sideBar.sensorManagement.txtSensorManagement'),
            path: routesSensorManagement.SENSOR_LIST
          },
          {
            id: 'sensorSub2',
            name: i18n.t('sideBar.communicationErrorList')
          }
        ]
      },
      {
        isShow: true,
        key: 'sensorSub3',
        title: i18n.t('sideBar.batteryDropList'),
        path: routesName.BATTERY_MANAGEMENT,
        component: SensorBatteryIsLowContainer,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'sensor',
            name: i18n.t('sideBar.sensorManagement.txtSensorManagement'),
            path: routesSensorManagement.SENSOR_LIST
          },
          {
            id: 'sensorSub3',
            name: i18n.t('sideBar.batteryDropList')
          }
        ]
      },
      {
        isShow: true,
        key: 'sensorSub4',
        title: i18n.t('sideBar.listOfOutages'),
        path: routesName.OUTAGES_MANAGEMENT,
        component: SensorOfOutagesContainer,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'sensor',
            name: i18n.t('sideBar.sensorManagement.txtSensorManagement'),
            path: routesSensorManagement.SENSOR_LIST
          },
          {
            id: 'sensorSub4',
            name: i18n.t('sideBar.listOfOutages')
          }
        ]
      }
    ]
  },
  {
    id: 4,
    key: rootKey?.display,
    title: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
    icon: IconDisplayManagement,
    activeIcon: IconDisplayManagementActive,
    isShow: true,
    subMenu: [
      {
        isShow: true,
        key: 'displaySub1',
        title: i18n.t('sideBar.caculatorList'),
        path: routesName.DISPLAY_MANAGEMENT,
        component: DisplayManagement,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'display',
            name: i18n.t('sideBar.displayManagement.txtDisplayManagement')
          },
          {
            id: 'displaySub1',
            name: i18n.t('sideBar.caculatorList')
          }
        ]
      },
      /* {
        isShow: false,
        key: '',
        title: i18n.t('sideBar.caculatorList'),
        path: routesDisplayManagement.MULTI_SETTINGS,
        component: MultiSettings,
        arrBreadScrumb: [
          {
            id: 'display',
            name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
            path: routesName?.DISPLAY_MANAGEMENT
          },
          {
            id: 'displaySub2',
            name: i18n.t('displayManagement.multiSetting')
          }
        ]
      }, */
      /* {
        isShow: false,
        key: '',
        title: i18n.t('sideBar.caculatorList'),
        path: routesDisplayManagement.MULTI_SETTINGS_DETAIL,
        component: MultiSettingsDetail,
        arrBreadScrumb: [
          {
            id: 'display',
            name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
            path: routesName?.DISPLAY_MANAGEMENT
          },
          {
            id: 'displaySub2',
            name: i18n.t('displayManagement.multiSetting')
          }
        ]
      }, */
      /* {
        isShow: false,
        key: '',
        title: i18n.t('sideBar.caculatorList'),
        path: routesDisplayManagement.INDIVIDUAL_SETTINGS,
        component: IndividualSettings,
        arrBreadScrumb: [
          {
            id: 'display',
            name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
            path: routesName?.DISPLAY_MANAGEMENT
          },
          {
            id: 'displaySub2',
            name: i18n.t('caculation.individualSetting')
          }
        ]
      }, */
      /* {
        isShow: false,
        key: '',
        title: i18n.t('sideBar.caculatorList'),
        path: routesDisplayManagement.INDIVIDUAL_SETTINGS_DETAIL,
        component: IndividualSettingsDetail,
        arrBreadScrumb: [
          {
            id: 'display',
            name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
            path: routesName?.DISPLAY_MANAGEMENT
          },
          {
            id: 'displaySub2',
            name: i18n.t('caculation.individualSetting')
          }
        ]
      }, */
      {
        isShow: false,
        key: '',
        title: i18n.t('sideBar.caculatorList'),
        path: routesDisplayManagement.DETAIL_FORMULA,
        component: DetailFormula,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'display',
            name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
            path: routesName?.DISPLAY_MANAGEMENT
          },
          {
            id: 'displaySub2',
            name: i18n.t('caculation.detailFormula')
          }
        ]
      },
      {
        isShow: false,
        key: '',
        title: i18n.t('sideBar.caculatorList'),
        path: routesDisplayManagement.CREATE_FORMULA,
        component: CreateFormula,
        role: ROLE_CRUD,
        arrBreadScrumb: [
          {
            id: 'display',
            name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
            path: routesName?.DISPLAY_MANAGEMENT
          },
          {
            id: 'displaySub2',
            name: i18n.t('caculation.createFormula')
          }
        ]
      }
    ]
  },
  {
    id: 5,
    key: rootKey?.information,
    title: i18n.t('sideBar.infoManagement.txtInfoManagement'),
    icon: IconInfoManagement,
    activeIcon: IconInfoManagementActive,
    isShow: true,
    subMenu: [
      {
        isShow: true,
        key: 'informationSub1',
        title: i18n.t('sideBar.inforRegister'),
        path: routesInfoManagement.INFO_CREATE,
        component: InfoCreate,
        role: ROLE_CRUD,
        arrBreadScrumb: [
          {
            id: 'information',
            name: i18n.t('sideBar.infoManagement.txtInfoManagement'),
            path: routesName.INFO_MANAGEMENT
          },
          {
            id: 'informationSub1',
            name: i18n.t('sideBar.inforRegister')
          }
        ]
      },
      {
        isShow: true,
        key: 'informationSub2',
        title: i18n.t('sideBar.listNotification'),
        path: routesName.INFO_MANAGEMENT,
        component: InfoManagement,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'information',
            name: i18n.t('sideBar.infoManagement.txtInfoManagement')
          },
          {
            id: 'informationSub2',
            name: i18n.t('sideBar.listNotification')
          }
        ]
      }
    ]
  },
  {
    id: 8,
    key: rootKey?.katashiki,
    title: i18n.t('sideBar.katashiki.txtkatashiki'),
    icon: IconKatakishiblack,
    activeIcon: IconKatakishiwhite,
    isShow: true,
    subMenu: [
      {
        isShow: false,
        key: 'serialNoSub1',
        title: i18n.t('sideBar.serialNo.createSerialNo'),
        path: routeSerial.SELECT_TYPE_CREATE_SERIAL,
        component: SelectTypeCreateSerialNo,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'serialNo',
            name: i18n.t('sideBar.serialNo.serialNoTitle'),
            path: routeSerial.LIST_SERIAL
          },
          {
            id: 'serialNoSub1',
            name: i18n.t('sideBar.serialNo.createSerialBreadcrumb')
          }
        ]
      },
      {
        isShow: true,
        key: 'serialNoSub2',
        title: i18n.t('sideBar.serialNo.listSerialNo'),
        path: routeSerial.LIST_SERIAL,
        component: SerialNoManagement,
        role: ROLE_ALL,
        dividerLine: true,
        arrBreadScrumb: [
          {
            id: 'serialNo',
            name: i18n.t('sideBar.serialNo.serialNoTitle')
            // path: routeSerial.LIST_SERIAL
          },
          {
            id: 'serialNoSub2',
            name: i18n.t('sideBar.serialNo.listSerialBreadcrumb')
          }
        ]
      },
      {
        isShow: false,
        key: 'serialNoSub3',
        title: null,
        path: routeSerial.INDIVIDUAL_CREATE,
        component: IndividualCreate,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'serialNo',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
            path: routeSerial.LIST_SERIAL
          },
          {
            id: 'create1',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialCreate'),
            path: routeSerial.SELECT_TYPE_CREATE_SERIAL
          },
          {
            id: 'createIndividual',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.individual')
          }
        ]
      },
      {
        isShow: false,
        key: 'serialNoSub4',
        title: null,
        path: routeSerial.INDIVIDUAL_DETAIL,
        component: CreateIndividualSerialDetail,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'serialNo',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
            path: routeSerial.LIST_SERIAL
          },
          {
            id: 'create1',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialDetail')
          }
        ]
      },
      {
        isShow: false,
        key: 'serialNoSub5',
        title: null,
        path: routeSerial.INDIVIDUAL_EDIT,
        component: EditSerialNo,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'serialNo',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
            path: routeSerial.LIST_SERIAL
          },
          {
            id: 'create1',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialEdit'),
            path: routeSerial.SELECT_TYPE_CREATE_SERIAL
          },
          {
            id: 'editIndividual',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.individualEdit')
          }
        ]
      },
      {
        isShow: false,
        key: 'serialNoSub6',
        title: null,
        path: routeSerial.BULK_CREATE,
        component: BulkCreate,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'serialNo',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
            path: routeSerial.LIST_SERIAL
          },
          {
            id: 'create2',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialCreate'),
            path: routeSerial.SELECT_TYPE_CREATE_SERIAL
          },
          {
            id: 'createBulk',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.bulk')
          }
        ]
      },
      {
        isShow: false,
        key: 'serialNoSub7',
        title: null,
        path: routeSerial.BULK_DETAIL,
        component: CreateBulkSerialDetail,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'serialNo',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
            path: routeSerial.LIST_SERIAL
          },
          {
            id: 'create2',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialCreate')
          }
        ]
      },
      {
        isShow: false,
        key: 'serialNoSub8',
        title: null,
        path: routeSerial.BULK_EDIT,
        component: EditBulkSerialNo,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'serialNo',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.listSerial'),
            path: routeSerial.LIST_SERIAL
          },
          {
            id: 'create2',
            name: i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialCreate'),
            path: routeSerial.SELECT_TYPE_CREATE_SERIAL
          },
          {
            id: 'createBulk',
            name: '複数台編集'
          }
        ]
      },
      {
        isShow: false,
        key: 'orderSub1',
        title: i18n.t('sideBar.order.createOrder'),
        path: routeOrder.CREATE_ORDER,
        component: CreateOrder,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'order',
            name: i18n.t('sideBar.order.orderTitle'),
            path: routeOrder.LIST_ORDER
          },
          {
            id: 'orderSub1',
            name: i18n.t('sideBar.order.createOrderBreadcrumb')
          }
        ]
      },
      {
        isShow: true,
        key: 'orderSub2',
        title: i18n.t('sideBar.order.listOrder'),
        path: routeOrder.LIST_ORDER,
        component: OrderManagement,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'order',
            name: i18n.t('sideBar.order.orderTitle')
            // path: routeOrder.LIST_ORDER
          },
          {
            id: 'orderSub2',
            name: i18n.t('sideBar.order.listOrderBreadcrumb')
          }
        ]
      },
      {
        key: 'orderDetail',
        path: routeOrder.ORDER_DETAIL,
        component: OrderDetail,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'order',
            name: i18n.t('sideBar.order.orderTitle'),
            path: routeOrder.LIST_ORDER
          },
          {
            id: 'orderSub2',
            name: i18n.t('sideBar.order.orderDetailBreadcrumb')
          }
        ]
      },
      {
        key: 'orderEdit',
        path: routeOrder.ORDER_EDIT,
        component: EditOrder,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'order',
            name: i18n.t('sideBar.order.orderTitle'),
            path: routeOrder.ORDER_EDIT
          },
          {
            id: 'orderSub2',
            name: i18n.t('sideBar.order.editOrderlBreadcrumb')
          }
        ]
      },
      {
        isShow: false,
        key: 'kt1',
        title: i18n.t('sideBar.katashiki.createKatashikiTitle'),
        path: routesName.KATASHIKI + '/create',
        component: CreateKatashiki,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'kt1',
            name: i18n.t('sideBar.katashiki.txtkatashiki'),
            path: routesName.KATASHIKI + '/listKatashiki'
          },
          {
            id: 'kt2',
            name: i18n.t('sideBar.katashiki.createkatashiki')
          }
        ]
      },
      {
        isShow: true,
        key: 'kt2',
        title: i18n.t('sideBar.katashiki.listKatashiki'),
        path: routesName.KATASHIKI + '/listKatashiki',
        component: ListKatashiki,
        role: ROLE_ALL,
        dividerLine: true,
        arrBreadScrumb: [
          {
            id: 'katashiki',
            name: i18n.t('sideBar.katashiki.txtkatashiki')
            // path: routesName.KATASHIKI + '/listKatashiki'
          },
          {
            id: 'kt2',
            name: i18n.t('sideBar.katashiki.titleListKatashiki')
          }
        ]
      }
    ]
  },
  // {
  //   id: 7,
  //   key: rootKey?.qrcord,
  //   title: i18n.t('sideBar.qrcode.QRcodeTitle'),
  //   icon: IconQrcodeBlack,
  //   activeIcon: IconQrcode,
  //   isShow: true,
  //   subMenu: [
  //     {
  //       isShow: true,
  //       key: 'qr1',
  //       title: i18n.t('sideBar.qrcode.QRcodeCreate'),
  //       path: routesName.QR_CODE + '/create',
  //       component: Qrcode,
  //       role: ROLE_CRUD,
  //       arrBreadScrumb: [
  //         {
  //           id: 'qr1',
  //           name: i18n.t('sideBar.qrcode.QRcodeTitle'),
  //           path: routesName.QR_CODE + '/list'
  //         },
  //         {
  //           id: 'qr2',
  //           name: i18n.t('sideBar.qrcode.QRcodeList')
  //         }
  //       ]
  //     },
  //     {
  //       isShow: true,
  //       key: 'qr2',
  //       title: i18n.t('sideBar.qrcode.QRcodeList'),
  //       path: routesName.QR_CODE + '/list',
  //       component: ListQrcode,
  //       role: ROLE_ALL,
  //       arrBreadScrumb: [
  //         {
  //           id: 'rqcode',
  //           name: i18n.t('sideBar.qrcode.QRcodeTitle'),
  //           path: routesName.QR_CODE + '/list'
  //         },
  //         {
  //           id: 'qr2',
  //           name: i18n.t('sideBar.qrcode.QRcodeList')
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    id: 6,
    key: rootKey?.admin,
    title: i18n.t('sideBar.adminManagement.txtAdminManagement'),
    icon: IconAdminManagement,
    activeIcon: IconAdminManagementActive,
    isShow: true,
    subMenu: [
      {
        isShow: true,
        key: 'adminSub1',
        title: i18n.t('sideBar.adminRegister'),
        path: routesName.ADMIN_MANAGEMENT,
        isCreate: true,
        component: AdminManagement,
        role: [ERoles.MASTER],
        arrBreadScrumb: [
          {
            id: 'admin',
            name: i18n.t('sideBar.adminManagement.txtAdminManagement')
          },
          {
            id: 'adminSub1',
            name: i18n.t('sideBar.adminRegister')
          }
        ]
      },
      {
        isShow: true,
        key: 'adminSub2',
        title: i18n.t('sideBar.listAdmin'),
        path: routesName.ADMIN_MANAGEMENT,
        component: AdminManagement,
        role: ROLE_ALL,
        arrBreadScrumb: [
          {
            id: 'admin',
            name: i18n.t('sideBar.adminManagement.txtAdminManagement')
          },
          {
            id: 'adminSub2',
            name: i18n.t('sideBar.listAdmin')
          }
        ]
      }
    ]
  },
  {
    id: 7,
    title: i18n.t('sideBar.changePass'),
    icon: null,
    activeIcon: null,
    role: ROLE_ALL,
    path: routesName.CHANGE_PASSWORD,
    component: ChangePasswordComponent,
    isShow: false,
  }
]

const childrenRoutes = [
  {
    id: 1,
    title: 'Group-detail-management',
    path: routesName.USER_MANAGEMENT.concat('/group/:id'),
    component: GroupDetailComponent
  },
  {
    id: 2,
    title: 'Sensor-management',
    path: routesSensorManagement.SENSOR_DETAIL.concat('/:id'),
    component: SensorDetailContainer
  },
  {
    id: 6,
    title: 'info-detail',
    path: routesInfoManagement.INFO_DETAIL.concat('/:id'),
    component: InfoDetail
  },
  {
    id: 7,
    title: 'info-edit',
    path: routesInfoManagement.INFO_EDIT.concat('/:id'),
    component: InfoEdit
  },
  {
    id: 11,
    title: 'calculator',
    path: routesDisplayManagement.CALCULATOR_SHEET.concat('/:id/:type'),
    component: CalculatorSheet
  },
  {
    id: 12,
    title: 'user-detail',
    path: routesName?.USER_MANAGEMENT.concat('/user/:id'),
    component: UserDetailContainer
  },
  /* {
    id: 15,
    title: 'edit-formula',
    path: routesDisplayManagement.EDIT_FORMULA.concat('/:id'),
    component: EditMultiSettings,
    isShow: true
  }, */

  {
    id: 13,
    title: 'edit-formula',
    path: routesDisplayManagement.EDIT_FORMULA.concat('/:id'),
    component: EditFormula,
    isShow: true
  },
  /* {
    id: 16,
    title: 'edit-individual',
    path: routesDisplayManagement.EDIT_INDIVIDUAL.concat('/:id'),
    component: EditIndividualSettings,
    isShow: true
  }, */
  /* {
    id: 14,
    title: 'detail-qrcode',
    path: routesName.QR_CODE + '/list/:id',

    component: DetailQrcode,
    isShow: true
  },
  {
    id: 15,
    title: 'edit-qrcode',
    path: routesName.QR_CODE + '/create/:id',
    component: UpdateQrcode,
    isShow: true
  }, */
  {
    id: 18,
    title: 'katashiki-detail',
    path: routesName.KATASHIKI + '/detail',
    component: KatashikiDetail,
    isShow: true
  },
  {
    id: 16,
    title: 'access-denied',
    path: routesName.ACCESSDENIED,
    component: AccessDeniedComponent,
    isShow: false
  },
  {
    id: 17,
    title: 'not-found',
    path: '*',
    component: NotFoundComponent,
    isShow: false
  }
]
const regexDashboardManagement = /(admin\/dashboard)/
const regexSensorManagement = /(admin\/sensor-management)/
const regexInfoManagement = /(admin\/infomation-management)/
const regexUserManagement = /(admin\/user-management)/
const regexDisplayManagement = /(admin\/display-management)/
const regexAdminManagement = /(admin\/administrator-management)/
const regexQrcode = /(admin\/qrcode)/
const regexCustomer = /(admin\/customer)/
const regexKatashiki = /(admin\/katashiki)/
const regexSerialNo = /(admin)\/serial-no/
const regexOrder = /(admin)\/order/

const checkRootKey = (path: string) => {
  let key = ''
  const list = [
    {
      name: 'dashboard-management',
      regex: regexDashboardManagement.test(path),
      parentId: rootKey?.dashboard
    },
    {
      name: 'user-management',
      regex: regexUserManagement.test(path),
      parentId: rootKey?.user
    },
    {
      name: 'sensor-management',
      regex: regexSensorManagement.test(path),
      parentId: rootKey?.sensor
    },
    {
      name: 'display-management',
      regex: regexDisplayManagement.test(path),
      parentId: rootKey?.display
    },
    {
      name: 'infor-management',
      regex: regexInfoManagement.test(path),
      parentId: rootKey?.information
    },
    {
      name: 'administrator-management',
      regex: regexAdminManagement.test(path),
      parentId: rootKey?.admin
    },
    {
      name: 'qrcode',
      regex: regexQrcode.test(path),
      parentId: rootKey?.qrcord
    },
    {
      name: 'customer',
      regex: regexCustomer.test(path),
      parentId: rootKey?.customer
    },
    {
      name: 'katashiki' || 'serial-no' || 'order',
      regex: regexKatashiki.test(path) || regexSerialNo.test(path) || regexOrder.test(path),
      parentId: rootKey?.katashiki
    }
  ]
  list.forEach((item) => {
    if (item?.regex) {
      key = item?.parentId
      return
    }
  })
  return key
}
const accessDeniedRoleView = [
  routesUserManagement.USER_CREATE,
  routesInfoManagement.INFO_CREATE,
  routesName.ADMIN_MANAGEMENT + '/create'
  // routesName.QR_CODE + '/create'
]
const accessDeniedRoleCrud = [routesName.ADMIN_MANAGEMENT + '/create']
const arrPathFormulaCopy = [
  /* ADMIN_ROUTE.concat(routesDisplayManagement?.MULTI_SETTINGS),
  ADMIN_ROUTE.concat(routesDisplayManagement?.INDIVIDUAL_SETTINGS) */
  ADMIN_ROUTE.concat(routesDisplayManagement.CREATE_FORMULA)
]
const arrBreadScrumbFormulaCopy = [
  {
    id: 'display',
    name: i18n.t('sideBar.displayManagement.txtDisplayManagement'),
    path: routesName?.DISPLAY_MANAGEMENT
  },
  {
    id: 'displaySub2',
    name: i18n.t('displayManagement.copy.formula')
  }
]
export {
  routes,
  childrenRoutes,
  routesSensorManagement,
  regexSensorManagement,
  routesInfoManagement,
  routeOrder,
  routeSerial,
  routerChangePass,
  regexInfoManagement,
  regexUserManagement,
  routesDisplayManagement,
  checkRootKey,
  accessDeniedRoleView,
  accessDeniedRoleCrud,
  arrPathFormulaCopy,
  arrBreadScrumbFormulaCopy
}
