import {
  ICreateAlert,
  IDetailAlert,
  IListAlert,
  IUpdateAlert
} from 'services/params/notification/INotificationParams'
import { ENotificationAction } from './ENotificationsAction'
import {
  ICreateAlertAction,
  IGetListAlertAction,
  IUpdateAlertAction,
  IDetailAlertAction
} from './notificationsTypes'

export const getListAlertAction = (
  params: IListAlert,
  callBack: (data: any) => void,
  isLoading: boolean
): IGetListAlertAction => ({
  type: ENotificationAction.GET_LIST_ALERT_SYSTEM,
  params,
  callBack,
  isLoading
})

export const createAlertAction = (
  params: ICreateAlert,
  callBack: (data: any) => void,
  isLoading: boolean
): ICreateAlertAction => ({
  type: ENotificationAction.CREATE_ALERT_SYSTEM,
  params,
  callBack,
  isLoading
})
export const updateAlertAction = (
  params: IUpdateAlert,
  callBack: (data: any) => void,
  isLoading: boolean
): IUpdateAlertAction => ({
  type: ENotificationAction.UPDATE_ALERT_SYSTEM,
  params,
  callBack,
  isLoading
})
export const detailAlertAction = (
  params: IDetailAlert,
  callBack: (data: any) => void,
  isLoading: boolean
): IDetailAlertAction => ({
  type: ENotificationAction.DETAIL_ALERT_SYSTEM,
  params,
  callBack,
  isLoading
})
