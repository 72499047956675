import { getDataDashboardRequest } from 'services/requests/dashboard/dashboardRequest'
import { configRequest } from '..'

class DashboardApis {
  getDataDashboard = () => {
    const requests = getDataDashboardRequest()
    return configRequest(requests)
  }
}

export const DashboardApi = new DashboardApis()
