import { IQrcode } from 'interfaces/qrcode/IQrcode'
import { ICreateQrcode } from 'redux/actions/qrcode/qrcodeTypes'
import {
  createQrcode,
  editQrcode,
  getDetailQrcode,
  getListQrcode
} from 'services/requests/qrcode/qrcodeRequest'
import { configRequest } from '..'

class qrcodeApis {
  createQr = (params: ICreateQrcode) => {
    const requests = createQrcode(params)
    return configRequest(requests)
  }
  editQr = (params: IQrcode) => {
    const requests = editQrcode(params)
    return configRequest(requests)
  }
  getListQr = () => {
    const requests = getListQrcode()
    return configRequest(requests)
  }
  detailQr = (id: string) => {
    const requests = getDetailQrcode(id)
    return configRequest(requests)
  }
}

export const qrcodeApi = new qrcodeApis()
