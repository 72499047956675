import i18n from 'configs/i18n'
import { validCreateUser } from './validatorUser'

const formRegister = [
  {
    label: i18n.t('modal.userManagement.create.lable.userName'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.userName'),
    name: 'username',
    rules: validCreateUser.username,
    type: 'text',
    maxLength: 64,
    isRequired: true,
    autoComplete: 'new-password'
  },
  {
    label: i18n.t('modal.userManagement.create.lable.email'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.email'),
    name: 'email',
    rules: validCreateUser.email,
    type: 'text',
    maxLength: 255,
    isRequired: true,
    autoComplete: 'new-password'
  },
  {
    label: i18n.t('modal.userManagement.create.lable.password'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.password'),
    name: 'password',
    rules: validCreateUser.password,
    type: 'password',
    maxLength: 32,
    isRequired: true,
    autoComplete: 'new-password'
  },
  {
    label: i18n.t('modal.userManagement.create.lable.passwordConfirm'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.passwordConfirm'),
    name: 'confirm',
    rules: validCreateUser.confirmPassword,
    type: 'confirmPassword',
    maxLength: 32,
    isRequired: true,
    autoComplete: 'new-password'
  }
]
const formEdit = [
  {
    label: i18n.t('modal.userManagement.create.lable.userName'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.userName'),
    name: 'username',
    rules: validCreateUser.username,
    type: 'text',
    maxLength: 64,
    isRequired: true
  },
  {
    label: i18n.t('modal.userManagement.create.lable.email'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.email'),
    name: 'email',
    rules: validCreateUser.email,
    type: 'text',
    maxLength: 255,
    isRequired: true
  },
  {
    label: i18n.t('modal.userManagement.create.lable.password'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.password'),
    name: 'password',
    rules: validCreateUser.password,
    type: 'password',
    maxLength: 32,
    isRequired: true
  },
  {
    label: i18n.t('modal.userManagement.create.lable.passwordConfirm'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.passwordConfirm'),
    name: 'confirm',
    rules: validCreateUser.confirmPassword,
    type: 'confirmPassword',
    maxLength: 32,
    isRequired: true
  }
]
export { formRegister, formEdit }
