import { Col, Row, Form } from 'antd'
import { IconDeleteModal, IconExportCsv, IconResendMail, IconSendMail } from 'assets'
import VButton from 'components/button/VButton'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'
import ExportCsvComponent from 'components/common/exportCsv/ExportCsvComponent'
import InputComponent from 'components/common/input/InputComponent'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import SelectComponent from 'components/common/select/SelectComponent'
import TableComponent from 'components/common/table/TableComponent'
import ModalComponent from 'components/modal/ModalComponent'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'styles/Customer.scss'
import { headerCsvCustomers } from '../sensor/csv'
import { defaultColumns } from './defaultColumns'
import { useDispatch } from 'react-redux'
import {
  deleteCustomerAction,
  downloadCustomerCsvAction,
  getCustomerMasterDataAction,
  listCustomerAction,
  resendCustomerVerifyEmailAction
} from 'redux/actions/customer/customerAction'
import { IMasterDataType } from './createCustomer'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import moment from 'moment'
import 'moment/locale/ja'
import { useHistory } from 'react-router-dom'
import { ADMIN_ROUTE, routesCustomerManagement } from 'views/routes/routes'
import ModalCheckSuccessComponent from 'components/modal/ModalCheckSuccessComponent'
import { showTotalRecord } from 'utils/Functions'
import { concat } from 'lodash'
import Collapsed from '../serialNo/serialNoManagement/components/collapsed'

interface IDataType {
  key: any
  id: number
  customerName: string
  serviceStartDate: string
  serviceEndDate: string
  customerStatus: string
  isExpired: boolean
  sales: string
  dealers: string
  cooporateKey: string
}

const getData = (current: number, pageSize: number, data: IDataType[]) => {
  return data.slice((current - 1) * pageSize, current * pageSize)
}

function CustomerManagement() {
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [dataTable, setDataTable] = useState<IDataType[]>([])
  const [dataExpired, setDataExpired] = useState<any[]>([])
  const [dataExport, setDataExport] = useState<any[]>([])
  const [selectDealer, setSelectDealer] = useState<any>()
  const [dealersNameList, setDealersNameList] = useState<any[]>([])
  const [selectSale, setSelectSale] = useState<any>()
  const [customerId, setCustomerId] = useState<any>()
  const [salesNameList, setSalesNameList] = useState<any[]>([])
  const [isDownload, setIsDownload] = useState<boolean>(false)
  const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const columns = defaultColumns(
    (record: any) => handleOnClickDetail(record),
    (record: any) => handleOnClickDelete(record),
    current,
    pageSize
  )
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  const [modalSuccess, setModalSuccess] = useState({
    isModalVisible: false,
    content: t('modal.customer.delSuccess')
  })
  const showSuccess = (text: string) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text
    }
    dispatch(SuccessAction({ ...stateSuccess }))
    const param = {
      timeZone: getTimeZone,
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    getListDisplay(param, false, false)
  }

  // finish submit form control
  const onFinish = async () => {
    setIsSearch(true)
    const param = {
      customerName: form.getFieldValue('customerName')
        ? form.getFieldValue('customerName').trim()
        : '',
      customerKey: form.getFieldValue('cooporateKey')
        ? form.getFieldValue('cooporateKey').trim()
        : '',
      saleId: selectSale,
      dealerId: selectDealer,
      timeZone: getTimeZone,
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    getListDisplay(param, true, true)
  }

  // delete customer
  const handleOnClickDelete = (record: any) => {
    setCustomerId(parseInt(record?.key, 10))
    setModal({
      ...modal,
      isVisible: true,
      type: 'delete-customer',
      isComponent: false,
      img: IconDeleteModal,
      btnSubmit: t('modal.btnDelete'),
      content: t('modal.customer.customerDeleteContent'),
      hideBtnFooter: false
    })
  }

  const deleteCustomer = async () => {
    const params = { id: customerId }
    dispatch(
      deleteCustomerAction(
        params,
        (data) => {
          if (data?.result?.id) {
            const dataSource = [...dataTable]
            const listCustomer = dataSource?.filter((item) => parseInt(item.key, 10) !== customerId)
            setDataTable(listCustomer)
            const totalPageChange = Math.ceil(listCustomer?.length / pageSize)
            if (totalPageChange === current - 1) {
              setCurrent(totalPageChange)
            }
            setModal({ ...modal, isVisible: false })
            dispatch(SetLoadingAction(false))
            setModalSuccess({
              ...modalSuccess,
              isModalVisible: true
            })
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }
  // Resend mail
  // For specify customer
  const onClickResendMailForOne = (id: any) => {
    setCustomerId(id)
    setModal({
      ...modal,
      isVisible: true,
      type: 'resend-mail-one',
      isComponent: false,
      img: IconResendMail,
      btnSubmit: t('modal.resendMail.btnSubmit'),
      content: t('modal.resendMail.content'),
      hideBtnFooter: false
    })
  }
  const resendMailForOne = () => {
    const params = {
      customerId: customerId
    }
    dispatch(
      resendCustomerVerifyEmailAction(
        { ...params },
        (data) => {
          if (data && data?.error) {
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
            showSuccess(t('modal.resendMail.sendMailSuccess'))
          }
        },
        true
      )
    )
  }
  // For all customer
  const onClickResendMailForAll = () => {
    setModal({
      ...modal,
      isVisible: true,
      type: 'resend-mail-all',
      isComponent: false,
      img: IconResendMail,
      btnSubmit: t('modal.resendMail.btnSubmit'),
      content: t('modal.resendMail.content'),
      hideBtnFooter: false
    })
  }
  const resendMailForAll = () => {
    const params = {
      customerId: 0
    }
    dispatch(
      resendCustomerVerifyEmailAction(
        { ...params },
        (data) => {
          if (data && data?.error) {
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
            showSuccess(t('modal.resendMail.sendMailSuccess'))
          }
        },
        true
      )
    )
  }
  // go to detail page
  const handleOnClickDetail = (record: any) => {
    const url = ADMIN_ROUTE.concat(
      routesCustomerManagement.CUSTOMER_DETAIL.replace(':id', record?.key)
    )
    history.push({ pathname: url })
  }

  useEffect(() => {
    handleGetListSales()
    handleGetListDealers()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const param = {
      timeZone: getTimeZone,
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    getListDisplay(param, true, false)

    // eslint-disable-next-line
  }, [dispatch])

  const convertDataTable = (data: any) => {
    moment.locale('ja')
    data = data.map((item: any, index: number) => {
      return {
        key: item?.id,
        id: index + 1,
        customerName: item?.customerName,
        serviceStartDate: moment(item?.startedAt).format('YYYY/MM/DD'),
        serviceEndDate: item?.endedAt ? moment(item?.endedAt).format('YYYY/MM/DD') : '',
        customerStatus: getStatus(
          item?.sendAt,
          item?.isActive,
          item?.expired,
          parseInt(item?.id, 10)
        ),
        isExpired: item?.expired,
        sales: item?.sale?.nameJp,
        dealers: item?.dealer?.nameJp,
        cooporateKey: item?.customerKey
      }
    })
    return data
  }
  const dataIsExpired = (data: any) => {
    data = data.map((item: any, index: number) => {
      return {
        id: item?.id,
        isActive: item?.isActive,
        isExpired: item?.expired
      }
    })
    return data
  }

  const getStatus = (sendAt: string, isActive: boolean, expired: boolean, customerId?: any) => {
    if (sendAt === null) {
      return <span>{t('customerScreen.customerActiveStatus.mailNotSend')}</span>
    } else {
      if (isActive) {
        return <span>{t('customerScreen.customerActiveStatus.actived')}</span>
      } else {
        if (expired) {
          return (
            <div className='send-mail-alert'>
              {t('customerScreen.customerActiveStatus.expired')}
              <p>&nbsp;</p>
              <span onClick={() => onClickResendMailForOne(customerId)} className='pointer'>
                {t('customerScreen.customerActiveStatus.resendMailWhenExpired')}
              </span>
            </div>
          )
        }
      }
      return <span>{t('customerScreen.customerActiveStatus.mailSendButNotActive')}</span>
    }
  }

  const getListDisplay = async (param: any, pageFirst: boolean, pageSearch: boolean) => {
    await dispatch(
      listCustomerAction(param, true, (data) => {
        if (data?.result) {
          const dataDisplayConvert = convertDataTable(data?.result?.record)
          const checkDataExpired = dataIsExpired(data?.result?.record)
          if (pageFirst) {
            setCurrent(1)
          }
          if (pageSearch) {
            setIsSearch(true)
          }
          setDataTable(dataDisplayConvert)
          if (checkDataExpired) {
            const dataCheck: any = checkDataExpired.filter(
              (item: any, index: any) => item?.isExpired === true
            )
            setDataExpired(dataCheck)
          }
          dispatch(SetLoadingAction(false))
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
  }
  // get dealer & sale dropdown list
  const handleGetListSales = () => {
    const params = {
      typeDataMaster: 0
    }
    dispatch(
      getCustomerMasterDataAction(
        { ...params },
        (data) => {
          if (data && data?.result) {
            const listSales: any = []
            data.result.record = data.result.record.map(({ id, nameJp }: IMasterDataType) => {
              return listSales.push({
                id: id,
                name: nameJp
              })
            })
            setSalesNameList(listSales)
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }
  const handleGetListDealers = () => {
    const params = {
      typeDataMaster: 1
    }
    dispatch(
      getCustomerMasterDataAction(
        { ...params },
        (data) => {
          if (data && data?.result) {
            const listDealers: any = []
            data.result.record = data.result.record.map(({ id, nameJp }: IMasterDataType) => {
              return listDealers.push({
                id: id,
                name: nameJp
              })
            })
            setDealersNameList(listDealers)
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }
  // on select dealer/sale
  const handleOnChangeSales = (sale: any) => {
    setSelectSale(sale)
  }
  const handleOnChangeDealers = (dealer: any) => {
    setSelectDealer(dealer)
  }
  // change page
  const handleOnChangePageSize = (size: number) => {
    const totalPageChange = Math.ceil(dataTable?.length / size)
    if (current > totalPageChange) {
      setCurrent(totalPageChange)
    }
    setPageSize(size)
  }
  const handleOnChangePage = (pageChange: number) => {
    setCurrent(pageChange)
  }
  // go to create customer page
  const goToReg = () => {
    history.push(ADMIN_ROUTE.concat(routesCustomerManagement.CUSTOMER_CREATE))
  }

  const convertDataExport = (data: any) => {
    moment.locale('ja')
    data = data.map((item: any, index: number) => {
      return {
        customerKey: item?.customerKey,
        customerName: item?.customerName,
        dealer: item?.dealer,
        email: item?.email,
        endedAt: item?.endedAt,
        sale: item?.sale,
        startedAt: item?.startedAt,
        statusUser: item?.statusUser,
        totalOrder: item?.totalOrder,
        totalSensor: item?.totalSensor,
        totalUser: item?.totalUser,
        zipCode: item?.zipCode,
        address: item?.address1 ? concat(item?.address1 + '　' + item?.address2) : item?.address2
      }
    })
    return data
  }

  const getDataExport = () => {
    // setIsDownload(true)
    const params = {
      customerName: form.getFieldValue('customerName')
        ? form.getFieldValue('customerName').trim()
        : '',
      customerKey: form.getFieldValue('cooporateKey')
        ? form.getFieldValue('cooporateKey').trim()
        : '',
      saleId: selectSale,
      dealerId: selectDealer,
      timeZone: getTimeZone,
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    dispatch(
      downloadCustomerCsvAction(
        { ...params },
        async (data) => {
          if (data && data?.error?.code === 400) {
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
            const convertData = await convertDataExport(data?.result)
            setDataExport(convertData)
            setIsDownload(true)
          }
        },
        true
      )
    )
  }

  const handleExportCsv = () => {
    getDataExport()
    setIsDownload(false)
  }

  const onClearSearch = () => {
    setIsSearch(false)
    setSelectSale(null)
    setSelectDealer(null)
    setIsDownload(false)
    form.resetFields()
    const param = {
      timeZone: getTimeZone,
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    getListDisplay(param, true, false)
  }
  // modal case
  async function handleOnSubmitModal() {
    // check modal?.type dispatch action
    switch (modal?.type) {
      case 'delete-customer':
        deleteCustomer()
        break
      case 'resend-mail-one':
        resendMailForOne()
        break
      case 'resend-mail-all':
        resendMailForAll()
        break
      default:
        break
    }
  }

  return (
    <>
      <div className='bgrffffff'>
        <div className='customer-management pd_16x_0x'>
          <div className='ss-search-email mg_0_16x'>
            <VButton
              onClick={goToReg}
              title={t('userManagement.txtCreatNew')}
              htmlType='button'
              style={styleBtnSearch}
            />
          </div>
        </div>
      </div>
      <Collapsed isBottomSpace={false}>
        <div  style={{ marginTop: 16 }}>
        <div className='customer-management'>
          <div className='ss-search-email'>
            <Form form={form} onFinish={onFinish}>
              <Row gutter={24} style={{ marginBottom: -16 }}>
                <Col span={12}>
                  <InputComponent
                    label={t('customerScreen.customerName')}
                    name='customerName'
                    type='text'
                    value={form.getFieldValue('customerName')}
                    maxLength={64}
                  />
                </Col>
                <Col span={12}>
                  <Form.Item name='department'>
                    <SelectComponent
                      disableAll={true}
                      lable={t('customerScreen.sales')}
                      styleLabel={{ fontSize: 12 }}
                      dataOption={salesNameList}
                      defaultOption={form.getFieldValue('department')}
                      onChangeSelect={handleOnChangeSales}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <InputComponent
                    label={t('customerScreen.corporateKey')}
                    name='cooporateKey'
                    type='text'
                    value={form.getFieldValue('cooporateKey')}
                    maxLength={64}
                  />
                </Col>
                <Col span={12}>
                  <Form.Item name='agency'>
                    <SelectComponent
                      disableAll={true}
                      lable={t('customerScreen.dealers')}
                      styleLabel={{ fontSize: 12 }}
                      styleSelect={{ marginBottom: -16 }}
                      dataOption={dealersNameList}
                      defaultOption={form.getFieldValue('agency')}
                      onChangeSelect={handleOnChangeDealers}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className='group-btn'>
                <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                  {() => (
                    <VButton
                      title={t('sensorManagementScreen.btnSubmit')}
                      htmlType='submit'
                      style={styleBtnSearch}
                      type='text'
                    />
                  )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <VButton
                    title={t('modal.btnCancel')}
                    htmlType='button'
                    onClick={onClearSearch}
                    style={styleBtnClear}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
      </Collapsed>
      <div className='bgrffffff' style={{ marginTop: 16 }}>
        <div className='customer-management pd_16x_0x'>
          <div className='ss-search-email mg_0_16x'>
            <div className='d-flex justify-content-space-between align-items-center '>
              <div
                style={{ alignItems: 'center' }}
                className='d-flex font-w-bold pd_16x_0x total-record'>
                {dataTable.length > 0 ? (
                  <>
                    <div className='bulk-send-mail pointer' onClick={handleExportCsv}>
                      <div className='d-flex send-mail pointer' style={{ fontSize: 12 }}>
                        <img className='img-w-h_18x' src={IconExportCsv} alt='export-csv' />
                        <span>{t('sensorManagementScreen.csv')}</span>
                      </div>
                    </div>
                    <div style={{ display: 'none' }}>
                      <ExportCsvComponent
                        headers={headerCsvCustomers}
                        data={dataExport}
                        fileName='顧客一覧'
                        isDisabled={false}
                        isDownload={isDownload}
                      />
                    </div>
                    {dataExpired?.length > 0 ? (
                      <div className='bulk-send-mail pointer' onClick={onClickResendMailForAll}>
                        <div className='d-flex send-mail pointer' style={{ fontSize: 12 }}>
                          <img className='img-w-h_18x' src={IconSendMail} alt='export-csv' />
                          <span>{t('customerScreen.btnSendMail')}</span>
                        </div>
                      </div>
                    ) : (
                      <div className='bulk-send-mail disable-btn'>
                        <div className='d-flex send-mail' style={{ fontSize: 12 }}>
                          <img className='img-w-h_18x' src={IconSendMail} alt='export-csv' />
                          <span>{t('customerScreen.btnSendMail')}</span>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className='bulk-send-mail disable-btn'>
                      <div className='d-flex send-mail' style={{ fontSize: 12 }}>
                        <img className='img-w-h_18x' src={IconExportCsv} alt='export-csv' />
                        <span>{t('sensorManagementScreen.csv')}</span>
                      </div>
                    </div>
                    <div className='bulk-send-mail disable-btn'>
                      <div className='d-flex send-mail' style={{ fontSize: 12 }}>
                        <img className='img-w-h_18x' src={IconSendMail} alt='export-csv' />
                        <span>{t('customerScreen.btnSendMail')}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className='d-flex justify-content-flex-end align-items-center mg-pagination'>
                <div className='sensor-pagination'>
                  <div className='d-flex justify-content-flex-end font-w-bold pd_16x_0x total-record'>
                    {showTotalRecord(dataTable?.length)}
                  </div>
                </div>
                <PaginationComponent
                  onChangePageSize={(size) => handleOnChangePageSize(size)}
                  total={dataTable?.length}
                  onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                  pageSize={pageSize}
                  isShowSizeChanger={false}
                  currentpage={current}
                />
              </div>
            </div>
            <div className='custom-table'>
              <TableComponent
                isSearch={isSearch}
                columns={columns}
                dataSource={getData(current, pageSize, dataTable)}
              />
            </div>
            <div className='mg-pagination'>
              <PaginationComponent
                onChangePageSize={(size) => handleOnChangePageSize(size)}
                total={dataTable?.length}
                onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                pageSize={pageSize}
                isShowSizeChanger={true}
                currentpage={current}
              />
            </div>
            <div className='btn-return text-align-center'>
              <ButtonReturnComponent />
            </div>
          </div>
        </div>
      </div>
      {modal?.isVisible ? (
        <ModalComponent
          img={modal?.img}
          isVisible={modal?.isVisible}
          content={modal?.content}
          component={modal?.component}
          btnSubmit={modal?.btnSubmit}
          btnCancel={modal?.btnCancel}
          onCancelModal={() => setModal({ ...modal, isVisible: false })}
          onSubmitModal={handleOnSubmitModal}
          hideBtnFooter={modal?.hideBtnFooter}
        />
      ) : null}
      {modalSuccess?.isModalVisible ? (
        <ModalCheckSuccessComponent
          isVisible={modalSuccess?.isModalVisible}
          content={modalSuccess?.content}
          handleClickCancel={() =>
            setModalSuccess({
              ...modalSuccess,
              isModalVisible: false
            })
          }
        />
      ) : null}
    </>
  )
}
const styleBtnSearch = {
  height: 38,
  width: 76,
  backgroundColor: '#d7000f',
  color: '#fff',
  borderColor: ' #d7000f',
  marginRight: 16
}
const styleBtnClear = {
  height: 38,
  width: 116,
  backgroundColor: '#fff',
  color: ' #d7000f',
  border: 'solid 1px #e3242b'
}
export default CustomerManagement
