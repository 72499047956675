export enum EQrcodeActions {
  CREATE_QRCODE = 'CREATE_QRCODE',
  GET_LIST_QR = 'GET_LIST_QR',
  SET_LIST_QR = 'SET_LIST_QR',
  SET_LIST_SEARCH = 'SET_LIST_SEARCH',
  EDIT_QR = 'EDIT_QR',
  DELETE_QR = 'DELETE_QR',
  GET_DETAIL_QR = 'GET_DETAIL_QR',
  SET_DETAIL_QR = 'SET_DETAIL_QR'
}
