import { EBlank } from 'interfaces/enums/EBlank'

const convertListAttribute = (data: any) => {
  data = data?.map((item: any) => {
    return {
      id: item?.id,
      attributeId: item?.attributeId,
      attributeIndex: item?.attributeIndex,
      attributeKey: item?.attributeKey,
      unit: item?.unit,
      dataKeyPrefix: item?.dataKeyPrefix,
      attributeName: item?.attributeTranslate[0] ? item?.attributeTranslate[0]?.attributeName : '',
      scaleValue: item?.scaleValue,
      conversionFormula: item?.conversionFormulaPush
        ? item?.conversionFormulaPush
        : item?.conversionFormula === '-' || item?.conversionFormula === EBlank.FORMULA
        ? keyFormulaDefault
        : item?.conversionFormula,
      statusShow: item?.statusShow || false,
      mode: item?.mode
    }
  })
  return data
}
const convertListAttributeAdd = (data: any, total: number, totalAttTv = 0) => {
  data = data?.map((item: any, index: number) => {
    return {
      attributeId: (index + 1 + total).toString(),
      attributeIndex: index + 1 + totalAttTv,
      attributeKey: item?.positionID + (index + 1 + totalAttTv),
      attributeName: item?.settingTarget,
      unit: item?.sensorUnit,
      dataKeyPrefix: item?.positionID,
      scaleValue: item?.sensorIndex,
      statusShow: item?.statusShow || false,
      conversionFormula: item?.conversionFormulaPush
        ? item?.conversionFormulaPush
        : item?.conversionFormula === '-' || item?.conversionFormula === EBlank.FORMULA
        ? keyFormulaDefault
        : item?.conversionFormula,
      mode: item?.mode
    }
  })
  return data
}

export const keyFormulaDefault = '(:ecv * (:rawDataValue - :minInputValue) + :minConversionValue)'

export { convertListAttribute, convertListAttributeAdd }
