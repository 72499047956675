import { IRequest } from 'interfaces/IRequest'
import { Methods } from 'services/api/EMethod'
import {
  IGetListEmailAlert,
  IGetListSensorByUser,
  IUpdateEmailAlert,
  IUpdateUser,
  IUserDetail
} from 'services/params/userDetail/userDetail'

export const userDetailRequest = (param: IUserDetail): IRequest<IUserDetail> => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.USER_DETAIL,
    params: param
  }
}
export const getListEmailAlertRequest = (
  param: IGetListEmailAlert
): IRequest<IGetListEmailAlert> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_LIST_EMAIL_ALERT,
    params: param
  }
}
export const getListSensorByUserRequest = (
  param: IGetListSensorByUser
): IRequest<IGetListSensorByUser> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_LIST_SENSOR_BY_USER,
    params: param
  }
}
export const updateEmailAlertRequest = (param: IUpdateEmailAlert): IRequest<IUpdateEmailAlert> => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.UPDATE_EMAIL_ALERT,
    params: param
  }
}
export const updateUserRequest = (param: IUpdateUser): IRequest<IUpdateUser> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.USER_EDIT,
    params: param
  }
}
