import { useState } from 'react'
import 'styles/SerialNoManagement.scss'

type Props = {
  title: string,
  onSort: (method: 'ASC' | 'DESC') => void,
}

const Sortable = ({ onSort, title }: Props) => {
  const [method, setMethod] = useState<'ASC' | 'DESC'>('ASC');
  const handleOnSort = async () => {
    document.querySelector(".serial-no__column-sortable")?.classList.toggle("serial-no__column-sortable-desc");
    await setMethod(method === 'ASC' ? 'DESC' : 'ASC');
    if (onSort) { onSort(method === 'ASC' ? 'DESC' : 'ASC'); }
  }
  return (
    <button onClick={() => handleOnSort()} className='serial-no__column-sortable'>
    <div className="serial-no__column-sortable-title">{title}</div>
    <div className="serial-no__column-sortable-icon">▼</div>
  </button>
  )
}

export default Sortable