import i18n from 'configs/i18n'
import { EMaxLength } from 'interfaces/enums/EMaxLength'
import { EMinLength } from 'interfaces/enums/EMinLength'
import { regexEmail, regexPassword } from 'utils/Functions'
const validLogin = {
  email: [
    {
      required: true,
      message: i18n.t('errors.message.FRE00001', {
        0: i18n.t('modal.userManagement.create.lable.email')
      })
    },
    {
      pattern: new RegExp(regexEmail),
      message: i18n.t('errors.message.FRE00002', {
        0: i18n.t('modal.userManagement.create.lable.email')
      })
    },
    {
      max: EMaxLength.max255,
      message: '入力長を超えました '
    }
  ],
  password: [
    {
      required: true,
      message: i18n.t('errors.message.FRE00001', {
        0: i18n.t('modal.userManagement.create.lable.password')
      })
    },
    {
      min: EMinLength.min6,
      message: i18n.t('errors.message.FRE00006', {
        0: EMinLength.min6
      })
    },
    {
      max: EMaxLength.max32,
      message: '入力長を超えました '
    },
    {
      pattern: new RegExp(regexPassword),
      message: i18n.t('errors.message.FRE00002', {
        0: i18n.t('modal.userManagement.create.lable.password')
      })
    }
  ]
}
export { validLogin }
