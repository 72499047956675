import { Input, Form } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { CSSProperties } from 'react'

type IconProps = {
  icon?: string
}

const IconPrefix = ({ icon }: IconProps) => {
  return <img src={icon} style={{ width: 24, height: 24 }} alt='' />
}

type Props = {
  icon?: string
  placeholder?: string
  type?: string
  size?: SizeType
  style?: CSSProperties
  name: string
  rules?: any
  maxLength: number
  label?: string
  isReq?: boolean
}

const InputPrefix = ({
  icon,
  placeholder,
  type,
  size = 'small',
  style,
  name,
  rules,
  maxLength,
  label,
  isReq
}: Props) => {
  return (
    <div>
      <div className='label-mb font-w-bold'>
        {label}
        {isReq && <span style={{ color: '#DD0000' }}>必須</span>}
      </div>
      <Form.Item name={name} rules={rules}>
        {type !== 'password' ? (
          <Input
            maxLength={maxLength}
            size={size}
            placeholder={placeholder}
            prefix={<IconPrefix icon={icon} />}
            style={{ ...styles.input, ...style }}
          />
        ) : (
          <Input.Password
            maxLength={maxLength}
            size={size}
            placeholder={placeholder}
            prefix={<IconPrefix icon={icon} />}
            style={{ ...styles.input, ...style }}
          />
        )}
      </Form.Item>
    </div>
  )
}

const styles = {
  input: {
    padding: 17
  }
}

export default InputPrefix
