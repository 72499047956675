import { EAuditLogActions } from './../../actions/auditLog/EAuditLogAction';
import { AuditLogApi } from './../../../services/api/auditLog/auditLogApi';
import { AxiosResponse } from "axios"
import i18n from "configs/i18n"
import { DELAY } from "interfaces/enums/EDelay"
import { IData } from "interfaces/IData"
import { INotification } from "interfaces/INotification"
import { IDetailAuditLogAction } from "redux/actions/auditLog/auditLogTypes"
import { all, call, delay, put, takeEvery } from 'redux-saga/effects';
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction';

function* detailAuditLogSaga(action: IDetailAuditLogAction) {
   let err: any = null
   if (action.isLoading) {
     yield put(SetLoadingAction(true))
   }
   try {
     const response: AxiosResponse<IData<any>> = yield call(
       AuditLogApi.detailAuditLog,
       action.params
     )
     if (response?.data?.error) {
       err = response?.data?.error
       action.callBack(err)
     } else {
       yield delay(DELAY.DELAYSUCCESS)
       action.callBack(response?.data)
     }
   } catch (error) {
     err = error
     return Promise.reject(error)
   } finally {
     if (err) {
       yield delay(DELAY.DELAYERR)
       const noti: INotification = {
         title: i18n.t('notification.error'),
         message: err?.message,
         isLoading: action.isLoading,
         notiType: err?.errorType,
         code: err?.code
       }
       yield put(SetNotificationAction(noti))
       yield put(SetLoadingAction(false))
     }
   }
 }

 export default function* auditLogSaga() {
   yield all([
     takeEvery(EAuditLogActions.GET_DETAIL_AUDIT_LOG, detailAuditLogSaga),
   ]);
 }
 