import { Link } from 'react-router-dom'

interface IPropsItem {
  label: string
  value: string
  isHref?: boolean
  href?: string
}
function ItemComponent({ label, value, isHref, href }: IPropsItem) {
  return (
    <>
      <div className='mb-10x'>
        <div className='d-flex'>
          <div className='font-w-bold mr-10x'>{label}</div>
          <div className={isHref ? 'custom-a cls_3EACFF' : ''}>
            {isHref ? <Link to={href ?? ''}>{value}</Link> : value}
          </div>
        </div>
      </div>
    </>
  )
}
export default ItemComponent
