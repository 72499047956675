import i18n from 'configs/i18n'
import { EMaxLength } from 'interfaces/enums/EMaxLength'
import { EMinLength } from 'interfaces/enums/EMinLength'
import { regexEmail, regexPassword } from 'utils/Functions'
const validCreateUser = {
  username: [
    () => ({
      validator(_: object, value: string) {
        if (value.trim().length > 0) {
          if (value.trim().length > EMaxLength.max64) {
            return Promise.reject(new Error('入力長を超えました'))
          }
          return Promise.resolve()
        }
        return Promise.reject(
          new Error(
            i18n.t('errors.message.FRE00001', {
              0: i18n.t('modal.userManagement.create.lable.userName')
            })
          )
        )
      }
    })
  ],
  email: [
    {
      required: true,
      message: i18n.t('errors.message.FRE00001', {
        0: i18n.t('modal.userManagement.create.lable.email')
      })
    },
    {
      pattern: new RegExp(regexEmail),
      message: i18n.t('errors.message.FRE00002', {
        0: i18n.t('modal.userManagement.create.lable.email')
      })
    },
    {
      max: EMaxLength.max255,
      message: '入力長を超えました '
    }
  ],
  password: [
    {
      required: true,
      message: i18n.t('errors.message.FRE00001', {
        0: i18n.t('modal.userManagement.create.lable.password')
      })
    },
    {
      min: EMinLength.min6,
      message: i18n.t('errors.message.FRE00006', {
        0: EMinLength.min6
      })
    },
    {
      max: EMaxLength.max32,
      message: '入力長を超えました '
    },
    {
      pattern: new RegExp(regexPassword),
      message: i18n.t('errors.message.FRE00002', {
        0: i18n.t('modal.userManagement.create.lable.password')
      })
    }
  ],
  confirmPassword: [
    ({ getFieldValue }: any) => ({
      validator(_: object, value: string) {
        if (value.length > 0) {
          if (value.length < EMinLength.min6) {
            return Promise.reject(
              new Error(
                i18n.t('errors.message.FRE00006', {
                  0: EMinLength.min6
                })
              )
            )
          } else if (value.length > EMaxLength.max32) {
            return Promise.reject(new Error('入力長を超えました'))
          } else if (!regexPassword.test(value)) {
            return Promise.reject(
              new Error(
                i18n.t('errors.message.FRE00002', {
                  0: i18n.t('modal.userManagement.create.lable.passwordConfirm')
                })
              )
            )
          } else {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(new Error(i18n.t('errors.message.FRE00005')))
          }
        }
        return Promise.reject(
          new Error(
            i18n.t('errors.message.FRE00001', {
              0: i18n.t('modal.userManagement.create.lable.passwordConfirm')
            })
          )
        )
      }
    })
  ]
}
export { validCreateUser }
