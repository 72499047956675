import VButton from 'components/button/VButton'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import 'styles/ButtonReturn.scss'
interface IButtonReturn {
  onClickButtonReturn?: () => void
}
function ButtonReturnComponent({ onClickButtonReturn }: IButtonReturn) {
  const { t } = useTranslation()
  const history = useHistory()
  const handleOnClick = () => {
    history.goBack()
  }
  return (
    <div className='custom-btn-return'>
      <VButton
        onClick={handleOnClick}
        title={t('sensorManagementScreen.btnReturn')}
        style={styleBtnReturn}
      />
    </div>
  )
}
const styleBtnReturn = {
  backgroundColor: '#fff',
  color: '#d7000f',
  border: 'solid 1px #d7000f',
  width: 74,
  height: 38
}
export default ButtonReturnComponent
