import {
  ICustomerMasterData,
  IAddCustomerData,
  ICreateCustomer,
  IListCustomer,
  IDetailRegCustomer,
  IEditCustomer,
  IDeleteCustomer,
  IDeleteMasterSales,
  IDeleteMasterDealer,
  IResendCustomerVerifyEmail,
} from 'services/params/customer/ICustomerParam'
import CustomerRequest from 'services/requests/customer/customerRequest'
import { configRequest } from '..'
import { Methods } from '../EMethod'

class CustomerApis {
  customerMasterData = (params: ICustomerMasterData) => {
    const requests = CustomerRequest(params, Methods.GET_CUSTOMER_MASTER_DATA)
    return configRequest(requests)
  }
  addCustomerMasterData = (params: IAddCustomerData) => {
    const requests = CustomerRequest(params, Methods.ADD_CUSTOMER_MASTER_DATA)
    return configRequest(requests)
  }
  createCustomer = (params: ICreateCustomer) => {
    const requests = CustomerRequest(params, Methods.CREATE_CUSTOMER)
    return configRequest(requests)
  }
  generateCustomerKey = (params: any) => {
    const requests = CustomerRequest(params, Methods.GENERATE_CUSTOMER_KEY)
    return configRequest(requests)
  }
  listCustomer = (params: IListCustomer) => {
    const requests = CustomerRequest(params, Methods.LIST_CUSTOMER)
    return configRequest(requests)
  }
  detailCustomerReg = (params: IDetailRegCustomer) => {
    const requests = CustomerRequest(params, Methods.DETAIL_CUSTOMER)
    return configRequest(requests)
  }
  editCustomer = (params: IEditCustomer) => {
    const requests = CustomerRequest(params, Methods.EDIT_CUSTOMER)
    return configRequest(requests)
  }
  deleteCustomer = (params: IDeleteCustomer) => {
    const requests = CustomerRequest(params, Methods.DELETE_CUSTOMER)
    return configRequest(requests)
  }
  deleteMasterSales = (params: IDeleteMasterSales) => {
    const requests = CustomerRequest(params, Methods.DELETE_MASTER_SALES)
    return configRequest(requests)
  }
  deleteMasterDealer = (params: IDeleteMasterDealer) => {
    const requests = CustomerRequest(params, Methods.DELETE_MASTER_DEALER)
    return configRequest(requests)
  }
  resendCustomerVerifyEmail = (params: IResendCustomerVerifyEmail) => {
    const requests = CustomerRequest(params, Methods.RESEND_EMAIL)
    return configRequest(requests)
  }
  downloadCustomerCsv = (params: IListCustomer) => {
    const requests = CustomerRequest(params, Methods.DOWNLOAD_CSV)
    return configRequest(requests)
  }
}

export const CustomerApi = new CustomerApis()
