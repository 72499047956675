import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'
import ButtonFooterComponent from 'components/common/button/ButtonFooterComponent'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import ModalNewRegisterComponent from 'components/modal/ModalNewRegisterComponent'
import ModalEditAccountComponent from 'components/modal/ModalEditAccountComponent'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AppState } from 'redux/reducers'
import { ERoles, originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import 'styles/AdminManagement.scss'
import { IconEditBlue, IconDeleteRed, IconDeleteModal } from 'assets'
import { ConvertTime, showTotalRecord } from 'utils/Functions'
import ModalComponent from 'components/modal/ModalComponent'
import { useDispatch, useSelector } from 'react-redux'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import TableComponent from 'components/common/table/TableComponent'
import { deleteUserAction, getAdminsListAction } from 'redux/actions/users/usersAction'
import { IDataAdminListRes } from 'interfaces/IUserList'
import { IOption } from 'interfaces/sensor/IPropsSelect'
import InputSearchComponent from 'components/common/input/InputSearchComponent'
import { initialEditAdmin } from '../userManagement/userDetail/initialState'
import { defaultShowBlank } from '../sensor/defaultValue'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import { setAdminCreateAction } from 'redux/actions/create/createActions'
import { EMaxLength } from 'interfaces/enums/EMaxLength'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'

interface IDataType {
  key: string
  no: number
  adminName: string
  emailAcc: string
  registerDate: string
  authority: string
  statusEmail: boolean
  department: string
}
interface IModalEditAdmin {
  isVisible: boolean
  initialValue: {
    userName: string
    email: string
    department: string
    authority: string
  }
}

const getData = (current: number, pageSize: number, data: IDataType[]) => {
  return data.slice((current - 1) * pageSize, current * pageSize)
}

const AdminManagement = () => {
  const dispatch = useDispatch()
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  const checkAdminCreate = useSelector((state: AppState) => state?.create?.adminCreate)
  const { t } = useTranslation()
  // state pagination
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  // modal delete
  const [modalDelete, setModalDelete] = useState({
    isModalVisible: false,
    content: t('adminManagement.confirmDel')
  })
  // eslint-disable-next-line
  const [keySearch, setKeySearch] = useState<string>()
  const [isSearch, setIsSearch] = useState<boolean>(false)
  // list data
  const [dataTable, setDataTable] = useState<IDataType[]>([])
  const [masterDatatable, setMasterDatatable] = useState<IDataType[]>([])
  const [idItem, setIdItem] = useState<string>('')
  const [roleItem, setRoleItem] = useState<string>('')
  const [modalCreate, setModalCreate] = useState<boolean>(false)
  const [modalEditAccount, setModalEditAccount] = useState<IModalEditAdmin>({
    ...initialEditAdmin
  })
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  // render colums
  const columns: ColumnsType<IDataType> = [
    {
      title: t('sensorManagementScreen.table.column.no'),
      dataIndex: '',
      render: (text, row, index) => {
        const fontStyle = (row?.authority === ERoles.MASTER) ? { fontWeight: 'bold' } : {};
        return <span style={{ ...fontStyle }}>{(current - 1) * pageSize + index + 1}</span>
      },
      align: 'center',
      width: '5%'
    },
    {
      title: t('adminManagement.adminName'),
      dataIndex: 'adminName',
      render: (_: object, record: { adminName: string, authority: string }) => {
        const fontStyle = (record?.authority === ERoles.MASTER) ? { fontWeight: 'bold' } : {};
        return <TooltipComponent maxSlice={20} title={record?.adminName} spanStyle={fontStyle} />
      },
      width: '16%'
    },
    {
      title: t('adminManagement.emailAcc'),
      dataIndex: 'emailAcc',
      render: (_: object, record: { emailAcc: string, authority: string }) => {
        const fontStyle = (record?.authority === ERoles.MASTER) ? { fontWeight: 'bold' } : {};
        return <TooltipComponent maxSlice={30} title={record?.emailAcc} spanStyle={fontStyle} />
      },
      width: '20%'
    },
    {
      title: t('adminManagement.registeredDate'),
      dataIndex: 'registeredDate',
      render: (_: object, record: { registerDate: string, authority: string }) => {
        const fontStyle = (record?.authority === ERoles.MASTER) ? { fontWeight: 'bold' } : {};
        return (
          <span style={fontStyle}>{record?.registerDate ? ConvertTime(record?.registerDate) : defaultShowBlank}</span>
        )
      },
      width: '20%'
    },
    {
      title: t('adminManagement.authority'),
      dataIndex: 'authority',
      render: (_: object, record: { authority: string }) => {
        const fontStyle = (record?.authority === ERoles.MASTER) ? { fontWeight: 'bold' } : {};
        return (
          <span style={{ ...fontStyle }}>
            {record?.authority === ERoles.MASTER ? t('adminManagement.adminInitial') :
              record?.authority === 'admin_crud'
                ? t('adminManagement.adminCrud')
                : t('adminManagement.adminView')}
          </span>
        )
      },
      width: role === ERoles.ADMIN_VIEW || role === ERoles.ADMIN_CRUD ? '20%' : '12%'
    },
    {
      title: t('adminManagement.department'),
      dataIndex: 'department',
      render: (_: object, record: { department: string, authority: string }) => {
        const fontStyle = (record?.authority === ERoles.MASTER) ? { fontWeight: 'bold' } : {};
        return <TooltipComponent maxSlice={25} title={record?.department} spanStyle={fontStyle} />
      },
      width: role === ERoles.ADMIN_VIEW || role === ERoles.ADMIN_CRUD ? '40%' : '18%',
    },
    role === ERoles.MASTER
      ? {
        title: <div className='text-center'>{t('adminManagement.colEdit')}</div>,
        dataIndex: '',
        render: (_: object, record: any) => {
          return (
            <div className='text-center'>
              <img
                src={IconEditBlue}
                onClick={() => handleOnClickEdit(record)}
                className='w42x-h28x pointer'
                alt='edit'
              />
            </div>
          )
        },
        align: 'center',
        width: '60px'
      }
      : { width: '0px' },
    role === ERoles.MASTER
      ? {
        title: <div className='text-center'>{t('adminManagement.colDel')}</div>,
        dataIndex: '',
        render: (_: object, record: { key: string; authority: string }) => {
          const isNotMaster = record?.authority !== 'master';
          return (
            isNotMaster ? (
              <div className='text-center'>
                <img
                  src={IconDeleteRed}
                  onClick={() => handleOnClickDelete(record?.key, record?.authority)}
                  className='w42x-h28x pointer'
                  alt='delete'
                />
              </div>
            ) : <div style={{ width: '60px' }}></div>
          )
        }
      }
      : {
        width: '0px'
      }
  ]

  useEffect(() => {
    dispatch(
      getAdminsListAction(
        {},
        (data) => {
          const dataRecord: IDataType[] = []
          data.result.record = data.result.record.map(
            ({
              adminId,
              username,
              email,
              createdAt,
              statusUser,
              department,
              role,
              emailVerifiedAt
            }: // eslint-disable-next-line
              IDataAdminListRes) => {
              if (emailVerifiedAt !== null) {
                dataRecord.push({
                  key: adminId,
                  no: dataRecord.length,
                  adminName: username,
                  emailAcc: email,
                  registerDate: createdAt,
                  statusEmail: statusUser,
                  department: department,
                  authority: role?.roleName
                })
              }
            }
          )
          setMasterDatatable(dataRecord)
          setDataTable(dataRecord)
          dispatch(SetLoadingAction(false))
        },
        true
      )
    )
    // eslint-disable-next-line
  }, [])

  const handleOnSubmitModal = () => {
    switch (modal?.type) {
      case 'delete-user':
        deleteAdmin()
        break
      default:
        break
    }
  }
  // open create account on path create
  useEffect(() => {
    if (checkAdminCreate) {
      setModalCreate(true)
    }
    // eslint-disable-next-line
  }, [checkAdminCreate])
  // state modal create new account
  const handleOnClickCreate = () => {
    setModalCreate(true)
  }
  const handleOnCancelModal = () => {
    setModalCreate(false)
    dispatch(setAdminCreateAction({ adminCreate: false }))
    /* history.push(ADMIN_ROUTE.concat(routesName.ADMIN_MANAGEMENT)) */
    dispatch(SetLoadingAction(false))
  }

  const handleOnSubmitCreate = (data: any) => {
    // eslint-disable-next-line
    const adminItem: IDataType = {
      key: data?.adminId,
      no: masterDatatable?.length + 1,
      adminName: data?.username,
      emailAcc: data?.email,
      registerDate: data?.createAt,
      authority: data?.role,
      statusEmail: data?.statusUser,
      department: data?.department
    }
    /* setMasterDatatable([...masterDatatable, adminItem]) */
    setModalCreate(false)
    showSuccess(t('adminManagement.confirmNewRegis'))
  }

  const showSuccess = (text: string) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }
  // state modal edit account
  const handleOnClickEdit = (record: any) => {
    const initialValue = {
      adminId: record?.key ?? '',
      userName: record?.adminName ?? '',
      email: record?.emailAcc ?? '',
      department: record?.department ?? '',
      authority: record?.authority ?? ''
    }
    setModalEditAccount({ isVisible: true, initialValue: initialValue })
  }

  const handleOnSubmitEdit = (update: any) => {
    const adminInforMaster = [...masterDatatable]
    const adminInfor = [...dataTable]
    const adminInforPositionMaster = adminInforMaster.findIndex((el) => {
      return el.key === update?.key
    })
    adminInforMaster[adminInforPositionMaster] = {
      ...adminInforMaster[adminInforPositionMaster],
      adminName: update?.userName,
      emailAcc: update?.email,
      department: update?.department,
      authority: update?.authority
    }
    const adminInforPosition = adminInfor.findIndex((el) => {
      return el.key === update?.key
    })
    adminInfor[adminInforPosition] = {
      ...adminInfor[adminInforPosition],
      adminName: update?.userName,
      emailAcc: update?.email,
      department: update?.department,
      authority: update?.authority
    }
    setDataTable(adminInfor)
    setMasterDatatable(adminInforMaster)
    setModalEditAccount({ ...modalEditAccount, isVisible: false })
    // hide modal confirm
    showSuccess(t('adminManagement.confirmEditAccount'))
  }

  // state pagination
  const handleOnChangePageSize = (size: number) => {
    const totalPageChange = Math.ceil(dataTable?.length / size)
    if (current > totalPageChange) {
      setCurrent(totalPageChange)
    }
    setPageSize(size)
  }

  const handleOnChangePage = (pageChange: number) => {
    setCurrent(pageChange)
  }
  // state delete
  const handleOnClickDelete = (id: string, role: string) => {
    setRoleItem(role)
    setIdItem(id)
    setModal({
      ...modal,
      isVisible: true,
      type: 'delete-user',
      isComponent: false,
      img: IconDeleteModal,
      btnSubmit: t('modal.btnDelete'),
      content: t('adminManagement.deleteAdmin'),
      hideBtnFooter: false
    })
  }
  const deleteAdmin = async () => {
    const request = {
      id: idItem,
      role: roleItem
    }
    dispatch(
      deleteUserAction(
        {
          ...request
        },
        (data) => {
          if (data?.result?.deletedAt) {
            const masterDataSource = [...masterDatatable]
            const dataSource = [...dataTable]
            const listAdmin = dataSource?.filter((item) => item?.key !== idItem)
            const listAdminMaster = masterDataSource?.filter((item) => item?.key !== idItem)
            setMasterDatatable(listAdminMaster)
            setDataTable(listAdmin)
            const totalPageChange = Math.ceil(listAdmin?.length / pageSize)
            if (totalPageChange === current - 1) {
              setCurrent(totalPageChange)
            }
            showSuccess(t('adminManagement.deleteAdminSuccess'))
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  const handleOnSubmitDelete = () => {
    setModalDelete({
      ...modalDelete,
      isModalVisible: false
    })
    showSuccess(t('adminManagement.msgDelSuccess'))
  }
  // state search

  const handleOnSubmitSearch = (valueSearch: string) => {
    if (valueSearch !== undefined) {
      const data = [...masterDatatable]
      const search: IDataType[] = []
      data.forEach((item: any) => {
        if (item.emailAcc.toLowerCase().includes(valueSearch.toLowerCase().trim())) {
          search.push(item)
        }
      })
      setKeySearch(valueSearch)
      setDataTable(search)
      setCurrent(1)
      setIsSearch(true)
    } else {
      setKeySearch(undefined)
      setDataTable(masterDatatable)
      setCurrent(1)
      setIsSearch(false)
    }
  }
  async function fetchOptionList(valueSearch: string): Promise<IOption[]> {
    const dataSearch: any = []
    masterDatatable.forEach((item: any) => {
      if (item.emailAcc.toLowerCase().includes(valueSearch.toLowerCase().trim())) {
        dataSearch.push({
          id: item?.emailAcc,
          name: item?.emailAcc
        })
      }
    })
    return dataSearch
  }

  const renderInputSearch = () => {
    return (
      <div className='search-box'>
        <Row>
          <Col span={24}>
            <InputSearchComponent
              placeholder={t('userManagementScreen.placeholder')}
              maxLength={EMaxLength.max255}
              btnSearch={t('sensorManagementScreen.btnSearch')}
              onSubmitSearch={(valueSearch) => handleOnSubmitSearch(valueSearch)}
              fetchOptions={fetchOptionList}
              valueSelect={keySearch}
            />
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className='admin-management'>
      {role === ERoles.MASTER ? (
        <div className='bgrffffff' style={{ padding: '16px', marginBottom: '25px' }}>
          <ButtonFooterComponent
            text={t('adminManagement.btnSignUp')}
            classSty='btn-create btn-red'
            onClickButton={handleOnClickCreate}
          />
        </div>
      ) : null}
      <div className='bgrffffff' style={{ padding: '16px' }}>
        {renderInputSearch()}
        <div className='ss-table' style={{ margin: '32px -16px' }}>
          <div className='mg_0_16x'>
            <div className='d-flex justify-content-flex-end align-items-center mg-pagination'>
              <div className='d-flex justify-content-flex-end font-w-bold pd_16x_0x total-record'>
                {showTotalRecord(dataTable?.length)}
              </div>
              <div className='sensor-pagination'>
                <PaginationComponent
                  onChangePageSize={(size) => handleOnChangePageSize(size)}
                  total={dataTable?.length}
                  onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                  pageSize={pageSize}
                  isShowSizeChanger={false}
                  currentpage={current}
                />
              </div>
            </div>
          </div>
          <div className='custom-table'>
            <TableComponent
              isSearch={isSearch}
              columns={columns}
              dataSource={getData(current, pageSize, dataTable)}
            />
          </div>
          <div className='mg_0_16x'>
            <div className='mg-pagination'>
              <PaginationComponent
                onChangePageSize={(size) => handleOnChangePageSize(size)}
                total={dataTable?.length}
                onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                pageSize={pageSize}
                isShowSizeChanger={true}
                currentpage={current}
              />
            </div>
          </div>
        </div>
        <div className='view-btn-filter' style={{ justifyContent: 'center' }}>
          <ButtonReturnComponent />
        </div>
      </div>
      {modalDelete?.isModalVisible ? (
        <ModalComponent
          isVisible={modalDelete?.isModalVisible}
          img={IconDeleteModal}
          content={modalDelete?.content}
          onCancelModal={() =>
            setModalDelete({
              ...modalDelete,
              isModalVisible: false
            })
          }
          btnCancel={t('modal.btnCancel')}
          btnSubmit={t('modal.btnDelete')}
          onSubmitModal={handleOnSubmitDelete}
        />
      ) : null}
      {modal?.isVisible ? (
        <ModalComponent
          isVisible={modal?.isVisible}
          isComponent={modal?.isComponent}
          img={modal?.img}
          content={modal?.content}
          component={modal?.component}
          btnSubmit={modal?.btnSubmit}
          btnCancel={modal?.btnCancel}
          onCancelModal={() => setModal({ ...modal, isVisible: false })}
          onSubmitModal={handleOnSubmitModal}
          hideBtnFooter={modal?.hideBtnFooter}
        />
      ) : null}
      {modalCreate && ROLE_CRUD.includes(role) ? (
        <ModalNewRegisterComponent
          onCancelModal={handleOnCancelModal}
          onSubmitModal={handleOnSubmitCreate}
        />
      ) : null}
      {modalEditAccount?.isVisible ? (
        <ModalEditAccountComponent
          title={t('adminManagement.editAccount')}
          onCancelModal={() => setModalEditAccount({ ...modalEditAccount, isVisible: false })}
          onSubmitModal={handleOnSubmitEdit}
          initialValue={modalEditAccount?.initialValue}
        />
      ) : null}
    </div>
  )
}

export default AdminManagement
