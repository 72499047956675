import { ColumnsType } from 'antd/lib/table'
import { IconWarning } from 'assets'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import i18n from 'configs/i18n'
import { Link } from 'react-router-dom'
import { ConvertTime } from 'utils/Functions'
import { ADMIN_ROUTE, routesName, routesSensorManagement } from 'views/routes/routes'
import {
  defaultTypeSensorLTE,
  defaultShowBlank,
  defaultTypeSensorLora,
  communicationStatusSetting
} from 'views/pages/sensor/defaultValue'
import { IDataListTableSensorManagementUserDetail } from 'interfaces/userDetail/userDetail'
import { convertTimePushAtData } from 'views/pages/dashboard/convertData'

const defineColumSensorOfUser = (current: number, pageSize: number) => {
  const columnsSensorManagementUserDetail: ColumnsType<IDataListTableSensorManagementUserDetail> = [
    {
      title: i18n.t('userDetail.tableSensor.no'),
      dataIndex: 'no',
      width: '5%',
      render: (text, row, index) => {
        return <span>{(current - 1) * pageSize + (index + 1)}</span>
      }
    },
    {
      title: i18n.t('userDetail.tableSensor.deviceID'),
      dataIndex: 'deviceID',
      render: (_: object, record: { key: string; deviceID: string }) => {
        return (
          <div>
            {record?.deviceID ? (
              <div className='pointer custom-a cls_3EACFF'>
                <Link
                  to={ADMIN_ROUTE.concat(
                    routesSensorManagement.SENSOR_DETAIL + `/${record?.deviceID}`
                  )}>
                  {record?.deviceID}
                </Link>
              </div>
            ) : (
              defaultShowBlank
            )}
          </div>
        )
      }
    },
    {
      title: i18n.t('userDetail.tableSensor.sensorName'),
      dataIndex: 'sensorName',
      render: (_: object, record: { sensorName: string }) => {
        return (
          <TooltipComponent
            maxSlice={10}
            title={record?.sensorName ? record?.sensorName : defaultShowBlank}
          />
        )
      }
    },
    {
      title: i18n.t('userDetail.tableSensor.groupName'),
      dataIndex: 'groupName',
      render: (_: object, record: { groupId: string; groupName: string }) => {
        return (
          <div>
            {record?.groupId ? (
              <div className='pointer custom-a cls_3EACFF'>
                <Link
                  to={ADMIN_ROUTE.concat(routesName.USER_MANAGEMENT + `/group/${record?.groupId}`)}>
                  <TooltipComponent maxSlice={10} title={record?.groupName} />
                </Link>
              </div>
            ) : (
              defaultShowBlank
            )}
          </div>
        )
      }
    },
    {
      title: i18n.t('userDetail.tableSensor.sensorType'),
      dataIndex: 'sensorType',
      render: (_: object, record: { sensorType: string }) => {
        return (
          <div>{record?.sensorType ? <span>{record?.sensorType}</span> : defaultShowBlank}</div>
        )
      }
    },
    {
      title: i18n.t('userDetail.tableSensor.lteOrLora'),
      dataIndex: 'lteOrLora',
      render: (_: object, record: { lteOrLora: string }) => {
        return <span>{record?.lteOrLora ? record?.lteOrLora : defaultShowBlank}</span>
      }
    },
    {
      title: i18n.t('userDetail.tableSensor.imeiNumber'),
      dataIndex: 'imeiNumber',
      render: (_: object, record: { lteOrLora: string; imeiNumber: string }) => {
        return (
          <span>
            {record?.lteOrLora === defaultTypeSensorLTE
              ? record?.imeiNumber
                ? record?.imeiNumber
                : defaultShowBlank
              : defaultShowBlank}
          </span>
        )
      }
    },
    {
      title: i18n.t('userDetail.tableSensor.securityKey'),
      dataIndex: 'securityKey',
      render: (_: object, record: { lteOrLora: string; securityKey: string }) => {
        return (
          <>
            {record?.lteOrLora === defaultTypeSensorLora ? (
              record?.securityKey ? (
                <TooltipComponent title={record?.securityKey} />
              ) : (
                defaultShowBlank
              )
            ) : (
              defaultShowBlank
            )}
          </>
        )
      }
    },
    {
      title: i18n.t('userDetail.tableSensor.communicationStatus'),
      dataIndex: 'communicationStatus',
      render: (_: object, record: { communicationStatus: string }) => {
        return (
          <div>
            {record?.communicationStatus ? (
              record?.communicationStatus === communicationStatusSetting?.abnormal ? (
                <div className='d-flex align-items-center'>
                  <span className='cls_E93A44'>
                    {i18n.t('sensorManagementScreen.table.status.abnormal')}
                  </span>
                  <img className='img_20x' src={IconWarning} alt='warning' />
                </div>
              ) : (
                <div className='d-flex align-items-center'>
                  <span className='mr_10x'>
                    {i18n.t('sensorManagementScreen.table.status.normal')}
                  </span>
                </div>
              )
            ) : (
              defaultShowBlank
            )}
          </div>
        )
      }
    },
    {
      title: i18n.t('userDetail.tableSensor.battery'),
      dataIndex: 'battery',
      render: (_: object, record: { battery: string }) => {
        return (
          <div>
            {record?.battery ? (
              record?.battery === communicationStatusSetting?.abnormal ? (
                <div className='d-flex align-items-center'>
                  <span className='cls_E93A44'>
                    {i18n.t('sensorManagementScreen.table.status.abnormal')}
                  </span>
                  <img className='img_20x' src={IconWarning} alt='warning' />
                </div>
              ) : (
                <div className='d-flex align-items-center'>
                  <span className='mr_10x'>
                    {i18n.t('sensorManagementScreen.table.status.normal')}
                  </span>
                </div>
              )
            ) : (
              defaultShowBlank
            )}
          </div>
        )
      }
    },
    {
      title: i18n.t('userDetail.tableSensor.registerDate'),
      dataIndex: 'registerDate',
      render: (_: object, record: { registerDate: string }) => {
        return record?.registerDate ? ConvertTime(record?.registerDate) : defaultShowBlank
      }
    },
    {
      title: i18n.t('userDetail.tableSensor.lastDate'),
      dataIndex: 'lastDate',
      render: (_: object, record: { lastDate: string }) => {
        return record?.lastDate ? convertTimePushAtData(record?.lastDate) : defaultShowBlank
      }
    }
  ]
  return columnsSensorManagementUserDetail
}
export { defineColumSensorOfUser }
