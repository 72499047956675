import { IQrcode } from 'interfaces/qrcode/IQrcode'
import { ICreateQrcode } from 'redux/actions/qrcode/qrcodeTypes'
import { Methods } from 'services/api/EMethod'

export const createQrcode = (request: ICreateQrcode) => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.CREATE_QRCODE,
    params: request
  }
}

export const editQrcode = (request: IQrcode) => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.EDIT_QRCODE,
    params: request
  }
}

export const getListQrcode = () => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_LIST_QR,
    params: {}
  }
}

export const getDetailQrcode = (id: string) => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_DETAIL_QR,
    params: {
      id
    }
  }
}
