import { EAuditLogActions } from './EAuditLogAction';
import { IDetailAuditLog } from "services/params/auditLog/IAuditLogParam";
import { IDetailAuditLogAction } from "./auditLogTypes";

export const detailAuditLogAction = (
   params: IDetailAuditLog,
   callBack: (value: any) => void,
   isLoading: boolean
 ): IDetailAuditLogAction => ({
   type: EAuditLogActions.GET_DETAIL_AUDIT_LOG,
   params,
   callBack,
   isLoading
 })