import { Form } from 'antd'
import FormItem from 'components/common/form/FormItem'
import { IconMiharas, IconPassWord, IconUser } from 'assets'
import VButton from 'components/button/VButton'
import InputPrefix from 'components/inputPrefix/InputPrefix'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { forgotPasswordAction } from 'redux/actions/users/usersAction'
import { ILoginParams } from 'services/params/ILoginParams'
import classes from 'styles/Login.module.scss'
import { formForgotPassword } from '../adminManagement/AccountForm'
import { validLogin } from './rules'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'
import { EMaxLength } from 'interfaces/enums/EMaxLength'

type Props = {
  handleLogin: (loginParams: ILoginParams, callBack: (value: any) => void) => void
  isLoading: boolean
}

type LayoutType = Parameters<typeof Form>[0]['layout']

const Login = ({ handleLogin, isLoading = false }: Props) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(1)
  const formLayout: LayoutType = 'vertical'
  const [form] = Form.useForm()
  const [errLogin, setErrLogin] = useState<string>('')
  const dispatch = useDispatch()
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  const layout = {
    layout: formLayout,
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  }
  const showSuccess = (text: string) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text,
      isRedirect: true,
      href: ADMIN_ROUTE.concat(routesName.LOGIN)
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }
  const onFinish = (values: any) => {
    const loginParams: ILoginParams = {
      username: values.username,
      password: values.password
    }
    handleLogin(loginParams, (data) => {
      if (data === 401) {
        setErrLogin(t('loginScreen.loginErrMessage'))
      } else if (data === 400) {
        setErrLogin(t('loginScreen.loginErrMessage'))
      } else {
        /*  console.log('data', data) */
      }
    })
  }
  const onSendMail = (values: any) => {
    const params = {
      email: values?.email
    }
    dispatch(
      forgotPasswordAction(
        { ...params },
        (data) => {
          if (data?.code) {
            if (data?.code === 400) {
              form.setFields([
                {
                  name: data?.data?.id ? 'email' : '',
                  errors: [data?.data?.id[0] ? t(`errors.message.${data?.data?.id[0]}`) : '']
                }
              ])
              dispatch(SetLoadingAction(false))
            } else {
              dispatch(SetLoadingAction(false))
            }
          } else {
            if (data?.result) {
              showSuccess(t('modal.sendMailResetSuccess'))
            }
          }
        },
        true
      )
    )
  }

  const handleForgotPassword = () => {
    setTab(2)
    form.setFieldsValue({ email: '' })
  }
  const handleReturn = () => {
    form.setFieldsValue({ username: '', password: '' })
    setTab(1)
  }

  const onValuesChange = (values: any) => {
    setErrLogin('')
    if (values?.username) {
      form.setFieldsValue({ username: values?.username?.split(' ').join('') })
    }
    if (values?.password) {
      form.setFieldsValue({ password: values?.password?.split(' ').join('') })
    }
  }

  const onChangeEmail = (values: any) => {
    if (values?.email) {
      form.setFieldsValue({ email: values?.email?.split(' ').join('') })
    }
  }

  const _renderLogin = () => {
    return (
      <div className={classes.login_container}>
        <div className={classes.miharas_logo}>
          <img className={classes.ic_miharas_logo} src={IconMiharas} alt='' />
          <h1 className={classes.name_system}>{t('loginScreen.systemName')}</h1>
        </div>
        <Form
          form={form}
          {...layout}
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onValuesChange={onValuesChange}>
          <InputPrefix
            icon={IconUser}
            placeholder={t('loginScreen.mailAddress')}
            name='username'
            rules={validLogin.email}
            maxLength={EMaxLength.max255}
          />
          <InputPrefix
            icon={IconPassWord}
            placeholder={t('loginScreen.passWord')}
            type='password'
            name='password'
            rules={validLogin.password}
            maxLength={EMaxLength.max32}
          />
          <div className='cls_d7000f' style={{ marginTop: -10 }}>
            {errLogin ? errLogin : ''}
          </div>
          <div className='text-align-center'>
            <Form.Item shouldUpdate>
              {() => (
                <VButton
                  disabled={!!form.getFieldsError().filter(({ errors }) => errors.length).length}
                  className={classes.btn_login}
                  title={t('loginScreen.loginBtn')}
                  style={styles.submitLogin}
                />
              )}
            </Form.Item>
            <p className={classes.foget_password}>
              {t('loginScreen.fogetPassword')}
              <span className={classes.reset_password} onClick={handleForgotPassword}>
                {t('loginScreen.resetPassword')}
              </span>
            </p>
          </div>
        </Form>
      </div>
    )
  }

  const _renderForgotPassword = () => {
    return (
      <div className={classes.reset_container}>
        <div className={classes.foget_heading}>
          <h1 className={classes.name_heading}>{t('fogetPasswordScreen.titleName')}</h1>
        </div>
        <Form
          form={form}
          autoComplete='off'
          onValuesChange={onChangeEmail}
          onFinish={onSendMail}
          style={{ marginTop: 80 }}>
          {formForgotPassword?.map((item) => (
            <div key={item?.name}>
              <FormItem
                label={item?.label}
                name={item?.name}
                type={item?.type}
                rules={item?.rules}
                maxLength={item?.maxLength}
                isRequired={item?.isRequired}
              />
            </div>
          ))}
          <div>
            <p className={classes.reset_input_noti}>{t('fogetPasswordScreen.alertInput')}</p>
            <div className='text-align-center d-flex justify-content-center'>
              <div className={classes.submit_btn}>
                <Form.Item shouldUpdate>
                  {() => (
                    <VButton
                      disabled={
                        !form.isFieldsTouched(true) ||
                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                      }
                      style={styles.styleBtnSubmit}
                      type='text'
                      title={t('adminManagement.confirmPassword.btnConfirmSendMail')}
                    />
                  )}
                </Form.Item>
              </div>
              <div className={classes.cancel_btn}>
                <Form.Item>
                  <VButton
                    title={t('adminManagement.confirmPassword.btnReturn')}
                    style={styles.styleBtnCancel}
                    type='text'
                    htmlType='button'
                    onClick={() => handleReturn()}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  }

  const _renderTab = () => {
    switch (tab) {
      case 1:
        return _renderLogin()
      case 2:
        return _renderForgotPassword()
      default:
        return _renderLogin()
    }
  }

  return <div className={`${classes.login_page} custom-err-form login-page`}>{_renderTab()}</div>
}

const styles = {
  btnresetpw: {
    marginTop: 31,
    fontWeight: 700,
    color: '#fff'
  },
  btnLogin: {
    backgroundColor: '#FFFFFF',
    marginLeft: 24,
    marginTop: 31,
    fontWeight: 700,
    border: '1px solid rgba(215, 0, 15, 1)',
    color: '#D7000F'
  },
  submitLogin: {
    color: '#fff'
  },
  styleBtnSubmit: {
    width: 88,
    height: 38,
    marginRight: 10,
    color: '#ffffff',
    marginTop: 15
  },
  styleBtnCancel: {
    width: 102,
    height: 38,
    marginLeft: 10,
    background: '#ffffff',
    border: '1px solid #D7000F',
    color: '#D7000F',
    marginTop: 15
  }
}

export default Login
