import { ColumnsType } from 'antd/lib/table'
import { IconWarning } from 'assets'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import i18n from 'configs/i18n'
import { IDataListTableSensorManagement } from 'interfaces/sensor/list/ISensorList'
import { Link } from 'react-router-dom'
import { ADMIN_ROUTE, routesSensorManagement } from 'views/routes/routes'
import { convertTimePushAtData } from '../dashboard/convertData'
import { defaultShowBlank, communicationStatusSetting } from './defaultValue'

const defineColums = (current: number, pageSize: number) => {
  const columnsSensorManagement: ColumnsType<IDataListTableSensorManagement> = [
    {
      title: i18n.t('sensorManagementScreen.table.column.no'),
      dataIndex: 'no',
      width: '4%',
      render: (text, row, index) => {
        return <span>{(current - 1) * pageSize + (index + 1)}</span>
      }
    },
    {
      title: i18n.t('sensorManagementScreen.table.column.customerName'),
      dataIndex: 'customerName',
      render: (_: object, record: { customerName: string }) => {
        return (
          <TooltipComponent
            maxSlice={15}
            title={record?.customerName ? record?.customerName : defaultShowBlank}
          />
        )
      }
    },
    {
      title: i18n.t('sensorManagementScreen.table.column.groupName'),
      dataIndex: 'groupName',
      render: (_: object, record: { groupName: string }) => {
        return (
          <TooltipComponent
            maxSlice={15}
            title={record?.groupName ? record?.groupName : defaultShowBlank}
          />
        )
      }
    },
    {
      title: i18n.t('sensorManagementScreen.table.column.deviceID'),
      dataIndex: 'deviceID',
      render: (_: object, record: { key: string; deviceID: string }) => {
        return (
          <div className='pointer custom-a cls_3EACFF text-underline'>
            <Link
              to={ADMIN_ROUTE.concat(
                routesSensorManagement.SENSOR_DETAIL + `/${record?.deviceID}`
              )}>
              {record?.deviceID}
            </Link>
          </div>
        )
      }
    },
    {
      title: i18n.t('sensorManagementScreen.table.column.sensorName'),
      dataIndex: 'sensorName',
      render: (_: object, record: { sensorName: string }) => {
        return (
          <TooltipComponent
            maxSlice={15}
            title={record?.sensorName ? record?.sensorName : defaultShowBlank}
          />
        )
      }
    },
    {
      title: i18n.t('sensorManagementScreen.table.column.lteOrLora'),
      dataIndex: 'lteOrLora',
      render: (_: object, record: { lteOrLora: string; imeiNumber: string }) => {
        return <span>{record?.lteOrLora}</span>
      }
    },
    {
      title: i18n.t('sensorManagementScreen.table.column.sensorType'),
      dataIndex: 'sensorType'
    },
    {
      title: i18n.t('sensorManagementScreen.table.column.communicationStatus'),
      dataIndex: 'communicationStatus',
      render: (_: object, record: { communicationStatus: string }) => {
        return (
          <div>
            {record?.communicationStatus === communicationStatusSetting?.abnormal ? (
              <div className='d-flex align-items-center'>
                <span className='cls_E93A44'>
                  {i18n.t('sensorManagementScreen.table.status.abnormal')}
                </span>
                <img className='img_20x' src={IconWarning} alt='warning' />
              </div>
            ) : (
              <div className='d-flex align-items-center'>
                <span className='mr_10x'>
                  {i18n.t('sensorManagementScreen.table.status.normal')}
                </span>
              </div>
            )}
          </div>
        )
      }
    },
    {
      title: i18n.t('sensorManagementScreen.table.column.battery'),
      dataIndex: 'battery',
      render: (_: object, record: { battery: string }) => {
        return (
          <div>
            {record?.battery === communicationStatusSetting?.abnormal ? (
              <div className='d-flex align-items-center'>
                <span className='cls_E93A44'>
                  {i18n.t('sensorManagementScreen.table.status.abnormal')}
                </span>
                <img className='img_20x' src={IconWarning} alt='warning' />
              </div>
            ) : (
              <div className='d-flex align-items-center'>
                <span className='mr_10x'>
                  {i18n.t('sensorManagementScreen.table.status.normal')}
                </span>
              </div>
            )}
          </div>
        )
      }
    },
    {
      title: i18n.t('sensorManagementScreen.table.column.lastDate'),
      dataIndex: 'lastDate',
      render: (_: object, record: { lastDate: string }) => {
        return (
          <span>
            {record?.lastDate ? convertTimePushAtData(record?.lastDate) : defaultShowBlank}
          </span>
        )
      }
    }
  ]
  return columnsSensorManagement
}
export { defineColums }
