import { Button } from 'antd'
import { CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducers'

type Props = {
  disabled?: boolean
  className?: string
  loading?: boolean
  title: string
  type?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed' | undefined
  color?: string
  textColor?: string
  style?: CSSProperties
  onClick?: () => void
  htmlType?: 'button' | 'reset' | 'submit' | undefined
  btnIcon?: any
}

const VButtonOrder = ({
  disabled,
  className,
  loading = false,
  title,
  type = 'primary',
  color,
  textColor,
  style,
  onClick,
  htmlType,
  btnIcon
}: Props) => {
  const theme = useSelector((state: AppState) => state.common.theme)
  return (
    <Button
      icon={btnIcon}
      disabled={disabled}
      className={className}
      style={{
        ...stylesWithParam(color ? color : theme.PRIMARY_MAIN_LIGHT).btn,
        ...style
      }}
      loading={loading}
      type={type}
      onClick={onClick}
      htmlType={htmlType ? htmlType : 'submit'}>
      {title}
    </Button>
  )
}

const stylesWithParam = (val: string) => {
  const obj = { btn: {} }
  obj.btn = {
    backgroundColor: '#fff',
    color: '#d7000f',
    border: 'solid 1px #d7000f',
    width: 74,
    height: 38,
    borderRadius: 4,
    fontSize: 12,
    marginLeft: 24
  }
  return obj
}

export default VButtonOrder
