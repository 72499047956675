import Encoding from "encoding-japanese";
import { isSafari } from "./core";

export const convertSjis2Utf8 = (file: any, callback: (blob: any)=>void) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {

            const type = isSafari() ? 'application/csv' : 'text/csv';
            const contents = new Uint8Array(reader?.result as ArrayBuffer);
            const encodingType = Encoding.detect(contents);
            const sjisArray = Encoding.convert(contents, 'UTF8', `${encodingType}` as 'SJIS' |  'UTF8');
            const blob = new Blob([new Uint8Array(sjisArray)], { type: `${type};charset=UTF-8` });

            if (callback) callback(blob);
            resolve(blob)
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}

export const encodeDetect = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const contents = new Uint8Array(reader?.result as ArrayBuffer);
            const encodingType = Encoding.detect(contents);
            resolve(`${encodingType}`)
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}

export const readFile = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            console.log('content', reader?.result);
            resolve(reader?.result);
        };
        reader.onerror = reject;
        reader.readAsText(file);
    });
}