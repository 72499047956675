import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { DELAY } from 'interfaces/enums/EDelay'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import {
  IAddCustomerDealerOrSales,
  IGetCustomerMasterDataAction,
  ICreateCustomerAction,
  IGenerateCustomerKeyAction,
  IListCustomerAction,
  IDetailCustomerRegAction,
  IUpdateCustomerAction,
  IDeleteCustomerAction,
  IDeleteMasterSalesAction,
  IDeleteMasterDealerAction,
  IResendCustomerVerifyEmailAction,
  IDownloadCustomerCsvAction
} from 'redux/actions/customer/customerTypes'
import { ECustomerActions } from 'redux/actions/customer/ECustomerAction'
import { CustomerApi } from 'services/api/customer/customerApi'

function* getCustomerMasterDataSaga(action: IGetCustomerMasterDataAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      CustomerApi.customerMasterData,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* addCustomerDataSaga(action: IAddCustomerDealerOrSales) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<any> = yield call(
      CustomerApi.addCustomerMasterData,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 400) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* createCustomerSaga(action: ICreateCustomerAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      CustomerApi.createCustomer,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 400 && err?.code !== 401) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* generateCustomerKeySaga(action: IGenerateCustomerKeyAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      CustomerApi.generateCustomerKey,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* listCustomerSaga(action: IListCustomerAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(CustomerApi.listCustomer, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* detailCustomerRegSaga(action: IDetailCustomerRegAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      CustomerApi.detailCustomerReg,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* editCustomerSaga(action: IUpdateCustomerAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(CustomerApi.editCustomer, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 400) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* deleteCustomerSaga(action: IDeleteCustomerAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      CustomerApi.deleteCustomer,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* deleteMasterSalesSaga(action: IDeleteMasterSalesAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      CustomerApi.deleteMasterSales,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 400) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* deleteMasterDealerSaga(action: IDeleteMasterDealerAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      CustomerApi.deleteMasterDealer,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err && err?.code !== 400) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* resendCustomerVerifyEmail(action: IResendCustomerVerifyEmailAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      CustomerApi.resendCustomerVerifyEmail,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* downloadCustomerCsvSaga(action: IDownloadCustomerCsvAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      CustomerApi.downloadCustomerCsv,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
export default function* customerSaga() {
  yield all([
    takeEvery(ECustomerActions.GET_CUSTOMER_MASTER_DATA, getCustomerMasterDataSaga),
    takeLatest(ECustomerActions.ADD_CUSTOMER_MASTER_DATA, addCustomerDataSaga),
    takeLatest(ECustomerActions.CREAT_CUSTOMER, createCustomerSaga),
    takeLatest(ECustomerActions.GENERATE_CUSTOMER_KEY, generateCustomerKeySaga),
    takeLatest(ECustomerActions.LIST_CUSTOMER, listCustomerSaga),
    takeLatest(ECustomerActions.DETAIL_CUSTOMER_REG, detailCustomerRegSaga),
    takeLatest(ECustomerActions.EDIT_CUSTOMER, editCustomerSaga),
    takeLatest(ECustomerActions.DELETE_CUSTOMER, deleteCustomerSaga),
    takeLatest(ECustomerActions.DELETE_MASTER_SALES, deleteMasterSalesSaga),
    takeLatest(ECustomerActions.DELETE_MASTER_DEALER, deleteMasterDealerSaga),
    takeLatest(ECustomerActions.RESEND_MAIL, resendCustomerVerifyEmail),
    takeLatest(ECustomerActions.DOWNLOAD_CSV, downloadCustomerCsvSaga)
  ])
}
