import i18n from 'configs/i18n'
import { DEFAULT_AVATAR_URL, MEDIA_URL } from 'constants/general'
import { EBlank } from 'interfaces/enums/EBlank'
import { EDate } from 'interfaces/enums/EDate'
import { ERROR_TYPE } from 'interfaces/enums/ErrorTypes'
import { EUserStatus } from 'interfaces/enums/EUserStatus'
import { INotification } from 'interfaces/INotification'
import moment from 'moment'
import { keyFormulaDefault } from 'views/pages/multiSettings/convertData'
import { colors, IColors } from './colors'
import { SERVICE_ERROR_ID, SERVICE_ERROR_MSG } from './helpers'

const changeCSS = (theme: IColors) => {
  document.documentElement.style.setProperty('--text', theme.TEXT)
  document.documentElement.style.setProperty('--menu-text-active', theme.MENU_TEXT_ACTIVE)
  document.documentElement.style.setProperty('--primary-main', theme.PRIMARY_MAIN)
}
const ConvertTime = (time: string) => {
  moment.locale()
  const timeConvert = moment(time).format(EDate.DATEJP)
  return timeConvert
}

const TimeToServer = (startDate: string | Date, startTime: string | Date) => {
  const concatTime = moment(startDate)
    .format('YYYY/MM/DD')
    .concat(' ' + moment(startTime).format('HH:mm'))
  return new Date(concatTime).toLocaleString('en-US', {
    timeZone: 'UTC',
    hour12: false
  })
}

const checkError = (errorType: string | number, message: string) => {
  return {
    errorType: errorType,
    message: message
  }
}

const convertStatusToColor = (status: string) => {
  switch (status) {
    case EUserStatus.ACTIVE:
      return colors.STATUS_ACTIVE
    case EUserStatus.INACTIVE:
      return colors.STATUS_INACTIVE
    case EUserStatus.BLOCK:
      return colors.STATUS_BLOCK
    default:
      return colors.STATUS_ACTIVE
  }
}

const getImgUrl = (imgName: string) => {
  if (imgName) {
    return MEDIA_URL.concat('/').concat(imgName)
  }
  return DEFAULT_AVATAR_URL
}

const getToken = () => {
  const localData = localStorage.getItem('persist:root')
  if (localData) {
    const parseData = JSON.parse(localData)
    if( !parseData?.auth)
      return null;
    const auth = parseData?.auth
    const cookie = JSON.parse(auth)

    return cookie?.accessToken
  }
}

const defaultNoti: INotification = {
  notiType: ERROR_TYPE.SOMETHING_WRONG,
  title: i18n.t('notification.error'),
  message: i18n.t('errors.wrong')
}
const defaultOptionPageSize = [
  {
    id: 10,
    value: 10
  },
  {
    id: 20,
    value: 20
  },
  {
    id: 30,
    value: 30
  }
]
const diffTimeFun = (
  startDateValue: Date,
  endDateValue: Date,
  startTimeValue: Date,
  endTimeValue: Date
) => {
  const startDate = moment(startDateValue).format('YYYY/MM/DD')
  const endDate = moment(endDateValue).format('YYYY/MM/DD')
  const startTime = moment(startTimeValue).format('HH:mm')
  const endTime = moment(endTimeValue).format('HH:mm')
  const concatDateTimeStart = startDate.concat(' ' + startTime)
  const concatDateTimeEnd = endDate.concat(' ' + endTime)
  const diffTime = moment
    .utc(moment(concatDateTimeEnd, EDate.DATEJP).diff(moment(concatDateTimeStart, EDate.DATEJP)))
    .valueOf()
  return diffTime
}
const regexEmail =
  // eslint-disable-next-line
  /^([\ s]*)+([0-9a-zA-Z]([\+\-_\.][0-9a-zA-Z]+)*)+@([a-zA-Z_]+?\.)+([a-zA-Z]{2,4})+[\ s]*$/

const regexZipCode = /^\d{6}$|^\d{3}-\d{4}$/
const regexPassword = /^[0-9a-zA-Z!@#$%^&*]*$/
const regexUppercaseAndLowercase = /(?=.*[a-z])(?=.*[A-Z])/
const regexSpecialCharacters = /[!@#$%^&*]/
const convertFullsizeToHalfsize = (str: string) => {
  const rex = /[\uFF10-\uFF19]/g
  const result = str.replace(rex, (ch) => {
    return String.fromCharCode(ch.charCodeAt(0) - 65248)
  })
  return result
}
const diffTime = (timeEnd: any, timeStart: any) => {
  const diffTimeValue = moment
    .utc(moment(timeEnd, EDate.DATEJP).diff(moment(timeStart, EDate.DATEJP)))
    .valueOf()
  return diffTimeValue
}

const cleanObject = (obj: any) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName]
    }
  }
  return obj
}

const checkRole = () => {
  return true
}

const formatFormula = (exp: string, listAtt: any) => {
  if (exp === keyFormulaDefault) return EBlank.FORMULA
  let result = exp
  let listTm: any = []
  if (listAtt.length > 0 && exp) {
    // get list att into formula
    for (var i = 0; i < listAtt.length; i++) {
      let indexExp = exp.indexOf(listAtt[i]?.attributeKey)
      if (indexExp > -1 && listAtt[i]?.attributeKey !== '') {
        listTm.push(listAtt[i]?.attributeKey)
      }
    }

    // format formula
    if (listTm.length > 0) {
      for (let i = 0; i < listTm.length; i++) {
        let indexExp = exp.indexOf(listTm[i])
        if (indexExp > -1) {
          result = result.replaceAll(
            listTm[i],
            listAtt.find((x: any) => x.attributeKey === listTm[i]).dataKeyPrefix +
              listAtt.find((x: any) => x.attributeKey === listTm[i]).attributeIndex.toString()
          )
        }
      }
    }

    // convert bieu thu js -> bang tinh
    if (result.indexOf('*') !== -1) {
      result = result.replaceAll('*', 'x')
    }
    if (result.indexOf('/') !== -1) {
      result = result.replaceAll('/', '÷')
    }
    // if (result.indexOf('/100') !== -1) {
    //   result = result.replaceAll('/100', '%')
    // }
    if (result.indexOf('pi') !== -1) {
      result = result.replaceAll('pi', 'π')
    }
    if (result.indexOf('root(') !== -1) {
      result = result.replaceAll('root(', '√(')
    }

    if (result.indexOf(':') !== -1) {
      result = result.replaceAll(':', '')
    }
  }
  return result
}
const showTotalRecord = (total: number, isSearch?: boolean) => {
  const result = total > 0 ? i18n.t('applicable', { 0: total }) : null
  return result
}
const sortListAttByAttributeIndex = (data: any) => {
  data = data?.sort(function (a: any, b: any) {
    return a?.attributeIndex - b?.attributeIndex
  })
  return data
}
const formatExpFormula = (exp: string, listAtt: any) => {
  if (exp === keyFormulaDefault) return EBlank.FORMULA
  let result = exp
  let listTm: any = []
  if (listAtt.length > 0 && exp) {
    // get list att into formula
    for (var i = 0; i < listAtt.length; i++) {
      let indexExp = exp.indexOf(listAtt[i]?.attributeKey)
      if (indexExp > -1 && listAtt[i]?.attributeKey !== '') {
        listTm.push(listAtt[i]?.attributeKey)
      }
    }
    // format formula

    // convert bieu thu js -> bang tinh
    if (result.indexOf('*') !== -1) {
      result = result.replaceAll('*', 'x')
    }
    if (result.indexOf('/') !== -1) {
      result = result.replaceAll('/', '÷')
    }
    // if (result.indexOf('/100') !== -1) {
    //   result = result.replaceAll('/100', '%')
    // }
    if (result.indexOf('pi') !== -1) {
      result = result.replaceAll('pi', 'π')
    }
    if (result.indexOf('root(') !== -1) {
      result = result.replaceAll('root(', '√(')
    }

    if (result.indexOf(':') !== -1) {
      result = result.replaceAll(':', '')
    }
  }
  return result
}
const formatExpFormulaOther = (exp: string, listAtt: any) => {
  if (exp === keyFormulaDefault) return EBlank.FORMULA
  let result = exp
  let listTm: any = []
  if (listAtt.length > 0 && exp) {
    // get list att into formula
    for (var i = listAtt.length; i >= 0; i--) {
      let nameAtt = listAtt[i]?.dataKeyPrefix + listAtt[i]?.attributeIndex
      let indexExp = exp.indexOf(nameAtt)
      if (indexExp > -1 && nameAtt !== '') {
        listTm.push(nameAtt)
      }
    }
    // format formula
    if (listTm.length > 0) {
      for (let i = 0; i < listTm.length; i++) {
        let indexExp = exp.indexOf(listTm[i])
        if (indexExp > -1) {
          result = result.replaceAll(
            listTm[i],
            listAtt.find((x: any) => x.dataKeyPrefix + x.attributeIndex === listTm[i]).attributeKey
          )
        }
      }
    }

    // convert bieu thu js -> bang tinh
    if (result.indexOf('*') !== -1) {
      result = result.replaceAll('*', 'x')
    }
    if (result.indexOf('/') !== -1) {
      result = result.replaceAll('/', '÷')
    }
    // if (result.indexOf('/100') !== -1) {
    //   result = result.replaceAll('/100', '%')
    // }
    if (result.indexOf('pi') !== -1) {
      result = result.replaceAll('pi', 'π')
    }
    if (result.indexOf('root(') !== -1) {
      result = result.replaceAll('root(', '√(')
    }

    if (result.indexOf(':') !== -1) {
      result = result.replaceAll(':', '')
    }
  }
  return result
}
const formatFormulaOther = (exp: string, listAtt: any) => {
  if (exp === keyFormulaDefault) return EBlank.FORMULA
  let result = exp
  let listTm: any = []
  if (listAtt.length > 0 && exp) {
    // get list att into formula
    for (var i = listAtt.length; i >= 0; i--) {
      let nameAtt = listAtt[i]?.dataKeyPrefix + listAtt[i]?.attributeIndex
      let indexExp = exp.indexOf(nameAtt)
      if (indexExp > -1 && nameAtt !== '') {
        listTm.push(nameAtt)
      }
    }

    // format formula
    // if (listTm.length > 0) {
    //   for (let i = 0; i < listTm.length; i++) {
    //     let indexExp = exp.indexOf(listTm[i])
    //     if (indexExp > -1) {
    //       result = result.replaceAll(
    //         listTm[i],
    //         listAtt.find((x: any) => (x.dataKeyPrefix + x.attributeIndex) === listTm[i]).attributeKey
    //       )
    //     }
    //   }
    // }

    // convert bieu thu js -> bang tinh
    if (result.indexOf('*') !== -1) {
      result = result.replaceAll('*', 'x')
    }
    if (result.indexOf('/') !== -1) {
      result = result.replaceAll('/', '÷')
    }
    // if (result.indexOf('/100') !== -1) {
    //   result = result.replaceAll('/100', '%')
    // }
    if (result.indexOf('pi') !== -1) {
      result = result.replaceAll('pi', 'π')
    }
    if (result.indexOf('root(') !== -1) {
      result = result.replaceAll('root(', '√(')
    }

    if (result.indexOf(':') !== -1) {
      result = result.replaceAll(':', '')
    }
  }
  return result
}

const getServiceMessage = (errorID: any) => {
  switch (errorID) {
    case SERVICE_ERROR_ID.FRE00027:
      return SERVICE_ERROR_MSG.FRE00027

    default:
      return ''
  }
}

const getShipMentStatus = (status: boolean) => {
  const NOT_YET = '未'
  const READY = '済'
  return status ? READY : NOT_YET
}

export {
  ConvertTime,
  checkError,
  convertStatusToColor,
  getImgUrl,
  changeCSS,
  getToken,
  defaultNoti,
  defaultOptionPageSize,
  diffTimeFun,
  regexEmail,
  regexZipCode,
  convertFullsizeToHalfsize,
  diffTime,
  TimeToServer,
  cleanObject,
  checkRole,
  formatFormula,
  showTotalRecord,
  sortListAttByAttributeIndex,
  formatExpFormula,
  formatExpFormulaOther,
  formatFormulaOther,
  getServiceMessage,
  getShipMentStatus,
  regexPassword,
  regexUppercaseAndLowercase,
  regexSpecialCharacters
}
