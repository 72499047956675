/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Row, Tooltip, Upload } from 'antd'
import ExportCsvComponent from 'components/common/exportCsv/ExportCsvComponent'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { headersCSVListSerialNo } from 'views/pages/katashiki/scv'
import './../../../../styles/SerialNoManagement.scss'
import CheckBoxs from './components/checkboxes'
import Dropdown from './components/dropdown'
import CustomDatePicker from './components/mDatePicker'
import { UploadOutlined } from '@ant-design/icons'
import TableComponent from 'components/common/table/TableComponent'
import { column } from './components/column'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'
import {
  getListKatashikiAction,
  // getListSerialNoAction,
  getListSensorAction,
  searchSerialNo,
  deleteSerialNoAction,
  importCsvFile
} from 'redux/actions/serialNo/serialNoAction'
import { useDispatch } from 'react-redux'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import { listCustomerAction } from 'redux/actions/customer/customerAction'
import {
  IDeleteSerialNoParams,
  IImportCsvParams,
  IListSerialNo
} from 'services/params/serialNo/IserialNoParam'
import moment from 'moment'
import ModalComponent from 'components/modal/ModalComponent'
import ModalCheckSuccessComponent from 'components/modal/ModalCheckSuccessComponent'
import { IconDeleteModal } from '../../../../assets'
import { ADMIN_ROUTE, routeSerial } from 'views/routes/routes'
import { useHistory } from 'react-router-dom'
import { showTotalRecord } from 'utils/Functions'
import ModalEnvironment from 'components/modal/ModalEnvironment'
import VButton from 'components/button/VButton'
import { CONFIRMED_STATUS } from 'utils/helpers'
import InputComponent from 'components/common/input/InputComponent'
import Collapsed from './components/collapsed'
import { convertSjis2Utf8, readFile } from 'components/common/exportCsv/csvCore/csvUtils'
import { ValidationCSVMessage } from 'components/common/exportCsv/csvCore/components/error-message'

const ManufacturingPlant = [
  {
    id: '1',
    label: '佐賀',
    value: '佐賀'
  },
  {
    id: '2',
    label: '台湾',
    value: '台湾'
  }
]

const INVALID_ITEM_ID = -1
const REMOVE_ITEM = 1
const IMPORT_CSV_CONFIRM = 2

const SerialNoManagement = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()

  const [endDate, setEndDate] = useState<string>('')
  const [startDate, setStartDate] = useState<string>('')

  // dropdown status
  const [firstDropdownValue, setFirstDropdownValue] = useState<any>()
  // const [secondDropdownValue, setSecondDropdownValue] = useState<any>()
  // const [thirdDropdownValue, setThirdDropdownValue] = useState<any>()
  const [fourthDropdownValue, setFourthDropdownValue] = useState<any>()

  // checkbox status
  const [firstChecked, setFirstChecked] = useState<any>([])
  const [secondChecked, setSecondChecked] = useState<any>([])
  const [thirdChecked, setThirdChecked] = useState<any>([])

  const [csvData, setCSVData] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [totalRecord, setTotalRecord] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  //data dropdow
  const [listSensorType, setListSensorTpe] = useState<any>()
  const [listCommunicationType, setListCommunicationType] = useState<any>()
  const [listKatashiki, setListKatashiki] = useState<any>()
  // const [listSerialNoNew, setListSerialNoNew] = useState<any>()
  // const [listSerialNoOld, setListSerialNoOld] = useState<any>()
  const [listCustomer, setListCustomer] = useState<any>()
  const [currentSort, setCurrentSort] = useState<'ASC' | 'DESC' | null>(null);

  const [msgPopup, setMsgPopup] = useState({
    isVisible: false,
    msg: ''
  })
  const [confirmPopup, setConfirmPopup] = useState<{
    isVisible: boolean,
    itemID: number,
    imgURL: string,
    content: string,
    submitBtnTittle: string,
    cancelBtnTittle: string,
    popupType: number,
    csvFile: any
  }>({
    isVisible: false,
    itemID: INVALID_ITEM_ID,
    imgURL: '',
    content: '',
    submitBtnTittle: '',
    cancelBtnTittle: t('modal.btnCancel'),
    popupType: 0,
    csvFile: null
  })
  const [dateError, setDateError] = useState(false)
  const [msgError, setMsgError] = useState({
    isVisible: false,
    msg: ''
  })

  const refStartDate = useRef()
  const refEndDate = useRef()

  useEffect(() => {
    reFetchData()
  }, [])

  const reFetchData = () => {
    handleFilterData()
    const getCustomerList = () => {
      const param: any = {
        sortBy: {
          target: 'createdAt',
          order: 'ASC'
        }
      }
      dispatch(
        listCustomerAction(param, true, (data) => {
          const list: any[] = []
          if (data?.result?.record) {
            data?.result?.record.map((el: any) => {
              list.push({
                id: el.id,
                value: el.customerName
              })
            })
            setListCustomer(list)
          }
        })
      )
    }
    const getSensorList = () => {
      const params = {
        type: 'LoRa'
      }
      dispatch(
        getListSensorAction(
          params,
          (resp) => {
            if (resp?.result) {
              getCommunicationType(resp?.result?.sensorTypes)
              getSensorType(resp?.result?.sensorTranslate)
            }
          },
          true
        )
      )
    }
    getCustomerList()
    getSensorList()
  }

  useEffect(() => {
    Promise.all([/* getListSerialNoNew(), */ getListKatashiki()]).then((value) => {
      // setListSerialNoNew(value[0])
      setListKatashiki(value[0])
    })
    // setSecondDropdownValue(null)
    // setThirdDropdownValue(null)
    setTimeout(() => {
      dispatch(SetLoadingAction(false))
    }, 500)
  }, [firstDropdownValue])

  useEffect(() => {
    setCSVData([])
    setTimeout(() => {
      if (tableData.length > 0) {
        getCsvData()
      }
    }, 500)
  }, [tableData])

  const getCsvData = () => {
    const params: IListSerialNo = {
      customerId: firstDropdownValue ? parseInt(firstDropdownValue) : null,
      sensorTypes: firstChecked?.length > 0 ? firstChecked : [],
      sensorTypeTranslates: secondChecked?.length > 0 ? secondChecked : [],
      productSites: thirdChecked?.length > 0 ? thirdChecked : [],
      serialNoType: null,
      serialNoNumber: form.getFieldValue('newSerialNumber')
        ? form.getFieldValue('newSerialNumber').trim()
        : '',
      serialNoNumberOld: form.getFieldValue('oldSerialNumber')
        ? form.getFieldValue('oldSerialNumber').trim()
        : '',
      modelRegistrationModel: fourthDropdownValue ? fourthDropdownValue : '',
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
      pageNo: null,
      pageSize: null,
      isGenCSV: true,
      orderBy: null,
      ...(currentSort ? {
        sortBy: {
          order: currentSort,
          target: 'sr.serialNoNumber'
        }
      } : {})
    }
    dispatch(
      searchSerialNo(params, false, (resp) => {
        if (resp?.result?.record) {
          const list: any[] = []
          const blank = ''
          resp?.result?.record.some((item: any) => {
            const itemObj = {
              serialNoNumber: item.serialNoNumber,
              model: item.modelRegistration.model,
              createdAt: item.manufacturingDate ? moment(item.manufacturingDate).format('YYYY/MM') : blank,
              deviceId: item.deviceId ? item.deviceId : blank,
              lineType: item.sensorType.sensorTypeKey,
              sensorType: item.sensorTypeTranslate.sensorTypeName,
              securityKey: item.securityKey ? item.securityKey : blank,
              IMEINumber: item.IMEINumber ? item.IMEINumber : blank,
              serialOldNumber: item.serialNoNumberOld ? item.serialNoNumberOld : blank,
              channel: item.CHAddress ? item.CHAddress : blank,
              productSite: item.productSite ? item.productSite : blank,
              confirmed: item.confirmed ? CONFIRMED_STATUS.TRUE : CONFIRMED_STATUS.FALSE,
              customerName: item?.ordDetails?.order?.customer?.customerName
                ? item.ordDetails.order.customer.customerName
                : blank,
              regDate: moment(item.createdAt).format('YYYY/MM/DD'),
              updatedDate: moment(item.updatedAt).format('YYYY/MM/DD'),
              note: item.note ? item.note : blank,
            }
            list.push(itemObj)
          })
          setCSVData(list)
        }
      })
    )
  }

  const getListKatashiki = () => {
    const params = {
      customerId: firstDropdownValue ? parseInt(firstDropdownValue) : null
    }
    const listKatashiki: any = []
    dispatch(
      getListKatashikiAction(
        params,
        (resp) => {
          if (resp?.result) {
            resp?.result?.map((item: any) => {
              if (!item.deletedAt) {
                const katashiki = {
                  id: item?.id,
                  value: item?.model
                }
                listKatashiki.push(katashiki)
              }
            })
          }
        },
        true
      )
    )
    return listKatashiki
  }

  // const getListSerialNoNew = () => {
  //   const params = {
  //     type: 'NEW',
  //     customerId: firstDropdownValue ? parseInt(firstDropdownValue) : null
  //   }
  //   const listSerialNoNew: any = []
  //   dispatch(
  //     getListSerialNoAction(
  //       params,
  //       (resp: any) => {
  //         if (resp?.result) {
  //           resp?.result?.map((item: any) => {
  //             const serialNo = {
  //               id: item?.id,
  //               name: item?.name,
  //               value: item?.name
  //             }
  //             listSerialNoNew.push(serialNo)
  //           })
  //           setListSerialNoNew(listSerialNoNew)
  //         }
  //       },
  //       true
  //     )
  //   )
  //   return listSerialNoNew
  // }

  const getCommunicationType = (dataList: any[]) => {
    const list: any[] = []
    dataList.map((item: any) => {
      list.push({
        id: item.sensorTypeId,
        label: item.sensorTypeKey,
        value: Number(item.sensorTypeId)
      })
    })
    // list.sort(function (a, b) {
    //   return a.id - b.id
    // })
    setListCommunicationType(list)
  }

  const getSensorType = (dataList: any[]) => {
    const list: any[] = []
    dataList.map((item: any) => {
      list.push({
        id: item.sensorTypeTranslateId,
        label: item.sensorTypeName,
        value: Number(item.sensorTypeTranslateId)
      })
    })
    setListSensorTpe(list)
  }

  // useEffect(() => {
  //   setListSerialNoOld([])
  //   setTimeout(() => {
  //     getListSerialNoOld()
  //   }, 2000)
  // }, [listSerialNoNew])

  // const getListSerialNoOld = () => {
  //   const params = {
  //     type: 'OLD',
  //     customerId: firstDropdownValue ? parseInt(firstDropdownValue) : null
  //   }
  //   dispatch(
  //     getListSerialNoAction(
  //       params,
  //       (resp) => {
  //         if (resp?.result) {
  //           const listSerialNoOld: any = []
  //           resp?.result?.map((item: any) => {
  //             if (item?.name) {
  //               const serialNo = {
  //                 id: item?.id,
  //                 name: item?.name,
  //                 value: item?.name
  //               }
  //               listSerialNoOld.push(serialNo)
  //             }
  //           })
  //           setListSerialNoOld(listSerialNoOld)
  //         }
  //       },
  //       true
  //     )
  //   )
  // }

  const columns = column(
    currentPage,
    pageSize,
    (record: any) => handleDetailOnClicked(record),
    (record: any) => handleRemoveOnClicked(record),
    (order) => {
      setCurrentPage(1)
      setCurrentSort(order)
      handleFilterData(1, pageSize, false, order)
    }
  )

  const handleDetailOnClicked = (record: any) => {
    const path = ADMIN_ROUTE.concat(routeSerial.INDIVIDUAL_DETAIL).replace(':id', record?.id)
    history.push(path, record.id.toString())
  }
  const handleRemoveOnClicked = (record: any) => {
    setConfirmPopup({
      ...confirmPopup,
      isVisible: true,
      itemID: record.id,
      imgURL: IconDeleteModal,
      content: t('modal.message.removeOrderItem'),
      submitBtnTittle: t('modal.btnDelete'),
      popupType: REMOVE_ITEM
    })
  }

  const handleFirstDropdownChanged = (value: any) => {
    setFirstDropdownValue(value)
  }
  // const handleSecondDropdownChanged = (value: any) => {
  //   setSecondDropdownValue(value)
  // }
  // const handleThirdDropdownChanged = (value: any) => {
  //   setThirdDropdownValue(value)
  // }
  const handleFourthDropdownChanged = (value: any) => {
    setFourthDropdownValue(value)
  }

  const checkItemExist = (item: any, array: any[]) => {
    const index = array.findIndex((el) => item === el)
    return index > -1
  }

  const removeItem = (item: any, arr: any[]) => {
    const newArr = arr.filter((el) => el !== item)
    return newArr
  }

  const handleFirstCheckboxChanged = (value: any) => {
    if (checkItemExist(value, firstChecked)) {
      setFirstChecked(removeItem(value, firstChecked))
    } else {
      setFirstChecked((prev: any) => [...prev, value])
    }
  }

  const handleSecondCheckboxChanged = (value: any) => {
    if (checkItemExist(value, secondChecked)) {
      setSecondChecked(removeItem(value, secondChecked))
    } else {
      setSecondChecked((prev: any) => [...prev, value])
    }
  }

  const handleThirdCheckboxChanged = (value: any) => {
    if (checkItemExist(value, thirdChecked)) {
      setThirdChecked(removeItem(value, thirdChecked))
    } else {
      setThirdChecked((prev: any) => [...prev, value])
    }
  }

  const handleFirstDateChanged = (date: any) => {
    setStartDate(date)
    setDateError(false)
  }

  const handleSecondDateChanged = (date: any) => {
    setDateError(false)
    setEndDate(date)
  }

  const handleOnChangePageSize = (size: number) => {
    setPageSize(size)
    setCurrentPage(1)
    handleFilterData(1, size, false)
  }

  const handleOnChangeCurrentPage = (page: number) => {
    setCurrentPage(page)
    handleFilterData(page)
  }

  const handleFilterData = (pageNo: number = currentPage, pageS: number = pageSize, isSearch?: boolean, order: 'ASC' | 'DESC' | null = currentSort) => {
    const params: IListSerialNo = {
      customerId: isSearch ? (firstDropdownValue ? parseInt(firstDropdownValue) : null) : null,
      sensorTypes: firstChecked?.length > 0 ? firstChecked : [],
      sensorTypeTranslates: secondChecked?.length > 0 ? secondChecked : [],
      productSites: thirdChecked?.length > 0 ? thirdChecked : [],
      serialNoType: null,
      serialNoNumber: form.getFieldValue('newSerialNumber')
        ? form.getFieldValue('newSerialNumber').trim()
        : '',
      serialNoNumberOld: form.getFieldValue('oldSerialNumber')
        ? form.getFieldValue('oldSerialNumber').trim()
        : '',
      modelRegistrationModel: fourthDropdownValue ? fourthDropdownValue : '',
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
      pageNo: pageNo,
      pageSize: pageS,
      isGenCSV: true,
      orderBy: null,
      ...(order ? {
        sortBy: {
          order,
          target: 'sr.serialNoNumber'
        }
      } : {})
    }
    dispatch(
      searchSerialNo(params, true, (resp) => {
        if (resp?.result?.record) {
          const list: any[] = []
          resp?.result?.record.some((el: any) => {
            const obj = {
              ...el,
              key: el.id
            }
            list.push(obj)
          })
          setTableData(list)
          setTotalRecord(resp?.result?.totalRecord)
        }
      })
    )
  }

  const handleClearField = () => {
    setFirstDropdownValue(null)
    form.resetFields()
    // setSecondDropdownValue(null)
    // setThirdDropdownValue(null)
    setFourthDropdownValue(null)
    setFirstChecked([])
    setSecondChecked([])
    setThirdChecked([])
    setStartDate('')
    setEndDate('')
    setCurrentPage(1)
    setPageSize(10)
    setDateError(false)

    const params: IListSerialNo = {
      customerId: null,
      sensorTypes: [],
      sensorTypeTranslates: [],
      productSites: [],
      serialNoType: null,
      serialNoNumber: '',
      serialNoNumberOld: '',
      modelRegistrationModel: '',
      startDate: '',
      endDate: '',
      pageNo: 1,
      pageSize: 10,
      isGenCSV: true,
      orderBy: null,
      ...(currentSort ? {
        sortBy: {
          order: currentSort,
          target: 'sr.serialNoNumber'
        }
      } : {})
    }
    dispatch(
      searchSerialNo(params, true, (resp) => {
        if (resp?.result?.record) {
          const list: any[] = []
          resp?.result?.record.some((el: any) => {
            const obj = {
              ...el,
              key: el.id
            }
            list.push(obj)
          })
          setTableData(list)
          setTotalRecord(resp?.result?.totalRecord)
        }
      })
    )
  }

  const handleCancelRemovePopup = () => {
    hiddenConfirmPopup()
  }

  const handleSubmitRemovePopup = () => {
    switch (confirmPopup.popupType) {
      case REMOVE_ITEM:
        handleApiRemoveItem()
        break
      case IMPORT_CSV_CONFIRM:
        handleApiReimportCsv()
        break
      default:
        break
    }
  }

  const handleApiRemoveItem = () => {
    if (confirmPopup.itemID !== INVALID_ITEM_ID) {
      let id: IDeleteSerialNoParams = {
        id: confirmPopup.itemID
      }

      dispatch(
        deleteSerialNoAction(
          id,
          (resp) => {
            if (!resp?.result?.Error) {
              reloadTableData()
              setMsgPopup({
                ...msgPopup,
                isVisible: true,
                msg: t('modal.message.removeSuccess')
              })
              hiddenConfirmPopup()
            } else {
              setMsgError({
                ...msgError,
                isVisible: true,
                msg: 'すでに顧客が決まっている製造機器の削除ができません。'
              })
              hiddenConfirmPopup()
            }
          },
          true
        )
      )
    }
  }

  const reloadTableData = () => {
    const maxPage = Math.ceil((totalRecord - 1) / pageSize)
    const page = currentPage < maxPage ? currentPage : maxPage
    setCurrentPage(page)
    handleFilterData(page)
  }

  const handleApiReimportCsv = () => {
    const params: IImportCsvParams = {
      csvFile: confirmPopup.csvFile,
      params: {
        skip: true
      }
    }
    apiImportCsv(params)
  }

  const handleMsgPopupCancel = () => {
    setMsgPopup({
      ...msgPopup,
      isVisible: false,
      msg: ''
    })
  }

  const hiddenConfirmPopup = () => {
    setConfirmPopup({
      ...confirmPopup,
      isVisible: false,
      itemID: INVALID_ITEM_ID,
      imgURL: '',
      submitBtnTittle: '',
      content: ''
    })
  }

  const handleGoBack = () => {
    history.goBack()
  }

  const handleCreateSerialNoOnClick = () => {
    const path = ADMIN_ROUTE.concat(routeSerial.SELECT_TYPE_CREATE_SERIAL)
    history.push(path)
  }

  const handleSearchOnClick = () => {
    if (moment(startDate).isAfter(moment(endDate))) {
      setDateError(true)
    } else {
      setCurrentPage(1)
      setPageSize(10)
      handleFilterData(1, 10, true)
    }
  }

  const handleImportCsvFile = async (options: any) => {
    const { file } = options
    const data: IImportCsvParams = {
      csvFile: file
    }
    apiImportCsv(data)
  }

  const apiImportCsv = async (data: IImportCsvParams) => {

    // block other file type
    const fileExtension = data?.csvFile?.name?.split('.')?.at(-1);
    console.log('fileExtension', fileExtension);
    if (!fileExtension || fileExtension !== 'csv') {
      setMsgError({
        ...msgError,
        isVisible: true,
        msg: ValidationCSVMessage.INVALID_FILE_EXTENSION
      });
      return;
    }

    const csvFile = await convertSjis2Utf8(data.csvFile, (_) => { });
    const newData = { ...data, csvFile }
    await readFile(newData.csvFile);
    dispatch(
      importCsvFile(newData, async (resp) => {

        if (resp?.result?.Error) {
          const error: any = JSON.parse(resp?.result?.Error?.message)
          const SKIP_ERROR_CODE = 'FRE00038'
          if (error?.id[0] === SKIP_ERROR_CODE) {
            setConfirmPopup({
              ...confirmPopup,
              popupType: IMPORT_CSV_CONFIRM,
              isVisible: true,
              content: error?.message,
              imgURL: '',
              submitBtnTittle: 'スキップ',
              csvFile: newData.csvFile
            })
          } else {
            setMsgError({
              ...msgError,
              isVisible: true,
              msg: error?.message
            })
          }
        } else {
          if (confirmPopup.isVisible) {
            hiddenConfirmPopup()
          }
          if (resp?.result === 'IMPORT_SUCCESS') {
            setMsgPopup({
              ...msgPopup,
              isVisible: true,
              msg: 'インポートしました。'
            })
          }
          handleFilterData()
        }
      })
    )
  }

  const handleCloseErrorPopup = () => {
    setMsgError({
      ...msgError,
      isVisible: false,
      msg: ''
    })
  }
  // search serial option
  // const filterSerialOption = (value: any, option: any) => {
  //   return option?.children?.toLowerCase().indexOf(value?.toLowerCase()) >= 0
  // }

  return (
    <div>
      <div className='bgrffffff serial-body'>
        <VButton
          title={t('userManagementScreen.btnCreate')}
          onClick={() => handleCreateSerialNoOnClick()}
          style={styleBtnCreate}
        />
      </div>
      <br />
      <Collapsed isTopSpace={false} isBottomSpace={false}>
        <Form form={form}>
          <div className=' serial-body'>
            <div className=''>
              <div className='row-layout'>
                <div className='col-layout'>
                  <Dropdown
                    selectValue={false}
                    data={listCustomer}
                    label='顧客選択'
                    value={firstDropdownValue}
                    onSelect={handleFirstDropdownChanged}
                  />
                </div>
                <div className='col-layout'>
                  <CheckBoxs
                    data={listCommunicationType}
                    label='通信種別'
                    currentChecked={firstChecked}
                    onChange={handleFirstCheckboxChanged}
                  />
                </div>
              </div>
              <div className='row-layout'>
                <div className='col-layout'>
                  {/* <Dropdown
                  data={[]}
                  label='製造番号'
                  value={secondDropdownValue}
                  selectValue={true}
                  showSearch={true}
                  filterOption={filterSerialOption}
                  optionFilterProp='children'
                  onSearch={(value: any) => handleSecondDropdownChanged(value)}
                /> */}
                  <InputComponent
                    name='newSerialNumber'
                    type='text'
                    // maxLength={64}
                    label='製造番号'
                    style={{ width: '95%' }}
                  />
                </div>
                <div className='col-layout'>
                  <CheckBoxs
                    data={listSensorType}
                    label='機器種別'
                    currentChecked={secondChecked}
                    onChange={handleSecondCheckboxChanged}
                  />
                </div>
              </div>
              <div className='row-layout'>
                <div className='col-layout'>
                  {/* <Dropdown
                  data={listSerialNoOld}
                  label='旧製造番号'
                  value={thirdDropdownValue}
                  onSelect={handleThirdDropdownChanged}
                  selectValue={true}
                /> */}
                  <InputComponent
                    name='oldSerialNumber'
                    type='text'
                    // maxLength={64}
                    label='旧製造番号'
                    style={{ width: '95%' }}
                  />
                </div>
                <div className='col-layout'>
                  <CheckBoxs
                    data={ManufacturingPlant}
                    label='製造工場'
                    currentChecked={thirdChecked}
                    onChange={handleThirdCheckboxChanged}
                  />
                </div>
              </div>
              <div className='row-layout row-unset-margin-bottom'>
                <div className='col-layout'>
                  <Dropdown
                    data={listKatashiki}
                    label='型式'
                    value={fourthDropdownValue}
                    onSelect={handleFourthDropdownChanged}
                    selectValue={true}
                  />
                </div>
                <div className='date-picker-container'>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item>
                        <div className='start-date'>更新日</div>
                        <CustomDatePicker
                          refer={refStartDate}
                          selected={startDate}
                          isTilde={true}
                          onChange={handleFirstDateChanged}
                        />
                        {dateError ? (
                          <div className='msg-error'>適切な期間を指定して下さい。</div>
                        ) : null}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item>
                        <div className='start-date'>更新日</div>
                        <CustomDatePicker
                          refer={refEndDate}
                          selected={endDate}
                          isTilde={false}
                          onChange={handleSecondDateChanged}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className='group-btn-action'>
                <VButton
                  onClick={() => handleSearchOnClick()}
                  title={t('sensorManagementScreen.btnSubmit')}
                  style={styleBtnSubmit}
                  type='text'
                />
                <VButton
                  title={t('userManagementScreen.btnCancel')}
                  onClick={() => handleClearField()}
                  style={styleBtnClear}
                />
              </div>
            </div>
          </div>
        </Form>
      </Collapsed>
      <br />
      <div className='bgrffffff serial-body'>
        <div className='block'>
          <div className='row-layout row-unset-margin-bottom'>
            <div className='col-layout'>
              <div className='row-layout row-unset-margin-bottom'>
                <ExportCsvComponent
                  headers={headersCSVListSerialNo}
                  data={csvData}
                  fileName='製造番号一覧'
                  isDisabled={tableData?.length === 0 ? true : false}
                />
                <Tooltip
                  overlayInnerStyle={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
                  placement='top'
                  title={
                    '製造年月、IMEI番号、旧製造番号、無線CH、製造工場、\n疎通確認ステータス、備考が変更可能です。'
                  }>
                  <Upload showUploadList={false} accept='csv/*' customRequest={handleImportCsvFile}>
                    <Button
                      className='upload-button'
                      icon={<UploadOutlined style={{ fontSize: '16px' }} />}>
                      <span>{t('ListSerialNoScreen.Button.uploadCsv')}</span>
                    </Button>
                  </Upload>
                </Tooltip>
              </div>
            </div>
            <div className='col-layout inline'>
              <div className='d-flex justify-content-flex-end font-w-bold total-record'>
                {showTotalRecord(totalRecord)}
              </div>
              <div>
                <PaginationComponent
                  onChangePageSize={(size) => handleOnChangePageSize(size)}
                  total={totalRecord}
                  onChangePage={(pageChange) => handleOnChangeCurrentPage(pageChange)}
                  pageSize={pageSize}
                  isShowSizeChanger={false}
                  currentpage={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row-layout row-unset-margin-bottom'>
          <div className='table'>
            <TableComponent columns={columns} dataSource={tableData} isSearch={true} />
          </div>
        </div>
        <div className='block'>
          <div className='row-layout row-unset-margin-bottom pagination'>
            <div className='pagination-bottom'>
              <PaginationComponent
                onChangePageSize={(size) => handleOnChangePageSize(size)}
                total={totalRecord}
                onChangePage={(pageChange) => handleOnChangeCurrentPage(pageChange)}
                pageSize={pageSize}
                isShowSizeChanger={true}
                currentpage={currentPage}
              />
            </div>
          </div>
          <div className='row-layout center-item row-unset-margin-bottom'>
            <ButtonReturnComponent onClickButtonReturn={() => handleGoBack()} />
          </div>
        </div>
      </div>
      <ModalComponent
        isVisible={confirmPopup.isVisible}
        img={confirmPopup.imgURL}
        content={confirmPopup.content}
        btnCancel={confirmPopup.cancelBtnTittle}
        btnSubmit={confirmPopup.submitBtnTittle}
        onCancelModal={handleCancelRemovePopup}
        onSubmitModal={handleSubmitRemovePopup}
        style={confirmPopup.popupType === IMPORT_CSV_CONFIRM ? modelMsgStyle : null}
      />
      <ModalCheckSuccessComponent
        isVisible={msgPopup.isVisible}
        content={msgPopup.msg}
        handleClickCancel={handleMsgPopupCancel}
      />
      <ModalEnvironment
        isVisible={msgError.isVisible}
        content={msgError.msg}
        btnClose={t('modal.modalEnvironment.btnClose')}
        onCloseModal={handleCloseErrorPopup}
        contentStyle={msgErrorContentStyle}
      />
    </div>
  )
}
const styleBtnCreate = {
  height: 38,
  width: 76,
  backgroundColor: '#d7000f',
  color: '#fff',
  fontSize: 12,
  borderColor: ' #d7000f',
  margin: 24
}
const styleBtnSubmit = {
  width: 74,
  height: 38,
  color: '#ffffff'
}
const styleBtnClear = {
  height: 38,
  width: 116,
  marginLeft: 15,
  backgroundColor: '#fff',
  color: ' #d7000f',
  border: 'solid 1px #e3242b'
}
const modelMsgStyle = {
  whiteSpace: 'pre'
}
const msgErrorContentStyle = {
  whiteSpace: 'pre-line'
}
// const tooltipStyle = {
//   width: 'fit-content',
//   whiteSpace: 'nowrap'
// }

export default SerialNoManagement
