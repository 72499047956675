const caculator: any = [
  {
    key: 2,
    span: 8,
    className: 'operator-btn',
    children: [
      {
        key: 1,
        classDiv: 'unit-btn w-h-100',
        isButton: true,
        value: 'Rad',
        name: 'Rad'
      },
      {
        key: 2,
        classDiv: 'divided-line',
        isButton: false,
        value: '&nbsp;'
      },
      {
        key: 3,
        classDiv: 'unit-btn w-h-100',
        isButton: true,
        value: 'Deg',
        name: 'Deg'
      }
    ]
  },
  {
    key: 3,
    span: 4,
    className: 'operator-btn',
    value: '(',
    name: '('
  },
  {
    key: 4,
    span: 4,
    className: 'operator-btn',
    value: ')',
    name: ')'
  },
  {
    key: 5,
    span: 4,
    className: 'operator-btn',
    value: '%',
    name: '%'
  },
  {
    key: 6,
    span: 4,
    className: 'operator-btn',
    value: 'AC',
    name: 'AC'
  },
  {
    key: 7,
    span: 4,
    className: 'operator-btn',
    value: 'x^y',
    name: 'x^y'
  },
  {
    key: 8,
    span: 4,
    className: 'operator-btn',
    value: 'sin',
    name: 'sin'
  },
  {
    key: 9,
    span: 4,
    className: 'number-btn',
    value: '7',
    name: '7'
  },
  {
    key: 10,
    span: 4,
    className: 'number-btn',
    value: '8',
    name: '8'
  },
  {
    key: 11,
    span: 4,
    className: 'number-btn',
    value: '9',
    name: '9'
  },
  {
    key: 12,
    span: 4,
    className: 'operator-btn',
    value: '÷',
    name: '÷'
  },
  {
    key: 13,
    span: 4,
    className: 'operator-btn',
    value: 'π',
    name: 'π'
  },
  {
    key: 14,
    span: 4,
    className: 'operator-btn',
    value: 'cos',
    name: 'cos'
  },
  {
    key: 15,
    span: 4,
    className: 'number-btn',
    value: '4',
    name: '4'
  },
  {
    key: 16,
    span: 4,
    className: 'number-btn',
    value: '5',
    name: '5'
  },
  {
    key: 17,
    span: 4,
    className: 'number-btn',
    value: '6',
    name: '6'
  },
  {
    key: 18,
    span: 4,
    className: 'operator-btn',
    value: 'x',
    name: 'x'
  },
  {
    key: 19,
    span: 4,
    className: 'operator-btn',
    value: '√',
    name: '√'
  },
  {
    key: 20,
    span: 4,
    className: 'operator-btn',
    value: 'tan',
    name: 'tan'
  },
  {
    key: 21,
    span: 4,
    className: 'number-btn',
    value: '1',
    name: '1'
  },
  {
    key: 22,
    span: 4,
    className: 'number-btn',
    value: '2',
    name: '2'
  },
  {
    key: 23,
    span: 4,
    className: 'number-btn',
    value: '3',
    name: '3'
  },
  {
    key: 24,
    span: 4,
    className: 'operator-btn',
    value: '-',
    name: '-'
  },
  {
    key: 25,
    span: 4,
    className: 'operator-btn',
    value: 'DEL',
    name: 'DEL'
  },
  {
    key: 26,
    span: 4,
    className: 'operator-btn',
    value: 'log',
    name: 'log'
  },
  {
    key: 27,
    span: 4,
    className: 'number-btn',
    value: '0',
    name: '0'
  },
  {
    key: 28,
    span: 4,
    className: 'number-btn',
    value: '.',
    name: '.'
  },
  {
    key: 29,
    span: 4
  },
  {
    key: 30,
    span: 4,
    className: 'operator-btn',
    value: '+',
    name: '+'
  }
]
export default caculator
