import {
  IDataBatteryDash,
  IDataCommunication,
  IDataDashboard,
  IDataNotification,
  IDataOutages
} from 'interfaces/IDashboard'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import { getDataDashboardAction } from 'redux/actions/dashboard/dashboardAction'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'
import {
  convertDataAlertSystem,
  convertDataSensorCommunicaion,
  convertDataSensorLowBattery,
  convertDataSensorOutages,
  converTotalDashboard
} from './convertData'
import DashBoardDetailComponent from './DashBoardDetailComponent'
import DashboardTableComponent from './DashboardTableComponent'
import {
  columnsNotification,
  columnsSensorBattery,
  columnsSensorCommunication,
  columnsSensorOfOutages
} from './defaultColums'
function DashboardComponent() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // state dashboard
  const [dashboard, setDashboard] = useState<IDataDashboard>()
  // state table sensor communication err
  const [dataTableCommunicationErr, setDataTableCommunication] = useState<IDataCommunication[]>([])
  // state table sensor communication err
  const [dataTableOutages, setDataTableOutages] = useState<IDataOutages[]>([])
  // state table sensor battery is low
  const [dataTableBattery, setDataTableBattery] = useState<IDataBatteryDash[]>([])
  // state table list notification
  const [dataTableNotification, setDataTableNotification] = useState<IDataNotification[]>([])
  useEffect(() => {
    dispatch(
      getDataDashboardAction(true, (data) => {
        if (data?.result) {
          // total
          const total = converTotalDashboard(data)
          // convert data
          const convertDataCommunication = convertDataSensorCommunicaion(
            data?.result?.listSensorTransmissionError
          )
          const convertDataLowBattery = convertDataSensorLowBattery(
            data?.result?.listSensorLowBattery
          )
          const convertDataOutages = convertDataSensorOutages(data?.result?.listSensorInactive)
          const convertDataNotification = convertDataAlertSystem(data?.result?.listAlertSystem)
          // set state
          setDashboard(total)
          setDataTableCommunication(convertDataCommunication)
          setDataTableBattery(convertDataLowBattery)
          setDataTableOutages(convertDataOutages)
          setDataTableNotification(convertDataNotification)
          dispatch(SetLoadingAction(false))
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
  }, [dispatch])
  return (
    <div>
      <div className='dashboard-detail'>
        <DashBoardDetailComponent dataDashboard={dashboard} />
      </div>
      <div className='dashboard-table'>
        <div>
          <DashboardTableComponent
            title={t('sideBar.communicationErrorList')}
            columns={columnsSensorCommunication}
            data={dataTableCommunicationErr}
            path={ADMIN_ROUTE.concat(routesName?.COMMUNICATION_MANAGEMENT)}
            btnName={t('seeMore')}
          />
        </div>
        <div>
          <DashboardTableComponent
            title={t('sideBar.batteryDropList')}
            columns={columnsSensorBattery}
            data={dataTableBattery}
            path={ADMIN_ROUTE.concat(routesName?.BATTERY_MANAGEMENT)}
            btnName={t('seeMore')}
          />
        </div>
        <div>
          <DashboardTableComponent
            title={t('sideBar.listOfOutagesDash')}
            columns={columnsSensorOfOutages}
            data={dataTableOutages}
            path={ADMIN_ROUTE.concat(routesName?.OUTAGES_MANAGEMENT)}
            btnName={t('seeMore')}
          />
        </div>
        <div>
          <DashboardTableComponent
            title={t('sideBar.listNotification')}
            columns={columnsNotification}
            data={dataTableNotification}
            path={ADMIN_ROUTE.concat(routesName?.INFO_MANAGEMENT)}
            btnName={t('seeMore')}
          />
        </div>
      </div>
    </div>
  )
}
export default DashboardComponent
