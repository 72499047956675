import { INotification } from 'interfaces/INotification'
import { IColors } from 'utils/colors'
import {
  ISetLoadingAction,
  ISetNotificationAction,
  ISetThemeAction,
  ISuccessAction,
  IUploadAction
} from './commonTypes'
import { ECommonActions } from './ECommonAction'

export const SetLoadingAction = (isLoading: boolean): ISetLoadingAction => ({
  type: ECommonActions.SET_LOADING,
  isLoading
})

export const SetNotificationAction = (
  notification: INotification | null
): ISetNotificationAction => ({
  type: ECommonActions.SET_NOTIFICATION,
  notification,
  isLoading: false
})

export const UploadAction = (file: any): IUploadAction => ({
  type: ECommonActions.UPLOAD,
  file
})

export const SetThemeAction = (theme: IColors): ISetThemeAction => ({
  type: ECommonActions.SET_THEME,
  theme
})

export const SuccessAction = (success: any): ISuccessAction => ({
  type: ECommonActions.SET_SUCCESS,
  success
})

export const getMasterData = () => ({
  type: ECommonActions.GET_MASTER_DATA
})

export const setMasterData = (data: any) => ({
  type: ECommonActions.SET_MASTER_DATA,
  data
})
