import { IDataSensorDetail } from 'interfaces/sensor/ISensorDetail'
import { ConvertTime } from 'utils/Functions'
import { convertTimePushAtData } from 'views/pages/dashboard/convertData'
import { convertTypeSensor } from '../convertData'
import { communicationStatusSetting, defaultOperaStatus, defaultShowBlank } from '../defaultValue'
import i18n from 'configs/i18n'
import { EEventSensorDetail } from 'interfaces/enums/ESensorType'
import moment from 'moment'

const convertSerialNumber = (serial: string) => {
  let serialNumber = ''
  let result = ''
  const array = serial.split('-')
  if (array && array[1] && array[1]?.length > 3) {
    serialNumber = array[1]
    result = serialNumber
    if (serialNumber?.length < 9) {
      for (let i = 0; i < 9 - serialNumber?.length; i++) {
        result = '0' + result
      }
    }
  }
  return result
}
const convertGps = (gps: string) => {
  let gpsConvert = gps
  const array = gps?.split('.')
  if (array && array[0] && array[1]) {
    gpsConvert = array[0].concat('.' + array[1]?.slice(0, 4))
  }
  return gpsConvert
}
const convertDataSensorDetail = (data: any) => {
  const dataSensorDetail: IDataSensorDetail = {
    deviceId: data?.deviceId,
    oldDeviceId: data?.deviceOld,
    sensorType: convertTypeSensor(data?.sensorType?.sensorTypeTranslate),
    lteOrLora: data?.sensorTypeParent?.sensorTypeKey,
    collectionId: data?.localServerId,
    sensorId: data?.sensorId,
    securityKey: data?.securityKey,
    registerDate: ConvertTime(data?.createdAt),
    gps:
      data?.coordinate && data?.coordinate.x && data?.coordinate.y
        ? convertGps(data?.coordinate.x.toString()) +
          ('°, ' + convertGps(data?.coordinate.y.toString()) + '°')
        : i18n.t('sensorDetail.noReception'),
    lastData: data?.pushAtData ? convertTimePushAtData(data?.pushAtData) : '',
    sensorIp: data?.ipSensor,
    timeTracking: data?.timeTracking?.toString(),
    battery:
      data?.statusBattery === 1
        ? communicationStatusSetting.abnormal
        : communicationStatusSetting.normal,
    communicationStt:
      data?.statusTransmission === 1
        ? communicationStatusSetting.abnormal
        : communicationStatusSetting.normal,
    operaStt: data?.statusActive ? defaultOperaStatus.active : defaultOperaStatus?.stop,
    serialNumber:
      /* data?.sensorTypeParent?.sensorTypeKey === defaultTypeSensorLTE && data?.deviceId
        ? convertSerialNumber(data?.deviceId)
        : '', */ data?.deviceId ? convertSerialNumber(data?.deviceId) : '',
    imeiNumber: data?.imei,
    rebootRequest: '',
    downloadJson: data?.downloadJson
  }
  return dataSensorDetail
}
const convertListUser = (data: any) => {
  const user = data?.customer
  const sensorGroup = data?.group
  const result: any[] = []
  result.push({
    key: user?.userId,
    no: 1,
    userName: user?.customerName,
    email: user?.email,
    groupName: sensorGroup?.groupName
      ? sensorGroup?.groupName
      : defaultShowBlank,
    sensorName: data?.sensorName,
    registerDate: data?.createdAt
  })
  return result
}
const convertNameEvent = (key: string) => {
  let text = ''
  switch (key) {
    case EEventSensorDetail.ON:
      text = i18n.t('sensorDetail.event.on')
      break
    case EEventSensorDetail.OFF:
      text = i18n.t('sensorDetail.event.off')
      break
    default:
      break
  }
  return text
}
const convertListEvent = (data: any) => {
  data = data?.map((item: any, index: number) => {
    return {
      key: item?.id,
      no: index + 1,
      category: convertNameEvent(item?.eventKey),
      logDateTime: item?.createdAt,
      requestPeriodStart: item?.requestPeriodEnd,
      requestPeriodEnd: item?.requestPeriodStart
    }
  })
  return data
}
const downloadFileJson = async (sensorDetail: any) => {
  const date = moment().format('YYYYMMDDHHmmss')
  const myData = sensorDetail?.downloadJson
  const fileName = sensorDetail?.deviceId + '_' + date
  const json =
    myData === 'このセンサは一回もサーバにデータ送信していません。'
      ? myData
      : JSON.stringify(myData, null, 4)
  const blob = new Blob([json], { type: 'application/json' })
  const href = await URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = href
  link.download = fileName + '.json'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export { convertDataSensorDetail, convertListUser, convertListEvent, downloadFileJson }
