import 'styles/InputTextArea.scss'
import { IPropsInputTextArea } from 'interfaces/input/IPropsInputTextArea'
import { Input } from 'antd'

const { TextArea } = Input

const InputTextAreaComponent = ({
  state,
  style,
  onChangeText,
  disabled,
  placeholder,
  maxLength,
  minLength
}: IPropsInputTextArea) => {
  return (
    <div className='ip-textArea'>
      <TextArea
        className='border-r'
        value={state}
        style={style}
        onChange={onChangeText}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
      />
    </div>
  )
}

export default InputTextAreaComponent
