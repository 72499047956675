import { ECustomerStatus } from 'interfaces/enums/ECustomerStatus'
import { CustomerActionTypes, ICustomerState } from 'redux/actions/customer/customerTypes'
import { ECustomerActions } from 'redux/actions/customer/ECustomerAction'

const initialState: ICustomerState = {
  customerList: {
    content: [],
    currentPage: 1,
    totalPages: 1,
    payloadSize: 20,
    hasNext: false,
    skippedRecords: 0,
    totalRecords: 0
  },
  customerDetail: {
    id: 0,
    username: '',
    fullname: '',
    email: '',
    birthday: new Date(),
    phone: '',
    imageName: '',
    isSuperUser: false,
    status: ECustomerStatus.ACTIVE
  }
}
const customerReducer = (
  state: ICustomerState = initialState,
  action: CustomerActionTypes
): ICustomerState => {
  switch (action.type) {
    case ECustomerActions.GET_CUSTOMER_MASTER_DATA:
      return {
        ...state
      }
    default:
      return state
  }
}
export default customerReducer
