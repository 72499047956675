import { Col, Row } from 'antd'
import {
  IconDashActive,
  IconDashBattery,
  IconDashSensor,
  IconDashStop,
  IconDashUp,
  IconDashUser,
  IconDashWarning
} from 'assets'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import 'styles/Dashboard.scss'
import { ADMIN_ROUTE, routesSensorManagement, routesUserManagement } from 'views/routes/routes'
interface IData {
  dataDashboard: any
}
const _renderItem = (icon: string, label: string, value: string, cls: string, alt: string) => {
  return (
    <div className='d-flex align-items-center'>
      <img src={icon} alt={alt} className='wh32x' />
      <span className={`ml10x ${cls}`}>
        {label} {value}
      </span>
    </div>
  )
}
const _renderDashboard = (
  title: string,
  path: string,
  total: string,
  icon: string,
  alt: string,
  value: string,
  textTotal: string
) => {
  return (
    <div className={`h135x`}>
      <div className='pd'>
        <div className='d-flex j-between custom-bdr-bt h80x'>
          <div>
            <div className='custom-link'>
              <Link to={path}>{title}</Link>
            </div>
            <div className='mt20x fw500 fs_22x'>{total}</div>
          </div>
          <div>
            <img src={icon} alt={alt} className={'wh45x'} />
          </div>
        </div>
        <div className='pt5x'>
          <img src={IconDashUp} alt='up' className='wh24x' />
          <span className='cls_5BC146'>
            +{value}
            {textTotal}
          </span>
        </div>
      </div>
    </div>
  )
}
function DetailTotalCustomer({ dataDashboard }: IData) {
  const { t } = useTranslation()
  return (
    <div>
      <Row>
        <Col span={6} className='pdr'>
          <div className='bgrffffff'>
            {_renderDashboard(
              t('dashBoard.userRegister'),
              ADMIN_ROUTE.concat(routesUserManagement.USER_LIST),
              dataDashboard?.totalUserRegister,
              IconDashUser,
              'user',
              dataDashboard?.numberUserInc,
              t('dashBoard.textTotalUser')
            )}
          </div>
        </Col>
        <Col span={18} className='pdl'>
          <div className={`bgrffffff h135x`}>
            <Row>
              <Col span={8}>
                {_renderDashboard(
                  t('dashBoard.sensorRegister'),
                  ADMIN_ROUTE.concat(routesSensorManagement.SENSOR_LIST),
                  dataDashboard?.totalSensorRegister,
                  IconDashSensor,
                  'sensor',
                  dataDashboard?.numberSensorInc,
                  t('dashBoard.textTotalSensor')
                )}
              </Col>
              <Col span={16}>
                <div className='pd'>
                  <Row className='h75x mlRow'>
                    <Col span={12}>
                      {_renderItem(
                        IconDashWarning,
                        t('dashBoard.labelCommunication'),
                        dataDashboard?.totalCommunication,
                        'cls_E93A44',
                        'warning'
                      )}
                    </Col>
                    <Col span={12}>
                      {_renderItem(
                        IconDashActive,
                        t('dashBoard.labelOperation'),
                        dataDashboard?.totalOperation,
                        'cls_5BC146',
                        'active'
                      )}
                    </Col>
                  </Row>
                  <Row className='mlRow'>
                    <Col span={12}>
                      {_renderItem(
                        IconDashBattery,
                        t('dashBoard.labelBattery'),
                        dataDashboard?.totalBattery,
                        'cls_FDB901',
                        'battery'
                      )}
                    </Col>
                    <Col span={12}>
                      {_renderItem(
                        IconDashStop,
                        t('dashBoard.lableStop'),
                        dataDashboard?.totalStop,
                        'cls_7258FF',
                        'stop'
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default DetailTotalCustomer
