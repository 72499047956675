/* eslint-disable */
import { Col, Form, Row } from 'antd'
import { IconArrowDown, IconArrowUp } from 'assets'
import IconButton from 'components/button/IconButton'
import VButton from 'components/button/VButton'
import VButtonOrder from 'components/button/VButtonOrder'
import InputComponent from 'components/common/input/InputComponent'
import InputDateComponent from 'components/common/input/InputDateComponent'
import InputTextAreaComponent from 'components/common/input/InputTextAreaComponent'
import SelectComponent from 'components/common/select/SelectComponent'
import TableComponent from 'components/common/table/TableComponent'
import { useEffect, useRef, useState } from 'react'
import 'styles/order.scss'
import { unshipOrderDefaultColumns } from './defaultColumns'
import { getOrderDetailAction, updateOrderAction } from 'redux/actions/order/orderAction'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import { listCustomerAction } from 'redux/actions/customer/customerAction'
import { getListSensorAction, getSerialForOrderAction } from 'redux/actions/serialNo/serialNoAction'
import moment from 'moment'
import { IDataOrderType } from 'interfaces/order/IOrder'
import { ADMIN_ROUTE, routeOrder, routeSerial } from 'views/routes/routes'
import SerialDetailPopup from './serialDetailPopup';

function EditOrder() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const refDate = useRef()
  const styleTile = { minHeight: 90, maxHeight: 90 }
  const [date, setDate] = useState<Date | null>(null)
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [notes, setNotes] = useState<string>('')
  const columns = unshipOrderDefaultColumns((record: any) => handleOnClickDetail(record))
  const location: any = useLocation()
  const orderItem = location?.state?.item
  //data customer
  const [listCustomer, setListCustomer] = useState<any>([])
  const [selectedCustomer, setSelectedCustomer] = useState<any>()
  //data communication type
  const [listCommunicationType, setListCommunicationType] = useState<any>([])
  const [selectCommunication, setSelectCommunication] = useState<any>()
  //data device type
  const [listDeviceType, setListDeviceType] = useState<any>([])
  const [selectDevice, setSelectDevice] = useState<any>()
  // table
  const [dataTableUnsend, setDataTableUnsend] = useState<IDataOrderType[]>([])
  const [dataTableSend, setDataTableSend] = useState<IDataOrderType[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>()
  // const [selectedRowKeysSend, setSelectedRowKeysSend] = useState<any>([])
  const [flagAddRemove, setFlagAddRemove] = useState<any>([])
  // flag onblur text search
  const [flagOnblur, setFlagOnBlur] = useState<boolean>(false)

  const [modalDetailPopup, setModalDetailPopup] = useState<boolean>(false)
  const [detailShowId, setDetailShowId] = useState<number>()

  useEffect(() => {
    const getListCustomer = async () => {
      const param: any = {
        sortBy: {
          target: 'updatedAt',
          order: 'DESC'
        }
      }
      await dispatch(
        listCustomerAction(param, true, (data) => {
          if (data?.result) {
            const dataList: any = []
            const record = data?.result?.record
            record?.map((item: any) => {
              const cus = {
                id: item?.id,
                name: item?.customerName,
                value: item?.id
              }
              dataList.push(cus)
            })
            setListCustomer(dataList.sort((a: any, b: any) => a.id - b.id))
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        })
      )
    }

    getListCustomer()
    getDataTabelUnsend(null)
    // eslint-disable-next-line
  }, [])
  const getSensorList = () => {
    const params = {
      type: selectCommunication === '1' ? 'LoRa' : ''
    }
    dispatch(
      getListSensorAction(
        params,
        (resp) => {
          if (resp?.result) {
            const listComunicationType: any = []
            resp?.result?.sensorTypes.map((item: any) => {
              listComunicationType.push({
                id: item.sensorTypeId,
                name: item.sensorTypeKey,
                value: item.sensorTypeId
              })
            })
            const listDeviceType: any = []
            resp?.result?.sensorTranslate.map((item: any) => {
              listDeviceType.push({
                id: item.sensorTypeTranslateId,
                name: item.sensorTypeName,
                value: item.sensorTypeTranslateId
              })
            })
            setListCommunicationType(listComunicationType)
            setListDeviceType(listDeviceType)
          }
        },
        true
      )
    )
  }
  useEffect(() => {
    getSensorList()
    // eslint-disable-next-line
  }, [selectCommunication])
  useEffect(() => {
    const params: any = {
      sensorTypeTranslateId: selectDevice,
      sensorTypeId: selectCommunication,
      modelRegistrationModel: form?.getFieldValue('katashikiUnsend')?.trim(),
      serialNoNumber: form?.getFieldValue('serialNoUnsend')?.trim()
    }
    getDataTabelUnsend(params)
    // eslint-disable-next-line
  }, [selectDevice, selectCommunication, flagOnblur])
  const checkSerialStatus = (data: any) => {
    if (data === true) {
      return '済'
    } else {
      return '未'
    }
  }
  const getDataTabelUnsend = async (param: any) => {
    const params: any = {
      sensorTypeTranslateId: param?.selectDevice
        ? parseInt(param?.selectDevice, 10)
        : parseInt(selectDevice, 10),
      sensorTypeId: param?.selectCommunication
        ? parseInt(param?.selectCommunication)
        : parseInt(selectCommunication, 10),
      modelRegistrationModel: param?.modelRegistrationModel ? param?.modelRegistrationModel : null,
      serialNoNumber: param?.serialNoNumber ? param?.serialNoNumber : null,
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    await dispatch(
      getSerialForOrderAction(
        params,
        (resp) => {
          if (resp?.result?.record) {
            let listDataUnsend: any = []
            const record = resp?.result?.record
            record.map((item: any) => {
              if (!item?.ordDetails) {
                listDataUnsend.push({
                  id: item?.id,
                  key: item?.id,
                  serialNo: item?.serialNoNumber,
                  deviceId: item?.deviceId || 'N/A',
                  type: item?.modelRegistration?.model,
                  communicationType: item?.sensorType?.sensorTypeKey,
                  deviceType: item?.sensorTypeTranslate?.sensorTypeName,
                  communicationConfirm: checkSerialStatus(item?.confirmed)
                })
              }
            })
            if (dataTableSend.length > 0) {
              const listKeySend: any = []
              dataTableSend.map((item) => {
                listKeySend.push(item?.key)
              })
              listDataUnsend = listDataUnsend.filter(function (el: any) {
                return listKeySend.indexOf(el.key) < 0
              })
            }
            setDataTableUnsend(listDataUnsend)
            //get data table send the first
            if (
              param?.serialNoNumber === undefined &&
              param?.modelRegistrationModel === undefined
            ) {
              const listDataSendFirst: any = []
              record.map((item: any) => {
                if (item?.ordDetails?.order?.id === orderItem) {
                  listDataSendFirst.push({
                    id: item?.id,
                    key: item?.id,
                    serialNo: item?.serialNoNumber,
                    deviceId: item?.deviceId || 'N/A',
                    type: item?.modelRegistration?.model,
                    communicationType: item?.sensorType?.sensorTypeKey,
                    deviceType: item?.sensorTypeTranslate?.sensorTypeName,
                    communicationConfirm: checkSerialStatus(item?.confirmed)
                  })
                }
              })
              setDataTableSend(listDataSendFirst)
              setIsSearch(true)
            }
          }
        },
        true
      )
    )
  }
  const apiGetOrderDetail = async () => {
    const param: any = {
      orderId: orderItem
    }
    dispatch(
      getOrderDetailAction(param, async (resp) => {
        if (resp?.result) {
          const customer = await resp?.result?.customer
          const dataRes = await resp?.result
          if (dataRes?.dateDelivery !== null) {
            setDate(moment(dataRes?.dateDelivery).toDate())
          } else {
            setDate(null)
          }
          setSelectedCustomer(customer?.id)
          form.setFieldsValue({
            order: resp?.result?.orderCode,
            deliveryLocation: resp?.result?.address
          })
          setNotes(resp?.result?.note)
          dispatch(SetLoadingAction(false))
        }
      })
    )
  }
  useEffect(() => {
    apiGetOrderDetail()
    // eslint-disable-next-line
  }, [])
  const handleOnChangeDate = (date: any) => {
    setDate(date)
  }
  const handleOnClickDetail = (record: any) => {
    setModalDetailPopup(true)
    setDetailShowId(record?.key)
  }
  const handleOnChangeCommunicationType = (value: any) => {
    setSelectCommunication(value)
  }
  const handleOnChangeDeviceType = (value: any) => {
    setSelectDevice(value)
  }
  const handleOnChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value)
  }
  // select order from 2 tables
  const orderSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRowKeys(selectedRowKeys)
      const listCheck = dataTableUnsend.filter((item1) =>
        selectedRows.some((item2: { id: number }) => item2.id === item1.id)
      )
      setFlagAddRemove(listCheck.length > 0 ? 'add' : 'remove')
    }
  }
  // handle select dropdown
  const handleCustomerDropdownChanged = (value: any) => {
    setSelectedCustomer(value)
  }
  const onBlurTextSearch = () => {
    setFlagOnBlur(!flagOnblur)
  }
  // change item between table
  const handleOnClickAddToSend = () => {
    if (selectedRowKeys) {
      let dataSendUp: any = []
      dataSendUp = dataSendUp.concat(dataTableSend)
      selectedRowKeys.map((item: number) => {
        const rowFind: any = dataTableUnsend.find((it) => it.key === item)
        dataSendUp.push(rowFind)
      })
      let dataUnSendUp: any = []
      dataUnSendUp = dataTableUnsend.filter(function (el) {
        return selectedRowKeys.indexOf(el.key) < 0
      })
      setDataTableSend(dataSendUp)
      setDataTableUnsend(dataUnSendUp)
      setSelectedRowKeys(null)
      setIsSearch(false)
    }
  }

  const handleOnClickRemoveFromSend = () => {
    if (selectedRowKeys) {
      let dataUnSendUp: any = []
      dataUnSendUp = dataUnSendUp.concat(dataTableUnsend)
      selectedRowKeys.map((item: number) => {
        const rowFind: any = dataTableSend.find((it) => it.key === item)
        dataUnSendUp.push(rowFind)
      })
      let dataSendUp: any = []
      dataSendUp = dataTableSend.filter(function (el) {
        return selectedRowKeys.indexOf(el.key) < 0
      })
      setDataTableSend(dataSendUp)
      setDataTableUnsend(dataUnSendUp)
      setSelectedRowKeys([])
      setIsSearch(false)
    }
  }
  // disable send data to table
  const disableAddToSend = () => {
    if (flagAddRemove === 'add' && selectedRowKeys?.length > 0) {
      return false
    } else {
      return true
    }
  }
  const disableAddToUnsend = () => {
    if (flagAddRemove === 'remove' && selectedRowKeys?.length > 0) {
      return false
    } else {
      return true
    }
  }
  const createOrder = async () => {
    const arrDataSend: any = []
    dataTableSend.map((item) => {
      arrDataSend.push(item?.key)
    })
    const params: any = {
      customerId: Number(selectedCustomer),
      orderNo: form?.getFieldValue('order')?.trim(),
      dateDelivery: date ? moment(date).format('YYYY/M/DD') : null,
      address: form?.getFieldValue('deliveryLocation')?.trim(),
      listOrdersId: arrDataSend,
      note: notes,
      orderId: orderItem
    }
    await dispatch(
      updateOrderAction(
        params,
        (resp) => {
          if (resp?.code !== 400) {
            dispatch(SetLoadingAction(false))
            showSuccess(
              t('customerScreen.createSuccess'),
              ADMIN_ROUTE.concat(routeOrder.LIST_ORDER)
            )
          }
        },
        true
      )
    )
  }
  const goToListOrder = () => {
    history.push(ADMIN_ROUTE.concat(routeOrder.LIST_ORDER))
  }
  const showSuccess = (text: string, href?: any) => {
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text,
      href: href,
      isRedirect: true
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }
  return (
    <div className='bgrffffff'>
      <div className='order-create pd_16x_0x'>
        <div className='ss-search-email mg_0_16x'>
          <div className='label-bold'>出荷情報入力</div>
          <Form form={form}>
            <Row gutter={24}>
              <Col span={12}>
                <SelectComponent
                  lable='スペース名'
                  dataOption={listCustomer}
                  defaultOption={selectedCustomer}
                  disableAll={true}
                  onChangeSelect={handleCustomerDropdownChanged}
                />
              </Col>
              <Col span={12}>
                <div className='order-date-select'>納入確定日</div>
                <InputDateComponent
                  refer={refDate}
                  state={date}
                  readOnly={false}
                  selectStart={true}
                  // minDate={new Date()}
                  onChangeDate={(date: Date) => handleOnChangeDate(date)}
                  customPopper='bottom'
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <InputComponent name='order' type='text' label='オーダ' maxLength={64} />
              </Col>
              <Col span={12}>
                <InputComponent
                  name='deliveryLocation'
                  type='text'
                  label='納入場所'
                  maxLength={255}
                />
              </Col>
            </Row>
            <div className='label-regis-order'>出荷機器登録</div>
            <div className='unship-order-container'>
              <div className='table-unship-order pd_16x_16x'>
                <div className='unship-order-label'>未出荷一覧</div>
                <Row gutter={15}>
                  <Col span={6}>
                    <InputComponent
                      name='serialNoUnsend'
                      type='text'
                      label='製造番号'
                      maxLength={64}
                      onBlur={onBlurTextSearch}
                    />
                  </Col>
                  <Col span={6}>
                    <InputComponent
                      name='katashikiUnsend'
                      type='text'
                      label='型式'
                      maxLength={64}
                      onBlur={onBlurTextSearch}
                    />
                  </Col>
                  <Col span={6}>
                    <SelectComponent
                      lable='通信種別'
                      disableAll={true}
                      dataOption={listCommunicationType}
                      defaultOption={selectCommunication}
                      onChangeSelect={handleOnChangeCommunicationType}
                    />
                  </Col>
                  <Col span={6}>
                    <SelectComponent
                      lable='機器種別'
                      disableAll={true}
                      dataOption={listDeviceType}
                      defaultOption={selectDevice}
                      onChangeSelect={handleOnChangeDeviceType}
                    />
                  </Col>
                </Row>
                <div className='custom-table'>
                  <TableComponent
                    isSearch={isSearch}
                    columns={columns}
                    dataSource={dataTableUnsend}
                    rowSelection={orderSelection}
                    tableScroll={{ y: 240 }}
                  />
                </div>
              </div>
            </div>
            <div className='group-btn-add-remove pd_16x_0x'>
              <VButton
                title='戻す'
                btnIcon={<IconButton icon={IconArrowUp} />}
                onClick={handleOnClickRemoveFromSend}
                type='text'
                style={{ color: '#FFFFFF' }}
                disabled={disableAddToUnsend()}
              />
              <VButtonOrder
                title='追加'
                btnIcon={<IconButton icon={IconArrowDown} />}
                onClick={handleOnClickAddToSend}
                type='text'
                disabled={disableAddToSend()}
              />
            </div>
            <div className='ship-order-label'>出荷一覧</div>
            <div className='custom-table'>
              <TableComponent
                columns={columns}
                dataSource={dataTableSend}
                rowSelection={orderSelection}
                tableScroll={{ y: 240 }}
              />
            </div>
            <div className='ship-order-label' style={{ marginTop: 32 }}>
              備考
            </div>
            <div className='pd_16x_16x'>
              <InputTextAreaComponent
                maxLength={625}
                state={notes}
                style={styleTile}
                onChangeText={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleOnChangeText(e)}
                disabled={false}
                placeholder={''}
              />
            </div>
            <div className='group-btn' style={{ padding: 16 }}>
              <VButton
                title='登録'
                onClick={createOrder}
                type='text'
                style={styleBtnSubmit}
                disabled={dataTableSend.length === 0 && !selectedCustomer}
              />
              <VButton
                title={t('sensorManagementScreen.btnCancel')}
                onClick={goToListOrder}
                type='text'
                style={styleBtnCancel}
              />
            </div>
          </Form>
        </div>
      </div>
      {modalDetailPopup && detailShowId ? (
        <SerialDetailPopup
          title={t('serialNoScreen.individualDetail.serialDetailLabel')}
          idValue={detailShowId}
          onClosePopup={() => setModalDetailPopup(false)}
        />
      ) : null}
    </div>
  )
}
const styleBtnSubmit = {
  height: 38,
  width: 76,
  backgroundColor: '#d7000f',
  color: '#fff',
  borderColor: ' #d7000f',
  marginRight: 16
}
const styleBtnCancel = {
  height: 38,
  width: 116,
  backgroundColor: '#fff',
  color: ' #d7000f',
  border: 'solid 1px #e3242b'
}

export default EditOrder
