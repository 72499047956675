import { convertTypeSensor } from 'views/pages/sensor/convertData'
import { communicationStatusSetting } from 'views/pages/sensor/defaultValue'

const convertDataUserDetail = (data: any) => {
  const dataUserDetail = {
    userId: data?.userId,
    userName: data?.username,
    registerDate: data?.createdAt,
    numberSensorGroup: data?.totalGroup.toString(),
    numberSensor: data?.totalSensor.toString(),
    email: data?.email,
    role: data?.role?.roleName,
    statusUser: data?.statusUser
  }
  return dataUserDetail
}
const convertListUserAlert = (data: any) => {
  data = data?.record?.map((item: any, index: number) => {
    return {
      key: item?.userEmailAlertId,
      emailAlert: item?.email,
      statusEmail: item?.statusActive ? 'active' : 'stop'
    }
  })
  return data
}
const convertListSensor = (data: any) => {
  data = data?.record?.map((item: any, index: number) => {
    return {
      key: item?.sensorAiId,
      no: index + 1,
      deviceID: item?.deviceId,
      groupId: item?.group?.groupId,
      groupName: item?.group?.groupName,
      sensorName: item?.sensorName,
      sensorType: convertTypeSensor(item?.sensorType?.sensorTypeTranslate),
      lteOrLora: item?.sensorTypeParent?.sensorTypeKey,
      imeiNumber: item?.imei,
      securityKey: item?.securityKey,
      communicationStatus:
        item?.statusTransmission === 1
          ? communicationStatusSetting.abnormal
          : item?.statusTransmission === 0
          ? communicationStatusSetting.normal
          : '',
      battery:
        item?.statusBattery === 1
          ? communicationStatusSetting.abnormal
          : item?.statusBattery === 0
          ? communicationStatusSetting.normal
          : '',
      registerDate: item?.createdAt,
      lastDate: item?.pushAtData
    }
  })
  return data
}
const convertListGroupNosensor = (data: any) => {
  data = data?.map((item: any, index: number) => {
    return {
      key: item?.groupId,
      no: index + 1,
      deviceID: '',
      groupId: item?.groupId,
      groupName: item?.groupName,
      sensorName: '',
      sensorType: '',
      lteOrLora: '',
      imeiNumber: '',
      securityKey: '',
      communicationStatus: '',
      registerDate: '',
      lastDate: ''
    }
  })
  return data
}
export { convertListUserAlert, convertListSensor, convertDataUserDetail, convertListGroupNosensor }
