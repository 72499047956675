import TableComponent from 'components/common/table/TableComponent'
import { useTranslation } from 'react-i18next'
import { ConvertTime } from 'utils/Functions'
interface IPropsTableLora {
  data: any
}
function TableTypeLora({ data }: IPropsTableLora) {
  const { t } = useTranslation()
  const columns = [
    {
      title: t('sensorDetail.tableLora.no'),
      dataIndex: 'no',
      width: '5%'
    },
    {
      title: t('sensorDetail.tableLora.category'),
      dataIndex: 'category',
      width: '14%'
    },
    {
      title: t('sensorDetail.tableLora.logDateTime'),
      dataIndex: 'logDateTime',
      render: (_: object, record: { logDateTime: string }) => {
        return ConvertTime(record?.logDateTime)
      }
    }
  ]
  return <TableComponent columns={columns} dataSource={data} />
}
export default TableTypeLora
