export enum EDisplayActions {
  DISPLAY_LIST = 'DISPLAY_LIST',
  DELETE_FORMULA = 'DELETE_FORMULA',
  COPY_FORMULA = 'COPY_FORMULA',
  GET_DETAIL_FORMULA = 'GET_DETAIL_FORMULA',
  CREATE_FORMULA = 'CREATE_FORMULA',
  UPDATE_FORMULA = 'UPDATE_FORMULA',
  UPDATE_COPY_FORMULA = 'UPDATE_COPY_FORMULA',
  CLEAN_FORMULA = 'CLEAN_FORMULA'
}
