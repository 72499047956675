import DatePicker from 'react-datepicker'
import { IconClock } from 'assets'
import 'react-datepicker/dist/react-datepicker.css'
import 'styles/InputTime.scss'
import { IPropsInputTime } from 'interfaces/input/IPropsInputTime'
import { ja } from 'date-fns/locale'
import { forwardRef } from 'react'

const InputTimeComponent = ({
  refer,
  state,
  readOnly,
  onChangeDate,
  disabled
}: IPropsInputTime) => {
  const formatDate = 'H:mm a'
  const CustomInputTime = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className='custom-input-time pointer' onClick={onClick} ref={ref}>
      <div className='time-text'>{value}</div>
      <img className='icon-calendar' alt='clock' src={IconClock} />
    </div>
  ))
  return (
    <div className='ip-time'>
      <DatePicker
        ref={refer}
        selected={state}
        dateFormat={formatDate}
        onChange={onChangeDate}
        autoComplete='off'
        className='ip-timePicker'
        disabledKeyboardNavigation
        maxDate={new Date()}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={1}
        timeCaption='時間'
        readOnly={readOnly}
        locale={ja}
        disabled={disabled}
        customInput={<CustomInputTime />}
      />
    </div>
  )
}

export default InputTimeComponent
