import { Methods } from 'services/api/EMethod'

export const getDataDashboardRequest = () => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.DASHBOARD,
    params: {}
  }
}
