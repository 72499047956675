import { Col, Row, Table, Input, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { ADMIN_ROUTE, routesDisplayManagement, routesName } from 'views/routes/routes'
import SelectComponent from 'components/common/select/SelectComponent'
import { ColumnsType } from 'antd/es/table'
import React, { useState, useEffect } from 'react'
import {
  IconDeleteDisable,
  IconDeleteRed,
  IconEditBlue,
  IconEditDisable,
  IconStopModal
} from 'assets'
import 'styles/IndividualSetting.scss'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/reducers'
import { defaultTypeSensorLora, defaultValueAll } from '../sensor/defaultValue'
import { GetAttributesAction } from 'redux/actions/attributes/attributeAction'
import { IAttribute, IDataTypeAddAtribute } from 'interfaces/attributes/IAttribute'
import { validatorAttribute } from '../multiSettings/validatorAttribute'
import TableComponent from 'components/common/table/TableComponent'
import VButton from 'components/button/VButton'
import { v4 as uuidv4 } from 'uuid'
import {
  convertFullsizeToHalfsize,
  // formatFormula,
  formatExpFormulaOther,
  formatFormulaOther
} from 'utils/Functions'
import { convertListEmail } from '../sensor/convertData'
import { IOption } from 'interfaces/sensor/IPropsSelect'
import { checkFormulaByDeviceIdAction, searchSensorAction } from 'redux/actions/sensor/sensorAction'
import { convertDataListSensorOption } from '../individualSettings/convertData'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import { createFormulaAction, setFormulaCopyAction } from 'redux/actions/display/displayAction'
import { settingTypeDefault } from '../displayManagement/defaultValue'
import { initialFormulaCopy } from '../multiSettings/initialState'
import { convertTypeParentSensorToNumber, getNameAttribute } from '../displayManagement/convertData'
import { convertListAttribute, convertListAttributeAdd } from '../multiSettings/convertData'
import { originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import AccessDeniedComponent from '../accessDenied/AccessDeniedComponent'
import { maxNumberScaleValue, unitFormula } from 'utils/maxLength'
import { ESensorType } from 'interfaces/enums/ESensorType'
import { defaultAttReserve, defaultShowBlankFormula } from '../individualSettings/defaultValue'
import {
  styleBtnAdd,
  styleBtnConfiguration,
  styleBtnReturn,
  styleBtnSeletedSs,
  styleBtnSubmit
} from './style'
import InputReadOnlyComponent from 'components/common/input/InputReadOnlyComponent'
import { EExpKeys, EKey } from 'interfaces/enums/EKey'
import { dataKeyPrefixTv } from '../multiSettings/defaultValue'
import { checkDeleteTv } from './function'
import { convertViewFormula } from '../calculatorSheet/convertData'
import { getListSensorAction } from 'redux/actions/serialNo/serialNoAction'
import { ISensorPullDownType, ISensorTranslate } from 'interfaces/ISerialNo'

const getData = (data: IAttribute[]) => {
  const result = [...data]
  result.map((item) => {
    item.key = item.attributeId
    item.scaleValue = item.scaleValue || 0
    item.unit = item.unit || defaultAttReserve
    item.conversionFormula = item.conversionFormula || defaultShowBlankFormula
    return item
  })
  return result
}
const locale = {
  emptyText: ' '
}
const CreateFormula = () => {
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  const history = useHistory()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [describe, setDescribe] = useState<string>('')

  const dispatch = useDispatch()
  const appState = useSelector((state: AppState) => state)
  const masterData = appState.common.masterData
  const formula = useSelector((state: AppState) => state?.formula)
  // list email
  const defaultListEmail = convertListEmail(masterData?.email)
  // attribute
  const attributes = appState.attributes.attributes
  // state data  table
  const [dataTableAddAtribute, setDataTableAddAtribute] = useState<IDataTypeAddAtribute[]>([])
  const arrAttributeTv = (
    formula?.id && formula?.type === settingTypeDefault.sensor ? formula?.listAttribute : attributes
  )?.filter((item: any) => item?.dataKeyPrefix === EKey.keyPrefixTv)
  // clean state
  const cleanState = async () => {
    setDescribe('')
    setDataTableAddAtribute([])
    setSensor('')
    setSensorName('')
    setDeviceId('')
    setDuplicateErr('')
  }
  // state select option LteOrLora sensor
  const [lteOrLora, setLteOrLora] = useState<string>('')
  const [listLteOrLora, setListLteOrLora] = useState<any>([])
  const [listSensorTranslateType, setListSensorTranslateType] = useState<any>([])
  const handleOnChangeSelectLteOrLora = (valueLteOrLora: string) => {
    setSensorSelected(null)
    setLteOrLora(valueLteOrLora)
    cleanState()
    setSettingTarget('')
    setDuplicateErr('')
    setListSensor([])
    dispatch(setFormulaCopyAction(initialFormulaCopy))
    const getAttributes = () => {
      const params = {
        parentType: valueLteOrLora,
        typeSensor: settingTarget ? settingTarget : ESensorType.PADDY_FILED
      }
      dispatch(GetAttributesAction(params))
    }
    getAttributes()
  }
  // state select sensor from filter
  const [sensor, setSensor] = useState<string>('')
  const [sensorName, setSensorName] = useState<string>('')
  const [deviceId, setDeviceId] = useState<any>('')
  const [duplicateErr, setDuplicateErr] = useState<string>('')
  const [numberAttributeTv, setNumberAttributeTv] = useState<number>(0)
  const [lastIndexAttributeTv, setLastIndexAttributeTv] = useState<number>(0)
  const [listSensor, setListSensor] = useState<IOption[]>([])
  const handleChangeSensor = (sensorValue: string) => {
    setDuplicateErr('')
    setSensorSelected(null)
    const sensorFind = listSensor?.find((item) => item?.value === sensorValue)
    setDeviceId(sensorFind?.parentId ?? '')
    setSensorName(sensorFind?.name ?? '')
    setSensor(sensorValue)
  }
  const [sensorSelected, setSensorSelected] = useState<any>()
  // state select option list email
  const [email, setEmail] = useState<string>(defaultValueAll)
  const handleOnChangeSelectEmail = (valueEmail: string) => {
    setSensorSelected(null)
    setDuplicateErr('')
    setEmail(valueEmail)
    if (formula?.formulaType !== 'copy') {
      cleanState()
      dispatch(setFormulaCopyAction(initialFormulaCopy))
      const getAttributes = () => {
        const params = {
          parentType: lteOrLora ? lteOrLora : defaultTypeSensorLora,
          typeSensor: settingTarget ? settingTarget : ESensorType.PADDY_FILED
        }
        dispatch(GetAttributesAction(params))
      }
      getAttributes()
    }
  }
  // state select option setting target
  const [settingTarget, setSettingTarget] = useState<string>('')
  const handleOnChangeSelectSettingTarget = (valueSettingTarget: string) => {
    setSensorSelected(null)
    setDuplicateErr('')
    setSettingTarget(valueSettingTarget)
    cleanState()
    dispatch(setFormulaCopyAction(initialFormulaCopy))
    const getAttributes = () => {
      const params = {
        parentType: lteOrLora ? lteOrLora : defaultTypeSensorLora,
        typeSensor: valueSettingTarget
      }
      dispatch(GetAttributesAction(params))
    }
    getAttributes()
  }
  // modal confirm
  const [modalConfirm, setModalConfirm] = useState({
    isModalVisible: false,
    content: t('modal.contentSettingSuccess')
  })
  // state edit formula
  const handleOnClickEdit = (
    key: string,
    name: string,
    exp: string,
    typeValidate: boolean,
    attributeKey: string,
    mode: string
  ) => {
    const getForm = form.getFieldsValue()
    const id = uuidv4()
    const data = {
      ...formula,
      id: id,
      type: settingTypeDefault.sensor,
      sensorType: settingTarget,
      email: email,
      deviceId: sensorSelected?.deviceId,
      sensorName: sensorSelected?.sensorName,
      sensorAiId: sensor,
      parentType: lteOrLora,
      description: describe,
      listAttribute: getForm?.listAttributes,
      listAttributeAdd: getForm?.listAttributeAdd,
      numberAttributeTv: numberAttributeTv,
      lastIndexAttributeTv: lastIndexAttributeTv
    }
    dispatch(setFormulaCopyAction(data))
    const path = ADMIN_ROUTE.concat(routesDisplayManagement.CALCULATOR_SHEET + `/${key}/individual`)
    const expConvert = formatFormulaOther(exp, getForm?.listAttributes)
    const expFormat = formatExpFormulaOther(exp, getForm?.listAttributes)
    const nameAtt =
      attributeKey.indexOf('Tv') > -1
        ? name
        : name === defaultAttReserve
        ? t('caculation.reserve')
        : name

    history.push({
      pathname: path,
      state: {
        lteOrLora: lteOrLora,
        settingTarget: settingTarget,
        nameAtt: nameAtt,
        expFromAtt: expConvert,
        typeValidate: typeValidate,
        attributeKey: attributeKey,
        exp: expFormat,
        mode: mode === EExpKeys.DEG_KEY ? false : true
      }
    })
  }
  // change describe
  const handleOnChangeDescribe = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescribe(e.target.value)
  }
  // return last page
  const gotoLastPage = () => {
    history.goBack()
  }
  // render colums attribute setting
  const columns: ColumnsType<IAttribute> = [
    {
      title: t('caculation.table.positionID'),
      key: 'id',
      render: (value: IAttribute) => {
        const valueIndex = value.attributeIndex.toString()
        const result =
          formula?.formulaType === 'copy'
            ? value?.dataKeyPrefix === EKey.keyPrefixTv
              ? value?.attributeKey
              : lteOrLora && lteOrLora !== defaultTypeSensorLora
              ? 'tm'.concat(valueIndex)
              : ' tm(ID*10)+'.concat(valueIndex)
            : lteOrLora && lteOrLora !== defaultTypeSensorLora
            ? 'tm'.concat(valueIndex)
            : ' tm(ID*10)+'.concat(valueIndex)
        return <div className='pointer custom-a'>{result}</div>
      },
      align: 'center',
      width: '8%'
    },
    {
      title: t('caculation.table.sensorSpecs'),
      dataIndex: '',
      align: 'center',
      width: '10%',
      render: (value) => {
        return (
          <div>
            {value?.dataKeyPrefix !== EKey.keyPrefixTv &&
            getNameAttribute(value?.attributeTranslate) === defaultAttReserve
              ? t('caculation.reserve')
              : getNameAttribute(value?.attributeTranslate) || ''}
          </div>
        )
      }
    },
    {
      title: t('caculation.table.sensorUnit'),
      dataIndex: 'unit',
      render: (value, row, index) => {
        return (
          <Form.Item name={[index, 'unit']} rules={validatorAttribute.unit}>
            <Input
              className='edit-columns'
              type='text'
              maxLength={unitFormula}
              onInput={(event) => {
                const value = event.currentTarget.value
                event.currentTarget.value =
                  value === ' ' ? value?.trim() : convertFullsizeToHalfsize(value)
              }}
            />
          </Form.Item>
        )
      },
      align: 'center',
      width: '10%'
    },
    {
      title: t('caculation.table.sensorIndex'),
      dataIndex: 'scaleValue',
      render: (value, row, index) => {
        return (
          <Form.Item name={[index, 'scaleValue']} rules={validatorAttribute.scaleValue}>
            <Input
              type='text'
              className='edit-columns'
              onWheel={(event) => event.currentTarget.blur()}
              min={0}
              max={maxNumberScaleValue}
              onInput={(event) => {
                const value = event.currentTarget.value
                event.currentTarget.value = value.replace(/[^0-9]/g, '')
              }}
            />
          </Form.Item>
        )
      },
      align: 'center',
      width: '10%'
    },
    {
      title: t('caculation.table.defaultFormula'),
      dataIndex: 'conversionFormula',
      render: (value) => {
        const listAtt = attributes.length > 0 ? attributes : formula?.listAttribute
        return (
          <div>
            <InputReadOnlyComponent
              value={convertViewFormula(listAtt, value)}
              className='formula-column text-align-center'
              styleInput={{ height: '32px' }}
            />
          </div>
        )
      },
      align: 'center',
      width: '18%'
    },
    {
      title: t('caculation.table.edit'),
      dataIndex: '',
      render: (value) => {
        const listAtt = attributes.length > 0 ? attributes : formula?.listAttribute
        return (
          <div>
            {duplicateErr === '' ? (
              <img
                onClick={() =>
                  handleOnClickEdit(
                    value?.key,
                    getNameAttribute(value?.attributeTranslate),
                    convertViewFormula(listAtt, value?.conversionFormula),
                    false,
                    'tm'.concat(value?.attributeIndex?.toString()),
                    value?.mode
                  )
                }
                src={IconEditBlue}
                className='w42x-h28x pointer'
                alt='edit'
              />
            ) : (
              <img src={IconEditDisable} className='w42x-h28x pointer' alt='edit-disable' />
            )}
          </div>
        )
      },
      align: 'center',
      width: '5%'
    },
    {
      title: t('caculation.table.delete'),
      dataIndex: '',
      render: (value) => {
        return value?.dataKeyPrefix === EKey.keyPrefixTv ? (
          duplicateErr === '' ? (
            <img
              src={IconDeleteRed}
              onClick={() => handleOnClickDeleteTv(value?.key, value?.attributeKey)}
              className='w42x-h28x pointer'
              alt='delete'
            />
          ) : (
            <img src={IconDeleteDisable} alt='deleteDisable' className='w42x-h28x pointer' />
          )
        ) : (
          <div className='pointer'>---</div>
        )
      },
      align: 'center',
      width: '5%'
    }
  ]
  // render colums add attribute
  const columnsAddAttribute: ColumnsType<IDataTypeAddAtribute> = [
    {
      dataIndex: 'positionID',
      width: '8%',
      align: 'center',
      render: (value, row, index) => {
        return (
          <div className='position-id'>
            <span>{value + (index + 1 + lastIndexAttributeTv)}</span>
          </div>
        )
      }
    },
    {
      dataIndex: 'settingTarget',
      render: (value, row, index) => {
        return (
          <Form.Item name={[index, 'settingTarget']} rules={validatorAttribute.settingTarget}>
            <Input
              className='edit-columns'
              type='text'
              maxLength={64}
              onInput={(event) => {
                const value = event.currentTarget.value
                event.currentTarget.value =
                  value === ' ' ? value?.trim() : convertFullsizeToHalfsize(value)
              }}
            />
          </Form.Item>
        )
      },
      width: '10%',
      align: 'center'
    },
    {
      dataIndex: 'sensorUnit',
      render: (value, row, index) => {
        return (
          <Form.Item name={[index, 'sensorUnit']} rules={validatorAttribute.unit}>
            <Input
              className='edit-columns'
              type='text'
              maxLength={unitFormula}
              onChange={(val) => {
                form.setFields([
                  {
                    name: ['listAttributeAdd', index, 'sensorUnit'],
                    value:
                      val?.target?.value === ' '
                        ? val?.target?.value?.trim()
                        : convertFullsizeToHalfsize(val?.target?.value)
                  }
                ])
              }}
            />
          </Form.Item>
        )
      },
      width: '10%',
      align: 'center'
    },
    {
      dataIndex: 'sensorIndex',
      render: (value, row, index) => {
        return (
          <Form.Item name={[index, 'sensorIndex']} rules={validatorAttribute.scaleValue}>
            <Input
              className='edit-columns'
              type='text'
              onWheel={(event) => event.currentTarget.blur()}
              min={0}
              max={maxNumberScaleValue}
              onInput={(event) => {
                const value = event.currentTarget.value
                event.currentTarget.value = value.replace(/[^0-9]/g, '')
              }}
            />
          </Form.Item>
        )
      },
      width: '10%',
      align: 'center'
    },
    {
      dataIndex: 'conversionFormula',
      render: (conversionFormula, row, index) => {
        const listAtt = attributes.length > 0 ? attributes : formula?.listAttribute
        return (
          <div>
            {conversionFormula ? (
              <InputReadOnlyComponent
                value={convertViewFormula(listAtt, conversionFormula)}
                className='edit-columns'
              />
            ) : (
              <Form.Item
                name={[index, 'conversionFormula']}
                rules={validatorAttribute.conversionFormula}>
                <Input
                  placeholder={t('caculation.defaultFormula')}
                  className='edit-columns'
                  readOnly
                />
              </Form.Item>
            )}
          </div>
        )
      },
      width: '18%',
      align: 'center'
    },
    {
      dataIndex: '',
      render: (value, row, index) => {
        const listAtt = attributes.length > 0 ? attributes : formula?.listAttribute
        return (
          <div>
            {duplicateErr === '' ? (
              <VButton
                onClick={() =>
                  handleOnClickEdit(
                    value?.key,
                    form.getFieldsValue()?.listAttributeAdd?.[index]?.settingTarget,
                    convertViewFormula(listAtt, value?.conversionFormula),
                    true,
                    'Tv',
                    value?.mode
                  )
                }
                title={t('displayManagement.setting.btnSetting')}
                style={styleBtnConfiguration}
                htmlType='button'
                type='text'
              />
            ) : (
              <VButton
                disabled={true}
                title={t('displayManagement.setting.btnSetting')}
                style={styleBtnConfiguration}
                htmlType='button'
                type='text'
              />
            )}
          </div>
        )
      },
      width: '5%',
      align: 'center'
    },
    {
      dataIndex: '',
      render: (_: object, record: { key: string }) => {
        return (
          <div>
            {duplicateErr === '' ? (
              <img
                src={IconDeleteRed}
                onClick={() => handleOnClickDeleteAttributeAdd(record?.key)}
                className='w42x-h28x pointer'
                alt='delete'
              />
            ) : (
              <img src={IconDeleteDisable} alt='deleteDisable' className='w42x-h28x pointer' />
            )}
          </div>
        )
      },
      width: '5%',
      align: 'center'
    }
  ]
  const handleGetListSensor = () => {
    const params = {
      type: lteOrLora === defaultValueAll || lteOrLora === 'LoRa' ? 'LoRa' : ''
    }
    dispatch(
      getListSensorAction(
        { ...params },
        async (data) => {
          if (data && data?.result) {
            dispatch(SetLoadingAction(false))
            const resultCommsType: any = []
            const resultTypes: any = []
            data.result.sensorTypes = await data.result.sensorTypes.map(
              (
                { sensorTypeId, sensorTypeKey }: ISensorPullDownType // eslint-disable-next-line
              ) => {
                resultCommsType.push({
                  id: sensorTypeId,
                  name: sensorTypeKey,
                  value: sensorTypeKey
                })
              }
            )
            if (lteOrLora === 'LoRa') {
              data.result.sensorTranslate = await data.result.sensorTranslate.slice(0, 3).map(
                (
                  { sensorTypeTranslateId, sensorTypeName, sensorTypeKey }: ISensorTranslate // eslint-disable-next-line
                ) => {
                  resultTypes.push({
                    id: sensorTypeTranslateId,
                    name: sensorTypeName,
                    value: sensorTypeKey
                  })
                }
              )
            } else {
              data.result.sensorTranslate = await data.result.sensorTranslate.slice(0, 2).map(
                (
                  { sensorTypeTranslateId, sensorTypeName, sensorTypeKey }: ISensorTranslate // eslint-disable-next-line
                ) => {
                  resultTypes.push({
                    id: sensorTypeTranslateId,
                    name: sensorTypeName,
                    value: sensorTypeKey
                  })
                }
              )
            }
            setListLteOrLora(resultCommsType)
            setListSensorTranslateType(resultTypes)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        false
      )
    )
  }
  useEffect(() => {
    handleGetListSensor()
    // eslint-disable-next-line
  }, [lteOrLora])
  // add
  const handleClickAddAttribute = () => {
    const key = uuidv4()
    const getFormAttribute = form.getFieldsValue()?.listAttributeAdd
    const newData: IDataTypeAddAtribute = {
      key: key?.toString(),
      positionID: t('caculation.positionTv'),
      settingTarget: '',
      sensorUnit: '',
      sensorIndex: '',
      conversionFormula: '',
      dataKeyPrefix: dataKeyPrefixTv
    }
    setDataTableAddAtribute([...getFormAttribute, newData])
  }
  // delete
  const handleOnClickDeleteAttributeAdd = (key: string) => {
    const getFormAttribute = form.getFieldsValue()?.listAttributeAdd
    setDataTableAddAtribute(getFormAttribute.filter((item: any) => item.key !== key))
  }
  const handleOnClickDeleteTv = (key: string, attributeKey: string) => {
    let listAtt = form.getFieldsValue()?.listAttributes
    let listAttAdd = form.getFieldsValue()?.listAttributeAdd
    const arrDeleteTv = checkDeleteTv(
      key,
      attributeKey,
      listAtt,
      listAttAdd,
      lteOrLora,
      formula?.formulaType,
      numberAttributeTv
    )
    if (arrDeleteTv?.length) {
      const stateSuccess = {
        isVisible: true,
        content: t('errors.tvUsed', { 0: attributeKey, 1: arrDeleteTv?.join(', ') }),
        isImg: true,
        src: IconStopModal
      }
      dispatch(SuccessAction({ ...stateSuccess }))
    } else {
      if (formula?.formulaType === 'copy') {
        const getListAttributes = form.getFieldsValue()?.listAttributes
        let arr = [...getListAttributes]
        arr = arr?.filter((item: any) => item?.key !== key)
        dispatch(
          setFormulaCopyAction({
            ...formula,
            listAttribute: arr
          })
        )
      }
    }
  }
  const onFinishTable = (val: any) => {
    const arrAttribute = convertListAttribute(val?.listAttributes)
    const arrAtributeAdd = convertListAttributeAdd(
      val?.listAttributeAdd,
      val?.listAttributes?.length,
      lastIndexAttributeTv
    )
    const param = {
      type: settingTypeDefault.sensor,
      sensorType: settingTarget,
      parentType: convertTypeParentSensorToNumber(lteOrLora),
      email: email === defaultValueAll ? '' : email,
      deviceId: deviceId,
      sensorName: sensorName,
      description: describe,
      listAttribute: arrAttribute.concat(arrAtributeAdd)
    }
    dispatch(
      createFormulaAction(true, param, (data) => {
        if (data?.code) {
          dispatch(SetLoadingAction(false))
          setDuplicateErr(t('displayManagement.duplicateSensor'))
          window.scrollTo(0, 0)
        } else {
          dispatch(setFormulaCopyAction(initialFormulaCopy))
          setDuplicateErr('')
          setModalConfirm({
            ...modalConfirm,
            isModalVisible: false
          })
          // modal success
          const stateSuccess = {
            isVisible: true,
            content: t('modal.contentSettingSuccess'),
            isRedirect: true,
            href: ADMIN_ROUTE.concat(routesName.DISPLAY_MANAGEMENT)
          }
          dispatch(SuccessAction({ ...stateSuccess }))
        }
      })
    )
  }
  const onSelectedSs = () => {
    const params = {
      deviceId: deviceId
    }
    dispatch(
      checkFormulaByDeviceIdAction(true, params, (data) => {
        const ssSelected = {
          sensorName: sensorName,
          deviceId: deviceId
        }
        if (data?.result) {
          setSensorSelected(ssSelected)
          setDuplicateErr(t('displayManagement.duplicateSensor'))
          dispatch(SetLoadingAction(false))
        } else if (!data?.result) {
          setSensorSelected(ssSelected)
          setDuplicateErr('')
          dispatch(SetLoadingAction(false))
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
  }
  useEffect(() => {
    const getAttributes = () => {
      const params = {
        parentType: lteOrLora ? lteOrLora : defaultTypeSensorLora,
        typeSensor: settingTarget ? settingTarget : ESensorType.PADDY_FILED
      }
      dispatch(GetAttributesAction(params))
    }
    if (!formula?.id && formula.type !== settingTypeDefault.sensor) {
      getAttributes()
    }
    // eslint-disable-next-line
  }, [dispatch])
  useEffect(() => {
    const initialForm = {
      listAttributes:
        formula?.id && formula.type === settingTypeDefault.sensor
          ? formula?.listAttribute
          : attributes
    }
    form.setFieldsValue(initialForm)
    // eslint-disable-next-line
  }, [form, attributes, formula])
  useEffect(() => {
    const initialForm = {
      listAttributeAdd: dataTableAddAtribute
    }
    form.setFieldsValue(initialForm)
    // eslint-disable-next-line
  }, [form, dataTableAddAtribute])
  useEffect(() => {
    const getListSensor = async () => {
      dispatch(SetLoadingAction(true))
      const param = {
        sensorTypeKey: settingTarget ? settingTarget : ESensorType.PADDY_FILED,
        userEmail: email === defaultValueAll ? '' : email,
        parentSensorTypeKey: lteOrLora ? lteOrLora : defaultTypeSensorLora,
        groupName: '',
        sortBy: {
          target: 'createdAt',
          order: 'ASC'
        }
      }
      dispatch(
        searchSensorAction(true, param, (data) => {
          if (data?.result) {
            const listData = convertDataListSensorOption(data?.result?.record)
            setListSensor(listData)
            if (formula?.id && formula.type === settingTypeDefault.sensor) {
              setSensorName(formula?.sensorName ?? '')
              const sensorFind: any = listData?.find(
                (item: any) =>
                  item?.parentId === formula?.deviceId && item?.name === formula?.sensorName
              )
              setSensor(sensorFind?.value)
            }
            if (formula?.id && formula.type && formula?.listAttributeAdd?.length) {
              setDataTableAddAtribute(formula?.listAttributeAdd)
            }
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        })
      )
    }
    if (lteOrLora && settingTarget) {
      getListSensor()
    }
    // eslint-disable-next-line
  }, [dispatch, lteOrLora, settingTarget, email])
  useEffect(() => {
    if (formula?.id && formula.type === settingTypeDefault.sensor) {
      setDescribe(formula?.description)
      setLteOrLora(formula?.parentType)
      setSettingTarget(formula?.sensorType)
      setEmail(formula?.email ?? defaultValueAll)
      setDeviceId(formula?.deviceId ?? '')
      setSensorSelected({
        sensorName: formula?.sensorName,
        deviceId: formula?.deviceId
      })
      setNumberAttributeTv(formula?.numberAttributeTv ?? 0)
      setLastIndexAttributeTv(formula?.lastIndexAttributeTv ?? 0)
    }
    if (formula?.id && formula.type && formula?.listAttributeAdd?.length) {
      setDataTableAddAtribute(formula?.listAttributeAdd)
    }
  }, [formula])
  return ROLE_CRUD.includes(role) ? (
    <div className='individual-settings'>
      <div className='display-table mt-16x'>
        <div className='bgrffffff'>
          <div className='pd_16x_0x'>
            <div className='mg_0_16x'>
              <div className='table-search-option ss-drop-drown'>
                <div className='pl-7x'>
                  <div className='label_bold'>
                    {t('caculation.settingType')}
                    <div className='label'>{t('caculation.individualSetting')}</div>
                  </div>
                </div>
                <div className='pl-7x'>
                  <div className='label_bold_2'>{t('caculation.settingTargetRegis')}</div>
                </div>
                <Row>
                  <Col span={12} className='pr-7x pl-7x'>
                    <SelectComponent
                      lable={t('caculation.table.lteOrLora')}
                      defaultOption={lteOrLora}
                      dataOption={listLteOrLora}
                      onChangeSelect={handleOnChangeSelectLteOrLora}
                      disableAll={true}
                    />
                  </Col>
                  <Col span={12} className='pl-7x pr-7x'>
                    <SelectComponent
                      lable={t('caculation.selectEmail')}
                      defaultOption={email}
                      dataOption={defaultListEmail}
                      onChangeSelect={handleOnChangeSelectEmail}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} className='pr-7x pl-7x'>
                    <SelectComponent
                      lable={t('caculation.sensorType')}
                      defaultOption={settingTarget}
                      dataOption={listSensorTranslateType}
                      onChangeSelect={handleOnChangeSelectSettingTarget}
                      disableAll={true}
                    />
                  </Col>
                  <Col span={12} className='pr-7x pl-7x'>
                    <SelectComponent
                      disableAll={true}
                      lable={t('caculation.table.sensorName')}
                      defaultOption={sensor}
                      dataOption={listSensor}
                      onChangeSelect={handleChangeSensor}
                    />
                  </Col>
                </Row>
                <div className='d-flex justify-content-flex-end'>
                  <VButton
                    title={t('caculation.btnSelectSs')}
                    type='text'
                    style={styleBtnSeletedSs}
                    disabled={sensor ? false : true}
                    htmlType='button'
                    onClick={() => onSelectedSs()}
                  />
                </div>
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12} className='pl-7x pr-7x setting_input custom-form-item'>
                    <div className='label_bold_4'>{t('caculation.sensorNameSelected')}</div>
                    <InputReadOnlyComponent value={sensorSelected?.sensorName} />
                  </Col>
                  <Col span={12} className='pl-7x pr-7x setting_input custom-form-item'>
                    <div className='label_bold_4'>{t('caculation.deviceIdSelected')}</div>
                    <InputReadOnlyComponent value={sensorSelected?.deviceId} />
                    {duplicateErr ? <div className='custom-error'>{duplicateErr}</div> : null}
                  </Col>
                </Row>
                <Col span={24} className='pl-7x setting_input custom-form-item'>
                  <div className='label_bold_4'>{t('caculation.settingDes')}</div>
                  <Input
                    placeholder={t('caculation.inputPlaceholder')}
                    value={describe}
                    maxLength={100}
                    onChange={handleOnChangeDescribe}
                  />
                  <div className='label_describe'>{t('caculation.inputDes')}</div>
                </Col>
              </div>
            </div>
            <Form onFinish={onFinishTable} form={form}>
              <div className='custom-table custom-table-formula'>
                <Form.List name='listAttributes'>
                  {() => {
                    return (
                      <>
                        <TableComponent
                          dataSource={getData(
                            formula?.id && formula?.type === settingTypeDefault.sensor
                              ? formula?.listAttribute
                              : attributes
                          )}
                          columns={columns}
                        />
                      </>
                    )
                  }}
                </Form.List>
                <div className='mt-16x mg_0_16x mb-16x d-flex align-items-center'>
                  <div className='add-attribute-title'>{t('caculation.addAtribute')}</div>
                  {duplicateErr === '' ? (
                    <VButton
                      onClick={handleClickAddAttribute}
                      disabled={dataTableAddAtribute?.length >= 10 - arrAttributeTv?.length}
                      title={t('caculation.addNewBtn')}
                      type='text'
                      style={styleBtnAdd}
                      htmlType='button'
                    />
                  ) : (
                    <VButton
                      title={t('caculation.addNewBtn')}
                      type='text'
                      disabled={true}
                      style={styleBtnAdd}
                    />
                  )}
                </div>
                <Form.List name='listAttributeAdd'>
                  {() => {
                    return (
                      <>
                        <Table
                          pagination={false}
                          dataSource={dataTableAddAtribute}
                          columns={columnsAddAttribute}
                          locale={locale}
                          showHeader={false}
                        />
                      </>
                    )
                  }}
                </Form.List>
              </div>
              <div className='text-align-center d-flex justify-content-center mt-16x'>
                <Form.Item shouldUpdate>
                  {() => (
                    <VButton
                      title={t('caculation.confirmBtn')}
                      type='text'
                      style={styleBtnSubmit}
                      disabled={
                        !duplicateErr &&
                        sensorSelected?.sensorName &&
                        sensorSelected?.deviceId &&
                        !form.getFieldsError().filter(({ errors }) => errors.length).length
                          ? false
                          : true
                      }
                      htmlType='button'
                      onClick={() => form.submit()}
                    />
                  )}
                </Form.Item>
                <VButton
                  title={t('caculation.returnBtn')}
                  style={styleBtnReturn}
                  onClick={() => {
                    gotoLastPage()
                  }}
                  htmlType='button'
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <AccessDeniedComponent />
  )
}

export default CreateFormula
