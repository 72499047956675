import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { LoginAction } from 'redux/actions/auth/authAction'
import { commonSelector } from 'selectors/commonSelectors/commonSelector'
import { ILoginParams } from 'services/params/ILoginParams'
import Login from 'views/pages/login/Login'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleLogin: (loginParams: ILoginParams, callBack: (value: any) => void) =>
    dispatch(LoginAction(loginParams, callBack))
})

export default connect(commonSelector, mapDispatchToProps)(Login)
