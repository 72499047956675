import { ColumnsType } from 'antd/lib/table'
import { IconWarning } from 'assets'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import { Link } from 'react-router-dom'
import { ConvertTime } from 'utils/Functions'
import {
  defaultTypeSensorLTE,
  defaultShowBlank,
  defaultTypeSensorLora,
  communicationStatusSetting
} from 'views/pages/sensor/defaultValue'
import { ADMIN_ROUTE, routesSensorManagement } from 'views/routes/routes'
import i18n from 'configs/i18n'
import { IDataListTableGroupDetail } from 'interfaces/group/IGroup'
import { convertTimePushAtData } from 'views/pages/dashboard/convertData'

const columnsGroupDetail: ColumnsType<IDataListTableGroupDetail> = [
  {
    title: i18n.t('sensorManagementScreen.table.column.no'),
    dataIndex: 'no'
  },
  {
    title: i18n.t('sensorManagementScreen.table.column.deviceID'),
    dataIndex: 'deviceID',
    render: (_: object, record: { key: string; deviceID: string }) => {
      return (
        <div className='pointer custom-a cls_3EACFF text-underline'>
          <Link
            to={ADMIN_ROUTE.concat(routesSensorManagement.SENSOR_DETAIL + `/${record?.deviceID}`)}>
            {record?.deviceID}
          </Link>
        </div>
      )
    }
  },
  {
    title: i18n.t('sensorManagementScreen.table.column.sensorName'),
    dataIndex: 'sensorName',
    render: (_: object, record: { sensorName: string }) => {
      return (
        <TooltipComponent
          maxSlice={20}
          title={record?.sensorName ? record?.sensorName : defaultShowBlank}
        />
      )
    }
  },
  {
    title: i18n.t('sensorManagementScreen.table.column.sensorType'),
    dataIndex: 'sensorType'
  },
  {
    title: i18n.t('sensorManagementScreen.table.column.lteOrLora'),
    dataIndex: 'lteOrLora',
    render: (_: object, record: { lteOrLora: string }) => {
      return <span>{record?.lteOrLora}</span>
    }
  },
  {
    title: i18n.t('sensorManagementScreen.table.column.imeiNumber'),
    dataIndex: 'imeiNumber',
    render: (_: object, record: { lteOrLora: string; imeiNumber: string }) => {
      return (
        <span>
          {record?.lteOrLora === defaultTypeSensorLTE
            ? record?.imeiNumber
              ? record?.imeiNumber
              : defaultShowBlank
            : defaultShowBlank}
        </span>
      )
    }
  },
  {
    title: i18n.t('sensorManagementScreen.table.column.securityKey'),
    dataIndex: 'securityKey',
    render: (_: object, record: { lteOrLora: string; securityKey: string }) => {
      return (
        <>
          {record?.lteOrLora === defaultTypeSensorLora ? (
            record?.securityKey ? (
              <TooltipComponent title={record?.securityKey} />
            ) : (
              defaultShowBlank
            )
          ) : (
            defaultShowBlank
          )}
        </>
      )
    }
  },
  {
    title: i18n.t('sensorManagementScreen.table.column.communicationStatus'),
    dataIndex: 'communicationStatus',
    render: (_: object, record: { communicationStatus: string }) => {
      return (
        <div>
          {record?.communicationStatus === communicationStatusSetting?.abnormal ? (
            <div className='d-flex align-items-center'>
              <span className='cls_E93A44'>
                {i18n.t('sensorManagementScreen.table.status.abnormal')}
              </span>
              <img className='img_20x' src={IconWarning} alt='warning' />
            </div>
          ) : (
            <div className='d-flex align-items-center'>
              <span className='mr_10x '>
                {i18n.t('sensorManagementScreen.table.status.normal')}
              </span>
            </div>
          )}
        </div>
      )
    }
  },
  {
    title: i18n.t('sensorManagementScreen.table.column.battery'),
    dataIndex: 'battery',
    render: (_: object, record: { battery: string }) => {
      return (
        <div>
          {record?.battery === communicationStatusSetting?.abnormal ? (
            <div className='d-flex align-items-center'>
              <span className='cls_E93A44'>
                {i18n.t('sensorManagementScreen.table.status.abnormal')}
              </span>
              <img className='img_20x' src={IconWarning} alt='warning' />
            </div>
          ) : (
            <div className='d-flex align-items-center'>
              <span className='mr_10x'>{i18n.t('sensorManagementScreen.table.status.normal')}</span>
            </div>
          )}
        </div>
      )
    }
  },
  {
    title: i18n.t('sensorManagementScreen.table.column.registerDate'),
    dataIndex: 'registerDate',
    render: (_: object, record: { registerDate: string }) => {
      return ConvertTime(record?.registerDate)
    }
  },
  {
    title: i18n.t('sensorManagementScreen.table.column.lastDate'),
    dataIndex: 'lastDate',
    render: (_: object, record: { lastDate: string }) => {
      return record?.lastDate ? convertTimePushAtData(record?.lastDate) : defaultShowBlank
    }
  }
]
export { columnsGroupDetail }
