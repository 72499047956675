import Modal from 'antd/lib/modal/Modal'
import { IconSuccessModal } from 'assets'
import VButton from 'components/button/VButton'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LogoutAction } from 'redux/actions/auth/authAction'
import { SuccessAction } from 'redux/actions/common/commonAction'
interface IModalDelete {
  isVisible: boolean
  content?: string | ''
  isRedirect?: boolean
  href?: string
  isImg?: boolean
  src?: string
  isLogout?: boolean
}
function ModalSuccessComponent({ isVisible, content, isRedirect, href, isImg, src, isLogout }: IModalDelete) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const handleClickCancel = () => {
    if (isLogout) {
      dispatch(LogoutAction())
    }
    const stateSuccess = {
      isVisible: false,
      content: ''
    }
    dispatch(SuccessAction({ ...stateSuccess }))
    if (isRedirect && href) {
      history.push(href)
    }
  }
  return (
    <Modal
      visible={isVisible}
      centered={true}
      footer={null}
      maskClosable={false}
      onCancel={handleClickCancel}>
      <div style={{ padding: '20px 0px' }}>
        <div className='modal-ic-success text-align-center mb-16x'>
          <img src={isImg ? src : IconSuccessModal} alt='success' style={{ ...styleImg }} />
        </div>
        <div className='modal-success-content text-align-center mb-16x font-w-bold'>{content}</div>
        <div className='text-align-center'>
          <VButton title={t('modal.btnClose')} style={styleBtnCancel} onClick={handleClickCancel} />
        </div>
      </div>
    </Modal>
  )
}
const styleImg = {
  width: 90,
  height: 75
}
const styleBtnCancel = {
  width: 88,
  height: 38
}
export default ModalSuccessComponent
