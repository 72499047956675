/* eslint-disable */
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, Input, Row } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import VButton from 'components/button/VButton'
import InputTextAreaComponent from 'components/common/input/InputTextAreaComponent'
import { IconPlusBtn, IconTextJp, IconDeleteModal } from 'assets'
import InputComponent from 'components/common/input/InputComponent'
import { customerCreateRules } from './rules'
import 'styles/Customer.scss'
import SelectComponent from 'components/common/select/SelectComponent'
import ModalAddDealers from 'components/modal/ModalAddDealer'
import ModalAddSales from 'components/modal/ModalAddSales'
import { useDispatch } from 'react-redux'
import {
  detailCustomerRegAction,
  updateCustomerAction,
  getCustomerMasterDataAction,
  deleteMasterSalesAction,
  deleteMasterDealerAction
} from 'redux/actions/customer/customerAction'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import ModalComponent from 'components/modal/ModalComponent'
import InputDateComponent from 'components/common/input/InputDateComponent'
import moment from 'moment'
import { ADMIN_ROUTE, routesCustomerManagement } from 'views/routes/routes'
import { useHistory } from 'react-router-dom'
import Map from './Map'
import { regexZipCode, diffTimeFun } from 'utils/Functions'
import { useParams } from 'react-router'
import CustomSelect from 'components/common/select/custom/CustomSelectComponent'
import ModalCheckSuccessComponent from 'components/modal/ModalCheckSuccessComponent'
import ModalEnvironment from 'components/modal/ModalEnvironment'

export interface IMasterDataType {
  id: number
  nameJp: string
  name: string
}

const EditCustomer = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const [notes, setNotes] = useState('')
  const styleTile = { minHeight: 90, maxHeight: 90 }
  const [selectDealer, setSelectDealer] = useState<any>()
  const [dealersNameList, setDealersNameList] = useState<any[]>([])
  const [selectSale, setSelectSale] = useState<any>()
  const [salesNameList, setSalesNameList] = useState<any[]>([])
  const [modalDealers, setModalDealers] = useState<boolean>(false)
  const [modalSales, setModalSales] = useState<boolean>(false)
  const refDateStart = useRef()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const refDateEnd = useRef()
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [errStartDate, setErrStartDate] = useState<string>('')
  const [errSales, setErrSales] = useState<string>('')
  const [customerKey, setCustomerKey] = useState<string>('')
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  });
  const [modalSuccess, setModalSuccess] = useState({
    isVisible: false,
    msg: ''
  });
  const [modalError, setModalError] = useState({
    isVisible: false,
    msg: ''
  });
  const [position, setPosition] = useState<any>()
  const [addressFromZipCode, setAddressFromZipCode] = useState<string>()
  const [indexSalesOptionMouseEnter, setIndexSalesOptionMouseEnter] = useState<number>(0);
  const [indexDealerOptionMouseEnter, setIndexDealerOptionMouseEnter] = useState<number>(0);

  const idCumstomer = useParams<any>()
  const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  useEffect(() => {
    getDataDetail()
    handleGetListSales()
    handleGetListDealers()
    // eslint-disable-next-line
  }, [])

  const getDataDetail = async () => {
    const params: any = {
      timeZone: getTimeZone,
      customerId: Number(idCumstomer?.id)
    }
    dispatch(
      detailCustomerRegAction(
        { ...params },
        (data) => {
          if (data && data?.result) {
            const dataDetail = data?.result?.customer
            form.setFieldsValue({
              customerName: dataDetail?.customerName,
              customerEmail: dataDetail?.email,
              zipCode: dataDetail?.zipCode,
              address1: dataDetail?.address1,
              address2: dataDetail?.address2
            })
            setStartDate(moment(dataDetail?.startedAt).toDate())
            if (dataDetail?.endedAt) {
              setEndDate(moment(dataDetail?.endedAt).toDate())
            } else {
              setEndDate(null)
            }
            setAddressFromZipCode(dataDetail?.address1)
            setPosition({ lat: Number(dataDetail?.lat), lng: Number(dataDetail?.long) })
            setSelectSale(dataDetail?.sale?.id)
            setSelectDealer(dataDetail?.dealer?.id)
            setCustomerKey(dataDetail?.customerKey)
            setNotes(dataDetail?.note)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  // get dealer & sale dropdown list
  const handleGetListSales = () => {
    const params = {
      typeDataMaster: 0
    }
    dispatch(
      getCustomerMasterDataAction(
        { ...params },
        (data) => {
          if (data && data?.result) {
            const listSales: any = []
            data.result.record = data.result.record.map(({ id, nameJp }: IMasterDataType) => {
              return listSales.push({
                id: id,
                name: nameJp
              })
            })
            setSalesNameList(listSales)
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  const handleGetListDealers = () => {
    const params = {
      typeDataMaster: 1
    }
    dispatch(
      getCustomerMasterDataAction(
        { ...params },
        (data) => {
          if (data && data?.result) {
            const listDealers: any = []
            data.result.record = data.result.record.map(({ id, nameJp }: IMasterDataType) => {
              return listDealers.push({
                id: id,
                name: nameJp
              })
            })
            setDealersNameList(listDealers)
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  const handleOnChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value)
  }

  const handleSalesOptionMouseEnter = (event: React.MouseEventHandler<HTMLDivElement>, index: number) => {

    setIndexSalesOptionMouseEnter(index);
  }

  const handleDealerOptionMouseEnter = (event: React.MouseEventHandler<HTMLDivElement>, index: number) => {

    setIndexDealerOptionMouseEnter(index);
  }

  // modal success
  const showSuccess = (text: string, href?: any) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text,
      href: href,
      isRedirect: true
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }

  // modal add dealers
  const handleOpenModalDealers = () => {
    setModalDealers(true)
  }
  const handleOnSubmitAddDealers = (value: any) => {
    setModalDealers(false)
    showSuccess(t('customerScreen.addSuccess'))
    dispatch(SetLoadingAction(false))
    handleGetListDealers()
  }
  const handleOnCancelAddDealers = () => {
    setModalDealers(false)
  }
  // modal add sales
  const handleOpenModalSales = () => {
    setModalSales(true)
  }
  const handleOnSubmitAddSales = (value: any) => {
    setModalSales(false)
    showSuccess(t('customerScreen.addSuccess'))
    dispatch(SetLoadingAction(false))
    handleGetListSales()
  }
  const handleOnCancelAddSales = () => {
    setModalSales(false)
  }

  // on change start/end date
  const handleOnChangeStartDate = (date: Date) => {
    setStartDate(date)
    if (endDate) {
      const diffTime = diffTimeFun(date, endDate, date, endDate)
      if (diffTime < 0) {
        setErrStartDate(t('errors.time'))
      } else {
        setErrStartDate('')
      }
    }
  }
  const handleOnChangeEndDate = (date: Date) => {
    setEndDate(date)
    if (startDate) {
      const diffTime = diffTimeFun(startDate, date, startDate, date)
      if (diffTime < 0) {
        setErrStartDate(t('errors.time'))
      } else {
        setErrStartDate('')
      }
    }
  }
  // on select dealer/sale
  const handleOnChangeSales = (sale: any) => {
    setSelectSale(sale)
    setErrSales('')
  }
  const handleOnChangeDealers = (dealer: any) => {
    setSelectDealer(dealer)
  }
  const handleReturn = () => {
    history.push(ADMIN_ROUTE.concat(routesCustomerManagement.CUSTOMER_LIST))
  }

  const onFinish = async () => {
    const formatStartDate = moment(startDate).format('YYYY/M/DD')
    const formatEndDate = moment(endDate).format('YYYY/M/DD')
    if (startDate && selectSale) {
      setErrSales('')
      setErrStartDate('')
      const params: any = {
        customerId: Number(idCumstomer?.id),
        customerName: form.getFieldValue('customerName').trim() ?? '',
        zipCode: form.getFieldValue('zipCode') ? form.getFieldValue('zipCode').trim() : '',
        address1: form.getFieldValue('address1') ? form.getFieldValue('address1').trim() : '',
        address2: form.getFieldValue('address2').trim() ?? '',
        startedAt: formatStartDate ?? '',
        saleId: selectSale,
        note: notes ?? '',
        lat: position?.lat.toString(),
        long: position?.lng.toString()
      }
      if (endDate) {
        params.endedAt = formatEndDate
      }
      if (selectDealer) {
        params.dealerId = selectDealer
      }
      dispatch(
        updateCustomerAction(
          { ...params },
          (data) => {
            if (data && data?.code === 400) {
              form.setFields([
                {
                  name: 'customerName',
                  errors: [data?.data?.id[0] === 'FRE00020' ? t('errors.message.FRE00020') : '']
                }
              ])
              window.scrollTo(0, 0)
              dispatch(SetLoadingAction(false))
            } else {
              showSuccess(
                t('customerScreen.editSuccess'),
                ADMIN_ROUTE.concat(routesCustomerManagement.CUSTOMER_LIST)
              )
              dispatch(SetLoadingAction(false))
            }
          },
          true
        )
      )
    } else {
      if (startDate && !selectSale) {
        setErrStartDate('')
        setErrSales(t('errors.message.FRE00008'))
      } else if (!startDate && selectSale) {
        setErrStartDate(t('errors.message.FRE00008'))
        setErrSales('')
      } else {
        setErrStartDate(t('errors.message.FRE00008'))
        setErrSales(t('errors.message.FRE00008'))
      }
    }
  }
  // render add button inside select dropdown
  const renderBtnAddSales = (menu: any) => {
    return (
      <>
        <div className='pointer' style={styleBtnAddSales} onClick={handleOpenModalSales}>
          <img src={IconPlusBtn} alt='ic-plus' style={{ width: 12, height: 12, marginRight: 10 }} />
          <p style={{ fontWeight: 'bold' }}>{t('customerScreen.btnAddSales')}</p>
        </div>
        {menu}
      </>
    )
  }
  const renderBtnAddDealers = (menu: any) => {
    return (
      <>
        <div className='pointer' style={styleBtnAddDealers} onClick={handleOpenModalDealers}>
          <img src={IconPlusBtn} alt='ic-plus' style={{ width: 12, height: 12, marginRight: 10 }} />
          <p style={{ fontWeight: 'bold' }}>{t('customerScreen.btnAddDealers')}</p>
        </div>
        {menu}
      </>
    )
  }

  const renderMap = <Map getPosition={(e) => setPosition(e)} position={position} />

  const onBlurZipCode = async () => {
    let zipCode = form.getFieldValue('zipCode')
    if (zipCode && regexZipCode.test(zipCode.trim())) {
      zipCode = zipCode.trim()
      const url =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        zipCode +
        '&key=AIzaSyDFN7hokQVYJF-e1I-W96VBbzyfm3nXRHo&language=ja'
      const response = await fetch(url, { method: 'GET' })
      const myJson = await response.json()
      if (myJson?.results.length > 0) {
        const addressJs = myJson?.results[0]?.address_components
        const address4 = addressJs[4] ? addressJs[4]?.long_name + '　' : ''
        const address3 = addressJs[3] ? addressJs[3]?.long_name + '　' : ''
        const address2 = addressJs[2] ? addressJs[2]?.long_name + '　' : ''
        const address = address4 + address3 + address2 + addressJs[1]?.long_name
        setAddressFromZipCode(address)
        form.setFieldsValue({ address1: address })
        const position = myJson?.results[0]?.geometry?.location
        setPosition(position)
      } else {
        form.setFields([
          {
            name: 'zipCode',
            errors: ['郵便番号が存在していません。']
          }
        ])
      }
    } else {
      form.setFieldsValue({ address1: '' })
    }
  }

  const onBlurAddress = async () => {
    const address = form.getFieldValue('address2')
    if (address) {
      const url =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        address.trim() +
        '&key=AIzaSyDFN7hokQVYJF-e1I-W96VBbzyfm3nXRHo&language=ja'
      const response = await fetch(url, { method: 'GET' })
      const myJson = await response.json()
      if (myJson?.results.length > 0) {
        const position = myJson?.results[0]?.geometry?.location
        setPosition(position)
      }
    }
  }

  const checkDisabled = () => {
    if (
      startDate &&
      errStartDate === '' &&
      selectSale &&
      errSales === '' &&
      !form.getFieldsError().filter(({ errors }) => errors.length).length
    ) {
      return false
    }
    return true
  }
  const onValuesChange = (values: any) => {
    if (values?.customerName) {
      form.setFieldsValue({
        customerName: values?.customerName.replace(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, '')
      })
    }
  }

  const handleDeleteItemSalesName = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    setModal({
      ...modal,
      isVisible: true,
      type: 'delete-sales',
      isComponent: false,
      img: IconDeleteModal,
      btnSubmit: t('modal.btnDelete'),
      content: '削除してもよろしいでしょうか。',
      hideBtnFooter: false
    });
  }

  const handleDeleteItemDealers = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    setModal({
      ...modal,
      isVisible: true,
      type: 'delete-dealers',
      isComponent: false,
      img: IconDeleteModal,
      btnSubmit: t('modal.btnDelete'),
      content: '削除してもよろしいでしょうか。',
      hideBtnFooter: false
    });
  }

  const deleteSales = () => {
    const params = {
      id: salesNameList[indexSalesOptionMouseEnter]?.id
    }
    dispatch(
      deleteMasterSalesAction(
        { ...params },
        (data) => {
          if (data && data?.code === 400) {

            if (data?.data?.id[0] === 'FRE00032') {

              setModal({ ...modal, isVisible: false })

              setModalError({
                ...modalError,
                isVisible: true,
                msg: t(`errors.message.FRE00032`)
              })
            }

            dispatch(SetLoadingAction(false))
          } else if (data && data?.result?.id) {

            setModal({ ...modal, isVisible: false })

            setModalSuccess({
              ...modalSuccess,
              isVisible: true,
              msg: t('modal.message.removeSuccess')
            })

            // remote local master sales
            if (salesNameList[indexSalesOptionMouseEnter]?.id === selectSale) {
              console.log('remove local master sales');
              setSelectSale(null);
            }

            setSalesNameList([...salesNameList].filter((salesName) => salesName.id != salesNameList[indexSalesOptionMouseEnter]?.id))

            dispatch(SetLoadingAction(false))
          } else {

            setModal({ ...modal, isVisible: false })

            setModalError({
              ...modalError,
              isVisible: true,
              msg: t(`errors.message.FRE00032`)
            })

            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  const deleteDealers = () => {
    const params = {
      id: dealersNameList[indexDealerOptionMouseEnter]?.id
    }
    dispatch(
      deleteMasterDealerAction(
        { ...params },
        (data) => {
          if (data && data?.code === 400) {

            if (data?.data?.id[0] === 'FRE00032') {

              setModal({ ...modal, isVisible: false })

              setModalError({
                ...modalError,
                isVisible: true,
                msg: t(`errors.message.FRE00032`)
              })
            }

            dispatch(SetLoadingAction(false))
          } else if (data && data?.result?.id) {

            setModal({ ...modal, isVisible: false })

            setModalSuccess({
              ...modalSuccess,
              isVisible: true,
              msg: t('modal.message.removeSuccess')
            })

            // remote local master sales

            if (dealersNameList[indexDealerOptionMouseEnter]?.id === selectDealer) {
              setSelectDealer(null);
            }
            
            setDealersNameList([...dealersNameList].filter((dealerName) => dealerName.id !== dealersNameList[indexDealerOptionMouseEnter]?.id))

            dispatch(SetLoadingAction(false))
          } else {

            setModal({ ...modal, isVisible: false })

            setModalError({
              ...modalError,
              isVisible: true,
              msg: t(`errors.message.FRE00032`)
            })

            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  async function handleOnSubmitModal() {
    switch (modal?.type) {
      case 'delete-sales':
        deleteSales()
        break;
      case 'delete-dealers':
        deleteDealers()
        break;
      default:
        setModal({ ...modal, isVisible: false })
        break
    }
  }

  const handleModalSuccessClose = () => {
    setModalSuccess({
      ...modalSuccess,
      isVisible: false,
      msg: ''
    });
  }

  const handleModalErrorClose = () => {
    setModalError({
      ...modalError,
      isVisible: false,
      msg: ''
    });
  }

  return (
    <>
      <div>
        <div className='bgrffffff'>
          <div className='create-customer pd_16x_16x'>
            <Form
              onFinish={onFinish}
              form={form}
              autoComplete='off'
              onValuesChange={onValuesChange}>
              <Col className='pr-7x'>
                <InputComponent
                  name='customerName'
                  type='text'
                  isReq={true}
                  label={t('customerScreen.customerName')}
                  maxLength={64}
                  rules={customerCreateRules.customerName}
                />
              </Col>
              <Col className='pr-7x'>
                <InputComponent
                  name='customerEmail'
                  type='text'
                  isReq={true}
                  label={t('customerScreen.representativeEmail')}
                  maxLength={255}
                  rules={customerCreateRules.representativeEmail}
                  disabled={true}
                />
              </Col>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item>
                    <div className='start-date'>
                      {t('customerScreen.serviceStartDate')}
                      <span style={{ color: '#E93A44', fontSize: 14 }}>&nbsp;*</span>
                    </div>
                    <InputDateComponent
                      refer={refDateStart}
                      state={startDate}
                      readOnly={false}
                      selectStart={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChangeDate={(date: Date) => handleOnChangeStartDate(date)}
                      tidleSign={true}
                      customPopper='bottom'
                    />
                    {errStartDate !== '' ? <div className='err-text'>{errStartDate}</div> : null}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <div className='end-date'>{t('customerScreen.serviceEndDate')}</div>
                    <InputDateComponent
                      refer={refDateEnd}
                      state={endDate}
                      readOnly={false}
                      startDate={startDate}
                      endDate={endDate}
                      selectEnd={true}
                      minDate={startDate}
                      onChangeDate={(date: Date) => handleOnChangeEndDate(date)}
                      customPopper='bottom'
                      isClearable={endDate ? true : false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className='zip-code-container'>
                <Col className='pr-7x'>
                  <InputComponent
                    name='zipCode'
                    type='text'
                    label={t('customerScreen.createCustomer.zipCodeLabel')}
                    isReq={true}
                    placeholder={t('customerScreen.createCustomer.zipCodePlaceholder')}
                    maxLength={64}
                    rules={customerCreateRules.zipCode}
                    style={{ marginBottom: 8, height: 32 }}
                    prefix={
                      <>
                        <img src={IconTextJp} alt='ic-text' />
                      </>
                    }
                    onBlur={onBlurZipCode}
                  />
                  <InputComponent
                    name='address1'
                    type='text'
                    placeholder={t('customerScreen.createCustomer.autoFilZipCodePlaceholder')}
                    maxLength={255}
                    style={{ marginBottom: 8 }}
                    value={addressFromZipCode}
                    disabled={true}
                  />
                  <InputComponent
                    name='address2'
                    type='text'
                    placeholder={t('customerScreen.createCustomer.buildingPlaceholder')}
                    maxLength={255}
                    onBlur={onBlurAddress}
                    isReq={true}
                    rules={customerCreateRules.address2}
                  />
                  {renderMap}
                </Col>
              </div>
              <Col className='pr-7x'>
                <Form.Item>
                  <CustomSelect
                    title={t('customerScreen.salesName')}
                    required={true}
                    optionalTitle="&nbsp;*"
                    titleStyles={titleSelectionStyles}
                    selectStyles={selectionStyles}
                    value={selectSale}
                    options={salesNameList}
                    dropdownRender={renderBtnAddSales}
                    indexOptionMouseEnter={indexSalesOptionMouseEnter}
                    onOptionMouseEnter={(event, index) => handleSalesOptionMouseEnter(event, index)}
                    onChange={handleOnChangeSales}
                    iconRight={
                      <Button
                        style={styleIconButtonRight}
                        onClick={(e) => handleDeleteItemSalesName(e)}
                        shape='circle'
                        icon={<CloseOutlined />}
                      />
                    }
                  />
                  {errSales !== '' ? <div className='err-text'>{errSales}</div> : null}
                </Form.Item>
              </Col>
              <Col className='pr-7x'>
                <Form.Item>
                  <CustomSelect
                    title={t('customerScreen.dealersName')}
                    titleStyles={titleSelectionStyles}
                    selectStyles={selectionStyles}
                    value={selectDealer}
                    options={dealersNameList}
                    dropdownRender={renderBtnAddDealers}
                    indexOptionMouseEnter={indexDealerOptionMouseEnter}
                    onOptionMouseEnter={(event, index) => handleDealerOptionMouseEnter(event, index)}
                    onChange={handleOnChangeDealers}
                    iconRight={
                      <Button
                      style={styleIconButtonRight}
                      onClick={(e) => handleDeleteItemDealers(e)}
                      shape='circle'
                      icon={<CloseOutlined />}
                    />
                    }
                  />
                </Form.Item>
              </Col>
              <div className='customer-key-input'>
                <div className='lable-mb font-w-bold'>{t('customerScreen.corporateKey')}</div>
                <Col className='pr-7x'>
                  <Input value={customerKey} disabled={true} />
                </Col>
              </div>
              <Col className='pr-7x'>
                <div className='lable-mb font-w-bold' style={{ fontSize: 12, marginBottom: 8 }}>
                  {t('customerScreen.remarks')}
                </div>
                <InputTextAreaComponent
                  maxLength={625}
                  state={notes}
                  style={styleTile}
                  onChangeText={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleOnChangeText(e)
                  }
                  disabled={false}
                  placeholder={''}
                />
              </Col>
              <div className='group-btn-create'>
                <Form.Item shouldUpdate>
                  {() => (
                    <VButton
                      disabled={checkDisabled()}
                      title={t('common.btnUpdate')}
                      style={styleBtnSubmit}
                    />
                  )}
                </Form.Item>
                <VButton
                  htmlType='button'
                  onClick={handleReturn}
                  title={t('customerScreen.btnCancel')}
                  style={styleBtnReturn}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
      {modalDealers ? (
        <ModalAddDealers
          onSubmitModal={handleOnSubmitAddDealers}
          onCancelModal={handleOnCancelAddDealers}
        />
      ) : null}
      {modalSales ? (
        <ModalAddSales
          onSubmitModal={handleOnSubmitAddSales}
          onCancelModal={handleOnCancelAddSales}
        />
      ) : null}
      {modal?.isVisible ? (
        <ModalComponent
          isVisible={modal?.isVisible}
          isComponent={modal?.isComponent}
          img={modal?.img}
          content={modal?.content}
          component={modal?.component}
          btnSubmit={modal?.btnSubmit}
          btnCancel={modal?.btnCancel}
          onCancelModal={() => setModal({ ...modal, isVisible: false })}
          onSubmitModal={handleOnSubmitModal}
          hideBtnFooter={modal?.hideBtnFooter}
          zIndex={99999}
        />
      ) : null}
      <ModalCheckSuccessComponent
        isVisible={modalSuccess.isVisible}
        content={modalSuccess.msg}
        handleClickCancel={handleModalSuccessClose}
        zIndex={9999}
      />
      <ModalEnvironment
        isVisible={modalError.isVisible}
        content={modalError.msg}
        btnClose={t('modal.modalEnvironment.btnClose')}
        onCloseModal={handleModalErrorClose}
        contentStyle={msgErrorContentStyle}
      />
    </>
  )
}

export default EditCustomer

const styleBtnSubmit = {
  width: 74,
  height: 38,
  color: '#ffffff'
}
const styleBtnReturn = {
  backgroundColor: '#fff',
  color: '#d7000f',
  border: 'solid 1px #d7000f',
  height: 38,
  marginLeft: 24
}
const styleBtnAddSales = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#d7000f',
  color: '#fff',
  height: 32,
  width: 183,
  borderRadius: 4,
  margin: 12
}
const styleBtnAddDealers = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#d7000f',
  color: '#fff',
  height: 32,
  width: 141,
  borderRadius: 4,
  margin: 12
}
const styleIconButtonRight: React.CSSProperties = {
  color: '#939AB2',
  borderColor: '#D9D9D9',
  backgroundColor: 'transparent'
}
const titleSelectionStyles: React.CSSProperties = {
  margin: "0 0 8px 0",
  fontSize: "12px"
}

const selectionStyles: React.CSSProperties = {
  marginBottom: "8px"
}

const msgErrorContentStyle = {
  whiteSpace: 'pre-line'
}