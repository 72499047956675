const defaultFactoryList = [
  {
    id: 1,
    name: '台湾',
    value: '台湾'
  },
  {
    id: 2,
    name: '佐賀',
    value: '佐賀'
  }
]

export { defaultFactoryList }
