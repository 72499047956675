import { IRequest } from 'interfaces/IRequest'
import { Methods } from 'services/api/EMethod'
import {
  ICreateAlert,
  IDetailAlert,
  IListAlert,
  IUpdateAlert
} from 'services/params/notification/INotificationParams'

export const getListAlertRequest = (params: IListAlert): IRequest<IListAlert> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_LIST_ALERT_SYSTEM,
    params: params
  }
}
export const createAlertRequest = (params: ICreateAlert): IRequest<ICreateAlert> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.CREATE_ALERT_SYSTEM,
    params: params
  }
}
export const updateAlertRequest = (params: IUpdateAlert): IRequest<IUpdateAlert> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.UPDATE_ALERT_SYSTEM,
    params: params
  }
}
export const detailAlertRequest = (params: IDetailAlert): IRequest<IDetailAlert> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.DETAIL_ALERT_SYSTEM,
    params: params
  }
}
