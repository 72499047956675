import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'styles/InputMonth.scss'
import { IPropsInputDate } from 'interfaces/input/IPropsInputDate'
import { ja } from 'date-fns/locale'
import { forwardRef } from 'react'

const InputMonthComponent = ({
  refer,
  state,
  readOnly,
  onChangeDate,
  maxDate,
  minDate,
  disabled,
  selectStart,
  selectEnd,
  startDate,
  endDate,
  describeText,
  customPopper
}: IPropsInputDate) => {
  const CustomInputYear = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className='custom-input-year pointer' onClick={onClick} ref={ref}>
      <div className='date-text'>{value}</div>
    </div>
  ))
  return (
    <div className='ip-month'>
      <DatePicker
        closeOnScroll={true}
        ref={refer}
        selected={state}
        dateFormat='MM'
        onChange={onChangeDate}
        autoComplete='off'
        className='ip-month'
        disabledKeyboardNavigation
        maxDate={maxDate}
        minDate={minDate}
        readOnly={readOnly}
        disabled={disabled}
        locale={ja}
        customInput={<CustomInputYear />}
        selectsStart={selectStart}
        startDate={startDate}
        selectsEnd={selectEnd}
        endDate={endDate}
        popperPlacement={customPopper}
        yearItemNumber={15}
        showMonthYearPicker
        showFullMonthYearPicker
      />
      <span className='describe-text'>{describeText}</span>
    </div>
  )
}

export default InputMonthComponent
