import i18n from 'configs/i18n'
import { validChangePassword } from './rules'

const formChangePassword = [
  {
    label: i18n.t('changePassword.currentPassword'),
    placeholder: i18n.t('changePassword.currentPassword'),
    name: 'oldPassword',
    rules: validChangePassword.oldPassword,
    type: 'password',
    maxLength: 32,
    isRequired: true
  },
  {
    label: i18n.t('changePassword.newPassword'),
    placeholder: i18n.t('changePassword.newPassword'),
    name: 'newPassword',
    rules: validChangePassword.newPassword,
    type: 'password',
    maxLength: 32,
    isRequired: true
  },
  {
    label: i18n.t('changePassword.confirmPassword'),
    placeholder: i18n.t('changePassword.newPassword'),
    name: 'newPasswordConfirm',
    rules: validChangePassword.newPasswordConfirm,
    type: 'confirmPassword',
    maxLength: 32,
    isRequired: true
  }
]
export { formChangePassword }
