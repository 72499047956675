import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { DELAY } from 'interfaces/enums/EDelay'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, put, call, takeLatest, delay } from 'redux-saga/effects'

import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { IGetDataDashBoardAction } from 'redux/actions/dashboard/dashboardTypes'
import { EDashboardActions } from 'redux/actions/dashboard/EDashboardAction'
import { DashboardApi } from 'services/api/dashboard/dashboardApi'

function* getDataDashboardSaga(action: IGetDataDashBoardAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(DashboardApi.getDataDashboard)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

export default function* dashboardSaga() {
  yield all([takeLatest(EDashboardActions.DASHBOARD_LIST, getDataDashboardSaga)])
}
