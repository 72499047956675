import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
const { Option } = Select
interface IProps {
  defaultValue: string
  onChangeSelect: (value: string) => void
}
const timeChange = ['10', '20', '30', '40', '50', '60', '70']
function ChangeTimeComponent({ defaultValue, onChangeSelect }: IProps) {
  const { t } = useTranslation()
  const handleChange = (value: string) => {
    if (onChangeSelect) {
      onChangeSelect(value)
    }
  }
  return (
    <div className='d-flex justify-content-center' style={{ margin: '20px 0px 30px 0px' }}>
      <div className='d-flex align-items-center font-w-bold mr-16x'>
        {t('modal.communicationErr')}
      </div>
      <div className='custom-select-time'>
        <Select defaultValue={defaultValue} onChange={handleChange}>
          {timeChange.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
        <span className='bdr-minutes'>{t('modal.minutes')}</span>
      </div>
    </div>
  )
}
export default ChangeTimeComponent
