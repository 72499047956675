import { Form } from 'antd'
import VButton from 'components/button/VButton'
import FormItem from 'components/common/form/FormItem'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classes from 'styles/Login.module.scss'
import { formResetPassword } from './formResetPassword'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetPasswordAction } from 'redux/actions/users/usersAction'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'

type LayoutType = Parameters<typeof Form>[0]['layout']

const ResetPasswordComponent = () => {
  const { t } = useTranslation()
  const { token }: { token: string } = useParams()
  const formLayout: LayoutType = 'vertical'
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })

  const layout = {
    layout: formLayout,
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  }
  const onFinish = (values: any) => {
    const params = {
      password: values?.password,
      token
    }
    dispatch(
      resetPasswordAction(
        { ...params },
        (data) => {
          if (data?.code && data?.code === 401) {
            history.push(routesName.EXPIRED)
            dispatch(SetLoadingAction(false))
          } else {
            if (data?.result) {
              showSuccess(t('modal.resetPasswordSuccess'))
            }
          }
        },
        true
      )
    )
  }
  const handleReturn = () => {
    history.push(routesName.LOGIN)
  }

  const showSuccess = (text: string) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text,
      isRedirect: true,
      href: ADMIN_ROUTE.concat(routesName.LOGIN)
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }

  const onValuesChange = (values: any) => {
    if (values?.password) {
      form.setFieldsValue({ password: values?.password?.split(' ').join('') })
    }
    if (values?.confirm) {
      form.setFieldsValue({ confirm: values?.confirm?.split(' ').join('') })
    }
  }
  const _renderResetPassword = () => {
    return (
      <div className={classes.login_container}>
        <div className='text-align-center'>
          <h1 className={classes.signup_title}>{t('adminManagement.titleResetPassword')}</h1>
        </div>
        <Form
          form={form}
          {...layout}
          autoComplete='off'
          onValuesChange={onValuesChange}
          onFinish={onFinish}>
          {formResetPassword?.map((item) => (
            <div key={item?.name}>
              <FormItem
                label={item?.label}
                name={item?.name}
                type={item?.type}
                rules={item?.rules}
                maxLength={item?.maxLength}
                isRequired={item?.isRequired}
              />
            </div>
          ))}
          <div className='text-align-center d-flex justify-content-center'>
            <div className={classes.submit_btn}>
              <Form.Item shouldUpdate>
                {() => (
                  <VButton
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                    style={styles.styleBtnSubmit}
                    type='text'
                    title={t('validation.resetPasswordForm.btnConfirm')}
                  />
                )}
              </Form.Item>
            </div>
            <div className={classes.cancel_btn}>
              <Form.Item>
                <VButton
                  title={t('validation.resetPasswordForm.btnCancel')}
                  style={styles.styleBtnCancel}
                  type='text'
                  htmlType='button'
                  onClick={() => handleReturn()}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    )
  }

  return <div className={classes.login_page}>{_renderResetPassword()}</div>
}

const styles = {
  styleBtnSubmit: {
    width: 88,
    height: 38,
    marginRight: 10,
    color: '#ffffff',
    marginTop: 15
  },
  styleBtnCancel: {
    width: 102,
    height: 38,
    marginLeft: 10,
    background: '#ffffff',
    border: '1px solid #D7000F',
    color: '#D7000F',
    marginTop: 15
  }
}

export default ResetPasswordComponent
