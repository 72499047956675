import { IconDeleteBtn, IconDetailBtn } from 'assets'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import i18n from 'configs/i18n'

export const defaultColumns = (
  handleOnClickEdit: any,
  handleOnClickDelete: any,
  current: number,
  pageSize: number
) => {
  const columns = [
    {
      title: i18n.t('sensorManagementScreen.table.column.no'),
      dataIndex: '',
      render: (text: any, row: any, index: any) => {
        return <span>{(current - 1) * pageSize + index + 1}</span>
      },
      align: 'left',
      width: '5%'
    },
    {
      title: i18n.t('customerScreen.customerName'),
      dataIndex: '',
      align: 'left',
      render: (_: object, record: { customerName: string }) => {
        return <TooltipComponent title={record?.customerName} maxSlice={15} />
      }
    },
    {
      title: i18n.t('customerScreen.serviceStartDate'),
      dataIndex: 'serviceStartDate',
      align: 'left'
    },
    {
      title: i18n.t('customerScreen.serviceEndDate'),
      dataIndex: 'serviceEndDate',
      align: 'left'
    },
    {
      title: i18n.t('customerScreen.customerStatus'),
      dataIndex: 'customerStatus',
      align: 'left'
    },
    {
      title: i18n.t('customerScreen.sales'),
      dataIndex: '',
      render: (_: object, record: { sales: string }) => {
        return <TooltipComponent title={record?.sales} maxSlice={15} />
      },
      align: 'left'
    },
    {
      title: i18n.t('customerScreen.dealers'),
      dataIndex: '',
      align: 'left',
      render: (_: object, record: { dealers: string }) => {
        return <TooltipComponent title={record?.dealers} maxSlice={15} />
      }
    },
    {
      title: i18n.t('customerScreen.corporateKey'),
      dataIndex: 'cooporateKey',
      align: 'left'
    },
    {
      title: i18n.t('customerScreen.edit'),
      dataIndex: '',
      align: 'center',
      width: 60,
      render: (_: object, record: any) => {
        return (
          <div>
            <img
              src={IconDetailBtn}
              alt='edit'
              onClick={() => handleOnClickEdit(record)}
              className='w42x-h28x pointer'
            />
          </div>
        )
      }
    },
    {
      title: i18n.t('customerScreen.delete'),
      dataIndex: '',
      width: 60,
      align: 'center',
      render: (_: object, record: any) => {
        return (
          <div>
            <img
              onClick={() => handleOnClickDelete(record)}
              src={IconDeleteBtn}
              alt='delete'
              className='w42x-h28x pointer'
            />
          </div>
        )
      }
    }
  ]

  return columns
}

export const orderColumns = (onClickOrderDetail: any, current?: any, pageSize?: any): any => {
  const columns = [
    {
      title: i18n.t('customerScreen.orderNumber'),
      dataIndex: 'orderNo',
      render: (_: object, record: { orderNo: any }) => {
        return <TooltipComponent maxSlice={20} title={record?.orderNo} />
      },
      align: 'center'
    },
    {
      title: i18n.t('customerScreen.shipmentRegistrationDate'),
      dataIndex: 'createdAt',
      align: 'center'
    },
    {
      title: i18n.t('customerScreen.confirmedDeliveryDate'),
      dataIndex: 'dateDelivery',
      align: 'center'
    },
    {
      title: i18n.t('customerScreen.deliveryLocation'),
      dataIndex: 'address',
      render: (_: object, record: { address: any }) => {
        return <TooltipComponent maxSlice={20} title={record?.address} />
      },
      align: 'center'
    },
    {
      title: i18n.t('customerScreen.shipment'),
      dataIndex: 'totalOrder',
      align: 'center'
    },
    {
      title: i18n.t('customerScreen.edit'),
      dataIndex: '',
      align: 'center',
      width: '75px',
      render: (_: object, record: any) => {
        return (
          <div>
            <img
              src={IconDetailBtn}
              alt='edit'
              onClick={() => onClickOrderDetail(record)}
              className='w42x-h28x pointer'
            />
          </div>
        )
      }
    }
  ]

  return columns
}
