const jp = {
  miharasManagement: 'ミハラス管理システム',
  common: {
    language: {
      lang: 'Language',
      jp: 'Japan',
      en: 'English'
    },
    modal: {
      delete: {
        content: 'このQRコードを削除しますか。'
      },
      success: {
        content: 'このQRコードを削除しました。'
      }
    },
    loraLte: '通信種別',
    sensorType: '機器種別',
    serialNumber: '製造番号',
    btnConfirm: '登録 ',
    btnUpdate: '保存 ',
    createdAt: '登録日時',
    detail: '詳細',
    edit: '編集',
    delete: '削除',
    btnSubmitSearch: '更新'
  },
  loginScreen: {
    systemName: 'ミハラス管理システム',
    loginBtn: 'ログイン',
    mailAddress: 'メールアドレス',
    passWord: 'パスワード',
    fogetPassword: 'パスワードをお忘れの方は',
    resetPassword: 'こちら',
    loginErrMessage: 'メールアドレスまたはパスワードが違います。'
  },
  fogetPasswordScreen: {
    titleName: '管理者のパスワード再設定',
    titleInput: 'メールアドレス',
    alertInput: 'パスワード再設定用のURLが記載されたメールを上記のアドレスにお送りいたします。',
    btnresetpw: '再発行',
    btnlogin: 'ログイン'
  },
  userManagementScreen: {
    placeholder: 'メールアドレス',
    btnSearch: '検索',
    csv: 'CSVエクスポート',
    btnCreate: '新規作成',
    btnSubmit: '更新',
    btnCancel: 'キャンセル',
    btnReturn: '戻る',
    displayResult: '表示件数',
    table: {
      column: {
        customerName: '顧客名',
        customerCode: 'お客様コード',
        userName: 'ユーザ名',
        mailAddress: 'メールアドレス',
        userRole: 'ユーザ権限',
        userStatus: '利用状態'
      },
      status: {
        detailBtn: '詳細',
        editBtn: '編集',
        stopBtn: '停止',
        revivalBtn: '復活',
        deleteBtn: '削除',
        disableNActive: '停止/復活'
      }
    },
    status: {
      stop: '会員未登録',
      active: '利用中'
    },
    lableSystemStatus: '利用状態'
  },
  sensorManagementScreen: {
    placeholder: 'デバイスID検索',
    customerName: '顧客名',
    btnSearch: '検索',
    sensorType: '機器種別',
    emailAccount: 'ユーザのメールアカウント',
    lteOrLora: ' 通信種別',
    groupName: 'グループ名',
    csv: 'CSVエクスポート',
    btnSubmit: '検索',
    btnCancel: 'キャンセル',
    btnReturn: '戻る',
    displayResult: '表示件数',
    table: {
      column: {
        no: 'No',
        customerName: '顧客名',
        groupName: 'グループ名',
        deviceID: 'デバイスID',
        sensorName: 'センサ名',
        sensorType: '機器種別',
        lteOrLora: ' 通信種別',
        imeiNumber: 'IMEI番号',
        securityKey: 'セキュリティキー',
        communicationStatus: '通信ステータス',
        battery: 'バッテリ電圧低下',
        registerDate: '登録日時',
        lastDate: 'データ最終取得日時'
      },
      status: {
        abnormal: '異常',
        normal: '正常'
      }
    }
  },
  groupDetail: {
    userName: 'ユーザ名：',
    groupName: 'グループ名：',
    area: '地域：',
    numberSensor: 'センサ数：',
    emailAddress: 'メールアドレス：'
  },
  sensorDetail: {
    label: {
      deviceId: 'デバイスID',
      oldDeviceId: '旧デバイスID',
      sensorType: 'センサ種類',
      lteOrLora: ' 通信種別',
      collectionId: 'データ収集処置ID',
      sensorLteOrLora: '製造番号',
      sensorId: 'センサID',
      securityKey: 'セキュリティキー',
      registerDate: '登録日時',
      gps: 'GPS情報',
      lastData: '最終データ取得日時',
      sensorIp: 'センサIP',
      sensorIpLora: 'データ収集措置IP',
      communicationErr: '通信異常監視時間',
      battery: 'バッテリ電圧低下',
      communicationStt: '通信ステータス',
      operaStt: '運用状態',
      serialNumber: '製造番号',
      imeiNumber: 'IMEI番号',
      resendRequest: '再送要求',
      rebootRequest: '再起動要求'
    },
    btn: {
      btnJson: 'Json詳細',
      btnEdit: '編集',
      btnResurrection: '復活する',
      btnSend: '送信',
      btnStop: '停止する'
    },
    userInfor: '顧客情報',
    eventLogInfor: 'イベントログ',
    tableUser: {
      no: 'No',
      userName: '顧客名',
      email: 'センサ登録者',
      groupName: 'グループ名称',
      sensorName: 'センサ名',
      registerDate: 'センサ登録日時'
    },
    tableLora: {
      no: 'No',
      category: 'カテゴリ',
      logDateTime: 'ログの日時'
    },
    tableLte: {
      no: 'No',
      category: 'カテゴリ',
      logDateTime: 'ログの日時',
      requestPeriod: '過去データ再送要求期間'
    },
    stop: '停止中',
    active: '稼働中',
    event: {
      off: '稼働停止',
      on: '稼働復活'
    },
    noReception: '受信無し'
  },
  infoManagement: {
    deliveryDateTime: '配信日時',
    deliveryStatus: '配信ステータス：',
    title: 'タイトル',
    text: '本文',
    btnEdit: '編集',
    btnDel: '削除',
    btnReturn: '戻る',
    btnCreate: '新規作成',
    deliveryPeriod: '配信期間',
    dateTimeStart: '開始',
    deliveryStatusList: '配信ステータス',
    stsUndelivered: '未配信',
    stsDelivered: '配信済み',
    dateTimeEnd: '終了',
    displayOrder: '表示順',
    opDisplayOrderDesc: '配信日時降順',
    opDisplayOrderAsc: '配信日時昇順',
    btnUpdateFilter: '更新',
    btnCancelFilter: 'キャンセル',
    colDeliveryDate: '配信日時',
    colSubject: '件名',
    colContent: '内容',
    colDeliveryStatus: '配信ステータス',
    colDetail: '詳細',
    colEdit: '編集',
    colDel: '削除',
    btnSubmitEdit: '保存',
    noteMaximumTitle: '最大表示文字数（目安）：160文字',
    noteMaximumContent: '最大表示文字数（目安）：3000文字',
    btnSaveCreate: '作成',
    plTitle: 'タイトルをここに書いてください',
    plContent: '本文をここに書いてください',
    registeredPerson: '登録者',
    changer: '更新者',
    createdAt: '登録日',
    updatedAt: '更新日',
    error: {
      text: '本文を入力して下さい。',
      minTitleLength: '80文字以上で入力してください。',
      dateAndTimeError: '適切な期間を指定して下さい。'
    }
  },
  tooltip: {
    btnCopy: 'copy',
    msgSuccess: 'copy success'
  },
  tableBattery: {
    no: 'No',
    customerName: '顧客名',
    groupName: 'グループ名',
    deviceID: 'デバイスID',
    sensorName: 'センサ名',
    sensorType: '機器種別',
    lteOrLora: ' 通信種別',
    imeiNumber: 'IMEI番号',
    securityKey: 'セキュリティキー',
    lastDate: 'データ最終取得日時',
    batteryDroppedDate: 'バッテリ電圧低下発生日時'
  },
  tableOutages: {
    no: 'No',
    customerName: '顧客名',
    groupName: 'グループ名',
    deviceID: 'デバイスID',
    sensorName: 'センサ名',
    sensorType: '機器種別',
    lteOrLora: ' 通信種別',
    imeiNumber: 'IMEI番号',
    securityKey: 'セキュリティキー',
    dateTimeOfOutages: '稼働停止日時'
  },
  tableCommunicationErr: {
    no: 'No',
    customerName: '顧客名',
    groupName: 'グループ名',
    deviceID: 'デバイスID',
    sensorName: 'センサ名',
    sensorType: '機器種別',
    lteOrLora: ' 通信種別',
    imeiNumber: 'IMEI番号',
    securityKey: 'セキュリティキー',
    lastDate: 'データ最終取得日時',
    abnormalOccurrencePast: '異常発生経過',
    hours: '時間'
  },
  tableNotification: {
    no: 'No',
    dateTime: '配信日時',
    subject: '件名',
    content: '内容',
    deliveryStatus: '配信ステータス',
    detail: '詳細',
    delivered: '配信済み',
    UnDelivered: '未配信'
  },
  caculation: {
    newRegis: '演算式新規登録',
    btnBulkRegis: '一括登録',
    btnIndividualRegis: '個別登録',
    caculationSetting: '演算式設定一覧',
    order: '表示順',
    btnRegisFormula: '新規演算式登録',
    table: {
      no: 'No',
      positionID: 'ポジションID',
      settingType: '設定種類',
      settingTarget: '設定対象',
      lteOrLora: ' 通信種別',
      sensorSpecs: '計測値名称',
      sensorUnit: '表示単位',
      sensorIndex: '小数点以下桁数',
      defaultFormula: '演算式',
      selectedItem: 'ユーザのホーム画面に表示',
      userName: 'ユーザ名',
      setting: '設定内容',
      dateTimeRegis: '演算式登録日時',
      dateTimeUpdate: '演算式更新日時',
      caculationStatus: '演算式適用ステータス',
      edit: '編集',
      delete: '削除',
      sensorName: 'センサ名',
      detail: '詳細',
      editCopy: '編集/複製',
      copy: '複製',
      email: 'ユーザのメールアカウント',
      deviceId: 'デバイスID',
      emailAddress: 'メールアドレス'
    },
    sensorType: '機器種別',
    sensorName: 'センサ名',
    settingType: '設定種類：',
    multiSetting: '一括設定',
    individualSetting: '個別登録',
    detailFormula: '演算式詳細',
    dateAndTime: '演算式反映日時',
    settingDes: '設定内容',
    inputPlaceholder: '水位の補正値を変更',
    inputDes: '最大100文字',
    addNewLabel: '仮装値追加登録',
    addNewBtn: '追加',
    confirmBtn: '保存',
    returnBtn: '戻る',
    selectEmail: 'ユーザメールアドレス',
    settingTargetRegis: '設定対象登録',
    calculatorSheet: '',
    addAtribute: '仮装値追加登録',
    defaultFormula: '演算式を設定してください',
    positionTv: 'Tv',
    status: {
      valid: '適用中',
      invalid: '無効'
    },
    reserve: '予備',
    createFormula: '演算式登録',
    sensorNameSelected: '設定対象（センサ名）',
    deviceIdSelected: 'デバイスID',
    btnSelectSs: '設定対象登録',
    variableCalculator: '変数電卓'
  },
  validation: {
    loginForm: {
      notEmptyUser: 'メールアドレスを入力してください。',
      notEmptyPass: 'パスワードを入力してください。',
      incorrect: 'Username or password is incorrect',
      phone: 'Please input your phone number',
      time: 'Please select date'
    },
    resetPasswordForm: {
      btnConfirm: '再発行',
      btnCancel: 'ログイン'
    }
  },

  serialNoScreen: {
    serialCreate: {
      serialCreateLabel: '製造機器情報',
      communicationType: '通信種別',
      type: '型式',
      equipmentType: '機器種別',
      yearAndMonthPicker: '製造年月',
      year: '年',
      month: '月',
      oldSerialNumber: '旧製造番号',
      factory: '製造工場',
      imei: 'IMEI番号',
      channel: '無線CH',
      notes: '備考',
      cnf: 'Cnf設定'
    },
    bulkCreate: {
      communicationType: '通信種別',
      katashiki: '型式',
      equipmentType: '機器種別',
      yearAndMonthPicker: '製造年月',
      year: '年',
      month: '月',
      registrationsNumber: '登録台数',
      manufacturingFactory: '製造工場',
      remarks: '備考',
      inputQuantityPlaceHolder: '10000台以下で登録してください'
    },
    bulkDetail: {
      No: 'No',
      communicationType: '通信種別',
      katashiki: '型式',
      equipmentType: '機器種別',
      yearAndMonthPicker: '製造年月',
      year: '年',
      month: '月',
      registrationsNumber: '登録台数',
      manufacturingFactory: '製造工場',
      remarks: '備考',
      registrationResult: '登録結果',
      serialNumber: '製造番号',
      deviceId: 'デバイスID',
      securityKeyorImeiNumber: 'セキュリティキー又はIMEI番号',
      detail: '詳細',
      delete: '削除',
      edit: '編集',
      deleteQrContent: '削除してもよろしいでしょうか？',
      deleteQrParentContent: '削除してもよろしいでしょうか？',
      successDelete: '削除しました。',
      successDeleteParent: '削除しました。',
      preventDeleteQrContent: 'すでに顧客が決まっている製造機器の削除ができません。',
      btnClose: '閉じる'
    },
    individualDetail: {
      serialDetailLabel: '製造機器情報',
      btnSubmitChangeStatus: '切替',
      changeStatusContent1: '動作試験ステータスを切り替えてよろしいでしょうか。',
      changeStatusContent2: '疎通確認ステータスを切り替えてよろしいでしょうか。',
      successChangeStatus: '切り替えました。',
      successDeleteQr: '削除しました。',
      deleteQrCodeContent: '削除してもよろしいでしょうか。',
      btnCloseModalPrevent: '閉じる',
      preventDeleteQrContent: 'すでに顧客が決まっている製造機器の削除ができません。',
      serialNumberLabel: '製造番号',
      deviceIdLabel: 'デバイスID',
      securityKeyLabel: 'セキュリティキー',
      QRcodeLabel: 'QRコード',
      downloadQrLabel: 'ダウンロード',
      blankQRText: 'LoRaセンサの場合 QRコード発行無し',
      qrWarningText: '入力情報が不足のため 発行できません。',
      btnDownloadJsonLabel: 'Json詳細',
      customerNameLabel: 'スペース名（顧客名）',
      noCustomerNameText: '未確定（製造機器出荷後表示される）',
      sensorTypeLabel: '通信種別',
      modalRegisLabel: '型式',
      sensorTypeTranslateLabel: '機器種別',
      manufacturingDateLabel: '製造年月',
      oldSerialNumberLabel: '旧製造番号',
      productSiteLabel: '製造工場',
      imeiLabel: 'IMEI番号',
      noImeiText: '未設定',
      channelLabel: '無線CH',
      noteLabel: '備考',
      cnfLabel: 'cnf設定',
      statusLabel1: '動作確認ステータス',
      statusLabel2: '疎通確認ステータス',
      confirmedText: '確認済み',
      unconfirmedText: '未確認',
      registeredPerson: '登録者',
      changer: '更新者',
      createdAtLabel: '登録日',
      updatedAtLabel: '更新日',
      moreInfo: '登録結果',
      btnEditQr: '編集',
      btnDeleteQr: '削除'
    }
  },

  notification: {
    error: 'エラー',
    success: 'Success',
    messages: {
      invalidRole: 'You do not have access to the system'
    },
    forgotPassError: '登録されていないメールアドレスです。',
    errors: {
      formulaTv: '演算式を設定してください。',
      formulaExp: '演算式は正しくありません。'
    }
  },

  sideBar: {
    dashboard: {
      txtDashboard: 'ユーザ管理'
    },
    sensorManagement: {
      txtSensorManagement: 'センサ管理'
    },
    displayManagement: {
      txtDisplayManagement: '表示管理',
      caculation: '演算式一覧'
    },
    infoManagement: {
      txtInfoManagement: 'お知らせ管理',
      infoDetail: 'お知らせ詳細',
      infoEdit: 'お知らせ編集',
      infoCreate: 'お知らせ作成'
    },
    adminManagement: {
      txtAdminManagement: '管理者管理'
    },
    qrcode: {
      txtQrcode: 'QRコード作成',
      QRcodeTitle: 'QRコード管理',
      QRcodeCreate: 'QRコード作成',
      QRcodeList: 'QRコード一覧'
    },
    katashiki: {
      txtkatashiki: '製造機器管理',
      createKatashikiTitle: '型式登録',
      createkatashiki: '型式登録',
      inputKatashiki: '型式情報入力',
      listKatashiki: '型式情報一覧',
      titleListKatashiki: '型式一覧',
      katashikiTitle: {
        Model: '型式',
        productName: '製品名',
        CommunicationType: '通信種別',
        DeviceType: '機器種別',
        ManagementSymbol: '管理記号',
        remarks: '備考',
        delete: '削除',
        date: '型式登録日',
        edit: '編集',
        register: '登録'
      },
      katashikiLableErr: 'カタシキが存在しました',
      createSuccess: '型式を登録しました。',
      btnSubmidKatashikiDetail: '一覧へ戻る'
    },
    serialNo: {
      serialNoTitle: '製造機器管理',
      createSerialNo: '製造機器登録',
      createSerialBreadcrumb: '製造機器登録',
      listSerialNo: '製造機器一覧',
      listSerialBreadcrumb: '製造機器一覧',
      arrBreadcrumbsItem: {
        listSerial: '製造管理',
        serialCreate: '製造機器登録',
        serialEdit: '製造機器詳細',
        serialDetail: '製造機器詳細',
        individual: '1台登録',
        individualEdit: '編集',
        bulk: '複数台登録'
      }
    },
    order: {
      orderTitle: '製造機器管理',
      createOrder: '出荷情報登録',
      listOrder: '出荷情報一覧',
      createOrderBreadcrumb: '出荷情報登録',
      listOrderBreadcrumb: '出荷一覧',
      orderDetailBreadcrumb: '出荷情報詳細',
      editOrderlBreadcrumb: '出荷情報編集',
      orderItem: {
        customerName: 'スペース名（顧客名）',
        orderName: 'オーダ',
        orderNote: '備考',
        orderDate: '納入確定日',
        orderLocation: '納入場所',
        registeredPerson: '登録者',
        changer: '更新者',
        createdAt: '登録日',
        updatedAt: '更新日'
      }
    },
    createUser: 'ユーザ新規登録',
    userList: 'ユーザ一覧',
    sensorList: 'センサ一覧',
    groupDetail: 'グループ詳細',
    sensorDetail: 'センサ詳細',
    batteryDropList: 'バッテリ電圧低下一覧',
    communicationErrorList: '通信異常一覧',
    listOfOutages: '稼働停止中一覧',
    caculatorList: '演算式管理',
    inforRegister: 'お知らせ作成',
    listNotification: 'お知らせ一覧',
    adminRegister: '管理者新規登録',
    listAdmin: '管理者一覧',
    dashBoardMenu: 'ダッシュボード',
    listOfOutagesDash: '稼働停止中センサ一覧',
    userDetail: 'ユーザ詳細',
    customerManagement: 'スペース管理（顧客管理）',
    customerList: '顧客一覧',
    customerRegister: '顧客新規登録',
    customerDetail: '顧客新規詳細',
    customerEdit: '顧客情報編集',
    changePass: 'パスワード変更'
  },

  userManagement: {
    txtCreatNew: '新規作成',
    txtUploadBtn: 'CSVエクスポート',
    txtBtnReturn: '戻る'
  },

  menu: {
    changePass: 'パスワード変更',
    logOut: 'ログアウト'
  },
  changePassword: {
    currentPassword: '現在のパスワード',
    newPassword: '新しいパスワード',
    confirmPassword: '新しいパスワード（確認用）',
    btnChange: '変更',
    changePassSuccess: 'パスワード変更が完了しました。'
  },

  dashBoard: {
    userRegister: '利用中ユーザ数',
    textTotalUser: '名（先月比）',
    sensorRegister: '運用中センサ数',
    textTotalSensor: '個（先月比）',
    labelCommunication: '通信異常発生中:',
    labelOperation: '稼働中:',
    labelBattery: 'バッテリ電圧低下:',
    lableStop: '停止中:'
  },
  seeMore: 'もっと見る',
  errors: {
    internet: 'Please check your internet connection and try again!',
    unauthenticated: 'Unauthenticated',
    badRequest: 'Bad request',
    wrong: 'Something went wrong!',
    message: {
      FRE00001: '{{0}}を入力して下さい。',
      FRE00002: '{{0}}は正しい形式ではありません。',
      FRE00003: 'メールアドレスまたはパスワードが違います。',
      FRE00005: '同じパスワードが入力されていません。',
      FRE00006: '{{0}}文字以上で入力してください。',
      FRE00007: '登録されていないメールアドレスです。',
      FRE00008: '一つの値を選択してください。',
      FRE00009: 'メールアドレスが既に登録されています。',
      FRE00010: '{{0}}には数字のみ入力できます。',
      FRE00011: 'ダウンロードできません。',
      FRE00014: '{{0}}が既に登録されています。  ',
      FRE00015: '該当の検索結果が見つかりません。',
      FRE00020: 'スペース名（顧客名）が既に登録されています。',
      FRE00021: '営業担当部署名が既に登録されています。',
      FRE00022: '代理店名が既に登録されています。',
      FRE00024: '{{0}} が既に登録されています。  													',
      FRE00031: 'IMEIが登録済みの機器と重複しているため登録できません',
      FRE00032: '既に紐づかれたスペース（顧客）があるため削除できません。',
      PRE00033: '現在のパスワードとは異なる新しいパスワードを入力してください',
      PRE00034: '半角英小文字・大文字・特殊記号をそれぞれ1種類以上含む8文字以上で入力してください。\n※特殊記号は「!・@・#・$・%・^・&・*」のみが入力可能です。 ',
      PRE00035: '※特殊記号は「!・@・#・$・%・^・&・*」のみが入力可能です。',
      PRE00036: 'IMEI番号は半角英数字15桁で入力してください',
      MSE0002: 'Username or password is incorrect',
      MSE0001: '登録されていないメールアドレスです。'
    },
    time: '適切な期間を指定して下さい。',
    changePasswordEmpty: '現在のパスワードを入力して下さい。',
    resetPasswordEmpty: '新しいパスワードを入力して下さい。',
    confirmPasswordEmpty: '新しいパスワード（確認用）を入力して下さい。',
    formulaBlank: '演算式を設定してください。',
    tvUsed: '{{0}} は {{1}} の変数と設定されているため削除できません。',
    duplicateCustomerKey:
      'お客様コードが重複しています。「新しいコードを取得」ボタンをクリックし、コードを更新ください。'
  },

  header: {
    searchTxt: 'Search something'
  },
  displayManagement: {
    calculator: {
      pageLabel: '設定種類：',
      typeLabel: '設定対象：',
      sensorTypeLabel: '通信種別：',
      type2Label: '計測値名称：',
      displayLabel: '演算式',
      textGuide:
        'テキストボックスに直接入力できません。下記の関数電卓及び変数電卓を使って、演算式を設定してください。',
      btnAddSensor: '変数追加',
      textGuide2: '変数は最大3個まで設定できます。'
    },
    setting: {
      multi: '一括設定',
      individual: '個別設定',
      btnSetting: '設定'
    },
    detail: {
      formula: '演算式詳細'
    },
    edit: {
      formula: '演算式編集'
    },
    copy: {
      formula: '演算式複製'
    },
    multiSetting: '一括登録',
    duplicateSensor: 'このデバイスIDに対する演算式が設定されています。'
  },
  adminManagement: {
    btnSignUp: '新規登録',
    plSearchMail: 'メールアドレス',
    btnSearch: '検索',
    adminName: '管理者名称',
    emailAcc: 'メールアカウント',
    registeredDate: '登録日時',
    authority: '権限',
    department: '部署',
    colEdit: '編集',
    colDel: '削除',
    confirmDel: 'このアカウントを削除しますか。',
    msgDelSuccess: 'このアカウントを削除しました。',
    newRegis: '管理者アカウント登録',
    editAccount: '管理者アカウント編集',
    deleteAdmin: 'このアカウントを削除しますか。',
    deleteAdminSuccess: 'このアカウントを削除しました。',
    confirmNewRegis: '管理者アカウントを登録しました。',
    confirmEditAccount: '管理者アカウントを編集しました。',
    nameLabel: '管理者名称',
    emailLabel: 'メールアドレス',
    selectLabel: '権限',
    descriptionLabel: '部署',
    btnConfirm: '登録',
    btnCancel: 'キャンセル',
    titleSignUp: '管理者のパスワード設定',
    titleResetPassword: '管理者のパスワード再設定',
    passwordLabel: '新しいパスワード',
    confirmLabel: '新しいパスワード（確認用）',
    confirmPassword: {
      passwordLabel: 'パスワード',
      confirmPasswordLabel: 'パスワード（確認用）',
      passwordLabelReset: '新しいパスワード',
      confirmPasswordLabelReset: ' 新しいパスワード（確認用）',
      btnConfirm: '保存',
      btnConfirmSendMail: '再発行',
      btnReturn: 'ログイン'
    },
    adminCrud: '全権限',
    adminView: '閲覧',
    adminInitial : '初期管理者'
  },

  modal: {
    message: {
      success: 'Success',
      updateUserSuccess: 'Update user successfully!',
      stopSensor: 'センサの稼働を停止しますか。',
      requestStartSensor: '再起動要求を再送しますか。',
      activeSensor: 'センサを復活しますか。',
      resendData: '再送要求を送信しますか。',
      editTimeSuccess: '通信異常監視時間を保存しました。',
      stopSensorSuccess: 'センタの稼働を停止しました。',
      activeSensorSuccess: 'センサを復活しました。',
      rebootSensorSuccess: '再起動要求を送信しました。',
      resendDataSuccess: '再送要求を送信しました。',
      removeOrderItem: '削除してもよろしいでしょうか。',
      removeSuccess: '削除しました。'
    },
    btnDelete: '削除',
    btnCancel: 'キャンセル',
    btnConfirm: '保存',
    contentDelDisplay: 'この演算式を削除しますか。',
    contentDelNoti: 'このお知らせを削除しますか。',
    contentSuccessDisplay: 'この演算式を削除しました。',
    btnClose: '閉じる',
    btnSubmit: '保存',
    communicationErr: '通信異常監視時間:',
    minutes: '分',
    btnStop: '停止',
    btnActive: '復活',
    btnReboot: '送信',
    contentSuccessEdit: 'お知らせを編集しました。',
    contentSuccessCreate: 'お知らせを登録しました。',
    contentSuccessNoti: 'このお知らせを削除しました。',
    firstOption: '個別設定を上書きします',
    secondOption: '個別設定を上書きしません',
    optionDes: 'こちらの一括登録で個別設定を上書きしますか。',
    contentSettingSuccess: '演算式を保存しました。',
    contentConfirmReplace: 'センサの個別設定があります。',
    verifySuccess: 'パスワード設定が完了しました。',
    sendMailResetSuccess: 'メールを送信いたしました。',
    resetPasswordSuccess: 'パスワード変更が完了しました。',
    contentSaveFormulaSuccess: '保存しました。',
    userManagement: {
      titleRegis: 'ユーザアカウント登録',
      btnRegis: '登録',
      stopUser: 'このユーザを停止しますか。',
      activeUser: 'このユーザを復活しますか。',
      deleteUser: 'このユーザを削除しますか。',
      success: {
        stopUser: 'このユーザを停止しました。',
        activeUser: 'このユーザを復活しました。',
        deleteUser: 'このユーザを削除しました。',
        createUser: 'ユーザ登録しました。',
        editUser: 'このユーザを編集しました。'
      },
      create: {
        lable: {
          userName: 'ユーザ名',
          email: 'メールアドレス',
          password: 'パスワード',
          passwordConfirm: 'パスワード（確認）'
        },
        placeholder: {
          userName: 'ユーザ名',
          email: 'メールアドレス',
          password: 'パスワード',
          passwordConfirm: 'パスワード（確認）'
        }
      },
      edit: {
        title: 'ユーザアカウント編集',
        option: {
          noChange: '変更無し',
          reset: 'リセットする',
          enter: '手入力する'
        },
        note: {
          reset: '新パスワードが自動的に生成され上記のメールアドレスに送付されます。',
          enter: '新パスワードを自由に設定いただけます。'
        }
      }
    },
    modalEnvironment: {
      content: 'ミハラス検証環境です。',
      btnClose: '閉じる'
    },
    customer: {
      confirmDel: 'この顧客を削除しますか?',
      customerDeleteContent: `顧客を削除するとその顧客に属するユーザ及びセンサ情報もすべて削除されます。よろしいでしょうか。`,
      delSuccess: 'この顧客を削除しました。'
    },
    resendMail: {
      btnSubmit: '再送',
      btnCancel: 'キャンセル',
      content: '招待メールを再送してよろしいでしょうか。',
      sendMailSuccess: '再送しました。'
    },
    passwordChanged : {
      btnLogin : 'ログイン',
      content : 'パスワードが変更されたため、\n再度ログインしてください。'
    },
    tokenTimeout : {
      btnLogin : 'ログイン',
      content : '待ち操作がタイムアウトになりました。'
    }
  },
  valid: '有効',
  invalid: '無効',
  all: 'すべて',
  userDetail: {
    userName: 'ユーザ名：',
    registerDate: '登録日時：',
    numberSensorGroup: 'センサグループ数：',
    numberSensor: 'センサ数：',
    email: 'メールアドレス：',
    passwordReset: 'パスワードリセット：',
    btnPasswordReset: 'ユーザ情報編集',
    tableUserAlert: {
      no: 'No',
      emailAlert: 'アラート通知メールアドレス',
      stop: '停止',
      resurrection: '復活',
      delete: '削除',
      stopOrActive: '停止/復活'
    },
    tableSensor: {
      no: 'No',
      deviceID: 'デバイスID',
      sensorName: 'センサ名',
      groupName: 'グループ名',
      sensorType: '機器種別',
      lteOrLora: ' 通信種別',
      imeiNumber: 'IMEI番号',
      securityKey: 'セキュリティキー',
      communicationStatus: '通信ステータス',
      battery: 'バッテリ電圧低下',
      registerDate: '登録日時',
      lastDate: 'データ最終取得日時'
    },
    modal: {
      contentDelete: 'このメールアドレスを削除しますか？',
      contentStop: 'このメールアドレスを停止しますか。',
      active: 'このメールアドレスを復活しますか？',
      success: {
        delete: 'このメールアドレスを削除しました。',
        active: 'このメールアドレスを復活しました。',
        stop: 'このメールアドレスを停止しました。'
      },
      editPassword: {
        title: 'ユーザ情報編集',
        success: 'このユーザを編集しました。'
      },
      editUserSuccess: 'ユーザ編集しました。'
    }
  },
  searchEmptyTable: '該当の検索結果が見つかりません。',
  japan: '日本',
  LoRa: 'LoRa',
  LTE: 'LTE-M',
  sensorList: 'のセンサ一覧',
  disconnectNetwork: 'ネットワークに接続されていません。',
  hours: '時間',
  minutes: '分',
  logout: {
    content: 'ログアウトしますか。',
    no: 'いいえ',
    yes: 'はい'
  },
  gotoLogin: 'ログインへ',
  accessDenied: {
    title: 'エラー',
    description: 'このページにアクセスする権限がありません。'
  },
  expiredPageContent: {
    expiredText: 'リンクの有効期間が切れています。',
    btnGoBack: 'ログイン'
  },
  applicable: '全{{0}}件',
  qrcodeScreen: {
    loraLte: '',
    imei: 'IMEI 番号',
    imeiPlaceHolder: 'LTE-Mのセンサの場合は入力してください',
    qrDetail: {
      title: 'センサ情報',
      qrcodeTitle: '登録結果',
      qrcode: 'QRコード',
      securityKey: 'セキュリテイキー',
      downloadQr: 'ダウンロード',
      copied: 'コピー済み'
    },
    createQr: {
      title: 'センサ情報入力',
      seriPlaceholder: '製造番号入力してください'
    },
    listQr: {
      dl: 'DL',
      btnGenQr: 'QRコード作成',
      btnClearSearch: 'キャンセル '
    },
    errors: {
      imei1: 'IMEI 番号を入力して下さい。',
      imei2: 'IMEI 番号には数字のみ入力できます。',
      imei3: 'IMEI 番号は数字の15桁で入力してください。',
      sensorType1: '機器種別を入力して下さい。',
      seri1: '製造番号を入力して下さい。',
      seri2: '製造番号には数字のみ入力できます。',
      loraLte1: '通信種別を入力して下さい。'
    }
  },
  customerScreen: {
    customerName: 'スペース名（顧客名）',
    serviceStartDate: 'サービス開始日',
    serviceEndDate: 'サービス終了日',
    selectDate: '納入確定日',
    customerStatus: 'ユーザ登録ステータス',
    sales: '営業担当部署',
    dealers: '代理店',
    edit: '詳細',
    delete: '削除',
    representativeEmail: '代表メールアドレス',
    salesName: '営業担当部署名',
    dealersName: '代理店名',
    corporateKey: 'お客様コード',
    remarks: '備考',
    registeredPerson: '登録者',
    changer: '更新者',
    registrationDate: '登録日',
    updateDate: '更新日',
    required: '必須',
    btnConfirm: '登録',
    btnConfirmAddSalesOrDealers: '追加',
    btnCancel: 'キャンセル',
    btnAddSales: '営業担当部署名追加',
    btnAddDealers: '代理店名追加',
    btnSubmitAdd: '追加',
    addSales: '営業担当部署追加',
    addDealers: '代理店追加',
    addSuccess: '追加しました。',
    btnSendMail: '期限切れた顧客に招待メールを一括再送する',
    startedAt: 'サービス開始日',
    endedAt: 'サービス終了日',
    createdAt: '顧客登録日',
    updatedAt: '顧客更新日',
    customerActiveStatus: {
      actived: 'ユーザ登録済',
      mailNotSend: 'メール未送信',
      mailSendButNotActive: 'メール招待中',
      expired: '期限切れ',
      resendMailWhenExpired: '再送'
    },
    createCustomer: {
      zipCodeLabel: '顧客住所',
      zipCodePlaceholder: '郵便番号をハイフン（-）も入力してください。',
      autoFilZipCodePlaceholder: '郵便番号入力後自動入力',
      buildingPlaceholder: '番地・建物名を入力してください。',
      requiredMark: '必須',
      coopKeyPlaceholder: '自動生成',
      errorMessage: {
        saleRequired: '営業担当部署名を入力して下さい。',
        dealerRequired: '代理店名を入力して下さい。',
        errZipCodeFormat: '{{0}}は正しい形式ではありません。'
      }
    },
    map: {
      satellite: '航空写真',
      map: '地図'
    },
    btnEditCustomer: '顧客情報編集',
    shippingInfo: '出荷情報',
    orderNumber: 'オーダ番号',
    shipmentRegistrationDate: '登録日',
    confirmedDeliveryDate: '納入確定日',
    deliveryLocation: '納入場所',
    shipment: '出荷台数',
    createSuccess: '登録しました。',
    editSuccess: '編集しました。'
  },
  ListSerialNoScreen: {
    FormLabel: {
      firstDropDown: '製造番号',
      secondDropDown: '旧製造番号',
      thirdDropDown: '旧製造番号',
      firstCheckboxs: '通信種別',
      secondCheckboxs: '機器種別',
      thirdCheckboxs: '製造工場',
      datepicker: '登録日'
    },
    TableLabel: {
      firstCol: 'No',
      secondCol: '製造番号',
      thirdCol: 'スペース名（顧客名）',
      fourthCol: 'デバイスID',
      fifthCol: '通信種別',
      sixthCol: '機器種別',
      seventhCol: 'セキュリティキー\n又はIMEI番号',
      eighthCol: '製造工場',
      ninethCol: '疎通確認',
      tenthCol: '更新日',
      eleventhCol: '詳細',
      twelvethCol: '削除'
    },
    Button: {
      uploadCsv: 'CSVインポート'
    }
  },
  csvTable: {
    orderDetail: {
      shipmentRegistrationDate: '登録日',
      updateDate: '更新日',
      customerName: '顧客名',
      order: 'オーダ',
      confirmedDeliveryDate: '納入確定日',
      deliveryLocation: '納入場所',
      serialNo: '製造番号',
      model: '型式',
      manufacturingDate: '製造年月',
      deviceId: 'デバイスID',
      communicationType: '通信種別',
      deviceType: '機器種別',
      securityKey: 'セキュリティキー',
      imeiNumber: 'ＩＭＥＩ番号',
      oldSerialNo: '旧製造番号',
      wirelessCH: '無線CH',
      manufacturingPlant: '製造工場',
      communicationConfirmationStatus: '疎通確認ステータス',
      remarks: '備考'
    }
  }
}

export default jp
