import { Col, Form, Row } from 'antd'
import VButton from 'components/button/VButton'
import InputComponent from 'components/common/input/InputComponent'
import InputMonthComponent from 'components/common/input/InputMonthComponent'
import InputTextAreaComponent from 'components/common/input/InputTextAreaComponent'
import InputYearComponent from 'components/common/input/InputYearComponent'
import SelectComponent from 'components/common/select/SelectComponent'
import { IPullDownType, ISensorPullDownType, ISensorTranslate } from 'interfaces/ISerialNo'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import {
  getListKatashikiAction,
  getListSensorAction,
  getSerialNoDetailAction,
  updateSerialNoAction
} from 'redux/actions/serialNo/serialNoAction'
import { ADMIN_ROUTE, routeSerial } from 'views/routes/routes'
import { defaultFactoryList } from '../../defaultValue'
import { validImei } from './rules'
  
function EditSerialNo() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const serialNoId = useParams<any>()
  const styleTile = { minHeight: 90, maxHeight: 90, borderRadius: 2 }
  const refYear = useRef()
  const [year, setYear] = useState<any>(null)
  const refMonth = useRef()
  const [month, setMonth] = useState<any>(null)
  const [selectCommsType, setSelectCommsType] = useState<any>()
  const [communicationTypeList, setCommunicationTypeList] = useState<any>([])
  const [selectType, setSelectType] = useState<any>()
  const [typeList, setTypeList] = useState<any>([])
  const [selectEquipmentType, setSelectEquipmentType] = useState<any>()
  const [equipmentTypeList, setEquipmentTypeList] = useState<any>([])
  const [selectFactory, setSelectFactory] = useState<any>()
  const [factoryList, setFactoryList] = useState<any>([])
  const [cnf, setCnf] = useState<string>('')
  // change option
  const handleOnSelectFactory = (currentFactory: any) => {
    setSelectFactory(currentFactory)
  }
  const handleOnChangeCnf = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCnf(e.target.value)
  }
  const handleReturn = () => {
    history.push(ADMIN_ROUTE.concat(routeSerial.LIST_SERIAL))
  }
  // change year
  const handleOnChangeYear = (year: Date) => {
    setYear(year)
  }
  const handleOnChangeMonth = (month: Date) => {
    setMonth(month)
  }
  useEffect(() => {
    handleGetListKatashiki()
    setFactoryList(defaultFactoryList)
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    handleGetListSensor()
    // eslint-disable-next-line
  }, [selectCommsType])
  useEffect(() => {
    handleGetSerialNoDetail()
    // eslint-disable-next-line
  }, [])
  // data for dropdown katashiki
  const handleGetListKatashiki = () => {
    const params = {}
    dispatch(
      getListKatashikiAction(
        { ...params },
        (data) => {
          if (data && data?.result) {
            let listResult: any = []
            data.result = data.result.map(
              (
                { id, model, deletedAt }: IPullDownType // eslint-disable-next-line
              ) => {
                if (deletedAt === null && model !== '') {
                  listResult.push({
                    id: id,
                    name: model,
                    value: id
                  })
                }
              }
            )
            listResult.sort((a: any, b: any) => a.id - b.id)
            setTypeList(listResult)
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }
  // data for dropdown sensor
  const handleGetListSensor = () => {
    const params = {
      type: selectCommsType === 1 ? 'LoRa' : ''
    }
    dispatch(
      getListSensorAction(
        { ...params },
        (data) => {
          if (data && data?.result) {
            dispatch(SetLoadingAction(false))
            const resultCommsType: any = []
            const resultTypes: any = []
            data.result.sensorTypes = data.result.sensorTypes.map(
              (
                { sensorTypeId, sensorTypeKey }: ISensorPullDownType // eslint-disable-next-line
              ) => {
                resultCommsType.push({
                  id: sensorTypeId,
                  name: sensorTypeKey,
                  value: parseInt(sensorTypeId, 10)
                })
              }
            )
            data.result.sensorTranslate = data.result.sensorTranslate.map(
              (
                { sensorTypeTranslateId, sensorTypeName }: ISensorTranslate // eslint-disable-next-line
              ) => {
                resultTypes.push({
                  id: sensorTypeTranslateId,
                  name: sensorTypeName,
                  value: parseInt(sensorTypeTranslateId, 10)
                })
              }
            )
            setCommunicationTypeList(resultCommsType)
            setEquipmentTypeList(resultTypes)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        false
      )
    )
  }
  const handleGetSerialNoDetail = async () => {
    const params: any = {
      id: Number(serialNoId?.id)
    }
    dispatch(
      getSerialNoDetailAction(
        { ...params },
        async (data) => {
          if (data && data?.result) {
            dispatch(SetLoadingAction(false))
            const detail = data?.result
            form.setFieldsValue({
              oldSerialNumber: detail?.serialNoNumberOld,
              imei: detail?.IMEINumber,
              channel: detail?.CHAddress,
              notes: detail?.note
            })
            setSelectCommsType(detail?.sensorTypeId)
            setSelectType(detail?.modelRegistrationId)
            setSelectEquipmentType(detail?.sensorTypeTranslateId)
            setSelectFactory(detail?.productSite)
            setYear(moment(detail?.manufacturingDate).toDate())
            setMonth(moment(detail?.manufacturingDate).toDate())
            setCnf(detail?.cnfSetting)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }
  const onFinish = () => {
    const formatYear = parseInt(moment(year).format('YYYY'), 10)
    const formatMonth = parseInt(moment(month).format('M'), 10)
    const params: any = {
      id: Number(serialNoId?.id),
      sensorTypeId: selectCommsType,
      modelRegistrationId: selectType,
      sensorTypeTranslateId: selectEquipmentType,
      manufacturingYear: formatYear,
      manufacturingMonth: formatMonth,
      productSite: selectFactory,
      serialNoNumberOld: form.getFieldValue('oldSerialNumber')?.trim(),
      IMEINumber: form.getFieldValue('imei')?.trim(),
      CHAddress: form.getFieldValue('channel')?.trim(),
      note: form.getFieldValue('notes')?.trim(),
      cnfSetting: cnf?.trim()
    }
    dispatch(
      updateSerialNoAction(
        { ...params },
        async (data) => {
          if (data && data?.code === 400) {
            let errors: string[] = []
            let name: string = ''
            if (data?.data?.id[0] === 'FRE00031') {
              name = 'imei'
              errors = [t(`errors.message.FRE00031`)]
            }
            form.setFields([
              {
                name: name,
                errors: errors
              }
            ])
            window.scrollTo(0, 0)
            dispatch(SetLoadingAction(false))
          } else if (data && data?.result?.id) {
            dispatch(SetLoadingAction(false))
            const serialId = await data?.result?.id
            if (serialId) {
              history.push(
                ADMIN_ROUTE.concat(
                  routeSerial.INDIVIDUAL_DETAIL.replace(':id', serialId.toString())
                )
              )
            }
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }
  const checkDisable = () => {
    if (
      selectType &&
      selectEquipmentType &&
      !form.getFieldsError().filter(({ errors }) => errors.length).length
    ) {
      return false
    }
    return true
  }

  const onValuesChange = (values: any) => {
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field)
      if (!error.length) {
        return
      }
      // Clear error message of field
      form.setFields([
        {
          name: field,
          errors: []
        }
      ])
    })
  }

  return (
    <div className='bgrffffff'>
      <div className='serial-no-management pd_16x_0x'>
        <div className='mg_0_16x'>
          <p className='label-w-bold'>{t('serialNoScreen.serialCreate.serialCreateLabel')}</p>
          <div className='create-serial'>
            <Form form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
              <Row gutter={24} style={{ marginBottom: 14 }}>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.serialCreate.type')}
                    disableAll={true}
                    isLabelRequired={true}
                    dataOption={typeList}
                    defaultOption={selectType}
                    disableSelect={true}
                  />
                </Col>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.serialCreate.communicationType')}
                    isLabelRequired={true}
                    disableAll={true}
                    dataOption={communicationTypeList}
                    defaultOption={selectCommsType}
                    disableSelect={true}
                  />
                </Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: 14 }}>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.serialCreate.equipmentType')}
                    isLabelRequired={true}
                    disableAll={true}
                    dataOption={equipmentTypeList}
                    defaultOption={selectEquipmentType}
                    disableSelect={true}
                  />
                </Col>
                <Col span={12}>
                  <div className='year-picker-label'>
                    {t('serialNoScreen.serialCreate.yearAndMonthPicker')} <span>*</span>
                  </div>
                  <Row gutter={15}>
                    <Col span={12}>
                      <InputYearComponent
                        refer={refYear}
                        state={year}
                        readOnly={false}
                        selectStart={true}
                        onChangeDate={(year: Date) => handleOnChangeYear(year)}
                        tidleSign={true}
                        customPopper='bottom'
                        describeText={t('serialNoScreen.serialCreate.year')}
                      />
                    </Col>
                    <Col span={12}>
                      <InputMonthComponent
                        refer={refMonth}
                        state={month}
                        readOnly={false}
                        selectStart={true}
                        onChangeDate={(month: Date) => handleOnChangeMonth(month)}
                        tidleSign={true}
                        customPopper='bottom'
                        describeText={t('serialNoScreen.serialCreate.month')}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <InputComponent
                    label={t('serialNoScreen.serialCreate.oldSerialNumber')}
                    name='oldSerialNumber'
                    type='text'
                    maxLength={64}
                  />
                </Col>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.serialCreate.factory')}
                    dataOption={factoryList}
                    defaultOption={selectFactory}
                    disableAll={true}
                    onChangeSelect={handleOnSelectFactory}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <InputComponent
                    label={t('serialNoScreen.serialCreate.imei')}
                    name='imei'
                    type='text'
                    maxLength={15}
                    rules={validImei.imei}
                  />
                </Col>
                <Col span={12}>
                  <InputComponent
                    label={t('serialNoScreen.serialCreate.channel')}
                    name='channel'
                    type='text'
                    maxLength={64}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <InputComponent
                    label={t('serialNoScreen.serialCreate.notes')}
                    name='notes'
                    type='text'
                    maxLength={64}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <div className='lable-mb font-w-bold' style={{ fontSize: 12, marginBottom: 8 }}>
                    {t('serialNoScreen.serialCreate.cnf')}
                  </div>
                  <InputTextAreaComponent
                    maxLength={625}
                    state={cnf}
                    style={styleTile}
                    onChangeText={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleOnChangeCnf(e)
                    }
                    disabled={false}
                    placeholder={''}
                  />
                </Col>
              </Row>
              <div className='group-btn-create'>
                <Form.Item shouldUpdate>
                  {() => (
                    <VButton
                      title='編集'
                      style={styleBtnSubmit}
                      type='text'
                      disabled={checkDisable()}
                    />
                  )}
                </Form.Item>
                <VButton
                  htmlType='button'
                  onClick={handleReturn}
                  title={t('customerScreen.btnCancel')}
                  style={styleBtnReturn}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
const styleBtnSubmit = {
  width: 74,
  height: 38,
  color: '#ffffff'
}
const styleBtnReturn = {
  backgroundColor: '#fff',
  color: '#d7000f',
  border: 'solid 1px #d7000f',
  height: 38,
  marginLeft: 24
}

export default EditSerialNo
