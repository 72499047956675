import InputSearchComponent from 'components/common/input/InputSearchComponent'
import {
  IDataEventLogInformaion,
  IDataSensorDetail,
  IDataUserInformation
} from 'interfaces/sensor/ISensorDetail'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import ShowSenserTypeLora from './typeSensor/ShowSenserTypeLora'
import ShowSensorTypeLTE from './typeSensor/ShowSensorTypeLTE'
import TableTypeLora from './table/TableTypeLora'
import TableTypeLTE from './table/TableTypeLTE'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'
import ModalComponent from 'components/modal/ModalComponent'
import ChangeTimeComponent from './ChangeTimeComponent'
import {
  IconActiveSensorModal,
  IconResendModal,
  // IconStartSensorModal,
  IconStopModal
} from 'assets'
import { useDispatch } from 'react-redux'
import {
  SetLoadingAction,
  SetNotificationAction,
  SuccessAction
} from 'redux/actions/common/commonAction'
import { IOption } from 'interfaces/sensor/IPropsSelect'
import {
  getListEventBySensorAction,
  searchDeviceIdSensorAction,
  sensorDetailAction,
  updateSensorAction
} from 'redux/actions/sensor/sensorAction'
import {
  convertDataSensorDetail,
  convertListEvent,
  convertListUser,
  downloadFileJson
} from './convertData'
import { initialValueSensorDetail } from './initialValue'
import { defaultOperaStatus, defaultTypeSensorLora } from '../defaultValue'
import TableComponent from 'components/common/table/TableComponent'
import { columnsUserInformation } from './defaultColumns'
import { INotification } from 'interfaces/INotification'
import { convertListDeviceId } from '../convertData'
import { ADMIN_ROUTE, routesName, routesSensorManagement } from 'views/routes/routes'
import { EMaxLength } from 'interfaces/enums/EMaxLength'
interface IParamTypes {
  id: string
}
function SensorDetailComponent() {
  // param id sensor
  const { id } = useParams<IParamTypes>()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  // device id
  const [deviceId, setDeviceId] = useState<string>()
  // submit search
  const handleOnSubmitSearch = (valueSearch: string) => {
    if (valueSearch !== undefined) {
      setDeviceId(valueSearch)
      history.push(ADMIN_ROUTE.concat(routesSensorManagement.SENSOR_DETAIL + `/${valueSearch}`))
    } else {
      setDeviceId(undefined)
    }
  }
  // state sensor detail
  const [sensorDetail, setSensorDetail] = useState<IDataSensorDetail>(initialValueSensorDetail)
  // time Tracking
  const [timeTracking, setTimeTracking] = useState<string>('70')
  const handleChangeTime = (value: string) => {
    setTimeTracking(value)
  }
  // action lora
  const handleOnClickBtnJson = async () => {
    downloadFileJson(sensorDetail)
  }
  const handleOnClickEditTimeTracking = () => {
    setModal({
      ...modal,
      isVisible: true,
      type: 'edit-time',
      isComponent: true,
      btnSubmit: t('modal.btnSubmit'),
      component: (
        <ChangeTimeComponent
          defaultValue={timeTracking}
          onChangeSelect={(value) => handleChangeTime(value)}
        />
      )
    })
  }
  const handleOnClickBtnStopSensor = () => {
    setModal({
      ...modal,
      isVisible: true,
      type: 'stop-sensor',
      isComponent: false,
      img: IconStopModal,
      btnSubmit: t('modal.btnStop'),
      content: t('modal.message.stopSensor')
    })
  }
  const handleOnClickBtnActiveSensor = () => {
    setModal({
      ...modal,
      isVisible: true,
      type: 'active-sensor',
      isComponent: false,
      img: IconActiveSensorModal,
      btnSubmit: t('modal.btnActive'),
      content: t('modal.message.activeSensor')
    })
  }
  const handleOnClickBtnRequestStart = () => {
    // setModal({
    //   ...modal,
    //   isVisible: true,
    //   type: 'request-start-sensor',
    //   isComponent: false,
    //   img: IconStartSensorModal,
    //   btnSubmit: t('modal.btnReboot'),
    //   content: t('modal.message.requestStartSensor')
    // })
  }
  const handleOnClickBtnResendData = () => {
    setModal({
      ...modal,
      isVisible: true,
      btnSubmit: t('modal.btnReboot'),
      type: 'resend-data',
      isComponent: false,
      img: IconResendModal,
      content: t('modal.message.resendData')
    })
  }
  // list user information
  const [dataTableUserInfor, setDataTableUserInfor] = useState<IDataUserInformation[]>([])
  // list event information
  const [dataTableEventInfor, setDataTableEventInfor] = useState<IDataEventLogInformaion[]>([])
  // modal
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel')
  })
  // action submit
  const updateTimeTracking = async () => {
    if (+timeTracking > 0) {
      const param = {
        deviceId: sensorDetail?.deviceId ?? '',
        timeTracking: +timeTracking
      }
      dispatch(
        updateSensorAction(true, { ...param }, (data) => {
          if (data?.result) {
            if (data?.result?.affected > 0) {
              setSensorDetail({
                ...sensorDetail,
                timeTracking: timeTracking
              })
              success(t('modal.message.editTimeSuccess'))
            } else {
              dispatch(SetLoadingAction(false))
            }
          } else {
            dispatch(SetLoadingAction(false))
          }
        })
      )
    }
  }
  // enable/disable sensor
  const enableOrDisableSensor = async (type: string) => {
    const param = {
      deviceId: sensorDetail?.deviceId ?? '',
      statusActive: type === 'active-sensor' ? true : false
    }
    dispatch(
      updateSensorAction(true, { ...param }, async (data) => {
        if (data?.result) {
          if (data?.result?.affected > 0) {
            const text =
              type === 'active-sensor'
                ? t('modal.message.activeSensorSuccess')
                : t('modal.message.stopSensorSuccess')
            setSensorDetail({
              ...sensorDetail,
              operaStt:
                type === 'active-sensor' ? defaultOperaStatus.active : defaultOperaStatus.stop
            })
            await getListEventBySensor()
            success(text)
          } else {
            dispatch(SetLoadingAction(false))
          }
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
  }
  const handleOnSubmitModal = () => {
    // check modal?.type dispatch action
    switch (modal?.type) {
      case 'edit-time':
        updateTimeTracking()
        break
      case 'stop-sensor':
        enableOrDisableSensor('stop-sensor')
        break
      case 'active-sensor':
        enableOrDisableSensor('active-sensor')
        break
      case 'request-start-sensor':
        // text = t('modal.message.rebootSensorSuccess')
        break
      default:
        // text = t('modal.message.resendDataSuccess')
        break
    }
  }
  const handleOnCancelModal = () => {
    if (modal?.type === 'edit-time') {
      setTimeTracking(sensorDetail?.timeTracking?.toString() ?? '70')
    }
    setModal({ ...modal, isVisible: false })
  }
  const success = (text: string) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }
  const fetchOptionList = async (valueSearch: string): Promise<IOption[]> => {
    let dataSearchOption: any = []
    if (valueSearch?.trim()) {
      const param = {
        deviceId: valueSearch
      }
      const res = await searchDeviceIdSensorAction(param)
      if (res?.data?.error) {
        const noti: INotification = {
          title: t('notification.error'),
          message: res?.data?.error?.message,
          code: res?.data?.error?.code
        }
        dispatch(SetNotificationAction(noti))
      } else {
        dataSearchOption = convertListDeviceId(res?.data?.result)
      }
    }
    return dataSearchOption
  }
  const getListEventBySensor = async () => {
    const param = {
      deviceId: sensorDetail?.deviceId,
      sortBy: {
        target: 'createdAt',
        order: 'ASC'
      }
    }
    dispatch(
      getListEventBySensorAction({ ...param }, (data) => {
        if (data?.result) {
          const dataEvent = convertListEvent(data?.result?.record)
          setDataTableEventInfor(dataEvent)
        }
      })
    )
  }
  useEffect(() => {
    const param = {
      deviceId: id
    }
    dispatch(SetLoadingAction(true))
    dispatch(
      sensorDetailAction({ ...param }, (data) => {
        if (data?.result?.sensorAiId) {
          const datasensorDetail = convertDataSensorDetail(data?.result)
          const dataUser = convertListUser(data?.result)
          const dataEvent = convertListEvent(data?.result?.events)
          setSensorDetail(datasensorDetail)
          setDataTableUserInfor(dataUser)
          setDataTableEventInfor(dataEvent)
          setTimeTracking(datasensorDetail?.timeTracking ?? '70')
          dispatch(SetLoadingAction(false))
        } else {
          setSensorDetail(initialValueSensorDetail)
          dispatch(SetLoadingAction(false))
          history.push(ADMIN_ROUTE.concat(routesName.NOT_FOUND))
        }
      })
    )
    // eslint-disable-next-line
  }, [id, dispatch])
  return (
    <>
      {sensorDetail?.lteOrLora ? (
        <div>
          <div className='bgrffffff'>
            <div className='sensor-detail pd_16x_0x'>
              <div className='block-sensor-detail'>
                <div className='mg_0_16x'>
                  <div className='search'>
                    <InputSearchComponent
                      placeholder={t('sensorManagementScreen.placeholder')}
                      maxLength={EMaxLength.max255}
                      btnSearch={t('sensorManagementScreen.btnSearch')}
                      onSubmitSearch={(valueSearch) => handleOnSubmitSearch(valueSearch)}
                      fetchOptions={fetchOptionList}
                      valueSelect={deviceId}
                    />
                  </div>
                  <div className='show-sensor-detail'>
                    {sensorDetail?.lteOrLora === defaultTypeSensorLora ? (
                      <ShowSenserTypeLora
                        sensorDetailData={sensorDetail}
                        onClickBtnJson={handleOnClickBtnJson}
                        onClickBtnEdit={handleOnClickEditTimeTracking}
                        onClickBtnStopSensor={handleOnClickBtnStopSensor}
                        onClickBtnActiveSensor={handleOnClickBtnActiveSensor}
                      />
                    ) : sensorDetail?.lteOrLora.indexOf('LTE') > -1 ? (
                      <ShowSensorTypeLTE
                        sensorDetailData={sensorDetail}
                        onClickBtnJson={handleOnClickBtnJson}
                        onClickBtnEdit={handleOnClickEditTimeTracking}
                        onClickBtnStopSensor={handleOnClickBtnStopSensor}
                        onClickBtnActiveSensor={handleOnClickBtnActiveSensor}
                        onClickBtnSend={handleOnClickBtnRequestStart}
                        onClickBtnResendData={handleOnClickBtnResendData}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='block-table'>
            <div className='bgrffffff'>
              <div className='custom-table'>
                <div className='pd_16x_16x'>
                  <span className='font-w-bold fs-16x'>{t('sensorDetail.userInfor')}</span>
                </div>
                <TableComponent columns={columnsUserInformation} dataSource={dataTableUserInfor} />
              </div>
            </div>
          </div>
          <div className='block-table'>
            <div className='bgrffffff'>
              <div className='custom-table'>
                <div className='pd_16x_16x'>
                  <span className='font-w-bold fs-16x'>{t('sensorDetail.eventLogInfor')}</span>
                </div>
                {sensorDetail?.lteOrLora === defaultTypeSensorLora ? (
                  <TableTypeLora data={dataTableEventInfor} />
                ) : (
                  <TableTypeLTE data={dataTableEventInfor} />
                )}
              </div>
              <div className='btn-return text-align-center'>
                <ButtonReturnComponent />
              </div>
            </div>
          </div>
          {modal?.isVisible ? (
            <ModalComponent
              isVisible={modal?.isVisible}
              isComponent={modal?.isComponent}
              img={modal?.img}
              content={modal?.content}
              component={modal?.component}
              btnSubmit={modal?.btnSubmit}
              btnCancel={modal?.btnCancel}
              onCancelModal={handleOnCancelModal}
              onSubmitModal={handleOnSubmitModal}
            />
          ) : null}
        </div>
      ) : null}
    </>
  )
}
export default SensorDetailComponent
