import { Row, Col } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { IconActiveSensorModal, IconDeleteBtn, IconDeleteModal, IconStopModal } from 'assets'
import VButton from 'components/button/VButton'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import TableComponent from 'components/common/table/TableComponent'
import ModalComponent from 'components/modal/ModalComponent'
import { originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import { INotification } from 'interfaces/INotification'
import {
  IDataListTableSensorManagementUserDetail,
  IDataTableUserAlert,
  IModalEditUser,
  IParamStatusEmail,
  IUserDetail
} from 'interfaces/userDetail/userDetail'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  SetLoadingAction,
  SetNotificationAction,
  SuccessAction
} from 'redux/actions/common/commonAction'
import {
  getListDataOfUserDetailAction,
  updateEmailAlertAction,
  userDetailAction
} from 'redux/actions/userDetail/usersDetailAction'
import { AppState } from 'redux/reducers'
import { ConvertTime } from 'utils/Functions'
import ItemComponent from 'views/pages/sensor/group/ItemComponent'
import { ADMIN_ROUTE } from 'views/routes/routes'
import {
  convertDataUserDetail,
  convertListGroupNosensor,
  convertListSensor,
  convertListUserAlert
} from './convertData'
import { defineColumSensorOfUser } from './defaultColums'
import {
  initialEditUser,
  initialModal,
  initialParamStatusEmail,
  initialUserDetail
} from './initialState'
import ModalEditUserComponent from './ModalEditUserComponent'
import { styBtnBtnActive, styBtnBtnStop, styBtnResetPassword } from './style'
interface IParamTypes {
  id: string
}
const getData = (
  current: number,
  pageSize: number,
  data: IDataListTableSensorManagementUserDetail[]
) => {
  if (data) {
    return data.slice((current - 1) * pageSize, current * pageSize)
  }
}
function UserDetailComponent() {
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  // param id group
  const { id } = useParams<IParamTypes>()
  // data table user alert
  const [dataTableUserAlert, setDataTableUserAlert] = useState<IDataTableUserAlert[]>()
  // data table sensor
  const [dataTable, setDataTable] = useState<IDataListTableSensorManagementUserDetail[]>([])
  // data sensor no group
  const [listGroupNoSensor, setListGroupNoSensor] = useState<
    IDataListTableSensorManagementUserDetail[]
  >([])
  const [paramStatus, setParamStatus] = useState<IParamStatusEmail>({
    ...initialParamStatusEmail
  })
  // modal edit user
  const [modalEditUser, setModalEditUser] = useState<IModalEditUser>({
    ...initialEditUser
  })
  const columsTableUserAlert: ColumnsType<IDataTableUserAlert> = [
    {
      title: t('userDetail.tableUserAlert.no'),
      dataIndex: '',
      width: '5%',
      render: (text, row, index) => {
        return <span>{index + 1}</span>
      }
    },
    {
      title: t('userDetail.tableUserAlert.emailAlert'),
      dataIndex: 'emailAlert'
    },
    ROLE_CRUD.includes(role)
      ? {
          title: t('userDetail.tableUserAlert.stopOrActive'),
          dataIndex: '',
          width: '80px',
          align: 'center',
          render: (_: object, record: { key: string; statusEmail: string; emailAlert: string }) => {
            return record?.statusEmail === 'stop' ? (
              <VButton
                type='text'
                title={t('userDetail.tableUserAlert.resurrection')}
                style={styBtnBtnActive}
                onClick={() => handleOnClickActive(record?.key, record?.emailAlert)}
              />
            ) : (
              <VButton
                type='text'
                title={t('userDetail.tableUserAlert.stop')}
                style={styBtnBtnStop}
                onClick={() => handleOnClickStop(record?.key, record?.emailAlert)}
              />
            )
          }
        }
      : {},
    ROLE_CRUD.includes(role)
      ? {
          title: t('userDetail.tableUserAlert.delete'),
          dataIndex: '',
          width: '55px',
          align: 'center',
          render: (_: object, record: { key: string; emailAlert: string }) => {
            return (
              <img
                onClick={() => handleOnClickDelete(record?.key, record?.emailAlert)}
                src={IconDeleteBtn}
                alt='delete'
                className='w42x-h28x pointer'
              />
            )
          }
        }
      : {}
  ]
  const [userDetail, setUserDetail] = useState<IUserDetail>({
    ...initialUserDetail
  })
  // modal
  const [modal, setModal] = useState({ ...initialModal })
  // pagination
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  // acton edit user
  const handleOnClickResetPassword = () => {
    const initialValue = {
      userId: userDetail?.userId ?? '',
      username: userDetail?.userName ?? '',
      email: userDetail?.email ?? '',
      passwordGroup: 'reset',
      role: userDetail?.role,
      statusUser: userDetail?.statusUser
    }
    setModalEditUser({
      isVisible: true,
      initialValue: initialValue
    })
  }
  const handleOnSubmitResetPassword = (update: any) => {
    const updateUserDetail: IUserDetail = {
      userId: userDetail?.userId,
      userName: update?.userName,
      registerDate: userDetail?.registerDate,
      numberSensorGroup: userDetail?.numberSensorGroup,
      numberSensor: userDetail?.numberSensor,
      email: update?.email,
      role: userDetail?.role,
      statusUser: update?.statusUser
    }
    setUserDetail(updateUserDetail)
    setModalEditUser({ ...modalEditUser, isVisible: false })
    success(t('userDetail.modal.editUserSuccess'))
  }
  // action stop alert
  const handleOnClickStop = (key: string, email: string) => {
    setParamStatus({
      key: key,
      userId: userDetail?.userId,
      email: email,
      type: 'inactive'
    })
    setModal({
      ...modal,
      isVisible: true,
      type: 'stop-alert',
      isComponent: false,
      img: IconStopModal,
      btnSubmit: t('modal.btnStop'),
      content: t('userDetail.modal.contentStop')
    })
  }
  // action active user
  const handleOnClickActive = (key: string, email: string) => {
    setParamStatus({
      key: key,
      userId: userDetail?.userId,
      email: email,
      type: 'active'
    })
    setModal({
      ...modal,
      isVisible: true,
      type: 'active-alert',
      isComponent: false,
      img: IconActiveSensorModal,
      btnSubmit: t('modal.btnActive'),
      content: t('userDetail.modal.active')
    })
  }
  // action delete user
  const handleOnClickDelete = (key: string, email: string) => {
    setParamStatus({
      key: key,
      userId: userDetail?.userId,
      email: email,
      type: 'delete'
    })
    setModal({
      ...modal,
      isVisible: true,
      type: 'delete-alert',
      isComponent: false,
      img: IconDeleteModal,
      btnSubmit: t('modal.btnDelete'),
      content: t('userDetail.modal.contentDelete')
    })
  }
  // action
  const stopOrActiveEmailAlert = () => {
    dispatch(
      updateEmailAlertAction(
        {
          userId: paramStatus?.userId,
          email: paramStatus?.email,
          type: paramStatus?.type
        },
        true,
        (data) => {
          if (data?.result) {
            let text = ''
            paramStatus?.type === 'inactive'
              ? (text = t('userDetail.modal.success.stop'))
              : (text = t('userDetail.modal.success.active'))
            const itemUpdate = {
              key: paramStatus?.key,
              emailAlert: paramStatus?.email,
              statusEmail: data?.result?.statusActive ? 'active' : 'stop'
            }
            if (dataTableUserAlert) {
              const data = [...dataTableUserAlert]
              const newState = data?.map((item) =>
                item?.key === itemUpdate?.key ? itemUpdate : item
              )
              setDataTableUserAlert(newState)
            }
            success(text)
          } else {
            dispatch(SetLoadingAction(false))
          }
        }
      )
    )
  }
  // delete user alert
  const deleteUserAlert = () => {
    dispatch(
      updateEmailAlertAction(
        {
          userId: paramStatus?.userId,
          email: paramStatus?.email,
          type: paramStatus?.type
        },
        true,
        (data) => {
          if (data?.result?.deletedAt) {
            if (dataTableUserAlert) {
              const dataSource = [...dataTableUserAlert]
              setDataTableUserAlert(dataSource?.filter((item) => item?.key !== paramStatus?.key))
              success(t('userDetail.modal.success.delete'))
            }
          } else {
            dispatch(SetLoadingAction(false))
          }
        }
      )
    )
  }

  // submit modal
  const handleOnSubmitModal = () => {
    // check modal?.type dispatch action
    switch (modal?.type) {
      case 'stop-alert':
        stopOrActiveEmailAlert()
        break
      case 'active-alert':
        stopOrActiveEmailAlert()
        break
      case 'delete-alert':
        deleteUserAlert()
        break
      default:
        break
    }
  }
  const success = (text: string) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }
  // action pagination
  const handleOnChangePage = (pageChange: number) => {
    setCurrent(pageChange)
  }
  const handleOnChangePageSize = (size: number) => {
    const totalPageChange = Math.ceil(dataTable?.length / size)
    if (current > totalPageChange) {
      setCurrent(totalPageChange)
    }
    setPageSize(size)
  }
  useEffect(() => {
    dispatch(SetLoadingAction(true))
    const param = {
      userId: id
    }
    dispatch(
      userDetailAction({ ...param }, (data) => {
        if (data?.code) {
          dispatch(SetLoadingAction(false))
          history.push(ADMIN_ROUTE.concat('/not-found'))
        } else {
          const dataUserDetail = convertDataUserDetail(data?.result)
          const dataListGroupNoSensor = convertListGroupNosensor(data?.result?.groupNoSensor)
          setListGroupNoSensor(dataListGroupNoSensor)
          setUserDetail(dataUserDetail)
        }
      })
    )
    // eslint-disable-next-line
  }, [id])
  useEffect(() => {
    if (userDetail?.userId) {
      const param = {
        emailAlert: {
          userId: userDetail?.userId,
          sortBy: {
            target: 'createdAt',
            order: 'ASC'
          }
        },
        sensor: {
          userId: userDetail?.userId,
          sortBy: {
            target: 'createdAt',
            order: 'ASC'
          }
        }
      }
      dispatch(
        getListDataOfUserDetailAction({ ...param }, (data) => {
          if (data[0] && data[0]?.error) {
            const noti: INotification = {
              title: t('notification.error'),
              message: data[0]?.error?.message,
              code: data[0]?.error?.code
            }
            dispatch(SetNotificationAction(noti))
          } else {
            const dataEmailAlertConvert = convertListUserAlert(data[0]?.result)
            setDataTableUserAlert(dataEmailAlertConvert)
          }
          if (data[1] && data[1]?.error) {
            const noti: INotification = {
              title: t('notification.error'),
              message: data[1]?.error?.message,
              code: data[1]?.error?.code
            }
            dispatch(SetNotificationAction(noti))
          } else {
            const dataSensorConvert = convertListSensor(data[1]?.result)
            setDataTable(dataSensorConvert.concat(listGroupNoSensor))
          }
          dispatch(SetLoadingAction(false))
        })
      )
    }
    // eslint-disable-next-line
  }, [id, userDetail?.userId])
  return (
    <>
      <div className='bgrffffff user-detail-management'>
        <div className='user-detail pd_16x_0x'>
          <div className='mg_0_16x'>
            <div className='user-detail-item'>
              <Row>
                <Col span={12}>
                  <ItemComponent label={t('userDetail.userName')} value={userDetail?.userName} />
                  <ItemComponent
                    label={t('userDetail.registerDate')}
                    value={userDetail?.registerDate ? ConvertTime(userDetail?.registerDate) : ''}
                  />
                  <ItemComponent
                    label={t('userDetail.numberSensorGroup')}
                    value={userDetail?.numberSensorGroup}
                  />
                  <ItemComponent
                    label={t('userDetail.numberSensor')}
                    value={userDetail?.numberSensor}
                  />
                </Col>
                <Col span={12}>
                  <ItemComponent label={t('userDetail.email')} value={userDetail?.email} />
                  <div className='mb-10x'>
                    {userDetail?.userId && ROLE_CRUD.includes(role) ? (
                      <VButton
                        title={t('userDetail.btnPasswordReset')}
                        onClick={handleOnClickResetPassword}
                        style={styBtnResetPassword}
                      />
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className='user-detail-table mt-16x'>
            <div className='custom-table'>
              <TableComponent columns={columsTableUserAlert} dataSource={dataTableUserAlert} />
            </div>
          </div>
          <div className={`${dataTable.length > 0 ? 'user-detail-sensor-management' : ''} mt-16x`}>
            <div className='mg_0_16x'>
              <div className='d-flex justify-content-flex-end mg-pagination'>
                <div className='sensor-pagination'>
                  <PaginationComponent
                    onChangePageSize={(size) => handleOnChangePageSize(size)}
                    total={dataTable?.length}
                    onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                    pageSize={pageSize}
                    isShowSizeChanger={false}
                    currentpage={current}
                  />
                </div>
              </div>
            </div>
            <div className='custom-table'>
              <TableComponent
                columns={defineColumSensorOfUser(current, pageSize)}
                dataSource={getData(current, pageSize, dataTable)}
              />
            </div>
            <div className='mg_0_16x'>
              <div className='mg-pagination'>
                <PaginationComponent
                  onChangePageSize={(size) => handleOnChangePageSize(size)}
                  total={dataTable?.length}
                  onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                  pageSize={pageSize}
                  isShowSizeChanger={true}
                  currentpage={current}
                />
              </div>
            </div>
          </div>
          <div className='btn-return text-align-center'>
            <ButtonReturnComponent />
          </div>
        </div>
      </div>
      {modal?.isVisible ? (
        <ModalComponent
          isVisible={modal?.isVisible}
          isComponent={modal?.isComponent}
          img={modal?.img}
          content={modal?.content}
          btnSubmit={modal?.btnSubmit}
          btnCancel={modal?.btnCancel}
          onCancelModal={() => setModal({ ...modal, isVisible: false })}
          onSubmitModal={handleOnSubmitModal}
        />
      ) : null}
      {modalEditUser?.isVisible ? (
        <ModalEditUserComponent
          title={t('userDetail.modal.editPassword.title')}
          onCancelModal={() => setModalEditUser({ ...modalEditUser, isVisible: false })}
          onSubmitModal={handleOnSubmitResetPassword}
          initialValue={modalEditUser?.initialValue}
        />
      ) : null}
    </>
  )
}

export default UserDetailComponent
