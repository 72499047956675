import { CommonActionTypes, ICommonState } from 'redux/actions/common/commonTypes'
import { ECommonActions } from 'redux/actions/common/ECommonAction'
import { mainColors } from 'utils/colors'

const initialState: ICommonState = {
  isLoading: false,
  notification: null,
  theme: mainColors,
  success: {
    isVisible: false,
    content: ''
  },
  masterData: {
    email: [],
    group: [],
    sensorType: [],
    sensorParentType: []
  }
}

const commonReducer = (state = initialState, action: CommonActionTypes): ICommonState => {
  switch (action.type) {
    case ECommonActions.SET_THEME:
      return {
        ...state,
        theme: action.theme
      }
    case ECommonActions.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case ECommonActions.SET_NOTIFICATION:
      const noti = { ...action.notification }
      return {
        ...state,
        notification: noti || null,
        isLoading: noti?.isLoading ?? false
      }
    case ECommonActions.SET_SUCCESS:
      const success = { ...action.success }
      return {
        ...state,
        isLoading: false,
        success: success || null
      }
    case ECommonActions.SET_MASTER_DATA:
      const sensorType = action.data?.sensorType

      const listSensorType = filterList(sensorType, '1')
      const newListSensorType = convertToSensorList(listSensorType)
      const listParentType = filterList(sensorType, null)
      const newListParentType = convertToSensorList(listParentType)
      return {
        ...state,
        masterData: {
          ...state.masterData,
          sensorType: newListSensorType,
          sensorParentType: newListParentType,
          email: action?.data?.email
        }
      }
    default:
      return state
  }
}

const filterList = (list: any, value: any) => {
  const listSensorType = list.filter((item: any) => item?.parentSensorTypeId === value)
  return listSensorType
}

const convertToSensorList = (list: any) => {
  const newList: any = []
  list.forEach((item: any) => {
    newList.push({
      id: item.sensorTypeId,
      name: item?.sensorTypeTranslate[1]?.sensorTypeName
        ? item?.sensorTypeTranslate[1]?.sensorTypeName
        : item.sensorTypeKey === 'LoRa'
        ? 'LoRa'
        : 'LTE-M',
      value: item.sensorTypeKey
    })
  })

  return newList
}

export default commonReducer
