import Modal from 'antd/lib/modal/Modal'
import { IconSuccessModal } from 'assets'
import VButton from 'components/button/VButton'
import { useTranslation } from 'react-i18next'
interface IModalSuccess {
  isVisible: boolean
  content?: string | ''
  image?: any
  handleClickCancel?: () => void
  zIndex?: number
}
function ModalCheckSuccessComponent({
  isVisible,
  content,
  image,
  handleClickCancel,
  zIndex
}: IModalSuccess) {
  const { t } = useTranslation()
  return (
    <Modal
      visible={isVisible}
      centered={true}
      footer={null}
      maskClosable={false}
      onCancel={handleClickCancel}
      zIndex={zIndex}>
      <div style={{ padding: '20px 0px' }}>
        <div className='modal-ic-success text-align-center mb-16x'>
          <img src={image ? image : IconSuccessModal} alt='success' style={{ ...styleImg }} />
        </div>
        <div className='modal-success-content text-align-center mb-16x font-w-bold'>{content}</div>
        <div className='text-align-center'>
          <VButton title={t('modal.btnClose')} style={styleBtnCancel} onClick={handleClickCancel} />
        </div>
      </div>
    </Modal>
  )
}
const styleImg = {
  width: 90,
  height: 75
}
const styleBtnCancel = {
  width: 88,
  height: 46
}
export default ModalCheckSuccessComponent
