import i18n from 'configs/i18n'
import { regexPassword, regexUppercaseAndLowercase, regexSpecialCharacters } from 'utils/Functions'
const validResetPassword = {
  password: [
    {
      required: true,
      message: i18n.t('errors.resetPasswordEmpty')
    },
    {
      validator(_: object, value: string) {
        if (value) {
          if ((value.length < 8)
            || !value.match(regexPassword)
            || !value.match(regexUppercaseAndLowercase)
            || !value.match(regexSpecialCharacters)) {
            return Promise.reject(new Error(i18n.t('errors.message.PRE00034')))
          }
          if (value.length > 32) {
            return Promise.reject(new Error('入力長を超えました'))
          }
        }
        return Promise.resolve()
      }
    }
  ],
  confirmPassword: [
    ({ getFieldValue }: any) => ({
      validator(_: object, value: string) {
        if (!value) {
          return Promise.reject(new Error(i18n.t('errors.confirmPasswordEmpty')))
        }
        if (value.length > 32) {
          return Promise.reject(new Error('入力長を超えました'))
        }
        if (getFieldValue('password') !== value) {
          return Promise.reject(new Error(i18n.t('errors.message.FRE00005')))
        }
        return Promise.resolve()
      }
    })
  ]
}
export { validResetPassword }
