import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { DELAY } from 'interfaces/enums/EDelay'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, call, delay, put, takeLatest } from 'redux-saga/effects'
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { EOrderAction } from 'redux/actions/order/EOrderAction'
import {
  IGetListOrderAction,
  ICreateOrderAction,
  IOrderDetailAction,
  IDeleteOrderAction,
  IUpdateOrderAction
} from 'redux/actions/order/orderTypes'
import { orderApi } from 'services/api/order/orderAPI'

function* getListOrderSaga(action: IGetListOrderAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(orderApi.getListOrder, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* deleteOrderSaga(action: IDeleteOrderAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(orderApi.deleteOrder, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}

function* createOrderSaga(action: ICreateOrderAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(orderApi.createOrder, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

function* orderDetail(action: IOrderDetailAction) {
  let err: any = null
  yield put(SetLoadingAction(true))
  try {
    const response: AxiosResponse<IData<any>> = yield call(orderApi.orderDetail, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    // if (err) {
    //   yield delay(DELAY.DELAYERR)
    //   const noti: INotification = {
    //     title: i18n.t('notification.error'),
    //     message: err?.message,
    //     notiType: err?.errorType,
    //     code: err?.code
    //   }
    //   yield put(SetNotificationAction(noti))
    // }
    // yield put(SetLoadingAction(false))
  }
}

function* updateOrderSaga(action: IUpdateOrderAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(orderApi.updateOrder, action.params)
    if (response?.data?.error) {
      err = response?.data?.error
      action.callBack(err)
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

export default function* orderSaga() {
  yield all([
    takeLatest(EOrderAction.LIST_ORDER, getListOrderSaga),
    takeLatest(EOrderAction.DELETE_ORDER, deleteOrderSaga),
    takeLatest(EOrderAction.CREATE_ORDER, createOrderSaga),
    takeLatest(EOrderAction.ORDER_DETAIL, orderDetail),
    takeLatest(EOrderAction.UPDATE_ORDER, updateOrderSaga)
  ])
}
