import { DisplayApi } from 'services/api/display/displayApi'
import {
  ICopyFormula,
  ICreateFormula,
  IDeleteFormula,
  IDisplay,
  IGetDetailFormula,
  IUpdateFormula
} from 'services/params/display/IDisplay'
import {
  ICopyFormulaAction,
  ICreateFormulaAction,
  IDeleteFormulaAction,
  IGetDataDisplayAction,
  ISetFormulaCopy,
  IGetDetailFormulaAction
} from './displayTypes'
import { EDisplayActions } from './EDisplayAction'
import { store } from 'redux/store'
import i18n from 'configs/i18n'
import { INotification } from 'interfaces/INotification'
import { SetLoadingAction, SetNotificationAction } from '../common/commonAction'

export const getDataDisplayAction = (
  isLoading: boolean,
  param: IDisplay,
  callBack: (data: any) => void
): IGetDataDisplayAction => ({
  type: EDisplayActions.DISPLAY_LIST,
  isLoading,
  param,
  callBack
})
export const deleteFormulaAction = (
  isLoading: boolean,
  param: IDeleteFormula,
  callBack: (data: any) => void
): IDeleteFormulaAction => ({
  type: EDisplayActions.DELETE_FORMULA,
  isLoading,
  param,
  callBack
})
export const copyFormulaAction = (
  isLoading: boolean,
  param: ICopyFormula,
  callBack: (data: any) => void
): ICopyFormulaAction => ({
  type: EDisplayActions.COPY_FORMULA,
  isLoading,
  param,
  callBack
})
export const getDetailFormula = (
  isLoading: boolean,
  param: IGetDetailFormula,
  callBack: (data: any) => void
): IGetDetailFormulaAction => ({
  type: EDisplayActions.GET_DETAIL_FORMULA,
  isLoading,
  param,
  callBack
})
export const createFormulaAction = (
  isLoading: boolean,
  param: ICreateFormula,
  callBack: (data: any) => void
): ICreateFormulaAction => ({
  type: EDisplayActions.CREATE_FORMULA,
  isLoading,
  param,
  callBack
})

export const updateFormulaAction = async (param: IUpdateFormula) => {
  let err: any = null
  try {
    const response: any = await DisplayApi.updateFormula(param)
    if (response?.result?.Error) {
      err = response?.result?.Error
    } else {
      return response
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      store.dispatch(SetNotificationAction(noti))
      store.dispatch(SetLoadingAction(false))
    }
  }
}

export const setFormulaCopyAction = (data: ISetFormulaCopy) => ({
  type: EDisplayActions.UPDATE_COPY_FORMULA,
  data
})

export const cleanFormulaAction = () => ({
  type: EDisplayActions.CLEAN_FORMULA
})
