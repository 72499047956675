import { ISetCreate } from './createTypes'
import { ECreateActions } from './ECreateAction'

export const setUserCreateAction = (data: ISetCreate) => ({
  type: ECreateActions.USER_CREATE,
  data
})
export const setAdminCreateAction = (data: ISetCreate) => ({
  type: ECreateActions.ADMIN_CREATE,
  data
})
