import { EKey } from 'interfaces/enums/EKey'
import { formatFormula } from 'utils/Functions'
import { defaultTypeSensorLora } from '../sensor/defaultValue'

const checkDeleteTv = (
  id: string,
  attributeKey: string,
  listAtt: any,
  listAttAdd: any,
  lteOrLora: string,
  formulaType: any,
  numberAttributeTv: number
) => {
  let arrNameAttribute: any = []
  // remove key delete
  listAtt = listAtt?.filter((item: any) => item?.key !== id)
  // check att use
  listAtt.forEach((item: any) => {
    const conversionFormulaFormat = formatFormula(item?.conversionFormula, listAtt)
    const getAttNameTv = conversionFormulaFormat?.replace(/[^Tv{0-9} ]+/g, ',')
    const arrTv = getAttNameTv?.split(',')
    if (arrTv && arrTv?.includes(attributeKey)) {
      const valueIndex = item?.attributeIndex?.toString()
      const result =
        formulaType === 'copy'
          ? item?.dataKeyPrefix === EKey.keyPrefixTv
            ? item?.attributeKey
            : lteOrLora && lteOrLora !== defaultTypeSensorLora
            ? 'tm'.concat(valueIndex)
            : ' tm(ID*10)+'.concat(valueIndex)
          : lteOrLora && lteOrLora !== defaultTypeSensorLora
          ? 'tm'.concat(valueIndex)
          : ' tm(ID*10)+'.concat(valueIndex)
      arrNameAttribute.push(result)
    }
  })
  listAttAdd.forEach((item: any, index: number) => {
    const conversionFormulaFormat = formatFormula(item?.conversionFormula, listAtt)
    const getAttNameTv = conversionFormulaFormat?.replace(/[^Tv{0-9} ]+/g, ',')
    const arrTv = getAttNameTv?.split(',')
    if (arrTv && arrTv?.includes(attributeKey)) {
      const result = item?.positionID + (numberAttributeTv + index + 1)
      arrNameAttribute.push(result)
    }
  })
  return arrNameAttribute
}
export { checkDeleteTv }
