import { CreateAction, ISetCreate } from 'redux/actions/create/createTypes'
import { ECreateActions } from 'redux/actions/create/ECreateAction'

const initialState: ISetCreate = {
  userCreate: false,
  adminCreate: false
}
const createReducer = (state = initialState, action: CreateAction): ISetCreate => {
  switch (action.type) {
    case ECreateActions.USER_CREATE:
      return {
        ...state,
        userCreate: action?.data?.userCreate
      }
    case ECreateActions.ADMIN_CREATE:
      return {
        ...state,
        adminCreate: action?.data?.adminCreate
      }
    default:
      return state
  }
}

export default createReducer
