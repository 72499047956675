import { Checkbox } from 'antd'
import React from 'react'
import './../../../../../styles/SerialNoManagement.scss'

type Props = {
  data: any[]
  currentChecked: any[]
  onChange: any
  label: string
}

const CheckBoxs = ({ data, currentChecked, onChange, label }: Props) => {
  const isExist = (item: any, array: any[]) => {
    const index = array.findIndex((el) => item === el)
    return index > -1
  }

  return (
    <div className='checkbox-layout'>
      <div className='label'>{label}</div>
      <div className='checkbox-group'>
        {data?.map((el, index) => {
          return (
            <React.Fragment key={el.value}>
              <Checkbox
                checked={isExist(el.value, currentChecked)}
                onChange={() => onChange(el.value)}>
                {el.label}
              </Checkbox>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default CheckBoxs
