import { IGetAttributeParams } from 'services/params/atrtibutes/IGetAttributeParams'
import { getAttributes } from 'services/requests/attributes/atrtibuteParam'
import { configRequest } from '..'

class AttributeApis {
  getAttributes = (params: IGetAttributeParams) => {
    const requests = getAttributes(params)
    return configRequest(requests)
  }
}

export const AttributeApi = new AttributeApis()
