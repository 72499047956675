import { IconDeleteBtn, IconDetailBtn } from 'assets'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import i18n from 'configs/i18n'
import Sortable from '../serialNo/serialNoManagement/components/sortable'

export const defaultColumns = (
  handleOnClickDetail: any,
  handleOnClickDelete: any,
  current: number,
  pageSize: number
) => {
  const columns = [
    {
      title: 'No',
      key: 'no',
      dataIndex: '',
      align: 'left',
      render: (text: any, row: any, index: any) => {
        return <span>{(current - 1) * pageSize + (index + 1)}</span>
      }
    },
    {
      title: '登録日',
      dataIndex: 'createdAt',
      align: 'left'
    },
    {
      title: 'スペース名（顧客名）',
      dataIndex: '',
      render: (_: object, record: { customerName: string }) => {
        return <TooltipComponent title={record.customerName} maxSlice={15} />
      },
      align: 'left',
      width: '200px'
    },
    {
      title: 'オーダ',
      dataIndex: '',
      render: (_: object, record: { orderCode: string }) => {
        return <TooltipComponent title={record.orderCode} maxSlice={20} />
      },
      width: '250px',
      align: 'left'
    },
    {
      title: '納入確定日',
      dataIndex: 'dateDelivery',
      align: 'left'
    },
    {
      title: '納入場所',
      dataIndex: '',
      render: (_: object, record: { address: string }) => {
        return <TooltipComponent title={record?.address} maxSlice={15} />
      },
      align: 'left'
    },
    {
      title: i18n.t('customerScreen.edit'),
      dataIndex: '',
      align: 'center',
      width: 60,
      render: (_: object, record: any) => {
        return (
          <div>
            <img
              src={IconDetailBtn}
              alt='edit'
              onClick={() => handleOnClickDetail(record)}
              className='w42x-h28x pointer'
            />
          </div>
        )
      }
    },
    {
      title: i18n.t('customerScreen.delete'),
      dataIndex: '',
      width: 60,
      align: 'center',
      render: (_: object, record: any) => {
        return (
          <div>
            <img
              onClick={() => handleOnClickDelete(record)}
              src={IconDeleteBtn}
              alt='delete'
              className='w42x-h28x pointer'
            />
          </div>
        )
      }
    }
  ]
  return columns
}

export const unshipOrderDefaultColumns = (handleOnClickDetail: any) => {
  const unshipColumns = [
    {
      title: '製造番号',
      dataIndex: 'serialNo',
      align: 'left'
    },
    {
      title: 'デバイスID',
      dataIndex: 'deviceId',
      align: 'left'
    },
    {
      title: '型式',
      dataIndex: 'type',
      align: 'left'
    },
    {
      title: '通信種別',
      dataIndex: 'communicationType',
      align: 'left'
    },
    {
      title: '機器種別',
      dataIndex: 'deviceType',
      align: 'left'
    },
    {
      title: '疎通確認',
      dataIndex: 'communicationConfirm',
      align: 'left',
      width: '150px'
    },
    {
      title: i18n.t('customerScreen.edit'),
      dataIndex: '',
      align: 'center',
      width: '75px',
      render: (_: object, record: any) => {
        return (
          <div>
            <img
              src={IconDetailBtn}
              alt='edit'
              onClick={() => handleOnClickDetail(record)}
              className='w42x-h28x pointer'
            />
          </div>
        )
      }
    }
  ]
  return unshipColumns
}

export const orderDetailColumns = (handleOnClickDetail: any, callbackOnSort: (order: 'ASC' | 'DESC') => void) => {
  const unshipColumns = [
    {
      title: <Sortable title='製造番号' onSort={(method) => {
        if (callbackOnSort) callbackOnSort(method);
      }}/>,
      dataIndex: 'serialNoNumber',
      align: 'left'
    },
    {
      title: 'デバイスID',
      dataIndex: 'deviceId',
      align: 'left'
    },
    {
      title: '型式',
      dataIndex: 'model',
      align: 'left'
    },
    {
      title: '通信種別',
      dataIndex: 'transmissionLineType',
      align: 'left'
    },
    {
      title: '機器種別',
      dataIndex: 'sensorType',
      align: 'left'
    },
    {
      title: '製造工場',
      dataIndex: 'productSite',
      align: 'left'
    },
    {
      title: '疎通確認',
      dataIndex: 'shipmentStatus',
      align: 'left'
    },
    {
      title: i18n.t('customerScreen.edit'),
      dataIndex: '',
      align: 'center',
      width: '80px',
      render: (_: object, record: any) => {
        return (
          <div>
            <img
              src={IconDetailBtn}
              alt='edit'
              onClick={() => handleOnClickDetail(record)}
              className='w42x-h28x pointer'
            />
          </div>
        )
      }
    }
  ]
  return unshipColumns
}
