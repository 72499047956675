import {
  IKatashiKiParamsGetList,
  IKatashikiParamUpdateItem,
  IKatashikiParamCreateItem
} from 'services/params/katashiki/IKatashikiParams'
import {
  KatashikiGetKeyword,
  KatashikiRemoveItem,
  KatashikiGetSensorType,
  KatashikiSearch,
  KatashikiUpdateItem,
  KatashikiCreateItem
} from 'services/requests/katashiki/katashikiRequest'
import { configRequest } from '..'

class KatashikiAPIs {
  katashikiSearch = (param: IKatashiKiParamsGetList) => {
    const requests = KatashikiSearch(param)
    return configRequest(requests)
  }
  katashikiGetKeyword = () => {
    const requests = KatashikiGetKeyword()
    return configRequest(requests)
  }
  katashikiGetSensorType = () => {
    const requests = KatashikiGetSensorType()
    return configRequest(requests)
  }
  KatashikiRemoveItem = (param: number) => {
    const requests = KatashikiRemoveItem(param)
    return configRequest(requests)
  }
  KatashikiUpdateItem = (param: IKatashikiParamUpdateItem) => {
    const requests = KatashikiUpdateItem(param)
    return configRequest(requests)
  }
  KatashikiCreateItem = (param: IKatashikiParamCreateItem) => {
    const requests = KatashikiCreateItem(param)
    return configRequest(requests)
  }
}

export const KatashikiAPI = new KatashikiAPIs()
