import { AxiosResponse } from 'axios'
import { IData } from 'interfaces/IData'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { SetLoadingAction, setMasterData } from 'redux/actions/common/commonAction'
import { IGetMasterDataAction, IUploadAction } from 'redux/actions/common/commonTypes'
import { ECommonActions } from 'redux/actions/common/ECommonAction'
import { SensorApi } from 'services/api/sensor/sensorApi'

function* uploadSaga(action: IUploadAction) {
  try {
  } catch (error) {
    /* const noti: INotification = {
      notiType: error?.errorType,
      title: i18n.t('notification.error'),
      message: error?.message[0]
    }
    yield put(SetNotificationAction(noti)) */
  } finally {
    yield put(SetLoadingAction(false))
  }
}

function* getMasterData(action: IGetMasterDataAction) {
  try {
    const response: AxiosResponse<IData<any>> = yield call(SensorApi.getListTypeSensorSensor)
    const data = response?.data?.result
    if (data) {
      yield put(setMasterData(data))
    }
  } catch (error) {
  } finally {
    // yield put(SetLoadingAction(false))
  }
}

export default function* commonSaga() {
  yield all([
    takeLatest(ECommonActions.UPLOAD, uploadSaga),
    takeLatest(ECommonActions.GET_MASTER_DATA, getMasterData)
  ])
}
