import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { DELAY } from 'interfaces/enums/EDelay'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, call, takeLatest, put, delay } from 'redux-saga/effects'
import { SetAttributesAction } from 'redux/actions/attributes/attributeAction'
import { IGetAttrAction } from 'redux/actions/attributes/attributeTypes'
import { EAttributeTypes } from 'redux/actions/attributes/EAttributeAction'
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { AttributeApi } from 'services/api/attributes/attributesApi'
import { sortListAttByAttributeIndex } from 'utils/Functions'
import { dataKeyPrefixTv } from 'views/pages/multiSettings/defaultValue'

function* getAttributes(action: IGetAttrAction) {
  let err: any = null
  yield put(SetLoadingAction(true))
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      AttributeApi.getAttributes,
      action.params
    )
    const result = response?.data?.result
    if (result) {
      // get list att tm and sort
      let listAttTm = result?.filter((item: any) => item?.dataKeyPrefix !== dataKeyPrefixTv)
      listAttTm = sortListAttByAttributeIndex(listAttTm)
      // get list att tv and sort
      let listAttTv = result?.filter((item: any) => item?.dataKeyPrefix === dataKeyPrefixTv)
      listAttTv = sortListAttByAttributeIndex(listAttTv)
      // list att sort
      const arrResult = listAttTm?.concat(listAttTv)
      yield put(SetAttributesAction(arrResult, action.params.typeSensor))
      yield put(SetLoadingAction(false))
    } else {
      err = response?.data?.error
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

export default function* attributesSaga() {
  yield all([takeLatest(EAttributeTypes.GET_ATTRIBUTES, getAttributes)])
}
