import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { ErrorCodes, ERROR_TYPE } from 'interfaces/enums/ErrorTypes'
import { IData } from 'interfaces/IData'
import { IError } from 'interfaces/IError'
import { checkError } from './Functions'

function checkStatus<T>(response: AxiosResponse<IData<T>>) {
  if (response.status >= 200 && response.status < 300) {
    return response.data.result
  }

  throw Object.assign(checkError(response.status, response.statusText))
}

const checkErrorMessage = (errorData: IError): string => {
  const errorCode = errorData.data
  const code = errorCode[Object.keys(errorCode)[0]][0]
  switch (code) {
    case ErrorCodes.FRE00005:
    case ErrorCodes.MSE0002:
      return i18n.t('errors.message.FRE00005')
    default:
      return i18n.t('errors.wrong')
  }
}

const errorNoti = <T>(response: AxiosResponse<IData<T>>) => {
  const type = ERROR_TYPE.ERROR
  const message = checkErrorMessage(response.data.error)
  return {
    notiType: type,
    title: i18n.t('notification.error'),
    message: message
  }
}

export { checkStatus, checkErrorMessage, errorNoti }
