import { Button } from 'antd'
import 'styles/ButtonFooter.scss'

interface IButtonFooter {
  text: string
  classSty: string
  onClickButton: () => void
  disabled?: boolean
}

function ButtonFooterComponent({ text, classSty, onClickButton, disabled }: IButtonFooter) {
  return (
    <div className='btn-footer'>
      <Button
        disabled={disabled}
        type='text'
        className={`btn-style ${classSty}`}
        onClick={onClickButton}>
        {text}
      </Button>
    </div>
  )
}
export default ButtonFooterComponent
