const arrSettingTarget = [
  { id: '1', name: '水田' },
  { id: '2', name: '気象' },
  { id: '3', name: '畑' }
]
const defaultShowBlank = '-'
const defaultShowBlankFormula = '---'
const defaultLteOrLora = [
  {
    id: 'lte',
    name: 'LTE-M'
  },
  {
    id: 'lora',
    name: 'LoRa'
  }
]
const dataListSensor = [
  {
    id: '1',
    name: 'センサ１'
  },
  {
    id: '2',
    name: 'センサ2'
  },
  {
    id: '3',
    name: 'センサ3'
  }
]
const dataEmail = [
  {
    id: '1',
    name: 'test@gmail.com'
  },
  {
    id: '2',
    name: 'test2@gmail.com'
  },
  {
    id: '3',
    name: 'test3@gmail.com'
  }
]
const defaultAttReserve = '-'
export {
  dataEmail,
  arrSettingTarget,
  defaultShowBlank,
  defaultLteOrLora,
  defaultShowBlankFormula,
  dataListSensor,
  defaultAttReserve
}
