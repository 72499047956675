export enum Methods {
  LOGIN = 'auth.admin_login',
  VERIFY_ADMIN = 'admin.user.verify_admin_account',
  CREATE_USER = 'admin.user.create',
  FORGOT_PASSWORD = 'admin.user.reset_password',
  RESET_PASSWORD = 'admin.user.verify_reset_password',
  CHECK_TOKEN_EXIST = 'admin.user.check_token_exist',
  GET_LIST_USER = 'admin.user.get_list',
  DELETE_USER = 'admin.user.delete',
  USER_DETAIL = 'admin.user.detail',
  USER_EDIT = 'admin.user.edit',
  GET_LIST_EMAIL_ALERT = 'admin.user.get_list_email_alert',
  UPDATE_EMAIL_ALERT = 'admin.user.update_email_alert',
  GET_LIST_SENSOR_BY_USER = 'admin.user.get_list_sensor',
  GET_LIST_ADMIN = 'admin.user.get_list_admin',
  // sensor
  GET_LIST_SENSOR = 'admin.user.get_list_sensor',
  SENSOR_DETAIL = 'admin.sensor.show',
  GET_LIST_USER_BY_SENSOR = 'admin.user.by_sensor',
  GET_LIST_EVENT_BY_SENSOR = 'admin.sensor.get_list_event',
  ADMIN_SENSOR_EDIT = 'admin.sensor.edit',
  SEARCH_DEVICE_ID_SENSOR = 'admin.sensor.search_device_id',
  SEARCH_SENSOR = 'admin.user.get_list_sensor',
  GET_LIST_GROUP = 'admin.group.get_list',
  SENSOR_TYPE = 'admin.master_data',
  GEN_SECURITY = 'admin.sensor.generator_key',
  CHECK_BY_DEVICE_ID = 'admin.formula.check_formula_by_device_id',
  // dashboard
  DASHBOARD = 'admin.dashboard',
  UPDATE_USER_STATUS = 'admin.user.edit',
  // group
  GROUP_DETAIL = 'admin.group.detail',
  GET_SENSOR_BY_GROUP = 'admin.group.get_list_sensor_by_group',
  // display
  DISPLAY = 'admin.formula.get_list',
  DELETE_FORMULA = 'admin.formula.delete',
  COPY_FORMULA = 'admin.formula.detail',
  CREATE_FORMULA = 'admin.formula.create',
  GET_DETAIL_FORMULA = 'admin.formula.detail',
  UPDATE_FORMULA = 'admin.formula.update',
  // notification
  GET_LIST_ALERT_SYSTEM = 'admin.alert.get_list',
  CREATE_ALERT_SYSTEM = 'admin.alert.create',
  UPDATE_ALERT_SYSTEM = 'admin.alert.update',
  DETAIL_ALERT_SYSTEM = 'admin.alert.detail',
  // attributes
  GET_ATTRIBUTES = 'admin.attribute.get_list_by_type',
  // qrcode
  CREATE_QRCODE = 'admin.qr-code.create',
  EDIT_QRCODE = 'admin.qr-code.update',
  GET_LIST_QR = 'admin.qr-code.get_list',
  GET_DETAIL_QR = 'admin.qr-code.detail',
  // customer
  GET_CUSTOMER_MASTER_DATA = 'admin.customer.get_data_master',
  ADD_CUSTOMER_MASTER_DATA = 'admin.customer.add_data_master',
  CREATE_CUSTOMER = 'admin.customer.create',
  GENERATE_CUSTOMER_KEY = 'admin.customer.generator',
  LIST_CUSTOMER = 'admin.customer.get_list',
  DETAIL_CUSTOMER = 'admin.customer.detail',
  EDIT_CUSTOMER = 'admin.customer.update',
  DELETE_CUSTOMER = 'admin.customer.delete',
  RESEND_EMAIL = 'admin.customer.resend_mail',
  DOWNLOAD_CSV = 'admin.customer.download',

  // master data
  DELETE_MASTER_SALES = 'admin.master-sales.delete',
  DELETE_MASTER_DEALER = 'admin.master-dealer.delete',

  // Katashiki
  KATASHIKI_SEARCH = 'admin.model-registration.get_list',
  KATASHIKI_GET_KEYWORD = 'admin.model-registration.list_model',
  KATASHIKI_REMOVE_ITEM = 'admin.model-registration.delete',
  KATASHIKI_UPDATE_ITEM = 'admin.model-registration.update',
  KATASHIKI_GET_SENSOR_TYPE = 'admin.model-registration.list_type_sensor',
  KATASHIKI_CREATE = 'admin.model-registration.create',

  //serial-no
  GET_LIST_SERIAL_NO = 'admin.serial-no.get_list',
  LIST_KATASHIKI = 'admin.model-registration.list_model',
  SERIAL_NO_CREATE = 'admin.serial-no.create',
  LIST_SENSOR = 'admin.serial-no.list_type_sensor',
  GET_SERIAL_NO = 'admin.serial-no.get_serial_no_number',
  SERIAL_NO_DETAIL = 'admin.serial-no.detail',
  BULK_SERIAL_NO_DETAIL = 'admin.serial-no.detail_series',
  DELETE_SERIAL_NO = 'admin.serial-no.delete',
  CONFIRM_STATUS = 'admin.serial-no.confirm',
  UPDATE_SERIAL_NO = 'admin.serial-no.update',
  UPDATE_BULK_SERIAL_NO = 'admin.serial-no.update_series',
  DOWNLOAD_JSON = 'admin.sensor.download_json',
  SERIAL_NO_FOR_ORDER = 'admin.serial-no.get_list_for_order',
  IMPORT_CSV_FILE = 'admin.serial-no.import',
  //order
  CREATE_ORDER = 'admin.order.create',
  LIST_ORDER = 'admin.order.get_list',
  ORDER_DETAIL = 'admin.order.detail',
  DELETE_ORDER = 'admin.order.delete',
  UPDATE_ORDER = 'admin.order.update',

  // audit log
  GET_AUDIT_LOG_LATEST = 'admin.audit-log.get_latest',

  //change password
  CHANGE_PASSWORD = 'admin.user.change_password'
}
