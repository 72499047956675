import { IRequest } from 'interfaces/IRequest'
import { ILoginParams } from 'services/params/ILoginParams'
import { Methods } from 'services/api/EMethod'

const LoginRequest = (loginParams: ILoginParams): IRequest<ILoginParams> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.LOGIN,
    params: loginParams
  }
}

export default LoginRequest
