import { Col, Row } from 'antd'
import VButton from 'components/button/VButton'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import { showTotalRecord } from 'utils/Functions'
import { useDispatch } from 'react-redux'
import { SuccessAction, SetLoadingAction } from 'redux/actions/common/commonAction'
import TableComponent from 'components/common/table/TableComponent'
import { defaultShowBlank } from '../../../sensor/defaultValue'
import { IconDeleteModal } from 'assets'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import 'styles/serialNo.scss'
import { ADMIN_ROUTE, routeSerial } from 'views/routes/routes'
import ModalComponent from 'components/modal/ModalComponent'
import {
  deleteSerialNoAction,
  getBulkSerialNoDetailAction
} from 'redux/actions/serialNo/serialNoAction'
import { defaultColumns } from './defaultColumn'
import moment from 'moment'
import ModalEnvironment from 'components/modal/ModalEnvironment'
import NotFoundComponent from 'views/pages/notFound/NotFoundComponent'

const getData = (current: number, pageSize: number, data: any[]) => {
  return data.slice((current - 1) * pageSize, current * pageSize)
}

function BulkSerialDetail() {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const parentId = useParams<any>()
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [dataDetail, setDataDetail] = useState<any>()
  const [totalSensorRegis, setTotalSensorRegis] = useState<any>()
  const columns = defaultColumns(
    (record: any) => handleOnClickDetail(record),
    (record: any) => handleOnClickDelete(record),
    current,
    pageSize
  )
  const [deleteId, setDeleteId] = useState<any>()
  const [dataTable, setDataTable] = useState<any[]>([])
  const [checkData, setCheckData] = useState<any>('check')
  // success pop up
  const showSuccess = (text: string, href?: any) => {
    // hide modal confirm
    setModal({
      ...modal,
      isVisible: false
    })
    // show success
    const stateSuccess = {
      isVisible: true,
      content: text,
      isRedirect: true,
      href: href
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }
  useEffect(() => {
    handleGetSerialDetail()
    // eslint-disable-next-line
  }, [])
  const [modal, setModal] = useState({
    isVisible: false,
    type: '',
    isComponent: false,
    img: '',
    content: '',
    component: <></>,
    btnSubmit: t('modal.btnSubmit'),
    btnCancel: t('modal.btnCancel'),
    hideBtnFooter: false
  })
  const [modalPrevent, setModalPrevent] = useState({
    isVisible: false,
    btnClose: '',
    content: ''
  })

  // convert data table
  const convertData = (data: any) => {
    data = data.map((item: any, index: number) => {
      return {
        id: item?.id,
        key: item?.id,
        orderId: item?.orderId,
        customerName: item?.customer?.customerName,
        serialNoNumber: item?.serialNoNumber,
        deviceId: item?.deviceId ?? defaultShowBlank,
        securityKey: item?.securityKey ?? defaultShowBlank,
        IMEINumber: item?.IMEINumber ?? defaultShowBlank,
        sensorType: item?.sensorType?.sensorTypeKey,
        sensorTypeId: item?.sensorTypeId,
        sensorTypeTranslate: item?.sensorTypeTranslate?.sensorTypeName,
        katashiki: item?.modelRegistration?.model
      }
    })
    return data
  }
  const handleGetSerialDetail = () => {
    const param = {
      serialNoParent: Number(parentId?.id)
    }
    dispatch(
      getBulkSerialNoDetailAction(
        { ...param },
        async (data) => {
          if (data && data?.result?.data) {
            dispatch(SetLoadingAction(false))
            const resultData = convertData(data?.result?.data)
            const recordData = await data?.result?.data[0]
            const totalSensor = await data?.result?.total
            setDataTable(resultData)
            setDataDetail(recordData)
            setTotalSensorRegis(totalSensor)
            setCheckData('dataExist')
          } else {
            dispatch(SetLoadingAction(false))
            setCheckData('noData')
            setDataTable([])
          }
        },
        true
      )
    )
  }

  // handle change page
  const handleOnChangePageSize = (size: number) => {
    const totalPageChange = Math.ceil(dataTable?.length / size)
    if (current > totalPageChange) {
      setCurrent(totalPageChange)
    }
    setPageSize(size)
  }
  const handleOnChangePage = (pageChange: number) => {
    setCurrent(pageChange)
  }
  // detail individual
  const handleOnClickDetail = (record: any) => {
    const serialId = record?.id
    history.push(
      ADMIN_ROUTE.concat(routeSerial.INDIVIDUAL_DETAIL.replace(':id', serialId.toString()))
    )
  }
  // edit bulk serial no
  const onClickBtnEdit = () => {
    const serialParent = parentId?.id
    history.push(ADMIN_ROUTE.concat(routeSerial.BULK_EDIT.replace(':id', serialParent.toString())))
  }
  // handle delete serial no
  const handleOnClickDelete = (record: any) => {
    setDeleteId(record?.id)
    setModal({
      ...modal,
      isVisible: true,
      type: 'delete-serial',
      isComponent: false,
      img: IconDeleteModal,
      btnSubmit: t('modal.btnDelete'),
      content: t('serialNoScreen.bulkDetail.deleteQrContent'),
      hideBtnFooter: false
    })
  }
  const handleOnClickDeleteParent = () => {
    const serialParent = Number(parentId?.id)
    setDeleteId(serialParent)
    setModal({
      ...modal,
      isVisible: true,
      type: 'delete-parent',
      isComponent: false,
      img: IconDeleteModal,
      btnSubmit: t('modal.btnDelete'),
      content: t('serialNoScreen.bulkDetail.deleteQrParentContent'),
      hideBtnFooter: false
    })
  }

  const deleteSerial = async () => {
    const params: any = {
      id: deleteId
    }
    dispatch(
      deleteSerialNoAction(
        { ...params },
        async (data) => {
          if (data && data?.result?.deletedAt) {
            dispatch(SetLoadingAction(false))
            setModal({ ...modal, isVisible: false })
            const dataSource = [...dataTable]
            const listOrder = dataSource?.filter((item) => parseInt(item.key, 10) !== deleteId)
            setDataTable(listOrder)
            const totalPageChange = Math.ceil(listOrder?.length / pageSize)
            if (totalPageChange === current - 1) {
              setCurrent(totalPageChange)
            }
            if (listOrder.length === 0) {
              setCheckData('dataExist')
              showSuccess(
                t('serialNoScreen.bulkDetail.successDelete'),
                ADMIN_ROUTE.concat(routeSerial.LIST_SERIAL)
              )
            } else {
              showSuccess(t('serialNoScreen.bulkDetail.successDelete'))
              handleGetSerialDetail()
            }
          } else {
            setModalPrevent({
              ...modal,
              isVisible: true,
              content: t('serialNoScreen.bulkDetail.preventDeleteQrContent'),
              btnClose: t('serialNoScreen.bulkDetail.btnClose')
            })
            setModal({ ...modal, isVisible: false })
            handleGetSerialDetail()
          }
        },
        true
      )
    )
  }
  const deleteParentSerial = async () => {
    const params = {
      serialNoParent: deleteId
    }
    dispatch(
      deleteSerialNoAction(
        { ...params },
        (data) => {
          if (data && data?.result?.Error) {
            setModalPrevent({
              ...modal,
              isVisible: true,
              content: t('serialNoScreen.bulkDetail.preventDeleteQrContent'),
              btnClose: t('serialNoScreen.bulkDetail.btnClose')
            })
            setModal({ ...modal, isVisible: false })
            handleGetSerialDetail()
          } else {
            dispatch(SetLoadingAction(false))
            setModal({ ...modal, isVisible: false })
            showSuccess(
              t('serialNoScreen.bulkDetail.successDeleteParent'),
              ADMIN_ROUTE.concat(routeSerial.LIST_SERIAL)
            )
          }
        },
        true
      )
    )
  }
  // submit delete
  async function handleOnSubmitModal() {
    switch (modal?.type) {
      case 'delete-serial':
        deleteSerial()
        break
      case 'delete-parent':
        deleteParentSerial()
        break
      default:
        break
    }
  }

  return (
    <>
      {checkData === 'check' ? null : checkData === 'dataExist' ? (
        <div className='bgrffffff'>
          <div className='serial-no-management pd_top_16x'>
            <div className='mg_0_16x'>
              <div className='create-serial'>
                <Row gutter={24}>
                  <Col span={2}>
                    <div className='label-w-bold pd-bot-20'>
                      {t('serialNoScreen.bulkDetail.communicationType')}
                    </div>
                  </Col>
                  <Col span={10}>
                    <div>{dataDetail?.sensorType?.sensorTypeKey}</div>
                  </Col>
                  <Col span={2}>
                    <div className='label-w-bold pd-bot-20'>
                      {t('serialNoScreen.bulkDetail.katashiki')}
                    </div>
                  </Col>
                  <Col span={10}>
                    <div>{dataDetail?.modelRegistration?.model}</div>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={2}>
                    <div className='label-w-bold pd-bot-20'>
                      {t('serialNoScreen.bulkDetail.equipmentType')}
                    </div>
                  </Col>
                  <Col span={10}>
                    <div>{dataDetail?.sensorTypeTranslate?.sensorTypeName}</div>
                  </Col>
                  <Col span={2}>
                    <div className='label-w-bold pd-bot-20'>
                      {t('serialNoScreen.bulkDetail.yearAndMonthPicker')}
                    </div>
                  </Col>
                  <Col span={10}>
                    <div>
                      {dataDetail?.manufacturingDate
                        ? moment(dataDetail?.manufacturingDate).format('YYYY/MM')
                        : ''}
                    </div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={2}>
                    <div className='label-w-bold pd-bot-20'>
                      {t('serialNoScreen.bulkDetail.registrationsNumber')}
                    </div>
                  </Col>
                  <Col span={10}>
                    {totalSensorRegis ? (
                      <>
                        {totalSensorRegis}
                        <span>台</span>
                      </>
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col span={2}>
                    <div className='label-w-bold pd-bot-20'>
                      {t('serialNoScreen.bulkDetail.manufacturingFactory')}
                    </div>
                  </Col>
                  <Col span={10}>{dataDetail?.productSite}</Col>
                </Row>

                <Row gutter={24}>
                  <Col span={2}>
                    <div className='label-w-bold pd-bot-20'>
                      {t('serialNoScreen.bulkDetail.remarks')}
                    </div>
                  </Col>
                  <Col span={22}>{dataDetail?.note}</Col>
                </Row>
              </div>
            </div>
          </div>
          <Row gutter={24} className='registration-result'>
            <Col span={24}>
              <div>{t('serialNoScreen.bulkDetail.registrationResult')}</div>
            </Col>
          </Row>
          <div className='ss-search-email mg_0_16x'>
            <div className='sensor-pagination mb-16x'>
              <div className='d-flex justify-content-flex-end font-w-bold total-record'>
                {showTotalRecord(dataTable?.length)}{' '}
                <PaginationComponent
                  onChangePageSize={(size) => handleOnChangePageSize(size)}
                  total={dataTable?.length}
                  onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                  pageSize={pageSize}
                  isShowSizeChanger={false}
                  currentpage={current}
                />
              </div>
            </div>
            <div className='custom-table'>
              <TableComponent
                columns={columns}
                dataSource={getData(current, pageSize, dataTable)}
              />
            </div>
            <div className='sensor-pagination'>
              <PaginationComponent
                onChangePageSize={(size) => handleOnChangePageSize(size)}
                total={dataTable?.length}
                onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                pageSize={pageSize}
                isShowSizeChanger={true}
                currentpage={current}
              />
            </div>
          </div>
          <div className='view-btn-ft' style={{ paddingBottom: 16 }}>
            <div>
              <VButton
                title={t('serialNoScreen.bulkDetail.edit')}
                style={styleBtnEdit}
                type='text'
                onClick={onClickBtnEdit}
              />
            </div>
            <div>
              <VButton
                title={t('serialNoScreen.bulkDetail.delete')}
                style={styleBtnDelete}
                type='text'
                onClick={handleOnClickDeleteParent}
              />
            </div>
          </div>
        </div>
      ) : (
        <NotFoundComponent />
      )}
      <>
        {modal?.isVisible ? (
          <ModalComponent
            img={modal?.img}
            isVisible={modal?.isVisible}
            content={modal?.content}
            component={modal?.component}
            btnSubmit={modal?.btnSubmit}
            btnCancel={modal?.btnCancel}
            onCancelModal={() => setModal({ ...modal, isVisible: false })}
            onSubmitModal={handleOnSubmitModal}
            hideBtnFooter={modal?.hideBtnFooter}
          />
        ) : null}
        {modalPrevent?.isVisible ? (
          <ModalEnvironment
            isVisible={modalPrevent?.isVisible}
            btnClose={modalPrevent.btnClose}
            content={modalPrevent.content}
            onCloseModal={() => setModalPrevent({ ...modalPrevent, isVisible: false })}
          />
        ) : null}
      </>
    </>
  )
}

const styleBtnEdit = {
  width: 74,
  height: 38,
  color: '#ffffff'
}
const styleBtnDelete = {
  backgroundColor: '#fff',
  color: '#d7000f',
  border: 'solid 1px #d7000f',
  width: 74,
  height: 38,
  marginLeft: 24
}

export default BulkSerialDetail
