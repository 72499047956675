import i18n from 'configs/i18n'
import { EDate } from 'interfaces/enums/EDate'
import moment from 'moment'
import { convertTypeSensor } from '../sensor/convertData'
const diffTimeFunc = (timeEnd: string, timeStart: string) => {
  let timeConvert = ''
  let timePushAt = moment(timeStart).subtract(9, 'hours').format(EDate.DATEJP)
  const diffTime = moment
    .utc(moment(timeEnd, EDate.DATEJP).diff(moment(timePushAt, EDate.DATEJP), 'minutes'))
    .valueOf()
  var hours = Math.floor(diffTime / 60)
  let minutes = diffTime % 60
  if (minutes > 0) {
    timeConvert = hours + i18n.t('hours') + minutes + i18n.t('minutes')
  } else {
    timeConvert = hours + i18n.t('hours')
  }
  return timeConvert
}
const convertDataSensorCommunicaion = (data: any) => {
  const timeCurrent = moment().format(EDate.DATEJP)
  data = data?.slice(0, 10)?.map((item: any, index: number) => {
    return {
      key: item?.sensorAiId,
      no: index + 1,
      deviceID: item?.deviceId,
      sensorName: item?.sensorName,
      sensorType: convertTypeSensor(item?.sensorType?.sensorTypeTranslate),
      lteOrLora: item?.sensorTypeParent?.sensorTypeKey,
      lastDate: item?.pushAtData,
      abnormalOccurrencePast: item?.pushAtData ? diffTimeFunc(timeCurrent, item?.pushAtData) : ''
    }
  })
  return data
}
const convertDataSensorLowBattery = (data: any) => {
  data = data?.slice(0, 10)?.map((item: any, index: number) => {
    return {
      key: item?.sensorAiId,
      no: index + 1,
      deviceID: item?.deviceId,
      sensorName: item?.sensorName,
      sensorType: convertTypeSensor(item?.sensorType?.sensorTypeTranslate),
      lteOrLora: item?.sensorTypeParent?.sensorTypeKey,
      lastDate: item?.pushAtData,
      batteryDroppedDate: item?.updatedAt
    }
  })
  return data
}
const convertDataSensorOutages = (data: any) => {
  data = data?.slice(0, 10)?.map((item: any, index: number) => {
    return {
      key: item?.sensorAiId,
      no: index + 1,
      deviceID: item?.deviceId,
      sensorName: item?.sensorName,
      sensorType: convertTypeSensor(item?.sensorType?.sensorTypeTranslate),
      lteOrLora: item?.sensorTypeParent?.sensorTypeKey,
      dateTimeOfOutages: item?.updatedAt
    }
  })
  return data
}
const convertDataAlertSystem = (data: any) => {
  data = data?.slice(0, 2)?.map((item: any, index: number) => {
    return {
      key: item?.systemAlertId,
      no: index + 1,
      dateTime: item?.timePushNotification,
      subject: item?.title,
      content: item?.content,
      deliveryStatus: item?.statusNotification === 1 ? 'Delivered' : 'UnDelivered'
    }
  })
  return data
}
const convertTimePushAtData = (time: string) => {
  return moment(time).subtract(9, 'hours').format(EDate.DATEJP)
}
const converTotalDashboard = (data: any) => {
  const total = {
    totalUserRegister: data?.result?.totalUser,
    numberUserInc: data?.result?.totalUserInMonth,
    totalSensorRegister: data?.result?.totalSensor,
    numberSensorInc: data?.result?.totalSensorInMonth,
    totalCommunication: data?.result?.totalSensorTransmissionError,
    totalOperation: data?.result?.totalSensorActive,
    totalBattery: data?.result?.totalSensorLowBattery,
    totalStop: data?.result?.totalSensorInactive
  }
  return total
}
export {
  diffTimeFunc,
  convertDataSensorCommunicaion,
  convertDataSensorLowBattery,
  convertDataSensorOutages,
  convertDataAlertSystem,
  convertTimePushAtData,
  converTotalDashboard
}
