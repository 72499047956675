import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { Redirect, useParams } from 'react-router'
import 'styles/CalculatorSheet.scss'
import { useHistory } from 'react-router-dom'
import CalculatorComponent from './CalculatorComponent'
import ModalCheckSuccessComponent from 'components/modal/ModalCheckSuccessComponent'
import { INotification } from 'interfaces/INotification'
import { SetNotificationAction } from 'redux/actions/common/commonAction'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/reducers'
import { setFormulaCopyAction } from 'redux/actions/display/displayAction'
import { ADMIN_ROUTE, routesName } from 'views/routes/routes'
import { dataKeyPrefixTv } from '../multiSettings/defaultValue'

interface IParams {
  id: string
  type: string
}

const CalculatorSheet = () => {
  const dispatch = useDispatch()
  const params = useParams<IParams>()
  const history = useHistory()
  const { t } = useTranslation()
  const [expView, setExpView] = useState<any>('')
  const [expCheck, setExpCheck] = useState<any>('')
  const [expData, setExpData] = useState<any>('')
  const [mode, setMode] = useState<any>('')
  const masterData = useSelector((state: AppState) => state.common.masterData)
  const defaultTypeSensor = masterData.sensorType
  const defaultLteOrLora = masterData.sensorParentType
  const dataSelected: any = history?.location?.state
  const [sensorType, setSensorType] = useState<any>('')
  const [lteOrLora, setLteOrLora] = useState<any>('')
  const formula = useSelector((state: AppState) => state?.formula)
  useEffect(() => {
    if (dataSelected) {
      const sensorType = defaultTypeSensor.find((x) => x.value === dataSelected.settingTarget)
      setSensorType(sensorType?.name)

      const lteOrLora = defaultLteOrLora.find((x) => x.value === dataSelected.lteOrLora)
      setLteOrLora(lteOrLora?.name)
    }
    // eslint-disable-next-line
  }, [masterData])

  // modal confirm
  const handleClickCancel = () => {
    setModalConfirm({ ...modalConfirm, isModalVisible: false })
    history.goBack()
  }
  // state modal delete
  const [modalConfirm, setModalConfirm] = useState({
    isModalVisible: false,
    content: t('modal.contentSaveFormulaSuccess'),
    firstOption: t('modal.firstOption'),
    secondOption: t('modal.secondOption'),
    optionDes: t('modal.optionDes')
  })
  // state confirm pop up
  const handleOnClickConfirm = () => {
    let listAtt: any = []
    let listAttAdd: any = []
    listAtt = formula?.listAttribute
    listAttAdd = formula?.listAttributeAdd
    let lisAttConcat = listAtt.concat(listAttAdd)
    const attFind = lisAttConcat?.find((item: any) => item?.key === params?.id)
    if (attFind?.dataKeyPrefix === dataKeyPrefixTv && !expCheck) {
      // show popup error
      const noti: INotification = {
        title: t('notification.error'),
        message: t('notification.errors.formulaTv')
      }
      dispatch(SetNotificationAction(noti))
    } else {
      // validate bieu thuc
      if (validate(expCheck)) {
        // update list attribute
        const attSelected = listAtt?.find((item: any) => item.key === params.id)
        const updateAtt = {
          ...attSelected,
          conversionFormula: expView,
          conversionFormulaPush: expData,
          mode: mode
        }
        const listAttUpdate = listAtt.map((item: any) =>
          item.key === updateAtt.key ? (item = updateAtt) : item
        )
        // update list tv
        const tvSelected = listAttAdd?.find((item: any) => item.key === params.id)
        const updateTv = {
          ...tvSelected,
          conversionFormula: expView,
          conversionFormulaPush: expData,
          mode: mode
        }
        const listTvUpdate = listAttAdd.map((item: any) =>
          item.key === updateTv.key ? (item = updateTv) : item
        )

        const updateFormula = {
          ...formula,
          listAttribute: listAttUpdate,
          listAttributeAdd: listTvUpdate
        }
        dispatch(setFormulaCopyAction(updateFormula))
        setModalConfirm({
          ...modalConfirm,
          isModalVisible: true
        })
      } else {
        // show popup error
        const noti: INotification = {
          title: t('notification.error'),
          message: t('notification.errors.formulaExp')
        }
        dispatch(SetNotificationAction(noti))
      }
    }
  }

  const validate = (e: string) => {
    if (!dataSelected?.typeValidate && (e === '' || e === '-')) return true
    const arrayStrInvalid = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'PI']

    if (e.indexOf('/0') === -1) {
      // check case loi khi sau/truoc tm la 0-9, pi
      for (var i = 0; i < arrayStrInvalid.length; i++) {
        let tmp1 = 'y' + arrayStrInvalid[i]
        let tmp2 = arrayStrInvalid[i] + 'y'
        if (e.indexOf(tmp1) > -1 || e.indexOf(tmp2) > -1) {
          return false
        }
      }
      let exp = (' ' + e + ' ')
        .replace(/([a-z]{2,})/gi, 'Math.$1')
        .replace(/([^a-z])[a-z]([^a-z])/gi, '$11$2')
        .replace(/([^a-z])[a-z]([^a-z])/gi, '$11$2')
        .replace(/([0])[0-9]/gi, '1')
      // check exp co sqrt
      if (exp.indexOf('sqrt')) exp = exp.replaceAll('sqrt', 'sin')
      if (exp.indexOf('log')) exp = exp.replaceAll('log', 'sin')
      try {
        // eslint-disable-next-line
        return !isNaN(eval(exp))
      } catch (e) {
        return false
      }
    } else return false
  }
  // return last page
  const gotoLastPage = () => {
    history.goBack()
  }
  //
  const getResultCal = (exp: string, expCheck: string, expData: string, mode: string) => {
    setExpView(exp)
    setExpCheck(expCheck)
    setExpData(expData)
    setMode(mode)
  }

  /* note: label text in span tag need to change depend on current page */
  return (
    <div>
      {formula?.id ? (
        <div className='calculator-sheet'>
          <div className='display-table mt-16x'>
            <div className='bgrffffff'>
              <div className='pd_16x_0x'>
                <div className='mg_0_16x'>
                  <div className='page-label'>
                    <div className='title'>
                      {t('displayManagement.calculator.pageLabel')}
                      {params?.type === 'multi' ? (
                        <span>{t('caculation.multiSetting')}</span>
                      ) : (
                        <span>{t('caculation.individualSetting')}</span>
                      )}
                    </div>
                  </div>
                  <div className='page-label mt-16x'>
                    <div className='title'>
                      {t('displayManagement.calculator.typeLabel')}
                      <span>{sensorType}</span>
                    </div>
                  </div>
                  <div className='page-label mt-16x'>
                    <div className='title'>
                      {t('displayManagement.calculator.sensorTypeLabel')}
                      <span>{lteOrLora}</span>
                    </div>
                  </div>
                  <div className='page-label mt-16x'>
                    <div className='title'>
                      {t('displayManagement.calculator.type2Label')}
                      <span>{dataSelected?.nameAtt}</span>
                    </div>
                  </div>
                  <div className='mt-16x mb-16x'>
                    <CalculatorComponent
                      getResultCal={(exp, expCheck, expData, mode) =>
                        getResultCal(exp, expCheck, expData, mode)
                      }
                      dataSelected={dataSelected}
                      formula={formula}
                    />
                  </div>
                </div>
                <div className='btn-return text-align-center button-grp'>
                  <button className='submit-btn pointer' onClick={() => handleOnClickConfirm()}>
                    {t('caculation.confirmBtn')}
                  </button>
                  <button
                    className='return-btn pointer'
                    onClick={() => {
                      gotoLastPage()
                    }}>
                    {t('caculation.returnBtn')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {modalConfirm?.isModalVisible ? (
            <ModalCheckSuccessComponent
              isVisible={modalConfirm?.isModalVisible}
              content={modalConfirm?.content}
              handleClickCancel={handleClickCancel}
            />
          ) : null}
        </div>
      ) : (
        <Redirect to={ADMIN_ROUTE.concat(routesName?.DISPLAY_MANAGEMENT)} exact />
      )}
    </div>
  )
}

export default CalculatorSheet
