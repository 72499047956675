const citiesJp = [
  {
    id: '1847947',
    state_id: '30',
    name: '新宮市',
    name_en: 'Shingū',
    latitude: '33.73333',
    longitude: '135.983337'
  },
  {
    id: '1847963',
    state_id: '14',
    name: '厚木市',
    name_en: 'Atsugi',
    latitude: '35.438889',
    longitude: '139.359726'
  },
  {
    id: '1847966',
    state_id: '28',
    name: '明石市',
    name_en: 'Akashi',
    latitude: '34.633331',
    longitude: '134.983337'
  },
  {
    id: '1847968',
    state_id: '14',
    name: '逗子市',
    name_en: 'Zushi',
    latitude: '35.291672',
    longitude: '139.585556'
  },
  {
    id: '1847979',
    state_id: '25',
    name: '大津市',
    name_en: 'Awazuchō',
    latitude: '34.98333',
    longitude: '135.899994'
  },
  {
    id: '1847996',
    state_id: '15',
    name: '長岡市',
    name_en: 'Zaōmachi',
    latitude: '37.450001',
    longitude: '138.850006'
  },
  {
    id: '1848002',
    state_id: '47',
    name: '座間味村',
    name_en: 'Zamami',
    latitude: '26.224171',
    longitude: '127.301109'
  },
  {
    id: '1848004',
    state_id: '14',
    name: '座間市',
    name_en: 'Zama',
    latitude: '35.488892',
    longitude: '139.388611'
  },
  {
    id: '1848015',
    state_id: '15',
    name: '湯沢町',
    name_en: 'Yuzawa',
    latitude: '36.933331',
    longitude: '138.816666'
  },
  {
    id: '1848016',
    state_id: '06',
    name: '遊佐町',
    name_en: 'Yuza',
    latitude: '39',
    longitude: '139.916672'
  },
  {
    id: '1848028',
    state_id: '39',
    name: '梼原町',
    name_en: 'Yusuhara',
    latitude: '33.383331',
    longitude: '132.916672'
  },
  {
    id: '1848040',
    state_id: '13',
    name: '中央区',
    name_en: 'Yūrakuchō',
    latitude: '35.666672',
    longitude: '139.766663'
  },
  {
    id: '1848049',
    state_id: '31',
    name: '北栄町',
    name_en: 'Yura',
    latitude: '35.48333',
    longitude: '133.75'
  },
  {
    id: '1848087',
    state_id: '40',
    name: '行橋市',
    name_en: 'Yukuhashi',
    latitude: '33.728729',
    longitude: '130.983002'
  },
  {
    id: '1848096',
    state_id: '08',
    name: '結城市',
    name_en: 'Yūki',
    latitude: '36.299999',
    longitude: '139.883331'
  },
  {
    id: '1848113',
    state_id: '14',
    name: '湯河原町',
    name_en: 'Yugawara',
    latitude: '35.150002',
    longitude: '139.066666'
  },
  {
    id: '1848119',
    state_id: '20',
    name: '茅野市',
    name_en: 'Yugawa',
    latitude: '36.033329',
    longitude: '138.233337'
  },
  {
    id: '1848131',
    state_id: '30',
    name: '湯浅町',
    name_en: 'Yuasa',
    latitude: '34.033329',
    longitude: '135.183334'
  },
  {
    id: '1848169',
    state_id: '29',
    name: '吉野町',
    name_en: 'Yoshino-chō',
    latitude: '34.396111',
    longitude: '135.857681'
  },
  {
    id: '1848188',
    state_id: '11',
    name: '吉川市',
    name_en: 'Yoshikawa',
    latitude: '35.885281',
    longitude: '139.839447'
  },
  {
    id: '1848193',
    state_id: '15',
    name: '佐渡市',
    name_en: 'Yoshii',
    latitude: '38.049999',
    longitude: '138.399994'
  },
  {
    id: '1848194',
    state_id: '10',
    name: '高崎市',
    name_en: 'Yoshii',
    latitude: '36.25',
    longitude: '138.983337'
  },
  {
    id: '1848200',
    state_id: '18',
    name: '鯖江市',
    name_en: 'Yoshie',
    latitude: '35.987041',
    longitude: '136.172287'
  },
  {
    id: '1848210',
    state_id: '15',
    name: '燕市',
    name_en: 'Yoshida-kasugachō',
    latitude: '37.683331',
    longitude: '138.883331'
  },
  {
    id: '1848211',
    state_id: '20',
    name: '長野市',
    name_en: 'Yoshida',
    latitude: '36.666672',
    longitude: '138.216675'
  },
  {
    id: '1848230',
    state_id: '17',
    name: '輪島市',
    name_en: 'Miimachi-yoromi',
    latitude: '37.316669',
    longitude: '136.966675'
  },
  {
    id: '1848243',
    state_id: '11',
    name: '寄居町',
    name_en: 'Yorii',
    latitude: '36.116669',
    longitude: '139.199997'
  },
  {
    id: '1848254',
    state_id: '11',
    name: 'さいたま市',
    name_en: 'Yono',
    latitude: '35.883331',
    longitude: '139.633331'
  },
  {
    id: '1848266',
    state_id: '47',
    name: '与那国町',
    name_en: 'Yonakuni',
    latitude: '24.466669',
    longitude: '123'
  },
  {
    id: '1848277',
    state_id: '31',
    name: '米子市',
    name_en: 'Yonago',
    latitude: '35.433331',
    longitude: '133.333328'
  },
  {
    id: '1848281',
    state_id: '47',
    name: '与那原町',
    name_en: 'Yonabaru',
    latitude: '26.201389',
    longitude: '127.753891'
  },
  {
    id: '1848305',
    state_id: '13',
    name: '武蔵村山市',
    name_en: 'Yokota',
    latitude: '35.75',
    longitude: '139.383331'
  },
  {
    id: '1848313',
    state_id: '14',
    name: '横須賀市',
    name_en: 'Yokosuka',
    latitude: '35.283611',
    longitude: '139.667221'
  },
  {
    id: '1848354',
    state_id: '14',
    name: '横浜市',
    name_en: 'Yokohama',
    latitude: '35.447781',
    longitude: '139.642502'
  },
  {
    id: '1848364',
    state_id: '34',
    name: '竹原市',
    name_en: 'Yokodaidō',
    latitude: '34.400002',
    longitude: '132.899994'
  },
  {
    id: '1848373',
    state_id: '24',
    name: '四日市市',
    name_en: 'Yokkaichi',
    latitude: '34.966671',
    longitude: '136.616669'
  },
  {
    id: '1848382',
    state_id: '25',
    name: '東近江市',
    name_en: 'Youkaichi',
    latitude: '35.116261',
    longitude: '136.197678'
  },
  {
    id: '1848409',
    state_id: '33',
    name: '倉敷市',
    name_en: 'Yobimatsu',
    latitude: '34.5',
    longitude: '133.766663'
  },
  {
    id: '1848439',
    state_id: '26',
    name: '八幡市',
    name_en: 'Yawata',
    latitude: '34.87009',
    longitude: '135.702698'
  },
  {
    id: '1848440',
    state_id: '19',
    name: '上野原市',
    name_en: 'Yatsuzawa',
    latitude: '35.616669',
    longitude: '139.116669'
  },
  {
    id: '1848445',
    state_id: '43',
    name: '八代市',
    name_en: 'Honmachi',
    latitude: '32.5',
    longitude: '130.600006'
  },
  {
    id: '1848447',
    state_id: '16',
    name: '富山市',
    name_en: 'Yatsuomachi-higashikumisaka',
    latitude: '36.566669',
    longitude: '137.133331'
  },
  {
    id: '1848482',
    state_id: '32',
    name: '安来市',
    name_en: 'Yasugichō',
    latitude: '35.433331',
    longitude: '133.25'
  },
  {
    id: '1848489',
    state_id: '25',
    name: '野洲市',
    name_en: 'Yasu-shi',
    latitude: '35.068008',
    longitude: '136.02327'
  },
  {
    id: '1848498',
    state_id: '28',
    name: '加東市',
    name_en: 'Yashiro',
    latitude: '34.916672',
    longitude: '134.966675'
  },
  {
    id: '1848499',
    state_id: '11',
    name: '八潮市',
    name_en: 'Yashio',
    latitude: '35.822552',
    longitude: '139.83905'
  },
  {
    id: '1848518',
    state_id: '21',
    name: '八百津町',
    name_en: 'Yaotsu',
    latitude: '35.466671',
    longitude: '137.149994'
  },
  {
    id: '1848522',
    state_id: '27',
    name: '八尾市',
    name_en: 'Yao',
    latitude: '34.616669',
    longitude: '135.600006'
  },
  {
    id: '1848528',
    state_id: '24',
    name: '尾鷲市',
    name_en: 'Yanohama',
    latitude: '34.066669',
    longitude: '136.199997'
  },
  {
    id: '1848550',
    state_id: '35',
    name: '柳井市',
    name_en: 'Yanai',
    latitude: '33.966671',
    longitude: '132.116669'
  },
  {
    id: '1848573',
    state_id: '40',
    name: '柳川市',
    name_en: 'Yanagawa',
    latitude: '33.166672',
    longitude: '130.399994'
  },
  {
    id: '1848575',
    state_id: '09',
    name: '足利市',
    name_en: 'Yanadachō',
    latitude: '36.299999',
    longitude: '139.483337'
  },
  {
    id: '1848593',
    state_id: '29',
    name: '大和高田市',
    name_en: 'Yamato-Takada',
    latitude: '34.51667',
    longitude: '135.75'
  },
  {
    id: '1848598',
    state_id: '14',
    name: '大和市',
    name_en: 'Yamatominami',
    latitude: '35.46722',
    longitude: '139.456116'
  },
  {
    id: '1848612',
    state_id: '08',
    name: '古河市',
    name_en: 'Yamata',
    latitude: '36.150002',
    longitude: '139.816666'
  },
  {
    id: '1848633',
    state_id: '28',
    name: '宍粟市',
    name_en: 'Yamazakichō-nakabirose',
    latitude: '35',
    longitude: '134.550003'
  },
  {
    id: '1848667',
    state_id: '14',
    name: '山北町',
    name_en: 'Yamakita',
    latitude: '35.35667',
    longitude: '139.073334'
  },
  {
    id: '1848689',
    state_id: '35',
    name: '山口市',
    name_en: 'Yamaguchi',
    latitude: '34.185829',
    longitude: '131.47139'
  },
  {
    id: '1848705',
    state_id: '43',
    name: '山鹿市',
    name_en: 'Yamaga',
    latitude: '33.01667',
    longitude: '130.683334'
  },
  {
    id: '1848706',
    state_id: '40',
    name: '筑前町',
    name_en: 'Yamae',
    latitude: '33.466671',
    longitude: '130.566666'
  },
  {
    id: '1848739',
    state_id: '40',
    name: '福岡市',
    name_en: 'Yakuin-Horibata',
    latitude: '33.583328',
    longitude: '130.399994'
  },
  {
    id: '1848764',
    state_id: '33',
    name: '矢掛町',
    name_en: 'Yakage',
    latitude: '34.616669',
    longitude: '133.583328'
  },
  {
    id: '1848774',
    state_id: '22',
    name: '焼津市',
    name_en: 'Yaizu',
    latitude: '34.866669',
    longitude: '138.333328'
  },
  {
    id: '1848776',
    state_id: '09',
    name: '矢板市',
    name_en: 'Yaita',
    latitude: '36.799999',
    longitude: '139.933334'
  },
  {
    id: '1848841',
    state_id: '28',
    name: '相生市',
    name_en: 'Yabutani',
    latitude: '34.799999',
    longitude: '134.466675'
  },
  {
    id: '1848848',
    state_id: '14',
    name: '藤沢市',
    name_en: 'Yabuhana',
    latitude: '35.408329',
    longitude: '139.467224'
  },
  {
    id: '1848852',
    state_id: '28',
    name: '養父市',
    name_en: 'Yabu',
    latitude: '35.383331',
    longitude: '134.816666'
  },
  {
    id: '1848899',
    state_id: '22',
    name: '伊豆市',
    name_en: 'Warabo',
    latitude: '34.916672',
    longitude: '139'
  },
  {
    id: '1848903',
    state_id: '11',
    name: '蕨市',
    name_en: 'Warabi',
    latitude: '35.826389',
    longitude: '139.704437'
  },
  {
    id: '1848906',
    state_id: '46',
    name: '奄美市',
    name_en: 'Wano',
    latitude: '28.41667',
    longitude: '129.699997'
  },
  {
    id: '1848919',
    state_id: '28',
    name: '神戸市',
    name_en: 'Wakinohamachō',
    latitude: '34.700001',
    longitude: '135.216675'
  },
  {
    id: '1848933',
    state_id: '36',
    name: '美馬市',
    name_en: 'Wakimachi',
    latitude: '34.066669',
    longitude: '134.149994'
  },
  {
    id: '1848935',
    state_id: '33',
    name: '和気町',
    name_en: 'Wake',
    latitude: '34.799999',
    longitude: '134.133331'
  },
  {
    id: '1848938',
    state_id: '30',
    name: '田辺市',
    name_en: 'Wakayama',
    latitude: '33.914581',
    longitude: '135.511124'
  },
  {
    id: '1848949',
    state_id: '31',
    name: '若桜町',
    name_en: 'Wakasa',
    latitude: '35.333328',
    longitude: '134.399994'
  },
  {
    id: '1848976',
    state_id: '17',
    name: '輪島市',
    name_en: 'Wajima',
    latitude: '37.400002',
    longitude: '136.899994'
  },
  {
    id: '1848988',
    state_id: '46',
    name: '和泊町',
    name_en: 'Wadomari',
    latitude: '27.383329',
    longitude: '128.649994'
  },
  {
    id: '1848991',
    state_id: '28',
    name: '朝来市',
    name_en: 'Wadayama',
    latitude: '35.316669',
    longitude: '134.866669'
  },
  {
    id: '1849053',
    state_id: '09',
    name: '宇都宮市',
    name_en: 'Utsunomiya',
    latitude: '36.56583',
    longitude: '139.883606'
  },
  {
    id: '1849069',
    state_id: '43',
    name: '宇土市',
    name_en: 'Uto',
    latitude: '32.683331',
    longitude: '130.666672'
  },
  {
    id: '1849073',
    state_id: '37',
    name: '丸亀市',
    name_en: 'Utazu',
    latitude: '34.299999',
    longitude: '133.816666'
  },
  {
    id: '1849094',
    state_id: '44',
    name: '臼杵市',
    name_en: 'Usuki',
    latitude: '33.108059',
    longitude: '131.787781'
  },
  {
    id: '1849154',
    state_id: '43',
    name: '天草市',
    name_en: 'Ushibukamachi',
    latitude: '32.190559',
    longitude: '130.022781'
  },
  {
    id: '1849168',
    state_id: '39',
    name: '土佐市',
    name_en: 'Usa',
    latitude: '33.450001',
    longitude: '133.449997'
  },
  {
    id: '1849183',
    state_id: '41',
    name: '嬉野市',
    name_en: 'Ureshinomachi-shimojuku',
    latitude: '33.099998',
    longitude: '129.983337'
  },
  {
    id: '1849186',
    state_id: '12',
    name: '浦安市',
    name_en: 'Urayasu',
    latitude: '35.670559',
    longitude: '139.888611'
  },
  {
    id: '1849237',
    state_id: '16',
    name: '魚津市',
    name_en: 'Uozu',
    latitude: '36.799999',
    longitude: '137.399994'
  },
  {
    id: '1849274',
    state_id: '33',
    name: '玉野市',
    name_en: 'Uno',
    latitude: '34.48333',
    longitude: '133.949997'
  },
  {
    id: '1849299',
    state_id: '40',
    name: '宇美町',
    name_en: 'Umi',
    latitude: '33.566669',
    longitude: '130.5'
  },
  {
    id: '1849344',
    state_id: '42',
    name: '諫早市',
    name_en: 'Uki',
    latitude: '32.799999',
    longitude: '130.083328'
  },
  {
    id: '1849347',
    state_id: '46',
    name: '宇検村',
    name_en: 'Uken',
    latitude: '28.299999',
    longitude: '129.233337'
  },
  {
    id: '1849367',
    state_id: '09',
    name: 'さくら市',
    name_en: 'Ujiie',
    latitude: '36.683331',
    longitude: '139.966675'
  },
  {
    id: '1849372',
    state_id: '26',
    name: '宇治市',
    name_en: 'Uji',
    latitude: '34.883331',
    longitude: '135.800003'
  },
  {
    id: '1849407',
    state_id: '19',
    name: '上野原市',
    name_en: 'Uenohara',
    latitude: '35.616669',
    longitude: '139.116669'
  },
  {
    id: '1849410',
    state_id: '15',
    name: '十日町市',
    name_en: 'Ueno',
    latitude: '37.183331',
    longitude: '138.75'
  },
  {
    id: '1849414',
    state_id: '24',
    name: '伊賀市',
    name_en: 'Ueno-ebisumachi',
    latitude: '34.75',
    longitude: '136.133331'
  },
  {
    id: '1849423',
    state_id: '14',
    name: '鎌倉市',
    name_en: 'Ueki',
    latitude: '35.349998',
    longitude: '139.516663'
  },
  {
    id: '1849424',
    state_id: '43',
    name: '熊本市',
    name_en: 'Uekimachi-mōno',
    latitude: '32.900002',
    longitude: '130.683334'
  },
  {
    id: '1849429',
    state_id: '20',
    name: '上田市',
    name_en: 'Ueda',
    latitude: '36.400002',
    longitude: '138.266663'
  },
  {
    id: '1849498',
    state_id: '35',
    name: '宇部市',
    name_en: 'Ube',
    latitude: '33.943062',
    longitude: '131.251114'
  },
  {
    id: '1849519',
    state_id: '33',
    name: '津山市',
    name_en: 'Tsuyama',
    latitude: '35.049999',
    longitude: '134'
  },
  {
    id: '1849539',
    state_id: '23',
    name: '津島市',
    name_en: 'Tsushima',
    latitude: '35.166672',
    longitude: '136.716675'
  },
  {
    id: '1849561',
    state_id: '44',
    name: '大分市',
    name_en: 'Tsurusaki',
    latitude: '33.240002',
    longitude: '131.684433'
  },
  {
    id: '1849563',
    state_id: '06',
    name: '鶴岡市',
    name_en: 'Tsuruoka',
    latitude: '38.721668',
    longitude: '139.821671'
  },
  {
    id: '1849584',
    state_id: '17',
    name: '白山市',
    name_en: 'Tsurugi-asahimachi',
    latitude: '36.450001',
    longitude: '136.633331'
  },
  {
    id: '1849588',
    state_id: '23',
    name: '碧南市',
    name_en: 'Tsurugasaki',
    latitude: '34.883331',
    longitude: '136.983337'
  },
  {
    id: '1849592',
    state_id: '18',
    name: '敦賀市',
    name_en: 'Tsuruga',
    latitude: '35.64547',
    longitude: '136.055801'
  },
  {
    id: '1849621',
    state_id: '45',
    name: '都農町',
    name_en: 'Tsunō',
    latitude: '32.25',
    longitude: '131.566666'
  },
  {
    id: '1849633',
    state_id: '43',
    name: '津奈木町',
    name_en: 'Tsunagi',
    latitude: '32.23333',
    longitude: '130.449997'
  },
  {
    id: '1849647',
    state_id: '45',
    name: '西都市',
    name_en: 'Tsuma',
    latitude: '32.099998',
    longitude: '131.399994'
  },
  {
    id: '1849661',
    state_id: '44',
    name: '津久見市',
    name_en: 'Tsukumiura',
    latitude: '33.07056',
    longitude: '131.857224'
  },
  {
    id: '1849706',
    state_id: '44',
    name: '玖珠町',
    name_en: 'Tsukawaki',
    latitude: '33.26667',
    longitude: '131.149994'
  },
  {
    id: '1849708',
    state_id: '21',
    name: '岐阜市',
    name_en: 'Tsukasamachi',
    latitude: '35.416672',
    longitude: '136.766663'
  },
  {
    id: '1849713',
    state_id: '28',
    name: '尼崎市',
    name_en: 'Tsukaguchichō',
    latitude: '34.75',
    longitude: '135.416672'
  },
  {
    id: '1849729',
    state_id: '40',
    name: '築上町',
    name_en: 'Tsuiki',
    latitude: '33.67239',
    longitude: '131.037415'
  },
  {
    id: '1849771',
    state_id: '13',
    name: '三宅島',
    name_en: 'Tsubota',
    latitude: '34.049999',
    longitude: '139.533325'
  },
  {
    id: '1849782',
    state_id: '17',
    name: '津幡町',
    name_en: 'Tsubata',
    latitude: '36.666672',
    longitude: '136.733337'
  },
  {
    id: '1849788',
    state_id: '15',
    name: '燕市',
    name_en: 'Tsubame',
    latitude: '37.650002',
    longitude: '138.933334'
  },
  {
    id: '1849796',
    state_id: '24',
    name: '津市',
    name_en: 'Tsu',
    latitude: '34.730282',
    longitude: '136.508606'
  },
  {
    id: '1849798',
    state_id: '06',
    name: '鶴岡市',
    name_en: 'Tozawa',
    latitude: '38.633331',
    longitude: '139.683334'
  },
  {
    id: '1849809',
    state_id: '23',
    name: '豊田市',
    name_en: 'Toyota-shi',
    latitude: '35.141178',
    longitude: '137.301498'
  },
  {
    id: '1849814',
    state_id: '23',
    name: '豊田市',
    name_en: 'Toyota',
    latitude: '35.083328',
    longitude: '137.149994'
  },
  {
    id: '1849817',
    state_id: '20',
    name: '安曇野市',
    name_en: 'Toyoshina',
    latitude: '36.299911',
    longitude: '137.901077'
  },
  {
    id: '1849831',
    state_id: '28',
    name: '豊岡市',
    name_en: 'Toyooka',
    latitude: '35.533329',
    longitude: '134.833328'
  },
  {
    id: '1849837',
    state_id: '27',
    name: '豊中市',
    name_en: 'Toyonaka',
    latitude: '34.78244',
    longitude: '135.469315'
  },
  {
    id: '1849845',
    state_id: '23',
    name: '豊川市',
    name_en: 'Toyokawa',
    latitude: '34.816669',
    longitude: '137.399994'
  },
  {
    id: '1849846',
    state_id: '23',
    name: '豊橋市',
    name_en: 'Toyohashi',
    latitude: '34.76667',
    longitude: '137.383331'
  },
  {
    id: '1849850',
    state_id: '23',
    name: '南知多町',
    name_en: 'Toyohama',
    latitude: '34.700001',
    longitude: '136.933334'
  },
  {
    id: '1849876',
    state_id: '16',
    name: '富山市',
    name_en: 'Toyama',
    latitude: '36.695278',
    longitude: '137.211395'
  },
  {
    id: '1849890',
    state_id: '31',
    name: '三朝町',
    name_en: 'Tottori',
    latitude: '35.367859',
    longitude: '133.850815'
  },
  {
    id: '1849892',
    state_id: '31',
    name: '鳥取市',
    name_en: 'Tottori',
    latitude: '35.5',
    longitude: '134.233337'
  },
  {
    id: '1849897',
    state_id: '14',
    name: '横浜市',
    name_en: 'Totsuka chō',
    latitude: '35.400002',
    longitude: '139.533325'
  },
  {
    id: '1849904',
    state_id: '41',
    name: '鳥栖市',
    name_en: 'Tosu',
    latitude: '33.366669',
    longitude: '130.516663'
  },
  {
    id: '1849977',
    state_id: '23',
    name: '春日井市',
    name_en: 'Toriimatsu',
    latitude: '35.23333',
    longitude: '136.966675'
  },
  {
    id: '1850004',
    state_id: '37',
    name: '土庄町',
    name_en: 'Tonoshō',
    latitude: '34.48333',
    longitude: '134.183334'
  },
  {
    id: '1850034',
    state_id: '27',
    name: '富田林市',
    name_en: 'Tondabayashichō',
    latitude: '34.5',
    longitude: '135.600006'
  },
  {
    id: '1850040',
    state_id: '15',
    name: '糸魚川市',
    name_en: 'Tonami',
    latitude: '37.001282',
    longitude: '137.727203'
  },
  {
    id: '1850091',
    state_id: '10',
    name: '富岡市',
    name_en: 'Tomioka',
    latitude: '36.25',
    longitude: '138.899994'
  },
  {
    id: '1850108',
    state_id: '47',
    name: '豊見城市',
    name_en: 'Tomigusuku',
    latitude: '26.184999',
    longitude: '127.675003'
  },
  {
    id: '1850147',
    state_id: '13',
    name: '新宿区',
    name_en: 'Tokyo',
    latitude: '35.689499',
    longitude: '139.691711'
  },
  {
    id: '1850152',
    state_id: '35',
    name: '周南市',
    name_en: 'Tokuyama',
    latitude: '34.049999',
    longitude: '131.816666'
  },
  {
    id: '1850158',
    state_id: '36',
    name: '徳島市',
    name_en: 'Tokushima',
    latitude: '34.06583',
    longitude: '134.559433'
  },
  {
    id: '1850177',
    state_id: '21',
    name: '海津市',
    name_en: 'Tokuda',
    latitude: '35.23333',
    longitude: '136.583328'
  },
  {
    id: '1850181',
    state_id: '11',
    name: '所沢市',
    name_en: 'Tokorozawa',
    latitude: '35.79916',
    longitude: '139.469025'
  },
  {
    id: '1850185',
    state_id: '23',
    name: '常滑市',
    name_en: 'Tokoname',
    latitude: '34.883331',
    longitude: '136.850006'
  },
  {
    id: '1850207',
    state_id: '21',
    name: '土岐市',
    name_en: 'Toki',
    latitude: '35.349998',
    longitude: '137.183334'
  },
  {
    id: '1850213',
    state_id: '47',
    name: '渡嘉敷村',
    name_en: 'Tokashiki',
    latitude: '26.192499',
    longitude: '127.358612'
  },
  {
    id: '1850217',
    state_id: '15',
    name: '十日町市',
    name_en: 'Tōkamachi',
    latitude: '37.133331',
    longitude: '138.766663'
  },
  {
    id: '1850223',
    state_id: '34',
    name: '三次市',
    name_en: 'Tōkaichi',
    latitude: '34.799999',
    longitude: '132.850006'
  },
  {
    id: '1850233',
    state_id: '43',
    name: '五木村',
    name_en: 'Tōji',
    latitude: '32.400002',
    longitude: '130.833328'
  },
  {
    id: '1850269',
    state_id: '42',
    name: '時津町',
    name_en: 'Togitsu',
    latitude: '32.833328',
    longitude: '129.850006'
  },
  {
    id: '1850302',
    state_id: '45',
    name: '日南市',
    name_en: 'Todaka',
    latitude: '31.6',
    longitude: '131.366669'
  },
  {
    id: '1850307',
    state_id: '15',
    name: '長岡市',
    name_en: 'Tochio-honchō',
    latitude: '37.466671',
    longitude: '139'
  },
  {
    id: '1850311',
    state_id: '09',
    name: '栃木市',
    name_en: 'Tochigi',
    latitude: '36.383331',
    longitude: '139.733337'
  },
  {
    id: '1850314',
    state_id: '41',
    name: '唐津市',
    name_en: 'Tōbō',
    latitude: '33.48333',
    longitude: '129.933334'
  },
  {
    id: '1850345',
    state_id: '24',
    name: '鳥羽市',
    name_en: 'Toba',
    latitude: '34.48333',
    longitude: '136.850006'
  },
  {
    id: '1850396',
    state_id: '29',
    name: '天理市',
    name_en: 'Tenri',
    latitude: '34.583328',
    longitude: '135.833328'
  },
  {
    id: '1850405',
    state_id: '05',
    name: '潟上市',
    name_en: 'Tennō',
    latitude: '39.900002',
    longitude: '139.966675'
  },
  {
    id: '1850418',
    state_id: '40',
    name: '福岡市',
    name_en: 'Tenjin',
    latitude: '33.583328',
    longitude: '130.399994'
  },
  {
    id: '1850420',
    state_id: '37',
    name: '高松市',
    name_en: 'Tenjimmae',
    latitude: '34.333328',
    longitude: '134.050003'
  },
  {
    id: '1850472',
    state_id: '29',
    name: '田原本町',
    name_en: 'Tawaramoto',
    latitude: '34.549999',
    longitude: '135.800003'
  },
  {
    id: '1850498',
    state_id: '20',
    name: '辰野町',
    name_en: 'Tatsuno',
    latitude: '35.984261',
    longitude: '137.997208'
  },
  {
    id: '1850499',
    state_id: '28',
    name: 'たつの市',
    name_en: 'Tatsunochō-tominaga',
    latitude: '34.866669',
    longitude: '134.550003'
  },
  {
    id: '1850523',
    state_id: '12',
    name: '館山市',
    name_en: 'Tateyama',
    latitude: '34.98333',
    longitude: '139.866669'
  },
  {
    id: '1850559',
    state_id: '10',
    name: '館林市',
    name_en: 'Tatebayashi',
    latitude: '36.25',
    longitude: '139.533325'
  },
  {
    id: '1850589',
    state_id: '46',
    name: '垂水市',
    name_en: 'Tarumizu',
    latitude: '31.48333',
    longitude: '130.699997'
  },
  {
    id: '1850600',
    state_id: '21',
    name: '垂井町',
    name_en: 'Tarui',
    latitude: '35.366669',
    longitude: '136.533325'
  },
  {
    id: '1850617',
    state_id: '43',
    name: '多良木町',
    name_en: 'Taragi',
    latitude: '32.26667',
    longitude: '130.933334'
  },
  {
    id: '1850627',
    state_id: '40',
    name: '久留米市',
    name_en: 'Tanushimarumachi-toyoki',
    latitude: '33.349998',
    longitude: '130.683334'
  },
  {
    id: '1850630',
    state_id: '09',
    name: '佐野市',
    name_en: 'Tanuma',
    latitude: '36.366669',
    longitude: '139.583328'
  },
  {
    id: '1850692',
    state_id: '13',
    name: '西東京市',
    name_en: 'Nishi-Tokyo-shi',
    latitude: '35.725262',
    longitude: '139.5383'
  },
  {
    id: '1850693',
    state_id: '13',
    name: '小平市',
    name_en: 'Tanashichō',
    latitude: '35.723888',
    longitude: '139.522217'
  },
  {
    id: '1850707',
    state_id: '26',
    name: '京田辺市',
    name_en: 'Tanabe',
    latitude: '34.816669',
    longitude: '135.766663'
  },
  {
    id: '1850708',
    state_id: '30',
    name: '田辺市',
    name_en: 'Tanabe',
    latitude: '33.73333',
    longitude: '135.366669'
  },
  {
    id: '1850718',
    state_id: '26',
    name: '京丹後市',
    name_en: 'Tamba',
    latitude: '35.633331',
    longitude: '135.066666'
  },
  {
    id: '1850736',
    state_id: '44',
    name: '竹田市',
    name_en: 'Tamarai',
    latitude: '32.950001',
    longitude: '131.383331'
  },
  {
    id: '1850742',
    state_id: '33',
    name: '玉野市',
    name_en: 'Tamano',
    latitude: '34.48333',
    longitude: '133.949997'
  },
  {
    id: '1850745',
    state_id: '43',
    name: '玉名市',
    name_en: 'Tamana',
    latitude: '32.916672',
    longitude: '130.566666'
  },
  {
    id: '1850746',
    state_id: '10',
    name: '玉村町',
    name_en: 'Tamamura',
    latitude: '36.299999',
    longitude: '139.116669'
  },
  {
    id: '1850762',
    state_id: '06',
    name: '小国町',
    name_en: 'Tamagawa',
    latitude: '38.033329',
    longitude: '139.666672'
  },
  {
    id: '1850818',
    state_id: '23',
    name: '武豊町',
    name_en: 'Taketoyo',
    latitude: '34.849998',
    longitude: '136.916672'
  },
  {
    id: '1850822',
    state_id: '47',
    name: '竹富町',
    name_en: 'Taketomi',
    latitude: '24.330669',
    longitude: '124.08506'
  },
  {
    id: '1850834',
    state_id: '41',
    name: '武雄市',
    name_en: 'Takeochō-takeo',
    latitude: '33.200001',
    longitude: '130.016663'
  },
  {
    id: '1850837',
    state_id: '16',
    name: '立山町',
    name_en: 'Takenouchi',
    latitude: '36.700001',
    longitude: '137.300003'
  },
  {
    id: '1850860',
    state_id: '34',
    name: '竹原市',
    name_en: 'Takehara',
    latitude: '34.338329',
    longitude: '132.916672'
  },
  {
    id: '1850872',
    state_id: '18',
    name: '越前市',
    name_en: 'Takefu',
    latitude: '35.903931',
    longitude: '136.16687'
  },
  {
    id: '1850878',
    state_id: '44',
    name: '竹田市',
    name_en: 'Takedamachi',
    latitude: '32.966671',
    longitude: '131.399994'
  },
  {
    id: '1850892',
    state_id: '21',
    name: '高山市',
    name_en: 'Takayama',
    latitude: '36.133331',
    longitude: '137.25'
  },
  {
    id: '1850910',
    state_id: '27',
    name: '高槻市',
    name_en: 'Takatsuki',
    latitude: '34.848331',
    longitude: '135.616776'
  },
  {
    id: '1850916',
    state_id: '32',
    name: '益田市',
    name_en: 'Takatsu',
    latitude: '34.683331',
    longitude: '131.783325'
  },
  {
    id: '1850924',
    state_id: '28',
    name: '神戸市',
    name_en: 'Takatorichō',
    latitude: '34.650002',
    longitude: '135.133331'
  },
  {
    id: '1850940',
    state_id: '23',
    name: '名古屋市',
    name_en: 'Takatachō',
    latitude: '35.133331',
    longitude: '136.933334'
  },
  {
    id: '1850943',
    state_id: '25',
    name: '長浜市',
    name_en: 'Takata',
    latitude: '35.433331',
    longitude: '136.233337'
  },
  {
    id: '1851002',
    state_id: '10',
    name: '高崎市',
    name_en: 'Takasaki',
    latitude: '36.333328',
    longitude: '139.016663'
  },
  {
    id: '1851009',
    state_id: '28',
    name: '高砂市',
    name_en: 'Takasagochō-takasemachi',
    latitude: '34.75',
    longitude: '134.800003'
  },
  {
    id: '1851012',
    state_id: '28',
    name: '宝塚市',
    name_en: 'Takarazuka',
    latitude: '34.799358',
    longitude: '135.356964'
  },
  {
    id: '1851032',
    state_id: '16',
    name: '高岡市',
    name_en: 'Takaoka',
    latitude: '36.75',
    longitude: '137.016663'
  },
  {
    id: '1851068',
    state_id: '45',
    name: '高鍋町',
    name_en: 'Takanabe',
    latitude: '32.133331',
    longitude: '131.5'
  },
  {
    id: '1851079',
    state_id: '43',
    name: '高森町',
    name_en: 'Takamori',
    latitude: '32.816669',
    longitude: '131.116669'
  },
  {
    id: '1851099',
    state_id: '17',
    name: 'かほく市',
    name_en: 'Takamatsu',
    latitude: '36.76667',
    longitude: '136.716675'
  },
  {
    id: '1851100',
    state_id: '37',
    name: '高松市',
    name_en: 'Takamatsu',
    latitude: '34.340279',
    longitude: '134.043335'
  },
  {
    id: '1851114',
    state_id: '22',
    name: '静岡市',
    name_en: 'Takajōmachi',
    latitude: '34.966671',
    longitude: '138.383331'
  },
  {
    id: '1851125',
    state_id: '27',
    name: '高石市',
    name_en: 'Takaishi',
    latitude: '34.51667',
    longitude: '135.433334'
  },
  {
    id: '1851137',
    state_id: '33',
    name: '高梁市',
    name_en: 'Takahashi',
    latitude: '34.783329',
    longitude: '133.616669'
  },
  {
    id: '1851141',
    state_id: '45',
    name: '高原町',
    name_en: 'Takaharu',
    latitude: '31.91667',
    longitude: '131.016663'
  },
  {
    id: '1851155',
    state_id: '23',
    name: '高浜市',
    name_en: 'Takahama',
    latitude: '34.916672',
    longitude: '136.983337'
  },
  {
    id: '1851170',
    state_id: '44',
    name: '豊後高田市',
    name_en: 'Bungo-Takada-shi',
    latitude: '33.556702',
    longitude: '131.445053'
  },
  {
    id: '1851193',
    state_id: '21',
    name: '多治見市',
    name_en: 'Tajimi',
    latitude: '35.316669',
    longitude: '137.133331'
  },
  {
    id: '1851259',
    state_id: '23',
    name: '田原市',
    name_en: 'Tahara',
    latitude: '34.666672',
    longitude: '137.266663'
  },
  {
    id: '1851273',
    state_id: '40',
    name: '田川市',
    name_en: 'Tagawa',
    latitude: '33.633331',
    longitude: '130.800003'
  },
  {
    id: '1851279',
    state_id: '25',
    name: '多賀町',
    name_en: 'Taga',
    latitude: '35.226341',
    longitude: '136.286255'
  },
  {
    id: '1851282',
    state_id: '37',
    name: '多度津町',
    name_en: 'Tadotsu',
    latitude: '34.275002',
    longitude: '133.75'
  },
  {
    id: '1851292',
    state_id: '07',
    name: '只見町',
    name_en: 'Tadami',
    latitude: '37.349998',
    longitude: '139.316666'
  },
  {
    id: '1851307',
    state_id: '13',
    name: '立川市',
    name_en: 'Tachikawa',
    latitude: '35.69278',
    longitude: '139.41806'
  },
  {
    id: '1851319',
    state_id: '40',
    name: '朝倉市',
    name_en: 'Tachiarai',
    latitude: '33.400002',
    longitude: '130.616669'
  },
  {
    id: '1851323',
    state_id: '35',
    name: '平生町',
    name_en: 'Tabuse',
    latitude: '33.950001',
    longitude: '132.066666'
  },
  {
    id: '1851348',
    state_id: '24',
    name: '鈴鹿市',
    name_en: 'Suzuka',
    latitude: '34.883331',
    longitude: '136.583328'
  },
  {
    id: '1851357',
    state_id: '20',
    name: '須坂市',
    name_en: 'Suzaka',
    latitude: '36.650002',
    longitude: '138.316666'
  },
  {
    id: '1851368',
    state_id: '20',
    name: '諏訪市',
    name_en: 'Suwa',
    latitude: '36.037991',
    longitude: '138.113083'
  },
  {
    id: '1851383',
    state_id: '30',
    name: 'すさみ町',
    name_en: 'Susami',
    latitude: '33.549999',
    longitude: '135.5'
  },
  {
    id: '1851390',
    state_id: '39',
    name: '須崎市',
    name_en: 'Susaki',
    latitude: '33.366669',
    longitude: '133.283325'
  },
  {
    id: '1851426',
    state_id: '28',
    name: '洲本市',
    name_en: 'Sumoto',
    latitude: '34.349998',
    longitude: '134.899994'
  },
  {
    id: '1851462',
    state_id: '39',
    name: '宿毛市',
    name_en: 'Sukumo',
    latitude: '32.933331',
    longitude: '132.733337'
  },
  {
    id: '1851483',
    state_id: '27',
    name: '吹田市',
    name_en: 'Suita',
    latitude: '34.761429',
    longitude: '135.515671'
  },
  {
    id: '1851494',
    state_id: '15',
    name: '阿賀野市',
    name_en: 'Suibara',
    latitude: '37.833328',
    longitude: '139.233337'
  },
  {
    id: '1851497',
    state_id: '20',
    name: '上田市',
    name_en: 'Suguri',
    latitude: '36.26667',
    longitude: '138.149994'
  },
  {
    id: '1851504',
    state_id: '11',
    name: '杉戸町',
    name_en: 'Sugito',
    latitude: '36.033329',
    longitude: '139.733337'
  },
  {
    id: '1851542',
    state_id: '46',
    name: '曽於市',
    name_en: 'Sueyoshichō-ninokata',
    latitude: '31.65',
    longitude: '131.016663'
  },
  {
    id: '1851597',
    state_id: '43',
    name: '湯前町',
    name_en: 'Shimosomeda',
    latitude: '32.283329',
    longitude: '130.983337'
  },
  {
    id: '1851604',
    state_id: '11',
    name: '草加市',
    name_en: 'Sōka',
    latitude: '35.820278',
    longitude: '139.804443'
  },
  {
    id: '1851606',
    state_id: '33',
    name: '総社市',
    name_en: 'Sōja',
    latitude: '34.666672',
    longitude: '133.75'
  },
  {
    id: '1851617',
    state_id: '40',
    name: '添田町',
    name_en: 'Soeda',
    latitude: '33.566669',
    longitude: '130.866669'
  },
  {
    id: '1851622',
    state_id: '23',
    name: '稲沢市',
    name_en: 'Sobue',
    latitude: '35.25',
    longitude: '136.716675'
  },
  {
    id: '1851632',
    state_id: '22',
    name: '伊豆市',
    name_en: 'Shuzenji',
    latitude: '34.966671',
    longitude: '138.933334'
  },
  {
    id: '1851711',
    state_id: '11',
    name: '久喜市',
    name_en: 'Shōbu',
    latitude: '36.066669',
    longitude: '139.600006'
  },
  {
    id: '1851713',
    state_id: '34',
    name: '庄原市',
    name_en: 'Shōbara',
    latitude: '34.849998',
    longitude: '133.016663'
  },
  {
    id: '1851717',
    state_id: '22',
    name: '静岡市',
    name_en: 'Shizuoka',
    latitude: '34.97694',
    longitude: '138.383057'
  },
  {
    id: '1851751',
    state_id: '23',
    name: '設楽町',
    name_en: 'Shitara',
    latitude: '35.099998',
    longitude: '137.566666'
  },
  {
    id: '1851813',
    state_id: '15',
    name: '新潟市',
    name_en: 'Shirone',
    latitude: '37.76667',
    longitude: '139.016663'
  },
  {
    id: '1851842',
    state_id: '17',
    name: '白山市',
    name_en: 'Shirayamamachi',
    latitude: '36.433331',
    longitude: '136.633331'
  },
  {
    id: '1851883',
    state_id: '11',
    name: '白岡市',
    name_en: 'Shiraoka',
    latitude: '36.01667',
    longitude: '139.666672'
  },
  {
    id: '1851898',
    state_id: '13',
    name: '練馬区',
    name_en: 'Shirako',
    latitude: '35.76667',
    longitude: '139.633331'
  },
  {
    id: '1851935',
    state_id: '28',
    name: '姫路市',
    name_en: 'Shirahamachō-usazakiminami',
    latitude: '34.783329',
    longitude: '134.716675'
  },
  {
    id: '1851937',
    state_id: '42',
    name: '西海市',
    name_en: 'Shirahama',
    latitude: '33.01667',
    longitude: '129.733337'
  },
  {
    id: '1851951',
    state_id: '09',
    name: '那須塩原市',
    name_en: 'Shiozawa',
    latitude: '37.066669',
    longitude: '139.933334'
  },
  {
    id: '1851952',
    state_id: '15',
    name: '南魚沼市',
    name_en: 'Shiozawa',
    latitude: '37.033329',
    longitude: '138.850006'
  },
  {
    id: '1851992',
    state_id: '14',
    name: '横浜市',
    name_en: 'Shiomidai',
    latitude: '35.583328',
    longitude: '139.533325'
  },
  {
    id: '1852003',
    state_id: '20',
    name: '塩尻市',
    name_en: 'Shiojiri',
    latitude: '36.099998',
    longitude: '137.966675'
  },
  {
    id: '1852025',
    state_id: '40',
    name: '福岡市',
    name_en: 'Shiobaru',
    latitude: '33.566669',
    longitude: '130.433334'
  },
  {
    id: '1852038',
    state_id: '47',
    name: '南大東村',
    name_en: 'Shintō',
    latitude: '25.85195',
    longitude: '131.259277'
  },
  {
    id: '1852046',
    state_id: '23',
    name: '新城市',
    name_en: 'Shinshiro',
    latitude: '34.900002',
    longitude: '137.5'
  },
  {
    id: '1852097',
    state_id: '32',
    name: '松江市',
    name_en: 'Shinjichō-shinji',
    latitude: '35.400002',
    longitude: '132.899994'
  },
  {
    id: '1852102',
    state_id: '34',
    name: '福山市',
    name_en: 'Shin’ichi',
    latitude: '34.549999',
    longitude: '133.266663'
  },
  {
    id: '1852109',
    state_id: '40',
    name: '新宮町',
    name_en: 'Shingū',
    latitude: '33.716671',
    longitude: '130.433334'
  },
  {
    id: '1852140',
    state_id: '13',
    name: '品川区',
    name_en: 'Shinagawa-ku',
    latitude: '35.60902',
    longitude: '139.730164'
  },
  {
    id: '1852195',
    state_id: '40',
    name: '那珂川市',
    name_en: 'Shimoshirouzu',
    latitude: '33.51667',
    longitude: '130.433334'
  },
  {
    id: '1852201',
    state_id: '32',
    name: '出雲市',
    name_en: 'Shimo-satsume',
    latitude: '35.216671',
    longitude: '132.649994'
  },
  {
    id: '1852225',
    state_id: '35',
    name: '下関市',
    name_en: 'Shimonoseki',
    latitude: '33.950001',
    longitude: '130.949997'
  },
  {
    id: '1852237',
    state_id: '10',
    name: '下仁田町',
    name_en: 'Shimonita',
    latitude: '36.216671',
    longitude: '138.783325'
  },
  {
    id: '1852272',
    state_id: '26',
    name: '南丹市',
    name_en: 'Shimoku',
    latitude: '35.099998',
    longitude: '135.600006'
  },
  {
    id: '1852278',
    state_id: '14',
    name: '川崎市',
    name_en: 'Shimokodanaka',
    latitude: '35.566669',
    longitude: '139.633331'
  },
  {
    id: '1852347',
    state_id: '08',
    name: '筑西市',
    name_en: 'Shimodate',
    latitude: '36.299999',
    longitude: '139.983337'
  },
  {
    id: '1852357',
    state_id: '22',
    name: '下田市',
    name_en: 'Shimoda',
    latitude: '34.666672',
    longitude: '138.949997'
  },
  {
    id: '1852383',
    state_id: '30',
    name: '和歌山市',
    name_en: 'Minato',
    latitude: '34.183331',
    longitude: '135.199997'
  },
  {
    id: '1852385',
    state_id: '16',
    name: '高岡市',
    name_en: 'Nishishinminato',
    latitude: '36.783329',
    longitude: '137.066666'
  },
  {
    id: '1852427',
    state_id: '14',
    name: '横浜市',
    name_en: 'Shinbashichō',
    latitude: '35.433331',
    longitude: '139.516663'
  },
  {
    id: '1852472',
    state_id: '22',
    name: '島田市',
    name_en: 'Shimada',
    latitude: '34.816669',
    longitude: '138.183334'
  },
  {
    id: '1852479',
    state_id: '42',
    name: '島原市',
    name_en: 'Shimabara',
    latitude: '32.783329',
    longitude: '130.366669'
  },
  {
    id: '1852502',
    state_id: '11',
    name: '志木市',
    name_en: 'Shiki',
    latitude: '35.833328',
    longitude: '139.583328'
  },
  {
    id: '1852524',
    state_id: '26',
    name: '京都市',
    name_en: 'Shijōdōri',
    latitude: '35.00378',
    longitude: '135.763031'
  },
  {
    id: '1852534',
    state_id: '40',
    name: '築上町',
    name_en: 'Shiida',
    latitude: '33.653412',
    longitude: '131.057968'
  },
  {
    id: '1852561',
    state_id: '37',
    name: 'さぬき市',
    name_en: 'Shido',
    latitude: '34.32333',
    longitude: '134.173325'
  },
  {
    id: '1852579',
    state_id: '47',
    name: '宮古島市',
    name_en: 'Nanabari',
    latitude: '24.783461',
    longitude: '125.308693'
  },
  {
    id: '1852588',
    state_id: '46',
    name: '志布志市',
    name_en: 'Shibushi',
    latitude: '31.466669',
    longitude: '131.104996'
  },
  {
    id: '1852595',
    state_id: '10',
    name: '渋川市',
    name_en: 'Shibukawa',
    latitude: '36.48333',
    longitude: '139'
  },
  {
    id: '1852607',
    state_id: '15',
    name: '新発田市',
    name_en: 'Shibata',
    latitude: '37.950001',
    longitude: '139.333328'
  },
  {
    id: '1852663',
    state_id: '23',
    name: '瀬戸市',
    name_en: 'Seto',
    latitude: '35.23333',
    longitude: '137.100006'
  },
  {
    id: '1852673',
    state_id: '40',
    name: 'みやま市',
    name_en: 'Setakamachi-takayanagi',
    latitude: '33.150002',
    longitude: '130.466675'
  },
  {
    id: '1852676',
    state_id: '13',
    name: '世田谷区',
    name_en: 'Umegaoka',
    latitude: '35.633331',
    longitude: '139.649994'
  },
  {
    id: '1852679',
    state_id: '25',
    name: '大津市',
    name_en: 'Seta',
    latitude: '34.966671',
    longitude: '135.916672'
  },
  {
    id: '1852685',
    state_id: '46',
    name: '鹿児島市',
    name_en: 'Kiire-sesekushichō',
    latitude: '31.41667',
    longitude: '130.516663'
  },
  {
    id: '1852699',
    state_id: '35',
    name: '長門市',
    name_en: 'Senzaki',
    latitude: '34.383331',
    longitude: '131.199997'
  },
  {
    id: '1852736',
    state_id: '46',
    name: '薩摩川内市',
    name_en: 'Satsumasendai',
    latitude: '31.816669',
    longitude: '130.300003'
  },
  {
    id: '1852748',
    state_id: '42',
    name: '長崎市',
    name_en: 'Sembonyama',
    latitude: '32.700001',
    longitude: '129.850006'
  },
  {
    id: '1852775',
    state_id: '21',
    name: '関市',
    name_en: 'Sekimachi',
    latitude: '35.48333',
    longitude: '136.916672'
  },
  {
    id: '1852788',
    state_id: '21',
    name: '関ケ原町',
    name_en: 'Sekigahara',
    latitude: '35.366669',
    longitude: '136.466675'
  },
  {
    id: '1852795',
    state_id: '24',
    name: '亀山市',
    name_en: 'Sekichō-furumaya',
    latitude: '34.849998',
    longitude: '136.399994'
  },
  {
    id: '1852831',
    state_id: '28',
    name: '佐用町',
    name_en: 'Sayō',
    latitude: '35',
    longitude: '134.366669'
  },
  {
    id: '1852849',
    state_id: '11',
    name: '幸手市',
    name_en: 'Satte',
    latitude: '36.066669',
    longitude: '139.716675'
  },
  {
    id: '1852899',
    state_id: '42',
    name: '佐世保市',
    name_en: 'Sasebo',
    latitude: '33.159168',
    longitude: '129.722778'
  },
  {
    id: '1852901',
    state_id: '28',
    name: '丹波篠山市',
    name_en: 'Sasayama',
    latitude: '35.066669',
    longitude: '135.216675'
  },
  {
    id: '1852915',
    state_id: '40',
    name: '篠栗町',
    name_en: 'Sasaguri',
    latitude: '33.616669',
    longitude: '130.533325'
  },
  {
    id: '1852964',
    state_id: '09',
    name: '佐野市',
    name_en: 'Sano',
    latitude: '36.316669',
    longitude: '139.583328'
  },
  {
    id: '1852984',
    state_id: '15',
    name: '三条市',
    name_en: 'Sanjō',
    latitude: '37.616669',
    longitude: '138.949997'
  },
  {
    id: '1853008',
    state_id: '28',
    name: '三田市',
    name_en: 'Sandachō',
    latitude: '34.883331',
    longitude: '135.233337'
  },
  {
    id: '1853024',
    state_id: '45',
    name: '国富町',
    name_en: 'Sanmyō',
    latitude: '32',
    longitude: '131.350006'
  },
  {
    id: '1853045',
    state_id: '39',
    name: '高知市',
    name_en: 'Sanbashidōri',
    latitude: '33.549999',
    longitude: '133.550003'
  },
  {
    id: '1853058',
    state_id: '14',
    name: '大和市',
    name_en: 'Sakurakabu',
    latitude: '35.450001',
    longitude: '139.466675'
  },
  {
    id: '1853066',
    state_id: '29',
    name: '桜井市',
    name_en: 'Sakurai',
    latitude: '34.5',
    longitude: '135.850006'
  },
  {
    id: '1853071',
    state_id: '11',
    name: '行田市',
    name_en: 'Sakurachō',
    latitude: '36.150002',
    longitude: '139.466675'
  },
  {
    id: '1853072',
    state_id: '16',
    name: '富山市',
    name_en: 'Sakurabashidōri',
    latitude: '36.700001',
    longitude: '137.216675'
  },
  {
    id: '1853081',
    state_id: '20',
    name: '佐久市',
    name_en: 'Saku',
    latitude: '36.216671',
    longitude: '138.483337'
  },
  {
    id: '1853140',
    state_id: '06',
    name: '酒田市',
    name_en: 'Sakata',
    latitude: '38.916672',
    longitude: '139.854996'
  },
  {
    id: '1853150',
    state_id: '43',
    name: '阿蘇市',
    name_en: 'Ichinomiyamachi-sakanashi',
    latitude: '32.933331',
    longitude: '131.133331'
  },
  {
    id: '1853163',
    state_id: '20',
    name: '坂城町',
    name_en: 'Sakaki',
    latitude: '36.466671',
    longitude: '138.183334'
  },
  {
    id: '1853174',
    state_id: '32',
    name: '松江市',
    name_en: 'Sakaiminato',
    latitude: '35.549999',
    longitude: '133.233337'
  },
  {
    id: '1853188',
    state_id: '37',
    name: '坂出市',
    name_en: 'Sakaidechō',
    latitude: '34.316669',
    longitude: '133.850006'
  },
  {
    id: '1853190',
    state_id: '37',
    name: '坂出市',
    name_en: 'Sakaidechō',
    latitude: '34.316669',
    longitude: '133.850006'
  },
  {
    id: '1853192',
    state_id: '10',
    name: '伊勢崎市',
    name_en: 'Sakai-nakajima',
    latitude: '36.26667',
    longitude: '139.25'
  },
  {
    id: '1853193',
    state_id: '08',
    name: '境町',
    name_en: 'Sakai',
    latitude: '36.099998',
    longitude: '139.800003'
  },
  {
    id: '1853195',
    state_id: '27',
    name: '堺市',
    name_en: 'Sakai',
    latitude: '34.583328',
    longitude: '135.466675'
  },
  {
    id: '1853202',
    state_id: '11',
    name: '秩父市',
    name_en: 'Sakaguchi',
    latitude: '35.950001',
    longitude: '139.033325'
  },
  {
    id: '1853209',
    state_id: '11',
    name: '坂戸市',
    name_en: 'Sakado',
    latitude: '35.95694',
    longitude: '139.388885'
  },
  {
    id: '1853237',
    state_id: '44',
    name: '佐伯市',
    name_en: 'Saiki',
    latitude: '32.950001',
    longitude: '131.899994'
  },
  {
    id: '1853280',
    state_id: '22',
    name: '牧之原市',
    name_en: 'Sagara',
    latitude: '34.683331',
    longitude: '138.199997'
  },
  {
    id: '1853295',
    state_id: '14',
    name: '相模原市',
    name_en: 'Sagamihara',
    latitude: '35.553059',
    longitude: '139.354446'
  },
  {
    id: '1853303',
    state_id: '41',
    name: '佐賀市',
    name_en: 'Saga',
    latitude: '33.249321',
    longitude: '130.298798'
  },
  {
    id: '1853338',
    state_id: '18',
    name: '鯖江市',
    name_en: 'Sabae',
    latitude: '35.946468',
    longitude: '136.184982'
  },
  {
    id: '1853354',
    state_id: '19',
    name: '南アルプス市',
    name_en: 'Ryūō',
    latitude: '35.650002',
    longitude: '138.5'
  },
  {
    id: '1853371',
    state_id: '15',
    name: '佐渡市',
    name_en: 'Ryōtsu-minato',
    latitude: '38.083328',
    longitude: '138.433334'
  },
  {
    id: '1853394',
    state_id: '46',
    name: '霧島市',
    name_en: 'Ronji',
    latitude: '31.783331',
    longitude: '130.716675'
  },
  {
    id: '1853398',
    state_id: '26',
    name: '宇治市',
    name_en: 'Rokujizō',
    latitude: '34.933331',
    longitude: '135.800003'
  },
  {
    id: '1853412',
    state_id: '10',
    name: '前橋市',
    name_en: 'Renjakuchō',
    latitude: '36.383331',
    longitude: '139.066666'
  },
  {
    id: '1853433',
    state_id: '43',
    name: '大津町',
    name_en: 'Ōzu',
    latitude: '32.866669',
    longitude: '130.866669'
  },
  {
    id: '1853483',
    state_id: '09',
    name: '小山市',
    name_en: 'Oyama',
    latitude: '36.299999',
    longitude: '139.800003'
  },
  {
    id: '1853486',
    state_id: '22',
    name: '御前崎市',
    name_en: 'Ōyama',
    latitude: '34.599998',
    longitude: '138.216675'
  },
  {
    id: '1853514',
    state_id: '24',
    name: '尾鷲市',
    name_en: 'Owase',
    latitude: '34.066669',
    longitude: '136.199997'
  },
  {
    id: '1853564',
    state_id: '19',
    name: '都留市',
    name_en: 'Ōtsuki',
    latitude: '35.599998',
    longitude: '138.933334'
  },
  {
    id: '1853574',
    state_id: '25',
    name: '大津市',
    name_en: 'Ōtsu',
    latitude: '35.00444',
    longitude: '135.868332'
  },
  {
    id: '1853620',
    state_id: '18',
    name: '福井市',
    name_en: 'Ohtemachi',
    latitude: '36.067348',
    longitude: '136.220169'
  },
  {
    id: '1853662',
    state_id: '35',
    name: '和木町',
    name_en: 'Ōtake',
    latitude: '34.200001',
    longitude: '132.216675'
  },
  {
    id: '1853677',
    state_id: '10',
    name: '太田市',
    name_en: 'Ōta',
    latitude: '36.299999',
    longitude: '139.366669'
  },
  {
    id: '1853807',
    state_id: '11',
    name: '行田市',
    name_en: 'Oshi',
    latitude: '36.133331',
    longitude: '139.449997'
  },
  {
    id: '1853904',
    state_id: '27',
    name: '大阪市',
    name_en: 'Ōsaka-fu',
    latitude: '34.68631',
    longitude: '135.519684'
  },
  {
    id: '1853909',
    state_id: '27',
    name: '大阪市',
    name_en: 'Osaka',
    latitude: '34.693741',
    longitude: '135.502182'
  },
  {
    id: '1853992',
    state_id: '34',
    name: '尾道市',
    name_en: 'Onomichi',
    latitude: '34.416672',
    longitude: '133.199997'
  },
  {
    id: '1854018',
    state_id: '35',
    name: '山陽小野田市',
    name_en: 'Onoda',
    latitude: '34.001389',
    longitude: '131.183609'
  },
  {
    id: '1854022',
    state_id: '18',
    name: '大野市',
    name_en: 'Ono',
    latitude: '35.98106',
    longitude: '136.487274'
  },
  {
    id: '1854026',
    state_id: '28',
    name: '小野市',
    name_en: 'Ono',
    latitude: '34.849998',
    longitude: '134.933334'
  },
  {
    id: '1854028',
    state_id: '34',
    name: '廿日市市',
    name_en: 'Ōno-hara',
    latitude: '34.283329',
    longitude: '132.266663'
  },
  {
    id: '1854029',
    state_id: '36',
    name: '神山町',
    name_en: 'Ono',
    latitude: '33.98333',
    longitude: '134.366669'
  },
  {
    id: '1854037',
    state_id: '47',
    name: '恩納村',
    name_en: 'Onna',
    latitude: '26.492781',
    longitude: '127.85083'
  },
  {
    id: '1854083',
    state_id: '40',
    name: '大牟田市',
    name_en: 'Ōmuta',
    latitude: '33.033329',
    longitude: '130.449997'
  },
  {
    id: '1854093',
    state_id: '42',
    name: '大村市',
    name_en: 'Ōmura',
    latitude: '32.921391',
    longitude: '129.953888'
  },
  {
    id: '1854128',
    state_id: '22',
    name: '富士宮市',
    name_en: 'Ōmiyachō',
    latitude: '35.216671',
    longitude: '138.616669'
  },
  {
    id: '1854156',
    state_id: '16',
    name: '富山市',
    name_en: 'Omi',
    latitude: '36.566669',
    longitude: '137.383331'
  },
  {
    id: '1854162',
    state_id: '13',
    name: '青梅市',
    name_en: 'Ōme',
    latitude: '35.78389',
    longitude: '139.243057'
  },
  {
    id: '1854177',
    state_id: '10',
    name: 'みどり市',
    name_en: 'Ōmamachō-ōmama',
    latitude: '36.433331',
    longitude: '139.283325'
  },
  {
    id: '1854186',
    state_id: '20',
    name: '大町市',
    name_en: 'Ōmachi',
    latitude: '36.5',
    longitude: '137.866669'
  },
  {
    id: '1854246',
    state_id: '46',
    name: '伊佐市',
    name_en: 'Ōkuchi-shinohara',
    latitude: '32.066669',
    longitude: '130.616669'
  },
  {
    id: '1854255',
    state_id: '27',
    name: '熊取町',
    name_en: 'Ōkubo-naka',
    latitude: '34.400002',
    longitude: '135.350006'
  },
  {
    id: '1854371',
    state_id: '11',
    name: '桶川市',
    name_en: 'Okegawa',
    latitude: '36',
    longitude: '139.55722'
  },
  {
    id: '1854372',
    state_id: '13',
    name: '八丈島',
    name_en: 'Ōkagō',
    latitude: '33.099998',
    longitude: '139.783325'
  },
  {
    id: '1854376',
    state_id: '23',
    name: '岡崎市',
    name_en: 'Okazaki',
    latitude: '34.950001',
    longitude: '137.166672'
  },
  {
    id: '1854383',
    state_id: '33',
    name: '岡山市',
    name_en: 'Okayama',
    latitude: '34.661671',
    longitude: '133.934998'
  },
  {
    id: '1854384',
    state_id: '20',
    name: '岡谷市',
    name_en: 'Okaya',
    latitude: '36.056591',
    longitude: '138.045105'
  },
  {
    id: '1854405',
    state_id: '40',
    name: '大川市',
    name_en: 'Ōkawa',
    latitude: '33.200001',
    longitude: '130.350006'
  },
  {
    id: '1854431',
    state_id: '13',
    name: '大島町',
    name_en: 'Okata',
    latitude: '34.783329',
    longitude: '139.383331'
  },
  {
    id: '1854444',
    state_id: '15',
    name: '小千谷市',
    name_en: 'Ojiya',
    latitude: '37.299999',
    longitude: '138.800003'
  },
  {
    id: '1854461',
    state_id: '26',
    name: '亀岡市',
    name_en: 'Shinochō-ōji',
    latitude: '34.98333',
    longitude: '135.616669'
  },
  {
    id: '1854487',
    state_id: '44',
    name: '大分市',
    name_en: 'Ōita',
    latitude: '33.23806',
    longitude: '131.612503'
  },
  {
    id: '1854492',
    state_id: '14',
    name: '大磯町',
    name_en: 'Ōiso',
    latitude: '35.303059',
    longitude: '139.30278'
  },
  {
    id: '1854530',
    state_id: '11',
    name: 'ふじみ野市',
    name_en: 'Ōi',
    latitude: '35.851109',
    longitude: '139.516663'
  },
  {
    id: '1854579',
    state_id: '24',
    name: '鳥羽市',
    name_en: 'Ohama',
    latitude: '34.5',
    longitude: '136.833328'
  },
  {
    id: '1854588',
    state_id: '47',
    name: '石垣市',
    name_en: 'Ōhama',
    latitude: '24.344481',
    longitude: '124.19706'
  },
  {
    id: '1854611',
    state_id: '06',
    name: '小国町',
    name_en: 'Ogunimachi',
    latitude: '38.066669',
    longitude: '139.75'
  },
  {
    id: '1854626',
    state_id: '11',
    name: '越生町',
    name_en: 'Ogose',
    latitude: '35.958889',
    longitude: '139.289993'
  },
  {
    id: '1854629',
    state_id: '35',
    name: '山口市',
    name_en: 'Ogōri-shimogō',
    latitude: '34.099998',
    longitude: '131.399994'
  },
  {
    id: '1854642',
    state_id: '11',
    name: '入間市',
    name_en: 'Ōgimachiya',
    latitude: '35.833328',
    longitude: '139.399994'
  },
  {
    id: '1854656',
    state_id: '41',
    name: '小城市',
    name_en: 'Ogimachi',
    latitude: '33.287781',
    longitude: '130.201111'
  },
  {
    id: '1854665',
    state_id: '11',
    name: '小川町',
    name_en: 'Ogawa',
    latitude: '36.049999',
    longitude: '139.266663'
  },
  {
    id: '1854691',
    state_id: '11',
    name: '小鹿野町',
    name_en: 'Ogano',
    latitude: '36.01667',
    longitude: '139'
  },
  {
    id: '1854703',
    state_id: '21',
    name: '大垣市',
    name_en: 'Ōgaki',
    latitude: '35.349998',
    longitude: '136.616669'
  },
  {
    id: '1854712',
    state_id: '42',
    name: '対馬市',
    name_en: 'Mitsushimachō-ōfunakoshi',
    latitude: '34.273331',
    longitude: '129.354721'
  },
  {
    id: '1854742',
    state_id: '07',
    name: '会津若松市',
    name_en: 'Ōtera',
    latitude: '37.549999',
    longitude: '139.983337'
  },
  {
    id: '1854747',
    state_id: '14',
    name: '小田原市',
    name_en: 'Odawara',
    latitude: '35.255562',
    longitude: '139.159714'
  },
  {
    id: '1854762',
    state_id: '23',
    name: '名古屋市',
    name_en: 'Ōdaka',
    latitude: '35.066669',
    longitude: '136.933334'
  },
  {
    id: '1854774',
    state_id: '32',
    name: '大田市',
    name_en: 'Ōdachō-ōda',
    latitude: '35.183331',
    longitude: '132.5'
  },
  {
    id: '1854788',
    state_id: '19',
    name: '甲州市',
    name_en: 'Ochiai',
    latitude: '35.799999',
    longitude: '138.833328'
  },
  {
    id: '1854798',
    state_id: '20',
    name: '小布施町',
    name_en: 'Obuse',
    latitude: '36.700001',
    longitude: '138.316666'
  },
  {
    id: '1854803',
    state_id: '23',
    name: '東浦町',
    name_en: 'Ōbu',
    latitude: '35',
    longitude: '136.966675'
  },
  {
    id: '1854807',
    state_id: '42',
    name: '長与町',
    name_en: 'Obita',
    latitude: '32.816669',
    longitude: '129.883331'
  },
  {
    id: '1854849',
    state_id: '18',
    name: '小浜市',
    name_en: 'Obama',
    latitude: '35.495762',
    longitude: '135.746033'
  },
  {
    id: '1854868',
    state_id: '16',
    name: '入善町',
    name_en: 'Nyūzen',
    latitude: '36.933331',
    longitude: '137.5'
  },
  {
    id: '1854902',
    state_id: '22',
    name: '沼津市',
    name_en: 'Numazu',
    latitude: '35.099998',
    longitude: '138.866669'
  },
  {
    id: '1854905',
    state_id: '10',
    name: '沼田市',
    name_en: 'Numata',
    latitude: '36.633331',
    longitude: '139.050003'
  },
  {
    id: '1854964',
    state_id: '47',
    name: '嘉手納町',
    name_en: 'Nosato',
    latitude: '26.35',
    longitude: '127.76667'
  },
  {
    id: '1854979',
    state_id: '17',
    name: '野々市市',
    name_en: 'Nonoichi',
    latitude: '36.533329',
    longitude: '136.616669'
  },
  {
    id: '1855066',
    state_id: '40',
    name: '直方市',
    name_en: 'Nōgata',
    latitude: '33.73333',
    longitude: '130.733337'
  },
  {
    id: '1855078',
    state_id: '12',
    name: '野田市',
    name_en: 'Noda',
    latitude: '35.941109',
    longitude: '139.858063'
  },
  {
    id: '1855095',
    state_id: '45',
    name: '延岡市',
    name_en: 'Nobeoka',
    latitude: '32.583328',
    longitude: '131.666672'
  },
  {
    id: '1855114',
    state_id: '18',
    name: '小浜市',
    name_en: 'Nishizu',
    latitude: '35.516472',
    longitude: '135.752304'
  },
  {
    id: '1855134',
    state_id: '28',
    name: '西脇市',
    name_en: 'Nishiwaki',
    latitude: '34.98333',
    longitude: '134.966675'
  },
  {
    id: '1855189',
    state_id: '23',
    name: '西尾市',
    name_en: 'Nishio',
    latitude: '34.866669',
    longitude: '137.050003'
  },
  {
    id: '1855203',
    state_id: '46',
    name: '西之表市',
    name_en: 'Nishinoomote',
    latitude: '30.73333',
    longitude: '131'
  },
  {
    id: '1855207',
    state_id: '28',
    name: '西宮市',
    name_en: 'Nishinomiya-hama',
    latitude: '34.716671',
    longitude: '135.333328'
  },
  {
    id: '1855265',
    state_id: '19',
    name: '甲府市',
    name_en: 'Nishikichō',
    latitude: '35.650002',
    longitude: '138.566666'
  },
  {
    id: '1855301',
    state_id: '19',
    name: '中央市',
    name_en: 'Nishi-hanawa',
    latitude: '35.583328',
    longitude: '138.533325'
  },
  {
    id: '1855309',
    state_id: '20',
    name: '長野市',
    name_en: 'Nishigochō',
    latitude: '36.650002',
    longitude: '138.183334'
  },
  {
    id: '1855342',
    state_id: '47',
    name: '南城市',
    name_en: 'Nishihara',
    latitude: '26.18417',
    longitude: '127.755829'
  },
  {
    id: '1855357',
    state_id: '47',
    name: '粟国村',
    name_en: 'Nishi',
    latitude: '26.58333',
    longitude: '127.23333'
  },
  {
    id: '1855363',
    state_id: '19',
    name: '韮崎市',
    name_en: 'Nirasaki',
    latitude: '35.700001',
    longitude: '138.449997'
  },
  {
    id: '1855380',
    state_id: '14',
    name: '二宮町',
    name_en: 'Ninomiya',
    latitude: '35.303329',
    longitude: '139.253326'
  },
  {
    id: '1855395',
    state_id: '09',
    name: '日光市',
    name_en: 'Nikkō',
    latitude: '36.75',
    longitude: '139.616669'
  },
  {
    id: '1855410',
    state_id: '15',
    name: '新潟市',
    name_en: 'Niitsu-honchō',
    latitude: '37.799999',
    longitude: '139.116669'
  },
  {
    id: '1855416',
    state_id: '33',
    name: '新見市',
    name_en: 'Niimi',
    latitude: '34.98333',
    longitude: '133.466675'
  },
  {
    id: '1855425',
    state_id: '38',
    name: '新居浜市',
    name_en: 'Niihama',
    latitude: '33.959332',
    longitude: '133.316727'
  },
  {
    id: '1855431',
    state_id: '15',
    name: '新潟市',
    name_en: 'Niigata',
    latitude: '37.902222',
    longitude: '139.023605'
  },
  {
    id: '1855444',
    state_id: '14',
    name: '横浜市',
    name_en: 'Nihon’ōdōri',
    latitude: '35.450001',
    longitude: '139.649994'
  },
  {
    id: '1855476',
    state_id: '45',
    name: '日南市',
    name_en: 'Nichinan',
    latitude: '31.6',
    longitude: '131.366669'
  },
  {
    id: '1855503',
    state_id: '13',
    name: '練馬区',
    name_en: 'Nerima',
    latitude: '35.73333',
    longitude: '139.649994'
  },
  {
    id: '1855512',
    state_id: '12',
    name: '南房総市',
    name_en: 'Nemoto',
    latitude: '34.900002',
    longitude: '139.833328'
  },
  {
    id: '1855540',
    state_id: '46',
    name: '奄美市',
    name_en: 'Naze',
    latitude: '28.366671',
    longitude: '129.483337'
  },
  {
    id: '1855566',
    state_id: '27',
    name: '枚方市',
    name_en: 'Nasuzukuri',
    latitude: '34.783329',
    longitude: '135.666672'
  },
  {
    id: '1855580',
    state_id: '36',
    name: '鳴門市',
    name_en: 'Narutochō-mitsuishi',
    latitude: '34.183331',
    longitude: '134.616669'
  },
  {
    id: '1855585',
    state_id: '19',
    name: '甲州市',
    name_en: 'Narusawa',
    latitude: '35.75',
    longitude: '138.733337'
  },
  {
    id: '1855612',
    state_id: '29',
    name: '奈良市',
    name_en: 'Nara-shi',
    latitude: '34.685051',
    longitude: '135.804855'
  },
  {
    id: '1855623',
    state_id: '33',
    name: '井原市',
    name_en: 'Nanukaichi',
    latitude: '34.599998',
    longitude: '133.466675'
  },
  {
    id: '1855670',
    state_id: '17',
    name: '七尾市',
    name_en: 'Nanao',
    latitude: '37.049999',
    longitude: '136.966675'
  },
  {
    id: '1855694',
    state_id: '16',
    name: '滑川市',
    name_en: 'Namerikawa',
    latitude: '36.76667',
    longitude: '137.333328'
  },
  {
    id: '1855713',
    state_id: '47',
    name: '今帰仁村',
    name_en: 'Nakijin',
    latitude: '26.675831',
    longitude: '127.971672'
  },
  {
    id: '1855730',
    state_id: '12',
    name: '船橋市',
    name_en: 'Nakayama',
    latitude: '35.716671',
    longitude: '139.949997'
  },
  {
    id: '1855753',
    state_id: '21',
    name: '中津川市',
    name_en: 'Nakatsugawa',
    latitude: '35.48333',
    longitude: '137.5'
  },
  {
    id: '1855756',
    state_id: '22',
    name: '浜松市',
    name_en: 'Nakatsu',
    latitude: '34.799999',
    longitude: '137.716675'
  },
  {
    id: '1855757',
    state_id: '44',
    name: '中津市',
    name_en: 'Nakatsu',
    latitude: '33.59811',
    longitude: '131.188293'
  },
  {
    id: '1855847',
    state_id: '47',
    name: '北大東村',
    name_en: 'Nakano',
    latitude: '25.94562',
    longitude: '131.310883'
  },
  {
    id: '1855852',
    state_id: '10',
    name: '中之条町',
    name_en: 'Nakanojōmachi',
    latitude: '36.583328',
    longitude: '138.850006'
  },
  {
    id: '1855863',
    state_id: '20',
    name: '中野市',
    name_en: 'Nakano',
    latitude: '36.75',
    longitude: '138.366669'
  },
  {
    id: '1855882',
    state_id: '20',
    name: '松本市',
    name_en: 'Nakamura',
    latitude: '36.150002',
    longitude: '137.916672'
  },
  {
    id: '1855891',
    state_id: '39',
    name: '四万十市',
    name_en: 'Nakamura',
    latitude: '32.98333',
    longitude: '132.933334'
  },
  {
    id: '1855907',
    state_id: '40',
    name: '中間市',
    name_en: 'Nakama',
    latitude: '33.816669',
    longitude: '130.699997'
  },
  {
    id: '1855933',
    state_id: '16',
    name: '富山市',
    name_en: 'Nakajima',
    latitude: '36.716671',
    longitude: '137.216675'
  },
  {
    id: '1855939',
    state_id: '22',
    name: '磐田市',
    name_en: 'Nakaizumi',
    latitude: '34.700001',
    longitude: '137.850006'
  },
  {
    id: '1855944',
    state_id: '35',
    name: '山口市',
    name_en: 'Nakaichi',
    latitude: '34.166672',
    longitude: '131.483337'
  },
  {
    id: '1855947',
    state_id: '28',
    name: '宍粟市',
    name_en: 'Yamazakichō-nakai',
    latitude: '34.98333',
    longitude: '134.533325'
  },
  {
    id: '1855973',
    state_id: '35',
    name: '山口市',
    name_en: 'Naka-gōchi',
    latitude: '34.01667',
    longitude: '131.333328'
  },
  {
    id: '1855987',
    state_id: '23',
    name: '豊明市',
    name_en: 'Nakagawa',
    latitude: '35.066669',
    longitude: '137.033325'
  },
  {
    id: '1856033',
    state_id: '39',
    name: '奈半利町',
    name_en: 'Nahari',
    latitude: '33.416672',
    longitude: '134.016663'
  },
  {
    id: '1856035',
    state_id: '47',
    name: '那覇市',
    name_en: 'Naha',
    latitude: '26.2125',
    longitude: '127.681107'
  },
  {
    id: '1856057',
    state_id: '23',
    name: '名古屋市',
    name_en: 'Nagoya',
    latitude: '35.181469',
    longitude: '136.906403'
  },
  {
    id: '1856068',
    state_id: '47',
    name: '名護市',
    name_en: 'Nago',
    latitude: '26.58806',
    longitude: '127.976112'
  },
  {
    id: '1856177',
    state_id: '42',
    name: '長崎市',
    name_en: 'Nagasaki',
    latitude: '32.74472',
    longitude: '129.873611'
  },
  {
    id: '1856184',
    state_id: '12',
    name: '流山市',
    name_en: 'Nagareyama',
    latitude: '35.8563',
    longitude: '139.902664'
  },
  {
    id: '1856199',
    state_id: '15',
    name: '長岡市',
    name_en: 'Nagaoka',
    latitude: '37.450001',
    longitude: '138.850006'
  },
  {
    id: '1856207',
    state_id: '37',
    name: 'さぬき市',
    name_en: 'Nagao',
    latitude: '34.26667',
    longitude: '134.166672'
  },
  {
    id: '1856212',
    state_id: '10',
    name: '長野原町',
    name_en: 'Naganohara',
    latitude: '36.549999',
    longitude: '138.633331'
  },
  {
    id: '1856215',
    state_id: '20',
    name: '長野市',
    name_en: 'Nagano',
    latitude: '36.65139',
    longitude: '138.181107'
  },
  {
    id: '1856239',
    state_id: '44',
    name: '大分市',
    name_en: 'Nagahamamachi',
    latitude: '33.23333',
    longitude: '131.616669'
  },
  {
    id: '1856243',
    state_id: '25',
    name: '長浜市',
    name_en: 'Nagahama',
    latitude: '35.383331',
    longitude: '136.266663'
  },
  {
    id: '1856293',
    state_id: '24',
    name: '名張市',
    name_en: 'Nabari',
    latitude: '34.616669',
    longitude: '136.083328'
  },
  {
    id: '1856300',
    state_id: '28',
    name: '西脇市',
    name_en: 'Myōrakujichō',
    latitude: '34.966671',
    longitude: '134.916672'
  },
  {
    id: '1856367',
    state_id: '13',
    name: '武蔵野市',
    name_en: 'Musashino',
    latitude: '35.706108',
    longitude: '139.559433'
  },
  {
    id: '1856386',
    state_id: '39',
    name: '室戸市',
    name_en: 'Murotsu',
    latitude: '33.283329',
    longitude: '134.149994'
  },
  {
    id: '1856392',
    state_id: '39',
    name: '室戸市',
    name_en: 'Muroto-misakicho',
    latitude: '33.283329',
    longitude: '134.149994'
  },
  {
    id: '1856426',
    state_id: '15',
    name: '五泉市',
    name_en: 'Muramatsu',
    latitude: '37.683331',
    longitude: '139.183334'
  },
  {
    id: '1856434',
    state_id: '15',
    name: '村上市',
    name_en: 'Murakami',
    latitude: '38.23333',
    longitude: '139.483337'
  },
  {
    id: '1856435',
    state_id: '20',
    name: '塩尻市',
    name_en: 'Muraimachi-minami',
    latitude: '36.166672',
    longitude: '137.966675'
  },
  {
    id: '1856456',
    state_id: '26',
    name: '京都市',
    name_en: 'Mukō',
    latitude: '34.96545',
    longitude: '135.704147'
  },
  {
    id: '1856472',
    state_id: '17',
    name: '内灘町',
    name_en: 'Mukai-awagasaki',
    latitude: '36.633331',
    longitude: '136.633331'
  },
  {
    id: '1856476',
    state_id: '15',
    name: '南魚沼市',
    name_en: 'Muikamachi',
    latitude: '37.066669',
    longitude: '138.883331'
  },
  {
    id: '1856488',
    state_id: '36',
    name: '牟岐町',
    name_en: 'Mugiura',
    latitude: '33.666672',
    longitude: '134.416672'
  },
  {
    id: '1856521',
    state_id: '30',
    name: '田辺市',
    name_en: 'Motomachi',
    latitude: '33.73333',
    longitude: '135.366669'
  },
  {
    id: '1856524',
    state_id: '22',
    name: '富士市',
    name_en: 'Motoichiba',
    latitude: '35.150002',
    longitude: '138.649994'
  },
  {
    id: '1856531',
    state_id: '42',
    name: '西海市',
    name_en: 'Motogoe',
    latitude: '32.966671',
    longitude: '129.800003'
  },
  {
    id: '1856560',
    state_id: '11',
    name: '毛呂山町',
    name_en: 'Morohongō',
    latitude: '35.935558',
    longitude: '139.304443'
  },
  {
    id: '1856566',
    state_id: '44',
    name: '中津市',
    name_en: 'Yamakunimachi-morizane',
    latitude: '33.400002',
    longitude: '131.033325'
  },
  {
    id: '1856569',
    state_id: '25',
    name: '守山市',
    name_en: 'Moriyama',
    latitude: '35.066669',
    longitude: '135.983337'
  },
  {
    id: '1856584',
    state_id: '27',
    name: '守口市',
    name_en: 'Moriguchi',
    latitude: '34.73333',
    longitude: '135.566666'
  },
  {
    id: '1856586',
    state_id: '22',
    name: '森町',
    name_en: 'Mori',
    latitude: '34.833328',
    longitude: '137.933334'
  },
  {
    id: '1856667',
    state_id: '21',
    name: '瑞浪市',
    name_en: 'Mizunami',
    latitude: '35.366669',
    longitude: '137.25'
  },
  {
    id: '1856685',
    state_id: '31',
    name: '伯耆町',
    name_en: 'Mizoguchi',
    latitude: '35.349998',
    longitude: '133.433334'
  },
  {
    id: '1856697',
    state_id: '23',
    name: 'みよし市',
    name_en: 'Miyoshi-shi',
    latitude: '35.083328',
    longitude: '137.066666'
  },
  {
    id: '1856698',
    state_id: '34',
    name: '三次市',
    name_en: 'Miyoshi',
    latitude: '34.799999',
    longitude: '132.850006'
  },
  {
    id: '1856703',
    state_id: '26',
    name: '宮津市',
    name_en: 'Miyazu',
    latitude: '35.533329',
    longitude: '135.183334'
  },
  {
    id: '1856717',
    state_id: '45',
    name: '宮崎市',
    name_en: 'Miyazaki',
    latitude: '31.91111',
    longitude: '131.423889'
  },
  {
    id: '1856764',
    state_id: '10',
    name: '伊勢崎市',
    name_en: 'Miyamaechō',
    latitude: '36.316669',
    longitude: '139.199997'
  },
  {
    id: '1856775',
    state_id: '45',
    name: '都城市',
    name_en: 'Miyakonojō',
    latitude: '31.73333',
    longitude: '131.066666'
  },
  {
    id: '1856789',
    state_id: '27',
    name: '松原市',
    name_en: 'Miyake-naka',
    latitude: '34.583328',
    longitude: '135.550003'
  },
  {
    id: '1856798',
    state_id: '34',
    name: '廿日市市',
    name_en: 'Miyajima',
    latitude: '34.30183',
    longitude: '132.326126'
  },
  {
    id: '1856801',
    state_id: '37',
    name: '綾川町',
    name_en: 'Miyaji',
    latitude: '34.200001',
    longitude: '133.983337'
  },
  {
    id: '1856826',
    state_id: '20',
    name: '宮田村',
    name_en: 'Miyada',
    latitude: '35.76667',
    longitude: '137.949997'
  },
  {
    id: '1856828',
    state_id: '40',
    name: '宮若市',
    name_en: 'Miyata',
    latitude: '33.716671',
    longitude: '130.666672'
  },
  {
    id: '1856845',
    state_id: '20',
    name: '長野市',
    name_en: 'Miwa',
    latitude: '36.666672',
    longitude: '138.199997'
  },
  {
    id: '1856878',
    state_id: '15',
    name: '見附市',
    name_en: 'Mitsuke',
    latitude: '37.533329',
    longitude: '138.933334'
  },
  {
    id: '1856881',
    state_id: '08',
    name: '常総市',
    name_en: 'Mitsukaidō',
    latitude: '36.01667',
    longitude: '139.983337'
  },
  {
    id: '1856900',
    state_id: '29',
    name: '奈良市',
    name_en: 'Mitsugarasu',
    latitude: '34.666672',
    longitude: '135.833328'
  },
  {
    id: '1856938',
    state_id: '21',
    name: '御嵩町',
    name_en: 'Mitake',
    latitude: '35.416672',
    longitude: '137.133331'
  },
  {
    id: '1856942',
    state_id: '13',
    name: '三鷹市',
    name_en: 'Mitaka-shi',
    latitude: '35.68351',
    longitude: '139.559631'
  },
  {
    id: '1856950',
    state_id: '13',
    name: '港区',
    name_en: 'Mita',
    latitude: '35.650002',
    longitude: '139.733337'
  },
  {
    id: '1856977',
    state_id: '22',
    name: '三島市',
    name_en: 'Mishima',
    latitude: '35.116669',
    longitude: '138.916672'
  },
  {
    id: '1857036',
    state_id: '10',
    name: '高崎市',
    name_en: 'Minowa',
    latitude: '36.383331',
    longitude: '138.949997'
  },
  {
    id: '1857046',
    state_id: '27',
    name: '箕面市',
    name_en: 'Mino',
    latitude: '34.826908',
    longitude: '135.470566'
  },
  {
    id: '1857061',
    state_id: '19',
    name: '身延町',
    name_en: 'Minobu',
    latitude: '35.366669',
    longitude: '138.433334'
  },
  {
    id: '1857062',
    state_id: '21',
    name: '美濃市',
    name_en: 'Mino',
    latitude: '35.533329',
    longitude: '136.916672'
  },
  {
    id: '1857089',
    state_id: '40',
    name: '大牟田市',
    name_en: 'Minatomachi',
    latitude: '33.033329',
    longitude: '130.449997'
  },
  {
    id: '1857105',
    state_id: '12',
    name: '富津市',
    name_en: 'Minato',
    latitude: '35.21806',
    longitude: '139.887497'
  },
  {
    id: '1857117',
    state_id: '11',
    name: '皆野町',
    name_en: 'Minano',
    latitude: '36.066669',
    longitude: '139.100006'
  },
  {
    id: '1857140',
    state_id: '13',
    name: '墨田区',
    name_en: 'Minamisenju',
    latitude: '35.73333',
    longitude: '139.816666'
  },
  {
    id: '1857144',
    state_id: '14',
    name: '大和市',
    name_en: 'Minamirinkan',
    latitude: '35.48333',
    longitude: '139.449997'
  },
  {
    id: '1857173',
    state_id: '26',
    name: '笠置町',
    name_en: 'Minamikasagi',
    latitude: '34.75',
    longitude: '135.933334'
  },
  {
    id: '1857208',
    state_id: '43',
    name: '水俣市',
    name_en: 'Minamata',
    latitude: '32.216671',
    longitude: '130.399994'
  },
  {
    id: '1857209',
    state_id: '25',
    name: '甲賀市',
    name_en: 'Minakuchichō-matoba',
    latitude: '34.966671',
    longitude: '136.166672'
  },
  {
    id: '1857213',
    state_id: '33',
    name: '総社市',
    name_en: 'Minagi',
    latitude: '34.716671',
    longitude: '133.649994'
  },
  {
    id: '1857260',
    state_id: '18',
    name: '坂井市',
    name_en: 'Mikuni',
    latitude: '36.21706',
    longitude: '136.151855'
  },
  {
    id: '1857276',
    state_id: '28',
    name: '三木市',
    name_en: 'Miki',
    latitude: '34.799999',
    longitude: '134.983337'
  },
  {
    id: '1857334',
    state_id: '34',
    name: '三原市',
    name_en: 'Mihara',
    latitude: '34.400002',
    longitude: '133.083328'
  },
  {
    id: '1857343',
    state_id: '43',
    name: '御船町',
    name_en: 'Mifune',
    latitude: '32.716671',
    longitude: '130.800003'
  },
  {
    id: '1857357',
    state_id: '44',
    name: '豊後大野市',
    name_en: 'Mie',
    latitude: '32.966671',
    longitude: '131.583328'
  },
  {
    id: '1857379',
    state_id: '09',
    name: '壬生町',
    name_en: 'Mibu',
    latitude: '36.416672',
    longitude: '139.800003'
  },
  {
    id: '1857403',
    state_id: '11',
    name: '熊谷市',
    name_en: 'Menuma',
    latitude: '36.216671',
    longitude: '139.383331'
  },
  {
    id: '1857441',
    state_id: '24',
    name: '松阪市',
    name_en: 'Mayanoheta',
    latitude: '34.549999',
    longitude: '136.516663'
  },
  {
    id: '1857451',
    state_id: '22',
    name: '松崎町',
    name_en: 'Matsuzaki',
    latitude: '34.75',
    longitude: '138.783325'
  },
  {
    id: '1857470',
    state_id: '17',
    name: '白山市',
    name_en: 'Matsutō',
    latitude: '36.51667',
    longitude: '136.566666'
  },
  {
    id: '1857496',
    state_id: '24',
    name: '松阪市',
    name_en: 'Matsuzaka-shi',
    latitude: '34.566669',
    longitude: '136.533325'
  },
  {
    id: '1857519',
    state_id: '20',
    name: '松本市',
    name_en: 'Matsumoto',
    latitude: '36.23333',
    longitude: '137.966675'
  },
  {
    id: '1857533',
    state_id: '15',
    name: '新潟市',
    name_en: 'Matsugasakihama',
    latitude: '37.957779',
    longitude: '139.133331'
  },
  {
    id: '1857550',
    state_id: '32',
    name: '松江市',
    name_en: 'Matsue',
    latitude: '35.472221',
    longitude: '133.050568'
  },
  {
    id: '1857553',
    state_id: '12',
    name: '松戸市',
    name_en: 'Matsudo',
    latitude: '35.783329',
    longitude: '139.899994'
  },
  {
    id: '1857560',
    state_id: '43',
    name: '宇城市',
    name_en: 'Matsubase',
    latitude: '32.650002',
    longitude: '130.666672'
  },
  {
    id: '1857568',
    state_id: '27',
    name: '松原市',
    name_en: 'Matsubara',
    latitude: '34.566669',
    longitude: '135.550003'
  },
  {
    id: '1857578',
    state_id: '37',
    name: '綾川町',
    name_en: 'Matoba',
    latitude: '34.25',
    longitude: '133.949997'
  },
  {
    id: '1857594',
    state_id: '32',
    name: '益田市',
    name_en: 'Masuda',
    latitude: '34.666672',
    longitude: '131.850006'
  },
  {
    id: '1857651',
    state_id: '18',
    name: '坂井市',
    name_en: 'Maruoka',
    latitude: '36.1534',
    longitude: '136.270294'
  },
  {
    id: '1857654',
    state_id: '13',
    name: '千代田区',
    name_en: 'Marunouchi',
    latitude: '35.683331',
    longitude: '139.766663'
  },
  {
    id: '1857659',
    state_id: '20',
    name: '上田市',
    name_en: 'Kamimaruko',
    latitude: '36.316669',
    longitude: '138.266663'
  },
  {
    id: '1857665',
    state_id: '37',
    name: '丸亀市',
    name_en: 'Marugame',
    latitude: '34.283329',
    longitude: '133.783325'
  },
  {
    id: '1857672',
    state_id: '19',
    name: '南部町',
    name_en: 'Manzawa',
    latitude: '35.216671',
    longitude: '138.516663'
  },
  {
    id: '1857689',
    state_id: '36',
    name: '徳島市',
    name_en: 'Bandaichō',
    latitude: '34.066669',
    longitude: '134.566666'
  },
  {
    id: '1857692',
    state_id: '14',
    name: '真鶴町',
    name_en: 'Manazuru',
    latitude: '35.153889',
    longitude: '139.135559'
  },
  {
    id: '1857706',
    state_id: '17',
    name: '金沢市',
    name_en: 'Mameda-honmachi',
    latitude: '36.566669',
    longitude: '136.633331'
  },
  {
    id: '1857712',
    state_id: '46',
    name: '枕崎市',
    name_en: 'Makurazaki',
    latitude: '31.26667',
    longitude: '130.316666'
  },
  {
    id: '1857736',
    state_id: '22',
    name: '島田市',
    name_en: 'Makinohara',
    latitude: '34.816669',
    longitude: '138.116669'
  },
  {
    id: '1857751',
    state_id: '15',
    name: '新潟市',
    name_en: 'Maki',
    latitude: '37.75',
    longitude: '138.883331'
  },
  {
    id: '1857766',
    state_id: '26',
    name: '舞鶴市',
    name_en: 'Maizuru',
    latitude: '35.450001',
    longitude: '135.333328'
  },
  {
    id: '1857769',
    state_id: '25',
    name: '米原市',
    name_en: 'Maibara',
    latitude: '35.316669',
    longitude: '136.283325'
  },
  {
    id: '1857829',
    state_id: '39',
    name: '南国市',
    name_en: 'Maehama',
    latitude: '33.533329',
    longitude: '133.666672'
  },
  {
    id: '1857838',
    state_id: '35',
    name: '下関市',
    name_en: 'Maeda',
    latitude: '33.966671',
    longitude: '130.966675'
  },
  {
    id: '1857843',
    state_id: '10',
    name: '前橋市',
    name_en: 'Maebashi',
    latitude: '36.391109',
    longitude: '139.060837'
  },
  {
    id: '1857844',
    state_id: '40',
    name: '糸島市',
    name_en: 'Maebaru-chūō',
    latitude: '33.549999',
    longitude: '130.199997'
  },
  {
    id: '1857871',
    state_id: '13',
    name: '町田市',
    name_en: 'Machida',
    latitude: '35.540279',
    longitude: '139.450836'
  },
  {
    id: '1857906',
    state_id: '26',
    name: '京都市',
    name_en: 'Kyōto-shi',
    latitude: '35.107368',
    longitude: '135.724014'
  },
  {
    id: '1857910',
    state_id: '26',
    name: '京都市',
    name_en: 'Kyoto',
    latitude: '35.021069',
    longitude: '135.753845'
  },
  {
    id: '1857922',
    state_id: '26',
    name: '舞鶴市',
    name_en: 'Kyōguchi',
    latitude: '35.450001',
    longitude: '135.333328'
  },
  {
    id: '1857968',
    state_id: '24',
    name: '桑名市',
    name_en: 'Kuwana',
    latitude: '35.066669',
    longitude: '136.699997'
  },
  {
    id: '1858025',
    state_id: '28',
    name: '伊丹市',
    name_en: 'Kushiro',
    latitude: '34.799999',
    longitude: '135.416672'
  },
  {
    id: '1858033',
    state_id: '30',
    name: '串本町',
    name_en: 'Kushimoto',
    latitude: '33.466671',
    longitude: '135.783325'
  },
  {
    id: '1858041',
    state_id: '46',
    name: 'いちき串木野市',
    name_en: 'Kushikino',
    latitude: '31.716669',
    longitude: '130.266663'
  },
  {
    id: '1858067',
    state_id: '25',
    name: '草津市',
    name_en: 'Kusatsu',
    latitude: '35.01667',
    longitude: '135.966675'
  },
  {
    id: '1858088',
    state_id: '40',
    name: '久留米市',
    name_en: 'Kurume',
    latitude: '33.316669',
    longitude: '130.516663'
  },
  {
    id: '1858249',
    state_id: '23',
    name: '一宮市',
    name_en: 'Kuroda',
    latitude: '35.349998',
    longitude: '136.783325'
  },
  {
    id: '1858283',
    state_id: '11',
    name: '久喜市',
    name_en: 'Kurihashi',
    latitude: '36.133331',
    longitude: '139.699997'
  },
  {
    id: '1858296',
    state_id: '34',
    name: '呉市',
    name_en: 'Kure',
    latitude: '34.23333',
    longitude: '132.566666'
  },
  {
    id: '1858301',
    state_id: '31',
    name: '倉吉市',
    name_en: 'Kurayoshi',
    latitude: '35.433331',
    longitude: '133.816666'
  },
  {
    id: '1858311',
    state_id: '33',
    name: '倉敷市',
    name_en: 'Kurashiki',
    latitude: '34.583328',
    longitude: '133.766663'
  },
  {
    id: '1858333',
    state_id: '26',
    name: '舞鶴市',
    name_en: 'Kuradani',
    latitude: '35.450001',
    longitude: '135.333328'
  },
  {
    id: '1858373',
    state_id: '46',
    name: '和泊町',
    name_en: 'Kunigami',
    latitude: '27.41667',
    longitude: '128.683334'
  },
  {
    id: '1858421',
    state_id: '43',
    name: '熊本市',
    name_en: 'Kumamoto',
    latitude: '32.789719',
    longitude: '130.741669'
  },
  {
    id: '1858428',
    state_id: '11',
    name: '熊谷市',
    name_en: 'Kumagaya',
    latitude: '36.133331',
    longitude: '139.383331'
  },
  {
    id: '1858445',
    state_id: '11',
    name: '久喜市',
    name_en: 'Kukichūō',
    latitude: '36.066669',
    longitude: '139.666672'
  },
  {
    id: '1858480',
    state_id: '13',
    name: '杉並区',
    name_en: 'Kugayama',
    latitude: '35.683331',
    longitude: '139.600006'
  },
  {
    id: '1858486',
    state_id: '35',
    name: '岩国市',
    name_en: 'Kugamachi',
    latitude: '34.083328',
    longitude: '132.083328'
  },
  {
    id: '1858492',
    state_id: '30',
    name: '九度山町',
    name_en: 'Kudoyama',
    latitude: '34.283329',
    longitude: '135.566666'
  },
  {
    id: '1858498',
    state_id: '35',
    name: '下松市',
    name_en: 'Kudamatsu',
    latitude: '34',
    longitude: '131.866669'
  },
  {
    id: '1858591',
    state_id: '23',
    name: '豊川市',
    name_en: 'Kozakai-chō',
    latitude: '34.799999',
    longitude: '137.358887'
  },
  {
    id: '1858667',
    state_id: '37',
    name: '琴平町',
    name_en: 'Kotohira',
    latitude: '34.183331',
    longitude: '133.816666'
  },
  {
    id: '1858681',
    state_id: '26',
    name: '長岡京市',
    name_en: 'Kōtari',
    latitude: '34.916672',
    longitude: '135.699997'
  },
  {
    id: '1858695',
    state_id: '16',
    name: '射水市',
    name_en: 'Kuragaki-kosugi',
    latitude: '36.716671',
    longitude: '137.100006'
  },
  {
    id: '1858707',
    state_id: '31',
    name: '境港市',
    name_en: 'Ko-shinozu',
    latitude: '35.5',
    longitude: '133.25'
  },
  {
    id: '1858729',
    state_id: '11',
    name: '越谷市',
    name_en: 'Koshigaya',
    latitude: '35.883331',
    longitude: '139.783325'
  },
  {
    id: '1858756',
    state_id: '22',
    name: '湖西市',
    name_en: 'Kosai-shi',
    latitude: '34.718399',
    longitude: '137.531754'
  },
  {
    id: '1858794',
    state_id: '11',
    name: '鴻巣市',
    name_en: 'Kōnosu',
    latitude: '36.049999',
    longitude: '139.516663'
  },
  {
    id: '1858836',
    state_id: '23',
    name: '江南市',
    name_en: 'Kōnan',
    latitude: '35.33165',
    longitude: '136.870422'
  },
  {
    id: '1858858',
    state_id: '20',
    name: '小諸市',
    name_en: 'Komoro',
    latitude: '36.316669',
    longitude: '138.433334'
  },
  {
    id: '1858866',
    state_id: '24',
    name: '菰野町',
    name_en: 'Komono',
    latitude: '35',
    longitude: '136.516663'
  },
  {
    id: '1858902',
    state_id: '36',
    name: '小松島市',
    name_en: 'Komatsushimachō',
    latitude: '34',
    longitude: '134.583328'
  },
  {
    id: '1858910',
    state_id: '17',
    name: '小松市',
    name_en: 'Komatsu',
    latitude: '36.40263',
    longitude: '136.450882'
  },
  {
    id: '1858926',
    state_id: '23',
    name: '小牧市',
    name_en: 'Komaki',
    latitude: '35.283329',
    longitude: '136.916672'
  },
  {
    id: '1858937',
    state_id: '10',
    name: '前橋市',
    name_en: 'Komagatamachi',
    latitude: '36.349998',
    longitude: '139.133331'
  },
  {
    id: '1858964',
    state_id: '13',
    name: '国分寺市',
    name_en: 'Kokubunji',
    latitude: '35.702221',
    longitude: '139.475555'
  },
  {
    id: '1858972',
    state_id: '46',
    name: '霧島市',
    name_en: 'Kokubu-matsuki',
    latitude: '31.73333',
    longitude: '130.766663'
  },
  {
    id: '1859088',
    state_id: '13',
    name: '小金井市',
    name_en: 'Koganei',
    latitude: '35.691391',
    longitude: '139.501114'
  },
  {
    id: '1859093',
    state_id: '08',
    name: '古河市',
    name_en: 'Koga',
    latitude: '36.183331',
    longitude: '139.716675'
  },
  {
    id: '1859094',
    state_id: '40',
    name: '古賀市',
    name_en: 'Koga',
    latitude: '33.73333',
    longitude: '130.466675'
  },
  {
    id: '1859100',
    state_id: '19',
    name: '甲府市',
    name_en: 'Kōfu',
    latitude: '35.663891',
    longitude: '138.568329'
  },
  {
    id: '1859113',
    state_id: '11',
    name: '本庄市',
    name_en: 'Kodamachō-kodamaminami',
    latitude: '36.183331',
    longitude: '139.133331'
  },
  {
    id: '1859116',
    state_id: '13',
    name: '小平市',
    name_en: 'Kodaira',
    latitude: '35.726391',
    longitude: '139.483887'
  },
  {
    id: '1859129',
    state_id: '23',
    name: '江南市',
    name_en: 'Kochino',
    latitude: '35.333328',
    longitude: '136.866669'
  },
  {
    id: '1859143',
    state_id: '22',
    name: '下田市',
    name_en: 'Kōchi',
    latitude: '34.700001',
    longitude: '138.933334'
  },
  {
    id: '1859146',
    state_id: '39',
    name: '高知市',
    name_en: 'Kochi',
    latitude: '33.559719',
    longitude: '133.531113'
  },
  {
    id: '1859171',
    state_id: '28',
    name: '神戸市',
    name_en: 'Kobe',
    latitude: '34.691299',
    longitude: '135.182999'
  },
  {
    id: '1859175',
    state_id: '45',
    name: '小林市',
    name_en: 'Kobayashi',
    latitude: '31.98333',
    longitude: '130.983337'
  },
  {
    id: '1859181',
    state_id: '45',
    name: '国富町',
    name_en: 'Kobaruyama',
    latitude: '32.01667',
    longitude: '131.283325'
  },
  {
    id: '1859224',
    state_id: '43',
    name: '益城町',
    name_en: 'Kiyama',
    latitude: '32.783329',
    longitude: '130.816666'
  },
  {
    id: '1859234',
    state_id: '44',
    name: '杵築市',
    name_en: 'Kitsuki',
    latitude: '33.416672',
    longitude: '131.616669'
  },
  {
    id: '1859262',
    state_id: '23',
    name: '小牧市',
    name_en: 'Kita-toyama',
    latitude: '35.26667',
    longitude: '136.916672'
  },
  {
    id: '1859307',
    state_id: '40',
    name: '北九州市',
    name_en: 'Kitakyushu',
    latitude: '33.833328',
    longitude: '130.833328'
  },
  {
    id: '1859319',
    state_id: '07',
    name: '喜多方市',
    name_en: 'Kitakata',
    latitude: '37.650002',
    longitude: '139.866669'
  },
  {
    id: '1859335',
    state_id: '25',
    name: '大津市',
    name_en: 'Kitahama',
    latitude: '35.166672',
    longitude: '135.916672'
  },
  {
    id: '1859363',
    state_id: '16',
    name: '富山市',
    name_en: 'Kitadaishin',
    latitude: '36.716671',
    longitude: '137.166672'
  },
  {
    id: '1859383',
    state_id: '27',
    name: '岸和田市',
    name_en: 'Kishiwada',
    latitude: '34.466671',
    longitude: '135.366669'
  },
  {
    id: '1859393',
    state_id: '12',
    name: '木更津市',
    name_en: 'Kisarazu',
    latitude: '35.374722',
    longitude: '139.922501'
  },
  {
    id: '1859400',
    state_id: '11',
    name: '加須市',
    name_en: 'Kisai',
    latitude: '36.099998',
    longitude: '139.583328'
  },
  {
    id: '1859405',
    state_id: '10',
    name: '桐生市',
    name_en: 'Kiryū',
    latitude: '36.400002',
    longitude: '139.333328'
  },
  {
    id: '1859420',
    state_id: '39',
    name: '室戸市',
    name_en: 'Kiragawachō',
    latitude: '33.333328',
    longitude: '134.100006'
  },
  {
    id: '1859462',
    state_id: '47',
    name: '金武町',
    name_en: 'Kin',
    latitude: '26.452221',
    longitude: '127.917778'
  },
  {
    id: '1859472',
    state_id: '30',
    name: '和歌山市',
    name_en: 'Kimiidera',
    latitude: '34.183331',
    longitude: '135.199997'
  },
  {
    id: '1859479',
    state_id: '14',
    name: '横浜市',
    name_en: 'Kikuna',
    latitude: '35.5',
    longitude: '139.633331'
  },
  {
    id: '1859492',
    state_id: '43',
    name: '菊池市',
    name_en: 'Kikuchi',
    latitude: '32.98333',
    longitude: '130.816666'
  },
  {
    id: '1859586',
    state_id: '11',
    name: '加須市',
    name_en: 'Kazo',
    latitude: '36.116669',
    longitude: '139.600006'
  },
  {
    id: '1859619',
    state_id: '42',
    name: '川棚町',
    name_en: 'Kawatana',
    latitude: '33.066669',
    longitude: '129.866669'
  },
  {
    id: '1859642',
    state_id: '14',
    name: '川崎市',
    name_en: 'Kawasaki',
    latitude: '35.520561',
    longitude: '139.717224'
  },
  {
    id: '1859647',
    state_id: '40',
    name: '添田町',
    name_en: 'Kawasaki',
    latitude: '33.583328',
    longitude: '130.833328'
  },
  {
    id: '1859675',
    state_id: '27',
    name: '池田市',
    name_en: 'Kawanishi',
    latitude: '34.816669',
    longitude: '135.416672'
  },
  {
    id: '1859687',
    state_id: '32',
    name: '川本町',
    name_en: 'Kawamoto',
    latitude: '34.98333',
    longitude: '132.5'
  },
  {
    id: '1859716',
    state_id: '29',
    name: '天川村',
    name_en: 'Kawai',
    latitude: '34.23333',
    longitude: '135.850006'
  },
  {
    id: '1859730',
    state_id: '11',
    name: '川口市',
    name_en: 'Kawaguchi',
    latitude: '35.805',
    longitude: '139.720566'
  },
  {
    id: '1859740',
    state_id: '11',
    name: '川越市',
    name_en: 'Kawagoe',
    latitude: '35.908611',
    longitude: '139.485275'
  },
  {
    id: '1859765',
    state_id: '18',
    name: '勝山市',
    name_en: 'Katsuyama',
    latitude: '36.061729',
    longitude: '136.501007'
  },
  {
    id: '1859842',
    state_id: '14',
    name: '藤沢市',
    name_en: 'Katase',
    latitude: '35.31694',
    longitude: '139.474442'
  },
  {
    id: '1859884',
    state_id: '11',
    name: '春日部市',
    name_en: 'Kasukabe',
    latitude: '35.976391',
    longitude: '139.753616'
  },
  {
    id: '1859891',
    state_id: '23',
    name: '春日井市',
    name_en: 'Kasugai',
    latitude: '35.24762',
    longitude: '136.97229'
  },
  {
    id: '1859895',
    state_id: '40',
    name: '春日市',
    name_en: 'Kasugabaru',
    latitude: '33.533329',
    longitude: '130.466675'
  },
  {
    id: '1859900',
    state_id: '41',
    name: '鹿島市',
    name_en: 'Kasuga',
    latitude: '33.033329',
    longitude: '130.050003'
  },
  {
    id: '1859908',
    state_id: '15',
    name: '柏崎市',
    name_en: 'Kashiwazaki',
    latitude: '37.366669',
    longitude: '138.550003'
  },
  {
    id: '1859913',
    state_id: '29',
    name: '御所市',
    name_en: 'Kashiwara',
    latitude: '34.450001',
    longitude: '135.766663'
  },
  {
    id: '1859914',
    state_id: '24',
    name: '大紀町',
    name_en: 'Kashiwano',
    latitude: '34.316669',
    longitude: '136.399994'
  },
  {
    id: '1859924',
    state_id: '12',
    name: '柏市',
    name_en: 'Kashiwa',
    latitude: '35.854439',
    longitude: '139.968887'
  },
  {
    id: '1859941',
    state_id: '41',
    name: '鹿島市',
    name_en: 'Kashima',
    latitude: '33.10611',
    longitude: '130.090561'
  },
  {
    id: '1859951',
    state_id: '29',
    name: '橿原市',
    name_en: 'Kashihara-shi',
    latitude: '34.508961',
    longitude: '135.792892'
  },
  {
    id: '1859952',
    state_id: '27',
    name: '藤井寺市',
    name_en: 'Kashihara',
    latitude: '34.583328',
    longitude: '135.616669'
  },
  {
    id: '1859964',
    state_id: '46',
    name: '南さつま市',
    name_en: 'Kaseda-shirakame',
    latitude: '31.41667',
    longitude: '130.316666'
  },
  {
    id: '1859990',
    state_id: '33',
    name: '笠岡市',
    name_en: 'Kasaoka',
    latitude: '34.5',
    longitude: '133.5'
  },
  {
    id: '1859998',
    state_id: '21',
    name: '笠松町',
    name_en: 'Kasamatsuchō',
    latitude: '35.366669',
    longitude: '136.766663'
  },
  {
    id: '1860031',
    state_id: '31',
    name: '鳥取市',
    name_en: 'Karochō',
    latitude: '35.533329',
    longitude: '134.183334'
  },
  {
    id: '1860034',
    state_id: '23',
    name: '刈谷市',
    name_en: 'Kariya',
    latitude: '34.98333',
    longitude: '136.983337'
  },
  {
    id: '1860039',
    state_id: '15',
    name: '刈羽村',
    name_en: 'Kariwa',
    latitude: '37.429749',
    longitude: '138.61879'
  },
  {
    id: '1860063',
    state_id: '41',
    name: '唐津市',
    name_en: 'Karatsu',
    latitude: '33.442501',
    longitude: '129.969727'
  },
  {
    id: '1860095',
    state_id: '41',
    name: '神埼市',
    name_en: 'Kanzakimachi-kanzaki',
    latitude: '33.299999',
    longitude: '130.366669'
  },
  {
    id: '1860098',
    state_id: '09',
    name: '鹿沼市',
    name_en: 'Kanuma',
    latitude: '36.549999',
    longitude: '139.733337'
  },
  {
    id: '1860112',
    state_id: '46',
    name: '鹿屋市',
    name_en: 'Kanoya',
    latitude: '31.383329',
    longitude: '130.850006'
  },
  {
    id: '1860122',
    state_id: '37',
    name: '観音寺市',
    name_en: 'Kan’onjichō',
    latitude: '34.116669',
    longitude: '133.649994'
  },
  {
    id: '1860176',
    state_id: '34',
    name: '福山市',
    name_en: 'Kannabechō-yahiro',
    latitude: '34.533329',
    longitude: '133.383331'
  },
  {
    id: '1860191',
    state_id: '23',
    name: '蟹江町',
    name_en: 'Kanie',
    latitude: '35.133331',
    longitude: '136.800003'
  },
  {
    id: '1860211',
    state_id: '10',
    name: '高崎市',
    name_en: 'Kanekomachi',
    latitude: '36.400002',
    longitude: '139'
  },
  {
    id: '1860234',
    state_id: '40',
    name: '苅田町',
    name_en: 'Kanda',
    latitude: '33.783329',
    longitude: '130.983337'
  },
  {
    id: '1860239',
    state_id: '18',
    name: 'あわら市',
    name_en: 'Kanazu',
    latitude: '36.213261',
    longitude: '136.230804'
  },
  {
    id: '1860243',
    state_id: '17',
    name: '金沢市',
    name_en: 'Kanazawa',
    latitude: '36.59444',
    longitude: '136.625565'
  },
  {
    id: '1860244',
    state_id: '14',
    name: '横浜市',
    name_en: 'Kanazawachō',
    latitude: '35.349998',
    longitude: '139.633331'
  },
  {
    id: '1860250',
    state_id: '30',
    name: '白浜町',
    name_en: 'Kanayama',
    latitude: '33.683331',
    longitude: '135.350006'
  },
  {
    id: '1860256',
    state_id: '22',
    name: '島田市',
    name_en: 'Kanaya',
    latitude: '34.816669',
    longitude: '138.133331'
  },
  {
    id: '1860293',
    state_id: '33',
    name: '岡山市',
    name_en: 'Mitsu-kanagawa',
    latitude: '34.799999',
    longitude: '133.933334'
  },
  {
    id: '1860310',
    state_id: '14',
    name: '小田原市',
    name_en: 'Kamonomiya',
    latitude: '35.283329',
    longitude: '139.183334'
  },
  {
    id: '1860321',
    state_id: '36',
    name: '吉野川市',
    name_en: 'Kamojimachō-jōgejima',
    latitude: '34.066669',
    longitude: '134.350006'
  },
  {
    id: '1860335',
    state_id: '33',
    name: '里庄町',
    name_en: 'Kamogatachō-kamogata',
    latitude: '34.533329',
    longitude: '133.583328'
  },
  {
    id: '1860341',
    state_id: '15',
    name: '加茂市',
    name_en: 'Kamo',
    latitude: '37.650002',
    longitude: '139.050003'
  },
  {
    id: '1860380',
    state_id: '32',
    name: '安来市',
    name_en: 'Hirosechō-kamiyamasa',
    latitude: '35.316669',
    longitude: '133.116669'
  },
  {
    id: '1860437',
    state_id: '13',
    name: '三鷹市',
    name_en: 'Kamirenjaku',
    latitude: '35.683331',
    longitude: '139.550003'
  },
  {
    id: '1860458',
    state_id: '09',
    name: '上三川町',
    name_en: 'Kaminokawa',
    latitude: '36.433331',
    longitude: '139.916672'
  },
  {
    id: '1860483',
    state_id: '34',
    name: '広島市',
    name_en: 'Kami-nagarekawachō',
    latitude: '34.400002',
    longitude: '132.449997'
  },
  {
    id: '1860521',
    state_id: '40',
    name: '北九州市',
    name_en: 'Kamikōjaku',
    latitude: '33.828579',
    longitude: '130.760193'
  },
  {
    id: '1860563',
    state_id: '16',
    name: '上市町',
    name_en: 'Kamiichi',
    latitude: '36.700001',
    longitude: '137.366669'
  },
  {
    id: '1860588',
    state_id: '28',
    name: '上郡町',
    name_en: 'Kamigōri',
    latitude: '34.866669',
    longitude: '134.366669'
  },
  {
    id: '1860626',
    state_id: '24',
    name: '亀山市',
    name_en: 'Kameyama',
    latitude: '34.849998',
    longitude: '136.449997'
  },
  {
    id: '1860635',
    state_id: '26',
    name: '亀岡市',
    name_en: 'Kameoka',
    latitude: '35',
    longitude: '135.583328'
  },
  {
    id: '1860648',
    state_id: '15',
    name: '新潟市',
    name_en: 'Kameda-honchō',
    latitude: '37.866669',
    longitude: '139.116669'
  },
  {
    id: '1860649',
    state_id: '13',
    name: '葛飾区',
    name_en: 'Kameari',
    latitude: '35.75',
    longitude: '139.850006'
  },
  {
    id: '1860672',
    state_id: '14',
    name: '鎌倉市',
    name_en: 'Kamakura',
    latitude: '35.308891',
    longitude: '139.550278'
  },
  {
    id: '1860704',
    state_id: '28',
    name: '加古川市',
    name_en: 'Kakogawachō-honmachi',
    latitude: '34.76667',
    longitude: '134.850006'
  },
  {
    id: '1860728',
    state_id: '22',
    name: '掛川市',
    name_en: 'Kakegawa',
    latitude: '34.76667',
    longitude: '138.016663'
  },
  {
    id: '1860735',
    state_id: '21',
    name: '各務原市',
    name_en: 'Kakamigahara',
    latitude: '35.416672',
    longitude: '136.866669'
  },
  {
    id: '1860748',
    state_id: '08',
    name: '鹿嶋市',
    name_en: 'Kashima-shi',
    latitude: '35.965359',
    longitude: '140.644745'
  },
  {
    id: '1860750',
    state_id: '46',
    name: '姶良市',
    name_en: 'Kajiki',
    latitude: '31.73333',
    longitude: '130.666672'
  },
  {
    id: '1860765',
    state_id: '27',
    name: '貝塚市',
    name_en: 'Kaizuka',
    latitude: '34.450001',
    longitude: '135.350006'
  },
  {
    id: '1860785',
    state_id: '30',
    name: '海南市',
    name_en: 'Kainan',
    latitude: '34.150002',
    longitude: '135.199997'
  },
  {
    id: '1860797',
    state_id: '33',
    name: '岡山市',
    name_en: 'Kaigandōri',
    latitude: '34.666672',
    longitude: '133.916672'
  },
  {
    id: '1860827',
    state_id: '46',
    name: '鹿児島市',
    name_en: 'Kagoshima',
    latitude: '31.560181',
    longitude: '130.558136'
  },
  {
    id: '1860841',
    state_id: '36',
    name: '徳島市',
    name_en: 'Kagasuno',
    latitude: '34.116669',
    longitude: '134.583328'
  },
  {
    id: '1860847',
    state_id: '47',
    name: '那覇市',
    name_en: 'Kagamizu',
    latitude: '26.200001',
    longitude: '127.650002'
  },
  {
    id: '1860871',
    state_id: '27',
    name: '門真市',
    name_en: 'Kadoma',
    latitude: '34.73333',
    longitude: '135.583328'
  },
  {
    id: '1860875',
    state_id: '45',
    name: '門川町',
    name_en: 'Kadogawa',
    latitude: '32.466671',
    longitude: '131.649994'
  },
  {
    id: '1860876',
    state_id: '33',
    name: '岡山市',
    name_en: 'Kado',
    latitude: '34.783329',
    longitude: '133.850006'
  },
  {
    id: '1860887',
    state_id: '23',
    name: '春日井市',
    name_en: 'Kachikawa',
    latitude: '35.23333',
    longitude: '136.949997'
  },
  {
    id: '1860941',
    state_id: '46',
    name: '中種子町',
    name_en: 'Jūrokuban',
    latitude: '30.6',
    longitude: '130.983337'
  },
  {
    id: '1861006',
    state_id: '15',
    name: '燕市',
    name_en: 'Jizōdō',
    latitude: '37.616669',
    longitude: '138.850006'
  },
  {
    id: '1861036',
    state_id: '27',
    name: '吹田市',
    name_en: 'Terauchi',
    latitude: '34.783329',
    longitude: '135.533325'
  },
  {
    id: '1861049',
    state_id: '34',
    name: '廿日市市',
    name_en: 'Jigozen',
    latitude: '34.333328',
    longitude: '132.316666'
  },
  {
    id: '1861060',
    state_id: '13',
    name: '千代田区',
    name_en: 'Japan',
    latitude: '35.68536',
    longitude: '139.753098'
  },
  {
    id: '1861084',
    state_id: '32',
    name: '出雲市',
    name_en: 'Izumo',
    latitude: '35.366669',
    longitude: '132.766663'
  },
  {
    id: '1861095',
    state_id: '27',
    name: '泉大津市',
    name_en: 'Izumiōtsu',
    latitude: '34.5',
    longitude: '135.399994'
  },
  {
    id: '1861107',
    state_id: '27',
    name: '和泉市',
    name_en: 'Izumi',
    latitude: '34.48333',
    longitude: '135.433334'
  },
  {
    id: '1861108',
    state_id: '46',
    name: '出水市',
    name_en: 'Izumi',
    latitude: '32.083328',
    longitude: '130.366669'
  },
  {
    id: '1861116',
    state_id: '42',
    name: '対馬市',
    name_en: 'Izuhara',
    latitude: '34.201389',
    longitude: '129.287216'
  },
  {
    id: '1861124',
    state_id: '47',
    name: '伊是名村',
    name_en: 'Izena',
    latitude: '26.916269',
    longitude: '127.933563'
  },
  {
    id: '1861164',
    state_id: '11',
    name: 'さいたま市',
    name_en: 'Iwatsuki',
    latitude: '35.94278',
    longitude: '139.69194'
  },
  {
    id: '1861171',
    state_id: '22',
    name: '磐田市',
    name_en: 'Iwata',
    latitude: '34.700001',
    longitude: '137.850006'
  },
  {
    id: '1861189',
    state_id: '30',
    name: '有田川町',
    name_en: 'Iwanogawa',
    latitude: '34.049999',
    longitude: '135.333328'
  },
  {
    id: '1861202',
    state_id: '28',
    name: 'たつの市',
    name_en: 'Mitsuchō-iwami',
    latitude: '34.783329',
    longitude: '134.533325'
  },
  {
    id: '1861207',
    state_id: '23',
    name: '岩倉市',
    name_en: 'Iwakura',
    latitude: '35.283329',
    longitude: '136.866669'
  },
  {
    id: '1861212',
    state_id: '35',
    name: '岩国市',
    name_en: 'Iwakuni',
    latitude: '34.150002',
    longitude: '132.183334'
  },
  {
    id: '1861223',
    state_id: '08',
    name: '坂東市',
    name_en: 'Iwai',
    latitude: '36.049999',
    longitude: '139.899994'
  },
  {
    id: '1861244',
    state_id: '30',
    name: '岩出市',
    name_en: 'Iwade',
    latitude: '34.25',
    longitude: '135.316666'
  },
  {
    id: '1861261',
    state_id: '13',
    name: 'あきる野市',
    name_en: 'Itsukaichi',
    latitude: '35.725281',
    longitude: '139.217773'
  },
  {
    id: '1861280',
    state_id: '47',
    name: '糸満市',
    name_en: 'Itoman',
    latitude: '26.12472',
    longitude: '127.669441'
  },
  {
    id: '1861285',
    state_id: '15',
    name: '糸魚川市',
    name_en: 'Itoigawa',
    latitude: '37.033329',
    longitude: '137.850006'
  },
  {
    id: '1861290',
    state_id: '22',
    name: '伊東市',
    name_en: 'Itō',
    latitude: '34.966671',
    longitude: '139.083328'
  },
  {
    id: '1861296',
    state_id: '40',
    name: '福岡市',
    name_en: 'Itazuke',
    latitude: '33.566669',
    longitude: '130.449997'
  },
  {
    id: '1861305',
    state_id: '36',
    name: '三好市',
    name_en: 'Ikedachō-itano',
    latitude: '34.01667',
    longitude: '133.783325'
  },
  {
    id: '1861310',
    state_id: '28',
    name: '伊丹市',
    name_en: 'Itami',
    latitude: '34.784271',
    longitude: '135.40126'
  },
  {
    id: '1861314',
    state_id: '33',
    name: '岡山市',
    name_en: 'Itakura',
    latitude: '34.666672',
    longitude: '133.833328'
  },
  {
    id: '1861336',
    state_id: '28',
    name: '宝塚市',
    name_en: 'Isoshi',
    latitude: '34.79689',
    longitude: '135.355606'
  },
  {
    id: '1861383',
    state_id: '23',
    name: '西尾市',
    name_en: 'Ishiki',
    latitude: '34.799999',
    longitude: '137.016663'
  },
  {
    id: '1861393',
    state_id: '47',
    name: 'うるま市',
    name_en: 'Ishikawa',
    latitude: '26.42333',
    longitude: '127.821388'
  },
  {
    id: '1861400',
    state_id: '36',
    name: '石井町',
    name_en: 'Ishii',
    latitude: '34.066669',
    longitude: '134.433334'
  },
  {
    id: '1861406',
    state_id: '08',
    name: '常総市',
    name_en: 'Ishige',
    latitude: '36.116669',
    longitude: '139.966675'
  },
  {
    id: '1861416',
    state_id: '47',
    name: '石垣市',
    name_en: 'Ishigaki',
    latitude: '24.34478',
    longitude: '124.157173'
  },
  {
    id: '1861436',
    state_id: '10',
    name: '伊勢崎市',
    name_en: 'Isesaki',
    latitude: '36.316669',
    longitude: '139.199997'
  },
  {
    id: '1861449',
    state_id: '14',
    name: '伊勢原市',
    name_en: 'Isehara',
    latitude: '35.39056',
    longitude: '139.307785'
  },
  {
    id: '1861450',
    state_id: '24',
    name: '伊勢市',
    name_en: 'Ise',
    latitude: '34.48333',
    longitude: '136.699997'
  },
  {
    id: '1861454',
    state_id: '19',
    name: '笛吹市',
    name_en: 'Isawa',
    latitude: '35.650002',
    longitude: '138.633331'
  },
  {
    id: '1861464',
    state_id: '42',
    name: '諫早市',
    name_en: 'Isahaya',
    latitude: '32.84111',
    longitude: '130.04306'
  },
  {
    id: '1861528',
    state_id: '23',
    name: '犬山市',
    name_en: 'Inuyama',
    latitude: '35.379951',
    longitude: '136.942947'
  },
  {
    id: '1861584',
    state_id: '39',
    name: 'いの町',
    name_en: 'Ino',
    latitude: '33.549999',
    longitude: '133.433334'
  },
  {
    id: '1861586',
    state_id: '34',
    name: '尾道市',
    name_en: 'Innoshima',
    latitude: '34.283329',
    longitude: '133.183334'
  },
  {
    id: '1861598',
    state_id: '42',
    name: '壱岐市',
    name_en: 'Ishidachō-indōjiura',
    latitude: '33.73333',
    longitude: '129.75'
  },
  {
    id: '1861602',
    state_id: '23',
    name: '稲沢市',
    name_en: 'Inazawa',
    latitude: '35.25',
    longitude: '136.783325'
  },
  {
    id: '1861641',
    state_id: '20',
    name: '伊那市',
    name_en: 'Ina',
    latitude: '35.82756',
    longitude: '137.953781'
  },
  {
    id: '1861642',
    state_id: '13',
    name: 'あきる野市',
    name_en: 'Ina',
    latitude: '35.716671',
    longitude: '139.25'
  },
  {
    id: '1861661',
    state_id: '32',
    name: '隠岐の島町',
    name_en: 'Imazu',
    latitude: '36.166672',
    longitude: '133.316666'
  },
  {
    id: '1861677',
    state_id: '41',
    name: '伊万里市',
    name_en: 'Imarichō-kō',
    latitude: '33.26667',
    longitude: '129.883331'
  },
  {
    id: '1861699',
    state_id: '09',
    name: '日光市',
    name_en: 'Imaichi',
    latitude: '36.716671',
    longitude: '139.683334'
  },
  {
    id: '1861749',
    state_id: '29',
    name: '生駒市',
    name_en: 'Ikoma',
    latitude: '34.683331',
    longitude: '135.699997'
  },
  {
    id: '1861793',
    state_id: '20',
    name: '池田町',
    name_en: 'Ikeda',
    latitude: '36.416672',
    longitude: '137.883331'
  },
  {
    id: '1861795',
    state_id: '27',
    name: '池田市',
    name_en: 'Ikeda',
    latitude: '34.822079',
    longitude: '135.429794'
  },
  {
    id: '1861797',
    state_id: '22',
    name: '磐田市',
    name_en: 'Ikeda',
    latitude: '34.73333',
    longitude: '137.816666'
  },
  {
    id: '1861799',
    state_id: '36',
    name: '三好市',
    name_en: 'Ikedachō',
    latitude: '34.01667',
    longitude: '133.800003'
  },
  {
    id: '1861816',
    state_id: '28',
    name: '太子町',
    name_en: 'Ikaruga',
    latitude: '34.833328',
    longitude: '134.583328'
  },
  {
    id: '1861825',
    state_id: '46',
    name: '日置市',
    name_en: 'Ijūin',
    latitude: '31.616671',
    longitude: '130.399994'
  },
  {
    id: '1861835',
    state_id: '40',
    name: '飯塚市',
    name_en: 'Iizuka',
    latitude: '33.633331',
    longitude: '130.683334'
  },
  {
    id: '1861838',
    state_id: '20',
    name: '飯山市',
    name_en: 'Iiyama',
    latitude: '36.849998',
    longitude: '138.366669'
  },
  {
    id: '1861853',
    state_id: '20',
    name: '飯田市',
    name_en: 'Iijima',
    latitude: '35.299999',
    longitude: '137.883331'
  },
  {
    id: '1861864',
    state_id: '20',
    name: '飯田市',
    name_en: 'Iida',
    latitude: '35.51965',
    longitude: '137.82074'
  },
  {
    id: '1861901',
    state_id: '24',
    name: '伊賀市',
    name_en: 'Iga-shi',
    latitude: '34.768551',
    longitude: '136.130127'
  },
  {
    id: '1861903',
    state_id: '45',
    name: '延岡市',
    name_en: 'Ifukugata',
    latitude: '32.51667',
    longitude: '131.666672'
  },
  {
    id: '1861949',
    state_id: '23',
    name: '一宮市',
    name_en: 'Ichinomiya',
    latitude: '35.299999',
    longitude: '136.800003'
  },
  {
    id: '1861950',
    state_id: '36',
    name: '徳島市',
    name_en: 'Ichinomiyachō',
    latitude: '34.033329',
    longitude: '134.466675'
  },
  {
    id: '1861968',
    state_id: '12',
    name: '市川市',
    name_en: 'Ichikawa',
    latitude: '35.719719',
    longitude: '139.924713'
  },
  {
    id: '1862010',
    state_id: '46',
    name: '指宿市',
    name_en: 'Ibusuki',
    latitude: '31.23333',
    longitude: '130.649994'
  },
  {
    id: '1862034',
    state_id: '33',
    name: '井原市',
    name_en: 'Ibara',
    latitude: '34.599998',
    longitude: '133.466675'
  },
  {
    id: '1862098',
    state_id: '20',
    name: '安曇野市',
    name_en: 'Hotaka',
    latitude: '36.3396',
    longitude: '137.882538'
  },
  {
    id: '1862136',
    state_id: '29',
    name: '斑鳩町',
    name_en: 'Hōryūji',
    latitude: '34.616669',
    longitude: '135.733337'
  },
  {
    id: '1862143',
    state_id: '13',
    name: '杉並区',
    name_en: 'Horinouchi',
    latitude: '35.683331',
    longitude: '139.649994'
  },
  {
    id: '1862197',
    state_id: '21',
    name: '岐阜市',
    name_en: 'Honjōchō',
    latitude: '35.416672',
    longitude: '136.75'
  },
  {
    id: '1862198',
    state_id: '11',
    name: '本庄市',
    name_en: 'Honjō',
    latitude: '36.23333',
    longitude: '139.183334'
  },
  {
    id: '1862230',
    state_id: '43',
    name: '天草市',
    name_en: 'Hondomachi-hondo',
    latitude: '32.450001',
    longitude: '130.199997'
  },
  {
    id: '1862291',
    state_id: '12',
    name: '館山市',
    name_en: 'Hōjō',
    latitude: '34.98333',
    longitude: '139.866669'
  },
  {
    id: '1862292',
    state_id: '28',
    name: '加西市',
    name_en: 'Hōjōchō-hōjō',
    latitude: '34.933331',
    longitude: '134.833328'
  },
  {
    id: '1862302',
    state_id: '35',
    name: '防府市',
    name_en: 'Hōfu',
    latitude: '34.049999',
    longitude: '131.566666'
  },
  {
    id: '1862352',
    state_id: '43',
    name: '人吉市',
    name_en: 'Hitoyoshi',
    latitude: '32.216671',
    longitude: '130.75'
  },
  {
    id: '1862373',
    state_id: '44',
    name: '日田市',
    name_en: 'Hita',
    latitude: '33.316669',
    longitude: '130.933334'
  },
  {
    id: '1862389',
    state_id: '24',
    name: '津市',
    name_en: 'Hisai-motomachi',
    latitude: '34.666672',
    longitude: '136.466675'
  },
  {
    id: '1862415',
    state_id: '34',
    name: '広島市',
    name_en: 'Hiroshima',
    latitude: '34.396271',
    longitude: '132.459366'
  },
  {
    id: '1862444',
    state_id: '44',
    name: '日田市',
    name_en: 'Hirokawa',
    latitude: '33.116669',
    longitude: '130.966675'
  },
  {
    id: '1862462',
    state_id: '14',
    name: '平塚市',
    name_en: 'Hiratsuka',
    latitude: '35.323059',
    longitude: '139.342224'
  },
  {
    id: '1862471',
    state_id: '32',
    name: '出雲市',
    name_en: 'Hiratachō',
    latitude: '35.433331',
    longitude: '132.816666'
  },
  {
    id: '1862505',
    state_id: '47',
    name: '宮古島市',
    name_en: 'Hirara',
    latitude: '24.799999',
    longitude: '125.283333'
  },
  {
    id: '1862513',
    state_id: '35',
    name: '平生町',
    name_en: 'Hirao',
    latitude: '33.938332',
    longitude: '132.071671'
  },
  {
    id: '1862539',
    state_id: '11',
    name: 'さいたま市',
    name_en: 'Hirakata',
    latitude: '35.933331',
    longitude: '139.550003'
  },
  {
    id: '1862540',
    state_id: '27',
    name: '枚方市',
    name_en: 'Hirakata',
    latitude: '34.813519',
    longitude: '135.649139'
  },
  {
    id: '1862554',
    state_id: '42',
    name: '長崎市',
    name_en: 'Hiradogoyamachi',
    latitude: '32.75',
    longitude: '129.866669'
  },
  {
    id: '1862555',
    state_id: '42',
    name: '平戸市',
    name_en: 'Hirado',
    latitude: '33.359718',
    longitude: '129.553055'
  },
  {
    id: '1862569',
    state_id: '46',
    name: '日置市',
    name_en: 'Hioki',
    latitude: '31.58333',
    longitude: '130.350006'
  },
  {
    id: '1862588',
    state_id: '45',
    name: '日之影町',
    name_en: 'Hinokage',
    latitude: '32.650002',
    longitude: '131.399994'
  },
  {
    id: '1862599',
    state_id: '13',
    name: '日野市',
    name_en: 'Hino',
    latitude: '35.673061',
    longitude: '139.400284'
  },
  {
    id: '1862601',
    state_id: '25',
    name: '日野町',
    name_en: 'Hino',
    latitude: '35',
    longitude: '136.25'
  },
  {
    id: '1862612',
    state_id: '16',
    name: '氷見市',
    name_en: 'Himimachi',
    latitude: '36.849998',
    longitude: '136.983337'
  },
  {
    id: '1862627',
    state_id: '28',
    name: '姫路市',
    name_en: 'Himeji',
    latitude: '34.816669',
    longitude: '134.699997'
  },
  {
    id: '1862633',
    state_id: '40',
    name: '添田町',
    name_en: 'Hikosan',
    latitude: '33.48333',
    longitude: '130.899994'
  },
  {
    id: '1862636',
    state_id: '25',
    name: '彦根市',
    name_en: 'Hikone',
    latitude: '35.25',
    longitude: '136.25'
  },
  {
    id: '1862689',
    state_id: '44',
    name: '日出町',
    name_en: 'Hiji',
    latitude: '33.366669',
    longitude: '131.533325'
  },
  {
    id: '1862732',
    state_id: '41',
    name: '小城市',
    name_en: 'Suminoe-higashi',
    latitude: '33.200001',
    longitude: '130.199997'
  },
  {
    id: '1862739',
    state_id: '13',
    name: '品川区',
    name_en: 'Higashishinagawa',
    latitude: '35.599998',
    longitude: '139.733337'
  },
  {
    id: '1862752',
    state_id: '27',
    name: '東大阪市',
    name_en: 'Higashi-ōsaka',
    latitude: '34.666672',
    longitude: '135.583328'
  },
  {
    id: '1862782',
    state_id: '34',
    name: '三原市',
    name_en: 'Higashimachi',
    latitude: '34.400002',
    longitude: '133.083328'
  },
  {
    id: '1862818',
    state_id: '15',
    name: '新潟市',
    name_en: 'Higashiboridōri',
    latitude: '37.916672',
    longitude: '139.050003'
  },
  {
    id: '1862845',
    state_id: '32',
    name: '松江市',
    name_en: 'Higashiasahimachi',
    latitude: '35.466671',
    longitude: '133.066666'
  },
  {
    id: '1862855',
    state_id: '47',
    name: '粟国村',
    name_en: 'Higashi',
    latitude: '26.576389',
    longitude: '127.22139'
  },
  {
    id: '1862912',
    state_id: '23',
    name: '碧南市',
    name_en: 'Hekinan',
    latitude: '34.883331',
    longitude: '136.983337'
  },
  {
    id: '1862944',
    state_id: '22',
    name: '沼津市',
    name_en: 'Heda',
    latitude: '34.966671',
    longitude: '138.766663'
  },
  {
    id: '1862971',
    state_id: '33',
    name: '早島町',
    name_en: 'Hayashima',
    latitude: '34.599998',
    longitude: '133.833328'
  },
  {
    id: '1862978',
    state_id: '14',
    name: '横須賀市',
    name_en: 'Hayashi',
    latitude: '35.216671',
    longitude: '139.633331'
  },
  {
    id: '1862992',
    state_id: '14',
    name: '葉山町',
    name_en: 'Hayama',
    latitude: '35.26667',
    longitude: '139.583328'
  },
  {
    id: '1862998',
    state_id: '19',
    name: '早川町',
    name_en: 'Hayakawa',
    latitude: '35.450001',
    longitude: '138.350006'
  },
  {
    id: '1863018',
    state_id: '34',
    name: '廿日市市',
    name_en: 'Hatsukaichi',
    latitude: '34.349998',
    longitude: '132.333328'
  },
  {
    id: '1863023',
    state_id: '11',
    name: '川口市',
    name_en: 'Hatogaya-honchō',
    latitude: '35.834171',
    longitude: '139.736389'
  },
  {
    id: '1863051',
    state_id: '35',
    name: '下関市',
    name_en: 'Hatabuchō',
    latitude: '33.98333',
    longitude: '130.933334'
  },
  {
    id: '1863077',
    state_id: '29',
    name: '広陵町',
    name_en: 'Hashio',
    latitude: '34.566669',
    longitude: '135.75'
  },
  {
    id: '1863082',
    state_id: '30',
    name: '橋本市',
    name_en: 'Hashimoto',
    latitude: '34.316669',
    longitude: '135.616669'
  },
  {
    id: '1863130',
    state_id: '23',
    name: '岡崎市',
    name_en: 'Harisakichō',
    latitude: '34.916672',
    longitude: '137.149994'
  },
  {
    id: '1863140',
    state_id: '11',
    name: 'さいたま市',
    name_en: 'Harigaya',
    latitude: '35.866669',
    longitude: '139.633331'
  },
  {
    id: '1863161',
    state_id: '20',
    name: '川上村',
    name_en: 'Hara',
    latitude: '35.966671',
    longitude: '138.550003'
  },
  {
    id: '1863173',
    state_id: '11',
    name: '羽生市',
    name_en: 'Hanyū',
    latitude: '36.166672',
    longitude: '139.533325'
  },
  {
    id: '1863183',
    state_id: '11',
    name: '飯能市',
    name_en: 'Hannō',
    latitude: '35.85194',
    longitude: '139.318054'
  },
  {
    id: '1863209',
    state_id: '23',
    name: '半田市',
    name_en: 'Handa',
    latitude: '34.883331',
    longitude: '136.933334'
  },
  {
    id: '1863218',
    state_id: '22',
    name: '焼津市',
    name_en: 'Hanazawa',
    latitude: '34.900002',
    longitude: '138.333328'
  },
  {
    id: '1863219',
    state_id: '09',
    name: '宇都宮市',
    name_en: 'Hanawadamachi',
    latitude: '36.549999',
    longitude: '139.883331'
  },
  {
    id: '1863250',
    state_id: '43',
    name: '熊本市',
    name_en: 'Hanabatachō',
    latitude: '32.799999',
    longitude: '130.699997'
  },
  {
    id: '1863276',
    state_id: '30',
    name: '美浜町',
    name_en: 'Hamanose',
    latitude: '33.883331',
    longitude: '135.149994'
  },
  {
    id: '1863279',
    state_id: '46',
    name: '霧島市',
    name_en: 'Hamanoichi',
    latitude: '31.716669',
    longitude: '130.733337'
  },
  {
    id: '1863282',
    state_id: '06',
    name: '酒田市',
    name_en: 'Hamanaka',
    latitude: '38.816669',
    longitude: '139.783325'
  },
  {
    id: '1863289',
    state_id: '22',
    name: '浜松市',
    name_en: 'Hamamatsu',
    latitude: '34.700001',
    longitude: '137.733337'
  },
  {
    id: '1863293',
    state_id: '22',
    name: '浜松市',
    name_en: 'Hamakita',
    latitude: '34.799999',
    longitude: '137.783325'
  },
  {
    id: '1863310',
    state_id: '32',
    name: '浜田市',
    name_en: 'Hamada',
    latitude: '34.883331',
    longitude: '132.083328'
  },
  {
    id: '1863341',
    state_id: '17',
    name: '羽咋市',
    name_en: 'Hakui',
    latitude: '36.883331',
    longitude: '136.783325'
  },
  {
    id: '1863356',
    state_id: '13',
    name: '瑞穂町',
    name_en: 'Hakonegasaki',
    latitude: '35.76944',
    longitude: '139.351105'
  },
  {
    id: '1863357',
    state_id: '14',
    name: '箱根町',
    name_en: 'Hakone',
    latitude: '35.183331',
    longitude: '139.033325'
  },
  {
    id: '1863385',
    state_id: '40',
    name: '筑後市',
    name_en: 'Hainuzuka',
    latitude: '33.200001',
    longitude: '130.5'
  },
  {
    id: '1863398',
    state_id: '29',
    name: '宇陀市',
    name_en: 'Haibara-akanedai',
    latitude: '34.533329',
    longitude: '135.949997'
  },
  {
    id: '1863410',
    state_id: '16',
    name: '富山市',
    name_en: 'Hagiwara',
    latitude: '36.666672',
    longitude: '137.199997'
  },
  {
    id: '1863418',
    state_id: '35',
    name: '萩市',
    name_en: 'Hagi',
    latitude: '34.400002',
    longitude: '131.416672'
  },
  {
    id: '1863426',
    state_id: '47',
    name: 'うるま市',
    name_en: 'Katsuren-haebaru',
    latitude: '26.336941',
    longitude: '127.871941'
  },
  {
    id: '1863431',
    state_id: '14',
    name: '秦野市',
    name_en: 'Hadano',
    latitude: '35.371109',
    longitude: '139.223618'
  },
  {
    id: '1863440',
    state_id: '13',
    name: '八王子市',
    name_en: 'Hachiōji',
    latitude: '35.65583',
    longitude: '139.323883'
  },
  {
    id: '1863451',
    state_id: '25',
    name: '近江八幡市',
    name_en: 'Ōmihachiman',
    latitude: '35.128609',
    longitude: '136.097595'
  },
  {
    id: '1863475',
    state_id: '40',
    name: '中間市',
    name_en: 'Habu',
    latitude: '33.816669',
    longitude: '130.699997'
  },
  {
    id: '1863482',
    state_id: '11',
    name: '行田市',
    name_en: 'Gyōda',
    latitude: '36.133331',
    longitude: '139.449997'
  },
  {
    id: '1863495',
    state_id: '47',
    name: 'うるま市',
    name_en: 'Gushikawa',
    latitude: '26.354441',
    longitude: '127.868607'
  },
  {
    id: '1863516',
    state_id: '23',
    name: '豊川市',
    name_en: 'Goyuchō',
    latitude: '34.833328',
    longitude: '137.316666'
  },
  {
    id: '1863521',
    state_id: '32',
    name: '江津市',
    name_en: 'Gōtsuchō',
    latitude: '35',
    longitude: '132.216675'
  },
  {
    id: '1863528',
    state_id: '22',
    name: '御殿場市',
    name_en: 'Gotenba',
    latitude: '35.299999',
    longitude: '138.933334'
  },
  {
    id: '1863540',
    state_id: '15',
    name: '五泉市',
    name_en: 'Gosen',
    latitude: '37.73333',
    longitude: '139.166672'
  },
  {
    id: '1863541',
    state_id: '29',
    name: '御所市',
    name_en: 'Gose',
    latitude: '34.450001',
    longitude: '135.733337'
  },
  {
    id: '1863592',
    state_id: '29',
    name: '五條市',
    name_en: 'Gojō',
    latitude: '34.349998',
    longitude: '135.699997'
  },
  {
    id: '1863611',
    state_id: '21',
    name: '神戸町',
    name_en: 'Gōdo',
    latitude: '35.416672',
    longitude: '136.600006'
  },
  {
    id: '1863614',
    state_id: '30',
    name: '美浜町',
    name_en: 'Gobō',
    latitude: '33.883331',
    longitude: '135.149994'
  },
  {
    id: '1863625',
    state_id: '47',
    name: '宜野座村',
    name_en: 'Ginoza',
    latitude: '26.47611',
    longitude: '127.973061'
  },
  {
    id: '1863627',
    state_id: '47',
    name: '宜野湾市',
    name_en: 'Ginowan',
    latitude: '26.26265',
    longitude: '127.761467'
  },
  {
    id: '1863641',
    state_id: '21',
    name: '岐阜市',
    name_en: 'Gifu-shi',
    latitude: '35.422909',
    longitude: '136.760391'
  },
  {
    id: '1863683',
    state_id: '47',
    name: '宜野湾市',
    name_en: 'Ganeko',
    latitude: '26.25601',
    longitude: '127.75351'
  },
  {
    id: '1863693',
    state_id: '23',
    name: '蒲郡市',
    name_en: 'Gamagōri',
    latitude: '34.833328',
    longitude: '137.233337'
  },
  {
    id: '1863713',
    state_id: '12',
    name: '富津市',
    name_en: 'Futtsu',
    latitude: '35.305279',
    longitude: '139.818604'
  },
  {
    id: '1863788',
    state_id: '26',
    name: '京都市',
    name_en: 'Fushimi',
    latitude: '34.935551',
    longitude: '135.761322'
  },
  {
    id: '1863822',
    state_id: '27',
    name: '門真市',
    name_en: 'Kōfukuchō',
    latitude: '34.73333',
    longitude: '135.600006'
  },
  {
    id: '1863834',
    state_id: '34',
    name: '広島市',
    name_en: 'Furuichi',
    latitude: '34.450001',
    longitude: '132.466675'
  },
  {
    id: '1863836',
    state_id: '44',
    name: '国東市',
    name_en: 'Furuichi',
    latitude: '33.48333',
    longitude: '131.716675'
  },
  {
    id: '1863837',
    state_id: '47',
    name: '読谷村',
    name_en: 'Furugen',
    latitude: '26.368589',
    longitude: '127.751106'
  },
  {
    id: '1863866',
    state_id: '30',
    name: '岩出市',
    name_en: 'Funato',
    latitude: '34.25',
    longitude: '135.316666'
  },
  {
    id: '1863905',
    state_id: '12',
    name: '船橋市',
    name_en: 'Honchō',
    latitude: '35.693062',
    longitude: '139.983337'
  },
  {
    id: '1863917',
    state_id: '34',
    name: '福山市',
    name_en: 'Fukuyama',
    latitude: '34.48333',
    longitude: '133.366669'
  },
  {
    id: '1863945',
    state_id: '22',
    name: '袋井市',
    name_en: 'Fukuroi',
    latitude: '34.75',
    longitude: '137.916672'
  },
  {
    id: '1863953',
    state_id: '28',
    name: '南あわじ市',
    name_en: 'Fukura',
    latitude: '34.25',
    longitude: '134.716675'
  },
  {
    id: '1863967',
    state_id: '40',
    name: '福岡市',
    name_en: 'Fukuoka',
    latitude: '33.606392',
    longitude: '130.41806'
  },
  {
    id: '1863973',
    state_id: '16',
    name: '南砺市',
    name_en: 'Fukumitsu',
    latitude: '36.549999',
    longitude: '136.866669'
  },
  {
    id: '1863978',
    state_id: '40',
    name: '福津市',
    name_en: 'Nishifukuma',
    latitude: '33.76667',
    longitude: '130.466675'
  },
  {
    id: '1863985',
    state_id: '18',
    name: '福井市',
    name_en: 'Fukui-shi',
    latitude: '36.06443',
    longitude: '136.222565'
  },
  {
    id: '1864009',
    state_id: '26',
    name: '福知山市',
    name_en: 'Fukuchiyama',
    latitude: '35.299999',
    longitude: '135.116669'
  },
  {
    id: '1864025',
    state_id: '11',
    name: '鴻巣市',
    name_en: 'Fukiage-fujimi',
    latitude: '36.099998',
    longitude: '139.449997'
  },
  {
    id: '1864031',
    state_id: '11',
    name: '深谷市',
    name_en: 'Fukayachō',
    latitude: '36.200001',
    longitude: '139.283325'
  },
  {
    id: '1864092',
    state_id: '14',
    name: '藤沢市',
    name_en: 'Fujisawa',
    latitude: '35.341942',
    longitude: '139.470001'
  },
  {
    id: '1864098',
    state_id: '09',
    name: '栃木市',
    name_en: 'Fujioka',
    latitude: '36.25',
    longitude: '139.649994'
  },
  {
    id: '1864099',
    state_id: '10',
    name: '藤岡市',
    name_en: 'Fujioka',
    latitude: '36.25',
    longitude: '139.083328'
  },
  {
    id: '1864105',
    state_id: '22',
    name: '富士宮市',
    name_en: 'Fujinomiya',
    latitude: '35.216671',
    longitude: '138.616669'
  },
  {
    id: '1864132',
    state_id: '22',
    name: '藤枝市',
    name_en: 'Fujieda',
    latitude: '34.866669',
    longitude: '138.266663'
  },
  {
    id: '1864134',
    state_id: '22',
    name: '富士市',
    name_en: 'Fuji',
    latitude: '35.166672',
    longitude: '138.683334'
  },
  {
    id: '1864154',
    state_id: '13',
    name: '府中市',
    name_en: 'Fuchu',
    latitude: '35.666672',
    longitude: '139.483337'
  },
  {
    id: '1864155',
    state_id: '34',
    name: '府中市',
    name_en: 'Fuchūchō',
    latitude: '34.566669',
    longitude: '133.233337'
  },
  {
    id: '1864180',
    state_id: '19',
    name: '甲州市',
    name_en: 'Enzan',
    latitude: '35.700001',
    longitude: '138.733337'
  },
  {
    id: '1864231',
    state_id: '13',
    name: '練馬区',
    name_en: 'Egota',
    latitude: '35.73333',
    longitude: '139.666672'
  },
  {
    id: '1864237',
    state_id: '25',
    name: '近江八幡市',
    name_en: 'Egashira',
    latitude: '35.116669',
    longitude: '136.050003'
  },
  {
    id: '1864240',
    state_id: '18',
    name: '坂井市',
    name_en: 'Edome-naka',
    latitude: '36.138432',
    longitude: '136.212357'
  },
  {
    id: '1864263',
    state_id: '25',
    name: '愛荘町',
    name_en: 'Echigawa',
    latitude: '35.166672',
    longitude: '136.199997'
  },
  {
    id: '1864338',
    state_id: '14',
    name: '相模原市',
    name_en: 'Dōshi',
    latitude: '35.583328',
    longitude: '139.216675'
  },
  {
    id: '1864381',
    state_id: '14',
    name: '川崎市',
    name_en: 'Den’enchōfu',
    latitude: '35.583328',
    longitude: '139.666672'
  },
  {
    id: '1864416',
    state_id: '27',
    name: '大東市',
    name_en: 'Daitōchō',
    latitude: '34.700001',
    longitude: '135.616669'
  },
  {
    id: '1864427',
    state_id: '31',
    name: '大山町',
    name_en: 'Daisen',
    latitude: '35.383331',
    longitude: '133.533325'
  },
  {
    id: '1864470',
    state_id: '35',
    name: '防府市',
    name_en: 'Daidō',
    latitude: '34.049999',
    longitude: '131.483337'
  },
  {
    id: '1864518',
    state_id: '13',
    name: '調布市',
    name_en: 'Chōfu',
    latitude: '35.65556',
    longitude: '139.552216'
  },
  {
    id: '1864523',
    state_id: '31',
    name: '智頭町',
    name_en: 'Chizu',
    latitude: '35.26667',
    longitude: '134.233337'
  },
  {
    id: '1864529',
    state_id: '13',
    name: '千代田区',
    name_en: 'Chiyoda-ku',
    latitude: '35.694019',
    longitude: '139.753632'
  },
  {
    id: '1864549',
    state_id: '23',
    name: '知立市',
    name_en: 'Chiryū',
    latitude: '35',
    longitude: '137.033325'
  },
  {
    id: '1864557',
    state_id: '20',
    name: '茅野市',
    name_en: 'Chino',
    latitude: '35.9944',
    longitude: '138.154282'
  },
  {
    id: '1864572',
    state_id: '40',
    name: '筑紫野市',
    name_en: 'Chikushino-shi',
    latitude: '33.496311',
    longitude: '130.515594'
  },
  {
    id: '1864624',
    state_id: '14',
    name: '茅ヶ崎市',
    name_en: 'Chigasaki',
    latitude: '35.326111',
    longitude: '139.403885'
  },
  {
    id: '1864637',
    state_id: '11',
    name: '秩父市',
    name_en: 'Chichibu',
    latitude: '35.99028',
    longitude: '139.076385'
  },
  {
    id: '1864652',
    state_id: '47',
    name: '北谷町',
    name_en: 'Chatan',
    latitude: '26.309441',
    longitude: '127.770279'
  },
  {
    id: '1864655',
    state_id: '46',
    name: '与論町',
    name_en: 'Chabana',
    latitude: '27.049999',
    longitude: '128.416672'
  },
  {
    id: '1864660',
    state_id: '40',
    name: '豊前市',
    name_en: 'Buzen-shi',
    latitude: '33.567429',
    longitude: '131.085785'
  },
  {
    id: '1864750',
    state_id: '44',
    name: '別府市',
    name_en: 'Beppu',
    latitude: '33.273609',
    longitude: '131.491943'
  },
  {
    id: '1864771',
    state_id: '28',
    name: '神戸市',
    name_en: 'Bentenchō',
    latitude: '34.683331',
    longitude: '135.183334'
  },
  {
    id: '1864815',
    state_id: '13',
    name: '千代田区',
    name_en: 'Ichibanchō',
    latitude: '35.683331',
    longitude: '139.75'
  },
  {
    id: '1864821',
    state_id: '17',
    name: '金沢市',
    name_en: 'Bakurochō',
    latitude: '36.566669',
    longitude: '136.649994'
  },
  {
    id: '1864873',
    state_id: '26',
    name: '綾部市',
    name_en: 'Ayabe',
    latitude: '35.299999',
    longitude: '135.25'
  },
  {
    id: '1864874',
    state_id: '45',
    name: '綾町',
    name_en: 'Aya',
    latitude: '31.98333',
    longitude: '131.266663'
  },
  {
    id: '1864878',
    state_id: '18',
    name: '越前市',
    name_en: 'Awatabe',
    latitude: '35.915611',
    longitude: '136.239395'
  },
  {
    id: '1864894',
    state_id: '18',
    name: 'あわら市',
    name_en: 'Awara',
    latitude: '36.223091',
    longitude: '136.192368'
  },
  {
    id: '1864945',
    state_id: '22',
    name: '熱海市',
    name_en: 'Atami',
    latitude: '35.089439',
    longitude: '139.068604'
  },
  {
    id: '1864948',
    state_id: '17',
    name: '小松市',
    name_en: 'Ataka-shimmachi',
    latitude: '36.402981',
    longitude: '136.407196'
  },
  {
    id: '1864985',
    state_id: '28',
    name: '芦屋市',
    name_en: 'Ashiya',
    latitude: '34.728069',
    longitude: '135.302643'
  },
  {
    id: '1865005',
    state_id: '09',
    name: '足利市',
    name_en: 'Ashikaga',
    latitude: '36.333328',
    longitude: '139.449997'
  },
  {
    id: '1865006',
    state_id: '27',
    name: '大阪市',
    name_en: 'Ashihara',
    latitude: '34.666672',
    longitude: '135.5'
  },
  {
    id: '1865086',
    state_id: '18',
    name: '大野市',
    name_en: 'Asahi',
    latitude: '35.90469',
    longitude: '136.662796'
  },
  {
    id: '1865090',
    state_id: '13',
    name: '杉並区',
    name_en: 'Asagaya-minami',
    latitude: '35.700001',
    longitude: '139.649994'
  },
  {
    id: '1865103',
    state_id: '41',
    name: '有田町',
    name_en: 'Arita',
    latitude: '33.183331',
    longitude: '129.899994'
  },
  {
    id: '1865110',
    state_id: '23',
    name: '名古屋市',
    name_en: 'Arimatsu',
    latitude: '35.066669',
    longitude: '136.966675'
  },
  {
    id: '1865207',
    state_id: '15',
    name: '妙高市',
    name_en: 'Arai',
    latitude: '37.01667',
    longitude: '138.25'
  },
  {
    id: '1865256',
    state_id: '20',
    name: '青木村',
    name_en: 'Aoki',
    latitude: '36.366669',
    longitude: '138.133331'
  },
  {
    id: '1865264',
    state_id: '23',
    name: '名古屋市',
    name_en: 'Aoichō',
    latitude: '35.166672',
    longitude: '136.916672'
  },
  {
    id: '1865290',
    state_id: '10',
    name: '安中市',
    name_en: 'Annaka',
    latitude: '36.316669',
    longitude: '138.899994'
  },
  {
    id: '1865294',
    state_id: '23',
    name: '安城市',
    name_en: 'Anjō',
    latitude: '34.958279',
    longitude: '137.080536'
  },
  {
    id: '1865309',
    state_id: '36',
    name: '阿南市',
    name_en: 'Anan',
    latitude: '33.916672',
    longitude: '134.649994'
  },
  {
    id: '1865312',
    state_id: '17',
    name: '穴水町',
    name_en: 'Anamizu',
    latitude: '37.23333',
    longitude: '136.899994'
  },
  {
    id: '1865375',
    state_id: '40',
    name: '朝倉市',
    name_en: 'Amagi',
    latitude: '33.416672',
    longitude: '130.649994'
  },
  {
    id: '1865387',
    state_id: '28',
    name: '尼崎市',
    name_en: 'Amagasaki',
    latitude: '34.716671',
    longitude: '135.416672'
  },
  {
    id: '1865401',
    state_id: '46',
    name: '阿久根市',
    name_en: 'Akune',
    latitude: '32.01667',
    longitude: '130.199997'
  },
  {
    id: '1865404',
    state_id: '33',
    name: '真庭市',
    name_en: 'Akuchi',
    latitude: '35.01667',
    longitude: '133.633331'
  },
  {
    id: '1865412',
    state_id: '28',
    name: '赤穂市',
    name_en: 'Kariya',
    latitude: '34.75',
    longitude: '134.399994'
  },
  {
    id: '1865449',
    state_id: '39',
    name: '安芸市',
    name_en: 'Aki',
    latitude: '33.5',
    longitude: '133.899994'
  },
  {
    id: '1865485',
    state_id: '13',
    name: '中央区',
    name_en: 'Akashichō',
    latitude: '35.666672',
    longitude: '139.783325'
  },
  {
    id: '1865661',
    state_id: '28',
    name: '相生市',
    name_en: 'Aioi',
    latitude: '34.803612',
    longitude: '134.468063'
  },
  {
    id: '1865714',
    state_id: '11',
    name: '上尾市',
    name_en: 'Ageoshimo',
    latitude: '35.969719',
    longitude: '139.588608'
  },
  {
    id: '1865720',
    state_id: '20',
    name: '上松町',
    name_en: 'Agematsu',
    latitude: '35.784611',
    longitude: '137.692673'
  },
  {
    id: '1865721',
    state_id: '24',
    name: 'いなべ市',
    name_en: 'Ageki',
    latitude: '35.150002',
    longitude: '136.516663'
  },
  {
    id: '1894388',
    state_id: '47',
    name: '本部町',
    name_en: 'Motobu',
    latitude: '26.652781',
    longitude: '127.890282'
  },
  {
    id: '1894619',
    state_id: '47',
    name: '浦添市',
    name_en: 'Urazoe',
    latitude: '26.25444',
    longitude: '127.70639'
  },
  {
    id: '1894628',
    state_id: '47',
    name: '南風原町',
    name_en: 'Haebaru',
    latitude: '26.184719',
    longitude: '127.722504'
  },
  {
    id: '1895695',
    state_id: '45',
    name: '串間市',
    name_en: 'Kushima',
    latitude: '31.45833',
    longitude: '131.233337'
  },
  {
    id: '1896348',
    state_id: '35',
    name: '光市',
    name_en: 'Hikari',
    latitude: '33.955002',
    longitude: '131.949997'
  },
  {
    id: '1897590',
    state_id: '34',
    name: '海田町',
    name_en: 'Kaita',
    latitude: '34.366669',
    longitude: '132.550003'
  },
  {
    id: '1898046',
    state_id: '15',
    name: '聖籠町',
    name_en: 'Seirō',
    latitude: '37.98333',
    longitude: '139.255005'
  },
  {
    id: '1898149',
    state_id: '41',
    name: '唐津市',
    name_en: 'Mirukashi',
    latitude: '33.438332',
    longitude: '129.938324'
  },
  {
    id: '1898186',
    state_id: '41',
    name: '唐津市',
    name_en: 'Yakataishi',
    latitude: '33.521671',
    longitude: '129.925003'
  },
  {
    id: '1899102',
    state_id: '35',
    name: '長門市',
    name_en: 'Nagato',
    latitude: '34.383331',
    longitude: '131.199997'
  },
  {
    id: '1907116',
    state_id: '11',
    name: '横瀬町',
    name_en: 'Nakai',
    latitude: '35.96833',
    longitude: '139.158615'
  },
  {
    id: '1907123',
    state_id: '11',
    name: '蓮田市',
    name_en: 'Hasuda',
    latitude: '35.975559',
    longitude: '139.650833'
  },
  {
    id: '1907125',
    state_id: '11',
    name: 'ふじみ野市',
    name_en: 'Kamifukuoka',
    latitude: '35.872501',
    longitude: '139.50972'
  },
  {
    id: '1907146',
    state_id: '11',
    name: '狭山市',
    name_en: 'Sayama',
    latitude: '35.852951',
    longitude: '139.412125'
  },
  {
    id: '1907148',
    state_id: '13',
    name: '福生市',
    name_en: 'Fussa',
    latitude: '35.736671',
    longitude: '139.323608'
  },
  {
    id: '1907157',
    state_id: '14',
    name: '相模原市',
    name_en: 'Fujino',
    latitude: '35.606392',
    longitude: '139.143066'
  },
  {
    id: '1907165',
    state_id: '13',
    name: '国立市',
    name_en: 'Kunitachi',
    latitude: '35.674438',
    longitude: '139.434998'
  },
  {
    id: '1907174',
    state_id: '13',
    name: '町田市',
    name_en: 'Okura',
    latitude: '35.584721',
    longitude: '139.470276'
  },
  {
    id: '1907190',
    state_id: '14',
    name: '愛川町',
    name_en: 'Minawa',
    latitude: '35.522781',
    longitude: '139.319443'
  },
  {
    id: '1907225',
    state_id: '14',
    name: '清川村',
    name_en: 'Kawara',
    latitude: '35.47361',
    longitude: '139.272781'
  },
  {
    id: '1907235',
    state_id: '14',
    name: '海老名市',
    name_en: 'Miyanomae',
    latitude: '35.422779',
    longitude: '139.392776'
  },
  {
    id: '1907243',
    state_id: '14',
    name: '茅ヶ崎市',
    name_en: 'Takata',
    latitude: '35.33889',
    longitude: '139.423065'
  },
  {
    id: '1907255',
    state_id: '14',
    name: '伊勢原市',
    name_en: 'Koyasu',
    latitude: '35.41806',
    longitude: '139.255554'
  },
  {
    id: '1907299',
    state_id: '11',
    name: '朝霞市',
    name_en: 'Asaka',
    latitude: '35.804722',
    longitude: '139.601944'
  },
  {
    id: '1907300',
    state_id: '11',
    name: '和光市',
    name_en: 'Wako',
    latitude: '35.78944',
    longitude: '139.623337'
  },
  {
    id: '1907301',
    state_id: '11',
    name: '戸田市',
    name_en: 'Shimotoda',
    latitude: '35.809441',
    longitude: '139.692505'
  },
  {
    id: '1907306',
    state_id: '13',
    name: '狛江市',
    name_en: 'Komae',
    latitude: '35.636391',
    longitude: '139.574432'
  },
  {
    id: '1907307',
    state_id: '12',
    name: '君津市',
    name_en: 'Kimitsu',
    latitude: '35.325279',
    longitude: '139.891113'
  },
  {
    id: '1907309',
    state_id: '14',
    name: '三浦市',
    name_en: 'Miura',
    latitude: '35.139999',
    longitude: '139.619171'
  },
  {
    id: '1926006',
    state_id: '38',
    name: '伊予市',
    name_en: 'Iyo',
    latitude: '33.751389',
    longitude: '132.701385'
  },
  {
    id: '1926016',
    state_id: '38',
    name: '東温市',
    name_en: 'Yokogawara',
    latitude: '33.803761',
    longitude: '132.886414'
  },
  {
    id: '1926020',
    state_id: '38',
    name: '宇和島市',
    name_en: 'Uwajima',
    latitude: '33.223751',
    longitude: '132.560013'
  },
  {
    id: '1926024',
    state_id: '38',
    name: '松前町',
    name_en: 'Tsutsui',
    latitude: '33.788849',
    longitude: '132.705017'
  },
  {
    id: '1926027',
    state_id: '38',
    name: '西条市',
    name_en: 'Tsuitachi',
    latitude: '33.916672',
    longitude: '133.183334'
  },
  {
    id: '1926054',
    state_id: '38',
    name: '西条市',
    name_en: 'Saijō',
    latitude: '33.916672',
    longitude: '133.183334'
  },
  {
    id: '1926055',
    state_id: '38',
    name: '大洲市',
    name_en: 'Ōzu',
    latitude: '33.5',
    longitude: '132.550003'
  },
  {
    id: '1926099',
    state_id: '38',
    name: '松山市',
    name_en: 'Matsuyama',
    latitude: '33.839161',
    longitude: '132.765747'
  },
  {
    id: '1926101',
    state_id: '38',
    name: '松前町',
    name_en: 'Masaki-chō',
    latitude: '33.787571',
    longitude: '132.711243'
  },
  {
    id: '1926102',
    state_id: '38',
    name: '砥部町',
    name_en: 'Mannen',
    latitude: '33.707279',
    longitude: '132.792694'
  },
  {
    id: '1926108',
    state_id: '38',
    name: '西条市',
    name_en: 'Komatsu',
    latitude: '33.883331',
    longitude: '133.116669'
  },
  {
    id: '1926116',
    state_id: '38',
    name: '四国中央市',
    name_en: 'Kawanoechō',
    latitude: '34.01667',
    longitude: '133.566666'
  },
  {
    id: '1926136',
    state_id: '38',
    name: '内子町',
    name_en: 'Ikazaki',
    latitude: '33.535278',
    longitude: '132.654373'
  },
  {
    id: '1926142',
    state_id: '38',
    name: '松山市',
    name_en: 'Hōjō',
    latitude: '33.976608',
    longitude: '132.777664'
  },
  {
    id: '2038715',
    state_id: '01',
    name: 'せたな町',
    name_en: 'Setana',
    latitude: '42.451389',
    longitude: '139.851395'
  },
  {
    id: '2038728',
    state_id: '01',
    name: '奥尻町',
    name_en: 'Okushiri',
    latitude: '42.168331',
    longitude: '139.518326'
  },
  {
    id: '2038800',
    state_id: '02',
    name: '深浦町',
    name_en: 'Fukaura',
    latitude: '40.633331',
    longitude: '139.916672'
  },
  {
    id: '2045397',
    state_id: '01',
    name: '奥尻町',
    name_en: 'Buhama',
    latitude: '42.073059',
    longitude: '139.424438'
  },
  {
    id: '2110460',
    state_id: '05',
    name: '湯沢市',
    name_en: 'Yuzawa',
    latitude: '39.166672',
    longitude: '140.5'
  },
  {
    id: '2110480',
    state_id: '12',
    name: '四街道市',
    name_en: 'Yotsukaidō',
    latitude: '35.650002',
    longitude: '140.166672'
  },
  {
    id: '2110498',
    state_id: '06',
    name: '米沢市',
    name_en: 'Yonezawa',
    latitude: '37.916672',
    longitude: '140.116669'
  },
  {
    id: '2110506',
    state_id: '05',
    name: '横手市',
    name_en: 'Yokote',
    latitude: '39.299999',
    longitude: '140.566666'
  },
  {
    id: '2110518',
    state_id: '12',
    name: '匝瑳市',
    name_en: 'Yōkaichiba',
    latitude: '35.700001',
    longitude: '140.550003'
  },
  {
    id: '2110538',
    state_id: '07',
    name: '伊達市',
    name_en: 'Yanagawamachi-saiwaichō',
    latitude: '37.849998',
    longitude: '140.600006'
  },
  {
    id: '2110541',
    state_id: '04',
    name: '東松島市',
    name_en: 'Yamoto',
    latitude: '38.42738',
    longitude: '141.214874'
  },
  {
    id: '2110556',
    state_id: '06',
    name: '山形市',
    name_en: 'Yamagata',
    latitude: '38.240559',
    longitude: '140.363327'
  },
  {
    id: '2110560',
    state_id: '03',
    name: '山田町',
    name_en: 'Yamada',
    latitude: '39.466671',
    longitude: '141.949997'
  },
  {
    id: '2110579',
    state_id: '12',
    name: '八街市',
    name_en: 'Yachimata',
    latitude: '35.650002',
    longitude: '140.316666'
  },
  {
    id: '2110583',
    state_id: '07',
    name: '矢吹町',
    name_en: 'Yabuki',
    latitude: '37.200001',
    longitude: '140.316666'
  },
  {
    id: '2110584',
    state_id: '05',
    name: '秋田市',
    name_en: 'Yabasehonchō',
    latitude: '39.716671',
    longitude: '140.100006'
  },
  {
    id: '2110586',
    state_id: '04',
    name: '亘理町',
    name_en: 'Watari',
    latitude: '38.035',
    longitude: '140.851105'
  },
  {
    id: '2110596',
    state_id: '04',
    name: '涌谷町',
    name_en: 'Wakuya',
    latitude: '38.533329',
    longitude: '141.133331'
  },
  {
    id: '2110608',
    state_id: '05',
    name: '秋田市',
    name_en: 'Wada',
    latitude: '39.650002',
    longitude: '140.216675'
  },
  {
    id: '2110617',
    state_id: '07',
    name: '福島市',
    name_en: 'Uwamachi',
    latitude: '37.75',
    longitude: '140.466675'
  },
  {
    id: '2110629',
    state_id: '08',
    name: '牛久市',
    name_en: 'Ushiku',
    latitude: '35.966671',
    longitude: '140.133331'
  },
  {
    id: '2110657',
    state_id: '03',
    name: '盛岡市',
    name_en: 'Uchimaru',
    latitude: '39.700001',
    longitude: '141.149994'
  },
  {
    id: '2110661',
    state_id: '07',
    name: 'いわき市',
    name_en: 'Uchigō-tsuzuramachi',
    latitude: '37.033329',
    longitude: '140.850006'
  },
  {
    id: '2110681',
    state_id: '08',
    name: 'つくば市',
    name_en: 'Tsukuba',
    latitude: '36.083328',
    longitude: '140.116669'
  },
  {
    id: '2110683',
    state_id: '08',
    name: 'つくば市',
    name_en: 'Tsukuba',
    latitude: '36.200001',
    longitude: '140.100006'
  },
  {
    id: '2110729',
    state_id: '08',
    name: '取手市',
    name_en: 'Toride',
    latitude: '35.900002',
    longitude: '140.083328'
  },
  {
    id: '2110735',
    state_id: '03',
    name: '遠野市',
    name_en: 'Tōno',
    latitude: '39.316669',
    longitude: '141.533325'
  },
  {
    id: '2110743',
    state_id: '08',
    name: '笠間市',
    name_en: 'Tomobe',
    latitude: '36.349998',
    longitude: '140.300003'
  },
  {
    id: '2110744',
    state_id: '04',
    name: '富谷市',
    name_en: 'Tomiya',
    latitude: '38.393059',
    longitude: '140.886108'
  },
  {
    id: '2110774',
    state_id: '12',
    name: '東金市',
    name_en: 'Tōgane',
    latitude: '35.549999',
    longitude: '140.366669'
  },
  {
    id: '2110793',
    state_id: '06',
    name: '天童市',
    name_en: 'Tendō',
    latitude: '38.353611',
    longitude: '140.36972'
  },
  {
    id: '2110809',
    state_id: '08',
    name: 'つくば市',
    name_en: 'Tateno',
    latitude: '36.033329',
    longitude: '140.116669'
  },
  {
    id: '2110812',
    state_id: '03',
    name: '平泉町',
    name_en: 'Tateishi',
    latitude: '38.98333',
    longitude: '141.116669'
  },
  {
    id: '2110837',
    state_id: '07',
    name: '棚倉町',
    name_en: 'Tanagura',
    latitude: '37.033329',
    longitude: '140.383331'
  },
  {
    id: '2110850',
    state_id: '12',
    name: '多古町',
    name_en: 'Tako',
    latitude: '35.73333',
    longitude: '140.466675'
  },
  {
    id: '2110891',
    state_id: '06',
    name: '高畠町',
    name_en: 'Takahata',
    latitude: '38.002499',
    longitude: '140.191116'
  },
  {
    id: '2110893',
    state_id: '08',
    name: '高萩市',
    name_en: 'Takahagi',
    latitude: '36.716671',
    longitude: '140.716675'
  },
  {
    id: '2110901',
    state_id: '08',
    name: '高萩市',
    name_en: 'Takado',
    latitude: '36.716671',
    longitude: '140.716675'
  },
  {
    id: '2110944',
    state_id: '08',
    name: '日立市',
    name_en: 'Suwa',
    latitude: '36.566669',
    longitude: '140.633331'
  },
  {
    id: '2110959',
    state_id: '07',
    name: '須賀川市',
    name_en: 'Sukagawa',
    latitude: '37.283329',
    longitude: '140.383331'
  },
  {
    id: '2110964',
    state_id: '08',
    name: '水戸市',
    name_en: 'Sugisaki',
    latitude: '36.383331',
    longitude: '140.350006'
  },
  {
    id: '2110994',
    state_id: '03',
    name: '雫石町',
    name_en: 'Shizukuishi',
    latitude: '39.683331',
    longitude: '140.983337'
  },
  {
    id: '2111008',
    state_id: '12',
    name: '酒々井町',
    name_en: 'Shisui',
    latitude: '35.716671',
    longitude: '140.266663'
  },
  {
    id: '2111016',
    state_id: '04',
    name: '白石市',
    name_en: 'Shiroishi',
    latitude: '38.00333',
    longitude: '140.618332'
  },
  {
    id: '2111018',
    state_id: '12',
    name: '白井市',
    name_en: 'Shiroi',
    latitude: '35.799999',
    longitude: '140.066666'
  },
  {
    id: '2111043',
    state_id: '08',
    name: '水戸市',
    name_en: 'Shiogasaki',
    latitude: '36.333328',
    longitude: '140.550003'
  },
  {
    id: '2111049',
    state_id: '04',
    name: '塩竈市',
    name_en: 'Shiogama',
    latitude: '38.316669',
    longitude: '141.033325'
  },
  {
    id: '2111065',
    state_id: '06',
    name: '新庄市',
    name_en: 'Shinjō',
    latitude: '38.75861',
    longitude: '140.300827'
  },
  {
    id: '2111106',
    state_id: '04',
    name: '仙台市',
    name_en: 'Shimizukōji',
    latitude: '38.25',
    longitude: '140.883331'
  },
  {
    id: '2111127',
    state_id: '12',
    name: '横芝光町',
    name_en: 'Shibayama',
    latitude: '35.683331',
    longitude: '140.433334'
  },
  {
    id: '2111149',
    state_id: '04',
    name: '仙台市',
    name_en: 'Sendai',
    latitude: '38.26889',
    longitude: '140.871933'
  },
  {
    id: '2111154',
    state_id: '03',
    name: '盛岡市',
    name_en: 'Sembokuchō',
    latitude: '39.683331',
    longitude: '141.149994'
  },
  {
    id: '2111173',
    state_id: '12',
    name: '香取市',
    name_en: 'Sawara',
    latitude: '35.883331',
    longitude: '140.5'
  },
  {
    id: '2111220',
    state_id: '12',
    name: '佐倉市',
    name_en: 'Sakura',
    latitude: '35.716671',
    longitude: '140.233337'
  },
  {
    id: '2111248',
    state_id: '06',
    name: '寒河江市',
    name_en: 'Sagae',
    latitude: '38.372501',
    longitude: '140.272507'
  },
  {
    id: '2111258',
    state_id: '08',
    name: '龍ケ崎市',
    name_en: 'Ryūgasaki',
    latitude: '35.900002',
    longitude: '140.183334'
  },
  {
    id: '2111277',
    state_id: '04',
    name: '利府町',
    name_en: 'Rifu',
    latitude: '38.323608',
    longitude: '140.974442'
  },
  {
    id: '2111302',
    state_id: '07',
    name: '郡山市',
    name_en: 'Ōtsukimachi',
    latitude: '37.383331',
    longitude: '140.316666'
  },
  {
    id: '2111310',
    state_id: '03',
    name: '大槌町',
    name_en: 'Ōtsuchi',
    latitude: '39.366669',
    longitude: '141.899994'
  },
  {
    id: '2111325',
    state_id: '09',
    name: '大田原市',
    name_en: 'Ōtawara',
    latitude: '36.866669',
    longitude: '140.033325'
  },
  {
    id: '2111401',
    state_id: '04',
    name: '女川町',
    name_en: 'Onagawa Chō',
    latitude: '38.438332',
    longitude: '141.449997'
  },
  {
    id: '2111425',
    state_id: '08',
    name: '常陸大宮市',
    name_en: 'Ōmiya',
    latitude: '36.549999',
    longitude: '140.416672'
  },
  {
    id: '2111429',
    state_id: '12',
    name: '香取市',
    name_en: 'Omigawa',
    latitude: '35.849998',
    longitude: '140.616669'
  },
  {
    id: '2111435',
    state_id: '05',
    name: '大仙市',
    name_en: 'Ōmagari',
    latitude: '39.450001',
    longitude: '140.483337'
  },
  {
    id: '2111441',
    state_id: '08',
    name: '茨城町',
    name_en: 'Okunoya',
    latitude: '36.283329',
    longitude: '140.416672'
  },
  {
    id: '2111461',
    state_id: '04',
    name: '大河原町',
    name_en: 'Ōkawara',
    latitude: '38.049999',
    longitude: '140.733612'
  },
  {
    id: '2111471',
    state_id: '06',
    name: '大石田町',
    name_en: 'Ōishida',
    latitude: '38.586941',
    longitude: '140.371384'
  },
  {
    id: '2111488',
    state_id: '03',
    name: '花巻市',
    name_en: 'Ōhasama',
    latitude: '39.466671',
    longitude: '141.283325'
  },
  {
    id: '2111495',
    state_id: '12',
    name: 'いすみ市',
    name_en: 'Ōhara',
    latitude: '35.25',
    longitude: '140.383331'
  },
  {
    id: '2111530',
    state_id: '03',
    name: '大船渡市',
    name_en: 'Ōfunato',
    latitude: '39.071671',
    longitude: '141.716675'
  },
  {
    id: '2111540',
    state_id: '07',
    name: '南相馬市',
    name_en: 'Odaka',
    latitude: '37.566669',
    longitude: '140.983337'
  },
  {
    id: '2111541',
    state_id: '07',
    name: '玉川村',
    name_en: 'Odaka',
    latitude: '37.200001',
    longitude: '140.416672'
  },
  {
    id: '2111543',
    state_id: '07',
    name: '福島市',
    name_en: 'Ōhira',
    latitude: '37.849998',
    longitude: '140.283325'
  },
  {
    id: '2111559',
    state_id: '06',
    name: '尾花沢市',
    name_en: 'Obanazawa',
    latitude: '38.603329',
    longitude: '140.401947'
  },
  {
    id: '2111567',
    state_id: '08',
    name: '大洗町',
    name_en: 'Ōarai',
    latitude: '36.316669',
    longitude: '140.600006'
  },
  {
    id: '2111568',
    state_id: '12',
    name: '大網白里市',
    name_en: 'Ōami',
    latitude: '35.51667',
    longitude: '140.316666'
  },
  {
    id: '2111594',
    state_id: '08',
    name: '常陸大宮市',
    name_en: 'Noguchi',
    latitude: '36.549999',
    longitude: '140.333328'
  },
  {
    id: '2111656',
    state_id: '07',
    name: '二本松市',
    name_en: 'Nihommatsu',
    latitude: '37.583328',
    longitude: '140.433334'
  },
  {
    id: '2111677',
    state_id: '12',
    name: '山武市',
    name_en: 'Narutō',
    latitude: '35.599998',
    longitude: '140.416672'
  },
  {
    id: '2111684',
    state_id: '12',
    name: '成田市',
    name_en: 'Narita',
    latitude: '35.783329',
    longitude: '140.316666'
  },
  {
    id: '2111687',
    state_id: '12',
    name: '習志野市',
    name_en: 'Narashino',
    latitude: '35.683331',
    longitude: '140.033325'
  },
  {
    id: '2111704',
    state_id: '07',
    name: '浪江町',
    name_en: 'Namie',
    latitude: '37.48333',
    longitude: '141'
  },
  {
    id: '2111706',
    state_id: '12',
    name: '成田市',
    name_en: 'Namekawa',
    latitude: '35.866669',
    longitude: '140.350006'
  },
  {
    id: '2111713',
    state_id: '06',
    name: '上山市',
    name_en: 'Nakayama',
    latitude: '38.121391',
    longitude: '140.218323'
  },
  {
    id: '2111749',
    state_id: '08',
    name: '土浦市',
    name_en: 'Naka',
    latitude: '36.049999',
    longitude: '140.166672'
  },
  {
    id: '2111756',
    state_id: '08',
    name: '茨城町',
    name_en: 'Nagasu',
    latitude: '36.283329',
    longitude: '140.516663'
  },
  {
    id: '2111781',
    state_id: '06',
    name: '長井市',
    name_en: 'Nagai',
    latitude: '38.103611',
    longitude: '140.035004'
  },
  {
    id: '2111824',
    state_id: '07',
    name: '本宮市',
    name_en: 'Motomiya',
    latitude: '37.51667',
    longitude: '140.399994'
  },
  {
    id: '2111827',
    state_id: '09',
    name: '茂木町',
    name_en: 'Motegi',
    latitude: '36.51667',
    longitude: '140.183334'
  },
  {
    id: '2111831',
    state_id: '08',
    name: '守谷市',
    name_en: 'Moriya',
    latitude: '35.933331',
    longitude: '140'
  },
  {
    id: '2111834',
    state_id: '03',
    name: '盛岡市',
    name_en: 'Morioka',
    latitude: '39.703609',
    longitude: '141.152496'
  },
  {
    id: '2111836',
    state_id: '09',
    name: '真岡市',
    name_en: 'Mooka',
    latitude: '36.433331',
    longitude: '140.016663'
  },
  {
    id: '2111846',
    state_id: '08',
    name: '鉾田市',
    name_en: 'Momiyama',
    latitude: '36.183331',
    longitude: '140.550003'
  },
  {
    id: '2111855',
    state_id: '12',
    name: '茂原市',
    name_en: 'Mobara',
    latitude: '35.416672',
    longitude: '140.300003'
  },
  {
    id: '2111859',
    state_id: '03',
    name: '奥州市',
    name_en: 'Mizusawa',
    latitude: '39.133331',
    longitude: '141.133331'
  },
  {
    id: '2111862',
    state_id: '08',
    name: '日立市',
    name_en: 'Mizuki',
    latitude: '36.51667',
    longitude: '140.633331'
  },
  {
    id: '2111884',
    state_id: '03',
    name: '宮古市',
    name_en: 'Miyako',
    latitude: '39.636669',
    longitude: '141.952499'
  },
  {
    id: '2111901',
    state_id: '08',
    name: '水戸市',
    name_en: 'Mito',
    latitude: '36.341389',
    longitude: '140.446671'
  },
  {
    id: '2111943',
    state_id: '07',
    name: '三春町',
    name_en: 'Miharu',
    latitude: '37.433331',
    longitude: '140.483337'
  },
  {
    id: '2111964',
    state_id: '04',
    name: '松島町',
    name_en: 'Matsushima',
    latitude: '38.373569',
    longitude: '141.06105'
  },
  {
    id: '2111999',
    state_id: '09',
    name: '益子町',
    name_en: 'Mashiko',
    latitude: '36.466671',
    longitude: '140.100006'
  },
  {
    id: '2112008',
    state_id: '04',
    name: '丸森町',
    name_en: 'Marumori',
    latitude: '37.916672',
    longitude: '140.766663'
  },
  {
    id: '2112019',
    state_id: '08',
    name: '桜川市',
    name_en: 'Makabe',
    latitude: '36.26667',
    longitude: '140.100006'
  },
  {
    id: '2112034',
    state_id: '12',
    name: '鴨川市',
    name_en: 'Maebara',
    latitude: '35.099998',
    longitude: '140.100006'
  },
  {
    id: '2112062',
    state_id: '03',
    name: '北上市',
    name_en: 'Kurosawajiri',
    latitude: '39.283329',
    longitude: '141.116669'
  },
  {
    id: '2112077',
    state_id: '09',
    name: '那須塩原市',
    name_en: 'Kuroiso',
    latitude: '36.966671',
    longitude: '140.050003'
  },
  {
    id: '2112141',
    state_id: '07',
    name: '郡山市',
    name_en: 'Kōriyama',
    latitude: '37.400002',
    longitude: '140.383331'
  },
  {
    id: '2112144',
    state_id: '07',
    name: '伊達市',
    name_en: 'Koori',
    latitude: '37.833328',
    longitude: '140.516663'
  },
  {
    id: '2112176',
    state_id: '08',
    name: '水戸市',
    name_en: 'Koibuchi',
    latitude: '36.349998',
    longitude: '140.366669'
  },
  {
    id: '2112182',
    state_id: '04',
    name: '美里町',
    name_en: 'Kogota',
    latitude: '38.549999',
    longitude: '141.050003'
  },
  {
    id: '2112209',
    state_id: '08',
    name: '水戸市',
    name_en: 'Kita-sannomaru',
    latitude: '36.366669',
    longitude: '140.483337'
  },
  {
    id: '2112216',
    state_id: '08',
    name: '高萩市',
    name_en: 'Kitakubo',
    latitude: '36.73333',
    longitude: '140.716675'
  },
  {
    id: '2112227',
    state_id: '03',
    name: '北上市',
    name_en: 'Kitakami',
    latitude: '39.283329',
    longitude: '141.116669'
  },
  {
    id: '2112232',
    state_id: '08',
    name: '北茨城市',
    name_en: 'Kitaibaraki',
    latitude: '36.783329',
    longitude: '140.75'
  },
  {
    id: '2112234',
    state_id: '04',
    name: '名取市',
    name_en: 'Kitagama',
    latitude: '38.13472',
    longitude: '140.935287'
  },
  {
    id: '2112297',
    state_id: '12',
    name: '鴨川市',
    name_en: 'Kamogawa',
    latitude: '35.099998',
    longitude: '140.100006'
  },
  {
    id: '2112309',
    state_id: '12',
    name: '勝浦市',
    name_en: 'Katsuura',
    latitude: '35.133331',
    longitude: '140.300003'
  },
  {
    id: '2112312',
    state_id: '08',
    name: 'ひたちなか市',
    name_en: 'Katsuta',
    latitude: '36.383331',
    longitude: '140.533325'
  },
  {
    id: '2112319',
    state_id: '12',
    name: '香取市',
    name_en: 'Katori-shi',
    latitude: '35.897671',
    longitude: '140.499435'
  },
  {
    id: '2112328',
    state_id: '06',
    name: '山形市',
    name_en: 'Kasumichō',
    latitude: '38.25',
    longitude: '140.333328'
  },
  {
    id: '2112343',
    state_id: '08',
    name: '笠間市',
    name_en: 'Kasama',
    latitude: '36.383331',
    longitude: '140.266663'
  },
  {
    id: '2112354',
    state_id: '09',
    name: '那須烏山市',
    name_en: 'Karasuyama',
    latitude: '36.650002',
    longitude: '140.149994'
  },
  {
    id: '2112370',
    state_id: '03',
    name: '金ケ崎町',
    name_en: 'Kanegasaki',
    latitude: '39.200001',
    longitude: '141.116669'
  },
  {
    id: '2112409',
    state_id: '06',
    name: '上山市',
    name_en: 'Kaminoyama',
    latitude: '38.153889',
    longitude: '140.273605'
  },
  {
    id: '2112444',
    state_id: '03',
    name: '釜石市',
    name_en: 'Kamaishi',
    latitude: '39.26667',
    longitude: '141.883331'
  },
  {
    id: '2112454',
    state_id: '05',
    name: '仙北市',
    name_en: 'Kakunodatemachi',
    latitude: '39.583328',
    longitude: '140.566666'
  },
  {
    id: '2112455',
    state_id: '04',
    name: '角田市',
    name_en: 'Kakuda',
    latitude: '37.966671',
    longitude: '140.783325'
  },
  {
    id: '2112473',
    state_id: '04',
    name: '石巻市',
    name_en: 'Kadonowakichō',
    latitude: '38.416672',
    longitude: '141.300003'
  },
  {
    id: '2112498',
    state_id: '06',
    name: '東根市',
    name_en: 'Jinmachi',
    latitude: '38.400002',
    longitude: '140.383331'
  },
  {
    id: '2112521',
    state_id: '08',
    name: '桜川市',
    name_en: 'Iwase',
    latitude: '36.349998',
    longitude: '140.100006'
  },
  {
    id: '2112527',
    state_id: '04',
    name: '岩沼市',
    name_en: 'Iwanuma',
    latitude: '38.104721',
    longitude: '140.859436'
  },
  {
    id: '2112539',
    state_id: '07',
    name: 'いわき市',
    name_en: 'Iwaki',
    latitude: '37.049999',
    longitude: '140.883331'
  },
  {
    id: '2112543',
    state_id: '03',
    name: '岩泉町',
    name_en: 'Iwaizumi',
    latitude: '39.849998',
    longitude: '141.800003'
  },
  {
    id: '2112551',
    state_id: '12',
    name: '御宿町',
    name_en: 'Iwawada',
    latitude: '35.183331',
    longitude: '140.366669'
  },
  {
    id: '2112555',
    state_id: '08',
    name: '潮来市',
    name_en: 'Itako',
    latitude: '35.933331',
    longitude: '140.550003'
  },
  {
    id: '2112562',
    state_id: '12',
    name: '鴨川市',
    name_en: 'Isomura',
    latitude: '35.083328',
    longitude: '140.100006'
  },
  {
    id: '2112569',
    state_id: '05',
    name: '由利本荘市',
    name_en: 'Ishiwaki',
    latitude: '39.383331',
    longitude: '140.050003'
  },
  {
    id: '2112571',
    state_id: '08',
    name: '石岡市',
    name_en: 'Ishioka',
    latitude: '36.183331',
    longitude: '140.266663'
  },
  {
    id: '2112576',
    state_id: '04',
    name: '石巻市',
    name_en: 'Ishinomaki',
    latitude: '38.416672',
    longitude: '141.300003'
  },
  {
    id: '2112583',
    state_id: '07',
    name: '石川町',
    name_en: 'Ishikawa',
    latitude: '37.150002',
    longitude: '140.449997'
  },
  {
    id: '2112615',
    state_id: '07',
    name: '猪苗代町',
    name_en: 'Inawashiro',
    latitude: '37.566669',
    longitude: '140.116669'
  },
  {
    id: '2112656',
    state_id: '03',
    name: '一関市',
    name_en: 'Ichinoseki',
    latitude: '38.916672',
    longitude: '141.133331'
  },
  {
    id: '2112664',
    state_id: '12',
    name: '市原市',
    name_en: 'Ichihara',
    latitude: '35.51667',
    longitude: '140.083328'
  },
  {
    id: '2112669',
    state_id: '08',
    name: '笠間市',
    name_en: 'Ibaraki',
    latitude: '36.310349',
    longitude: '140.316147'
  },
  {
    id: '2112688',
    state_id: '08',
    name: '鉾田市',
    name_en: 'Hokota',
    latitude: '36.150002',
    longitude: '140.516663'
  },
  {
    id: '2112692',
    state_id: '07',
    name: '伊達市',
    name_en: 'Hobaramachi',
    latitude: '37.816669',
    longitude: '140.550003'
  },
  {
    id: '2112708',
    state_id: '08',
    name: '日立市',
    name_en: 'Hitachi',
    latitude: '36.599998',
    longitude: '140.649994'
  },
  {
    id: '2112758',
    state_id: '06',
    name: '東根市',
    name_en: 'Higashine',
    latitude: '38.438889',
    longitude: '140.400558'
  },
  {
    id: '2112761',
    state_id: '03',
    name: '花巻市',
    name_en: 'Higashimiyanome',
    latitude: '39.416672',
    longitude: '141.133331'
  },
  {
    id: '2112802',
    state_id: '12',
    name: '銚子市',
    name_en: 'Hasaki',
    latitude: '35.73333',
    longitude: '140.833328'
  },
  {
    id: '2112818',
    state_id: '07',
    name: '塙町',
    name_en: 'Hanawa',
    latitude: '36.950001',
    longitude: '140.416672'
  },
  {
    id: '2112823',
    state_id: '03',
    name: '花巻市',
    name_en: 'Hanamaki',
    latitude: '39.383331',
    longitude: '141.116669'
  },
  {
    id: '2112860',
    state_id: '05',
    name: '五城目町',
    name_en: 'Gojōnome',
    latitude: '39.933331',
    longitude: '140.116669'
  },
  {
    id: '2112874',
    state_id: '08',
    name: '稲敷市',
    name_en: 'Futto',
    latitude: '35.966671',
    longitude: '140.366669'
  },
  {
    id: '2112892',
    state_id: '12',
    name: '我孫子市',
    name_en: 'Fusa',
    latitude: '35.849998',
    longitude: '140.133331'
  },
  {
    id: '2112899',
    state_id: '04',
    name: '大崎市',
    name_en: 'Furukawa',
    latitude: '38.571671',
    longitude: '140.955566'
  },
  {
    id: '2112903',
    state_id: '07',
    name: '田村市',
    name_en: 'Funehikimachi-funehiki',
    latitude: '37.433331',
    longitude: '140.583328'
  },
  {
    id: '2112913',
    state_id: '08',
    name: '東海村',
    name_en: 'Funaishikawa',
    latitude: '36.466671',
    longitude: '140.566666'
  },
  {
    id: '2112917',
    state_id: '06',
    name: '舟形町',
    name_en: 'Funagata',
    latitude: '38.688889',
    longitude: '140.319443'
  },
  {
    id: '2112923',
    state_id: '07',
    name: '福島市',
    name_en: 'Fukushima',
    latitude: '37.75',
    longitude: '140.467773'
  },
  {
    id: '2112940',
    state_id: '08',
    name: '取手市',
    name_en: 'Fujishiro',
    latitude: '35.916672',
    longitude: '140.116669'
  },
  {
    id: '2112963',
    state_id: '08',
    name: '稲敷市',
    name_en: 'Edosaki',
    latitude: '35.950001',
    longitude: '140.316666'
  },
  {
    id: '2112989',
    state_id: '08',
    name: '大子町',
    name_en: 'Daigo',
    latitude: '36.76667',
    longitude: '140.350006'
  },
  {
    id: '2113001',
    state_id: '12',
    name: '長南町',
    name_en: 'Chōnan',
    latitude: '35.400002',
    longitude: '140.233337'
  },
  {
    id: '2113015',
    state_id: '12',
    name: '千葉市',
    name_en: 'Chiba',
    latitude: '35.604721',
    longitude: '140.123337'
  },
  {
    id: '2113077',
    state_id: '12',
    name: '旭市',
    name_en: 'Asahi',
    latitude: '35.716671',
    longitude: '140.649994'
  },
  {
    id: '2113115',
    state_id: '08',
    name: '阿見町',
    name_en: 'Ami',
    latitude: '36.033329',
    longitude: '140.199997'
  },
  {
    id: '2113126',
    state_id: '05',
    name: '秋田市',
    name_en: 'Akita',
    latitude: '39.716671',
    longitude: '140.116669'
  },
  {
    id: '2113136',
    state_id: '07',
    name: '郡山市',
    name_en: 'Akanuma',
    latitude: '37.366669',
    longitude: '140.433334'
  },
  {
    id: '2113164',
    state_id: '12',
    name: '我孫子市',
    name_en: 'Abiko',
    latitude: '35.866669',
    longitude: '140.016663'
  },
  {
    id: '2113698',
    state_id: '04',
    name: '石巻市',
    name_en: 'Kahoku',
    latitude: '38.51667',
    longitude: '141.321671'
  },
  {
    id: '2113719',
    state_id: '05',
    name: '秋田市',
    name_en: 'Akita Shi',
    latitude: '39.71806',
    longitude: '140.103333'
  },
  {
    id: '2114009',
    state_id: '06',
    name: '真室川町',
    name_en: 'Nishikawa',
    latitude: '38.926109',
    longitude: '140.176392'
  },
  {
    id: '2114102',
    state_id: '06',
    name: '西川町',
    name_en: 'Mazawa',
    latitude: '38.433891',
    longitude: '140.124176'
  },
  {
    id: '2114128',
    state_id: '06',
    name: '寒河江市',
    name_en: 'Motodate',
    latitude: '38.35944',
    longitude: '140.290283'
  },
  {
    id: '2114134',
    state_id: '06',
    name: '山辺町',
    name_en: 'Yamanobe',
    latitude: '38.291111',
    longitude: '140.266663'
  },
  {
    id: '2114152',
    state_id: '04',
    name: '川崎町',
    name_en: 'Aone-onsen',
    latitude: '38.141941',
    longitude: '140.533615'
  },
  {
    id: '2127383',
    state_id: '01',
    name: '美幌町',
    name_en: 'Motomachi',
    latitude: '43.822781',
    longitude: '144.104446'
  },
  {
    id: '2127386',
    state_id: '01',
    name: '函館市',
    name_en: 'Zenikamechō',
    latitude: '41.76667',
    longitude: '140.833328'
  },
  {
    id: '2127398',
    state_id: '01',
    name: '由仁町',
    name_en: 'Yuni',
    latitude: '43.007221',
    longitude: '141.784439'
  },
  {
    id: '2127419',
    state_id: '01',
    name: '夕張市',
    name_en: 'Yūbari',
    latitude: '43.037781',
    longitude: '141.957779'
  },
  {
    id: '2127436',
    state_id: '02',
    name: '横浜町',
    name_en: 'Yokohama',
    latitude: '41.083328',
    longitude: '141.25'
  },
  {
    id: '2127478',
    state_id: '01',
    name: '八雲町',
    name_en: 'Yakumo',
    latitude: '42.253891',
    longitude: '140.269165'
  },
  {
    id: '2127500',
    state_id: '01',
    name: '和寒町',
    name_en: 'Wassamu',
    latitude: '44.020828',
    longitude: '142.417786'
  },
  {
    id: '2127515',
    state_id: '01',
    name: '稚内市',
    name_en: 'Wakkanai',
    latitude: '45.409439',
    longitude: '141.673889'
  },
  {
    id: '2127545',
    state_id: '01',
    name: '歌志内市',
    name_en: 'Utashinai',
    latitude: '43.51667',
    longitude: '142.050003'
  },
  {
    id: '2127574',
    state_id: '01',
    name: '雨竜町',
    name_en: 'Uryū',
    latitude: '43.639439',
    longitude: '141.887787'
  },
  {
    id: '2127586',
    state_id: '01',
    name: '浦河町',
    name_en: 'Urakawa',
    latitude: '42.15556',
    longitude: '142.783615'
  },
  {
    id: '2127588',
    state_id: '01',
    name: '浦幌町',
    name_en: 'Honchō',
    latitude: '42.80611',
    longitude: '143.654724'
  },
  {
    id: '2127626',
    state_id: '01',
    name: '月形町',
    name_en: 'Tsukigata',
    latitude: '43.335281',
    longitude: '141.669434'
  },
  {
    id: '2127635',
    state_id: '01',
    name: '津別町',
    name_en: 'Honchō',
    latitude: '43.703892',
    longitude: '144.021103'
  },
  {
    id: '2127652',
    state_id: '01',
    name: '豊頃町',
    name_en: 'Toyokoro',
    latitude: '42.816669',
    longitude: '143.533325'
  },
  {
    id: '2127660',
    state_id: '01',
    name: '札幌市',
    name_en: 'Toyohira-ku',
    latitude: '43.049999',
    longitude: '141.366669'
  },
  {
    id: '2127712',
    state_id: '01',
    name: '芦別市',
    name_en: 'Tomioka',
    latitude: '43.48333',
    longitude: '142.183334'
  },
  {
    id: '2127731',
    state_id: '01',
    name: '苫前町',
    name_en: 'Tomamae',
    latitude: '44.305279',
    longitude: '141.654434'
  },
  {
    id: '2127733',
    state_id: '01',
    name: '苫小牧市',
    name_en: 'Tomakomai',
    latitude: '42.63694',
    longitude: '141.603333'
  },
  {
    id: '2127746',
    state_id: '01',
    name: '北見市',
    name_en: 'Tokorochō-higashihama',
    latitude: '44.118061',
    longitude: '144.073608'
  },
  {
    id: '2127802',
    state_id: '01',
    name: '当別町',
    name_en: 'Tōbetsu',
    latitude: '43.216942',
    longitude: '141.516937'
  },
  {
    id: '2127820',
    state_id: '01',
    name: '天塩町',
    name_en: 'Teshio',
    latitude: '44.883888',
    longitude: '141.743057'
  },
  {
    id: '2127822',
    state_id: '01',
    name: '弟子屈町',
    name_en: 'Teshikaga',
    latitude: '43.483608',
    longitude: '144.456116'
  },
  {
    id: '2127878',
    state_id: '02',
    name: 'むつ市',
    name_en: 'Mutsu',
    latitude: '41.28944',
    longitude: '141.216934'
  },
  {
    id: '2127883',
    state_id: '02',
    name: '田子町',
    name_en: 'Takko',
    latitude: '40.336109',
    longitude: '141.151672'
  },
  {
    id: '2127896',
    state_id: '01',
    name: '滝川市',
    name_en: 'Takikawa',
    latitude: '43.55278',
    longitude: '141.906387'
  },
  {
    id: '2127910',
    state_id: '05',
    name: '北秋田市',
    name_en: 'Takanosu',
    latitude: '40.221939',
    longitude: '140.369446'
  },
  {
    id: '2127931',
    state_id: '01',
    name: '大樹町',
    name_en: 'Taiki',
    latitude: '42.490559',
    longitude: '143.274719'
  },
  {
    id: '2127949',
    state_id: '01',
    name: '寿都町',
    name_en: 'Suttsu',
    latitude: '42.788059',
    longitude: '140.225555'
  },
  {
    id: '2127955',
    state_id: '01',
    name: '砂川市',
    name_en: 'Sunagawa',
    latitude: '43.486389',
    longitude: '141.905563'
  },
  {
    id: '2127985',
    state_id: '01',
    name: '壮瞥町',
    name_en: 'Sōbetsu',
    latitude: '42.555828',
    longitude: '140.886108'
  },
  {
    id: '2128025',
    state_id: '01',
    name: '新ひだか町',
    name_en: 'Shizunai-furukawachō',
    latitude: '42.333889',
    longitude: '142.366943'
  },
  {
    id: '2128072',
    state_id: '01',
    name: '白老町',
    name_en: 'Shiraoi',
    latitude: '42.549999',
    longitude: '141.350006'
  },
  {
    id: '2128095',
    state_id: '01',
    name: '新得町',
    name_en: 'Shintoku',
    latitude: '43.074718',
    longitude: '142.834717'
  },
  {
    id: '2128112',
    state_id: '01',
    name: '占冠村',
    name_en: 'Shimukappu',
    latitude: '43',
    longitude: '142.416672'
  },
  {
    id: '2128131',
    state_id: '01',
    name: '函館市',
    name_en: 'Masukawachō',
    latitude: '41.816669',
    longitude: '140.850006'
  },
  {
    id: '2128139',
    state_id: '01',
    name: '下川町',
    name_en: 'Shinshimokawa',
    latitude: '44.28944',
    longitude: '142.636383'
  },
  {
    id: '2128141',
    state_id: '01',
    name: '更別村',
    name_en: 'Shimo-ihira',
    latitude: '42.716671',
    longitude: '143.266663'
  },
  {
    id: '2128147',
    state_id: '01',
    name: '富良野市',
    name_en: 'Shimo-furano',
    latitude: '43.349998',
    longitude: '142.383331'
  },
  {
    id: '2128159',
    state_id: '01',
    name: '清水町',
    name_en: 'Shimizu',
    latitude: '43.006111',
    longitude: '142.88472'
  },
  {
    id: '2128180',
    state_id: '01',
    name: '鹿部町',
    name_en: 'Shikabe',
    latitude: '42.025829',
    longitude: '140.821106'
  },
  {
    id: '2128186',
    state_id: '01',
    name: '士幌町',
    name_en: 'Shihoro',
    latitude: '43.166672',
    longitude: '143.25'
  },
  {
    id: '2128194',
    state_id: '02',
    name: '七戸町',
    name_en: 'Shichinohe',
    latitude: '40.691109',
    longitude: '141.154724'
  },
  {
    id: '2128206',
    state_id: '01',
    name: '標津町',
    name_en: 'Shibetsu',
    latitude: '43.658989',
    longitude: '145.131973'
  },
  {
    id: '2128207',
    state_id: '01',
    name: '標茶町',
    name_en: 'Shibecha',
    latitude: '43.292782',
    longitude: '144.600555'
  },
  {
    id: '2128215',
    state_id: '01',
    name: '斜里町',
    name_en: 'Honmachi',
    latitude: '43.90556',
    longitude: '144.659439'
  },
  {
    id: '2128277',
    state_id: '01',
    name: '猿払村',
    name_en: 'Sarufutsu',
    latitude: '45.256939',
    longitude: '142.200287'
  },
  {
    id: '2128295',
    state_id: '01',
    name: '札幌市',
    name_en: 'Sapporo',
    latitude: '43.064171',
    longitude: '141.346939'
  },
  {
    id: '2128303',
    state_id: '02',
    name: '三戸町',
    name_en: 'Sannohe',
    latitude: '40.373058',
    longitude: '141.257217'
  },
  {
    id: '2128327',
    state_id: '02',
    name: '十和田市',
    name_en: 'Sanbongi',
    latitude: '40.599998',
    longitude: '141.216675'
  },
  {
    id: '2128333',
    state_id: '01',
    name: '様似町',
    name_en: 'Honchō',
    latitude: '42.121941',
    longitude: '142.919174'
  },
  {
    id: '2128372',
    state_id: '01',
    name: '留寿都村',
    name_en: 'Rusutsu',
    latitude: '42.734169',
    longitude: '140.872787'
  },
  {
    id: '2128382',
    state_id: '01',
    name: '留萌市',
    name_en: 'Rumoi',
    latitude: '43.934441',
    longitude: '141.642776'
  },
  {
    id: '2128407',
    state_id: '01',
    name: '陸別町',
    name_en: 'Rikubetsu',
    latitude: '43.466671',
    longitude: '143.738068'
  },
  {
    id: '2128412',
    state_id: '01',
    name: '札幌市',
    name_en: 'Retsureppu',
    latitude: '43.116669',
    longitude: '141.366669'
  },
  {
    id: '2128430',
    state_id: '01',
    name: '羅臼町',
    name_en: 'Rausu',
    latitude: '44.018059',
    longitude: '145.191971'
  },
  {
    id: '2128490',
    state_id: '01',
    name: '美深町',
    name_en: 'Bifuka',
    latitude: '44.475281',
    longitude: '142.343063'
  },
  {
    id: '2128498',
    state_id: '01',
    name: '比布町',
    name_en: 'Pippu',
    latitude: '43.869438',
    longitude: '142.473892'
  },
  {
    id: '2128558',
    state_id: '01',
    name: '音更町',
    name_en: 'Otofuke',
    latitude: '42.991669',
    longitude: '143.200287'
  },
  {
    id: '2128574',
    state_id: '01',
    name: '小樽市',
    name_en: 'Otaru',
    latitude: '43.189442',
    longitude: '141.002213'
  },
  {
    id: '2128690',
    state_id: '01',
    name: '北広島市',
    name_en: 'Ōmagari',
    latitude: '42.968891',
    longitude: '141.473892'
  },
  {
    id: '2128710',
    state_id: '01',
    name: '遠軽町',
    name_en: 'Oku-ikutahara',
    latitude: '43.849998',
    longitude: '143.516663'
  },
  {
    id: '2128730',
    state_id: '01',
    name: '置戸町',
    name_en: 'Oketo',
    latitude: '43.671391',
    longitude: '143.589172'
  },
  {
    id: '2128787',
    state_id: '05',
    name: '大館市',
    name_en: 'Ōdate',
    latitude: '40.268608',
    longitude: '140.568329'
  },
  {
    id: '2128815',
    state_id: '01',
    name: '帯広市',
    name_en: 'Obihiro',
    latitude: '42.917221',
    longitude: '143.204437'
  },
  {
    id: '2128852',
    state_id: '01',
    name: '大樹町',
    name_en: 'Kōtoku',
    latitude: '42.51667',
    longitude: '143.149994'
  },
  {
    id: '2128867',
    state_id: '05',
    name: '能代市',
    name_en: 'Noshiro',
    latitude: '40.203892',
    longitude: '140.02417'
  },
  {
    id: '2128894',
    state_id: '01',
    name: '登別市',
    name_en: 'Noboribetsu',
    latitude: '42.451389',
    longitude: '141.173065'
  },
  {
    id: '2128922',
    state_id: '01',
    name: '苫小牧市',
    name_en: 'Nishikioka',
    latitude: '42.599998',
    longitude: '141.483337'
  },
  {
    id: '2128925',
    state_id: '01',
    name: '旭川市',
    name_en: 'Nishi-kagura',
    latitude: '43.683331',
    longitude: '142.399994'
  },
  {
    id: '2128975',
    state_id: '01',
    name: '根室市',
    name_en: 'Nemuro',
    latitude: '43.323608',
    longitude: '145.574997'
  },
  {
    id: '2128983',
    state_id: '01',
    name: '名寄市',
    name_en: 'Nayoro',
    latitude: '44.350559',
    longitude: '142.457779'
  },
  {
    id: '2129003',
    state_id: '01',
    name: '七飯町',
    name_en: 'Honchō',
    latitude: '41.883331',
    longitude: '140.683334'
  },
  {
    id: '2129005',
    state_id: '02',
    name: '青森市',
    name_en: 'Namioka',
    latitude: '40.707218',
    longitude: '140.589447'
  },
  {
    id: '2129048',
    state_id: '01',
    name: '北広島市',
    name_en: 'Nakanosawa',
    latitude: '42.966671',
    longitude: '141.550003'
  },
  {
    id: '2129052',
    state_id: '01',
    name: '紋別市',
    name_en: 'Mobetsu',
    latitude: '44.283329',
    longitude: '143.366669'
  },
  {
    id: '2129063',
    state_id: '01',
    name: '中川町',
    name_en: 'Nakagawa',
    latitude: '44.78611',
    longitude: '142.057785'
  },
  {
    id: '2129072',
    state_id: '01',
    name: '釧路市',
    name_en: 'Naitōbokujō',
    latitude: '43.049999',
    longitude: '144.216675'
  },
  {
    id: '2129073',
    state_id: '01',
    name: '奈井江町',
    name_en: 'Naie',
    latitude: '43.418331',
    longitude: '141.887787'
  },
  {
    id: '2129101',
    state_id: '01',
    name: '室蘭市',
    name_en: 'Muroran',
    latitude: '42.317219',
    longitude: '140.988068'
  },
  {
    id: '2129132',
    state_id: '01',
    name: '妹背牛町',
    name_en: 'Moseushi',
    latitude: '43.687778',
    longitude: '141.959167'
  },
  {
    id: '2129163',
    state_id: '01',
    name: '紋別市',
    name_en: 'Mombetsu',
    latitude: '44.352501',
    longitude: '143.352493'
  },
  {
    id: '2129176',
    state_id: '01',
    name: '札幌市',
    name_en: 'Moiwashita',
    latitude: '43',
    longitude: '141.350006'
  },
  {
    id: '2129210',
    state_id: '01',
    name: '苫小牧市',
    name_en: 'Misawa',
    latitude: '42.75',
    longitude: '141.716675'
  },
  {
    id: '2129211',
    state_id: '02',
    name: '三沢市',
    name_en: 'Misawa',
    latitude: '40.683609',
    longitude: '141.359726'
  },
  {
    id: '2129218',
    state_id: '01',
    name: '余市町',
    name_en: 'Yoichi',
    latitude: '43.203892',
    longitude: '140.770279'
  },
  {
    id: '2129262',
    state_id: '01',
    name: '芽室町',
    name_en: 'Memuro-minami',
    latitude: '42.90667',
    longitude: '143.050568'
  },
  {
    id: '2129282',
    state_id: '01',
    name: '松前町',
    name_en: 'Matsumae',
    latitude: '41.423889',
    longitude: '140.104996'
  },
  {
    id: '2129324',
    state_id: '01',
    name: '稚内市',
    name_en: 'Makubetsu',
    latitude: '45.371391',
    longitude: '141.821106'
  },
  {
    id: '2129327',
    state_id: '01',
    name: '真狩村',
    name_en: 'Makkari',
    latitude: '42.757778',
    longitude: '140.802505'
  },
  {
    id: '2129349',
    state_id: '01',
    name: '北見市',
    name_en: 'Tokorochō-kyōritsu',
    latitude: '44.084438',
    longitude: '144.053894'
  },
  {
    id: '2129350',
    state_id: '01',
    name: '京極町',
    name_en: 'Kyōgoku',
    latitude: '42.855',
    longitude: '140.886932'
  },
  {
    id: '2129365',
    state_id: '01',
    name: '倶知安町',
    name_en: 'Kutchan',
    latitude: '42.901112',
    longitude: '140.740555'
  },
  {
    id: '2129376',
    state_id: '01',
    name: '釧路市',
    name_en: 'Kushiro',
    latitude: '42.974998',
    longitude: '144.374725'
  },
  {
    id: '2129379',
    state_id: '01',
    name: '稚内市',
    name_en: 'Midori',
    latitude: '45.400002',
    longitude: '141.683334'
  },
  {
    id: '2129387',
    state_id: '01',
    name: '黒松内町',
    name_en: 'Kuromatsunai',
    latitude: '42.66806',
    longitude: '140.306107'
  },
  {
    id: '2129395',
    state_id: '02',
    name: '黒石市',
    name_en: 'Kuroishi',
    latitude: '40.638889',
    longitude: '140.592224'
  },
  {
    id: '2129405',
    state_id: '01',
    name: '訓子府町',
    name_en: 'Motomachi',
    latitude: '43.720558',
    longitude: '143.737213'
  },
  {
    id: '2129427',
    state_id: '03',
    name: '久慈市',
    name_en: 'Kuji',
    latitude: '40.187778',
    longitude: '141.76889'
  },
  {
    id: '2129447',
    state_id: '01',
    name: '小清水町',
    name_en: 'Koshimizu',
    latitude: '43.853329',
    longitude: '144.459717'
  },
  {
    id: '2129464',
    state_id: '02',
    name: '平内町',
    name_en: 'Hiranai',
    latitude: '40.92028',
    longitude: '140.955566'
  },
  {
    id: '2129501',
    state_id: '01',
    name: '稚内市',
    name_en: 'Koetoi',
    latitude: '45.40139',
    longitude: '141.752777'
  },
  {
    id: '2129513',
    state_id: '02',
    name: 'つがる市',
    name_en: 'Shimokizukuri',
    latitude: '40.80611',
    longitude: '140.386108'
  },
  {
    id: '2129530',
    state_id: '02',
    name: '八戸市',
    name_en: 'Kitanuma',
    latitude: '40.566669',
    longitude: '141.483337'
  },
  {
    id: '2129537',
    state_id: '01',
    name: '北見市',
    name_en: 'Kitami',
    latitude: '43.803059',
    longitude: '143.890823'
  },
  {
    id: '2129562',
    state_id: '01',
    name: '木古内町',
    name_en: 'Kikonai',
    latitude: '41.673061',
    longitude: '140.437225'
  },
  {
    id: '2129646',
    state_id: '03',
    name: '軽米町',
    name_en: 'Karumai',
    latitude: '40.321388',
    longitude: '141.458328'
  },
  {
    id: '2129653',
    state_id: '02',
    name: '青森市',
    name_en: 'Kareizawa',
    latitude: '40.716671',
    longitude: '140.649994'
  },
  {
    id: '2129696',
    state_id: '01',
    name: '神恵内村',
    name_en: 'Kamoenai',
    latitude: '43.138329',
    longitude: '140.434433'
  },
  {
    id: '2129752',
    state_id: '02',
    name: '七戸町',
    name_en: 'Kamikita Kōzan',
    latitude: '40.736111',
    longitude: '140.956116'
  },
  {
    id: '2129757',
    state_id: '01',
    name: '浦幌町',
    name_en: 'Kawakami',
    latitude: '43.150002',
    longitude: '143.716675'
  },
  {
    id: '2129761',
    state_id: '01',
    name: '上川町',
    name_en: 'Kamikawa',
    latitude: '43.84',
    longitude: '142.771103'
  },
  {
    id: '2129766',
    state_id: '01',
    name: '北斗市',
    name_en: 'Kamiiso',
    latitude: '41.816669',
    longitude: '140.649994'
  },
  {
    id: '2129791',
    state_id: '01',
    name: '中標津町',
    name_en: 'Kaiyō',
    latitude: '43.583328',
    longitude: '144.916672'
  },
  {
    id: '2129868',
    state_id: '01',
    name: '岩内町',
    name_en: 'Iwanai',
    latitude: '42.974442',
    longitude: '140.508896'
  },
  {
    id: '2129870',
    state_id: '01',
    name: '岩見沢市',
    name_en: 'Iwamizawa',
    latitude: '43.200279',
    longitude: '141.75972'
  },
  {
    id: '2129884',
    state_id: '02',
    name: '板柳町',
    name_en: 'Itayanagi',
    latitude: '40.690281',
    longitude: '140.45694'
  },
  {
    id: '2129909',
    state_id: '01',
    name: '石狩市',
    name_en: 'Ishikari',
    latitude: '43.239719',
    longitude: '141.353897'
  },
  {
    id: '2129961',
    state_id: '03',
    name: '一戸町',
    name_en: 'Ichinohe',
    latitude: '40.20694',
    longitude: '141.301666'
  },
  {
    id: '2129984',
    state_id: '01',
    name: '幌延町',
    name_en: 'Horonobe',
    latitude: '45.016941',
    longitude: '141.851395'
  },
  {
    id: '2130001',
    state_id: '01',
    name: '幌加内町',
    name_en: 'Horokanai',
    latitude: '44.005829',
    longitude: '142.154434'
  },
  {
    id: '2130019',
    state_id: '01',
    name: '利尻富士町',
    name_en: 'Hontomari',
    latitude: '45.25',
    longitude: '141.183334'
  },
  {
    id: '2130024',
    state_id: '01',
    name: '大空町',
    name_en: 'Hongō',
    latitude: '43.89814',
    longitude: '144.138733'
  },
  {
    id: '2130032',
    state_id: '01',
    name: '秩父別町',
    name_en: 'Hokuryū',
    latitude: '43.783329',
    longitude: '141.916672'
  },
  {
    id: '2130054',
    state_id: '01',
    name: '北広島市',
    name_en: 'Kitahiroshima',
    latitude: '42.97583',
    longitude: '141.567215'
  },
  {
    id: '2130057',
    state_id: '02',
    name: '弘前市',
    name_en: 'Hirosaki',
    latitude: '40.59306',
    longitude: '140.472504'
  },
  {
    id: '2130061',
    state_id: '01',
    name: '広尾町',
    name_en: 'Hiroo',
    latitude: '42.28389',
    longitude: '143.319168'
  },
  {
    id: '2130075',
    state_id: '01',
    name: '栗山町',
    name_en: 'Hinode',
    latitude: '43.066669',
    longitude: '141.916672'
  },
  {
    id: '2130106',
    state_id: '01',
    name: '苫前町',
    name_en: 'Higashikawa',
    latitude: '44.23333',
    longitude: '141.783325'
  },
  {
    id: '2130116',
    state_id: '01',
    name: '日高町',
    name_en: 'Hidaka',
    latitude: '42.87278',
    longitude: '142.440567'
  },
  {
    id: '2130135',
    state_id: '02',
    name: '青森市',
    name_en: 'Hashimoto',
    latitude: '40.816669',
    longitude: '140.75'
  },
  {
    id: '2130146',
    state_id: '05',
    name: '鹿角市',
    name_en: 'Hanawa',
    latitude: '40.183609',
    longitude: '140.787216'
  },
  {
    id: '2130188',
    state_id: '01',
    name: '函館市',
    name_en: 'Hakodate',
    latitude: '41.775829',
    longitude: '140.736664'
  },
  {
    id: '2130203',
    state_id: '02',
    name: '八戸市',
    name_en: 'Hachinohe',
    latitude: '40.5',
    longitude: '141.5'
  },
  {
    id: '2130204',
    state_id: '02',
    name: '八戸市',
    name_en: 'Hachinohe',
    latitude: '40.505001',
    longitude: '141.491943'
  },
  {
    id: '2130209',
    state_id: '01',
    name: '石狩市',
    name_en: 'Hachimanchō',
    latitude: '43.25',
    longitude: '141.366669'
  },
  {
    id: '2130316',
    state_id: '01',
    name: '礼文町',
    name_en: 'Funadomari',
    latitude: '45.436939',
    longitude: '141.039719'
  },
  {
    id: '2130332',
    state_id: '01',
    name: '深川市',
    name_en: 'Fukagawa',
    latitude: '43.708061',
    longitude: '142.039169'
  },
  {
    id: '2130337',
    state_id: '02',
    name: '藤崎町',
    name_en: 'Fujisaki',
    latitude: '40.650829',
    longitude: '140.491394'
  },
  {
    id: '2130387',
    state_id: '01',
    name: '遠軽町',
    name_en: 'Mukaiengaru',
    latitude: '44.054169',
    longitude: '143.522217'
  },
  {
    id: '2130389',
    state_id: '01',
    name: '遠別町',
    name_en: 'Kanaura',
    latitude: '44.707218',
    longitude: '141.788895'
  },
  {
    id: '2130404',
    state_id: '01',
    name: '江別市',
    name_en: 'Ebetsu',
    latitude: '43.108059',
    longitude: '141.550568'
  },
  {
    id: '2130421',
    state_id: '01',
    name: '伊達市',
    name_en: 'Date',
    latitude: '42.46806',
    longitude: '140.868057'
  },
  {
    id: '2130452',
    state_id: '01',
    name: '千歳市',
    name_en: 'Chitose',
    latitude: '42.819439',
    longitude: '141.652222'
  },
  {
    id: '2130518',
    state_id: '01',
    name: '平取町',
    name_en: 'Biratori',
    latitude: '42.583328',
    longitude: '142.133331'
  },
  {
    id: '2130531',
    state_id: '01',
    name: '美瑛町',
    name_en: 'Biei',
    latitude: '43.584438',
    longitude: '142.459717'
  },
  {
    id: '2130534',
    state_id: '01',
    name: '美唄市',
    name_en: 'Bibai',
    latitude: '43.324718',
    longitude: '141.858612'
  },
  {
    id: '2130557',
    state_id: '01',
    name: '稚内市',
    name_en: 'Minato',
    latitude: '45.400002',
    longitude: '141.683334'
  },
  {
    id: '2130558',
    state_id: '01',
    name: '増毛町',
    name_en: 'Mashike',
    latitude: '43.85194',
    longitude: '141.521393'
  },
  {
    id: '2130567',
    state_id: '01',
    name: '別海町',
    name_en: 'Betsukai',
    latitude: '43.375832',
    longitude: '145.286667'
  },
  {
    id: '2130574',
    state_id: '01',
    name: '石狩市',
    name_en: 'Bannaguro',
    latitude: '43.16861',
    longitude: '141.321106'
  },
  {
    id: '2130603',
    state_id: '01',
    name: '足寄町',
    name_en: 'Ashoro',
    latitude: '43.237221',
    longitude: '143.542786'
  },
  {
    id: '2130612',
    state_id: '01',
    name: '芦別市',
    name_en: 'Ashibetsu',
    latitude: '43.50972',
    longitude: '142.185562'
  },
  {
    id: '2130629',
    state_id: '01',
    name: '旭川市',
    name_en: 'Asahikawa',
    latitude: '43.76778',
    longitude: '142.370285'
  },
  {
    id: '2130658',
    state_id: '02',
    name: '青森市',
    name_en: 'Aomori',
    latitude: '40.82444',
    longitude: '140.740005'
  },
  {
    id: '2130677',
    state_id: '01',
    name: '厚岸町',
    name_en: 'Akkeshi',
    latitude: '43.035561',
    longitude: '144.852493'
  },
  {
    id: '2130700',
    state_id: '01',
    name: '赤井川村',
    name_en: 'Akaigawa',
    latitude: '43.083328',
    longitude: '140.817215'
  },
  {
    id: '2130705',
    state_id: '01',
    name: '赤平市',
    name_en: 'Akabira',
    latitude: '43.551392',
    longitude: '142.053055'
  },
  {
    id: '2130708',
    state_id: '02',
    name: '鰺ヶ沢町',
    name_en: 'Ajigasawa',
    latitude: '40.774441',
    longitude: '140.203064'
  },
  {
    id: '2130723',
    state_id: '01',
    name: '愛別町',
    name_en: 'Aibetsu',
    latitude: '43.906551',
    longitude: '142.577484'
  },
  {
    id: '2130732',
    state_id: '01',
    name: '安平町',
    name_en: 'Abira',
    latitude: '42.816669',
    longitude: '141.833328'
  },
  {
    id: '2130741',
    state_id: '01',
    name: '網走市',
    name_en: 'Abashiri',
    latitude: '44.021271',
    longitude: '144.269714'
  },
  {
    id: '2131612',
    state_id: '02',
    name: '五所川原市',
    name_en: 'Goshogawara',
    latitude: '40.80444',
    longitude: '140.441391'
  },
  {
    id: '2131651',
    state_id: '01',
    name: '浜頓別町',
    name_en: 'Hamatonbetsu',
    latitude: '45.133331',
    longitude: '142.383331'
  },
  {
    id: '1854585',
    state_id: '42',
    name: '五島市',
    name_en: 'Hama',
    latitude: '32.650002',
    longitude: '128.816666'
  },
  {
    id: '1862363',
    state_id: '21',
    name: '高山市',
    name_en: 'Okuhida-onsengō-hitoegane',
    latitude: '36.23333',
    longitude: '137.533325'
  },
  {
    id: '1863997',
    state_id: '42',
    name: '五島市',
    name_en: 'Fukuechō',
    latitude: '32.688061',
    longitude: '128.841934'
  },
  {
    id: '2111937',
    state_id: '13',
    name: '小笠原村',
    name_en: 'Minami',
    latitude: '24.783331',
    longitude: '141.316666'
  }
]
export default citiesJp
