import { IRequest } from 'interfaces/IRequest'

const CustomerRequest = (params?: any, method?: any): IRequest<any> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: method,
    params: params
  }
}

export default CustomerRequest
