import {
  IListOrder,
  ICreateOrderParams,
  IOrderDetailParams,
  IDeleteOrder,
  IUpdateOrderParams
} from 'services/params/order/IorderParam'
import { EOrderAction } from './EOrderAction'
import {
  IGetListOrderAction,
  ICreateOrderAction,
  IOrderDetailAction,
  IDeleteOrderAction,
  IUpdateOrderAction
} from './orderTypes'

export const getListOrderAction = (
  params: IListOrder,
  callBack: (value: any) => void,
  isLoading: boolean
): IGetListOrderAction => ({
  type: EOrderAction.LIST_ORDER,
  params,
  callBack,
  isLoading
})

export const createOrderAction = (
  params: ICreateOrderParams,
  callBack: (value: any) => void,
  isLoading: boolean
): ICreateOrderAction => ({
  type: EOrderAction.CREATE_ORDER,
  params,
  callBack,
  isLoading
})
export const deleteOrderAction = (
  params: IDeleteOrder,
  callBack: (value: any) => void,
  isLoading: boolean
): IDeleteOrderAction => ({
  type: EOrderAction.DELETE_ORDER,
  params,
  callBack,
  isLoading
})

export const getOrderDetailAction = (
  params: IOrderDetailParams,
  callBack: (value: any) => void,
  isLoading?: boolean
): IOrderDetailAction => ({
  type: EOrderAction.ORDER_DETAIL,
  params,
  callBack,
  isLoading
})

export const updateOrderAction = (
  params: IUpdateOrderParams,
  callBack: (value: any) => void,
  isLoading: boolean
): IUpdateOrderAction => ({
  type: EOrderAction.UPDATE_ORDER,
  params,
  callBack,
  isLoading
})
