import { IRequest } from 'interfaces/IRequest'
import { Methods } from 'services/api/EMethod'
import {
  IChangePasswordParams,
  ICheckTokenExistParams,
  IForgotPasswordParams,
  IResetPasswordParams
} from 'services/params/ILoginParams'
import {
  ICreateUser,
  IDeleteUser,
  IListAdmin,
  IListUser,
  IUpdateUserStatus
} from 'services/params/user/IUserParam'

export const createUserRequest = (createUserParam: ICreateUser): IRequest<ICreateUser> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.CREATE_USER,
    params: createUserParam
  }
}
export const getListUserRequest = (request: IListUser): IRequest<IListUser> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_LIST_USER,
    params: request
  }
}
export const deleteUserRequest = (request: IDeleteUser): IRequest<IDeleteUser> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.DELETE_USER,
    params: request
  }
}
export const updateUserStatus = (request: IUpdateUserStatus): IRequest<IUpdateUserStatus> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.UPDATE_USER_STATUS,
    params: request
  }
}
export const getListAdminRequest = (request: IListAdmin): IRequest<IListAdmin> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_LIST_ADMIN,
    params: request
  }
}
export const forgotPasswordRequest = (
  request: IForgotPasswordParams
): IRequest<IForgotPasswordParams> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.FORGOT_PASSWORD,
    params: request
  }
}
export const resetPasswordRequest = (
  request: IResetPasswordParams
): IRequest<IResetPasswordParams> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.RESET_PASSWORD,
    params: request
  }
}
export const changePasswordRequest = (
  request: IChangePasswordParams
): IRequest<IChangePasswordParams> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.CHANGE_PASSWORD,
    params: request
  }
}
export const checkTokenExistRequest = (
  request: ICheckTokenExistParams
): IRequest<ICheckTokenExistParams> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.CHECK_TOKEN_EXIST,
    params: request
  }
}
export default createUserRequest
