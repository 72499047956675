import { EAttributes, ESensorType } from 'interfaces/enums/ESensorType'
import { AttrActionTypes, IAttributeState } from 'redux/actions/attributes/attributeTypes'
import { EAttributeTypes } from 'redux/actions/attributes/EAttributeAction'

const initialState: IAttributeState = {
  attributes: []
}

const autattributeReducer = (state = initialState, action: AttrActionTypes): IAttributeState => {
  switch (action.type) {
    case EAttributeTypes.SET_ATTRIBUTES:
      const newAttr = [...action.data]
      switch (action.sensorType) {
        case ESensorType.PADDY_FILED:
          const item1 = action.data.findIndex(
            (item) => item.attributeKey === EAttributes.WATER_LEVEL
          )
          newAttr[item1].statusShow = true
          const item2 = action.data.findIndex(
            (item) => item.attributeKey === EAttributes.WATER_TEMP
          )
          newAttr[item2].statusShow = true
          break
        case ESensorType.UPLAND_FARMING:
          const item3 = action.data.findIndex((item) => item.attributeKey === EAttributes.EC_VALUE)
          newAttr[item3].statusShow = true
          const item4 = action.data.findIndex((item) => item.attributeKey === EAttributes.WATER)
          newAttr[item4].statusShow = true
          break
        case ESensorType.WEATHER:
          const item5 = action.data.findIndex(
            (item) => item.attributeKey === EAttributes.AMOUNT_RAIN
          )
          newAttr[item5].statusShow = true
          const item6 = action.data.findIndex(
            (item) => item.attributeKey === EAttributes.WIND_DIRECTION
          )
          newAttr[item6].statusShow = true
          break
        default:
          break
      }
      return {
        ...state,
        attributes: action.data
      }
    default:
      return state
  }
}

export default autattributeReducer
