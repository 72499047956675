import { IRequest } from 'interfaces/IRequest'
import { Methods } from 'services/api/EMethod'
import {
  IGetListGroupByEmail,
  IGetListSensorByGroup,
  IGroupDetail
} from 'services/params/group/IGroupParams'
export const groupDetailRequest = (param: IGroupDetail): IRequest<IGroupDetail> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GROUP_DETAIL,
    params: param
  }
}
export const getListSensorByGroupRequest = (
  param: IGetListSensorByGroup
): IRequest<IGetListSensorByGroup> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_SENSOR_BY_GROUP,
    params: param
  }
}
export const getListGroupByEmailRequest = (
  param: IGetListGroupByEmail
): IRequest<IGetListGroupByEmail> => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.GET_LIST_GROUP,
    params: param
  }
}
