import { ESerialNoAction } from 'redux/actions/serialNo/ESerialNoAction'
import { ISerialNoState, SerialNoActionTypes } from 'redux/actions/serialNo/serialNoTypes'

const initState: ISerialNoState = {
  serialNoList: {
    content: [],
    currentPage: 1,
    totalPages: 1,
    payloadSize: 20,
    hasNext: false,
    skippedRecords: 0,
    totalRecords: 0
  },
  serialDetail: {
    serialNo: '',
    customerName: '',
    deviceId: '',
    communicationType: 1,
    sensorType: 1,
    sercurityKey: '',
    location: '',
    orderStatus: false,
    updatedAt: ''
  }
}
const serialNoReducer = (
  state: ISerialNoState = initState,
  action: SerialNoActionTypes
): ISerialNoState => {
  switch (action.type) {
    case ESerialNoAction.CREATE_INDIVIDUAL:
      return {
        ...state
      }
    default:
      return state
  }
}
export default serialNoReducer
