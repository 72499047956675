import { Methods } from 'services/api/EMethod'
import {
  IKatashiKiParamsGetList,
  IKatashikiParamUpdateItem,
  IKatashikiParamCreateItem
} from 'services/params/katashiki/IKatashikiParams'

export const KatashikiSearch = (params: IKatashiKiParamsGetList) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.KATASHIKI_SEARCH,
    params: params
  }
}

export const KatashikiGetKeyword = () => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.KATASHIKI_GET_KEYWORD,
    params: {}
  }
}

export const KatashikiGetSensorType = () => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.KATASHIKI_GET_SENSOR_TYPE,
    params: {}
  }
}

export const KatashikiRemoveItem = (param: number) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.KATASHIKI_REMOVE_ITEM,
    params: {
      id: param
    }
  }
}

export const KatashikiUpdateItem = (param: IKatashikiParamUpdateItem) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.KATASHIKI_UPDATE_ITEM,
    params: param
  }
}

export const KatashikiCreateItem = (param: IKatashikiParamCreateItem) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.KATASHIKI_CREATE,
    params: param
  }
}
