import { Col, Row } from 'antd'
import VButton from 'components/button/VButton'
import GroupButtonActionSearchComponent from 'components/common/button/GroupButtonActionSearchComponent'
import { useTranslation } from 'react-i18next'
import { defaultShowBlank, defaultValueAll } from '../sensor/defaultValue'
import SelectComponent from 'components/common/select/SelectComponent'
import { ColumnsType } from 'antd/es/table'
import { ConvertTime, showTotalRecord, sortListAttByAttributeIndex } from 'utils/Functions'
import { useState, useEffect } from 'react'
import PaginationComponent from 'components/common/pagination/PaginationComponent'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'
import {
  IconCopyDisplay,
  IconDeleteModal,
  IconDeleteRed,
  IconDetailBtn,
  IconEditBlue
} from 'assets'
import ModalComponent from 'components/modal/ModalComponent'
import { useDispatch, useSelector } from 'react-redux'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import { ADMIN_ROUTE, routesDisplayManagement } from 'views/routes/routes'
import { useHistory } from 'react-router'
import TableComponent from 'components/common/table/TableComponent'
import {
  cleanFormulaAction,
  copyFormulaAction,
  deleteFormulaAction,
  getDataDisplayAction,
  setFormulaCopyAction
} from 'redux/actions/display/displayAction'
import { convertDataDisplay, convertListOptionSensor, listAttributeTv } from './convertData'
import { AppState } from 'redux/reducers'
import { originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import { IDisplay } from 'services/params/display/IDisplay'
import { initialFormulaCopy } from '../multiSettings/initialState'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import { convertListEmail } from '../sensor/convertData'
import { searchSensorAction } from 'redux/actions/sensor/sensorAction'
import { IOption } from 'interfaces/sensor/IPropsSelect'
import { dataKeyPrefixTv } from '../multiSettings/defaultValue'
import { getListSensorAction } from 'redux/actions/serialNo/serialNoAction'
import { ISensorPullDownType, ISensorTranslate } from 'interfaces/ISerialNo'
import Collapsed from '../serialNo/serialNoManagement/components/collapsed'

interface IDataType {
  key: string
  no: number
  settingType: string
  settingTarget: string
  sensorName: string
  lteOrLora: string
  email: string
  userName: string
  deviceId: string
  setting: string
  statusFormula: boolean
  dateTimeRegis: string
  dateTimeUpdate: string
}
const getData = (current: number, pageSize: number, data: IDataType[]) => {
  if (data) {
    return data.slice((current - 1) * pageSize, current * pageSize)
  }
}

const DisplayManagement = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  const masterData = useSelector((state: AppState) => state?.common?.masterData)
  const history = useHistory()
  const defaultListEmail = convertListEmail(masterData?.email)
  // search
  const [isSearch, setIsSearch] = useState<boolean>(false)

  const gotoIndividualSettings = () => {
    // const path = ADMIN_ROUTE.concat(routesDisplayManagement.INDIVIDUAL_SETTINGS)
    const path = ADMIN_ROUTE.concat(routesDisplayManagement.CREATE_FORMULA)
    dispatch(setFormulaCopyAction(initialFormulaCopy))
    history.push(path)
  }
  // state select option LteOrLora sensor
  const [lteOrLora, setLteOrLora] = useState<any>(defaultValueAll)
  const [listLteOrLora, setListLteOrLora] = useState<any>([])
  const [listSensorTranslateType, setListSensorTranslateType] = useState<any>([])
  const handleOnChangeSelectLteOrLora = (valueLteOrLora: string) => {
    setLteOrLora(valueLteOrLora)
    setSettingTarget(defaultValueAll)
  }

  // state select option setting target
  const [settingTarget, setSettingTarget] = useState<string>('all')
  const handleOnChangeSelectSettingTarget = (valueSettingTarget: string) => {
    setSettingTarget(valueSettingTarget)
  }
  // state email
  const [email, setEmail] = useState<string>(defaultValueAll)
  // state user name
  const [listSensor, setListSensor] = useState<IOption[]>([])
  const [sensor, setSensor] = useState<string>(defaultValueAll)
  // state pagination
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  // state data  table
  const [dataTable, setDataTable] = useState<IDataType[]>([])
  const handleOnChangePage = (pageChange: number) => {
    setCurrent(pageChange)
  }
  const handleOnChangePageSize = (size: number) => {
    const totalPageChange = Math.ceil(dataTable?.length / size)
    if (current > totalPageChange) {
      setCurrent(totalPageChange)
    }
    setPageSize(size)
  }
  // item delete
  const [idItemDelete, setIdItemDelete] = useState<string>('')
  // modal delete
  const [modalDelete, setModalDelete] = useState({
    isModalVisible: false,
    content: t('modal.contentDelDisplay')
  })

  // detail
  const handleOnClickDetail = (type: string, key: string) => {
    const path = ADMIN_ROUTE.concat(routesDisplayManagement.DETAIL_FORMULA)
    history.push({
      pathname: path,
      state: {
        key: key
      }
    })
  }
  // copy
  const handleOnClickCopy = (record: any) => {
    const param = {
      formulaId: record?.key
    }
    dispatch(
      copyFormulaAction(true, param, (data) => {
        if (data?.result) {
          // const path =
          //   data?.result?.type === settingTypeDefault.sensor
          //     ? ADMIN_ROUTE.concat(routesDisplayManagement.INDIVIDUAL_SETTINGS)
          //     : ADMIN_ROUTE.concat(routesDisplayManagement.MULTI_SETTINGS)
          // get list att tm and sort
          let listAttTm = data?.result?.formulaDetail?.filter(
            (item: any) => item?.dataKeyPrefix !== dataKeyPrefixTv
          )
          listAttTm = sortListAttByAttributeIndex(listAttTm)
          // get list att tv and sort
          let listAttTv = listAttributeTv(data?.result?.formulaDetail)
          listAttTv = sortListAttByAttributeIndex(listAttTv)
          // list att sort
          const arrResult = listAttTm?.concat(listAttTv)
          const path = ADMIN_ROUTE.concat(routesDisplayManagement.CREATE_FORMULA)
          const listAttributeTvAll = data?.result?.formulaDetail?.filter(
            (item: any) => item?.dataKeyPrefix === dataKeyPrefixTv
          )
          const sortArrAttributeTv = sortListAttByAttributeIndex(listAttributeTvAll)
          const dataCopy = {
            id: data?.result?.id,
            type: data?.result?.type,
            formulaType: 'copy',
            /* timeApply: data?.result?.timeApply, */
            sensorType: data?.result?.sensorType,
            parentType: record?.lteOrLora,
            description: data?.result?.description,
            listAttribute: arrResult,
            sensorName: '',
            email: data?.result?.email,
            deviceId: '',
            numberAttributeTv: listAttTv?.length ?? 0,
            lastIndexAttributeTv: sortArrAttributeTv?.length
              ? sortArrAttributeTv?.[sortArrAttributeTv?.length - 1]?.attributeIndex
              : 0
          }
          dispatch(setFormulaCopyAction(dataCopy))
          history.push(path)
          dispatch(SetLoadingAction(false))
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
  }
  const handleGetListSensor = () => {
    const params = {
      type: lteOrLora === defaultValueAll || lteOrLora === 'LoRa' ? 'LoRa' : ''
    }
    dispatch(
      getListSensorAction(
        { ...params },
        async (data) => {
          if (data && data?.result) {
            dispatch(SetLoadingAction(false))
            const resultCommsType: any = []
            const resultTypes: any = []
            data.result.sensorTypes = await data.result.sensorTypes.map(
              (
                { sensorTypeId, sensorTypeKey }: ISensorPullDownType // eslint-disable-next-line
              ) => {
                resultCommsType.push({
                  id: sensorTypeId,
                  name: sensorTypeKey,
                  value: sensorTypeKey
                })
              }
            )
            if (lteOrLora === 'LoRa') {
              data.result.sensorTranslate = await data.result.sensorTranslate.slice(0, 3).map(
                (
                  { sensorTypeTranslateId, sensorTypeName, sensorTypeKey }: ISensorTranslate // eslint-disable-next-line
                ) => {
                  resultTypes.push({
                    id: sensorTypeTranslateId,
                    name: sensorTypeName,
                    value: sensorTypeKey
                  })
                }
              )
            } else {
              data.result.sensorTranslate = await data.result.sensorTranslate.slice(0, 2).map(
                (
                  { sensorTypeTranslateId, sensorTypeName, sensorTypeKey }: ISensorTranslate // eslint-disable-next-line
                ) => {
                  resultTypes.push({
                    id: sensorTypeTranslateId,
                    name: sensorTypeName,
                    value: sensorTypeKey
                  })
                }
              )
            }
            setListLteOrLora(resultCommsType)
            setListSensorTranslateType(resultTypes)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        false
      )
    )
  }
  useEffect(() => {
    handleGetListSensor()
    // eslint-disable-next-line
  }, [lteOrLora])

  // delete
  const handleOnClickDelete = (id: string) => {
    setIdItemDelete(id)
    setModalDelete({
      ...modalDelete,
      isModalVisible: true
    })
  }
  const handleOnSubmitDelete = async () => {
    const param = {
      formulaId: idItemDelete
    }
    dispatch(
      deleteFormulaAction(true, param, (data) => {
        if (data?.result) {
          const dataSource = [...dataTable]
          const listFormula = dataSource?.filter((item) => item?.key !== idItemDelete)
          setDataTable(listFormula)
          const totalPageChange = Math.ceil(listFormula?.length / pageSize)
          if (totalPageChange === current - 1) {
            setCurrent(totalPageChange)
          }
          setModalDelete({
            ...modalDelete,
            isModalVisible: false
          })
          success(t('modal.contentSuccessDisplay'))
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
    setModalDelete({
      ...modalDelete,
      isModalVisible: false
    })
  }
  const success = (text: string) => {
    // modal success
    const stateSuccess = {
      isVisible: true,
      content: text
    }
    dispatch(SuccessAction({ ...stateSuccess }))
  }

  const handleOnChangeSelectEmail = (valueEmail: string) => {
    setEmail(valueEmail)
  }
  const handleOnChangeSensor = (valueSensor: string) => {
    setSensor(valueSensor)
  }

  const handleEditFormula = (id: string, type: string) => {
    dispatch(cleanFormulaAction())
    const path = ADMIN_ROUTE.concat(routesDisplayManagement.EDIT_FORMULA).concat('/').concat(id)
    history.push(path)
  }
  // render colums
  const columns: ColumnsType<IDataType> = [
    {
      title: t('caculation.table.no'),
      dataIndex: 'no',
      width: '6%',
      render: (text, row, index) => {
        return <span>{(current - 1) * pageSize + (index + 1)}</span>
      }
    },
    {
      title: t('caculation.table.sensorName'),
      dataIndex: 'sensorName',
      width: '10%',
      render: (_: object, record: { sensorName: string }) => {
        return (
          <TooltipComponent
            maxSlice={8}
            title={record?.sensorName ? record?.sensorName : defaultShowBlank}
          />
        )
      }
    },
    {
      title: t('caculation.table.deviceId'),
      dataIndex: 'deviceId',
      width: '14%',
      render: (_: object, record: { deviceId: string }) => {
        return (
          <>
            {record?.deviceId ? (
              <TooltipComponent maxSlice={25} title={record?.deviceId} />
            ) : (
              defaultShowBlank
            )}
          </>
        )
      }
    },
    {
      title: t('caculation.table.emailAddress'),
      dataIndex: 'email',
      width: '18%',
      render: (_: object, record: { email: string }) => {
        return (
          <>
            {record?.email ? (
              <TooltipComponent maxSlice={25} title={record?.email} />
            ) : (
              defaultShowBlank
            )}
          </>
        )
      }
    },
    {
      title: t('caculation.table.setting'),
      dataIndex: 'setting',
      render: (_: object, record: { setting: string }) => {
        return (
          <>{record?.setting ? <TooltipComponent maxSlice={25} title={record?.setting} /> : ''}</>
        )
      },
      width: '18%'
    },
    {
      title: t('caculation.table.dateTimeUpdate'),
      dataIndex: 'dateTimeUpdate',
      width: '15%',
      render: (_: object, record: { dateTimeUpdate: string }) => {
        return record?.dateTimeUpdate ? ConvertTime(record?.dateTimeUpdate) : defaultShowBlank
      }
    },
    {
      title: t('caculation.table.detail'),
      dataIndex: '',
      width: '58px',
      align: 'center',
      render: (_: object, record: { key: string; settingType: string }) => {
        return (
          <img
            onClick={() => handleOnClickDetail(record?.settingType, record?.key)}
            src={IconDetailBtn}
            className='w42x-h28x pointer'
            alt='detail'
          />
        )
      }
    },
    ROLE_CRUD.includes(role)
      ? {
          title: t('caculation.table.copy'),
          dataIndex: '',
          width: '58px',
          align: 'center',
          render: (_: object, record: any) => {
            return (
              <div>
                <img
                  src={IconCopyDisplay}
                  onClick={() => handleOnClickCopy(record)}
                  className='w42x-h28x pointer'
                  alt='copy'
                />
              </div>
            )
          }
        }
      : {},
    ROLE_CRUD.includes(role)
      ? {
          title: t('caculation.table.edit'),
          dataIndex: '',
          width: '58px',
          align: 'center',
          render: (
            _: object,
            record: {
              settingType: string
              statusFormula: boolean
              key: string
            }
          ) => {
            return (
              <div>
                <img
                  onClick={() => handleEditFormula(record?.key, record?.settingType)}
                  src={IconEditBlue}
                  className='w42x-h28x pointer'
                  alt='edit'
                />
              </div>
            )
          }
        }
      : {},
    ROLE_CRUD.includes(role)
      ? {
          title: t('caculation.table.delete'),
          dataIndex: '',
          width: '58px',
          align: 'center',
          render: (_: object, record: { key: string }) => {
            return (
              <img
                src={IconDeleteRed}
                onClick={() => handleOnClickDelete(record?.key)}
                className='w42x-h28x pointer'
                alt='delete'
              />
            )
          }
        }
      : {}
  ]
  const dropDownDefault = () => {
    setLteOrLora(defaultValueAll)
    setSettingTarget(defaultValueAll)
    setEmail(defaultValueAll)
    setSensor(defaultValueAll)
  }
  const getListDisplay = async (param: IDisplay, pageFirst: boolean, pageSearch: boolean) => {
    await dispatch(
      getDataDisplayAction(true, param, (data) => {
        if (data?.result) {
          const dataDisplayConvert = convertDataDisplay(data?.result?.record)
          if (pageFirst) {
            setCurrent(1)
          }
          if (pageSearch) {
            setIsSearch(true)
          }
          setDataTable(dataDisplayConvert)
          dispatch(SetLoadingAction(false))
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
  }

  const handleOnClickBtnSubmit = async () => {
    setIsSearch(true)
    const param = {
      sensorTypeKey: settingTarget === defaultValueAll ? '' : settingTarget,
      userEmail: email === defaultValueAll ? '' : email,
      parentSensorTypeKey: lteOrLora === defaultValueAll ? '' : lteOrLora,
      sensorName: sensor === defaultValueAll ? '' : sensor,
      parentTypeId: lteOrLora === 'LoRa' ? 1 : lteOrLora !== 'LoRa' ? 2 : null,
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    getListDisplay(param, true, true)
  }
  const handleOnClickBtnCancel = () => {
    setIsSearch(false)
    dropDownDefault()
    const param = {
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    getListDisplay(param, true, false)
  }
  useEffect(() => {
    const param = {
      sortBy: {
        target: 'createdAt',
        order: 'DESC'
      }
    }
    getListDisplay(param, false, false)
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    const param = {
      sensorTypeKey: settingTarget === defaultValueAll ? '' : settingTarget,
      userEmail: email === defaultValueAll ? '' : email,
      parentSensorTypeKey: lteOrLora === defaultValueAll ? '' : lteOrLora,
      groupName: '',
      sortBy: {
        target: 'createdAt',
        order: 'ASC'
      }
    }
    dispatch(
      searchSensorAction(true, { ...param }, (data) => {
        if (data?.result) {
          const dataSensorConvert = convertListOptionSensor(data?.result?.record)
          dispatch(SetLoadingAction(false))
          setListSensor(dataSensorConvert)
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
    // eslint-disable-next-line
  }, [dispatch, email, settingTarget, lteOrLora])
  return (
    <div className='display-management'>
      <div className='display-redirect'>
        {ROLE_CRUD.includes(role) ? (
          <div className='bgrffffff'>
            <div className='pd_16x_0x'>
              <div className='mg_0_16x'>
                <div className='redirect-btn'>
                  <VButton
                    title={t('caculation.btnRegisFormula')}
                    style={styleBtnSensor}
                    onClick={gotoIndividualSettings}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Collapsed isBottomSpace={false}>
        <div className='display-table'>
        <div className=''>
          <div className=''>
            <div className=''>
              <div className='table-search-title font-w-bold fs-16x'>
                {t('caculation.caculationSetting')}
              </div>
              <div className='table-search-option ss-drop-drown'>
                <Row>
                  <Col span={12} className='pr-7x'>
                    <SelectComponent
                      lable={t('caculation.table.lteOrLora')}
                      defaultOption={lteOrLora}
                      dataOption={listLteOrLora}
                      onChangeSelect={handleOnChangeSelectLteOrLora}
                    />
                  </Col>
                  <Col span={12} className='pl-7x'>
                    <SelectComponent
                      lable={t('caculation.selectEmail')}
                      defaultOption={email}
                      dataOption={defaultListEmail}
                      onChangeSelect={handleOnChangeSelectEmail}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} className='pr-7x'>
                    <SelectComponent
                      lable={t('caculation.sensorType')}
                      defaultOption={settingTarget}
                      dataOption={listSensorTranslateType}
                      onChangeSelect={handleOnChangeSelectSettingTarget}
                    />
                  </Col>
                  <Col span={12} className='pl-7x'>
                    <SelectComponent
                      lable={t('caculation.sensorName')}
                      defaultOption={sensor}
                      dataOption={listSensor}
                      onChangeSelect={handleOnChangeSensor}
                    />
                  </Col>
                </Row>
                <div className='text-align-right'>
                  <GroupButtonActionSearchComponent
                    onClickBtnSubmit={handleOnClickBtnSubmit}
                    onClickBtncancel={handleOnClickBtnCancel}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Collapsed>
      <div className='display-table mt-16x'>
        <div className='bgrffffff'>
          <div className=''>
            <div className='display-management-table'>
              <div className=''>
                <div className='d-flex justify-content-flex-end align-items-center mg-pagination'>
                  <div className='d-flex justify-content-flex-end font-w-bold pd_16x_0x total-record'>
                    {showTotalRecord(dataTable?.length)}
                  </div>
                  <div className='display-err-management-pagination'>
                    <PaginationComponent
                      onChangePageSize={(size) => handleOnChangePageSize(size)}
                      total={dataTable?.length}
                      onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                      pageSize={pageSize}
                      isShowSizeChanger={false}
                      currentpage={current}
                    />
                  </div>
                </div>
              </div>
              <div className='custom-table'>
                <TableComponent
                  columns={columns}
                  dataSource={getData(current, pageSize, dataTable)}
                  isSearch={isSearch}
                />
              </div>
            </div>
            <div className='mg_0_16x'>
              <div className='mg-pagination'>
                <PaginationComponent
                  onChangePageSize={(size) => handleOnChangePageSize(size)}
                  total={dataTable?.length}
                  onChangePage={(pageChange) => handleOnChangePage(pageChange)}
                  pageSize={pageSize}
                  isShowSizeChanger={true}
                  currentpage={current}
                />
              </div>
            </div>
            <div className='btn-return text-align-center'>
              <ButtonReturnComponent />
            </div>
          </div>
        </div>
      </div>
      {modalDelete?.isModalVisible ? (
        <ModalComponent
          isVisible={modalDelete?.isModalVisible}
          img={IconDeleteModal}
          content={modalDelete?.content}
          onCancelModal={() =>
            setModalDelete({
              ...modalDelete,
              isModalVisible: false
            })
          }
          btnCancel={t('modal.btnCancel')}
          btnSubmit={t('modal.btnDelete')}
          onSubmitModal={handleOnSubmitDelete}
        />
      ) : null}
    </div>
  )
}
const styleBtnSensor = {
  height: 38,
  width: 140
}

export default DisplayManagement
