import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { DELAY } from 'interfaces/enums/EDelay'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, put, call, takeLatest, delay } from 'redux-saga/effects'

import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { EKatashikiActions } from 'redux/actions/katashiki/EKatashikiAction'
import {
  IKatashikiGetKeywordType,
  IKatashikiRemoveItemType,
  IKatashikiSearchType,
  IKatashikiSensorType,
  IKatashikiUpdateItemType,
  IKatashikiCreateItemType
} from 'redux/actions/katashiki/katashikiTypes'
import { KatashikiAPI } from 'services/api/katashiki/katashikiApi'

function* katashikiSearch(action: IKatashikiSearchType) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      KatashikiAPI.katashikiSearch,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    yield put(SetLoadingAction(false))
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
  }
}

function* katashikiGetKeyword(action: IKatashikiGetKeywordType) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(KatashikiAPI.katashikiGetKeyword)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
  }
}

function* katashikiGetSensorType(action: IKatashikiSensorType) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(KatashikiAPI.katashikiGetSensorType)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
  }
}

function* katashikiRemoveItem(action: IKatashikiRemoveItemType) {
  let err: any = null
  yield put(SetLoadingAction(true))
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      KatashikiAPI.KatashikiRemoveItem,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}

function* katashikiUpdateItem(action: IKatashikiUpdateItemType) {
  let err: any = null
  yield put(SetLoadingAction(true))
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      KatashikiAPI.KatashikiUpdateItem,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}

function* katashikiCreateItem(action: IKatashikiCreateItemType) {
  let err: any = null
  yield put(SetLoadingAction(true))
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      KatashikiAPI.KatashikiCreateItem,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
    }
    yield put(SetLoadingAction(false))
  }
}

export default function* katashikiSaga() {
  yield all([takeLatest(EKatashikiActions.SEARCH, katashikiSearch)])
  yield all([takeLatest(EKatashikiActions.GET_KEY_WORDS, katashikiGetKeyword)])
  yield all([takeLatest(EKatashikiActions.GET_SENSOR_TYPE, katashikiGetSensorType)])
  yield all([takeLatest(EKatashikiActions.REMOVE_ITEM, katashikiRemoveItem)])
  yield all([takeLatest(EKatashikiActions.UPDATE_ITEM, katashikiUpdateItem)])
  yield all([takeLatest(EKatashikiActions.CREATE_ITEM, katashikiCreateItem)])
}
