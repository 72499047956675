import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'styles/InputDate.scss'
import { IPropsInputDate } from 'interfaces/input/IPropsInputDate'
import { ja } from 'date-fns/locale'
import { forwardRef } from 'react'
import moment from 'moment'

const InputYearComponent = ({
  refer,
  state,
  readOnly,
  onChangeDate,
  maxDate,
  minDate,
  disabled,
  selectStart,
  selectEnd,
  startDate,
  endDate,
  describeText,
  customPopper
}: IPropsInputDate) => {
  const CustomInputYear = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className='custom-input-year pointer' onClick={onClick} ref={ref}>
      <div className='date-text'>{value}</div>
    </div>
  ))
  return (
    <div className='ip-date'>
      <DatePicker
        closeOnScroll={true}
        ref={refer}
        selected={state}
        dateFormat='yyyy'
        onChange={onChangeDate}
        autoComplete='off'
        className='ip-year'
        disabledKeyboardNavigation
        maxDate={moment(new Date()).add(10, 'years').toDate()}
        // minDate={moment(new Date()).subtract(51, 'years').toDate()}
        readOnly={readOnly}
        disabled={disabled}
        locale={ja}
        customInput={<CustomInputYear />}
        selectsStart={selectStart}
        startDate={startDate}
        selectsEnd={selectEnd}
        endDate={endDate}
        popperPlacement={customPopper}
        yearItemNumber={15}
        showYearPicker
      />
      <span className='describe-text'>{describeText}</span>
    </div>
  )
}

export default InputYearComponent
