import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { DELAY } from 'interfaces/enums/EDelay'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, put, call, takeLatest, delay } from 'redux-saga/effects'

import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import {
  ICopyFormulaAction,
  ICreateFormulaAction,
  IDeleteFormulaAction,
  IGetDataDisplayAction,
  IGetDetailFormulaAction
} from 'redux/actions/display/displayTypes'
import { EDisplayActions } from 'redux/actions/display/EDisplayAction'
import { DisplayApi } from 'services/api/display/displayApi'

function* getDataDisplaySaga(action: IGetDataDisplayAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(DisplayApi.getDataDisplay, action.param)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* deleteFormulaSaga(action: IDeleteFormulaAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(DisplayApi.deleteFormula, action.param)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* copyFormulaSaga(action: ICopyFormulaAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(DisplayApi.copyFormula, action.param)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

function* createFormulaSaga(action: ICreateFormulaAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(DisplayApi.createFormula, action.param)
    if (response?.data?.result) {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    } else {
      if (response?.data?.error && response?.data?.error?.code === 400) {
        action.callBack(response?.data?.error)
      } else {
        err = response?.data?.error
      }
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

function* getDetailFormulaSaga(action: IGetDetailFormulaAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      DisplayApi.getDetailFormula,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
export default function* displaySaga() {
  yield all([
    takeLatest(EDisplayActions.DISPLAY_LIST, getDataDisplaySaga),
    takeLatest(EDisplayActions.DELETE_FORMULA, deleteFormulaSaga),
    takeLatest(EDisplayActions.COPY_FORMULA, copyFormulaSaga),
    takeLatest(EDisplayActions.CREATE_FORMULA, createFormulaSaga),
    takeLatest(EDisplayActions.GET_DETAIL_FORMULA, getDetailFormulaSaga)
  ])
}
