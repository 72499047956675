import { ColumnsType } from 'antd/lib/table'
import { IDataUserInformation } from 'interfaces/sensor/ISensorDetail'
// import { Link } from 'react-router-dom'
import { ConvertTime } from 'utils/Functions'
// import { ADMIN_ROUTE, routesName } from 'views/routes/routes'
import i18n from 'configs/i18n'
import TooltipComponent from 'components/common/tooltip/TooltipComponent'
import { defaultShowBlank } from '../defaultValue'

// render colums
const columnsUserInformation: ColumnsType<IDataUserInformation> = [
  {
    title: i18n.t('sensorDetail.tableUser.no'),
    dataIndex: 'no',
    width: '5%'
  },
  {
    title: i18n.t('sensorDetail.tableUser.userName'),
    dataIndex: 'userName',
    render: (_: object, record: { userName: string }) => {
      return (
        <TooltipComponent
          maxSlice={20}
          title={record?.userName ? record?.userName : defaultShowBlank}
        />
      )
    },
    width: '15%'
  },
  {
    title: i18n.t('sensorDetail.tableUser.email'),
    dataIndex: 'email',
    width: '20%',
    render: (_: object, record: { key: string; email: string }) => {
      return (
        <TooltipComponent maxSlice={20} title={record?.email ? record?.email : defaultShowBlank} />
      )
    }
  },
  {
    title: i18n.t('sensorDetail.tableUser.groupName'),
    dataIndex: 'groupName',
    render: (_: object, record: { groupName: string }) => {
      return (
        <TooltipComponent
          maxSlice={20}
          title={record?.groupName ? record?.groupName : defaultShowBlank}
        />
      )
    },
    width: '20%'
  },
  {
    title: i18n.t('sensorDetail.tableUser.sensorName'),
    dataIndex: 'sensorName',
    render: (_: object, record: { sensorName: string }) => {
      return (
        <TooltipComponent
          maxSlice={20}
          title={record?.sensorName ? record?.sensorName : defaultShowBlank}
        />
      )
    },
    width: '20%'
  },
  {
    title: i18n.t('sensorDetail.tableUser.registerDate'),
    dataIndex: 'registerDate',
    render: (_: object, record: { registerDate: string }) => {
      return ConvertTime(record?.registerDate)
    }
  }
]
export { columnsUserInformation }
