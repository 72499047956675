/* eslint-disable */

import { Col, Form, Row } from 'antd'
import VButton from 'components/button/VButton'
import InputComponent from 'components/common/input/InputComponent'
import InputMonthComponent from 'components/common/input/InputMonthComponent'
import InputYearComponent from 'components/common/input/InputYearComponent'
import SelectComponent from 'components/common/select/SelectComponent'
import { IPullDownType, ISensorPullDownType, ISensorTranslate } from 'interfaces/ISerialNo'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import {
  getBulkSerialNoDetailAction,
  getListKatashikiAction,
  getListSensorAction,
  updateBulkSerialNoAction
} from 'redux/actions/serialNo/serialNoAction'
import { ADMIN_ROUTE, routeSerial } from 'views/routes/routes'
import { defaultFactoryList } from '../../defaultValue'

function EditBulkSerialNo() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const parentId = useParams<any>()
  const [year, setYear] = useState<Date | null>()
  const refMonth = useRef()
  const [month, setMonth] = useState<Date | null>()
  const refYear = useRef()
  const [selectCommsType, setSelectCommsType] = useState<any>('LoRa')
  const [communicationTypeList, setCommunicationTypeList] = useState<any>([])
  const [selectType, setSelectType] = useState<any>()
  const [typeList, setTypeList] = useState<any>([])
  const [selectEquipmentType, setSelectEquipmentType] = useState<any>()
  const [equipmentTypeList, setEquipmentTypeList] = useState<any>([])
  const [selectFactory, setSelectFactory] = useState<any>()
  const [factoryList, setFactoryList] = useState<any>([])
  useEffect(() => {
    handleGetListSensor()
    // eslint-disable-next-line
  }, [selectCommsType])
  useEffect(() => {
    handleGetListKatashiki()
    setFactoryList(defaultFactoryList)
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    handleGetSerialDetail()
    // eslint-disable-next-line
  }, [])
  const handleOnSelectFactory = (currentFactory: any) => {
    setSelectFactory(currentFactory)
  }
  // change year
  const handleOnChangeYear = (year: Date) => {
    setYear(year)
  }
  const handleOnChangeMonth = (month: Date) => {
    setMonth(month)
  }
  const checkDisable = () => {
    if (
      selectType &&
      selectCommsType &&
      selectEquipmentType &&
      year &&
      month &&
      !form.getFieldsError().filter(({ errors }) => errors.length).length
    ) {
      return false
    }
    return true
  }
  // data for dropdown katashiki
  const handleGetListKatashiki = () => {
    const params = {}
    dispatch(
      getListKatashikiAction(
        { ...params },
        (data) => {
          if (data && data?.result) {
            let listResult: any = []
            data.result = data.result.map(
              (
                { id, model, deletedAt }: IPullDownType // eslint-disable-next-line
              ) => {
                if (deletedAt === null && model !== '') {
                  listResult.push({
                    id: id,
                    name: model,
                    value: id
                  })
                }
              }
            )
            listResult.sort((a: any, b: any) => a.id - b.id)
            setTypeList(listResult)
            dispatch(SetLoadingAction(false))
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }
  // data for dropdown sensor
  const handleGetListSensor = () => {
    const params = {
      type: selectCommsType === 1 || selectCommsType === 'LoRa' ? 'LoRa' : ''
    }
    dispatch(
      getListSensorAction(
        { ...params },
        (data) => {
          if (data && data?.result) {
            dispatch(SetLoadingAction(false))
            const resultCommsType: any = []
            const resultTypes: any = []
            data.result.sensorTypes = data.result.sensorTypes.map(
              (
                { sensorTypeId, sensorTypeKey }: ISensorPullDownType // eslint-disable-next-line
              ) => {
                resultCommsType.push({
                  id: sensorTypeId,
                  name: sensorTypeKey,
                  value: parseInt(sensorTypeId, 10)
                })
              }
            )
            data.result.sensorTranslate = data.result.sensorTranslate.map(
              (
                { sensorTypeTranslateId, sensorTypeName }: ISensorTranslate // eslint-disable-next-line
              ) => {
                resultTypes.push({
                  id: sensorTypeTranslateId,
                  name: sensorTypeName,
                  value: parseInt(sensorTypeTranslateId, 10)
                })
              }
            )
            setCommunicationTypeList(resultCommsType)
            setEquipmentTypeList(resultTypes)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        false
      )
    )
  }
  // handle get parent detail
  const handleGetSerialDetail = () => {
    const param = {
      serialNoParent: Number(parentId?.id)
    }
    dispatch(
      getBulkSerialNoDetailAction(
        { ...param },
        async (data) => {
          if (data && data?.result?.data) {
            dispatch(SetLoadingAction(false))
            const recordData = await data?.result?.data[0]
            const totalSensor = await data?.result?.total
            form.setFieldsValue({ registrationsNumber: totalSensor, notes: recordData?.note })
            setSelectCommsType(recordData?.sensorTypeId)
            setSelectType(recordData?.modelRegistrationId)
            setSelectEquipmentType(recordData?.sensorTypeTranslateId)
            setSelectFactory(recordData?.productSite)
            setYear(moment(recordData?.manufacturingDate).toDate())
            setMonth(moment(recordData?.manufacturingDate).toDate())
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }

  const handleOnClickBtnCancel = () => {
    history.goBack()
  }
  const onFinish = () => {
    const formatYear = parseInt(moment(year).format('YYYY'), 10)
    const formatMonth = parseInt(moment(month).format('M'), 10)
    const params: any = {
      serialNoParent: Number(parentId?.id),
      serialNoType: 1,
      sensorTypeId: selectCommsType,
      modelRegistrationId: selectType,
      sensorTypeTranslateId: selectEquipmentType,
      manufacturingYear: formatYear,
      manufacturingMonth: formatMonth,
      productSite: selectFactory,
      note: form.getFieldValue('notes'),
      serialNoQuantity: form.getFieldValue('registrationsNumber')
    }
    dispatch(
      updateBulkSerialNoAction(
        { ...params },
        async (data) => {
          if (data && data?.result?.data) {
            dispatch(SetLoadingAction(false))
            const parentId = await data?.result?.serialNoParent
            history.push(
              ADMIN_ROUTE.concat(routeSerial.BULK_DETAIL.replace(':id', parentId.toString()))
            )
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        true
      )
    )
  }
  return (
    <div className='bgrffffff'>
      <div className='serial-no-management pd_16x_0x'>
        <div className='mg_0_16x'>
          <div className='create-serial'>
            <Form form={form} onFinish={onFinish} autoComplete='off'>
              <Row gutter={24} style={{ marginBottom: 14 }}>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.serialCreate.type')}
                    disableAll={true}
                    isLabelRequired={true}
                    dataOption={typeList}
                    defaultOption={selectType}
                    disableSelect={true}
                  />
                </Col>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.serialCreate.communicationType')}
                    isLabelRequired={true}
                    disableAll={true}
                    dataOption={communicationTypeList}
                    defaultOption={selectCommsType}
                    disableSelect={true}
                  />
                </Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: 14 }}>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.serialCreate.equipmentType')}
                    isLabelRequired={true}
                    disableAll={true}
                    dataOption={equipmentTypeList}
                    defaultOption={selectEquipmentType}
                    disableSelect={true}
                  />
                </Col>
                <Col span={12}>
                  <div className='year-picker-label'>
                    {t('serialNoScreen.bulkCreate.yearAndMonthPicker')} <span>*</span>
                  </div>
                  <Row gutter={15}>
                    <Col span={12}>
                      <InputYearComponent
                        refer={refYear}
                        state={year}
                        readOnly={false}
                        selectStart={true}
                        onChangeDate={(year: Date) => handleOnChangeYear(year)}
                        tidleSign={true}
                        customPopper='bottom'
                        describeText={t('serialNoScreen.bulkCreate.year')}
                      />
                    </Col>
                    <Col span={12}>
                      <InputMonthComponent
                        refer={refMonth}
                        state={month}
                        readOnly={false}
                        selectStart={true}
                        onChangeDate={(month: Date) => handleOnChangeMonth(month)}
                        tidleSign={true}
                        customPopper='bottom'
                        describeText={t('serialNoScreen.bulkCreate.month')}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <InputComponent
                    label={t('serialNoScreen.bulkCreate.registrationsNumber')}
                    name='registrationsNumber'
                    type='text'
                    maxLength={64}
                    disabled={true}
                    isReq={true}
                  />
                </Col>
                <Col span={12}>
                  <SelectComponent
                    lable={t('serialNoScreen.bulkCreate.manufacturingFactory')}
                    disableAll={true}
                    dataOption={factoryList}
                    defaultOption={selectFactory}
                    onChangeSelect={handleOnSelectFactory}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <InputComponent
                    label={t('serialNoScreen.bulkCreate.remarks')}
                    name='notes'
                    type='text'
                    maxLength={64}
                  />
                </Col>
              </Row>
              <div className='group-btn-create'>
                <Form.Item shouldUpdate>
                  {() => (
                    <VButton
                      title='編集'
                      style={styleBtnSubmit}
                      disabled={checkDisable()}
                      type='text'
                    />
                  )}
                </Form.Item>
                <VButton
                  htmlType='button'
                  onClick={handleOnClickBtnCancel}
                  title={t('customerScreen.btnCancel')}
                  style={styleBtnReturn}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
const styleBtnSubmit = {
  width: 74,
  height: 38,
  color: '#ffffff'
}
const styleBtnReturn = {
  backgroundColor: '#fff',
  color: '#d7000f',
  border: 'solid 1px #d7000f',
  height: 38,
  marginLeft: 24
}

export default EditBulkSerialNo
