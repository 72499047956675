import { Col, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import 'styles/serialNo.scss'
import { ADMIN_ROUTE, routeSerial } from 'views/routes/routes'

function SelectTypeCreateSerialNo() {
  const history = useHistory()
  const goToIndividualCreate = () => {
    history.push(ADMIN_ROUTE.concat(routeSerial.INDIVIDUAL_CREATE))
  }
  const goToBulkCreate = () => {
    history.push(ADMIN_ROUTE.concat(routeSerial.BULK_CREATE))
  }
  return (
    <div className='bgrffffff'>
      <div className='customer-management pd_16x_0x'>
        <div className='ss-search-email mg_0_16x'>
          <Row gutter={24}>
            <Col span={12}>
              <div className='serial-regis' onClick={goToIndividualCreate}>
                一台登録
              </div>
            </Col>
            <Col span={12}>
              <div className='serial-regis' onClick={goToBulkCreate}>
                複数台登録
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default SelectTypeCreateSerialNo
