import { IUser } from 'interfaces/IUser'
import { IUserList } from 'interfaces/IUserList'
import {
  ICheckTokenExistParams,
  IForgotPasswordParams,
  IResetPasswordParams,
  IChangePasswordParams,
  IVerifyParams
} from 'services/params/ILoginParams'
import {
  ICreateUser,
  IDeleteUser,
  IListAdmin,
  IListUser,
  IUpdateUserStatus
} from 'services/params/user/IUserParam'
import { IUpdateUserById } from 'services/requests/IUpdateUserById'
import { EUserActions } from './EUserAction'
import {
  IChangePasswordAction,
  ICheckTokenExistAction,
  ICreateUserAction,
  IDeleteUserAction,
  IForgotPasswordAction,
  IGetAdminAction,
  IGetUserByIdAction,
  IGetUsersAction,
  IResetPasswordAction,
  IUpdateUserByIdAction,
  IUpdateUserDetailAction,
  IUpdateUsersAction,
  IUpdateUserStatusAction,
  IVerifyAdminAccountAction
} from './usersTypes'
export const createUserAction = (
  createParam: ICreateUser,
  callBack: (data: any) => void,
  isLoading: boolean
): ICreateUserAction => ({
  type: EUserActions.CREATE_USER,
  createParam,
  callBack,
  isLoading
})
export const getUsersListAction = (
  request: IListUser,
  callBack: (data: any) => void,
  isLoading: boolean
): IGetUsersAction => ({
  type: EUserActions.USERS_LIST,
  request,
  callBack,
  isLoading
})
export const deleteUserAction = (
  request: IDeleteUser,
  callBack: (data: any) => void,
  isLoading: boolean
): IDeleteUserAction => ({
  type: EUserActions.DELETE_USER,
  request,
  callBack,
  isLoading
})
export const GetUserByIdAction = (id: number): IGetUserByIdAction => ({
  type: EUserActions.GET_USER_BY_ID,
  id
})

export const UpdateUserByIdAction = (user: IUpdateUserById): IUpdateUserByIdAction => ({
  type: EUserActions.UPDATE_USER_BY_ID,
  user
})

export const UpdateUserDetailAction = (user: IUser): IUpdateUserDetailAction => ({
  type: EUserActions.UPDATE_USER_DETAIL,
  user
})

export const updateUsersListAction = (usersList: IUserList): IUpdateUsersAction => ({
  type: EUserActions.UPDATE_USERS_LIST,
  usersList
})

export const updateUserStatusAction = (
  request: IUpdateUserStatus,
  callBack: (data: any) => void,
  isLoading: boolean
): IUpdateUserStatusAction => ({
  type: EUserActions.UPDATE_USER_STATUS,
  request,
  callBack,
  isLoading
})

export const getAdminsListAction = (
  request: IListAdmin,
  callBack: (data: any) => void,
  isLoading: boolean
): IGetAdminAction => ({
  type: EUserActions.ADMIN_LIST,
  request,
  callBack,
  isLoading
})

export const verifyAdminAccountAction = (
  params: IVerifyParams,
  callBack: (data: any) => void,
  isLoading: boolean
): IVerifyAdminAccountAction => ({
  type: EUserActions.VERIFY_ADMIN,
  params,
  isLoading,
  callBack
})

export const forgotPasswordAction = (
  params: IForgotPasswordParams,
  callBack: (data: any) => void,
  isLoading: boolean
): IForgotPasswordAction => ({
  type: EUserActions.FORGOT_PASSWORD,
  params,
  isLoading,
  callBack
})

export const resetPasswordAction = (
  params: IResetPasswordParams,
  callBack: (data: any) => void,
  isLoading: boolean
): IResetPasswordAction => ({
  type: EUserActions.RESET_PASSWORD,
  params,
  isLoading,
  callBack
})

export const changePasswordAction = (
  params: IChangePasswordParams,
  callBack: (data: any) => void,
  isLoading: boolean
): IChangePasswordAction => ({
  type: EUserActions.CHANGE_PASSWORD,
  params,
  isLoading,
  callBack
})

export const checkTokenExistAction = (
  params: ICheckTokenExistParams,
  callBack: (data: any) => void,
  isLoading: boolean
): ICheckTokenExistAction => ({
  type: EUserActions.CHECK_TOKEN_EXIST,
  params,
  isLoading,
  callBack
})
