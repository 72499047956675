import {
  IListSensorParams,
  IListSerialNoParams,
  IListSerialNo,
  IChangeConfirmStatusParams,
  ICreateSerialNoParams,
  IDeleteSerialNoParams,
  ISerialNoDetailParams,
  IBulkSerialNoDetailParams,
  IUpdateBulkSerialNoParams,
  IDownloadJsonParams,
  IGetListSerialForOrderParams,
  IImportCsvParams
} from 'services/params/serialNo/IserialNoParam'
import { ESerialNoAction } from './ESerialNoAction'
import {
  IChangeSerialNoConfirmStatusAction,
  ICreateIndividualSerialNoAction,
  ICreateBulkSerialNoAction,
  IDeleteSerialNoAction,
  IGetListKatashikiAction,
  IGetListSensorAction,
  IGetListSerialNoAction,
  ISearchSerialNoAction,
  IGetSerialNoDetailAction,
  IUpdateSerialNoAction,
  IGetBulkSerialNoDetailAction,
  IUpdateBulkSerialNoAction,
  IDownLoadJsonAction,
  IGetListSerialNoForOrderAction,
  ImportCsvFileAction
} from './serialNoTypes'

export const getListKatashikiAction = (
  params: any,
  callBack: (value: any) => void,
  isLoading: boolean
): IGetListKatashikiAction => ({
  type: ESerialNoAction.LIST_KATASHIKI,
  params,
  callBack,
  isLoading
})

export const createIndividualSerialNoAction = (
  params: ICreateSerialNoParams,
  callBack: (value: any) => void,
  isLoading: boolean
): ICreateIndividualSerialNoAction => ({
  type: ESerialNoAction.CREATE_INDIVIDUAL,
  params,
  callBack,
  isLoading
})

export const createBulkSerialNoAction = (
  params: any,
  callBack: (value: any) => void,
  isLoading: boolean
): ICreateBulkSerialNoAction => ({
  type: ESerialNoAction.CREATE_BULK,
  params,
  callBack,
  isLoading
})

export const getListSensorAction = (
  params: IListSensorParams,
  callBack: (value: any) => void,
  isLoading: boolean
): IGetListSensorAction => ({
  type: ESerialNoAction.LIST_SENSOR,
  params,
  callBack,
  isLoading
})

export const getSerialNoDetailAction = (
  params: ISerialNoDetailParams,
  callBack: (value: any) => void,
  isLoading: boolean
): IGetSerialNoDetailAction => ({
  type: ESerialNoAction.SERIAL_NO_DETAIL,
  params,
  callBack,
  isLoading
})
export const getBulkSerialNoDetailAction = (
  params: IBulkSerialNoDetailParams,
  callBack: (value: any) => void,
  isLoading: boolean
): IGetBulkSerialNoDetailAction => ({
  type: ESerialNoAction.BULK_SERIAL_NO_DETAIL,
  params,
  callBack,
  isLoading
})

export const getListSerialNoAction = (
  params: IListSerialNoParams,
  callBack: (value: any) => void,
  isLoading: boolean
): IGetListSerialNoAction => ({
  type: ESerialNoAction.LIST_SERIAL_NO,
  params,
  callBack,
  isLoading
})
export const deleteSerialNoAction = (
  params: IDeleteSerialNoParams,
  callBack: (value: any) => void,
  isLoading: boolean
): IDeleteSerialNoAction => ({
  type: ESerialNoAction.DELETE_SERIAL_NO,
  params,
  callBack,
  isLoading
})

export const changeConfirmStatusAction = (
  params: IChangeConfirmStatusParams,
  callBack: (value: any) => void,
  isLoading: boolean
): IChangeSerialNoConfirmStatusAction => ({
  type: ESerialNoAction.CONFIRM_STATUS,
  params,
  callBack,
  isLoading
})

export const searchSerialNo = (
  params: IListSerialNo,
  isLoading: boolean,
  callBack: (value: any) => void
): ISearchSerialNoAction => ({
  type: ESerialNoAction.SEARCH_SERIAL_NO,
  params,
  isLoading,
  callBack
})

export const updateSerialNoAction = (
  params: ICreateSerialNoParams,
  callBack: (value: any) => void,
  isLoading: boolean
): IUpdateSerialNoAction => ({
  type: ESerialNoAction.UPDATE_SERIAL_NO,
  params,
  callBack,
  isLoading
})
export const updateBulkSerialNoAction = (
  params: IUpdateBulkSerialNoParams,
  callBack: (value: any) => void,
  isLoading: boolean
): IUpdateBulkSerialNoAction => ({
  type: ESerialNoAction.UPDATE_BULK_SERIAL_NO,
  params,
  callBack,
  isLoading
})
export const downloadJsonAction = (
  params: IDownloadJsonParams,
  callBack: (value: any) => void,
  isLoading: boolean
): IDownLoadJsonAction => ({
  type: ESerialNoAction.DOWNLOAD_JSON,
  params,
  callBack,
  isLoading
})
export const getSerialForOrderAction = (
  params: IGetListSerialForOrderParams,
  callBack: (value: any) => void,
  isLoading: boolean
): IGetListSerialNoForOrderAction => ({
  type: ESerialNoAction.SERIAL_NO_FOR_ORDER,
  params,
  callBack,
  isLoading
})
export const importCsvFile = (
  data: IImportCsvParams,
  callBack: (value: any) => void
): ImportCsvFileAction => ({
  type: ESerialNoAction.IMPORT_CSV,
  data,
  callBack
})
