import i18n from 'configs/i18n'
import { validResetPassword } from './rules'

const formResetPassword = [
  {
    label: i18n.t('adminManagement.confirmPassword.passwordLabelReset'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.password'),
    name: 'password',
    rules: validResetPassword.password,
    type: 'password',
    maxLength: 32,
    isRequired: true
  },
  {
    label: i18n.t('adminManagement.confirmPassword.confirmPasswordLabelReset'),
    placeholder: i18n.t('modal.userManagement.create.placeholder.passwordConfirm'),
    name: 'confirm',
    rules: validResetPassword.confirmPassword,
    type: 'confirmPassword',
    maxLength: 32,
    isRequired: true
  }
]
export { formResetPassword }
