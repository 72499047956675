import { API_URL } from 'constants/general'
import { IRequest } from 'interfaces/IRequest'
import instance from './v1'

const LOGIN = '/auth/login'
const USERS = '/users'
const USERS_LIST = '/users/list'
const GET_USER_BY_ID = '/users'
const UPDATE_USER_BY_ID = '/users'
const UPLOAD = '/upload'
const ADMIN_LIST = '/admins/list'
export { LOGIN, USERS, USERS_LIST, GET_USER_BY_ID, UPDATE_USER_BY_ID, UPLOAD, ADMIN_LIST }

export const configRequest = (params: IRequest<any>) => {
  return instance.post(API_URL, params)
}
