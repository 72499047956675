import DatePicker from 'react-datepicker'
import { IconCalendar } from 'assets'
import 'react-datepicker/dist/react-datepicker.css'
import { ja } from 'date-fns/locale'
import { forwardRef, LegacyRef } from 'react'

type Props = {
  isTilde: any
  onChange: any
  selected: any
  refer: LegacyRef<any>
}

const CustomDatePicker = ({ isTilde, onChange, selected, refer }: Props) => {
  const formatDate = 'yyyy/MM/dd'

  const CustomInputDate = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className='custom-input-date pointer' onClick={onClick} ref={ref}>
      <div className='date-text'>{value}</div>
      <img className='icon-calendar' alt='calendar' src={IconCalendar} />
    </div>
  ))

  return (
    <div className='ip-date'>
      <DatePicker
        ref={refer}
        selected={selected}
        autoComplete='off'
        className='ip-datePicker'
        disabledKeyboardNavigation
        locale={ja}
        onChange={onChange}
        dateFormat={formatDate}
        customInput={<CustomInputDate />}
        popperPlacement='bottom'
      />
      <>{isTilde ? <div className='tidle-sign'>~</div> : null}</>
    </div>
  )
}

export default CustomDatePicker
