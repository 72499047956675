import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
interface ITable {
  columns: any
  dataSource: any
  isSearch?: boolean
  rowSelection?: any
  onRow?: any
  tableScroll?: any
}

function TableComponent({
  columns,
  dataSource,
  isSearch,
  rowSelection,
  onRow,
  tableScroll
}: ITable) {
  const { t } = useTranslation()
  const locale = {
    emptyText: isSearch ? <span className='custom-error'>{t('searchEmptyTable')}</span> : ' '
  }
  return (
    <Table
      onRow={onRow}
      locale={locale}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowSelection={rowSelection}
      scroll={tableScroll}
    />
  )
}
export default TableComponent
