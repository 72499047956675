import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'redux/store'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './configs/i18n'
import 'antd/dist/antd.less'
import 'styles/base.scss'
import ScrollToTop from 'components/common/scroll/ScrollToTop'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
