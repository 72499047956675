import VButton from 'components/button/VButton'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { routesName } from 'views/routes/routes'
const ExpiredComponent = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const backToLogin = () => {
    history.push(routesName.LOGIN)
  }
  return (
    <>
      <div
        className='text-align-center'
        style={{
          fontSize: 30,
          fontWeight: 'bold',
          position: 'relative',
          top: '45%'
        }}>
        {t('expiredPageContent.expiredText')}
        <div className='text-align-center' style={{ marginTop: 16 }}>
          <VButton
            title={t('expiredPageContent.btnGoBack')}
            style={btnGoBack}
            onClick={backToLogin}
          />
        </div>
      </div>
    </>
  )
}
const btnGoBack = {
  width: 100,
  height: 46,
  fontSize: 16,
  fontWeight: 600
}

export default ExpiredComponent
