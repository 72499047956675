import {
  IGetListEmailAlert,
  IGetListSensorByUser,
  IUpdateEmailAlert,
  IUpdateUser,
  IUserDetail
} from 'services/params/userDetail/userDetail'
import {
  getListEmailAlertRequest,
  getListSensorByUserRequest,
  updateEmailAlertRequest,
  updateUserRequest,
  userDetailRequest
} from 'services/requests/userDetail/userDetailRequest'
import { configRequest } from '..'

class UserDetailApis {
  userDetail = (param: IUserDetail) => {
    const requests = userDetailRequest(param)
    return configRequest(requests)
  }
  getListEmailAlert = (param: IGetListEmailAlert) => {
    const requests = getListEmailAlertRequest(param)
    return configRequest(requests)
  }
  getListSensor = (param: IGetListSensorByUser) => {
    const requests = getListSensorByUserRequest(param)
    return configRequest(requests)
  }
  updateEmailAlert = (param: IUpdateEmailAlert) => {
    const requests = updateEmailAlertRequest(param)
    return configRequest(requests)
  }
  updateUser = (param: IUpdateUser) => {
    const requests = updateUserRequest(param)
    return configRequest(requests)
  }
}

export const UserDetailApi = new UserDetailApis()
