export enum ESensorActions {
  SENSOR_LIST = 'SENSOR_LIST',
  EMAIL_LIST = 'EMAIL_LIST',
  SENSOR_DETAIL = 'SENSOR_DETAIL',
  GET_LIST_USER_BY_SENSOR = 'GET_LIST_USER_BY_SENSOR',
  GET_LIST_EVENT_BY_SENSOR = 'GET_LIST_EVENT_BY_SENSOR',
  ADMIN_EDIT_SENSOR = 'ADMIN_EDIT_SENSOR',
  SEARCH_DEVICE_ID_SENSOR = 'SEARCH_DEVICE_ID_SENSOR',
  SEARCH_SENSOR = 'SEARCH_SENSOR',
  SEARCH_LIST_SENSOR = 'SEARCH_LIST_SENSOR',
  GET_LIST_TYPE_SENSOR = 'GET_LIST_TYPE_SENSOR',
  GEN_SECURITY = 'GEN_SECURITY',
  CHECK_BY_DEVICE_ID = 'CHECK_BY_DEVICE_ID'
}
