import { Col, Row, Table, Input, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { ADMIN_ROUTE, routesDisplayManagement, routesName } from 'views/routes/routes'
import SelectComponent from 'components/common/select/SelectComponent'
import { ColumnsType } from 'antd/es/table'
import React, { useState, useEffect } from 'react'
import { IconDeleteRed, IconEditBlue, IconStopModal } from 'assets'
import 'styles/IndividualSetting.scss'
import ModalCheckSuccessComponent from 'components/modal/ModalCheckSuccessComponent'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/reducers'
import { defaultValueAll, defaultTypeSensorLora } from '../sensor/defaultValue'
import { IAttribute, IDataTypeAddAtribute } from 'interfaces/attributes/IAttribute'
import { validatorAttribute } from '../multiSettings/validatorAttribute'
import TableComponent from 'components/common/table/TableComponent'
import VButton from 'components/button/VButton'
import { v4 as uuidv4 } from 'uuid'
// import moment from 'moment'
import {
  convertFullsizeToHalfsize,
  // formatFormula,
  sortListAttByAttributeIndex,
  formatExpFormulaOther,
  formatFormulaOther
} from 'utils/Functions'
import { convertListEmail } from '../sensor/convertData'
import { IOption } from 'interfaces/sensor/IPropsSelect'
import { SetLoadingAction, SuccessAction } from 'redux/actions/common/commonAction'
import {
  setFormulaCopyAction,
  updateFormulaAction,
  copyFormulaAction
} from 'redux/actions/display/displayAction'
import { settingTypeDefault } from '../displayManagement/defaultValue'
import { convertListAttribute, convertListAttributeAdd } from '../multiSettings/convertData'
import { originalRole, ROLE_CRUD } from 'interfaces/enums/ERoles'
import AccessDeniedComponent from '../accessDenied/AccessDeniedComponent'
import { maxNumberScaleValue, unitFormula } from 'utils/maxLength'
import { defaultAttReserve, defaultShowBlankFormula } from '../individualSettings/defaultValue'
import {
  styleBtnAddEdit,
  styleBtnConfigurationEdit,
  styleBtnReturnEdit,
  styleBtnSubmitEdit
} from './style'
import { getNameAttribute, listAttributeTv } from '../displayManagement/convertData'
import InputReadOnlyComponent from 'components/common/input/InputReadOnlyComponent'
import { EExpKeys, EKey } from 'interfaces/enums/EKey'
import { dataKeyPrefixTv } from '../multiSettings/defaultValue'
import { checkDeleteTv } from './function'
import { convertViewFormula } from '../calculatorSheet/convertData'
import { getListSensorAction } from 'redux/actions/serialNo/serialNoAction'
import { ISensorPullDownType, ISensorTranslate } from 'interfaces/ISerialNo'

const getData = (data: IAttribute[]) => {
  const result = [...data]
  result.map((item) => {
    item.key = item.attributeId
    item.scaleValue = item.scaleValue || 0
    item.unit = item.unit || defaultAttReserve
    item.conversionFormula = item.conversionFormula || defaultShowBlankFormula
    return item
  })
  return result
}
const locale = {
  emptyText: ' '
}
const EditFormula = () => {
  const role = originalRole(useSelector((state: AppState) => state?.auth?.role))
  const params = useParams<any>()
  const id = params?.id || ''
  const history = useHistory()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [describe, setDescribe] = useState<string>('')
  const [formulaId, setFormulaId] = useState<string>('')
  const dispatch = useDispatch()
  const appState = useSelector((state: AppState) => state)
  const masterData = appState.common.masterData
  const formula = useSelector((state: AppState) => state?.formula)
  const defaultListEmail = convertListEmail(masterData?.email)
  // attribute
  const [attributes, setAttributes] = useState<any>([])
  const arrAttributeTv = attributes?.filter((item: any) => item?.dataKeyPrefix === EKey.keyPrefixTv)
  const [numberAttributeTv, setNumberAttributeTv] = useState<number>(0)
  const [lastIndexAttributeTv, setLastIndexAttributeTv] = useState<number>(0)
  // state data  table
  const [dataTableAddAtribute, setDataTableAddAtribute] = useState<IDataTypeAddAtribute[]>([])
  // state select option LteOrLora sensor
  const [lteOrLora, setLteOrLora] = useState<string>('lora')
  const [listLteOrLora, setListLteOrLora] = useState<any>([])
  const [listSensorTranslateType, setListSensorTranslateType] = useState<any>([])
  const handleOnChangeSelectLteOrLora = (valueLteOrLora: string) => {
    setLteOrLora(valueLteOrLora)
  }
  // state select sensor from filter
  const [sensorName, setSensorName] = useState<string>('')
  const [deviceId, setDeviceId] = useState<string>('')
  const [listSensor, setListSensor] = useState<IOption[]>([])
  const handleChangeSensor = (sensorValue: string) => {
    setSensorName(sensorValue)
  }
  // state select option list email
  const [email, setEmail] = useState<string>(defaultValueAll)
  const handleOnChangeSelectEmail = (valueEmail: string) => {
    setEmail(valueEmail)
  }
  // state select option setting target
  const [settingTarget, setSettingTarget] = useState<string>('paddy_field')
  const handleOnChangeSelectSettingTarget = (valueSettingTarget: string) => {
    setSettingTarget(valueSettingTarget)
  }
  // close modal
  const handleClickCancel = () => {
    setModalConfirm({ ...modalConfirm, isModalVisible: false })
  }

  // modal confirm
  const [modalConfirm, setModalConfirm] = useState({
    isModalVisible: false,
    content: t('modal.contentSettingSuccess')
  })
  // state edit formula
  const handleOnClickEdit = (
    key: string,
    name: string,
    exp: string,
    typeValidate: boolean,
    dataKeyPrefix: string,
    attributeKey: string,
    mode: string
  ) => {
    const getForm = form.getFieldsValue()
    const id = uuidv4()
    const data = {
      formulaId: formulaId,
      id: id,
      type: settingTypeDefault.sensor,
      sensorType: settingTarget,
      email: email,
      deviceId: deviceId,
      sensorName: sensorName,
      sensorAiId: sensorName,
      parentType: lteOrLora,
      description: describe,
      listAttribute: getForm?.listAttributes,
      listAttributeAdd: getForm?.listAttributeAdd,
      numberAttributeTv: numberAttributeTv,
      lastIndexAttributeTv: lastIndexAttributeTv
    }
    dispatch(setFormulaCopyAction(data))
    const path = ADMIN_ROUTE.concat(routesDisplayManagement.CALCULATOR_SHEET + `/${key}/individual`)
    const expConvert = formatFormulaOther(exp, attributes)
    const expFormat = formatExpFormulaOther(exp, attributes)
    const nameAtt =
      attributeKey.indexOf('Tv') > -1
        ? name
        : name === defaultAttReserve
        ? t('caculation.reserve')
        : name

    history.push({
      pathname: path,
      state: {
        lteOrLora: lteOrLora,
        settingTarget: settingTarget,
        nameAtt: nameAtt,
        expFromAtt: expConvert,
        typeValidate: typeValidate,
        attributeKey: attributeKey,
        exp: expFormat,
        mode: mode === EExpKeys.DEG_KEY ? false : true
      }
    })
  }
  const handleGetListSensor = () => {
    const params = {
      type: lteOrLora === defaultValueAll || lteOrLora === 'LoRa' ? 'LoRa' : ''
    }
    dispatch(
      getListSensorAction(
        { ...params },
        async (data) => {
          if (data && data?.result) {
            dispatch(SetLoadingAction(false))
            const resultCommsType: any = []
            const resultTypes: any = []
            data.result.sensorTypes = await data.result.sensorTypes.map(
              (
                { sensorTypeId, sensorTypeKey }: ISensorPullDownType // eslint-disable-next-line
              ) => {
                resultCommsType.push({
                  id: sensorTypeId,
                  name: sensorTypeKey,
                  value: sensorTypeKey
                })
              }
            )
            if (lteOrLora === 'LoRa') {
              data.result.sensorTranslate = await data.result.sensorTranslate.slice(0, 3).map(
                (
                  { sensorTypeTranslateId, sensorTypeName, sensorTypeKey }: ISensorTranslate // eslint-disable-next-line
                ) => {
                  resultTypes.push({
                    id: sensorTypeTranslateId,
                    name: sensorTypeName,
                    value: sensorTypeKey
                  })
                }
              )
            } else {
              data.result.sensorTranslate = await data.result.sensorTranslate.slice(0, 2).map(
                (
                  { sensorTypeTranslateId, sensorTypeName, sensorTypeKey }: ISensorTranslate // eslint-disable-next-line
                ) => {
                  resultTypes.push({
                    id: sensorTypeTranslateId,
                    name: sensorTypeName,
                    value: sensorTypeKey
                  })
                }
              )
            }
            setListLteOrLora(resultCommsType)
            setListSensorTranslateType(resultTypes)
          } else {
            dispatch(SetLoadingAction(false))
          }
        },
        false
      )
    )
  }
  useEffect(() => {
    handleGetListSensor()
    // eslint-disable-next-line
  }, [lteOrLora])
  // delete
  const handleOnClickDelete = (key: string, attributeKey: string) => {
    let listAtt = form.getFieldsValue()?.listAttributes
    let listAttAdd = form.getFieldsValue()?.listAttributeAdd
    const arrDeleteTv = checkDeleteTv(
      key,
      attributeKey,
      listAtt,
      listAttAdd,
      lteOrLora,
      'copy',
      numberAttributeTv
    )
    if (arrDeleteTv?.length) {
      const stateSuccess = {
        isVisible: true,
        content: t('errors.tvUsed', { 0: attributeKey, 1: arrDeleteTv?.join(', ') }),
        isImg: true,
        src: IconStopModal
      }
      dispatch(SuccessAction({ ...stateSuccess }))
    } else {
      if (formula?.id) {
        const getListAttributes = form.getFieldsValue()?.listAttributes
        let arr = [...getListAttributes]
        arr = arr?.filter((item: any) => item?.key !== key)
        dispatch(
          setFormulaCopyAction({
            ...formula,
            listAttribute: arr
          })
        )
      } else {
        const getListAttributes = form.getFieldsValue()?.listAttributes
        let arr = [...getListAttributes]
        arr = arr?.filter((item: any) => item?.key !== key)
        setAttributes(arr)
      }
    }
  }
  // change describe
  const handleOnChangeDescribe = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescribe(e.target.value)
  }
  // return last page
  const gotoLastPage = () => {
    history.goBack()
  }
  // render colums attribute setting
  const columns: ColumnsType<IAttribute> = [
    {
      title: t('caculation.table.positionID'),
      key: 'id',
      render: (value: IAttribute) => {
        const valueIndex = value?.attributeIndex.toString()
        const result =
          value?.dataKeyPrefix === EKey.keyPrefixTv
            ? EKey.keyPrefixTv.concat(valueIndex)
            : lteOrLora && lteOrLora !== defaultTypeSensorLora
            ? 'tm'.concat(valueIndex)
            : ' tm(ID*10)+'.concat(valueIndex)
        return <div className='pointer custom-a'>{result}</div>
      },
      align: 'center',
      width: '6%'
    },
    {
      title: t('caculation.table.sensorSpecs'),
      dataIndex: '',
      align: 'center',
      width: '12%',
      render: (value) => {
        return (
          <div>
            {value?.dataKeyPrefix !== EKey.keyPrefixTv &&
            getNameAttribute(value?.attributeTranslate) === defaultAttReserve
              ? t('caculation.reserve')
              : getNameAttribute(value?.attributeTranslate) || ''}
          </div>
        )
      }
    },
    {
      title: t('caculation.table.sensorUnit'),
      dataIndex: 'unit',
      render: (value, row, index) => {
        return (
          <Form.Item name={[index, 'unit']} rules={validatorAttribute.unit}>
            <Input
              className='edit-columns'
              type='text'
              maxLength={unitFormula}
              onInput={(event) => {
                const value = event.currentTarget.value
                event.currentTarget.value =
                  value === ' ' ? value?.trim() : convertFullsizeToHalfsize(value)
              }}
            />
          </Form.Item>
        )
      },
      align: 'center',
      width: '12%'
    },
    {
      title: t('caculation.table.sensorIndex'),
      dataIndex: 'scaleValue',
      render: (value, row, index) => {
        return (
          <Form.Item name={[index, 'scaleValue']} rules={validatorAttribute.scaleValue}>
            <Input
              type='text'
              className='edit-columns'
              onWheel={(event) => event.currentTarget.blur()}
              min={0}
              max={maxNumberScaleValue}
              onInput={(event) => {
                const value = event.currentTarget.value
                event.currentTarget.value = value.replace(/[^0-9]/g, '')
              }}
            />
          </Form.Item>
        )
      },
      align: 'center',
      width: '14%'
    },
    {
      title: t('caculation.table.defaultFormula'),
      dataIndex: 'conversionFormula',
      render: (value) => {
        return (
          <InputReadOnlyComponent
            value={convertViewFormula(attributes, value)}
            className='formula-column text-align-center'
            styleInput={{ height: '32px' }}
          />
        )
      },
      align: 'center',
      width: '14%'
    },
    {
      title: t('caculation.table.edit'),
      dataIndex: '',
      render: (value) => {
        return (
          <>
            <img
              onClick={() =>
                handleOnClickEdit(
                  value?.key,
                  getNameAttribute(value?.attributeTranslate),
                  convertViewFormula(attributes, value?.conversionFormula),
                  false,
                  value?.dataKeyPrefix,
                  'tm'.concat(value?.attributeIndex?.toString()),
                  value?.mode
                )
              }
              src={IconEditBlue}
              className='w42x-h28x pointer'
              alt='edit'
            />
          </>
        )
      },
      align: 'center',
      width: '4%'
    },
    {
      title: t('caculation.table.delete'),
      dataIndex: '',
      render: (value) => {
        return value?.dataKeyPrefix === EKey.keyPrefixTv ? (
          <img
            src={IconDeleteRed}
            onClick={() => handleOnClickDelete(value?.key, value?.attributeKey)}
            className='w42x-h28x pointer'
            alt='delete'
          />
        ) : (
          <div className='pointer'>---</div>
        )
      },
      align: 'center',
      width: '4%'
    }
  ]
  // render colums add attribute
  const columnsAddAttribute: ColumnsType<IDataTypeAddAtribute> = [
    {
      dataIndex: 'positionID',
      width: '6%',
      align: 'center',
      render: (value, row, index) => {
        return (
          <div className='position-id'>
            <span>{value + (index + 1 + lastIndexAttributeTv)}</span>
          </div>
        )
      }
    },
    {
      dataIndex: 'settingTarget',
      render: (value, row, index) => {
        return (
          <Form.Item name={[index, 'settingTarget']} rules={validatorAttribute.settingTarget}>
            <Input
              className='edit-columns'
              type='text'
              maxLength={64}
              onInput={(event) => {
                const value = event.currentTarget.value
                event.currentTarget.value =
                  value === ' ' ? value?.trim() : convertFullsizeToHalfsize(value)
              }}
            />
          </Form.Item>
        )
      },
      width: '12%',
      align: 'center'
    },
    {
      dataIndex: 'sensorUnit',
      render: (value, row, index) => {
        return (
          <Form.Item name={[index, 'sensorUnit']} rules={validatorAttribute.unit}>
            <Input
              className='edit-columns'
              type='text'
              maxLength={unitFormula}
              onInput={(event) => {
                const value = event.currentTarget.value
                event.currentTarget.value =
                  value === ' ' ? value?.trim() : convertFullsizeToHalfsize(value)
              }}
            />
          </Form.Item>
        )
      },
      width: '12%',
      align: 'center'
    },
    {
      dataIndex: 'sensorIndex',
      render: (value, row, index) => {
        return (
          <Form.Item name={[index, 'sensorIndex']} rules={validatorAttribute.scaleValue}>
            <Input
              className='edit-columns'
              type='text'
              onWheel={(event) => event.currentTarget.blur()}
              min={0}
              max={maxNumberScaleValue}
              onInput={(event) => {
                const value = event.currentTarget.value
                event.currentTarget.value = value.replace(/[^0-9]/g, '')
              }}
            />
          </Form.Item>
        )
      },
      width: '14%',
      align: 'center'
    },
    {
      dataIndex: 'conversionFormula',
      render: (_conversionFormula, _row, index) => {
        return (
          <div>
            {_conversionFormula ? (
              <InputReadOnlyComponent
                value={convertViewFormula(attributes, _conversionFormula)}
                className='edit-columns'
              />
            ) : (
              <Form.Item
                name={[index, 'conversionFormula']}
                rules={validatorAttribute.conversionFormula}>
                <Input
                  placeholder={t('caculation.defaultFormula')}
                  className='edit-columns'
                  readOnly
                />
              </Form.Item>
            )}
          </div>
        )
      },
      width: '14%',
      align: 'center'
    },
    {
      dataIndex: '',
      render: (value, row, index) => {
        return (
          <div>
            <VButton
              onClick={() =>
                handleOnClickEdit(
                  value?.key,
                  form.getFieldsValue()?.listAttributeAdd?.[index]?.settingTarget,
                  convertViewFormula(attributes, value?.conversionFormula),
                  true,
                  value?.dataKeyPrefix,
                  value?.positionID + (index + 1 + numberAttributeTv),
                  value?.mode
                )
              }
              title={t('displayManagement.setting.btnSetting')}
              style={styleBtnConfigurationEdit}
              htmlType='button'
              type='text'
            />
          </div>
        )
      },
      width: '4%',
      align: 'center'
    },
    {
      dataIndex: '',
      render: (_: object, record: { key: string }) => {
        return (
          <img
            src={IconDeleteRed}
            onClick={() => handleOnClickDeleteAttributeAdd(record?.key)}
            className='w42x-h28x pointer'
            alt='delete'
          />
        )
      },
      width: '4%',
      align: 'center'
    }
  ]
  // add
  const handleClickAddAttribute = () => {
    const key = uuidv4()
    const getFormAttribute = form.getFieldsValue()?.listAttributeAdd
    const newData: IDataTypeAddAtribute = {
      key: key?.toString(),
      positionID: t('caculation.positionTv'),
      settingTarget: '',
      sensorUnit: '',
      sensorIndex: '',
      conversionFormula: '',
      dataKeyPrefix: dataKeyPrefixTv
    }
    setDataTableAddAtribute([...getFormAttribute, newData])
  }
  // delete
  const handleOnClickDeleteAttributeAdd = (key: string) => {
    const getFormAttribute = form.getFieldsValue()?.listAttributeAdd
    setDataTableAddAtribute(getFormAttribute.filter((item: any) => item.key !== key))
  }
  const onFinishTable = async (val: any) => {
    const arrAttribute = convertListAttribute(val?.listAttributes)
    const arrAtributeAdd = convertListAttributeAdd(
      val?.listAttributeAdd,
      val?.listAttributes?.length,
      lastIndexAttributeTv
    )
    const param = {
      id: id,
      description: describe,
      listAttribute: arrAttribute.concat(arrAtributeAdd)
    }
    if (param?.listAttribute) {
      dispatch(SetLoadingAction(true))
      const response = await updateFormulaAction(param)
      const result = response.data?.result
      if (result?.affected > 0) {
        // modal success
        const stateSuccess = {
          isVisible: true,
          content: t('modal.contentSettingSuccess'),
          isRedirect: true,
          href: ADMIN_ROUTE.concat(routesName.DISPLAY_MANAGEMENT)
        }
        dispatch(SuccessAction({ ...stateSuccess }))
      } else {
        dispatch(SetLoadingAction(false))
      }
    }
  }
  const getDetailFormula = () => {
    const param = {
      formulaId: id
    }
    dispatch(
      copyFormulaAction(true, param, (data) => {
        const result = data?.result
        if (result) {
          // get list att tm and sort
          let listAttTm = result?.formulaDetail?.filter(
            (item: any) => item?.dataKeyPrefix !== EKey.keyPrefixTv
          )
          listAttTm = sortListAttByAttributeIndex(listAttTm)
          // get list att tv and sort
          let listAttTv = listAttributeTv(data?.result?.formulaDetail)
          listAttTv = sortListAttByAttributeIndex(listAttTv)
          // list att sort
          const arrResult = listAttTm?.concat(listAttTv)
          setAttributes(arrResult || [])
          // get att tv in detailFormular
          const arrAttribteTv = listAttributeTv(data?.result?.formulaDetail)
          const listAttributeTvAll = data?.result?.formulaDetail?.filter(
            (item: any) => item?.dataKeyPrefix === dataKeyPrefixTv
          )
          const sortArrAttributeTv = sortListAttByAttributeIndex(listAttributeTvAll)
          setNumberAttributeTv(arrAttribteTv?.length)
          setLastIndexAttributeTv(
            sortArrAttributeTv?.length
              ? sortArrAttributeTv?.[sortArrAttributeTv?.length - 1]?.attributeIndex
              : 0
          )
          setSettingTarget(result?.sensorType)
          const parentType = result?.parentType
          setLteOrLora(parentType === '1' ? 'lora' : 'lte')
          setDeviceId(result?.deviceId)
          setDescribe(result?.description)
          setSensorName(result?.sensorName)
          setEmail(result?.email ?? defaultValueAll)
          setFormulaId(result?.id)
          const listSensorData = [
            {
              id: result?.sensorName,
              name: result?.sensorName
            }
          ]
          setListSensor(listSensorData)
          dispatch(SetLoadingAction(false))
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
  }
  useEffect(() => {
    if (!formula.id || formula.id.trim() === '') {
      getDetailFormula()
    } else {
      setAttributes(formula?.listAttribute)
    }
    // eslint-disable-next-line
  }, [id, dispatch])
  useEffect(() => {
    if (formulaId) {
      const initialForm = {
        listAttributes:
          formula?.id && formula.type === settingTypeDefault.sensor
            ? formula?.listAttribute
            : attributes
      }
      form.setFieldsValue(initialForm)
    }
    // eslint-disable-next-line
  }, [form, attributes, formula, formulaId])
  useEffect(() => {
    if (formulaId) {
      const initialForm = {
        listAttributeAdd: dataTableAddAtribute
      }
      form.setFieldsValue(initialForm)
    }
    // eslint-disable-next-line
  }, [form, dataTableAddAtribute, formulaId])
  useEffect(() => {
    if (formula?.id && formula.type === settingTypeDefault.sensor) {
      setNumberAttributeTv(formula?.numberAttributeTv ?? 0)
      setLastIndexAttributeTv(formula?.lastIndexAttributeTv ?? 0)
      setDescribe(formula?.description)
      setLteOrLora(formula?.parentType)
      setSettingTarget(formula?.sensorType)
      setDeviceId(formula?.deviceId ?? '')
      setEmail(formula?.email ?? defaultValueAll)
      setSensorName(formula?.sensorName ?? '')
      setFormulaId(formula?.formulaId ?? '')
      const listSensorData = [
        {
          id: formula?.sensorName ?? '',
          name: formula?.sensorName ?? ''
        }
      ]
      setListSensor(listSensorData)
    }
    if (formula?.id && formula.type && formula?.listAttributeAdd?.length) {
      setDataTableAddAtribute(formula?.listAttributeAdd)
    }
  }, [formula])
  return ROLE_CRUD.includes(role) ? (
    formulaId ? (
      <div className='individual-settings'>
        <div className='display-table mt-16x'>
          <div className='bgrffffff'>
            <div className='pd_16x_0x'>
              <div className='mg_0_16x'>
                <div className='table-search-option ss-drop-drown'>
                  <div className='pl-7x'>
                    <div className='label_bold'>
                      {t('caculation.settingType')}
                      <div className='label'>{t('caculation.individualSetting')}</div>
                    </div>
                  </div>
                  <div className='pl-7x'>
                    <div className='label_bold_2'>{t('caculation.settingTargetRegis')}</div>
                  </div>
                  <Row>
                    <Col span={12} className='pl-7x pr-7x'>
                      <SelectComponent
                        lable={t('caculation.table.lteOrLora')}
                        defaultOption={lteOrLora}
                        dataOption={listLteOrLora}
                        onChangeSelect={handleOnChangeSelectLteOrLora}
                        disableAll={true}
                        disableSelect={true}
                      />
                    </Col>
                    <Col span={12} className='pl-7x'>
                      <SelectComponent
                        lable={t('caculation.selectEmail')}
                        defaultOption={email}
                        dataOption={defaultListEmail}
                        onChangeSelect={handleOnChangeSelectEmail}
                        disableSelect={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} className='pl-7x pr-7x'>
                      <SelectComponent
                        lable={t('caculation.sensorType')}
                        defaultOption={settingTarget}
                        dataOption={listSensorTranslateType}
                        onChangeSelect={handleOnChangeSelectSettingTarget}
                        disableAll={true}
                        disableSelect={true}
                      />
                    </Col>
                    <Col span={12} className='pl-7x'>
                      <SelectComponent
                        disableAll={true}
                        lable={t('caculation.table.sensorName')}
                        defaultOption={sensorName}
                        dataOption={listSensor}
                        onChangeSelect={handleChangeSensor}
                        disableSelect={true}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 10 }}>
                    <Col span={12} className='pl-7x pr-7x setting_input custom-form-item'>
                      <div className='label_bold_4'>{t('caculation.sensorNameSelected')}</div>
                      <InputReadOnlyComponent value={sensorName} />
                    </Col>
                    <Col span={12} className='pl-7x pr-7x setting_input custom-form-item'>
                      <div className='label_bold_4'>{t('caculation.deviceIdSelected')}</div>
                      <InputReadOnlyComponent value={deviceId} />
                    </Col>
                  </Row>
                  <Col span={24} className='pl-7x setting_input'>
                    <div className='label_bold_4'>{t('caculation.settingDes')}</div>
                    <Input
                      size='large'
                      placeholder={t('caculation.inputPlaceholder')}
                      value={describe}
                      maxLength={100}
                      onChange={handleOnChangeDescribe}
                    />
                    <div className='label_describe'>{t('caculation.inputDes')}</div>
                  </Col>
                </div>
              </div>
              <Form onFinish={onFinishTable} form={form}>
                <div className='custom-table custom-table-formula'>
                  <Form.List name='listAttributes'>
                    {() => {
                      return (
                        <>
                          <TableComponent
                            dataSource={getData(
                              formula?.id && formula?.type === settingTypeDefault.sensor
                                ? formula?.listAttribute
                                : attributes
                            )}
                            columns={columns}
                          />
                        </>
                      )
                    }}
                  </Form.List>
                  <div className='mt-16x mg_0_16x mb-16x d-flex align-items-center'>
                    <div className='add-attribute-title'>{t('caculation.addAtribute')}</div>
                    <VButton
                      onClick={handleClickAddAttribute}
                      disabled={dataTableAddAtribute?.length >= 10 - arrAttributeTv?.length}
                      title={t('caculation.addNewBtn')}
                      type='text'
                      style={styleBtnAddEdit}
                      htmlType='button'
                    />
                  </div>
                  <Form.List name='listAttributeAdd'>
                    {() => {
                      return (
                        <>
                          <Table
                            pagination={false}
                            dataSource={dataTableAddAtribute}
                            columns={columnsAddAttribute}
                            locale={locale}
                            showHeader={false}
                          />
                        </>
                      )
                    }}
                  </Form.List>
                </div>
                <div className='text-align-center d-flex justify-content-center mt-16x'>
                  <Form.Item shouldUpdate>
                    {() => (
                      <VButton
                        title={t('caculation.confirmBtn')}
                        type='text'
                        style={styleBtnSubmitEdit}
                        disabled={
                          !!form.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                        htmlType='button'
                        onClick={() => form.submit()}
                      />
                    )}
                  </Form.Item>
                  <VButton
                    title={t('caculation.returnBtn')}
                    style={styleBtnReturnEdit}
                    onClick={() => {
                      gotoLastPage()
                    }}
                    htmlType='button'
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
        {modalConfirm?.isModalVisible ? (
          <ModalCheckSuccessComponent
            isVisible={modalConfirm?.isModalVisible}
            content={modalConfirm?.content}
            handleClickCancel={handleClickCancel}
          />
        ) : null}
      </div>
    ) : null
  ) : (
    <AccessDeniedComponent />
  )
}

export default EditFormula
