import { IRequest } from 'interfaces/IRequest'
import { Methods } from 'services/api/EMethod'
import {
  ICheckFormulaByDeviceId,
  IListEventBySensor,
  IListSensor,
  IListUserBySensor,
  IMasterDataTypeSensor,
  ISearchDeviceIdSensor,
  ISearchSensor,
  ISearchListSensor,
  ISensorDetail,
  IUpdateSensor
} from 'services/params/sensor/ISensorParam'

export const getListSensorRequest = (param: IListSensor): IRequest<IListSensor> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_LIST_SENSOR,
    params: param
  }
}
export const getListEmailSensorRequest = () => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_LIST_USER,
    params: {}
  }
}
export const sensorDetailRequest = (param: ISensorDetail): IRequest<ISensorDetail> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.SENSOR_DETAIL,
    params: param
  }
}
export const getListUserBySensor = (param: IListUserBySensor): IRequest<IListUserBySensor> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.GET_LIST_USER_BY_SENSOR,
    params: param
  }
}
export const getListEventBySensor = (param: IListEventBySensor): IRequest<IListEventBySensor> => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.GET_LIST_EVENT_BY_SENSOR,
    params: param
  }
}
export const updateSensorRequest = (param: IUpdateSensor): IRequest<IUpdateSensor> => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.ADMIN_SENSOR_EDIT,
    params: param
  }
}
export const searchDevieceIdRequest = (
  param: ISearchDeviceIdSensor
): IRequest<ISearchDeviceIdSensor> => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.SEARCH_DEVICE_ID_SENSOR,
    params: param
  }
}
export const searchSensorRequest = (param: ISearchSensor): IRequest<ISearchSensor> => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.SEARCH_SENSOR,
    params: param
  }
}
export const searchListSensorRequest = (param: ISearchListSensor): IRequest<ISearchListSensor> => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.SEARCH_SENSOR,
    params: param
  }
}
export const getListTypeSensorRequest = (param?: IMasterDataTypeSensor) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.SENSOR_TYPE,
    params: param
  }
}

export const gemSecurityKey = (serial: string) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.GEN_SECURITY,
    params: { serial }
  }
}

export const checkFormulaByDeviceId = (param?: ICheckFormulaByDeviceId) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.CHECK_BY_DEVICE_ID,
    params: param
  }
}
