import { useEffect, useState } from 'react'
import { Form, Col } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import VButton from 'components/button/VButton'
import FormItem from 'components/common/form/FormItem'
import { useTranslation } from 'react-i18next'
import { formRegister } from '../../views/pages/adminManagement/AccountForm'
import { IOption } from 'interfaces/sensor/IPropsSelect'
import { defaultPermission } from '../../views/pages/adminManagement/defaultValue'
import SelectComponent from 'components/common/select/SelectComponent'
import 'styles/ModalNewRegis.scss'
import { useDispatch } from 'react-redux'
import { createUserAction } from 'redux/actions/users/usersAction'
import { SetLoadingAction } from 'redux/actions/common/commonAction'

interface ICreateUser {
  onCancelModal: () => void
  onSubmitModal: (values: any) => void
}
function ModalNewRegisterComponent({ onCancelModal, onSubmitModal }: ICreateUser) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  // set item list for dropdown
  const [listPermission, setListPermission] = useState<IOption[]>([])
  const [permission, setPermission] = useState<string>('')
  const handleShowPermission = (authority: string) => {
    setPermission(authority)
  }
  useEffect(() => {
    setListPermission(defaultPermission)
  }, [])

  const onFinish = (values: any) => {
    const createUserParam = {
      username: values?.adminName?.trim(),
      email: values?.emailAcc?.trim(),
      role: permission,
      department: values?.department
    }
    dispatch(
      createUserAction(
        { ...createUserParam },
        (data) => {
          if (data?.error) {
            if (data?.error?.code === 400) {
              form.setFields([
                {
                  name: data?.error?.data?.email ? 'emailAcc' : '',
                  errors: [
                    data?.error?.data?.email[0]
                      ? t(`errors.message.${data?.error?.data?.email[0]}`)
                      : ''
                  ]
                }
              ])
              dispatch(SetLoadingAction(false))
            } else {
              dispatch(SetLoadingAction(false))
            }
          } else {
            if (onSubmitModal) {
              onSubmitModal(data?.result)
            }
          }
        },
        true
      )
    )
  }
  const onValuesChange = (values: any) => {
    if (values?.userName) {
      form.setFieldsValue({ userName: values?.userName?.split(' ').join('') })
    }
    if (values?.emailAcc) {
      form.setFieldsValue({ emailAcc: values?.emailAcc?.split(' ').join('') })
    }
    if (values?.password) {
      form.setFieldsValue({ password: values?.password?.split(' ').join('') })
    }
  }
  return (
    <Modal
      visible={true}
      centered={true}
      footer={null}
      maskClosable={false}
      onCancel={onCancelModal}>
      <div className='new-regis-form'>
        <div className='form-title text-align-center font-w-bold fs-16x'>
          {t('adminManagement.newRegis')}
        </div>
        <Form form={form} autoComplete='off' onFinish={onFinish} onValuesChange={onValuesChange}>
          {formRegister?.slice(0, 2).map((item) => (
            <div key={item?.name}>
              <FormItem
                label={item?.label}
                name={item?.name}
                type={item?.type}
                rules={item?.rules}
                maxLength={item?.maxLength}
                isRequired={item?.isRequired}
              />
            </div>
          ))}
          <Col span={24} className='mt-16x'>
            <div className='label-bold'>
              権限 <span>*</span>
            </div>
            <SelectComponent
              disableAll={true}
              defaultOption={permission}
              dataOption={listPermission}
              onChangeSelect={handleShowPermission}
            />
          </Col>
          {formRegister?.slice(-1).map((item) => (
            <div key={item?.name}>
              <FormItem
                label={item?.label}
                name={item?.name}
                type={item?.type}
                rules={item?.rules}
                maxLength={item?.maxLength}
                isRequired={item?.isRequired}
              />
            </div>
          ))}
          <div className='text-align-center d-flex justify-content-center'>
            <Form.Item shouldUpdate>
              {() => (
                <VButton
                  disabled={
                    permission === '' ||
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                  style={styleBtnSubmit}
                  type='text'
                  title={t('modal.userManagement.btnRegis')}
                />
              )}
            </Form.Item>
            <VButton
              title={t('modal.btnCancel')}
              style={styleBtnCancel}
              type='text'
              htmlType='button'
              onClick={() => onCancelModal()}
            />
          </div>
        </Form>
      </div>
    </Modal>
  )
}
const styleBtnSubmit = {
  width: 74,
  height: 38,
  marginRight: 10,
  color: '#ffffff',
  marginTop: 15
}
const styleBtnCancel = {
  width: 116,
  height: 38,
  marginLeft: 10,
  background: '#ffffff',
  border: '1px solid #D7000F',
  color: '#D7000F',
  marginTop: 15
}
export default ModalNewRegisterComponent
