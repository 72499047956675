import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { DELAY } from 'interfaces/enums/EDelay'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, put, call, takeLatest, delay } from 'redux-saga/effects'
import { SetNotificationAction, SetLoadingAction } from 'redux/actions/common/commonAction'
import { EGroupDetailActions } from 'redux/actions/group/EGroupDetailAction'
import {
  IGetListGroupByEmailAction,
  IGetListSensorByGroupAction,
  IGroupDetailAction
} from 'redux/actions/group/groupDetailTypes'
import { GroupApi } from 'services/api/group/groupApi'
function* getGroupDetailSaga(action: IGroupDetailAction) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(GroupApi.groupDetail, action.param)
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* getListSensorByGroupSaga(action: IGetListSensorByGroupAction) {
  let err: any = null
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      GroupApi.getListSenorByGroup,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* getListGroupByEmailSaga(action: IGetListGroupByEmailAction) {
  let err: any = null
  if (action?.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      GroupApi.getListGroupByEmail,
      action.param
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
export default function* groupSaga() {
  yield all([
    takeLatest(EGroupDetailActions.GROUP_DETAIL, getGroupDetailSaga),
    takeLatest(EGroupDetailActions.GET_LIST_SENSOR_BY_GROUP, getListSensorByGroupSaga),
    takeLatest(EGroupDetailActions.GET_LIST_GROUP_BY_EMAIL, getListGroupByEmailSaga)
  ])
}
