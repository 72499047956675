import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigProvider, Select, Typography } from 'antd'
import 'styles/CustomSelect.scss'
import { IOption } from 'interfaces/sensor/IPropsSelect'

const { Option } = Select
const { Title } = Typography

const PREFIX = 'ant-typography'

export interface CustomSelectProps {
  /** Text title associated with the Select */
  title?: string | React.ReactNode

  /** Styles for the Title element  */
  titleStyles?: React.CSSProperties

  /** Styles for the Select element  */
  selectStyles?: React.CSSProperties

  /** List of options to choose from */
  options: IOption[]

  /** Initial selected value (for uncontrolled components) */
  defaultValue?: string | number

  /** Indicates the Select is not available for interaction */
  disabled?: boolean

  /** Hide title visually */
  hiddenTitle?: boolean

  /** Customize dropdown content */
  dropdownRender?:
  | ((
    menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>)
  | undefined

  /** Callback when Select loses focus */
  onBlur?(event: React.FocusEvent<HTMLButtonElement>): void

  /** Callback when Select value changes */
  onChange?(value: string | number): void

  /** Callback when Select receives focus */
  onFocus?(event: React.FocusEvent<HTMLButtonElement>): void

  /** Callback when hover items */
  onOptionMouseEnter?(event: React.MouseEventHandler<HTMLDivElement>, index: number): void

  /** The `Icon` component */
  iconRight?: React.ReactNode;

  /**
   * Text displayed next to label for non-required fields. Only use this prop to
   * internationalize the default string
   */
  optionalTitle?: string

  /** Indicates that a user must select a value before submitting */
  required?: boolean

  /** Controlled selected value (for controlled components). Requires `onChange` handler */
  value?: string | number

  /** The index when hover option */
  indexOptionMouseEnter?: number,
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  title,
  titleStyles,
  selectStyles,
  options,
  defaultValue,
  disabled,
  hiddenTitle,
  dropdownRender,
  onBlur,
  onChange,
  onFocus,
  onOptionMouseEnter,
  iconRight,
  optionalTitle = 'Optional',
  required,
  value,
  indexOptionMouseEnter
}: CustomSelectProps) => {
  const { t } = useTranslation()

  const handleDropdownRender = (
    menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  ): any => {
    requestAnimationFrame(() => {
      const element = document.getElementsByClassName('ant-select-selection-item')

      for (let i = 0; i < element.length; i++) {
        const iconNode = element[i].getElementsByClassName('icon-right');

        for (let j = 0; j < iconNode.length; j++) {
          
          let iconElement: HTMLElement = iconNode[j] as HTMLElement;
          iconElement.style.display = "contents";

          const antBtnNode = iconNode[j].getElementsByClassName('ant-btn');
          
          if (antBtnNode) {

            for (let k = 0; k < antBtnNode.length; k++) {
              antBtnNode[k].remove()
            }
          }
        }
      }
    })

    return dropdownRender && dropdownRender(menu)
  }

  const handleOptionMouseEnter = (
    event: React.MouseEventHandler<HTMLDivElement>,
    index: number
  ) => {
    onOptionMouseEnter && onOptionMouseEnter(event, index)
  }

  return (
    <ConfigProvider renderEmpty={() => t('searchEmptyTable')}>

      <Title style={titleStyles}>
        {title}
        {required && <span className={`${PREFIX}__optional`}>{`${optionalTitle}`}</span>}
      </Title>

      <Select
        style={selectStyles}
        onChange={onChange}
        value={value}
        dropdownRender={handleDropdownRender}>

        {options.map((item, index) => (
          <Option
            key={index}
            onMouseEnter={(event: React.MouseEventHandler<HTMLDivElement>) =>
              handleOptionMouseEnter(event, index)
            }
            value={item?.value ?? item?.id ?? item?.name}
          >

            {iconRight && (indexOptionMouseEnter === index) ? (
              <div className='icon-right'>

                <span className="content">
                  {item?.name}
                </span>
                {iconRight}

              </div>
            ) : (
              item?.name
            )}
          </Option>
        ))}
      </Select>
    </ConfigProvider>
  )
}

export default CustomSelect;