export enum ESensorType {
  PADDY_FILED = 'paddy_field',
  UPLAND_FARMING = 'upland_farming',
  WEATHER = 'weather'
}

export enum EAttributes {
  WATER_LEVEL = '_water_level',
  WATER_TEMP = '_water_temperature',
  EC_VALUE = '_EC_value',
  WATER = '_water',
  AMOUNT_RAIN = '_amount_rain',
  WIND_DIRECTION = '_wind_direction'
}
export enum EEventSensorDetail {
  ON = 'ON',
  OFF = 'OFF'
}
