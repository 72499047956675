import i18n from 'configs/i18n'
import { regexEmail } from 'utils/Functions'
const validCreateUser = {
  userName: [
    () => ({
      validator(_: object, value: string) {
        if (value.trim().length > 0) {
          return Promise.resolve()
        }
        return Promise.reject(
          new Error(
            i18n.t('errors.message.FRE00001', {
              0: i18n.t('adminManagement.nameLabel')
            })
          )
        )
      }
    })
  ],
  email: [
    {
      required: true,
      message: i18n.t('errors.message.FRE00001', {
        0: i18n.t('adminManagement.emailLabel')
      })
    },
    {
      pattern: new RegExp(regexEmail),
      message: i18n.t('errors.message.FRE00002', {
        0: i18n.t('adminManagement.emailLabel')
      })
    }
  ],
  /* password: [
    {
      required: true,
      message: i18n.t('errors.message.FRE00001', {
        0: i18n.t('modal.userManagement.create.lable.password')
      })
    },
    {
      min: 6,
      message: i18n.t('errors.message.FRE00006', {
        0: 6
      })
    },
    {
      max: 32,
      message: '入力長を超えました '
    }
  ],
  confirmPassword: [
    ({ getFieldValue }: any) => ({
      validator(_: object, value: string) {
        if (value.length > 0) {
          if (value.length < 6) {
            return Promise.reject(
              new Error(
                i18n.t('errors.message.FRE00006', {
                  0: 6
                })
              )
            )
          } else if (value.length > 32) {
            return Promise.reject(new Error('入力長を超えました'))
          } else {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(new Error(i18n.t('errors.message.FRE00005')))
          }
        }
        return Promise.reject(
          new Error(
            i18n.t('errors.message.FRE00001', {
              0: i18n.t('modal.userManagement.create.lable.passwordConfirm')
            })
          )
        )
      }
    })
  ], */
  department: [
    () => ({
      validator(_: object, value: string) {
        if (value.trim().length > 0) {
          return Promise.resolve()
        }
        return Promise.reject(
          new Error(
            i18n.t('errors.message.FRE00001', {
              0: i18n.t('adminManagement.descriptionLabel')
            })
          )
        )
      }
    })
  ]
}
export { validCreateUser }
