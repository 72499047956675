import { IGetDataDashBoardAction } from './dashboardTypes'
import { EDashboardActions } from './EDashboardAction'

export const getDataDashboardAction = (
  isLoading: boolean,
  callBack: (data: any) => void
): IGetDataDashBoardAction => ({
  type: EDashboardActions.DASHBOARD_LIST,
  isLoading,
  callBack
})
