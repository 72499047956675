import { Breadcrumb } from 'antd'
import { IconHouse } from 'assets'
import { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { checkRegexTitleDetail } from 'views/routes/Breadscrumb'
import i18n from 'configs/i18n'
import {
  ADMIN_ROUTE,
  arrBreadScrumbFormulaCopy,
  arrPathFormulaCopy,
  routes,
  routesName
} from 'views/routes/routes'
import 'styles/Breadscrumb.scss'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducers'
interface IDataBreadcrumb {
  id: string
  name: string
  path?: string
}
const VBreadcrumb = () => {
  const location = useLocation()
  const history = useHistory()
  const serialId = window.location.pathname.split('/')
  const [listBreadcrumb, setListBreadcrumb] = useState<IDataBreadcrumb[]>([])
  const formula = useSelector((state: AppState) => state?.formula)
  const checkBreadcrumbFormulaCopy = (formulaType: string, arrBreadScrumb: any) => {
    if (formulaType === 'copy') {
      return arrBreadScrumbFormulaCopy
    } else {
      return arrBreadScrumb
    }
  }
  const handleOnClick = () => {
    if (ADMIN_ROUTE.concat(routesName.DASHBOARD) !== location?.pathname) {
      history.push(routesName.DASHBOARD)
    }
  }
  useEffect(() => {
    if (location?.pathname === ADMIN_ROUTE.concat(routesName?.DASHBOARD)) {
      setListBreadcrumb([])
    } else {
      const arrCheck = checkRegexTitleDetail(location.pathname)
      if (arrCheck?.length) {
        setListBreadcrumb(arrCheck)
      } else {
        routes.forEach((route) => {
          route?.subMenu?.forEach((itemSub) => {
            if (ADMIN_ROUTE.concat(itemSub.path ?? '') === location.pathname) {
              if (arrPathFormulaCopy?.includes(location?.pathname)) {
                const arr = checkBreadcrumbFormulaCopy(
                  formula?.formulaType ?? '',
                  itemSub?.arrBreadScrumb
                )
                setListBreadcrumb(arr)
              } else {
                setListBreadcrumb(itemSub?.arrBreadScrumb)
              }
              return
            }
          })
        })
      }
    }
    // eslint-disable-next-line
  }, [location.pathname])
  return (
    <div className='custom-breadscrumb'>
      <Breadcrumb className='fs-14x'>
        {listBreadcrumb.length ? (
          <Breadcrumb.Item>
            <img className='img-w-h pointer' onClick={handleOnClick} src={IconHouse} alt='house' />
          </Breadcrumb.Item>
        ) : null}
        {listBreadcrumb?.map((item) => (
          <Breadcrumb.Item key={item?.id}>
            {item?.path ? (
              item?.name === i18n.t('sideBar.serialNo.arrBreadcrumbsItem.serialDetail') ? (
                <Link
                  style={{ color: '#D7000F', cursor: 'pointer' }}
                  to={ADMIN_ROUTE.concat(
                    routesName.SERIAL_NO + `/individual-detail/${serialId[4]}`
                  )}>
                  {item?.name}
                </Link>
              ) : (
                <Link style={{ color: '#D7000F' }} to={ADMIN_ROUTE.concat(item?.path)}>
                  {item?.name}
                </Link>
              )
            ) : (
              <span style={{ color: '#6C757D' }}>{item?.name}</span>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  )
}

export default VBreadcrumb
