import { IAttribute } from 'interfaces/attributes/IAttribute'
import { IGetAttributeParams } from 'services/params/atrtibutes/IGetAttributeParams'
import { EAttributeTypes } from './EAttributeAction'

export const GetAttributesAction = (params?: IGetAttributeParams) => ({
  type: EAttributeTypes.GET_ATTRIBUTES,
  params
})

export const SetAttributesAction = (data: IAttribute[], sensorType: string) => ({
  type: EAttributeTypes.SET_ATTRIBUTES,
  data,
  sensorType
})
