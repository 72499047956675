const communicationStatusSetting = {
  abnormal: 'abnormal',
  normal: 'normal'
}
// default type sensor
const defaultTypeSensorLTE = 'Lte'
const defaultTypeSensorLora = 'LoRa'
const defaultShowBlank = '---'
// default opera status
const defaultOperaStatus = {
  active: 'actived',
  stop: 'stopped'
}
// default type list sensor
const defaultTypeListSensor = {
  lowBattery: 'LowBattery',
  communicationErr: 'TransmissionError',
  stop: 'Inactive'
}
const defaultValueAll = 'all'
export {
  communicationStatusSetting,
  defaultTypeSensorLTE,
  defaultTypeSensorLora,
  defaultShowBlank,
  defaultOperaStatus,
  defaultTypeListSensor,
  defaultValueAll
}
