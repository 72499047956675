import React from 'react'
import { Select } from 'antd'
import 'styles/SerialNoManagement.scss'

const { Option } = Select

type Props = {
  value?: any
  label: string
  onSelect?: any
  data: any[]
  selectValue?: boolean
  showSearch?: boolean
  allowClear?: boolean
  onSearch?: any
  filterOption?: any
  optionFilterProp?: any
}

const Dropdown = ({
  value,
  label,
  onSelect,
  data,
  selectValue,
  showSearch,
  allowClear,
  onSearch,
  optionFilterProp,
  filterOption
}: Props) => {
  return (
    <div className='dropdown-layout'>
      <div className='label'>{label}</div>
      <React.Fragment>
        <Select
          size='small'
          value={value}
          className='dropdown'
          onChange={onSelect}
          showSearch={showSearch}
          allowClear={allowClear}
          onSearch={onSearch}
          filterOption={filterOption}
          optionFilterProp={optionFilterProp}>
          {data?.map((item: any) => (
            <Option value={selectValue ? item.value : item.id} key={item.value + item.id}>
              {item.value}
            </Option>
          ))}
        </Select>
      </React.Fragment>
    </div>
  )
}

export default Dropdown
