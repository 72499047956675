export enum EUserActions {
  USERS_LIST = 'USERS_LIST',
  UPDATE_USERS_LIST = 'UPDATE_USERS_LIST',
  GET_USER_BY_ID = 'GET_USER_BY_ID',
  UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL',
  UPDATE_USER_BY_ID = 'UPDATE_USER_BY_ID',
  UPDATE_USER_STATUS = 'UPDATE_USER_STATUS',
  CREATE_USER = 'CREATE_USER',
  DELETE_USER = 'DELETE_USER',
  ADMIN_LIST = 'ADMIN_LIST',
  VERIFY_ADMIN = 'VERIFY_ADMIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHECK_TOKEN_EXIST = 'CHECK_TOKEN_EXIST'
}
