import { IRequest } from 'interfaces/IRequest'
import { IVerifyParams } from 'services/params/ILoginParams'
import { Methods } from 'services/api/EMethod'

const verifyRequest = (verifyParams: IVerifyParams): IRequest<IVerifyParams> => {
  return {
    id: 1,
    jsonrpc: '2.0',
    method: Methods.VERIFY_ADMIN,
    params: verifyParams
  }
}
export default verifyRequest
