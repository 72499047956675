import { AxiosResponse } from 'axios'
import i18n from 'configs/i18n'
import { IData } from 'interfaces/IData'
import { INotification } from 'interfaces/INotification'
import { all, put, call, takeLatest, delay } from 'redux-saga/effects'
import { DELAY } from 'interfaces/enums/EDelay'
import { NotificationApi } from 'services/api/notification/notificationsApi'
import {
  ICreateAlertAction,
  IGetListAlertAction,
  IUpdateAlertAction,
  IDetailAlertAction
} from 'redux/actions/notifications/notificationsTypes'
import { SetLoadingAction, SetNotificationAction } from 'redux/actions/common/commonAction'
import { IDataListAlertRes } from 'interfaces/INotificationList'
import { ENotificationAction } from 'redux/actions/notifications/ENotificationsAction'

function* getListAlertSaga(action: IGetListAlertAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<IDataListAlertRes>> = yield call(
      NotificationApi.getListAlert,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* createAlertSaga(action: ICreateAlertAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      NotificationApi.createAlert,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* updateAlertSaga(action: IUpdateAlertAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      NotificationApi.updateAlert,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}
function* detailAlertSaga(action: IDetailAlertAction) {
  let err: any = null
  if (action.isLoading) {
    yield put(SetLoadingAction(true))
  }
  try {
    const response: AxiosResponse<IData<any>> = yield call(
      NotificationApi.detailAlert,
      action.params
    )
    if (response?.data?.error) {
      err = response?.data?.error
    } else {
      yield delay(DELAY.DELAYSUCCESS)
      action.callBack(response?.data)
    }
  } catch (error) {
    err = error
    return Promise.reject(error)
  } finally {
    if (err) {
      yield delay(DELAY.DELAYERR)
      const noti: INotification = {
        title: i18n.t('notification.error'),
        message: err?.message,
        isLoading: action.isLoading,
        notiType: err?.errorType,
        code: err?.code
      }
      yield put(SetNotificationAction(noti))
      yield put(SetLoadingAction(false))
    }
  }
}

export default function* notificationSaga() {
  yield all([
    takeLatest(ENotificationAction.GET_LIST_ALERT_SYSTEM, getListAlertSaga),
    takeLatest(ENotificationAction.CREATE_ALERT_SYSTEM, createAlertSaga),
    takeLatest(ENotificationAction.UPDATE_ALERT_SYSTEM, updateAlertSaga),
    takeLatest(ENotificationAction.DETAIL_ALERT_SYSTEM, detailAlertSaga)
  ])
}
