import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import * as antdNoti from 'antd'
import { ESuccessType } from 'interfaces/enums/ESuccessType'
import { INotification } from 'interfaces/INotification'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { logout } from 'services/api/v1'
import { getToken } from 'utils/Functions'
import { ERROR_TYPE } from 'interfaces/enums/ErrorTypes'

const Context = React.createContext({ name: 'Default' })

type Props = {
  notification?: INotification
}

const Notification = ({ notification }: Props) => {
  const token = getToken()
  const [api, contextHolder] = antdNoti.notification.useNotification()
  const { t } = useTranslation()
  useEffect(() => {
    if (
      notification &&
      (notification?.code === ERROR_TYPE.UNAUTHENTICATED ||
        notification?.message === 'Unauthorized') &&
      token
    ) {
      logout()
    } else {
      if (notification && notification?.message) {
        const renderIcon = () => {
          switch (notification?.notiType) {
            case ESuccessType.SUCCESS:
              return <CheckCircleOutlined style={styles.success} />
            default:
              return <CloseCircleOutlined style={styles.failure} />
          }
        }

        api.open({
          message: notification?.title || '',
          description: <Context.Consumer>{({ name }) => name}</Context.Consumer>,
          placement: 'topRight',
          icon: renderIcon()
        })
      }
    }
    // eslint-disable-next-line
  }, [notification, api])

  return (
    <div>
      {notification && !(notification?.code === ERROR_TYPE.UNAUTHENTICATED || notification?.message === 'Unauthorized') ? (
        <Context.Provider
          value={{
            name:
              notification?.notiType === 'ERR_INTERNET_DISCONNECTED'
                ? t('disconnectNetwork')
                : notification?.message || ''
          }}>
          {contextHolder}
        </Context.Provider>
      ) : null}
    </div>
  )
}

const styles = {
  success: { color: 'green' },
  failure: { color: 'red' }
}

export default Notification
