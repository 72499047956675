import axios from 'axios'
import { API_URL } from 'constants/general'
import { IRequest } from 'interfaces/IRequest'
import { Methods } from 'services/api/EMethod'
import { IImportCsvParams } from 'services/params/serialNo/IserialNoParam'
import { getToken } from 'utils/Functions'

export const serialNoRequest = (params?: any, method?: any): IRequest<any> => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: method,
    params: params
  }
}

export const importCsvFile = (data: IImportCsvParams): any => {
  const params = { ...data.params }
  const token = getToken()
  const formData = new FormData()
  formData.append('file', data.csvFile)
  formData.append('method', Methods.IMPORT_CSV_FILE)
  formData.append('params', JSON.stringify(params))

  return axios({
    method: 'POST',
    url: API_URL,
    data: formData,
    headers: { Authorization: 'Bearer ' + token }
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error?.response
    })
}
