import { EExpKeys } from 'interfaces/enums/EKey'
import { FormulaActionTypes, ISetFormulaCopy } from 'redux/actions/display/displayTypes'
import { EDisplayActions } from 'redux/actions/display/EDisplayAction'

const initialState: ISetFormulaCopy = {
  id: '',
  formulaType: '',
  type: '',
  timeApply: '',
  sensorType: '',
  parentType: '',
  email: '',
  deviceId: '',
  sensorAiId: '',
  sensorName: '',
  description: '',
  listAttribute: [],
  listAttributeAdd: [],
  numberAttributeTv: 0,
  mode: EExpKeys.RAD_KEY
}
const formulaReducer = (state = initialState, action: FormulaActionTypes): ISetFormulaCopy => {
  switch (action.type) {
    case EDisplayActions.UPDATE_COPY_FORMULA:
      return {
        ...state,
        formulaId: action?.data?.formulaId,
        formulaType: action?.data?.formulaType,
        id: action?.data?.id,
        type: action?.data?.type,
        timeApply: action?.data?.timeApply,
        sensorType: action?.data?.sensorType,
        parentType: action?.data?.parentType,
        email: action?.data?.email,
        deviceId: action?.data?.deviceId,
        sensorAiId: action?.data?.sensorAiId,
        sensorName: action?.data?.sensorName,
        description: action?.data?.description,
        listAttribute: action?.data?.listAttribute,
        listAttributeAdd: action?.data?.listAttributeAdd,
        numberAttributeTv: action?.data?.numberAttributeTv,
        mode: action?.data?.mode,
        lastIndexAttributeTv: action?.data?.lastIndexAttributeTv
      }
    case EDisplayActions.CLEAN_FORMULA:
      return initialState

    default:
      return state
  }
}

export default formulaReducer
