import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  dataEmail,
  dataListSensor,
  defaultAttReserve,
  defaultShowBlankFormula
} from '../individualSettings/defaultValue'
import SelectComponent from 'components/common/select/SelectComponent'
import { ColumnsType } from 'antd/es/table'
import { IOption } from 'interfaces/sensor/IPropsSelect'
import { useState, useEffect } from 'react'
import 'styles/IndividualSetting.scss'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDetailFormula } from 'redux/actions/display/displayAction'
import {
  defaultTypeSensorLora,
  defaultTypeSensorLTE,
  defaultValueAll
} from '../sensor/defaultValue'
import { SetLoadingAction } from 'redux/actions/common/commonAction'
import { formatFormula, sortListAttByAttributeIndex } from 'utils/Functions'
import { AppState } from 'redux/reducers'
import { IAttribute } from 'interfaces/attributes/IAttribute'
import { getNameAttribute, listAttributeTv } from '../displayManagement/convertData'
import TableComponent from 'components/common/table/TableComponent'
import ButtonReturnComponent from 'components/common/button/ButtonReturnComponent'
import InputReadOnlyComponent from 'components/common/input/InputReadOnlyComponent'
import { EKey } from 'interfaces/enums/EKey'
const getData = (data: IAttribute[]) => {
  const result = [...data]
  result.map((item) => {
    item.key = item.attributeId
    item.scaleValue = item.scaleValue || 0
    item.unit = item.unit || defaultAttReserve
    item.conversionFormula = item.conversionFormula || defaultShowBlankFormula
    return item
  })
  return result
}
const DetailFormula = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [lteOrLora, setLteOrLora] = useState<string>('')
  const handleOnChangeSelectLteOrLora = (valueLteOrLora: string) => {
    setLteOrLora(valueLteOrLora)
  }
  // state select sensor from filter
  const [listSensor, setListSensor] = useState<string>('')
  const handleShowFilterSensor = (sensor: string) => {
    setListSensor(sensor)
  }
  // state select option list email
  const [listEmail, setListEmail] = useState<IOption[]>([])
  const [email, setEmail] = useState<string>(defaultValueAll)
  const handleOnChangeSelectEmail = (valueEmail: string) => {
    setEmail(valueEmail)
  }
  const [sensorName, setSensorName] = useState<string>('')
  const [deviceId, setDeviceId] = useState<string>('')
  useEffect(() => {
    setListEmail(dataEmail)
  }, [])
  // state select option setting target
  const [settingTarget, setSettingTarget] = useState<string>('')
  const handleOnChangeSelectSettingTarget = (valueSettingTarget: string) => {
    setSettingTarget(valueSettingTarget)
  }

  const stateScreen: any = history?.location?.state
  const [describe, setDescribe] = useState<string>('')
  const appState = useSelector((state: AppState) => state)
  const masterData = appState.common.masterData
  const sensorType = masterData.sensorType
  const defaultLteOrLora = masterData.sensorParentType
  const [attDetail, setAttDetail] = useState<any>([])

  useEffect(() => {
    const param = {
      formulaId: stateScreen?.key
    }
    dispatch(
      getDetailFormula(true, param, (data) => {
        if (data?.result) {
          const dataDetail = data?.result
          const loraOrLte =
            dataDetail?.parentType === '1' ? defaultTypeSensorLora : defaultTypeSensorLTE
          setLteOrLora(loraOrLte)
          setSettingTarget(dataDetail?.sensorType)
          setDescribe(dataDetail?.description)
          setSensorName(dataDetail?.sensorName)
          setDeviceId(dataDetail?.deviceId)
          setEmail(dataDetail?.email ?? defaultValueAll)
          setListSensor(dataDetail?.sensorName)
          const arrListAtt = dataDetail?.formulaDetail
          // get list att tm and sort
          let listAttTm = arrListAtt?.filter(
            (item: any) => item?.dataKeyPrefix !== EKey.keyPrefixTv
          )
          listAttTm = sortListAttByAttributeIndex(listAttTm)
          // get list att tv and sort
          let listAttTv = listAttributeTv(arrListAtt)
          listAttTv = sortListAttByAttributeIndex(listAttTv)
          // list att sort
          const listAtt = listAttTm?.concat(listAttTv)
          setAttDetail(listAtt)
          dispatch(SetLoadingAction(false))
        } else {
          dispatch(SetLoadingAction(false))
        }
      })
    )
    // eslint-disable-next-line
  }, [dispatch])
  // render columns
  const columns: ColumnsType<IAttribute> = [
    {
      title: t('caculation.table.positionID'),
      key: 'id',
      render: (value: IAttribute) => {
        const valueIndex = value.attributeIndex.toString()
        const result =
          value?.dataKeyPrefix === EKey.keyPrefixTv
            ? EKey.keyPrefixTv.concat(valueIndex)
            : lteOrLora === defaultTypeSensorLTE
            ? 'tm'.concat(valueIndex)
            : ' tm(ID*10)+'.concat(valueIndex)
        return <div className='pointer custom-a'>{result}</div>
      },
      align: 'center',
      width: '8%'
    },
    {
      title: t('caculation.table.sensorSpecs'),
      dataIndex: '',
      align: 'center',
      width: '15%',
      render: (value) => {
        return (
          <div>
            {value?.dataKeyPrefix !== EKey.keyPrefixTv &&
            getNameAttribute(value?.attributeTranslate) === defaultAttReserve
              ? t('caculation.reserve')
              : getNameAttribute(value?.attributeTranslate) || ''}
          </div>
        )
      }
    },
    {
      title: t('caculation.table.sensorUnit'),
      dataIndex: 'unit',
      render: (value, row, index) => {
        return (
          <div>
            <InputReadOnlyComponent value={value} className='edit-columns' />
          </div>
        )
      },
      align: 'center',
      width: '10%'
    },
    {
      title: t('caculation.table.sensorIndex'),
      dataIndex: 'scaleValue',
      render: (value, row, index) => {
        return (
          <div>
            <InputReadOnlyComponent value={value} className='edit-columns' />
          </div>
        )
      },
      align: 'center',
      width: '10%'
    },
    {
      title: t('caculation.table.defaultFormula'),
      dataIndex: 'conversionFormula',
      render: (value) => {
        return (
          <div>
            <InputReadOnlyComponent
              value={formatFormula(value, attDetail)}
              className='formula-column text-align-center'
              styleInput={{ height: '32px' }}
            />
          </div>
        )
      },
      align: 'center',
      width: '18%'
    }
  ]
  return (
    <div className='individual-settings'>
      <div className='display-table mt-16x'>
        <div className='bgrffffff'>
          <div className='pd_16x_0x'>
            <div className='mg_0_16x'>
              <div className='table-search-option ss-drop-drown'>
                <div className='pl-7x'>
                  <div className='label_bold'>
                    {t('caculation.settingType')}
                    <div className='label'>{t('caculation.individualSetting')}</div>
                  </div>
                </div>
                <div className='pl-7x'>
                  <div className='label_bold_2'>{t('caculation.settingTargetRegis')}</div>
                </div>
                <Row>
                  <Col span={12} className='pl-7x pr-7x'>
                    <SelectComponent
                      lable={t('caculation.table.lteOrLora')}
                      defaultOption={lteOrLora}
                      dataOption={defaultLteOrLora}
                      onChangeSelect={handleOnChangeSelectLteOrLora}
                      disableSelect={true}
                    />
                  </Col>
                  <Col span={12} className='pl-7x'>
                    <SelectComponent
                      lable={t('caculation.selectEmail')}
                      defaultOption={email}
                      dataOption={listEmail}
                      onChangeSelect={handleOnChangeSelectEmail}
                      disableSelect={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} className='pl-7x pr-7x'>
                    <SelectComponent
                      lable={t('caculation.sensorType')}
                      defaultOption={settingTarget}
                      dataOption={sensorType}
                      onChangeSelect={handleOnChangeSelectSettingTarget}
                      disableSelect={true}
                    />
                  </Col>
                  <Col span={12} className='pl-7x'>
                    <SelectComponent
                      disableAll={true}
                      lable={t('caculation.table.sensorName')}
                      defaultOption={listSensor}
                      dataOption={dataListSensor}
                      onChangeSelect={handleShowFilterSensor}
                      disableSelect={true}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12} className='pl-7x pr-7x setting_input custom-form-item'>
                    <div className='label_bold_4'>{t('caculation.sensorNameSelected')}</div>
                    <InputReadOnlyComponent value={sensorName} />
                  </Col>
                  <Col span={12} className='pl-7x  setting_input custom-form-item'>
                    <div className='label_bold_4'>{t('caculation.deviceIdSelected')}</div>
                    <InputReadOnlyComponent value={deviceId} />
                  </Col>
                </Row>
                <Col span={24} className='pl-7x setting_input'>
                  <div className='label_bold_4'>{t('caculation.settingDes')}</div>
                  <InputReadOnlyComponent value={describe} />
                </Col>
              </div>
            </div>
            <div className='custom-table'>
              <TableComponent dataSource={getData(attDetail)} columns={columns} />
            </div>
            <div className='text-align-center pd_16x_0x'>
              <ButtonReturnComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DetailFormula
