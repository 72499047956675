import { Methods } from 'services/api/EMethod'
import {
  ICopyFormula,
  ICreateFormula,
  IDeleteFormula,
  IDisplay,
  IGetDetailFormula,
  IUpdateFormula
} from 'services/params/display/IDisplay'

export const getDataDisplayRequest = (param: IDisplay) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.DISPLAY,
    params: param
  }
}
export const deleteFormulaRequest = (param: IDeleteFormula) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.DELETE_FORMULA,
    params: param
  }
}
export const copyFormulaRequest = (param: ICopyFormula) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.COPY_FORMULA,
    params: param
  }
}
export const createFormulaRequest = (param: ICreateFormula) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.CREATE_FORMULA,
    params: param
  }
}
export const getDtailFormulaRequest = (param: IGetDetailFormula) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.GET_DETAIL_FORMULA,
    params: param
  }
}
export const updateFormulaRequest = (param: IUpdateFormula) => {
  return {
    id: 8,
    jsonrpc: '2.0',
    method: Methods.UPDATE_FORMULA,
    params: param
  }
}
